import React, { useContext, useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Button, Modal, Spin } from "antd";
import { S3_UPLOAD_STATUS } from "../../../constants/index.js";
import { DocumentsTableQuery } from "./query.js";
import { DocumentUploadModal } from "../DocumentUploadModal/index.js";
import { handleGraphQLError, handleMutation } from "../../../utils/errorHandling.js";
import { REUPLOAD_SUBMISSION_DOCUMENT } from "../../../constants/mutations.js";
import { UserContext } from "../../Application/UserContext.js";
import { SUBMISSION_FILE_EXTENSION_ARRAY } from "../../../utils/index.js";
import { getS3StatusText, S3UploadStatus } from "../S3UploadStatus/index.js";
import { getS3ErrorText, S3ErrorMessage } from "../S3ErrorMessage/index.js";
import { ScrollableTable } from "../ScrollableTable/index.js";
import { DownloadButton } from "../DownloadButton/index.js";
import { PCDULink } from "../PCDULink/index.js";
import { DocumentDQStatus } from "../DocumentDQStatus/index.js";
import { PersistentState } from "../../../utils/PersistentState.js";
import { handleControlledDefaultSortOrder } from "../../../utils/handleControlledDefaultSortOrder.js";

// how long to wait before refetching :: 
const POLLING_DELAY = 2000; 

const { usePersistentState } = PersistentState();

const isNotEmpty = (arr) => Array.isArray(arr) && arr.length > 0;

const statusOfDocumentsHaveChanged = (previous, current) => {
    const previousStatuses = previous.map(doc => doc.S3UploadStatus);
    const currentStatuses = current.map(doc => doc.S3UploadStatus);
    // if length has changed, there has been a change in documents (return true); otherwise, 
    // these will always be in the same order, so we can compare 1 to 1 in order :: 
    return previousStatuses.length !== currentStatuses.length 
        ? true 
        : previousStatuses.some((status, i) => status !== currentStatuses[i]);
}; 

const errorMessage = () => {
    Modal.error({
        title: "Error",
        content: "Document upload was not successful. If the upload fails again, please contact The Plan Admin Team at PlanAdmin.PCDU@dhhs.nc.gov"
    });
};


export const DocumentsTable = ({
    submissionId, 
    persistState = false,
    subtitle = "",
    shortened = false,
    afterSuccessfulUpload = null,
    pollForData = true,
    afterClose = null,
    onStatusChangeOfDocuments = null, 
    forceRefetchOfDocuments, 
    newBreadcrumbs = [],
    forceRefetchOfDocumentsSetter 
}) => {
    const [reuploadDocumentModal, setReuploadDocumentModal] = useState(null);
    const [submissionDocumentsToReupload, setSubmissionDocumentsToReupload] = useState({});

    const [sortOn, setSortOn] = usePersistentState("specifier", persistState);
    const [sortBy, setSortBy] = usePersistentState("ascend", persistState);
    const [page, setPage] = usePersistentState(1, persistState);
    const [pageSize, setPageSize] = usePersistentState(10, persistState);

    const { 
        data, 
        previousData, 
        loading, 
        error, 
        refetch, 
        stopPolling, 
        startPolling  
    } = useQuery(DocumentsTableQuery, { 
        variables: { id: submissionId },
        fetchPolicy: "no-cache" 
    });
    
    const [reUploadSubmissionDocument, reUploadSubmissionDocumentProgress] = useMutation(REUPLOAD_SUBMISSION_DOCUMENT); 
    const { userPermCreateSubmission, userIsSubmitter } = useContext(UserContext);
    const submissionType = data?.submission?.obligation?.submissionType;
    const submissionDocuments = data?.submission?.submissionDocuments ?? []; 

    // handle when to start or stop polling based on status of submission documents :: 
    useEffect(() => { 
        if ( 
            pollForData === true && 
            isNotEmpty(submissionDocuments) && 
            submissionDocuments.some(doc => doc?.S3UploadStatus === S3_UPLOAD_STATUS.uploading)
        ) {
            startPolling(POLLING_DELAY);
        } else {
            stopPolling();
        }

        return stopPolling;
    }, [data]); 

    // invoke callback passed from parent component if the status of the documents change :: 
    useEffect(() => { 
        const previousFetchSubmissionDocuments = previousData?.submission?.submissionDocuments ?? []; 
        if (
            typeof onStatusChangeOfDocuments === "function" && 
            isNotEmpty(previousFetchSubmissionDocuments) && 
            isNotEmpty(submissionDocuments) && 
            statusOfDocumentsHaveChanged(previousFetchSubmissionDocuments, submissionDocuments)
        ) {
            onStatusChangeOfDocuments(previousFetchSubmissionDocuments, submissionDocuments);
        }
    }, [data]);

    // allow parent component to request a refetch :: 
    useEffect(() => {
        if (
            forceRefetchOfDocuments === true && 
            typeof forceRefetchOfDocumentsSetter === "function"
        ) {
            // set this to false so we don't loop or trigger more fetches than we actually need :: 
            forceRefetchOfDocumentsSetter(false);
            refetch();
        }
    }, [forceRefetchOfDocuments]); 

    if (error) { 
        // stop polling -- otherwise could spam user w/ multiple error messages 
        stopPolling();
        return handleGraphQLError(error);
    } 

    return (
        <>
            <h2>Documents</h2>
            { subtitle && typeof subtitle === "string" 
                ? <h3>{ subtitle }</h3> 
                : null
            }
            { shortened !== true && 
                <p>The following documents have been uploaded in relation to this submission. Each of the documents will be individually reviewed to ensure the documents meet the specified criteria. When a document has completed review and all criteria are met, the submitter can finalize the document. When all documents in a submission are finalized, the submission process will be completed.</p>
            }
            <ScrollableTable
                loading={loading}
                onChange={({ current, pageSize }, _filters, { columnKey, order }) => {
                    setPage(current);
                    setPageSize(pageSize);
                    setSortOn(columnKey);
                    setSortBy(order);
                }}
                pagination={{
                    defaultCurrent: page,
                    defaultPageSize: pageSize
                }}
                rowKey="id"
                id="documentsTable"
                noBorders={true}
                columns={handleControlledDefaultSortOrder({ 
                    sortOn, 
                    sortBy, 
                    customHandler: ({ key }) => key === sortOn 
                }, [
                    {
                        title: "ID",
                        key: "specifier",
                        sorter: (a, b) => a.specifier.trim().toLowerCase() > b.specifier.trim().toLowerCase() ? 1 : -1,
                        sortDirections: ["ascend", "descend", "ascend"],
                        render: ({ id, specifier }) => {
                            return (
                                <PCDULink 
                                    to={`/submissions/${submissionId}/documents/${id}`}
                                    newBreadcrumbs={newBreadcrumbs}
                                >
                                    {specifier}
                                </PCDULink>
                            );
                        }
                    },
                    {
                        title: "Name",
                        key: "name",
                        dataIndex: ["documentNewestVersion", "name"],
                        width: "20%",
                        sorter: (a, b) => a.nickname.trim().toLowerCase() > b.nickname.trim().toLowerCase() ? 1 : -1,
                        sortDirections: ["ascend", "descend", "ascend"]
                    },
                    {
                        title: "Sub-Submission Type (Child)",
                        key: "documentType",
                        dataIndex: "documentType",
                        render: (type) => type?.name,
                        sorter: (a, b) => a.documentType &&  a?.documentType.name.trim().toLowerCase() > b?.documentType.name.trim().toLowerCase() ? 1 : -1,
                        sortDirections: ["ascend", "descend", "ascend"]
                    },
                    {
                        title: "Upload Status",
                        key: "uploadStatus",
                        dataIndex: "S3UploadStatus",
                        sorter: (a, b)=> getS3StatusText(a.S3UploadStatus).localeCompare(getS3StatusText(b.S3UploadStatus)),
                        sortDirections: ["ascend", "descend", "ascend"],
                        render: S3UploadStatus
                    },
                    {
                        title: "Error Message",
                        key: "errorMessage",
                        dataIndex: "S3UploadStatus",
                        sorter: (a, b)=> getS3ErrorText(a.S3UploadStatus).localeCompare(getS3ErrorText(b.S3UploadStatus)),
                        sortDirections: ["ascend", "descend", "ascend"],
                        render: S3ErrorMessage
                    },
                    userPermCreateSubmission || shortened === true ? null : {
                        title: "Open Issues",
                        key: "issueCount",
                        render: ({ issues }) => {
                            return isNotEmpty(issues) 
                                ? issues.filter(
                                    ({ status }) => status === "Active"
                                ).length 
                                : 0;
                        }
                    },
                    !userIsSubmitter && submissionType?.dqFlag === true 
                        ? {
                            title: "DQ Status",
                            key: "dqStatus",
                            render: ({ documentNewestVersion }) => {
                                return <DocumentDQStatus 
                                    document={documentNewestVersion} 
                                    loading={loading}
                                    showIconOnSuccess={true}
                                />;
                            }
                        } 
                        : null,
                    submissionType?.dqFlag === true 
                        ? {
                            title: "Report Template Version",
                            key: "reportTemplateVersion",
                            dataIndex: ["documentNewestVersion", "reportTemplateVersion"]
                        } 
                        : null,
                    shortened === true ? null : {
                        title: " # Versions",
                        key: "versionCount",
                        render: ({ documentNewestVersion }) => `${parseInt(documentNewestVersion.specifier) + 1}`
                    },
                    !userPermCreateSubmission ? null : {
                        title: "Action",
                        render: (document) => {
                            //  should probably change this later to make it stricter? 
                            //  for instance, should only allow re-upload when the document's S3 upload status is "error" ?? 
                            const allowReupload = document.S3UploadStatus === S3_UPLOAD_STATUS.error;
                            const download = document.S3UploadStatus === S3_UPLOAD_STATUS.uploaded;
                            return allowReupload 
                                ? <Button
                                    className="ant-btn ant-btn-primary"
                                    style={{
                                        margin: "20px 0 20px 0" 
                                    }}
                                    onClick={() => {
                                        setReuploadDocumentModal(document);
                                    }}
                                >
                                    Re-Upload
                                </Button>
                                : <DownloadButton 
                                    style={{ margin: "20px 0 20px 0 "}}
                                    display={download}
                                    document={document.documentNewestVersion}
                                />;
                        }
                    }
                ].filter(col => col))}
                dataSource={submissionDocuments}
            />
            { userPermCreateSubmission && 
                <>
                    <Modal
                        title="Re-Upload Submission Document"
                        destroyOnClose={true}
                        maskClosable={false}
                        closable={false}
                        open={!!reuploadDocumentModal}
                        okText="Save"
                        onCancel={() => {
                            setReuploadDocumentModal(null);
                        }}
                        afterClose={async () => {
                            if (typeof afterClose === "function") {
                                await afterClose();
                            }
                            setSubmissionDocumentsToReupload({});
                        }}
                        okButtonProps={{
                            disabled: Object.keys(submissionDocumentsToReupload).length === 0 || reUploadSubmissionDocumentProgress.loading || submissionDocumentsToReupload.size === 0
                        }}
                        cancelButtonProps={{
                            disabled: reUploadSubmissionDocumentProgress.loading
                        }}
                        onOk={async (e) => {
                            e.preventDefault();

                            const originalDocumentData = reuploadDocumentModal;
                            const newUpload = submissionDocumentsToReupload;

                            const DocumentInput = {
                                documentId: originalDocumentData.documentNewestVersion.id,
                                documentName: newUpload.name,
                                fileSize: (newUpload.size).toString(),
                                file: newUpload.originFileObj
                            };

                            const success = await handleMutation(
                                reUploadSubmissionDocument({
                                    variables: {
                                        DocumentInput  
                                    }
                                })
                            );

                            if (success) {
                                refetch();
                                setReuploadDocumentModal(null);
    
                                if (typeof afterSuccessfulUpload === "function") {
                                    afterSuccessfulUpload();
                                }
                            } else {
                                errorMessage();
                            }
                        }}
                    >
                        <Spin size="large" spinning={reUploadSubmissionDocumentProgress.loading}>
                            <DocumentUploadModal
                                uploadFileMetaData={submissionDocumentsToReupload}
                                setUploadFileMetaData={setSubmissionDocumentsToReupload}
                                maxDocumentCount={1}
                                acceptingFileTypeList={SUBMISSION_FILE_EXTENSION_ARRAY}
                            />
                        </Spin>
                    </Modal>
                </>
            }
        </>
    );
};
