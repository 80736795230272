import { useQuery, gql } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Select } from "antd";

export const SubmissionIdInput = ({
    submissionTypeIDs,
    onChangeHandler, 
    FormInstance,
    Item, 
    initialValue
}) => {
    const [searchSpecifier, setSearchSpecifier] = useState("");
    const [disableDropdown, setDisableDropdown] = useState(true);
    const [shouldFetch, setShouldFetch] = useState(false);
    const [submissionTypesFilter, setSubmissionTypesFilter] = useState([]);
    const [submissionFilter, setSubmissionFilter] = useState([]);

    // helper fn :: 
    const deselectInvalidSubmissions = (submissions) => {
        const { getFieldValue, setFieldsValue } = FormInstance;
        if (submissions.length === 0) {
            setFieldsValue({ "submissionFilter": [] });
        } else {
            const selectedSubmissions = getFieldValue("submissionFilter") ?? [];
            const validSubmissionsObject = submissions.reduce((validSubs, submission) => {
                validSubs[submission.id] = true;
                return validSubs;
            }, { all: true });
            const validSubmissions = selectedSubmissions.filter(submissionId => validSubmissionsObject[submissionId] === true);
            setFieldsValue({ "submissionFilter": validSubmissions });
        }
    };

    const submissionsQuery = gql`
        query ExportTablesSubmissionsQuery (
            $submissionTypesFilter: [ID]
            $fetchAll: Boolean
            $sortOn: String 
            $sortBy: String
        ) {
            submissionsList (
                submissionTypesFilter: $submissionTypesFilter
                fetchAll: $fetchAll
                sortOn: $sortOn,
                sortBy: $sortBy
            ) {
                submissions {
                    id
                    specifier
                }
                count
            }
        }
    `;

    const { data, loading } = useQuery(submissionsQuery, {
        variables: {
            specifierSearch: searchSpecifier,
            submissionTypesFilter: submissionTypesFilter,
            fetchAll: true,
            sortOn: "specifier",
            sortBy: "ascend"
        },
        fetchPolicy: "no-cache",
        skip: !shouldFetch
    });

    const submissions = data?.submissionsList?.submissions ?? [];

    useEffect(() => {
        // regardless of anything else, we want to reset search specifier :: 
        setSearchSpecifier("");

        if (submissionTypeIDs && submissionTypeIDs.length > 0) {
            if (!submissionTypeIDs.includes("all")) {
                setShouldFetch(true);
                setSubmissionTypesFilter(submissionTypeIDs);
                setSubmissionFilter([]);             
            } else {
                setShouldFetch(false);
                setSubmissionFilter([{ label: "-- All --", value: "all" }]);
            }
        } 

        if (submissionTypeIDs === undefined || (submissionTypeIDs && submissionTypeIDs.length <= 0)) {
            FormInstance?.setFieldsValue?.({ "submissionFilter": [] });
            setDisableDropdown(true);
            setSubmissionFilter([]);
            setSubmissionTypesFilter([]);
            setShouldFetch(false);
        } else {
            setDisableDropdown(false);
        }
    }, [submissionTypeIDs]);

    useEffect(() => {
        if (submissions.length > 0) {
            setSubmissionFilter([{ label: "-- All --", value: "all" }].concat(submissions.map(({ id, specifier }) => {
                return {
                    label: specifier,
                    value: id
                };
            })));
        }
        if (!loading && data) {
            deselectInvalidSubmissions(submissions);
        }
    }, [submissions]);


    return (
        <Item
            label="Submission ID"
            name="submissionFilter"
            rules={[{ required: true, message: "Please enter Submission ID" }]}
            initialValue={initialValue}
        >
            <Select
                onChange={onChangeHandler}
                mode="multiple"
                disabled={disableDropdown}
                style={{ width:"'100%" }}
                placeholder="Please select Submission Specifier"
                showSearch={true}
                searchValue={searchSpecifier}
                allowClear
                onSearch={setSearchSpecifier}
                options={submissionFilter}
                filterOption={(input, option) => {
                    if (input.trim()) {
                        return option.label.toLowerCase().includes(input.trim().toLowerCase());
                    } else {
                        return true;
                    }
                }}
            />
        </Item>
    );
};