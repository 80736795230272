import React from "react";
import { Tag } from "antd";
import { ROLE_DISPLAY_CONVERSION } from "../../constants/index.js";
import { ScrollableTable } from "../common/ScrollableTable/index.js";
import { PCDULink } from "../common/PCDULink/index.js";
import { PersistentState } from "../../utils/PersistentState.js";
import { handleControlledDefaultSortOrder } from "../../utils/handleControlledDefaultSortOrder.js";

const { usePersistentState } = PersistentState();

const filterForReviewers = (reviews) => {
    if (!Array.isArray(reviews)) {
        return [];
    } else {
        const ids = {};
        const reviewsLastModifiedFirst = [...reviews].sort((a, b) => b.modifiedAt - a.modifiedAt);
        return reviewsLastModifiedFirst.filter(({ reviewer }) => {
            const key = reviewer.id;
            if (ids[key]) {
                return false;
            } else {
                ids[key] = true;
                return true;
            }
        });
    }
};

export const SubmissionReviewersTable = ({ 
    userIsSubmitter, 
    newBreadcrumbForLinks,
    submission 
}) => {

    const [sortOn, setSortOn] = usePersistentState("roleSpecifier");
    const [sortBy, setSortBy] = usePersistentState("ascend");
    const [page, setPage] = usePersistentState(1);
    const [pageSize, setPageSize] = usePersistentState(10);

    // we display reviewers table for all users who are not submitters: 
    const shouldRender = !userIsSubmitter || !submission;

    return shouldRender !== true ? <></> : (
        <>
            <h2>Reviewers</h2>
            <ScrollableTable
                id="reviewersTable"
                noBorders={true}
                rowKey={({ reviewer }) => reviewer?.id}
                pagination={{
                    defaultCurrent: page, 
                    defaultPageSize: pageSize
                }}
                onChange={({ current, pageSize }, _filters, { field, order }) => {
                    setSortOn(field);
                    setSortBy(order);
                    setPage(current);
                    setPageSize(pageSize);
                }}
                dataSource={filterForReviewers(submission?.submissionNewestVersion?.reviews)}
                columns={handleControlledDefaultSortOrder({ sortOn, sortBy }, [
                    {
                        title: "Name",
                        key: "reviewer",
                        dataIndex: "reviewer",
                        sorter: (a, b) => (a.reviewer && a.reviewer.name ? a.reviewer.name.trim().toLowerCase() : "") > (b.reviewer && b.reviewer.name ? b.reviewer.name.trim().toLowerCase() : "") ? 1 : -1,
                        sortDirections: ["ascend", "descend", "ascend"],
                        render: ({ name, id }) => <PCDULink 
                            to={`/users/${id}`} 
                            newBreadcrumbs={newBreadcrumbForLinks}
                        >
                            { name }
                        </PCDULink>
                    },
                    {
                        title: "Review Status",
                        dataIndex: "completed",
                        sorter: (a, b) => (a.completed ? "completed" : "inprogress") > (b.completed ? "completed" : "inprogress") ? 1 : -1,
                        sortDirections: ["ascend", "descend", "ascend"],
                        render: (completed) => {
                            return (
                                completed ? "Completed" : "In Progress"
                            );
                        }
                    },
                    {
                        title: "Role",
                        key: "roleSpecifier",
                        sorter: (a, b) => (a.roleSpecifier ? a.roleSpecifier.trim().toLowerCase() : "") > (b.roleSpecifier ? b.roleSpecifier.trim().toLowerCase() : "") ? 1 : -1,
                        defaultSortOrder: "ascend",
                        sortDirections: ["ascend", "descend", "ascend"],
                        render: ({ roleSpecifier }) => ROLE_DISPLAY_CONVERSION[roleSpecifier]
                    },
                    {
                        title: `Issues Raised (Vers. ${submission.submissionNewestVersion.specifier + 1})`,
                        key: "issuesRaised",
                        render: ({ reviewer }) => {
                            return submission.issues.filter(({ raisedInReview }) => {
                                return (reviewer.id === raisedInReview.reviewer.id
                                    &&
                                    submission.submissionNewestVersion.specifier === raisedInReview.submissionVersion.specifier
                                );
                            }).length;
                        }
                    },
                    {
                        title: "Email",
                        key: "Email",
                        dataIndex: ["reviewer", "email"]
                    },
                    {
                        title: "Reviewer Status",
                        dataIndex: "deleted",
                        render: (deleted) => {
                            const displayText = (deleted ? "Inactive" : "Active");
                            const color = deleted ? "volcano" : "geekblue";
                            return (
                                <Tag color={color}>
                                    {displayText}
                                </Tag>
                            );
                        }

                    }
                ])}
            />
        </>
    );
};