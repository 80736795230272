import { gql } from "@apollo/client";

export const usersQuery = gql`
    query UsersQuery (
        $limit: Int
        $offset: Int
        $organizationIds: [ID]
        $assignmentRoleIds: [ID]
        $activeFilter: Boolean
        $serviceAccountFilter:[Boolean]
        $nameSearch: String
        $ncidSearch: String
        $emailSearch: String
        $sortOn: String
        $sortBy: String
    ) {
        usersList(
            limit: $limit
            offset: $offset
            organizationFilter: $organizationIds
            nameSearch: $nameSearch
            ncidSearch: $ncidSearch
            emailSearch: $emailSearch
            activeFilter: $activeFilter
            serviceAccountFilter: $serviceAccountFilter
            assignmentRoleIds: $assignmentRoleIds
            sortOn: $sortOn
            sortBy: $sortBy
        ) {
            users {
                id
                email
                active
                ncid
                name
                serviceAccount
                organizations {
                    id
                    name
                }
                roles {
                    id
                    specifier
                }
                assignments {
                    role {
                        id
                        specifier
                    }
                }
            }
            count
        }
        roles {
            id
            specifier
        }
    }
`;