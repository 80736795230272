import React from "react";
import { Table } from "antd";
import { formatDate } from "../../utils/functions.js";
import { ISSUE_REACTION } from "../../constants/index.js";
import { PCDULink } from "../common/PCDULink/index.js";
import {handleControlledDefaultSortOrder} from "../../utils/handleControlledDefaultSortOrder.js";
import { PersistentState } from "../../utils/PersistentState.js";
import safeTableSorter from "../../utils/safeTableSorter.js";

const { usePersistentState } = PersistentState();

export const ReactionsTable = ({ 
    persistState = false,
    issue, 
    newBreadcrumbs 
}) => {

    const [sortOn, setSortOn] = usePersistentState("disposition", persistState);
    const [sortBy, setSortBy] = usePersistentState("ascend", persistState);
    const [currentPage, setCurrentPage] = usePersistentState(1, persistState);
    const [pageSize, setPageSize] = usePersistentState(10, persistState);

    const startingPage = currentPage;
    const startingPageSize = pageSize;
    
    return Array.isArray(issue.reactions) 
        ? <div style={{marginTop: "20px"}}>
            <h3> Reactions </h3>
            <Table 
                id="reactions-table"
                rowKey={"reviewerId"}
                dataSource={issue.reactions}
                columns={ handleControlledDefaultSortOrder({sortOn, sortBy},  [
                    {
                        title: "Disposition",
                        dataIndex: "disposition",
                        render: (text) => {
                            return typeof text === "string" ? text.toUpperCase() : "";
                        },
                        sorter: (a,b) => a.disposition.trim().toLowerCase() > b.disposition.trim().toLowerCase() ? 1 : -1,
                        sortDirections: ["ascend", "descend", "ascend"]
                    },
                    {
                        title: "Reviewer",
                        dataIndex: "reviewer",
                        sorter: safeTableSorter("reviewer.name"),
                        sortDirections: ["ascend", "descend", "ascend"],
                        render: ({id, name}) => {
                            return <PCDULink 
                                to={`/users/${id}`}
                                newBreadcrumbs={newBreadcrumbs}
                            >
                                {name}
                            </PCDULink>;
                        }
                    },
                    {
                        title: "Reviewed On",
                        dataIndex: "createdAt",
                        render: (dateString) => {
                            return formatDate(parseInt(dateString, 10));
                        },
                        sorter: (a, b) => {
                            return parseInt(a.createdAt) - parseInt(b.createdAt) ? 1 : -1;
                        },
                        sortDirections: ["ascend", "descend", "ascend"]
                    }
                ])}
                expandable={{
                    rowExpandable: (reaction) => reaction.comment && typeof reaction.comment === "string",
                    expandedRowRender: (reaction) => {
                        let title = "Disposition Comment From Reviewer";
                        if (reaction.disposition === ISSUE_REACTION.disagree) {
                            title = "Reason for Disagreement";
                        } else if (reaction.disposition === ISSUE_REACTION.agree) {
                            title = "Reason for Agreement";
                        }
                        return (
                            <div key={`expanded-${reaction.reviewerId}`}>
                                <h3>{title}</h3>
                                <p> {reaction.comment} </p>
                            </div>
                        );
                    }
                }}
                onChange={(pagination, _filters, { field, order })=>{
                    const { current, pageSize } = pagination;
                    setCurrentPage(current);
                    setPageSize(pageSize);
                    setSortOn(field);
                    setSortBy(order);
                }}
                pagination={{ 
                    defaultCurrent: startingPage, 
                    defaultPageSize: startingPageSize 
                }}
            />
        </div> 
        : null;
};