import React, { useState, useEffect, useContext } from "react";
import { Pagination } from "antd";
import { FileTextTwoTone } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder } from "@fortawesome/free-solid-svg-icons";
import { Table } from "../../common/Table/index.js";
import { UserContext } from "../../Application/UserContext.js";
import { formatTimeValue } from "../../../utils/functions.js";
import { displayFileSizeUnit } from "../../../utils/index.js";
import { LibraryDocumentsQuery, LibraryDocumentQuery } from "../queries.js";
import { useQuery } from "@apollo/client";
import { handleGraphQLError } from "../../../utils/errorHandling.js";
import { LibraryDocumentActions } from "../../common/LibraryDocumentActions/index.js";
import { DocumentUploadStatusActiveTracker } from "../../common/DocumentUploadStatusTracker/index.js";
import { PCDULink } from "../../common/PCDULink/index.js";
import { mergeDocumentData } from "../_helpers.js";
import { handleControlledDefaultSortOrder } from "../../../utils/handleControlledDefaultSortOrder.js";

const nullResult = <span> -- </span>;

export const LibrarySearchResults = ({ 
    searchData, 
    getFolderPathway, 
    resetFiltersFlag 
}) => { 
    const [sortOn, setSortOn] = useState("dateModified");
    const [sortBy, setSortBy] = useState("descend");
    const [documentsPage, setDocumentsPage] = useState(1);
    const [documentsPageSize, setDocumentsPageSize] = useState(10);
    const [updatedDocuments, setUpdatedDocuments] = useState([]);
    
    const documentsOffset = Math.max(0, ((documentsPage - 1) * documentsPageSize));

    const { data, previousData, error, loading, refetch } = useQuery(LibraryDocumentsQuery, {
        variables: {
            search: true,
            contractTypeId: "null",
            baseFolderFilter: searchData?.baseFolders ?? [],
            contractTypeFilter: searchData?.contractTypes ?? [],
            documentNameSearch: searchData?.name,
            documentsSortOn: sortOn,
            documentsSortBy: sortBy,
            documentsOffset,
            documentsLimit: documentsPageSize, 
            includeS3Metadata: true 
        },
        fetchPolicy: "no-cache"
    }); 

    useEffect(() => {
        setUpdatedDocuments([]);
    }, [data]);

    useEffect(() => {
        if (resetFiltersFlag === true) {
            setSortOn("dateModified");
            setSortBy("descend");
            setDocumentsPage(1);
            setDocumentsPageSize(10);
            setUpdatedDocuments([]);
        }
    }, [resetFiltersFlag]);

    const {
        userPermDownloadLibDoc,
        userPermDownloadAssociatedLibDoc,
        userPermUploadnModifyLibDoc,
        userIsSubmitter
    } = useContext(UserContext);

    // normalize fetched library documents w/ any updates since last refetch :: 
    const libraryDocuments = loading 
        ? (previousData?.libraryDocuments ?? [])
        : mergeDocumentData(data?.libraryDocuments, updatedDocuments); 
    const documentCount = libraryDocuments[0]?.count ?? 0;
    const userCanDownloadDocuments = userPermDownloadLibDoc || userPermDownloadAssociatedLibDoc;

    if (error) {
        return handleGraphQLError(error);
    }

    return (
        <div>
            <Table 
                loading={loading} 
                columns={handleControlledDefaultSortOrder({ sortOn, sortBy }, [
                    {
                        title: "Name",
                        dataIndex: "name",
                        key: "name",
                        sorter: true,
                        sortDirections: ["ascend", "descend", "ascend"],
                        render: (name, { id }) => <PCDULink to={`/library/documents/${id}`}>
                            <FileTextTwoTone style={{ marginRight: "10px" }} />
                            { name }
                        </PCDULink>
                    },
                    {
                        title: "Size",
                        dataIndex: "fileSize",
                        key: "fileSize",
                        sorter: true,
                        sortDirections: ["ascend", "descend", "ascend"],
                        render: (fileSize) => {
                            return displayFileSizeUnit(fileSize);
                        }
                    },
                    {
                        title: "Last Modified",
                        dataIndex: "dateModified",
                        key: "dateModified",
                        sorter: true,
                        sortDirections: ["descend", "ascend", "descend"],
                        render: (dateModified) => {
                            const text = formatTimeValue(dateModified, true);
                            return (<span>{text}</span>);
                        }
                    },
                    {
                        title: "Author",
                        key: "author-name",
                        dataIndex: "authorName",
                        sorter: true,
                        sortDirections: ["ascend", "descend", "ascend"],
                        render: (name, record) => {
                            const nameToUse = record?.author?.name ?? name;
                            return !nameToUse ? nullResult : <span>{ nameToUse }</span>;
                        }
                    },
                    {
                        title: "Archived",
                        key: "active",
                        dataIndex: "active",
                        sorter: true,
                        sortDirections: ["ascend", "descend", "ascend"],
                        render: (active) => {
                            return active ? "No" : "Yes";
                        }
                    },
                    {
                        title: "Upload Status",
                        key: "uploadStatus",
                        dataIndex: "S3UploadStatus",
                        sorter: true,
                        render: (S3UploadStatus, { id, fileSize }) => {
                            return <DocumentUploadStatusActiveTracker
                                status={S3UploadStatus}
                                fileSize={fileSize}
                                fetchOptions={{
                                    variables: { 
                                        id, 
                                        includeS3Metadata: false 
                                    }, 
                                    fetchPolicy: "no-cache"
                                }}
                                query={LibraryDocumentQuery}
                                documentTypeName={"libraryDocument"}
                            />;
                        }
                    },
                    {
                        title: "Location",
                        dataIndex: "parentFolderName",
                        key: "parent-folder",
                        sorter: true,
                        sortDirections: ["ascend", "descend", "ascend"],
                        render: (name, { contractTypeCategoryName, parentFolderId, baseFolderName }) => {
                            const nameToUse = contractTypeCategoryName ? contractTypeCategoryName : name;
                            const baseFolderNameToUse = baseFolderName ? ` (${baseFolderName})` : "";
                            if (!nameToUse) {
                                return nullResult;
                            } else if (typeof getFolderPathway !== "function") {
                                return <span>{nameToUse}{baseFolderNameToUse}</span>;
                            } else {
                                return (
                                    <>
                                        <span
                                            style={{
                                                color: "#1890ff",
                                                cursor: "pointer"
                                            }}
                                            onClick={() => {
                                                getFolderPathway({
                                                    variables: {
                                                        folderId: parentFolderId
                                                    }
                                                });
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faFolder} style={{ marginRight: "10px" }} className="far" />
                                            {nameToUse}
                                        </span>
                                        { baseFolderNameToUse ? <span>{baseFolderNameToUse}</span> : null }
                                    </>
                                );
                            }
                        }
                    },
                    {
                        title: "Contract Type",
                        dataIndex: "contractTypeName",
                        key: "contract-type",
                        sorter: true,
                        sortDirections: ["ascend", "descend", "ascend"],
                        render: (name) => {
                            return !name ? nullResult : <span>{name}</span>;
                        }
                    },
                    (!userCanDownloadDocuments ? null : {
                        title: "Actions",
                        key: "library-documents-actions",
                        render: (_, record) => <LibraryDocumentActions 
                            isSubmitter={userIsSubmitter}
                            record={record}
                            userCanUpload={userPermUploadnModifyLibDoc}
                            reportUpdatedDocument={(updatedDocument) => { 
                                const updatedDocumentToUse = updatedDocument && typeof updatedDocument === "object" ? updatedDocument : {};
                                const latestDocuments = mergeDocumentData(libraryDocuments, [updatedDocumentToUse]); 
                                setUpdatedDocuments(latestDocuments);
                            }} 
                            onReuploadFailure={() => {
                                refetch().finally(() => setUpdatedDocuments([]));
                            }}
                        />
                    })
                ].filter(col => col))}
                dataSource={libraryDocuments}
                rowKey="id"
                pagination={false}
                onChange={(_pagination, _filters, { field, order }) => {
                    setSortOn(field);
                    setSortBy(order);
                }}
            />
            <Pagination
                style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "20px 0 50px 0"
                }}
                showSizeChanger
                onShowSizeChange={(targetPage, pageSize) => {
                    setDocumentsPage(targetPage);
                    setDocumentsPageSize(pageSize);
                }}
                onChange={(targetPage, pageSize) => {
                    setDocumentsPage(targetPage);
                    setDocumentsPageSize(pageSize);
                }}
                current={documentsPage}
                defaultCurrent={documentsPage}
                pageSize={documentsPageSize}
                defaultPageSize={documentsPageSize}
                pageSizeOptions={["10", "25", "50", "100"]}
                total={documentCount}
            />
        </div>
    );  
};