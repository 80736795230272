import React from "react";
import { SUBMISSION_STATUS_PENDING_FAILED } from "../../../constants/index.js";
import { PCDULink } from "../PCDULink/index.js";

export const HeaderDetail = ({
    title,
    text,
    to,
    linkTitle, 
    linkIcon = null,
    linkState = {},
    newBreadcrumbs,
    textClassName = ""
}) => {
    return (to ? 
        <>
            <div className="header__label--sae">
                {title}
            </div>
            <div
                className="editable__input header__field--tmp"
            >
                <span>{text}
                    <PCDULink
                        to={to}
                        state={linkState}
                        newBreadcrumbs={newBreadcrumbs}
                    >
                        { linkIcon ? linkIcon : null }
                        {linkTitle}
                    </PCDULink>
                </span>
            </div>
        </>
        : (text === SUBMISSION_STATUS_PENDING_FAILED.failed ? 
            <>
                <div className="header__label--sae">
                    {title}
                </div>
                <div
                    className="editable__input header__field--tmp" 
                    style={{color: "red", fontWeight: "bold"}}
                >
                    <span >{text}
                        {linkTitle}
                    </span>
                </div>
            </>
            :
            <>
                <div className="header__label--sae">
                    {title}
                </div>
                <div
                    className="editable__input header__field--tmp" 
                >
                    <span className={textClassName}>
                        {text}
                        {linkTitle}
                    </span>
                </div>
            </>)
    );
};
