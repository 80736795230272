export * from "./functions.js";

export { dateable } from "./dateable.js";

export { filterable } from "./filterable.js";

export { searchable } from "./searchable.js";

const smallWords = ["of", "and", "to", "or", "for", "in", "by", "on", "the"];
export const SUBMISSION_FILE_EXTENSION_ARRAY = ["pdf", "doc", "docx", "xls", "xlsx", "txt", "dat", "mms", "zip","ppt","gz","mp4","csv","pptx"];
export const SUBMISSION_FILE_EXTENSION_STRING = ".pdf,.doc,.docx,.xls,.txt,.dat,docx,.mms,.zip,.ppt,.gz,.mp4,.csv,.pptx,.xlsx";
export const LIBRARY_FILE_EXTENTION_ARRAY = ["pdf", "doc", "xls", "xlsx", "txt", "dat", "docx", "mms", "zip", "mp4","ppt","gz","mp4","csv","xlsm","mvm","pptx"];
export const LIBRARY_FILE_EXTENTION_STRING = ".pdf,.doc,.xls,.xlsx,.txt,.dat,.docx,.mms,.zip,.mp4,.ppt,.gz,.mp4,.csv,.xlsm,.mvm,.pptx";

export const isSmallWord = (word) => {
    return smallWords.includes(word.toLowerCase());
};

export const titleCaseWord = (word) => {
    return `${word[0].toUpperCase()}${word.slice(1)}`;
};

export const kebabToTitleCase = (string) => {
    return string
        .split("-")
        .map((word) => {
            if (isSmallWord(word)) {
                return word;
            }

            return titleCaseWord(word);
        })
        .join("-");
};

// check the uuid is valid
export const validateUuid = (maybeuuid) => {
    if (typeof maybeuuid !== "string") {
        return false;
    }

    // split what might be a uuit into parts
    const subparts = maybeuuid.split("-");
    // if the subpart is a valid hexadecimal number
    // return the length of that subpart
    const subpartLengths = subparts.map((subpart) => {
        return Number(`0x${subpart}0`) ? subpart.length : "";
    });

    // coerce array of lengths into a string to validate
    return subpartLengths.toString() === "8,4,4,4,12";
};

export const categoryNameStructure = (name) => {
    return name.toLowerCase();
};

export const deStructureCategoryName = (category) => {
    return category.toLowerCase().split("%20").join(" ");
};

export const validFileUploadedExtension = (file, fileTypeArray) => {
    if (file) {
        const fileExtension = file.name.split(".").pop();
        return fileTypeArray.find((ext) => ext === fileExtension) ? true : false;

    } else {
        return false;
    }
};

export const displayFileSizeUnit = (fileSizeInBytes) => {
    const numToString = new String(fileSizeInBytes);
    const fileSizeLength = numToString.length;

    if (fileSizeLength > 9) {
        return `${floatRepresentation(fileSizeInBytes / (1000 * 1000 * 1000), 1)} GB`;
    } else if (fileSizeLength > 6) {
        return `${floatRepresentation(fileSizeInBytes / (1000 * 1000), 10)} MB`;
    } else if (fileSizeLength > 3) {
        return `${floatRepresentation(fileSizeInBytes / 1000)} KB`;
    } else {
        return `${floatRepresentation(fileSizeInBytes)} bytes`;
    }
};

const floatRepresentation = (x, y) => {
    if (y) {
        return Math.round(x * y) / y;    
    }
    return Math.round(x);
};

export const safeJSONParse = (JSONString) => {
    try {
        return JSON.parse(JSONString);
    } catch (err) {
        console.log(err);
        return null;
    }
};

export const pollingTimeByFileSize = (fileSizeInBytes) => {
    const numToString = new String(fileSizeInBytes);
    const fileSizeLength = numToString.length;

    if (fileSizeLength > 9) {
        return 6000;
    } else if (fileSizeLength > 6) {
        return 4000;
    } else if (fileSizeLength > 3) {
        return 3000;
    } else {
        return 2000;
    }
};

export const isValidString = (str) => {
    return str && typeof str === "string";
};