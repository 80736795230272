import React, { useContext } from "react";
import { Divider } from "antd";
import moment from "moment";
import { UserContext } from "../Application/UserContext.js";
import { Main } from "../common/Main/index.js";
import { SUBMISSION_STATUS_LIST } from "../../constants/index.js";
import { SelectableDetail } from "../common/SelectableDetail/index.js";
import { SubmissionsTable } from "./submissions-table.js";
import { ToDosTable } from "./to-dos-table.js";
import { CalendarContent } from "./calendar-content.js";
import { createDueRange } from "./helpers.js"; 
import { PersistentState } from "../../utils/PersistentState.js";
import { ClearFiltersButton } from "../common/ClearFiltersButton/index.js";
import { useAutoFlag } from "../../utils/useAutoFlag.js";
import { usePreloadedData } from "../Application/PreloadedData/index.js";

const { usePersistentState } = PersistentState();

export const Calendar = () => {
    const { preloadedData } = usePreloadedData();

    const containerLoaded = Boolean(preloadedData);

    const {
        userPermCreateSubmission, 
        userIsReviewerOrNotifier,
        userPermViewContractDetail,
        userPermViewSubmissions,
        userPermViewAllEntries
    } = useContext(UserContext);

    const organizationCount = preloadedData?.submitterOrganizations ? preloadedData.submitterOrganizations.length : 0;
    const canFilterByOrganization = userIsReviewerOrNotifier || organizationCount > 1;

    const permissions = {
        canCreateSubmission: userPermCreateSubmission,
        userIsReviewerOrNotifier,
        canViewContractDetail: userPermViewContractDetail,
        canViewSubmissions: userPermViewSubmissions,
        canViewAllEntries: userPermViewAllEntries,
        canFilterByOrganization
    };
    
    const defaultSubmissionDueRange = createDueRange(moment(), "month");
    const applicableStatuses = [];

    if (userIsReviewerOrNotifier || userPermViewAllEntries) {
        applicableStatuses.push(SUBMISSION_STATUS_LIST.review, SUBMISSION_STATUS_LIST.final_version_review);
    }

    if (userPermCreateSubmission || userPermViewAllEntries) {
        applicableStatuses.push(SUBMISSION_STATUS_LIST.version, SUBMISSION_STATUS_LIST.final_requested);
    }

    const [shouldReset, setShouldReset] = useAutoFlag(false);
    const [categoryFilterChanged, setCategoryFilterChanged] = useAutoFlag(false);


    // persistent state :: 
    const [submissionDueRange, setSubmissionDueRange] = usePersistentState(defaultSubmissionDueRange);
    const [submissionTypesFilter, setSubmissionTypesFilter] = usePersistentState([]);
    const [organizationsFilter, setOrganizationsFilter] = usePersistentState([]);
    const [statusFilter, setStatusFilter] = usePersistentState([]);
    const [categoryFilter, setCategoryFilter] = usePersistentState([]);

    const handleCategoryFilterChange = (newFilters) => {
        setCategoryFilter(newFilters);
        setCategoryFilterChanged(true);
    };

    const categoriesLoaded = Array.isArray(preloadedData?.categories);
    const categories = categoriesLoaded ? preloadedData.categories : [];
    
    const calendarConstants = {
        submissionTypes: preloadedData?.submissionTypes ?? [],
        organizations: preloadedData?.submitterOrganizations ?? [],
        categories,
        organizationCount,
        applicableStatuses
    };

    // undefined or empty array for status filter? use all applicable statuses; otherwise, use status filter :: 
    const statusFilterForQuery = (!Array.isArray(statusFilter) || statusFilter.length === 0) 
        ? applicableStatuses 
        : statusFilter;

    return (
        <Main
            title="Calendar"
            breadcrumbs={["Calendar"]}
        >
            <div style={{ padding: "10px 0px 0px 0px", width: "30%" }}>
                <SelectableDetail
                    title={"Category Type"}
                    multiple={true}
                    showArrow={true}
                    loading={!categoriesLoaded}
                    blurOnSelectionId={"calendar-category-filter-dropdown"}
                    value={categoriesLoaded ? categoryFilter : undefined}
                    strict={true}
                    onValueUpdated={handleCategoryFilterChange}
                    options={categories.map(({ name, id }) => {
                        return {
                            key: id,
                            text: `${name}`,
                            value: id
                        };
                    })}
                />            
            </div>

            <ClearFiltersButton 
                clearFilters={() => {
                    setShouldReset(true);
                    setSubmissionDueRange(createDueRange(moment(), "month"));
                    setSubmissionTypesFilter([]);
                    setOrganizationsFilter([]);
                    setStatusFilter([]);
                    setCategoryFilter([]);
                }}
                styleToUse={{ marginBottom: "15px" }}
                filtersData={[
                    { currentValue: submissionDueRange, defaultValue: defaultSubmissionDueRange },
                    { currentValue: submissionTypesFilter, defaultValue: [] },
                    { currentValue: organizationsFilter, defaultValue: [] },
                    { currentValue: statusFilter, defaultValue: [] },
                    { currentValue: categoryFilter, defaultValue: [] } 
                ]}
            />

            <CalendarContent 
                shouldReset={shouldReset}
                containerLoaded={containerLoaded}
                permissions={permissions}
                submissionDueRange={submissionDueRange}
                setSubmissionDueRange={setSubmissionDueRange}
                submissionTypesFilter={submissionTypesFilter}
                organizationsFilter={organizationsFilter}
                statusFilter={statusFilterForQuery}
                categoryFilter={categoryFilter}
            />

            <Divider />

            { userPermViewSubmissions && 
                <SubmissionsTable 
                    categoryFilterChanged={categoryFilterChanged}
                    shouldReset={shouldReset}
                    containerLoaded={containerLoaded}
                    permissions={permissions}
                    constants={calendarConstants}
                    submissionDueRange={submissionDueRange}
                    categoryFilter={categoryFilter}
                    submissionTypesFilter={submissionTypesFilter}
                    setSubmissionTypesFilter={setSubmissionTypesFilter}
                    organizationsFilter={organizationsFilter}
                    setOrganizationsFilter={setOrganizationsFilter}
                    statusFilter={statusFilter}
                    setStatusFilter={setStatusFilter}
                />
            }

            <ToDosTable 
                categoryFilterChanged={categoryFilterChanged}
                shouldReset={shouldReset}
                containerLoaded={containerLoaded}
                permissions={permissions}
                constants={calendarConstants}
                submissionDueRange={submissionDueRange}
                categoryFilter={categoryFilter}
                submissionTypesFilter={submissionTypesFilter}
                setSubmissionTypesFilter={setSubmissionTypesFilter}
                organizationsFilter={organizationsFilter}
                setOrganizationsFilter={setOrganizationsFilter}
            /> 

            {/* necessary to have empty space at end of page */}
            <div style={{ height: "200px" }}></div>
        </Main>
    );
};