import { gql } from "@apollo/client";

export const contractTypesQuery = gql`
    query ContractTypesQuery (
        $limit: Int
        $offset: Int
        $nameSearch: String
        $descriptionSearch: String
        $specifierSearch: String
        $startDateFilter: [String]
        $endDateFilter: [String]
        $activeFilter: Boolean
        $sortOn: String
        $sortBy: String
    ) {
        contractTypes(
            limit: $limit
            offset: $offset
            nameSearch: $nameSearch
            descriptionSearch: $descriptionSearch
            specifierSearch: $specifierSearch
            startDateFilter: $startDateFilter
            endDateFilter: $endDateFilter
            activeFilter: $activeFilter
            sortOn: $sortOn,
            sortBy: $sortBy
        ) {
            id
            name
            specifier
            description
            startDate
            endDate
            currentVersion
            count
        }
    }
`;