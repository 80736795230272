import React from "react";
import { useParams } from "react-router-dom";
import { UsersChangelogsQuery } from "./queries.js";
import { ChangelogsTable } from "./changelogs-table.js";
import { PCDULink } from "../common/PCDULink/index.js";


export const UsersChangelogs = (({ 
    actions,
    roles,
    subjectData,
    setSubjectData 
}) => {
    const params = useParams();
    const currentPageSubjectId = params.id;
    const subjectBreadcrumb = {
        label: "User Changelogs",
        path: "/changelogs/user"
    };
    
    return (
        <div style={{minWidth: "900px"}}>
            <ChangelogsTable 
                subjectBreadcrumb={subjectBreadcrumb}
                subjectData={subjectData}
                setSubjectData={setSubjectData}
                filterOptions={{
                    actions,
                    roles
                }}
                exportFilename={"Users_Changelogs.csv"}
                query={UsersChangelogsQuery}
                subjectHeader={{label: "User (Changed)", key: "changedUserName" }} 
                subjectTransform={(changelog) => changelog?.subject?.name}
                subjectColumn={
                    {
                        title: "User (Changed)",
                        dataIndex: ["subject", "name"],
                        render: (highlightableText, { subject }) => {
                            return subject 
                                ? (<>
                                    <PCDULink 
                                        to={`/users/${subject.id}`}
                                        newBreadcrumbs={[ 
                                            { label: "Changelogs", path: "/changelogs" }, 
                                            subjectBreadcrumb
                                        ]}
                                    > 
                                        {highlightableText} 
                                    </PCDULink> 
                                    { currentPageSubjectId !== subject.id && 
                                        <div>
                                            <span>
                                                <PCDULink to={`/changelogs/user/${subject.id}`}> Changelogs </PCDULink>
                                            </span>
                                        </div> 
                                    }
                                </>)
                                : null;
                        }
                    }
                }
            />
        </div>
    );
});
