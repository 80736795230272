import { gql } from "@apollo/client";

export const LibraryDocumentS3Status = gql`
    query LibraryDocumentS3Status (
        $id: ID!, 
        $includeS3Metadata: Boolean 
    ) {
        libraryDocument (
            id: $id, 
            includeS3Metadata: $includeS3Metadata
        ) { 
            id 
            fileSize 
            dateModified 
            author {
                id 
                name 
            }
            name 
            S3UploadStatus 
            failedS3Fetch 
            S3Metadata 
        }
    }
`;
