import { isUndefinedOrNull } from "./index.js";

export const findApplicableCriterionVersionByTimestamp = (criterion, timestamp) => { 
    // return applicable criterion version, if there is one; else return undefined ::  
    const ts = parseInt(timestamp, 10);
    const criterionTs = parseInt(criterion?.createdAt, 10); 

    if (Number.isNaN(ts) || Number.isNaN(criterionTs)) {
        return;
    } else if (ts < criterionTs) {
        // if the submission version predates when the criterion was created, we can exclude it ::  
        return; 
    } else if (Array.isArray(criterion?.versions) && criterion.versions.length > 0) {
        
        // important note :: criteria versions are arranged oldest --> newest; the following logic depends on that ordering... 
        let foundVersion;
        const allVersions = [criterion, ...criterion.versions];
        for (let i = 0; i < allVersions.length - 1; i++) {
            const min = parseInt(allVersions[i].createdAt);
            const max = parseInt(allVersions[i + 1].createdAt);
            if (min < ts && ts <= max) {
                foundVersion = allVersions[i + 1];
                break;
            }
        } 

        const applicableVersion = foundVersion ?? criterion; 
        if (!applicableVersion.deleted) { 
            // return applicable version only if it was active (not archived) -- 
            // we do need to make sure we include the id and the specifier data from criterion itself:: 
            return {
                ...applicableVersion, 
                specifier: criterion.specifier, 
                id: criterion.id 
            };
        } 
    } else if (!criterion.deleted) { 
        // in production could be archived criteria that don't have versions -- these should be excluded :: 
        return criterion; 
    }
}; 


export const filterCriteriaVersionsByTimestamp = (allCriteria, timestamp) => { 
    if (isUndefinedOrNull(timestamp) || !Array.isArray(allCriteria)) {
        return []; 
    } else { 
        return allCriteria.map(criterion => findApplicableCriterionVersionByTimestamp(criterion, timestamp)).filter(Boolean);
    }
};
