import { gql } from "@apollo/client";

export const organizationsDetailQuery = gql`
    query OrganizationDetailQuery ($id: ID!) {
        organization(id: $id) {
            id
            name
            specifier
            deleted
            email
            primaryContactId
            primaryContact {
                id
                name
                email
            }
        }
        usersList(organizationFilter: [$id]) {
            users {
                id
                name
                email
                active
                attestationRoleName
                roles
                {
                    id
                    specifier
                }
            }
        }
        contractsList(organizationFilter: [$id]) {
            contracts {
                id
                specifier
                name
                startDate
                endDate
                contractType {
                    id
                    name
                }
            }
        }
    }
`;