import React, { useState, useContext ,useEffect} from "react";
import { Button, Form, message,Checkbox } from "antd";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { UserContext } from "../Application/UserContext.js";
import { PCDULink } from "../common/PCDULink/index.js";
import { Main } from "../common/Main/index.js";
import { NoPermission } from "../common/NoPermission/index.js";
import { EditableDetail } from "../common/EditableDetail/index.js";
import { CREATE_USER } from "../../constants/mutations.js";
import { ROLE_DISPLAY_CONVERSION } from "../../constants/index.js";
import { handleMutation } from "../../utils/errorHandling.js";

const SAVE_BUTTON_FIELDS = {
    ncid: true,
    email: true,
    name: true,
    attestationRoleName: true
};

const showSaveButton = (modified) => {
    if (modified && typeof modified === "object") {
        return Object.keys(modified).some(key => SAVE_BUTTON_FIELDS[key]);
    } else {
        return false;
    }
};


export const CreateUser = () => {
    const navigate = useNavigate();
    const [modified, setModified] = useState({});
    const [serviceAccount, setServiceAccount] = useState(false); 
    const [createUser] = useMutation(CREATE_USER);
    const [userDetailForm] = Form.useForm();

    const {
        userPermCreateUsers,
        userPermEditUsers 
    } = useContext(UserContext);

    useEffect(() => {
        if (serviceAccount) {
            setModified({
                ...modified,
                ncid : null,
                email : null
            });
        }
    }, [serviceAccount]);

    if (!userPermCreateUsers) {
        return <NoPermission />;
    }

    const details = (
        <div id="details">
            <Form name="userDetails" form={userDetailForm}>
                {[
                    {
                        title: "Display Name",
                        key: "name",
                        required: true
                    },
                    {
                        title: "NCID",
                        key: "ncid",
                        required: !serviceAccount 
                    },
                    {
                        title: "Email",
                        key: "email",
                        additionalRules: [{type: "email", message: "Should be a valid email"}],
                        required: !serviceAccount
                    }
                ].map(({ title, key, additionalRules, required }) => {
                    return (
                        <div key={`editable-user-details-${key}`}>
                            <EditableDetail
                                title={title}
                                inputId={key}
                                required={required}
                                additionalRules={additionalRules}
                                readOnly={!userPermEditUsers || !required }
                                value={modified[key]}
                                onValueUpdated={(e) => {
                                    setModified({
                                        ...modified,
                                        [key]: e.target.value
                                    });
                                }}
                            />
                        </div>
                    );
                })}
                { userPermEditUsers && showSaveButton(modified) 
                    ? <Form.Item>
                        <Button
                            type="primary"
                            onClick={async () => {
                                try {

                                    await userDetailForm.validateFields();
                                    const newUser = {
                                        name: modified.name,
                                        email: modified.email,
                                        serviceAccount: serviceAccount,
                                        ncid: modified.ncid,
                                        attestationFlag: (modified.attestationRoleName && (ROLE_DISPLAY_CONVERSION[modified.attestationRoleName] != (ROLE_DISPLAY_CONVERSION.SUBMITTER_NONE))) ? true : false,
                                        attestationRoleName: modified.attestationRoleName
                                    };

                                    const id = await handleMutation(
                                        createUser({
                                            variables: {
                                                newUser
                                            }
                                        })
                                    );

                                    if (id && typeof id === "string") {
                                        setModified({});
                                        navigate(`/users/${id}`);
                                    }
                                } catch (err) {
                                    message.error({
                                        content: "Please correct form errors and try again.",
                                        style: {
                                            marginTop: "10vh"
                                        }
                                    });
                                }
                            }}
                        >
                            Save Changes
                        </Button>
                    </Form.Item>
                    : null 
                }
            </Form>
        </div>
    );

    return (
        <Main
            title={<div>
                <h1>Create a User</h1><br></br>
                <Form.Item>
                    <Checkbox
                        style={{ marginBottom: "12px" }}
                        onChange={e => {
                            setServiceAccount(e.target.checked);
                        }}
                        checked={serviceAccount}
                    >
                        Service Account
                    </Checkbox>
                </Form.Item>
            </div>}
            details={details}
            breadcrumbs={[<PCDULink tooltip={"All Users"} to="/users">Users</PCDULink>]}
        >
        </Main>
    );
};