import React, { useContext } from "react";
import { UserContext } from "../Application/UserContext.js";
import { 
    CheckCircleOutlined,
    ClockCircleOutlined, 
    CloudUploadOutlined, 
    UnorderedListOutlined, 
    WarningOutlined
} from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArchive, faFilePen} from "@fortawesome/free-solid-svg-icons";
import { PCDULink } from "../common/PCDULink/index.js";

const getSubmissionOption = (key, options) => {
    const defaultOptions = { resetBreadcrumbs: true, resetPersistentState: true };
    const optionsToUse = options ? { ...defaultOptions, ...options } : defaultOptions; 
    const { resetBreadcrumbs, resetPersistentState } = optionsToUse;

    switch (key) {
        case "submissions": 
            return {
                key: "submissions",
                icon: <UnorderedListOutlined />,
                label: <PCDULink 
                    text={"All Submissions"}
                    to={"/submissions"}
                    resetPersistentState={resetPersistentState}
                    resetBreadcrumbs={resetBreadcrumbs}
                >
                    All Submissions
                </PCDULink>
            };
        case "review":
            return {
                key: "review", 
                icon: <FontAwesomeIcon icon={faFilePen} />,
                label: <PCDULink 
                    text={"Submissions to Review"}
                    to={"/submissions/review"} 
                    resetPersistentState={resetPersistentState}
                    resetBreadcrumbs={resetBreadcrumbs}
                >
                    Submissions to Review
                </PCDULink>
            };
        case "fix":
            return {
                key: "fix", 
                icon: <CloudUploadOutlined />,
                label: <PCDULink 
                    text={"Submissions to Re-Upload"}
                    to={"/submissions/fix"} 
                    resetPersistentState={resetPersistentState}
                    resetBreadcrumbs={resetBreadcrumbs}
                >
                    Submissions to Re-Upload
                </PCDULink>
            };
        case "current": 
            return {
                key: "current", 
                icon: <ClockCircleOutlined />,
                label: <PCDULink 
                    text={"Current Submissions"}
                    to={"/submissions/current"} 
                    resetPersistentState={resetPersistentState}
                    resetBreadcrumbs={resetBreadcrumbs}
                >
                    Current Submissions
                </PCDULink>
            };
        case "finalized":
            return {
                key: "finalized", 
                icon: <CheckCircleOutlined />,
                label: <PCDULink 
                    text={"Finalized Submissions"}
                    to={"/submissions/finalized"} 
                    resetPersistentState={resetPersistentState}
                    resetBreadcrumbs={resetBreadcrumbs}
                >
                    Finalized Submissions
                </PCDULink>
            };
        case "archived":
            return {
                key: "archived", 
                icon: <FontAwesomeIcon icon={faArchive} />,
                label: <PCDULink 
                    text={"Archived Submissions"}
                    to={"/submissions/archived"} 
                    resetPersistentState={resetPersistentState}
                    resetBreadcrumbs={resetBreadcrumbs}
                >
                    Archived Submissions
                </PCDULink>
            };
        case "pending-failed":
            return {
                key: "pending-failed", 
                icon: <WarningOutlined />,
                label: <PCDULink 
                    text={"Pending & Failed Submissions"}
                    to={"/submissions/pending-failed"}
                    resetPersistentState={resetPersistentState}
                    resetBreadcrumbs={resetBreadcrumbs}
                >
                    Pending & Failed Submissions
                </PCDULink>
            };
    }
};

export const SubmissionOptionsSortedByRole = (options) => {
    const {
        userIsContractAdmin, 
        userIsSystemAdmin, 
        userIsSubmitter,
        userIsReviewerOrNotifier
    } = useContext(UserContext);

    if (userIsContractAdmin || userIsSystemAdmin) {
        return [
            getSubmissionOption("current", options), 
            getSubmissionOption("archived", options), 
            getSubmissionOption("pending-failed", options), 
            getSubmissionOption("finalized", options), 
            getSubmissionOption("submissions", options)
        ];
    } else if (userIsReviewerOrNotifier) {
        return [
            getSubmissionOption("review", options), 
            getSubmissionOption("current", options), 
            getSubmissionOption("archived", options), 
            getSubmissionOption("pending-failed", options), 
            getSubmissionOption("finalized", options), 
            getSubmissionOption("submissions", options)
        ];
    } else if (userIsSubmitter) {
        return [
            getSubmissionOption("fix", options), 
            getSubmissionOption("current", options), 
            getSubmissionOption("archived", options), 
            getSubmissionOption("pending-failed", options), 
            getSubmissionOption("finalized", options), 
            getSubmissionOption("submissions", options)
        ];
    } else {
        return [
            getSubmissionOption("submissions", options),
            getSubmissionOption("current", options), 
            getSubmissionOption("finalized", options), 
            getSubmissionOption("archived", options), 
            getSubmissionOption("pending-failed", options) 
        ];
    }
};

export const getSubmissionLink = (key, options) => {
    return getSubmissionOption(key, options).label;
};

export const getDefaultPageLink = (options) => {
    return SubmissionOptionsSortedByRole(options)[0].label;
};