import { Modal } from "antd";

const handleBadResponse = (response) => {
    if (response.status === 401) {
        Modal.error({
            title: "File could not be retrieved",
            content: "You are not authorized to retrieve this file."
        });
    } else if (response.status === 404) {
        Modal.error({
            title: "File could not be retrieved",
            content: "File was not found."
        });
    } else {
        Modal.error({
            title: "File could not be retrieved",
            content: "An error occurred while attempting to retrieve this file."
        });
    }
};

export const restoreArchiveFile = (path, documentId) => {
    const restoreUrl =  `/archive/restore/${documentId}?path=${path}`;
    return fetch(restoreUrl)
        .then(response => {
            if (response.ok) {
                Modal.info({
                    title: "File will be retrieved",
                    content: "Please wait 5 minutes for the retrieve to complete processing, then return to Final Deliverables and download the file."
                });
            } else {
                handleBadResponse(response);
            }
        })
        .catch(() => {
            Modal.error({
                title: "File could not be retrieved",
                content: "An unexpected error occurred while attempting to retrieve this file."
            });
        });
};
