import React from "react";
import {
    currentUserIncludesPermission,
    userHasRole
} from "../../utils/functions.js";
import { currentUserIncludesChangeLogPermission } from "../../utils/changeLogPermissionFunctions.js";
import {
    REVIEWER_ROLES,
    CONTRACT_ADMINISTRATOR,
    SUPER_USER,
    SYSTEM_ADMINISTRATOR,
    DHHS_ADMIN_ROLES,
    PRIMARY_REVIEWER,
    DATA_QUALITY_ADMIN,
    SECONDARY_NOTIFIER 
} from "../../constants/index.js";

const generalOrSpecificPermission = (userPermissions, permission, ids) => { 
    const idsToUse = Array.isArray(ids) ? ids.filter(id => id && typeof id === "string") : [];
    return idsToUse.length > 0 
        ? currentUserIncludesPermission(userPermissions, permission) || currentUserIncludesPermission(userPermissions, permission, ids) 
        : currentUserIncludesPermission(userPermissions, permission);
};

export const UserContext = React.createContext();

export const getUserContext = (currentUser, constants) => {

    const userPermViewSettingPage = currentUserIncludesPermission(currentUser.permissions, "VIEW_SETTINGS_PAGE");
    const userPermViewAuditReport = currentUserIncludesPermission(currentUser.permissions, "VIEW_AUDIT_REPORTS");

    const userPermViewUserAuditLog = currentUserIncludesPermission(currentUser.permissions, "VIEW_USER_AUDIT_LOGS");
    // For viewing Changelogs on the submission details page.
    const userPermViewSubmissionChangeLogs = currentUserIncludesPermission(currentUser.permissions, "VIEW_USER_SUBMISSION_CHANGELOGS");
    const userPermViewChangeLog = currentUserIncludesChangeLogPermission(currentUser.permissions);

    const userIsAdmin = currentUser.assignments.some(({ role }) => DHHS_ADMIN_ROLES[role.specifier]);

    const userIsSubmitter = currentUser.isSubmitter;
    const userIsContractAdmin = userHasRole(currentUser, CONTRACT_ADMINISTRATOR);
    const userIsSuperUser = userHasRole(currentUser, SUPER_USER);
    const userIsSystemAdmin = userHasRole(currentUser, SYSTEM_ADMINISTRATOR);
    const userIsDataQualityAdmin = userHasRole(currentUser, DATA_QUALITY_ADMIN);
    const userIsPrimaryReviewer = userHasRole(currentUser, PRIMARY_REVIEWER) ;
    const userRoles = currentUser.assignments.map((assignment) => assignment?.role?.specifier) ;
    /*
    Exclude Read only, primary reviewer, secondary reviewer, secondary notifier
    from viewing User Activity in Analytics page
    */
    const getUserPermForUserActivity = () => {
        const admins = userRoles.includes(CONTRACT_ADMINISTRATOR) || userRoles.includes(SYSTEM_ADMINISTRATOR);        
        return admins ? true : false;
    };  
    
    const userIsReviewerOrNotifier = userHasRole(currentUser, Object.keys(REVIEWER_ROLES));
    const userAdminAssignment = currentUser.assignments.find(({ role }) => DHHS_ADMIN_ROLES[role.specifier]);
    const userDataQualityAdminAssignment = currentUser.assignments.find(({ role }) => role?.specifier === DATA_QUALITY_ADMIN);

    const reviewerFilter = userHasRole(currentUser, Object.keys(REVIEWER_ROLES)) ? currentUser.id : "";

    // Contract Types
    const userPermViewContractTypes = currentUserIncludesPermission(currentUser.permissions, "VIEW_CONTRACT_TYPES");
    const userPermCreateContractTypes = currentUserIncludesPermission(currentUser.permissions, "CREATE_CONTRACT_TYPE");
    const userPermViewContractTypeDetail = currentUserIncludesPermission(currentUser.permissions, "VIEW_CONTRACT_TYPE_DETAIL");
    
    // Contracts
    const userPermViewContracts = currentUserIncludesPermission(currentUser.permissions, "VIEW_CONTRACTS");
    const userPermCreateContracts = currentUserIncludesPermission(currentUser.permissions, "CREATE_CONTRACT");
    const userPermEditContracts = currentUserIncludesPermission(currentUser.permissions, "EDIT_CONTRACT");
    const userPermViewContractDetail = currentUserIncludesPermission(currentUser.permissions, "VIEW_CONTRACT_DETAIL");

    // Submissions
    const userPermCreateSubmission = currentUserIncludesPermission(currentUser.permissions, "CREATE_SUBMISSION");
    const userPermViewSubDocumentCount = currentUserIncludesPermission(currentUser.permissions, "VIEW_SUBMISSION_DOCUMENT_COUNT");
    const userPermCreateSubDocument = currentUserIncludesPermission(currentUser.permissions, "CREATE_SUBMISSION_DOCUMENT");
    const userPermReviewDocuments = currentUserIncludesPermission(currentUser.permissions, "REVIEW_DOCUMENTS");
    const userPermFinalizeSubmission = currentUserIncludesPermission(currentUser.permissions, "FINALIZE_SUBMISSION");
    const userPermCreateSubmissionComment = currentUserIncludesPermission(currentUser.permissions, "CREATE_SUBMISSION_COMMENT");
    const userPermEditReviews = currentUserIncludesPermission(currentUser.permissions, "EDIT_REVIEW");
    
    const userPermViewSubmissions = currentUserIncludesPermission(currentUser.permissions, "VIEW_SUBMISSIONS");
    const userPermViewAllEntries = currentUserIncludesPermission(currentUser.permissions, "VIEW_ALL_ENTRIES");
    const userPermCreateExtension = currentUserIncludesPermission(currentUser.permissions, "CREATE_EXTENSION");
    const userPermViewDueDateSchedule = currentUserIncludesPermission(currentUser.permissions, "VIEW_DUE_DATE_SCHEDULE");

    const userPermCancelDocumentDQProcess = currentUserIncludesPermission(currentUser.permissions, "EDIT_DOCUMENT_DQ_STATUS");
    
    // Organization
    const userPermCreateOrganization = currentUserIncludesPermission(currentUser.permissions, "CREATE_ORGANIZATION");
    const userPermEditOrganization = currentUserIncludesPermission(currentUser.permissions, "EDIT_ORGANIZATION");
    const userPermViewOrganizationDetails = currentUserIncludesPermission(currentUser.permissions, "VIEW_ORGANIZATION_DETAIL");
    const userPermCreateOrgMembership = currentUserIncludesPermission(currentUser.permissions, "CREATE_MEMBERSHIP");
    // User
    const userPermArchiveUsers = currentUserIncludesPermission(currentUser.permissions, "ARCHIVE_USER");
    const userPermCreateUsers = currentUserIncludesPermission(currentUser.permissions, "CREATE_USER");
    const userPermEditUsers = currentUserIncludesPermission(currentUser.permissions, "EDIT_USER");
    const userDhhsUsers = currentUserIncludesPermission(currentUser.permissions, "VIEW_ALL_USERS");

    // User Add or Delete Role Assignment and Submission Type Assignment
    const userPermAssignReviewerandRole = currentUserIncludesPermission(currentUser.permissions, "ASSIGN_REVIEWER");
    

    const userPermSendFeedBack = (id) => {
        return currentUserIncludesPermission(currentUser.permissions, "SEND_FEEDBACK", [id]);
    };
    const userPermToCompleteReview = (id) => {
        return currentUserIncludesPermission(currentUser.permissions, "COMPLETE_REVIEW_DOCUMENT", [id]);
    };

    const userPermPrimaryReviewer = (id) => {
        return currentUser.assignments.some(({ role, nodeId }) => nodeId === id && role?.specifier === PRIMARY_REVIEWER);
    };

    const userPermSecondaryNotifier = (submissionTypeId) => {
        return currentUser.assignments.some(({ role, nodeId }) => nodeId === submissionTypeId && role?.specifier === SECONDARY_NOTIFIER);
    };

    //Submission Issue 
    const userPermCreateIssue = (submissionTypeId) => {
        return userIsAdmin || currentUserIncludesPermission(currentUser.permissions, "CREATE_ISSUE", [submissionTypeId]);
    };
    const userPermRejectnCloseIssue = (submissionTypeId) => {
        return userIsAdmin || userIsDataQualityAdmin  || currentUserIncludesPermission(currentUser.permissions, "REJECT_CLOSE_ISSUE", [submissionTypeId]);
    };
    const userPermCreateComments = (submissionTypeId) => {
        return userIsAdmin || userIsDataQualityAdmin || currentUserIncludesPermission(currentUser.permissions, "CREATE_COMMENT", [submissionTypeId]);
    };

    const userPermAddSecondaryReviewer = (submissionTypeId) => {
        return currentUserIncludesPermission(currentUser.permissions, "ADD_SUBMISSION_SECONDARY_REVIEWER", [submissionTypeId]);
    };

    // Submission Issue
    const userPermSelectPersonalStance = (submissionTypeId) => {
        return userIsDataQualityAdmin || currentUserIncludesPermission(currentUser.permissions, "SELECT_ISSUE_PERSONAL_STANCE", [submissionTypeId]);
    };

    // Submission Type
    const userPermCreateSubmissionType = currentUserIncludesPermission(currentUser.permissions, "CREATE_SUBMISSION_TYPE");
    const userPermEditSubmissionType = currentUserIncludesPermission(currentUser.permissions, "EDIT_SUBMISSION_TYPE");
    const userPermArchiveSubmissionType = currentUserIncludesPermission(currentUser.permissions, "ARCHIVE_SUBMISSION_TYPE");
    const userPermCreateSubTypeCategory = currentUserIncludesPermission(currentUser.permissions, "CREATE_CATEGORY");

    // Business Unit
    const userPermCreateBusinessUnits = currentUserIncludesPermission(currentUser.permissions, "CREATE_BUSINESSUNIT");

    // User Change Logs
    const userPermViewSubmissionLogs = currentUserIncludesPermission(currentUser.permissions, "VIEW_SUBMISSION_CHANGELOGS");
    const userPermViewUserLogs = currentUserIncludesPermission(currentUser.permissions, "VIEW_USER_CHANGELOGS");
    const userPermViewSubmissionTypeLogs = currentUserIncludesPermission(currentUser.permissions, "VIEW_SUBMISSION_TYPE_CHANGELOGS");
    const userPermViewContractTypePlanLogs = currentUserIncludesPermission(currentUser.permissions, "VIEW_CONTRACT_TYPE_PLAN_CHANGELOGS");
    const userPermViewOrganizationLogs = currentUserIncludesPermission(currentUser.permissions, "VIEW_ORGANIZATION_CHANGELOGS");
    const userPermViewLibraryLogs = currentUserIncludesPermission(currentUser.permissions, "VIEW_LIBRARY_CHANGELOGS");
    const userPermViewSubmissionTypeCategoryLogs = currentUserIncludesPermission(currentUser.permissions, "VIEW_SUBMISSION_TYPE_CATEGORY_CHANGELOGS");

    // Library Documents
    const userPermDownloadLibDoc = currentUserIncludesPermission(currentUser.permissions, "DOWNLOAD_LIBRARY_DOCUMENT");
    const userPermDownloadAssociatedLibDoc = currentUserIncludesPermission(currentUser.permissions, "DOWNLOAD_ASSOCIATED_LIBRARY_DOCUMENT");
    const userPermUploadnModifyLibDoc = currentUserIncludesPermission(currentUser.permissions, "UPLOAD_LIBRARY_DOCUMENT");
    const userPermViewLibDoc = currentUserIncludesPermission(currentUser.permissions, "VIEW_LIBRARY_DOCUMENT");
    const userPermViewAssociatedLibDoc = currentUserIncludesPermission(currentUser.permissions, "VIEW_ASSOCIATED_LIBRARY_DOCUMENT");
    const userPermCreateContractTypeCategory = currentUserIncludesPermission(currentUser.permissions, "CREATE_CONTRACT_TYPE_CATEGORY");
    const userPermEditLibraryDocuments = currentUserIncludesPermission(currentUser.permissions, "EDIT_LIBRARY_DOCUMENTS");

    // Submission Documents
    const userPermDownloadAllDocs = currentUserIncludesPermission(currentUser.permissions, "DOWNLOAD_ALL_DOCUMENTS");
    const userPermDownloadDocument = (submissionTypeId) => {
        return currentUserIncludesPermission(currentUser.permissions, "DOWNLOAD_DOCUMENTS", [submissionTypeId]);
    };
    const userPermDownloadFinalDoc = currentUserIncludesPermission(currentUser.permissions, "DOWNLOAD_FINAL_DOCUMENTS");
    const userPermViewComments = currentUserIncludesPermission(currentUser.permissions, "VIEW_COMMENTS");

    // Graph
    const userPermViewAllGraphs = currentUserIncludesPermission(currentUser.permissions, "VIEW_ALL_GRAPHS");

    // Feedback Documents -- need to use a function :: 
    const getFeedbackDocumentPermissions = (submissionTypeId) => {
        return {
            canCreate: currentUserIncludesPermission(currentUser.permissions, "CREATE_FEEDBACK_DOCUMENT"),
            canReupload: currentUserIncludesPermission(currentUser.permissions, "CREATE_FEEDBACK_DOCUMENT"),
            canDelete: generalOrSpecificPermission(currentUser.permissions, "DELETE_FEEDBACK_DOCUMENT", [submissionTypeId]),
            canInactivate: generalOrSpecificPermission(currentUser.permissions, "EDIT_FEEDBACK_DOCUMENT", [submissionTypeId])
        };
    };

    return { 
        auditReportTypes: currentUser?.auditReportTypes ?? [],
        username: currentUser.name,
        userId: currentUser.id,
        userAdminRole: currentUser.assignments.find(({ role }) => DHHS_ADMIN_ROLES[role.specifier]),
        userPermAttestation: currentUser.attestationFlag,
        userAttestationRoleName: currentUser.attestationRoleName,
        userAdminAssignment,
        userDataQualityAdminAssignment,
        userOrganizationId: currentUser.organizations[0].id,
        userOrganizationName: currentUser.organizations[0].name,
        userPermViewContracts,
        userPermCreateContracts,
        userPermEditContracts,
        userPermViewSettingPage,
        userPermViewAuditReport,
        userPermCreateSubTypeCategory,
        userPermViewUserAuditLog,
        userPermViewChangeLog,
        userIsAdmin,
        userIsSubmitter,
        userIsContractAdmin,
        userIsSuperUser,
        userIsSystemAdmin,
        userIsDataQualityAdmin,
        userIsReviewerOrNotifier,
        userIsPrimaryReviewer,
        userPermCreateSubmission,
        userPermViewSubDocumentCount,
        userPermCreateSubDocument,
        userPermReviewDocuments,
        userPermViewContractTypes,
        userPermCreateContractTypes,
        userPermViewContractTypeDetail,
        reviewerFilter,
        userPermFinalizeSubmission,
        userPermCreateSubmissionComment,
        userPermEditReviews,
        userPermViewContractDetail,
        userPermViewSubmissions,
        userPermViewAllEntries,
        userPermCreateExtension,
        userPermCreateSubmissionType,
        userPermArchiveSubmissionType,
        userPermCreateBusinessUnits,
        userPermViewDueDateSchedule,
        userPermEditSubmissionType,
        userPermViewSubmissionLogs,
        userPermViewUserLogs,
        userPermViewSubmissionTypeLogs,
        userPermViewContractTypePlanLogs,
        userPermViewOrganizationLogs,
        userPermViewLibraryLogs,
        userPermViewSubmissionTypeCategoryLogs,
        userPermCreateOrganization,
        userPermEditOrganization,
        userPermViewOrganizationDetails,
        userPermCreateOrgMembership,
        userPermArchiveUsers,
        userPermCreateUsers,
        userPermEditUsers,
        userPermAssignReviewerandRole,
        userPermDownloadLibDoc,
        userPermDownloadAssociatedLibDoc,
        userPermUploadnModifyLibDoc,
        userPermViewLibDoc,
        userPermViewAssociatedLibDoc,
        userPermCreateContractTypeCategory,
        userPermDownloadAllDocs,
        userPermDownloadFinalDoc,
        userPermViewComments,
        userPermViewAllGraphs,
        userDhhsUsers,
        userPermCancelDocumentDQProcess,
        userPermEditLibraryDocuments,
        getFeedbackDocumentPermissions,
        userPermDownloadDocument,
        userPermPrimaryReviewer,
        userPermSecondaryNotifier,
        userPermSendFeedBack,
        userPermToCompleteReview,
        userPermCreateIssue,
        userPermRejectnCloseIssue,
        userPermCreateComments,
        userPermAddSecondaryReviewer,
        userPermSelectPersonalStance,
        userPermViewSubmissionChangeLogs,
        getUserPermForUserActivity,
        constantsData: constants
    };
};