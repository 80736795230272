import { gql } from "@apollo/client";

export const dueDateModalQuery = gql`
    query DueDateModalQuery (
        $submissionTypesFilter: [ID]
        $organizationsFilter: [ID]
        $statusFilter: [String]
        $submissionDueRange: [String]
        $offsetSubmissions: Int
        $limitSubmissions: Int 
        $sortOn: String
        $sortBy: String
    ) {
        submissionsList (
            submissionTypesFilter: $submissionTypesFilter
            organizationsFilter: $organizationsFilter
            statusFilter: $statusFilter
            submissionDueRange: $submissionDueRange
            offset: $offsetSubmissions 
            limit: $limitSubmissions 
            sortOn: $sortOn
            sortBy: $sortBy
        ) {
            submissions {
                id
                specifier
                status
                timeline {
                    currentDueDate
                }
                submissionDocuments {
                    id
                }
                submitter {
                    __typename
                    ... on Organization {
                        id
                        name
                    }
                }
            }
            count
        }
    }
`;
