import React from "react";
import { Button, List, Modal, Radio } from "antd";
import { FileTextOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder } from "@fortawesome/free-solid-svg-icons";
import { MODIFY_LIBRARY_DOCUMENT, MODIFY_LIBRARY_FOLDER } from "../../../../constants/mutations.js";
import { handleMutations } from "../../../../utils/errorHandling.js";

const { confirm } = Modal; 

const ArchiveMessageTitle = ({ items, isArchiving }) => {
    const action = isArchiving ? "Archive" : "De-Archive";
    return <div>Do you really want to {action} {items.length === 1 ? "this item" : "these items"}?</div>;
};

const ArchiveMessageContent = ({ items, isArchiving }) => {
    const pastTenseAction = isArchiving ? "archived" : "de-archived";
    const additionalHeaderNote = items.some(item => item?.isFolder)
        ? <div style={{ color: "red" }}><strong>Note</strong>: Everything inside any folders listed below will also be {pastTenseAction}.</div>
        : null;

    const scrollNeeded = items.length > 10;

    return <>
        { additionalHeaderNote }
        <List 
            style={scrollNeeded 
                ? { maxHeight: "400px", overflow: "auto", marginTop: "15px" }
                : { marginTop: "15px" }
            }
            dataSource={items}
            renderItem={({ id, name, isFolder }) => {
                return <List.Item key={`archive-list-${id}`}>
                    { isFolder 
                        ? <FontAwesomeIcon icon={faFolder} style={{ marginRight: "10px" }} className="far" /> 
                        : <FileTextOutlined style={{ marginRight: "10px" }} />
                    }
                    { name }
                </List.Item>;
            }}
        />
    </>;
};

export const ArchiveViewToggle = ({ 
    defaultValue, 
    value, 
    onChange 
}) => {
    return <Radio.Group
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        buttonStyle="solid"
    >
        <Radio.Button value={true} >Active </Radio.Button>
        <Radio.Button value={false}> Archived </Radio.Button>
    </Radio.Group>;
};

export const ArchiveButton = ({
    refetch,
    documentSelection,
    setDocumentSelection,
    activeFilter,
    inArchivedFolder,
    libraryDocuments 
}) => { 
    const [modifyLibraryDocument] = useMutation(MODIFY_LIBRARY_DOCUMENT);
    const [modifyLibraryFolder] = useMutation(MODIFY_LIBRARY_FOLDER);


    const showConfirmArchiveDocument = () => {
        const itemsToArchive = libraryDocuments.filter(({ id }) => documentSelection.includes(id));
        confirm({
            okText: "Yes",
            title: <ArchiveMessageTitle 
                isArchiving={activeFilter} 
                items={itemsToArchive} 
            />,
            content: <ArchiveMessageContent 
                isArchiving={activeFilter} 
                items={itemsToArchive} 
            />,
            async onOk() {
                const active = !activeFilter;
                const mutations = itemsToArchive.map(({ id, isFolder }) => {
                    return isFolder 
                        ? modifyLibraryFolder({
                            variables: {
                                id, 
                                active
                            }
                        })
                        : modifyLibraryDocument({
                            variables: {
                                id,
                                active
                            }
                        });
                });

                await handleMutations(mutations, {
                    showSuccess: true,
                    successMessage: `${documentSelection.length === 1 ? "Item" : "Items"} successfully modified`
                });

                setDocumentSelection([]);
                refetch();
            }
        });
    };
        
    return (
        <div>
            <Button
                className="ownLine"
                type="danger"
                size="small"
                onClick={showConfirmArchiveDocument}
                disabled={inArchivedFolder || documentSelection.length <= 0}
            >
                {activeFilter ? "Archive" : "De-Archive"} {documentSelection?.length ?? 0} {documentSelection.length === 1 ? "item" : "items"}
            </Button>
        </div>
    );  
};