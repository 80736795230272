import React, { useEffect, useState, useContext, useRef } from "react";
import { Table, Pagination } from "antd";
import { useQuery } from "@apollo/client";
import { UserContext } from "../Application/UserContext.js";
import { filterable, formatDatetoUTC, isoDateRange } from "../../utils/index.js";
import { handleGraphQLError } from "../../utils/errorHandling.js";
import { toDosQuery } from "./queries.js";
import { EXPORTSIZE } from "../../constants/index.js";
import { CsvExport } from "../common/CsvExport/index.js";
import { CALENDAR_BREADCRUMB, toDoTableTitle } from "./helpers.js";
import { UploadSubmissionDocument } from "../common/UploadSubmissionDocument/index.js";
import { handleControlledDefaultSortOrder } from "../../utils/handleControlledDefaultSortOrder.js";
import { PersistentState } from "../../utils/PersistentState.js";
import { PCDULink } from "../common/PCDULink/index.js";

const { usePersistentState } = PersistentState();

const TO_DO_HEADERS = [
    {
        label: "Submission Type",
        key: "submissionType"
    },
    {
        label: "Submitting Organization",
        key: "submittingOrganization"
    },
    {
        label: "Contract",
        key: "contract"
    },
    {
        label: "Due Date",
        key: "dueDate"
    }
];

const transformToDosForExport = (data) => {
    const toDos = data?.toDos ?? [{}];
    return toDos.map((toDo) => {
        return {
            submissionType: `${toDo.submissionTypeSpecifier ?? ""} - ${toDo.submissionTypeName ?? ""}`,
            submittingOrganization: toDo.promisorName ?? "",
            contract: toDo.contractName ?? "",
            dueDate: formatDatetoUTC(toDo.currentDueDate)
        };
    });
};

export const ToDosTable = ({
    shouldReset,
    categoryFilterChanged,
    containerLoaded,
    permissions,
    constants,
    submissionDueRange,
    categoryFilter,
    submissionTypesFilter,
    setSubmissionTypesFilter,
    organizationsFilter,
    setOrganizationsFilter
}) => {
    const defaultSortOn = "currentDueDate";
    const defaultSortBy = "ascend";

    const refAnchor = useRef();

    // normal state :: 
    const [dataToUse, setDataToUse] = useState(null);
    const [scrollToAnchor, setScrollToAnchor] = useState(false);

    // persistent state :: 
    const [page, setPage] = usePersistentState(1);
    const [pageSize, setPageSize] = usePersistentState(10);
    const [sortOn, setSortOn] = usePersistentState(defaultSortOn);
    const [sortBy, setSortBy] = usePersistentState(defaultSortBy);

    const {
        userOrganizationId
    } = useContext(UserContext);

    const offset = (page - 1) * pageSize;

    const variables = {
        dateRangeFilter: isoDateRange(submissionDueRange),
        submissionTypesFilter,
        organizationsFilter,
        categoryFilter,
        limit: pageSize,
        offset,
        sortOn,
        sortBy
    };

    const { loading, error, data } = useQuery(toDosQuery, {
        variables,
        fetchPolicy: "no-cache"
    });

    useEffect(() => {
        if (data) {
            setDataToUse(data);
        }
    }, [data]);

    useEffect(() => {
        if (categoryFilterChanged === true) {
            setPage(1);
        }
    }, [categoryFilterChanged]);

    useEffect(() => {
        if (shouldReset) {
            setPage(1);
            setPageSize(10);
            setSortOn(defaultSortOn);
            setSortBy(defaultSortBy);
        }
    }, [shouldReset]);

    useEffect(() => {
        if (scrollToAnchor && refAnchor.current) {
            setTimeout(() => {
                refAnchor.current.scrollIntoView(true);
                setScrollToAnchor(false);
            }, 0);
        }
    }, [data]);    

    if (error) {
        return handleGraphQLError(error);
    }

    const toDos = dataToUse?.toDos ?? [];

    const toDosCount = dataToUse?.toDos?.[0]?.count ?? 0;

    const {
        canCreateSubmission,
        canViewContractDetail,
        canFilterByOrganization
    } = permissions;

    const {
        submissionTypes,
        organizations
    } = constants;

    return (
        <>
            {Array.isArray(toDos) && toDos.length > 0 &&
                <CsvExport
                    csvFilename={"Upcoming_Submissions_Scheduled_Report.csv"}
                    csvHeaders={TO_DO_HEADERS}
                    query={toDosQuery}
                    rightAligned
                    variables={{ ...variables, offset: 0, limit: EXPORTSIZE }}
                    transformData={transformToDosForExport}
                />
            }
            <div>
                <h3>
                    {toDoTableTitle({ canCreateSubmission, submissionDueRange })}
                </h3>
                <Table
                    ref={refAnchor}
                    rowKey={({ key }) => `to-dos-row-${key}`}
                    style={{
                        backgroundColor: "#fff"
                    }}
                    getPopupContainer={(node) => node ? node.parentNode : document.body}
                    size="middle"
                    columns={handleControlledDefaultSortOrder({ sortBy, sortOn, customHandler: ({ key }) => key === sortOn }, [
                        filterable({
                            title: "Submission Type",
                            domain: submissionTypes.map(({ id, specifier }) => {
                                return {
                                    label: specifier,
                                    value: id
                                };
                            }),
                            key: "submissionTypeName",
                            searchFilters: true,
                            resetFilterSearch: shouldReset,
                            filter: submissionTypesFilter,
                            setPage: setPage,
                            sorter: true,
                            width: canFilterByOrganization ? 500 : 650,
                            sortDirections: ["ascend", "descend", "ascend"],
                            setFilter: (subTypes) => {
                                setScrollToAnchor(true);
                                setSubmissionTypesFilter(subTypes);
                            },
                            render: ({ submissionTypeName, submissionTypeId }) => {
                                return (
                                    <PCDULink 
                                        to={`/submission-types/${submissionTypeId}`} 
                                        newBreadcrumbs={CALENDAR_BREADCRUMB}
                                    >
                                        {submissionTypeName}
                                    </PCDULink>
                                );
                            }
                        }),
                        canFilterByOrganization
                            ? filterable({
                                title: "Submitting Organization",
                                domain: organizations.map(({ id, name }) => {
                                    return {
                                        label: name,
                                        value: id
                                    };
                                }),
                                key: "promisorName",
                                filter: organizationsFilter,
                                sorter: true,
                                width: 300, 
                                setFilter: (orgs) => {
                                    setScrollToAnchor(true);
                                    setOrganizationsFilter(orgs);
                                } ,
                                setPage: setPage,
                                sortDirections: ["ascend", "descend", "ascend"],
                                render: ({ promisorName, promisorId }) => {
                                    return (
                                        <PCDULink 
                                            to={`/organizations/${promisorId}`} 
                                            newBreadcrumbs={CALENDAR_BREADCRUMB}
                                        >
                                            {promisorName}
                                        </PCDULink>
                                    );
                                }
                            })
                            : null
                        ,
                        {
                            title: "Contract",
                            key: "contractName",
                            sorter: true,
                            sortDirections: ["ascend", "descend", "ascend"],
                            render: ({ contractName, contractId }) => {
                                return canViewContractDetail
                                    ? (
                                        <PCDULink 
                                            to={`/contracts/${contractId}`} 
                                            newBreadcrumbs={CALENDAR_BREADCRUMB}
                                        >
                                            {contractName}
                                        </PCDULink>
                                    )
                                    : <span> {contractName} </span>;
                            }
                        },
                        {
                            title: "Due Date",
                            key: "currentDueDate",
                            sorter: true,
                            sortDirections: ["ascend", "descend", "ascend"],
                            render: ({ currentDueDate }) => {
                                return (
                                    <span> {formatDatetoUTC(currentDueDate)} </span>
                                );
                            }
                        },
                        canCreateSubmission ? {
                            title: "Actions",
                            width: 180,
                            key: "to-do-actions",
                            render: (toDo) => {
                                const obligation = {
                                    id: toDo.obligationId,
                                    submissionType: {
                                        id: toDo.submissionTypeId,
                                        specifier: toDo.submissionTypeSpecifier,
                                        name: toDo.submissionTypeName,
                                        daysToReview: toDo.submissionTypeDaysToReview,
                                        daysToResubmit: toDo.submissionTypeDaysToResubmit,
                                        useAttestation: toDo.submissionTypeUseAttestation,
                                        packagedSubmission: toDo.submissionTypePackagedSubmission,
                                        categoryName: toDo.categoryName,
                                        reportTemplate:{
                                            latestVersion: {
                                                reportTemplateStatus: toDo?.reportTemplateLatestVersion?.report_template_status,
                                                libraryDocumentId: toDo?.reportTemplateLatestVersion?.id
                                            },
                                            activeVersion:{
                                                reportTemplateStatus: toDo?.reportTemplateActiveVersion?.report_template_status
                                            }
                                        }
                                    }
                                };

                                return (
                                    <UploadSubmissionDocument
                                        buttonTitle="Create Submission"
                                        buttonStyle={{
                                            size: "small"
                                        }}
                                        style={{ marginBottom: 0 }}
                                        preloadDocumentTypes={true}
                                        documentUploadTitle={`Upload a New Version for ${toDo.submissionTypeName}`}
                                        isDisable={true}
                                        createData={{
                                            days: toDo.submissionTypeDaysToReview,
                                            obligationId: toDo.obligationId,
                                            organizationId: userOrganizationId,
                                            contractId: toDo.contractId,
                                            timelineId: toDo.timelineId,
                                            submissionTypeId: toDo.submissionTypeId,
                                            currentDueDate: toDo?.currentDueDate
                                        }}
                                        create={true}
                                        submissionTypeId={toDo.submissionTypeId}
                                        useAttestation={toDo.submissionTypeUseAttestation}
                                        submissionTypeData={obligation}
                                        submissionDocument={obligation.submissionType}
                                        maxDocumentCount={toDo.submissionTypePackagedSubmission ? 100 : 1}
                                        dueDateModelDisplay={true}
                                        newBreadcrumbs={CALENDAR_BREADCRUMB}
                                    />
                                );
                            }
                        } : null
                    ].filter(Boolean))}
                    dataSource={toDos}
                    pagination={false}
                    loading={loading || !containerLoaded}
                    onChange={(_, __, { columnKey, order }) => {
                        setSortOn(columnKey);
                        setSortBy(order);
                    }}
                />
            </div>
            <Pagination
                style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "20px 0 50px 0"
                }}
                showSizeChanger
                onChange={(targetPage, pageSize) => {
                    setPage(targetPage);
                    setPageSize(pageSize);
                }}
                current={page}
                pageSize={pageSize}
                pageSizeOptions={["10", "25", "50", "100"]}
                total={toDosCount}
            />
        </>
    );
};