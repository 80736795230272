import React, { useEffect } from "react";
import { CenteredBoxWithLogo } from "../common/CenteredBoxWithLogo/index.js";
import { AuthenticationLayout } from "../common/AuthenticationLayout/index.js";

export const LoggedOut = () => {
    useEffect(() => {
        window.sessionStorage.clear();
    });
    return (
        <AuthenticationLayout>
            <CenteredBoxWithLogo showWarningMsg="true">
                <div style={{
                    color: "white",
                    textAlign: "center",
                    margin: "10px 0"
                }}>
                    <h2 style={{ color: "inherit" }}>You&apos;ve been logged out</h2>
                    <p>Be sure to close your browser to terminate your session.</p>
                </div>
            </CenteredBoxWithLogo>
        </AuthenticationLayout>
    );
};
