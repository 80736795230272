import { useEffect, useState } from "react";

let PERSISTENT_STATE_DATA = {};

const getDefaultState = (key, fallback, storeKey) => {
    const store = PERSISTENT_STATE_DATA[storeKey];
    if (Object.hasOwn(store, key)) {
        return store[key];
    } else {
        return fallback;
    }
};

export const PersistentState = () => { 
    const keys = [];
    let index = 0, 
        allKeysRegistered = false;

    const getKey = () => {
        if (allKeysRegistered) {
            const key = keys[index];
            index++;
            if (index === keys.length) {
                index = 0;
            }
            return key;
        } else {
            const newKey = Symbol();
            keys.push(newKey);
            return newKey;
        }
    };

    return {
        usePersistentState: (originalDefaultValue, persist = true, key = null) => {
            const pathname = window.location.pathname;
            const storeKey = key && typeof key === "string" ? `${pathname}--${key}` : pathname;
            useEffect(() => {
                allKeysRegistered = true;
            }, []);
            
            if (!PERSISTENT_STATE_DATA[storeKey]) {
                PERSISTENT_STATE_DATA[storeKey] = {};
            }

            const keyToUse = getKey();
            const defaultState = persist === false ? originalDefaultValue : getDefaultState(keyToUse, originalDefaultValue, storeKey);
            const [currentValue, setter] = useState(defaultState);
            return [
                currentValue,
                (value) => {
                    PERSISTENT_STATE_DATA[storeKey][keyToUse] = value;
                    setter(value);
                }, 
                () => {
                    setter(getDefaultState(keyToUse, originalDefaultValue, storeKey));
                }
            ];
        }, 
        resetAllPersistentState: () => PERSISTENT_STATE_DATA = {}
    };
};