import React from "react";

import { Button } from "antd";

import { DebounceInput } from "react-debounce-input";

export const SearchDropdown = ({
    title,
    searchedText,
    handleSearch,
    handleReset
}) => {

    const resetCallback = handleReset ? handleReset : () => {
        handleSearch("");
    };

    return (
        <div style={{ padding: 8 }}>
            <DebounceInput
                placeholder={`Search ${title}`}
                value={searchedText}
                onChange={(e) => handleSearch(e.target.value)}
                debounceTimeout={500}
                style={{ width: 188, marginBottom: 8, display: "block" }}
            />
            <Button
                onClick={resetCallback}
                size="small"
                style={{ width: 90 }}
            >
                Reset
            </Button>
        </div>
    );
};
