import { useLazyQuery } from "@apollo/client";
import React, { createContext, useContext, useEffect, useState } from "react";
import { structurePreloadedData } from "./_helpers.js";
import { preloadedDataQuery } from "./preloadedDataQuery.js";

// poll for updates every 2 minutes :: 
const POLLING_INTERVAL_MS = 1000 * 60 * 2;

const PreloadedDataContext = createContext({
    preloadedData: undefined,
    preloadedDataLoading: true,
    preloadedDataError: undefined,
    refetchPreloadedData: async () => undefined
});

export const usePreloadedData = () => {
    return useContext(PreloadedDataContext);
};

export const PreloadedData = ({ children, initialData }) => {
    const [latestData, setLatestData] = useState({});
    const [refetch, { data, loading }] = useLazyQuery(preloadedDataQuery, { fetchPolicy: "no-cache"});

    useEffect(() => {
        const pollingTimerId = setInterval(refetch, POLLING_INTERVAL_MS);
        return () => clearInterval(pollingTimerId);
    }, []);

    useEffect(() => {
        if (data) {
            setLatestData(data);
        }
    }, [data]);

    return (
        <PreloadedDataContext.Provider value={{ 
            preloadedData: structurePreloadedData(initialData, latestData),
            refetchPreloadedData: async () => { 
                if (!loading) {
                    return await refetch();
                } 
            }
        }}> 
            { children } 
        </PreloadedDataContext.Provider>
    );
};