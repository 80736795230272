import React, { useEffect, useState, useContext } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Button, Radio, Modal, Pagination } from "antd";
import { useLocation, useParams } from "react-router-dom";
import { UserContext } from "../Application/UserContext.js";
import { MODIFY_SUBMISSION_TYPE } from "../../constants/mutations.js";
import { filterable, kebabToTitleCase, searchable } from "../../utils/index.js";
import { LoadingContent } from "../common/LoadingContent/index.js";
import { Main } from "../common/Main/index.js";
import { ScrollableTable } from "../common/ScrollableTable/index.js";
import { ClearFiltersButton } from "../common/ClearFiltersButton/index.js";
import { PCDULink } from "../common/PCDULink/index.js";
import { PRIMARY_REVIEWER, defaultTablePageSize } from "../../constants/index.js";
import { SubmissionTypesCsvExport } from "./export.js";
import { submissionTypesQuery } from "./query.js";
import { handleGraphQLError, handleMutations } from "../../utils/errorHandling.js";
import { handleControlledDefaultSortOrder } from "../../utils/handleControlledDefaultSortOrder.js";
import { PersistentState } from "../../utils/PersistentState.js";
import { useAutoFlag } from "../../utils/useAutoFlag.js";
import { getHistoryBreadcrumbs } from "../../utils/getHistoryBreadcrumbs.js";
import { usePreloadedData } from "../Application/PreloadedData/index.js";

const { usePersistentState } = PersistentState();
const { confirm } = Modal;

export const SubmissionTypes = (({ categories }) => {
    const location = useLocation();
    const { preloadedData } = usePreloadedData();
    const params = useParams();
    const lastHistoryBreadcrumb = getHistoryBreadcrumbs(-1);
    const categorySelected = Array.isArray(categories) && params.categoryId ? categories.find((category) => {
        return category.id === params.categoryId;
    }) : undefined;

    const [modifySubmissionType] = useMutation(MODIFY_SUBMISSION_TYPE);

    const defaultSortOn = "name";
    const defaultSortBy = "ascend";
 
    // normal state :: 
    const [dataToUse, setDataToUse] = useState(null);
    const [category, setCategory] = useState(categorySelected);
    const [resetFilterSearch, setResetFilterSearch] = useAutoFlag(false);

    // Persistent state :: 
    const [submissionTypeSelection, setSubmissionTypeSelection, resetSubmissionTypeSelection] = usePersistentState([]);
    const [submissionTypeTablePage, setSubmissionTypeTablePage, resetSubmissionTypeTablePage] = usePersistentState(1);
    const [submissionTypeTablePageSize, setSubmissionTypeTablePageSize, resetSubmissionTypeTablePageSize] = usePersistentState(defaultTablePageSize);
    const [nameSearch, setNameSearch, resetNameSearch] = usePersistentState("");
    const [specifierSearch, setSpecifierSearch, resetSpecifierSearch] = usePersistentState("");
    const [reviewerSearch, setReviewerSearch, resetReviewerSearch] = usePersistentState("");
    const [contractTypesFilter, setContractTypesFilter, resetContractTypesFilter] = usePersistentState([]);
    const [businessUnitFilter, setBusinessUnitFilter, resetBusinessUnitFilter] = usePersistentState([]);
    const [categoriesFilter, setCategoriesFilter, resetCategoriesFilter] = usePersistentState([]);
    const [displayDeleted, setDisplayDeleted, resetDisplayDeleted] = usePersistentState(false);
    const [sortOn, setSortOn, resetSortOn] = usePersistentState(defaultSortOn);
    const [sortBy, setSortBy, resetSortBy] = usePersistentState(defaultSortBy);

    const newBreadcrumbs = [
        { label: "Submission Types", path: "/submission-types" },
        category 
            ? { 
                label: category.name, 
                path: `/submission-types/category/${params.categoryId}`, 
                tooltip: `${category.name} Submission Types` 
            }
            : null
    ];

    const {
        userPermCreateSubmission, 
        userPermViewContractTypes,
        userPermCreateSubmissionType,
        userPermArchiveSubmissionType
    } = useContext(UserContext);

    const allSubmissionsTab = location.pathname === "/submission-types";

    const clearSearch = () => {
        setSortOn(defaultSortOn);
        setSortBy(defaultSortBy);
        setSubmissionTypeTablePage(1);
        setSubmissionTypeTablePageSize(defaultTablePageSize);
        setDisplayDeleted(false);
        setSpecifierSearch("");
        setNameSearch("");
        setReviewerSearch("");
        setContractTypesFilter([]);
        setBusinessUnitFilter([]);
        setCategoriesFilter([]);
        setSubmissionTypeSelection([]);
        setResetFilterSearch(true);
    };

    const resetToPersistentStateValues = () => {
        resetSubmissionTypeSelection();
        resetSubmissionTypeTablePage();
        resetSubmissionTypeTablePageSize();
        resetNameSearch();
        resetSpecifierSearch();
        resetReviewerSearch();
        resetContractTypesFilter();
        resetBusinessUnitFilter();
        resetCategoriesFilter();
        resetDisplayDeleted();
        resetSortOn();
        resetSortBy();
        setCategory(categorySelected);
    };

    const queryVariables = {
        offset: (submissionTypeTablePage - 1) * submissionTypeTablePageSize,
        limit: submissionTypeTablePageSize,
        nameSearch,
        specifierSearch,
        reviewerSearch,
        contractTypesFilter,
        businessUnitFilter,
        categoriesFilter: category ? [category.id] : categoriesFilter,
        displayDeleted,
        sortOn,
        sortBy
    };

    const { loading, error, data, refetch } = useQuery(submissionTypesQuery, {
        variables: queryVariables,
        fetchPolicy: "no-cache"
    }); 

    useEffect(() => {
        if (Array.isArray(categories) && params.categoryId) {
            setCategory(categories.find((category) => {
                return category.id === params.categoryId;
            }));
        }
    }, [categories]);

    useEffect(() => {
        resetToPersistentStateValues();
    }, [location]);

    useEffect(() => {
        if (data) {
            setDataToUse(data);
        }
    }, [data]);

    if (error) {
        return handleGraphQLError(error);
    }

    if (!dataToUse) {
        return (
            <LoadingContent />
        );
    }

    const { businessUnits } = preloadedData;
    const contractTypes = userPermCreateSubmission ? preloadedData.libraryAssociatedContractTypes : preloadedData.contractTypes;
    const { submissionTypesList } = dataToUse;
    const { submissionTypes, count: submissionTypesCount } = submissionTypesList;

    const rightAlignedRadioGroup = <Radio.Group
        value={displayDeleted}
        defaultValue={displayDeleted}
        onChange={(e) => {
            setDisplayDeleted(e.target.value);
            setSubmissionTypeTablePage(1);
        }}
        buttonStyle="solid"
        style={{
            display: userPermArchiveSubmissionType ? "initial" : "none"
        }}
        optionType="button"
    >
        <Radio.Button value={false} >Active </Radio.Button>
        <Radio.Button value={true}>Inactive </Radio.Button>
    </Radio.Group>;

    const submissionTypesTable = (
        <ScrollableTable
            exportButton={<SubmissionTypesCsvExport variables={queryVariables} />}
            clearFiltersButton={<ClearFiltersButton 
                clearFilters={clearSearch} 
                filtersData={[
                    { currentValue: submissionTypeSelection, defaultValue: [] },
                    { currentValue: nameSearch, defaultValue: "" },
                    { currentValue: specifierSearch, defaultValue: "" },
                    { currentValue: reviewerSearch, defaultValue: "" },
                    { currentValue: contractTypesFilter, defaultValue: [] },
                    { currentValue: businessUnitFilter, defaultValue: [] },
                    { currentValue: categoriesFilter, defaultValue: [] },
                    { currentValue: displayDeleted, defaultValue: false }
                ]}
            />}
            rightAlignedButtonBarElement={rightAlignedRadioGroup}
            pagination={false}
            columns={handleControlledDefaultSortOrder({ sortOn, sortBy }, [
                searchable({
                    title: "ID",
                    dataIndex: "specifier",
                    key: "specifier",
                    sorter: true,
                    sortDirections: ["ascend", "descend", "ascend"],
                    render: (specifier, { id }) => {
                        return (
                            <PCDULink 
                                to={`/submission-types/${id}`}
                            >
                                {specifier}
                            </PCDULink>
                        );
                    },
                    handleSearch: (textToSearch) => {
                        setSpecifierSearch(textToSearch);
                        setSubmissionTypeTablePage(1);
                    },
                    searchedColumn: () => { },
                    searchedText: specifierSearch
                }),
                searchable({
                    title: "Name",
                    dataIndex: "name",
                    handleSearch: (textToSearch) => {
                        setNameSearch(textToSearch);
                        setSubmissionTypeTablePage(1);
                    },
                    searchedText: nameSearch,
                    width: "250px",
                    sorter: true,
                    sortDirections: ["ascend", "descend", "ascend"]
                }),
                searchable({
                    title: "Primary Reviewer",
                    dataIndex: "reviewerAssignments",
                    sorter: true,
                    sortDirections: ["ascend", "descend", "ascend"],
                    handleSearch: (textToSearch) => {
                        setReviewerSearch(textToSearch);
                        setSubmissionTypeTablePage(1);
                    },
                    searchedText: reviewerSearch,
                    transform: (reviewerAssignments) => {
                        const assignment = reviewerAssignments.find((assignment) => assignment.role.specifier === PRIMARY_REVIEWER);
                        const primaryReviewer = assignment ? assignment.assignee.name : "";
                        return (primaryReviewer);
                    }
                }),
                filterable({
                    title: "Contract Type",
                    dataIndex: "contractType",
                    sorter: true,
                    sortDirections: ["ascend", "descend", "ascend"],
                    domain: contractTypes.map(({ id, name }) => {
                        return {
                            label: name,
                            value: id
                        };
                    }),
                    filter: contractTypesFilter,
                    setFilter: (contractTypeList) => {
                        setContractTypesFilter(contractTypeList);
                        setSubmissionTypeTablePage(1);
                    },
                    searchFilters: true,
                    resetFilterSearch,
                    render: ({ id, name }) => {
                        return userPermViewContractTypes
                            ? <PCDULink 
                                to={`/contract-types/${id}`} 
                                newBreadcrumbs={newBreadcrumbs}
                            >
                                {name}
                            </PCDULink>
                            : name;
                    }
                }),
                filterable({
                    title: "Business Unit",
                    dataIndex: "businessUnit",
                    sorter: true,
                    sortDirections: ["ascend", "descend", "ascend"],
                    domain: businessUnits.map(({ id, name }) => {
                        return {
                            label: name,
                            value: id
                        };
                    }),
                    filter: businessUnitFilter,
                    setFilter: (businessUnitList) => {
                        setBusinessUnitFilter(businessUnitList);
                        setSubmissionTypeTablePage(1);
                    },
                    searchFilters: true,
                    resetFilterSearch,
                    render: (businessUnit) => {
                        return businessUnit !== null ? businessUnit.name : "";
                    }
                }),
                allSubmissionsTab
                    ? filterable({
                        title: "Category",
                        dataIndex: "categoryName",
                        sorter: true,
                        sortDirections: ["ascend", "descend", "ascend"],
                        domain: categories.map(({ id, name }) => {
                            return {
                                value: id,
                                label: name
                            };
                        }),
                        filter: categoriesFilter,
                        setFilter: (categoryList) => {
                            setCategoriesFilter(categoryList);
                            setSubmissionTypeTablePage(1);
                        },
                        searchFilters: true,
                        resetFilterSearch,
                        render: (category) => category
                    })
                    : {
                        title: "Category",
                        dataIndex: "categoryName"
                    },
                {
                    title: "Actions",
                    key: "actions",
                    render: ({ id }) => {
                        return (
                            <PCDULink 
                                to={`/submissions/current?submission-type=${id}`}
                                newBreadcrumbs={newBreadcrumbs}
                            >
                                <Button
                                    size="small"
                                >
                                    View Submissions
                                </Button>
                            </PCDULink>
                        );
                    }
                }
            ])}
            dataSource={submissionTypes}
            rowKey="id"
            rowSelection={userPermArchiveSubmissionType && {
                selectedRowKeys: submissionTypeSelection,
                onChange: (selectedRowKeys) => {
                    setSubmissionTypeSelection(selectedRowKeys);
                }
            }}
            loading={loading}
            onChange={(_pagination, _filters, { field, order }) => {
                setSortOn(field);
                setSortBy(order);
            }}
        />
    );

    const submissionTypesTablePagination = (
        <Pagination
            style={{
                display: "flex",
                justifyContent: "center",
                padding: "20px 0 50px 0"
            }}
            showSizeChanger
            onShowSizeChange={(targetPage, pageSize) => {
                setSubmissionTypeTablePage(targetPage);
                setSubmissionTypeTablePageSize(pageSize);
            }}
            onChange={(targetPage, pageSize) => {
                setSubmissionTypeTablePage(targetPage);
                setSubmissionTypeTablePageSize(pageSize);
            }}
            current={submissionTypeTablePage}
            defaultCurrent={submissionTypeTablePage}
            pageSize={submissionTypeTablePageSize}
            defaultPageSize={submissionTypeTablePageSize}
            pageSizeOptions={["10", "25", "50", "100"]}
            total={submissionTypesCount}
        />
    );

    const showConfirmArchive = () => {
        const submissionTypeNames = submissionTypes
            .filter(({ id }) => submissionTypeSelection.includes(id))
            .map(({ name }) => name);

        confirm({
            title:
                `Do you really want to  ${displayDeleted ? "de-" : ""}archive  the Submission Types: ${
                    submissionTypeNames.join(", ")
                }?`,
            okText: "Yes",
            async onOk() {
                const mutations = submissionTypeSelection.map(id => {
                    return modifySubmissionType({
                        variables: {
                            id,
                            newSubmissionType: { deleted: !displayDeleted }
                        }
                    });
                });

                await handleMutations(mutations);
                refetch();
                setSubmissionTypeSelection([]);
            }
        });
    };

    return (
        <Main
            title={category?.name ? kebabToTitleCase(category.name) : "All Active Submission Types" && displayDeleted === false ? "All Active Submission Types" : "Archived Submission Types"}
            breadcrumbs={
                category
                    ? [
                        lastHistoryBreadcrumb?.path === "/submission-types" 
                            ? null 
                            : <PCDULink to="/submission-types" tooltip="All Submission Types">Submission Types</PCDULink>,
                        kebabToTitleCase(category.name)
                    ]
                    : ["Submission Types"]
            }
            details={
                <div>
                    <PCDULink
                        className="ant-btn ant-btn-primary ant-btn ant-btn-primary link-button"
                        to={category?.id ? {
                            pathname: "/submission-types/create",
                            search: `?categoryId=${category.id}&categoryName=${category.name}`
                        } : { pathname: "/submission-types/create" }}
                        style={{
                            float: "right",
                            display: userPermCreateSubmissionType ? "inline-block" : "none"
                        }}
                    >
                        Create {category?.name} Submission Type
                    </PCDULink>
                </div>
            }
        >
            <div style={{ 
                display: "flex", 
                justifyContent: userPermArchiveSubmissionType ? "space-between" : "right", 
                alignItems: "center",
                minWidth: "500px"
            }}>
                <Button
                    style={{
                        margin: "20px 0 20px 0",
                        display: userPermArchiveSubmissionType ? "block" : "none"
                    }}
                    className="ownLine"
                    type="danger"
                    size="small"
                    onClick={showConfirmArchive}
                    disabled={submissionTypeSelection.length <= 0}
                >
                    {displayDeleted ? "De-" : ""}Archive {submissionTypeSelection.length} Submission Types
                </Button>
            </div>
            {submissionTypesTable}
            {submissionTypesTablePagination}
        </Main>
    );
});