import React from "react";

export const Logo = (param) => {
    const logoHeight = param.height === undefined ? "46px" : param.height;

    return (
        <div
            id="LogoComponent"
            style={param.style !== undefined ? param.style : {
                display: "flex",
                flexDirection: "row"
            }}
        >
            <svg
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                viewBox="0 0 75 60"
                style={{ enableBackground: "new 0 0 75 60", height: logoHeight }}
            >
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M58.6,30.2c0,15.5-12.5,28-28,28c-15.5,0-28-12.5-28-28c0-15.5,12.5-28,28-28C46,2.2,58.6,14.7,58.6,30.2z
             M30.6,9.7c-11.3,0-20.3,8.9-20.3,20.1c0,11.2,9,20.5,20.3,20.5c11.3,0,20.4-9.3,20.4-20.5S41.9,9.7,30.6,9.7z"
                        ></path>
                        <path
                            className="st0"
                            d="M59.1,30c0,15.7-12.7,28.4-28.5,28.4C14.9,58.5,2.1,45.7,2.1,30S14.9,1.6,30.6,1.6
            C46.3,1.6,59.1,14.4,59.1,30z M30.6,2.7C15.5,2.7,3.2,14.9,3.2,30.1c0,15.1,12.2,27.4,27.3,27.4s27.3-12.3,27.3-27.4
            C57.9,14.9,45.6,2.7,30.6,2.7z"
                        ></path>
                        <path
                            className="st1"
                            d="M32,25.1c0,0,1.5-0.1,2.4,0c0.9,0.1,2,0,2.6,0c0.6-0.1,6.7,0.1,7,0.2c0.3,0,0.9,0.1,2,0.2
            c1.1,0.1,4.2-0.3,4.2-0.3s-1.1-5.4-6.2-10.3S31.5,9.7,30.5,9.7c-1,0-6.8,0.2-11.6,3.9c-4.8,3.7-6.5,7.5-6.5,7.5s1.8,0.8,2.6,1
            c0.7,0.3,10.4,3.4,11.1,3.5C26.8,25.6,32,25.1,32,25.1z"
                        ></path>
                        <path
                            className="st2"
                            d="M26.5,25l-0.2,0c0,0,0.8-0.1,1-0.1s1.5,0,1.7,0c0.2,0,1,0.1,1.1,0.1c0.2,0,0.1-0.1,0.6-0.1s1.5,0,1.6,0
            c0.1,0,2.9,0.1,3,0.1c0.2,0,0.7,0,0.7,0s0.8-0.1,1-0.1c0.2,0,3.6,0,3.8,0c0.2,0,2.8,0.1,3.1,0.2c0.3,0,0.3,0,0.4,0
            c0.1,0,0.4,0,0.5,0c0.1,0,3.1,0,3.4,0c0.3,0,0.9-0.1,1.1-0.1c0.2,0,0.7,0,0.7,0s0.7,3,0.7,3.7c0,0.7-0.1,4-0.1,4s-1.1,0-1.5-0.1
            c-0.4,0-2.7-0.3-2.8-0.3c-0.1,0-3.5-0.9-3.8-1.1c-0.4-0.2-6.1-1.7-6.2-1.7c-0.1,0-0.8-0.6-0.9-0.6c-0.1,0-1.8-0.9-2-1
            s-4.9-2.2-4.9-2.2L26.5,25z"
                        ></path>
                        <path
                            className="st3"
                            d="M15.5,21.6c0,0-0.1,0.5-0.5,0.5c-0.4,0-1.2-0.2-1.3-0.1c-0.1,0.1-0.5-0.1-0.8,0.1c-0.3,0.2-0.5,0.6-0.5,0.6
            l-0.7,0.2c0,0,0.5-1.7,0.7-1.9c0.3-0.2,0.9-0.5,0.9-0.5l0.6,0.1l0.7,0.3L15.5,21.6"
                        ></path>
                        <path
                            className="st3"
                            d="M15.3,22.4l0.4,0.1c0,0,0.1,0,0.3,0.1c0.2,0.1,5.4,2.1,5.8,2.2c0.3,0.1,2.7,0.9,2.8,1c0.1,0,1.5,0.4,1.6,0.4
            c0.1,0,0.9,0.3,0.9,0.3l0.1-1.2c0,0,0,0-0.3-0.1c-0.3-0.1-1.3-0.5-1.3-0.5s-1-0.5-1.4-0.6c-0.4-0.1-3.7-2.3-4.3-2.4
            c-0.6-0.1-2.8-0.6-3.1-0.6c-0.3,0.1-0.5,0.1-0.6,0.1c-0.1,0-0.4,0.2-0.5,0.3c0,0.1-0.2,0.3-0.3,0.4C15.4,21.9,15.3,22.3,15.3,22.4
            L15.3,22.4"
                        ></path>
                        <path
                            className="st3"
                            d="M11.1,26.9c0,0-0.1,0-0.3-0.1c-0.1-0.1-0.2-0.6-0.2-0.7c0.1-0.1,0.3-1.2,0.3-1.2l0.4-1.3l0.2-0.5l0.2-0.2
            c0,0,0.2-0.2,0.3-0.2c0.1,0,0.3,0,0.4-0.1c0.1-0.1,0.6-0.3,0.7-0.4c0.1,0,0.1-0.1,0.4-0.2c0.3-0.1,0.5-0.2,0.7-0.1
            c0.2,0.1,0.5,0.1,0.7,0.1s0.6,0.2,0.7,0.3c0.1,0,0.3,0.1,0.3,0.2c0,0,0.2,0,0.4,0.1c0.2,0,5.9,1.7,6.1,1.8
            c0.2,0.1,1.2,0.8,1.4,0.9c0.2,0.1,0.9,0.4,1.1,0.5c0.2,0.1,2,0.6,2.1,0.6c0.1,0,0.2-0.1,0.2,0.2c0,0.2,0,0.7,0,0.7
            s-1.2,0.2-1.7,0.1c-0.4,0-0.4-0.1-0.8-0.2s-3.6-0.5-4.3-0.7c-0.7-0.2-4.8-0.6-5.2-0.6c-0.4,0-1.2,0-1.4,0.1
            c-0.3,0-0.5,0.2-0.7,0.3c-0.2,0.1-1.6,0.5-1.6,0.5L11.1,26.9z"
                        ></path>
                        <path
                            className="st3"
                            d="M13.6,40.5c0,0,0.2,0.2,0.6,0.2c0.4,0,0.6,0,1,0c0.4,0,1.6-0.1,2.2-0.2c0.6-0.2,5.1-0.8,5.7-1
            c0.6-0.1,1.2-0.4,1.4-0.5s2.8-0.7,2.8-0.7s3.5-0.8,3.6-0.9c0.2-0.1,0.3-0.2,0.4-0.2c0.2,0,8.3-2.5,8.9-2.7
            c0.6-0.2,2.8-1.2,3.1-1.2c0.3,0,0.7-0.2,1-0.3c0.3-0.1,0.7-0.4,0.7-0.4s0.1-0.4,0.2-0.5c0.1-0.1,0.2-0.3-0.2-0.3s-1.6-0.5-2.1-0.6
            c-0.6-0.1-5.6-1.6-5.9-1.6c-0.3,0-0.1,0-0.5-0.1c-0.3-0.1-1.1-0.7-1.3-0.8c-0.2-0.2-1-0.2-1.1-0.3c-0.1,0-1.2-0.4-1.5-0.4
            c-0.3,0-1.1-1-1.4-1.3c-0.4-0.3-1.1-0.6-1.4-0.5c-0.3,0-0.1-0.2-0.3-0.2c-0.2,0-0.5,0.2-0.7,0.3c-0.2,0.1-0.4,0.1-0.5,0.1
            c-0.1,0-0.9,0.7-1.2,0.7c-0.2,0-0.5-0.1-0.8,0c-0.4,0.1-0.6,0.1-0.9,0.1c-0.3,0-0.2,0-0.5,0c-0.2,0,0-0.2-0.6-0.2
            c-0.6,0-8.6-1.3-9.2-1.2c-0.6,0-1.4,0.1-1.6,0.2c-0.3,0.1-1,0.4-1.3,0.5c-0.4,0.1-0.8,0.2-1,0.3c-0.2,0-0.5-0.3-0.6-0.2
            c-0.1,0.1-0.7,3.5-0.2,5.9c0.4,2.5,0.9,4.2,1.2,4.9C12,38.2,13.1,40.5,13.6,40.5z"
                        ></path>
                        <polyline
                            className="st3"
                            points="28.2,25.6 28.8,25.9 29.4,26.1 28.8,26.5 28.2,26.5 28.2,25.6         "
                        ></polyline>
                        <path
                            className="st4"
                            d="M13,40.2c0,0,0.5,0.1,0.9,0.2c0.4,0.1,1.3,0.4,1.5,0.3c0.3-0.1,0.3-0.3,0.7-0.3c0.4,0,7.6-1.5,8.2-1.6
            s2.1-0.3,2.4-0.4c0.3,0,2-0.6,2.3-0.7c0.2,0,12.1-3.8,12.7-3.9c0.6-0.1,3.2-1.2,3.2-1.2s3-0.3,3.3-0.2c0.3,0.1,2.7,0.3,2.7,0.3
            s-0.8,5.9-4.6,10.2c-3.8,4.4-9.5,7.3-14.2,7.3c-4.6,0-8.3-0.3-11.6-2.6C17.2,45.4,13.6,42.1,13,40.2"
                        ></path>
                        <path
                            className="st5"
                            d="M28.1,16.8l0.8-0.6l0.2-1.3l-0.2-0.6L29,14l0.2-0.2l-0.5,0L28,14.1l-0.2,0.5l-0.7,0.5c0,0-0.7,0.2-0.4,0.9
            l-0.3,0.2l0.2,0.9l0.7,0.1l-0.1-0.5l0.5-0.1L28.1,16.8z"
                        ></path>
                        <path
                            className="st6"
                            d="M20.5,20.2c0,0,0.1-1,0.4-0.8c0.2,0.2,0.4-0.2,0.4-0.2s0-0.3,0.2-0.5s0.3-0.7,0.2-0.8c-0.2-0.1,0-0.3,0-0.3
            l0.1-0.8L21.7,16c0,0-0.7-0.4-0.8-0.5c-0.2,0-1-0.1-1-0.1s-0.3-0.4-0.8-0.2c-0.4,0.2-0.7,0.6-0.7,0.8c0,0.2-0.1,1.1-0.1,1.1
            l-0.3,1.1c0,0,0.2,0.4,0.1,0.6c-0.2,0.2-0.7,0.8-0.6,1c0,0.1,0,0.2,0,0.2l0.4-0.1l0.2-0.2l0.2,0.1l0.2,0l0.1,0.1l0.4,0.3l0.7,0.4
            l0.6,0.1l0.4-0.1l0.2-0.2l-0.1-0.4L20.6,20L20.5,20.2z"
                        ></path>
                        <path
                            className="st5"
                            d="M20.8,20.1C20.8,20.1,20.8,20.1,20.8,20.1l0,0.1l0.1,0.2l0,0.2l-0.2,0.2l-0.4,0.1l-0.3,0l-0.4-0.1l-0.4-0.2
            l-0.7-0.4l-0.3-0.2l0-0.1L17.9,20l-0.3,0.1c0,0.1-0.1,0.2-0.2,0.3c-0.2,0.1-1,0.8-1,1.1c0,0.3-0.3,1-0.2,1.4c0,0-0.2,0.1-0.2,0.3
            c-0.1,0.2-0.2,0.7-0.1,0.9c0.1,0.2-0.2,0.5-0.2,0.6c0,0.1-0.1,0.7-0.1,1.3l0,0.6L16,26l0.6-0.7l0.5,0.5l0,1.1l0.2,1.2l0.5-0.6
            l0.7,0.2l0.9-0.5l0.6-0.7l0.5-1.3l0.4-1.2l0.3-0.7l0-0.7l0-0.8L21,21.2l0-0.5L20.8,20.1"
                        ></path>
                        <path
                            className="st6"
                            d="M14.5,32.1L14.5,32.1c0.1-0.1,0.1-0.1,0.1-0.2c0-0.2,0-0.4,0-0.5c0-0.2,0.7-0.8,0.8-1
            c0.1-0.1,0.1-0.8,0.1-0.9c0-0.1,0-2,0-2.2l0.1-0.4l0.4-0.8l0.5-0.6l0.6,0.2l0.1,0.6l0.1,1.4l-0.4,1.7l-0.6,1.4L16,31l-0.2,0.3
            l-0.4,0.5l-0.2,0.3l-0.2,0.2L15,32.1l0-0.3L14.8,32L14.5,32.1"
                        ></path>
                        <path
                            className="st7"
                            d="M16.7,36.4l0.5,0l0.9-0.8l1.1-0.9l0.3-0.6l0-0.3l-0.3-0.2l-0.4,0l-0.3,0.2L18.5,34l-0.2-0.3L18,32.9
            c0,0-0.6-1.1-0.6-1.2c-0.1,0-0.4-0.4-0.4-0.4l-0.4-0.2l-0.3,0L16.1,31l-0.3,0.3l-0.3,0.3L15.4,32l-0.1,0.3l-0.1,0L14.9,32
            l-0.2,0.1l-0.1,0l-0.8,0.6c0,0-0.3,0.3-0.2,0.5c0.2,0.3,0.2,0.4,0.9,0l0.2-0.1c0,0,0.3,0.1,0.4,0.7c0.1,0.6,0.6,2.1,0.9,2.4
            L16.7,36.4z"
                        ></path>
                        <path
                            className="st5"
                            d="M23.2,35.8c0,0,0,0.1,0,0.1c0,0.2,0,0.4,0,0.6C23.2,37,23.6,43,23.6,43s-1.2,0.3-1.3,0.3s-0.5,0.1-0.7,0.2
            c-0.2,0.1-0.8,0.4-1.1,0.5c-0.3,0-0.7-0.2-0.8-0.1c-0.2,0.1-0.4,0-0.9,0c-0.5-0.1-0.2-0.3-0.2-0.3s-0.8,0.2-1.2,0.2
            c-0.3,0-0.8-0.1-0.8-0.1l0.2-0.5c0,0-0.3-0.2-0.4-0.3c-0.1-0.1-0.4,0-0.4,0s0.1-0.5,0.1-0.9c0-0.4,0.1-1.9,0.1-2.2
            c0-0.2,0.1-1.3,0.2-1.8l0.2,0.1l1.2,0.1l1-0.2l0.6-0.1l0.7-0.2l0.6-0.2l0.6,0.1l0.3-0.6l0.3-0.4l0.4-0.1l0.5-0.2L23,36l0.1-0.1
            L23.2,35.8z"
                        ></path>
                        <path
                            className="st6"
                            d="M24.6,24.9l0.4-0.4l0.9-0.8l0.7-1l0.3-0.6l0.3-0.2l-0.1-1l0.1-0.1l0.2,0.1c0,0,0.2-0.1,0.3-0.2
            c0.2-0.1,0.2,0.1,0.2,0.1l0.1-0.1l0-0.3l0-0.2L28.1,20l0.1-0.2l-0.2-0.1l0.2-0.3l-0.1-0.1l-0.1,0L28,19.4l-0.2,0.1l0,0l0,0l-0.1,0
            l-0.1-0.3l-0.1-0.2l-0.1,0.3l-0.2,0.1l-0.2,0.2L27,20c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.3,0,0.9,0,0.9s-1.5,1.9-2.3,2.2l0,0.7
            L24.6,24.9z"
                        ></path>
                        <path
                            className="st0"
                            d="M24.5,23.4l-0.1-0.3L23.9,23l-0.5-0.4c0,0-0.1-0.2-0.3-0.4c0,0-0.2-0.3-0.3-0.4c-0.1-0.1-0.6-0.4-0.6-0.4
            s-0.1-0.2-0.4-0.3c-0.2-0.1-0.2-0.3-0.2-0.3s-0.3-0.7-0.7-0.7c-0.1,0-0.1,0-0.1,0l0.1,0.4L21,21l0,0.8l0,0.6l0,0.7L21,23.6
            l-0.4,1.4l-0.3,0.8l-0.3,0.8L19.6,27L19,27.5l-0.6,0.3l-0.6,0.2l-0.5,0.5l-0.5,1l-0.5,0.6l0,0.4l0,0.5l0.3,0.1l0.5,0.2l0.8,1.1
            l0.3,0.9l0.3,0.5l0.2-0.1l0.4-0.2l0.2,0l0.2,0.2l0.1,0.3l-0.8,0.9l-1.5,1l-0.6,0.2l-0.5,0.1l0,0.4l0,0.6l0.2,0.3l1.1,0.1l1.3-0.2
            l0.9-0.1c0,0,0.5-0.2,0.5-0.2c0,0,0.8,0,0.8,0l0.5-0.2l0.1-0.7l0.7,0l0.5-0.2l0.3-0.5l0.5-0.5l0.6-0.6l0.1-1L24,32l-0.1-0.7
            l0.1-0.4l0-0.9l0.2-0.1l0.1-1.4l0.1-2.3l0.1-1.4L24.5,23.4z"
                        ></path>
                        <path
                            className="st0"
                            d="M28.1,21.2L28,42.6c0,0-0.1,0.1-0.5,0.2c-0.3,0-0.3-0.1-0.3-0.1l0.1-20.7l-0.1-1v-0.3l0.1,0.1l0.1,0.1l0.2,0
            l0.3-0.2l0.1,0L28.1,21.2l0-0.3l0.1-0.1"
                        ></path>
                        <path
                            className="st0"
                            d="M27.1,19.7c0.1-0.1,0.2-2.5,0.2-2.5l-0.1-0.5l0.5-0.1l0.3,0.2l0,1.8l0,0.6l0,0.2l-0.4,0.2l0-0.1l-0.1-0.2
            l-0.1-0.1l-0.1,0.2l0,0.2L27.1,19.7z"
                        ></path>
                        <path
                            className="st4"
                            d="M35,42.3l0-0.8v-1l0.1-0.7L35,39l0-0.7l0.3-0.5l0.4-0.7l1-0.6l0.8-0.6l0.5-0.4l1.2-0.5l1.8-0.4l0.8-1.3
            l0.3-1.4l-0.1-1.7l0.4-1.1l0.3-0.5l0.4,0.1l0.3,0l0.4-0.4l0.1-0.3l-0.3-0.3l-0.4-0.4l0.3-1.3l0.2-0.1l0.3,0l0,0
            c0-0.3-0.3-0.6-0.4-0.7c0-0.1-0.5-0.5-0.5-0.6s-0.5-0.9-0.6-1.1c-0.1-0.2-1-0.6-1.1-0.7c-0.1,0-0.2,0-0.2-0.2c0-0.2-0.4,0.1-0.4,0
            c0-0.1,0,0.5-0.1,0.3c0-0.1-0.6,0.9-0.5,0.6c0.1-0.3,0.1,0.3-0.9,0.7c-0.1,0.1-0.4,0.2-0.6,0.2c-0.1,0-0.5-0.3-0.6-1.1
            c0-0.1,0.2-0.4,0.2-0.4s-0.1,0.1-0.1,0c-0.1-0.1-0.2,0.1-0.3,0.1c-0.1,0-0.3,0.2-0.4,0.3c-0.1,0-0.5,0.2-0.5,0.2l-0.6,0.8
            c0,0-0.1,0.4-0.2,0.6c-0.1,0.2,0,0.3-0.1,0.4c-0.1,0,0.1,0.2,0,0.3c-0.1,0.1-0.3,0.7-0.3,0.7s-0.2,0.1-0.4,0.2
            c-0.2,0.2-0.1,0.5-0.1,0.5s-0.1,0.1-0.2,0.3c0,0.1,0.5-0.3,0.7-0.1c0.1,0.1,0.4,0.4,0.5,0.5c0.2,0.2,0,0.7-0.2,0.9
            c-0.2,0.2,0,0.4,0.2,0.5c0.1,0.1,0.1,0.1,0.4,0.1c0.2,0,0.2-0.1,0.2-0.1l0.2,0.4l0.2,0.2l-0.2,0.5l-0.2,0.6l-0.8,0.2L35,31.6
            c0,0-1.8,0.7-2.6,1c-0.8,0.3-1.1,0.9-1.1,0.9l-0.1,1.9l0.2,0.8c0,0,0.3,1.7,0.3,2.1s0.5,1.7,0.5,1.7s-0.4,1-0.4,1.1
            c-0.1,0-0.2,0.4-0.2,0.6l-0.2,0.3l-0.1,0.3l0.3-0.1l0.4,0h0.4l0.4,0.2l0.4,0.3l1.3,0.1h0.5l0.4,0l0.2-0.1l-0.2-0.4L35.1,42
            L35,42.3z"
                        ></path>
                        <path
                            className="st6"
                            d="M32.5,43c-0.1,0-1,0.6-1.2,0.6c-0.3,0-1,0-1-0.1c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.4-0.4,0.4-0.4
            s0.3-0.3,0.4-0.5l0.5-0.1H32l0.4,0.1l0.5,0.2l0.1,0.2L32.5,43z"
                        ></path>
                        <path
                            className="st0"
                            d="M35.5,42.6l0.2,0.2c0,0-0.6,0.3-0.7,0.4c-0.2,0.1-0.3,0.2-0.3,0.2s-1.6,0.5-1.8,0.6c-0.2,0.1-0.5,0.1-0.5,0.1
            l0.3,0.2l-0.2,0.3l0.2,0.2l0.5,0.1c0,0-0.2,0.3-0.3,0.4c-0.2,0.1-0.2,0.2-0.1,0.3c0.1,0.1,0.6,0.2,0.6,0.2l0.1,0.2
            c0,0,0.6-0.1,0.8,0s0.4,0.1,0.7,0.1s0.3-0.2,0.4-0.2c0.1,0,0.4,0,0.6,0s0.4,0,0.5,0c0.1,0-0.2-0.2,0.1-0.2s1,0,1.3,0.1
            s0.1-0.2,0.2-0.3c0.1-0.2,0.2-0.3,0.5,0c0.3,0.3,0.9,0.3,1.2,0.3s1.1-0.4,1.4-0.5c0.2-0.1,0.8-0.8,1-0.9c0.3-0.1,1-1.1,1.1-1.5
            s1.2-1.9,1.4-2c0.1-0.2,0.6-1.3,0.8-1.6s0.3-1,0.4-1.2c0.1-0.2,0.2-0.7,0.2-1c0.1-0.3,0.2-0.6,0.4-0.8c0.1-0.2,0.1-0.8,0.2-1
            c0.1-0.2,0.3-0.8,0.3-1.1c0-0.2,0.2-1.3,0.3-1.7c0.1-0.4,0.3-0.5,0.3-0.6s0.4-0.9,0.5-1.1c0.1-0.3,0.7-0.3,0.8-0.6
            c0.1-0.2-1.4-0.2-1.6-0.2c-0.2,0-0.9,0.2-0.9,0.2l-0.3,0.4c0,0-0.1,0.4-0.2,0.4c-0.1,0-0.4,0.7-0.5,0.8c-0.1,0.1-0.2,0.2-0.4,0.4
            c-0.1,0.2-0.2,0.9-0.2,0.9s0.4,0,0,0.2s-0.5,0.8-0.5,0.8L44,33.8l-0.4,0.7l-1,0.8L42,35.6L40.7,36l-1-0.1L39,36.2l-0.9,0L37,36.3
            L36,36.9l-0.6,0.9L35,39l-0.1,1.5l-0.2,1.1L35.5,42.6z"
                        ></path>
                        <path
                            className="st6"
                            d="M43.8,27.9c0-0.1,0-0.1,0-0.1l0.7,0.5c0,0,1.9,1.4,1.9,1.4c0.1,0,0.4,0.4,0.5,0.4c0.1,0,0.3,0.1,0.5,0.1
            s0.8,0,0.9,0.1c0.1,0,0.2-0.2,0.3-0.2c0.1,0-0.2-0.2-0.4-0.2c-0.2,0-0.4-0.2-0.4-0.2s0.8,0.1,1,0.1c0.2,0,0.2,0.1,0.5,0
            c0.3-0.1-0.3-0.3-0.3-0.3l-0.5-0.1c0,0-0.8,0-0.9,0c-0.2,0-0.4,0-0.6-0.1c-0.1-0.1-0.9-1-1.2-1.3c-0.3-0.3-1.2-1.4-1.3-1.6
            c-0.2-0.2-0.5-0.4-0.5-0.4c0,0,0-0.1,0-0.1l-0.2,0l-0.3,0l-0.3,0.4l0.1,0.8l0.2,0.6L43.8,27.9z"
                        ></path>
                        <polygon
                            className="st6"
                            points="38.1,19.1 37.8,19.1 37.3,19.2 37.1,19.4 37.1,19.8 37.1,20.3 37.2,20.6 37.2,20.9 37.1,21.2
            37.2,21.9 37.5,22.3 37.8,22.5 38.3,22.5 38.5,22.4 38.6,22.6 38.7,23 38.5,23 38.3,23 38.4,23.5 38.6,24.1 38.7,24.4 38.9,24.5
            39.2,24.4 39.6,24.2 40,24 40.3,23.8 40.4,23.5 40.6,23.2 40.8,23 41,22.7 40.6,22.3 40.1,21.3 39.9,20.4       "
                        ></polygon>
                        <polygon
                            className="st6"
                            points="35.3,27.3 35.1,27.3 34.8,27.2 34.5,27.2 33.9,27.2 33.2,27.2 32.7,27.2 32.3,27.1 31.7,27
            31.3,26.9 30.8,26.4 30.5,26.3 30.2,26.3 29.9,26.2 29.6,26.3 29.4,26.4 29.3,26.5 29.3,26.7 29.4,26.8 29.3,26.9 29.4,27
            29.4,27.2 29.5,27.3 29.6,27.5 29.7,27.6 29.8,27.7 29.9,27.7 30.1,27.6 30.3,27.6 30.4,27.7 30.7,27.8 31.1,27.9 31.4,27.8
            31.7,27.8 32.2,28 33.8,28.4 34.6,28.6 35.5,28.7 35.9,28.7 36.2,28.6 36.4,28.5 36.5,27.9 36.4,27.7 36.3,27.4 36,27.2
            35.8,27.2       "
                        ></polygon>
                        <polygon
                            className="st0"
                            points="29.6,25 29.8,25.9 29.9,26.2 30.1,26.2 30,25.9 29.9,25.6 29.8,25.2 29.7,24.9 29.6,24.9       "
                        ></polygon>
                        <polygon
                            className="st0"
                            points="30.1,24.8 30.1,25.5 30.1,26.2 30.3,26.2 30.2,25.8 30.2,25.3 30.2,24.9 30.2,24.8 30.1,24.7       "
                        ></polygon>
                        <polygon
                            className="st0"
                            points="30.6,25 30.6,25.2 30.3,25.9 30.2,26.2 30.4,26.3 30.4,25.9 30.7,25.3 30.7,25.1       "
                        ></polygon>
                        <polygon
                            className="st0"
                            points="30,27.8 29.9,28.7 29.7,29.2 29.6,29.7 29.7,29.7 29.9,29.2 30,28.4 30.1,28.3 30.2,29.1 30.2,30.1
            30.3,30.1 30.3,28.3 30.4,28.7 30.6,29.5 30.6,29.6 30.8,29.6 30.7,29.2 30.5,28.7 30.5,28.4 30.4,28 30.4,27.6 30.2,27.6
            30,27.7         "
                        ></polygon>
                        <path
                            className="st3"
                            d="M41.2,40.1l-0.5,0.1l-0.4,0.3v0.6c0,0,0,0.5,0,0.5c0,0.1,0.1,0.4,0.3,0.4c0.2,0,0.4,0,0.4,0l0.4-0.2l0.4-0.4
            l-0.1-0.8L41.2,40.1z"
                        ></path>
                        <polygon
                            className="st5"
                            points="39.2,44.3 38.7,44.4 38.6,44.9 38.6,45.3 39,45.5 39.7,45.5 40.1,45.2 40.2,44.7 40.1,44.4
            39.6,44.2       "
                        ></polygon>
                        <path
                            className="st5"
                            d="M35,43.9l-0.7-0.1l-0.6,0.3l-0.3,0.4c0,0-0.6,0.1-0.6,0.1c0,0,0.4,0.2,0.4,0.2l0.4,0.2l0.2,0.2l0.2,0.4
            l0.5-0.2l1,0l0.5-0.1l0.6-0.2l0.2-0.3l-0.1-0.2l-0.3-0.1l-0.3,0.1l-0.1-0.2L36,44.3l-0.2-0.2L35.3,44L35,43.9z"
                        ></path>
                        <polygon
                            className="st3"
                            points="37.3,42 36.7,42.2 36.3,42.7 36.4,43.2 36.4,43.4 36,43.9 35.8,44.1 36,44.3 36.2,44.7 36.3,44.7
            36.6,44.7 36.8,44.8 37,44.7 37.1,44.4 37.2,43.8 37.3,43.4 37.7,42.9 37.6,42.6 37.8,42.3 37.8,41.8       "
                        ></polygon>
                        <polygon
                            className="st5"
                            points="38.1,39.2 37.6,39.4 37.3,39.9 37.3,40.4 37.8,40.5 38.3,40.1 38.5,39.8 38.5,39.7         "
                        ></polygon>
                        <polygon
                            className="st3"
                            points="38.9,39 38.4,39.2 38.6,39.7 38.8,39.9 39.1,39.9 39.4,39.9 39.7,39.9 39.8,39.6 39.7,39.3
            39.4,39.1       "
                        ></polygon>
                        <polygon
                            className="st5"
                            points="39.6,38.4 39.2,38.7 39.5,39.1 39.8,39.6 39.9,40 40.1,40.3 40.3,40.6 40.6,40.3 41,40.1 41.3,40
            41.3,39.6 40.9,38.9 40.8,38.6 40.6,38.2 40.1,38.1       "
                        ></polygon>
                        <polygon
                            className="st4"
                            points="30.8,42.8 30.8,43 31,42.9 31.3,42.9 31.6,42.9 31.8,43 32,43.1 32.2,43 32,42.8 31.8,42.7
            31.5,42.6 31.1,42.6 30.9,42.6       "
                        ></polygon>
                        <polygon
                            className="st4"
                            points="31.9,42.2 32,42.5 32.1,42.9 32.3,43.1 32.4,43 32.4,42.7 32.3,42.5 32.2,42.2         "
                        ></polygon>
                        <path
                            className="st7"
                            d="M44.3,24.1c0-0.2,0.2-0.3,0.2-0.3c0,0,0.2,0,0.3,0c0.1,0,0.7,0,0.8,0c0.1,0,0,0.3-0.1,0.4c0,0.1,0,0.3,0,0.4
            c0,0.1-0.2,0-0.3,0c-0.1,0-0.3-0.1-0.5-0.1c-0.2,0-0.5,0.1-0.5,0.1S44.3,24.3,44.3,24.1z M46.2,24.4c0.1,0,0.2,0,0.3,0
            c0.1,0,0.3,0,0.4-0.1c0.1,0,0-0.1,0-0.2c0-0.1,0.2-0.5,0.2-0.5s-0.4,0-0.6,0c-0.2,0-0.3,0-0.4,0c-0.1,0-0.1,0-0.2,0.2
            c-0.1,0.1-0.2,0.5,0,0.5C46.1,24.5,46.2,24.4,46.2,24.4z M47.7,24.4c0.1,0,0.2,0,0.3,0c0.1,0,0.3,0,0.3,0l0-0.3
            c0,0,0.1-0.4,0.1-0.4s-0.3,0-0.4,0s-0.6,0-0.6,0s-0.1,0.2-0.2,0.2c0,0.1,0,0.3,0,0.4C47.4,24.4,47.7,24.4,47.7,24.4z M47.7,23.4
            c0.1,0,0.2,0,0.3,0c0,0,0.1-0.1,0.1-0.2c0,0,0-0.3,0.1-0.3c0.1-0.1,0.2-0.2,0.1-0.2c0,0-0.3,0-0.4,0c-0.2,0-0.5,0-0.6,0
            c-0.1,0-0.1,0.3-0.1,0.4c0,0.1,0,0.4,0.1,0.4c0.1,0,0.2,0,0.2,0C47.5,23.4,47.7,23.4,47.7,23.4z M46.4,23.4c0.1,0,0.2,0,0.3,0
            c0.1,0,0.2,0,0.2,0c0-0.1,0-0.3,0-0.3l0.2-0.5c0,0-0.2,0-0.3,0c-0.1,0-0.6,0-0.6,0l-0.1,0.1c0,0-0.1,0.4-0.1,0.5
            c0,0.1,0,0.3,0.1,0.3C46.1,23.5,46.4,23.4,46.4,23.4z M45,23.4c0.1,0,0.2,0,0.3,0c0.1,0,0.3,0.1,0.3,0.1c0-0.1,0-0.2,0-0.2
            l0.2-0.5c0,0-0.3-0.1-0.4-0.1c-0.1,0-0.5,0-0.5,0l-0.2,0.1c0,0-0.2,0.2-0.1,0.4c0.1,0.2,0.2,0.2,0.2,0.2L45,23.4z M45,22.3l0.2,0
            l0.3,0l0.3,0c0,0-0.1-0.2,0-0.3c0.1-0.2,0.1-0.2,0.1-0.2l-0.4-0.1l-0.3,0c0,0-0.1,0.1-0.2,0.2c0,0.1-0.1,0.3-0.1,0.3
            C44.8,22.3,45,22.3,45,22.3z M46.4,22.3l0.5,0l0-0.6c0,0-0.3-0.1-0.4,0c0,0-0.4,0.1-0.4,0.1S46,22,46,22.1c0,0.1,0,0.2,0.1,0.2
            C46.2,22.3,46.4,22.3,46.4,22.3z M47.5,22.2c0,0,0.2,0.1,0.3,0.1c0.1,0,0.2-0.1,0.2-0.1s0-0.2,0-0.2c0,0,0.1-0.3,0.1-0.3l-0.2,0
            c0,0-0.3,0-0.3,0c0,0-0.2,0-0.2,0s-0.1,0.1-0.1,0.2c0,0-0.1,0.2-0.1,0.3C47.3,22.3,47.5,22.2,47.5,22.2z"
                        ></path>
                        <polygon points="57.4,24 57.5,23.8 57.4,23.9        "></polygon>
                        <polygon
                            className="st8"
                            points="4.1,39.2 4.3,38.9 4.5,38.6 4.5,38.2 4.5,38.2 4.4,38.5 4.2,38.8 3.9,39.4 3.8,39.6 3.8,39.8
            3.9,39.5        "
                        ></polygon>
                        <polygon
                            className="st8"
                            points="16.2,54.4 16.2,54.1 16.1,53.7 16,53.4 15.9,53.2 15.8,53.1 15.8,53.1 16,53.7 16.1,54.4 16.2,54.6
            16.2,54.6 16.4,54.7         "
                        ></polygon>
                        <polygon points="35.8,57.4 35.8,57.5 35.7,57.4 35.4,57.2 35.4,57.2 35.5,57.2        "></polygon>
                        <polygon
                            className="st8"
                            points="57.6,25.1 57.8,24.8 58.2,24.2 58.3,24.1 58.4,23.9 58.2,24.2 58,24.4 57.6,25 57.5,25.4 57.5,25.4
            57.5,25.4       "
                        ></polygon>
                        <polygon
                            className="st8"
                            points="56.5,20.2 56.8,19.5 56.9,19.4 56.9,19.2 56.9,19.1 56.6,19.7 56.3,20.4 56.3,20.5 56.2,20.7
            56.2,20.8 56.3,20.8         "
                        ></polygon>
                        <polygon
                            className="st8"
                            points="54.9,17.2 55,16.9 55.2,16.2 55.3,16 55.3,15.8 55.2,16.1 55.1,16.4 54.9,16.8 54.8,17.1 54.8,17.3
            54.8,17.5 54.8,17.5 54.9,17.5       "
                        ></polygon>
                        <polygon
                            className="st8"
                            points="54.3,16.2 54.4,15.8 54.6,15.1 54.6,15 54.6,14.8 54.6,15.1 54.5,15.4 54.3,16.1 54.3,16.2
            54.3,16.4 54.3,16.5 54.3,16.5       "
                        ></polygon>
                        <polygon
                            className="st8"
                            points="52.3,13.1 52.3,12.8 52.5,12.1 52.5,11.9 52.4,11.8 52.4,11.7 52.4,12.1 52.3,12.4 52.2,13.1
            52.2,13.3 52.3,13.5 52.3,13.5 52.3,13.5         "
                        ></polygon>
                        <polygon
                            className="st8"
                            points="50,9.4 49.9,9.2 49.9,9.1 49.9,9.1 49.8,9 49.9,9.4 49.8,9.7 49.8,10.4 49.9,10.6 50,10.7 50,10.8
            49.9,10.1       "
                        ></polygon>
                        <polygon
                            className="st8"
                            points="48.1,8.5 48.1,7.8 48,7.6 47.9,7.5 48,7.8 48,8.1 48,8.8 48.2,9.1 48.2,9.2 48.1,8.9       "
                        ></polygon>
                        <polygon
                            className="st8"
                            points="45.2,6.4 45,5.7 45,5.5 44.9,5.4 44.8,5.4 44.9,5.7 45,6 45,6.4 45.1,6.7 45.2,6.9 45.3,7 45.3,7
            45.4,7.1        "
                        ></polygon>
                        <polygon
                            className="st8"
                            points="42.8,4.6 42.8,4.4 42.7,4.3 42.6,4.2 42.8,4.9 43,5.5 43.1,5.7 43.2,5.8 43.3,5.9 43,5.3       "
                        ></polygon>
                        <polygon
                            className="st8"
                            points="22.6,3.2 22.9,3.4 23.2,3.6 23.6,3.6 23,3.4 22.4,3 22.2,2.9 22,2.9 22.3,3        "
                        ></polygon>
                        <polygon
                            className="st8"
                            points="19.2,4.5 19.8,4.8 20,4.8 20.2,4.8 20.2,4.8 19.9,4.7 19.6,4.6 18.9,4.3 18.8,4.3 18.6,4.3 18.9,4.3
                    "
                        ></polygon>
                        <polygon
                            className="st8"
                            points="16.7,5.5 17,5.6 17.3,5.7 17.7,5.8 17.8,5.8 18,5.8 18,5.8 17.7,5.7 17.4,5.6 16.7,5.4 16.6,5.4
            16.4,5.4 16.4,5.4       "
                        ></polygon>
                        <polygon
                            className="st8"
                            points="12,9.1 12.3,9.1 12.7,9.2 12.9,9.2 13,9.1 13,9.1 12.4,9.1 11.7,9 11.5,9 11.3,9 11.7,9        "
                        ></polygon>
                        <polygon
                            className="st8"
                            points="11.1,9.9 11.8,10 12,9.9 12.1,9.9 11.9,9.9 11.5,9.9 10.8,9.8 10.6,9.8 10.5,9.9 10.8,9.9      "
                        ></polygon>
                        <polygon
                            className="st8"
                            points="8.3,12.7 8.6,12.6 9.4,12.6 9.5,12.5 9.7,12.4 9.7,12.4 9.7,12.4 9.4,12.5 9,12.5 8.3,12.6 8.2,12.6
            8,12.7 8,12.7       "
                        ></polygon>
                        <polygon
                            className="st8"
                            points="6.3,15.7 6.6,15.7 7.3,15.5 7.5,15.3 7.3,15.4 7,15.5 6.3,15.6 6.1,15.7 6,15.7 5.9,15.8       "
                        ></polygon>
                        <polygon
                            className="st8"
                            points="3.7,21.3 4,21.2 4.7,20.9 4.8,20.8 4.9,20.7 4.9,20.6 4.7,20.8 4.3,20.9 3.7,21.2 3.5,21.4 3.5,21.4
            3.5,21.5        "
                        ></polygon>
                        <polygon
                            className="st8"
                            points="2.4,28.6 2.6,28.4 2.9,28.1 3.2,27.9 3.3,27.8 3.4,27.6 3.4,27.6 3.2,27.8 2.9,28.1 2.3,28.5
            2.2,28.6 2.2,28.7 2.2,28.8      "
                        ></polygon>
                        <polygon
                            className="st8"
                            points="6,44 6.1,43.6 6.2,43.3 6.3,43 6.3,42.8 6.3,42.6 6.3,42.6 6.2,42.9 6.1,43.2 5.9,43.9 5.9,44.1
            5.9,44.2 5.9,44.3       "
                        ></polygon>
                        <polygon
                            className="st8"
                            points="12.2,51.5 12.2,51.2 12.2,50.5 12,50.2 12,50.1 12.1,50.5 12.1,50.8 12.1,51.5 12.1,51.7 12.2,51.8
            12.3,51.8       "
                        ></polygon>
                        <polygon
                            className="st8"
                            points="8.8,48 8.8,47.7 8.9,47.3 8.9,47 8.8,46.6 8.8,46.9 8.8,47.3 8.7,48 8.7,48.1 8.7,48.3 8.8,48.3
            "
                        ></polygon>
                        <polygon
                            className="st8"
                            points="25.4,57.8 25.2,57.5 25,57.2 24.8,56.9 24.7,56.8 24.5,56.7 24.5,56.7 24.7,57 24.9,57.3 25.3,57.9
            25.4,58 25.5,58 25.6,58         "
                        ></polygon>
                        <polygon
                            className="st8"
                            points="30.3,58.3 30.1,58 29.8,57.8 29.6,57.5 29.3,57.4 29.2,57.4 29.5,57.6 29.7,57.8 30.2,58.4
            30.4,58.5 30.4,58.5 30.6,58.5       "
                        ></polygon>
                        <g>
                            <polygon
                                className="st8"
                                points="36.4,57.6 36.1,57.5 35.5,57.1 35.3,57 35.2,57.1 35.4,57.2 35.4,57.2 35.7,57.4 35.8,57.5
                36.4,57.8 36.5,57.9 36.5,57.9 36.7,57.8             "
                            ></polygon>
                            <polygon
                                className="st8"
                                points="41.1,56.2 40.8,56.1 40.2,55.8 39.9,55.8 39.8,55.8 40.1,55.9 40.4,56 41.1,56.4 41.2,56.4
                41.3,56.4 41.4,56.4             "
                            ></polygon>
                            <polygon
                                className="st8"
                                points="44.4,54.7 44.1,54.5 43.4,54.3 43.3,54.3 43.2,54.3 43.1,54.4 43.4,54.5 43.7,54.5 44.4,54.7
                44.6,54.8 44.7,54.8 44.8,54.7           "
                            ></polygon>
                            <polygon
                                className="st8"
                                points="47.5,52.6 47.2,52.6 46.9,52.5 46.5,52.5 46.3,52.5 46.3,52.5 46.1,52.6 46.5,52.6 46.8,52.6
                47.5,52.8 47.7,52.8 47.7,52.8 47.8,52.6             "
                            ></polygon>
                            <polygon
                                className="st8"
                                points="50.4,50.2 50,50.2 49.3,50.2 49,50.3 49,50.4 49.3,50.3 49.6,50.3 50.3,50.4 50.5,50.3 50.6,50.3
                50.7,50.2           "
                            ></polygon>
                            <g>
                                <polygon
                                    className="st8"
                                    points="56.8,40.1 56.1,40.3 56,40.4 55.8,40.5 55.8,40.6 55.8,40.7 56.4,40.3 57.1,40.1 57.2,40
                    57.4,39.8 57.4,39.8 57.1,39.9               "
                                ></polygon>
                                <polygon
                                    className="st8"
                                    points="53.5,46.5 53.2,46.5 52.9,46.6 52.5,46.7 52.3,46.8 52.2,46.9 52.5,46.8 52.9,46.7 53.6,46.6
                    53.7,46.6 53.8,46.5 53.8,46.4               "
                                ></polygon>
                                <g>
                                    <path
                                        className="st8"
                                        d="M55.5,43.4L55,43.5l-0.5,0.1l-0.1,0.1l-0.2,0.1l0,0L54,44.1l-0.2,0.4l-0.2,0.4l0.1,0l0.1-0.1l0.1-0.1
                        l0.7-0.2l0.3-0.1l0.3-0.1l0,0.1l0,0l0.2-0.1l0.3-0.3l0.2-0.4l0.1-0.4l-0.2,0.1L55.5,43.4z M55.4,44l-0.2,0.2L55,44.3l-0.5,0.1
                        l-0.6,0.2l0.2-0.4l0.3-0.3l0.3-0.2l0.3-0.1l0.6-0.1l0.1,0L55.4,44z"
                                    ></path>
                                    <path
                                        className="st8"
                                        d="M56.3,42.2l-0.2,0.1l-0.9,0.2l-0.2,0.1l-0.2,0.1l0,0L54.5,43l-0.3,0.4l-0.1,0.4l0.1-0.1l0.2-0.1l0.1-0.1
                        l0.7-0.1l0.3-0.1l0.3-0.1l0.2-0.1l0.1-0.2l0.2-0.4l0.1-0.4l-0.1,0.1L56.3,42.2z M56,42.9l-0.2,0.2l-0.2,0.1L55,43.4l-0.6,0.2
                        l0.2-0.4l0.2-0.3l0.3-0.2l0.4-0.1l0.7-0.1l0.1,0L56,42.9z"
                                    ></path>
                                    <polygon
                                        className="st8"
                                        points="55.3,43.4 54.6,43.6 54.4,43.6 54.3,43.8 54.2,43.9 54.2,43.9 54.3,43.7 54.5,43.6 55,43.5
                        55.5,43.4 55.7,43.3 55.9,43.2 55.6,43.3                     "
                                    ></polygon>
                                    <path
                                        className="st8"
                                        d="M56.6,41.2l-0.7,0.3l-0.6,0.3L55.1,42l-0.2,0.4l-0.1,0.4l0.1-0.1l0.3-0.2l0.7-0.2l0.3-0.1l0.3-0.2l0,0
                        l0.2-0.2l0.2-0.4l0.1-0.4l-0.1,0l-0.1,0.1L56.6,41.2z M56.5,41.8L56.3,42l-0.2,0.1l-0.5,0.1L55,42.4l0.2-0.3l0.2-0.3l0.3-0.2
                        l0.3-0.1l0.6-0.1l0.1-0.1L56.5,41.8z"
                                    ></path>
                                    <polygon
                                        className="st8"
                                        points="56.2,42.2 55.8,42.3 55.1,42.5 54.8,42.7 54.7,42.8 54.7,42.8 54.9,42.7 55.1,42.5 56.1,42.3
                        56.3,42.2 56.4,42.1 56.5,42 56.5,42                     "
                                    ></polygon>
                                    <path
                                        className="st8"
                                        d="M57.2,40l-0.1,0.1l-0.7,0.3l-0.6,0.3l0,0l-0.2,0.3l-0.2,0.4l-0.1,0.4l0.1,0l0.1-0.1l0.1-0.1l0.1-0.1
                        l0.7-0.2l0.3-0.1l0.2-0.1l0.3-0.3l0.2-0.4l0.1-0.4l0,0l0,0L57.2,40z M57,40.6l-0.2,0.2L56.6,41L56,41.2l-0.3,0.1l-0.3,0.1
                        l0.2-0.3l0.2-0.3l0.3-0.3l0.3-0.2l0.6-0.2l0.1,0L57,40.6z"
                                    ></path>
                                    <polygon
                                        className="st8"
                                        points="56.4,41.2 55.7,41.4 55.5,41.5 55.4,41.6 55.3,41.7 55.9,41.4 56.6,41.2 56.8,41.1 56.9,41
                        56.7,41.1                   "
                                    ></polygon>
                                    <g>
                                        <g>
                                            <g>
                                                <g>
                                                    <path
                                                        className="st8"
                                                        d="M49.9,10.6l0-0.2V9.7l0-0.3l0-0.3l0,0l-0.2-0.4l-0.3-0.3l-0.4-0.2L49,8.4l0,0.2l0,0.7l0.1,0.7l0,0
                                        l0.2,0.3l0.3,0.3l0.3,0.2l0,0l0,0L49.9,10.6z M49.7,9.9l0,0.3l0,0.3l-0.3-0.3l-0.2-0.3l-0.1-0.3l0-0.3l0-0.6l0-0.1l0.2,0.2
                                        l0.2,0.2l0.1,0.2l0.1,0.3L49.7,9.9z"
                                                    ></path>
                                                    <path
                                                        className="st8"
                                                        d="M49,9.6V8.9V8.6l0-0.3l-0.3-0.3l-0.3-0.3l-0.4-0.2L48,7.6l0,0.2l0,0.7l0.1,0.3l0.1,0.3l0.3,0.3
                                        l0.3,0.3l0.3,0.2l0,0L49,9.6z M48.6,9.4l-0.2-0.3l-0.1-0.3l0-0.3l0-0.6V7.8l0.4,0.3l0.2,0.2l0.1,0.2v0.5l0,0.5L48.6,9.4z"
                                                    ></path>
                                                    <polygon
                                                        className="st8"
                                                        points="49,9.3 49,8.6 49,8.4 48.9,8.2 49,8.6 49,8.9 49,9.6 49.1,9.9 49.1,10 49.1,10                                     "
                                                    ></polygon>
                                                    <path
                                                        className="st8"
                                                        d="M48,8.8l0-0.7V7.8l-0.1-0.3l-0.3-0.3l-0.3-0.3l-0.4-0.1L47,6.9l0.1,0.2l0.1,0.7l0.1,0.3l0.1,0.3
                                        l0.3,0.3L47.9,9l0.3,0.2l0,0l0,0L48,8.8z M47.7,8.6l-0.2-0.3l-0.1-0.3l-0.1-0.3l-0.1-0.6V7l0.4,0.3l0.2,0.2l0.1,0.2v0.5
                                        l0,0.3l0,0.3L47.7,8.6z"
                                                    ></path>
                                                    <path
                                                        className="st8"
                                                        d="M47.1,8.1l-0.1-0.3l0-0.3L47,7l-0.1-0.3l0,0l0,0l-0.3-0.3l-0.4-0.2l-0.3-0.1L46,6.2l0.1,0.2l0.1,0.7
                                        l0.1,0.3l0.1,0.3l-0.1,0L46.6,8l0.4,0.3l0.3,0.2l0,0l0,0L47.1,8.1z M46.8,7.9l-0.3-0.3l-0.2-0.3L46.3,7l-0.1-0.6l0-0.1
                                        l0.2,0.1l0.2,0.1l0.2,0.2L46.9,7L47,7.6l0.1,0.6L46.8,7.9z"
                                                    ></path>
                                                    <polygon
                                                        className="st8"
                                                        points="47.1,7.8 47.1,7.1 47,6.9 46.9,6.7 46.9,6.7 47,7 47,7.4 47.1,7.7 47.1,8.1 47.3,8.4
                                        47.3,8.4 47.2,8.1                                   "
                                                    ></polygon>
                                                    <path
                                                        className="st8"
                                                        d="M46.2,7.5l-0.1-0.1L46.1,7l0-0.3L46,6.3l-0.1-0.3l0.1,0l0,0l-0.3-0.3l-0.4-0.2l-0.3-0.1L45,5.5L45,5.7
                                        l0.1,0.7l0.2,0.7l0,0l0.3,0.3L46,7.6l0.3,0.1l0,0L46.2,7.5z M45.7,7.2L45.5,7l-0.2-0.3l-0.1-0.3l-0.1-0.6l0-0.1l0.5,0.3
                                        l0.2,0.2l0.1,0.2L46,6.9l0,0.3l0.1,0.3L45.7,7.2z"
                                                    ></path>
                                                    <polygon
                                                        className="st8"
                                                        points="46.2,7.4 46.2,7.1 46.1,6.4 46,6.2 45.9,6.1 45.9,6.1 46,6.3 46,6.7 46.1,7 46.1,7.4
                                        46.2,7.5 46.3,7.7 46.3,7.7 46.3,7.7                                     "
                                                    ></polygon>
                                                    <path
                                                        className="st8"
                                                        d="M45.2,6.9l-0.1-0.1L45,6.4L45,6l-0.1-0.3l-0.1-0.3l0.1,0l0-0.1l-0.3-0.3l-0.4-0.2l-0.4-0.1l0,0l0,0
                                        l0.1,0.1L44,5.1l0.2,0.7l0.1,0.3l0.1,0.3l0,0l0.3,0.2L45,6.9L45.3,7l0,0L45.2,6.9z M44.7,6.6l-0.3-0.2l-0.2-0.3l-0.1-0.3
                                        l-0.1-0.6L44,5l0.4,0.3l0.2,0.2l0.1,0.2l0.1,0.5L45,6.7L44.7,6.6z"
                                                    ></path>
                                                    <path
                                                        className="st8"
                                                        d="M44.2,6.2l-0.1-0.1l-0.2-0.7l-0.1-0.3l-0.1-0.3l-0.3-0.3L43,4.3l-0.4-0.1l0,0l0,0l0.1,0.1l0.1,0.2
                                        L43,5.3l0.3,0.6l0.3,0.2L44,6.3l0.3,0.1l-0.1,0L44.2,6.2z M43.7,6l-0.3-0.2l-0.2-0.3l-0.1-0.3L43,4.5V4.5l0.5,0.3l0.2,0.2
                                        l0.1,0.2l0.1,0.5l0.1,0.3L44,6.2L43.7,6z"
                                                    ></path>
                                                    <polygon
                                                        className="st8"
                                                        points="44.2,6.1 44.1,5.8 44,5.1 43.9,4.9 43.8,4.8 43.7,4.8 43.8,5.1 43.9,5.4 44.1,6.1 44.2,6.2
                                        44.3,6.4 44.3,6.4 44.4,6.5                                  "
                                                    ></polygon>
                                                    <path
                                                        className="st8"
                                                        d="M43.1,5.7L43,5.5l-0.2-0.7l-0.2-0.6L42.3,4l-0.4-0.2l-0.4-0.1l0.1,0.1l0.1,0.2L42,4.7l0.3,0.6l-0.1,0
                                        l0.3,0.2l0.4,0.2l0.4,0.1l0,0l0,0L43.1,5.7z M42.6,5.4l-0.3-0.2l-0.2-0.3L42,4.6L41.8,4l0-0.1l0.5,0.3l0.2,0.2l0.2,0.2
                                        l0.1,0.5l0.1,0.5L42.6,5.4z"
                                                    ></path>
                                                    <path
                                                        className="st8"
                                                        d="M42,5.2L42,5l-0.2-0.7L41.6,4l-0.1-0.3l-0.3-0.2l-0.4-0.2l-0.4-0.1l0.1,0.1l0.1,0.2l0.3,0.7L41,4.6
                                        l0.2,0.3l-0.1,0l0.3,0.2l0.4,0.2l0.3,0.1l0,0L42,5.2z M41.5,4.9l-0.3-0.2L41,4.5l-0.1-0.3l-0.1-0.3l-0.1-0.3l0-0.1l0.5,0.2
                                        l0.2,0.2l0.2,0.2l0.2,0.5l0.1,0.3l0.1,0.3L41.5,4.9z"
                                                    ></path>
                                                    <polygon
                                                        className="st8"
                                                        points="42,4.7 41.7,4.1 41.6,3.9 41.5,3.7 41.6,4 41.7,4.3 42,5 42,5.2 42.2,5.3 42.2,5.3 42.3,5.3
                                                                            "
                                                    ></polygon>
                                                    <path
                                                        className="st8"
                                                        d="M40.9,4.7l-0.1-0.1l-0.3-0.7l-0.1-0.3l-0.1-0.3L40,3l-0.4-0.1l-0.4,0L39.3,3l0.1,0.1l0.3,0.7l0.2,0.3
                                        L40,4.4l0,0l0.4,0.2l0.4,0.2l0.3,0.1l0,0L40.9,4.7z M40.4,4.5l-0.3-0.2l-0.2-0.3l-0.2-0.3l-0.2-0.6l0-0.1L40,3.2l0.2,0.1
                                        l0.2,0.2l0.2,0.5l0.2,0.5L40.4,4.5z"
                                                    ></path>
                                                    <polygon
                                                        className="st8"
                                                        points="41,4.6 40.8,4.3 40.6,3.6 40.5,3.4 40.3,3.3 40.5,3.6 40.6,3.9 40.9,4.5 40.9,4.7 41.1,4.8
                                        41.1,4.8 41.2,4.9                                   "
                                                    ></polygon>
                                                    <path
                                                        className="st8"
                                                        d="M39.7,4.1l-0.1-0.3l-0.1-0.3l-0.1-0.3l-0.1-0.3l0,0l0,0l-0.4-0.2l-0.4-0.1H38l0.1,0.1l0.1,0.1l0.3,0.6
                                        l0.2,0.3L38.9,4l-0.1,0l0,0l0.4,0.2l0.4,0.1L40,4.4l0-0.1l-0.1-0.1L39.7,4.1z M39.3,4.1L39,3.9l-0.3-0.2l-0.2-0.3l-0.3-0.5
                                        l-0.1-0.1l0.5,0.1L39,3l0.2,0.2l0.2,0.5l0.2,0.5L39.3,4.1z"
                                                    ></path>
                                                    <polygon
                                                        className="st8"
                                                        points="39.7,3.8 39.4,3.2 39.3,3 39.2,2.9 39.2,2.9 39.3,3.2 39.5,3.5 39.6,3.8 39.7,4.1 39.9,4.3
                                        40,4.4 39.9,4.1                                     "
                                                    ></polygon>
                                                    <path
                                                        className="st8"
                                                        d="M38.4,3.4l-0.1-0.3l-0.1-0.3L38,2.6h0l0,0l-0.4-0.2l-0.4-0.1h-0.4l0.1,0.1L37,2.5l0.3,0.6l0.2,0.3
                                        l0.2,0.3l0,0l0.3,0.2L38.5,4l0.3,0l-0.2-0.2L38.4,3.4z M38.2,3.7l-0.3-0.2l-0.3-0.2L37.4,3l-0.3-0.5l-0.1-0.1l0.5,0.1
                                        l0.2,0.1L38,2.8l0.3,0.5l0.1,0.3l0.1,0.2L38.2,3.7z"
                                                    ></path>
                                                    <polygon
                                                        className="st8"
                                                        points="38.7,3.7 38.5,3.4 38.2,2.8 38.1,2.7 38,2.6 38,2.6 38.1,2.8 38.3,3.1 38.4,3.4 38.6,3.8
                                        38.8,4 38.9,4                                   "
                                                    ></polygon>
                                                    <path
                                                        className="st8"
                                                        d="M37.6,3.5l-0.1-0.1l-0.2-0.3l-0.1-0.3l-0.2-0.3l-0.2-0.3h0l0,0l-0.4-0.2L35.9,2l-0.3,0l0.1,0.1
                                        l0.1,0.1l0.4,0.6l0.2,0.3l0.2,0.3l0,0L37,3.5l0.4,0.1l0.4,0l-0.1,0L37.6,3.5z M37,3.4l-0.3-0.1L36.4,3l-0.2-0.3l-0.3-0.5
                                        l-0.1-0.1l0.5,0.1l0.3,0.1l0.2,0.2L37.1,3l0.3,0.5L37,3.4z"
                                                    ></path>
                                                    <polygon
                                                        className="st8"
                                                        points="37.6,3.4 37.4,3.1 37,2.5 36.9,2.4 36.8,2.3 36.8,2.3 36.9,2.5 37.1,2.8 37.2,3.1 37.4,3.4
                                        37.6,3.5 37.7,3.6 37.8,3.7 37.8,3.7                                     "
                                                    ></polygon>
                                                    <polygon
                                                        className="st8"
                                                        points="36.4,3.2 36.2,2.9 35.8,2.2 35.7,2.1 35.6,2 35.5,2 35.7,2.3 35.9,2.6 36.3,3.2 36.6,3.4
                                        36.6,3.4 36.6,3.4                                   "
                                                    ></polygon>
                                                    <g>
                                                        <path
                                                            className="st8"
                                                            d="M58.7,34l-0.1,0.1L58,34.5L57.5,35l0,0l-0.1,0.4l-0.1,0.5l0,0.4l0.1-0.2l0.1-0.1l0.1-0.1l0.3-0.2
                                            l0.3-0.2l0.3-0.2l0.3-0.2l0,0l0.2-0.4l0.1-0.5l0-0.4l0,0l0,0L58.7,34z M58.6,34.7l-0.1,0.2l-0.2,0.2l-0.5,0.3l-0.5,0.3
                                            l0.1-0.4l0.2-0.4l0.2-0.3l0.1-0.1l0.1-0.1l0.5-0.3l0.1-0.1L58.6,34.7z"
                                                        ></path>
                                                        <polygon
                                                            className="st8"
                                                            points="58.4,35.2 58.1,35.4 57.8,35.6 57.5,35.8 57.4,35.9 57.3,36 57.2,36.2 57.2,36.2 57.8,35.7
                                            58.4,35.3 58.5,35.2 58.6,35 58.6,35 58.6,35                                         "
                                                        ></polygon>
                                                        <path
                                                            className="st8"
                                                            d="M58.8,32.7l-0.1,0.1l-0.3,0.2l-0.3,0.2l-0.5,0.5l0-0.1l-0.1,0.3l-0.1,0.5l0,0.4l0-0.1l0.1-0.2
                                            l0.1-0.1l0.6-0.4l0.3-0.2l0.2-0.2l0.1-0.4l0.1-0.5L59,32.5l0,0l0,0L58.8,32.7z M58.8,33.4l-0.1,0.3l-0.2,0.2l-0.5,0.3
                                            l-0.5,0.3l0.1-0.4l0.1-0.3l0.2-0.3l0.3-0.2l0.5-0.3l0.1-0.1L58.8,33.4z"
                                                        ></path>
                                                        <polygon
                                                            className="st8"
                                                            points="58.3,34.1 57.7,34.5 57.6,34.7 57.5,34.9 57.5,34.9 57.5,35 58,34.5 58.6,34.1 58.7,34
                                            58.8,33.8 58.9,33.8 58.6,34                                         "
                                                        ></polygon>
                                                        <path
                                                            className="st8"
                                                            d="M58.9,31.5l-0.1,0.1l-0.6,0.5l-0.5,0.5l-0.1,0.2l-0.1,0.5l0,0.4l0-0.1l0.1-0.2l0.1-0.1l0.6-0.4
                                            l0.3-0.2l0.2-0.2l0.1-0.4l0-0.5l-0.1-0.4l0,0l0,0.1L58.9,31.5z M58.9,32.1l-0.1,0.3l-0.1,0.3L58.3,33L58,33.2l-0.2,0.2V33
                                            l0.1-0.4l0.2-0.3l0.3-0.3l0.5-0.5l0.1-0.1l0,0.3L58.9,32.1z"
                                                        ></path>
                                                        <polygon
                                                            className="st8"
                                                            points="58.5,32.9 57.9,33.3 57.8,33.5 57.7,33.7 57.6,33.7 57.6,33.8 58.2,33.3 58.4,33.1
                                            58.7,32.9 58.8,32.7 59,32.6 59,32.5 58.8,32.7                                       "
                                                        ></polygon>
                                                        <path
                                                            className="st8"
                                                            d="M59,30.2l-0.1,0.1l-0.5,0.5l-0.5,0.6l-0.1,0.3l-0.1,0.5l0,0.4l0,0l0.1-0.2l0.2-0.3l0.6-0.5l0.3-0.2
                                            l0.2-0.2l0.1-0.4v-0.5L59.1,30l0,0.1L59,30.2z M59,30.7l0,0.3l-0.1,0.3l-0.1,0.2l-0.4,0.3l-0.5,0.3l0.1-0.3l0.1-0.3
                                            l0.2-0.3l0.3-0.3l0.5-0.4l0.1-0.1V30.7z"
                                                        ></path>
                                                        <polygon
                                                            className="st8"
                                                            points="58.6,31.7 58,32.2 57.8,32.4 57.8,32.6 58.2,32.1 58.8,31.6 58.9,31.5 59,31.3 59.1,31.3
                                            58.8,31.5                                       "
                                                        ></polygon>
                                                        <path
                                                            className="st8"
                                                            d="M59,29l-0.1,0.1l-0.5,0.5l-0.3,0.2l-0.2,0.3l0,0l-0.1,0.4V31l0.1,0.4l0,0l0.1-0.2l0.1-0.2l0.1-0.1
                                            l0.5-0.5l0.3-0.3l0.2-0.3l0,0.1l0.1-0.4v-0.4l-0.1-0.3L59,29z M59,29.7L58.9,30l-0.1,0.2l-0.4,0.3L58,30.9v-0.3l0.1-0.3
                                            l0.1-0.3l0.3-0.3l0.5-0.4l0.1-0.1V29.7z"
                                                        ></path>
                                                        <polygon
                                                            className="st8"
                                                            points="58.9,30.2 58.6,30.5 58.1,31 58,31.1 57.9,31.3 57.9,31.5 58.3,30.9 58.8,30.4 59,30.2
                                            59.1,30.1 59.1,30 59.1,30                                       "
                                                        ></polygon>
                                                        <path
                                                            className="st8"
                                                            d="M58,29.9l0.1-0.1l0.5-0.5l0.3-0.2l0.2-0.3l0,0.1l0,0l0.1-0.2l0-0.2L59.1,28L59,27.6l0,0l-0.1,0.2
                                            l-0.1,0.1l-0.5,0.5L58,28.7L57.8,29l0,0l-0.1,0.4l0,0.4l0.1,0.4l0,0L58,29.9z M58,29.4L58,29l0.1-0.3l0.2-0.3l0.4-0.4
                                            l0.1-0.1l0,0.5l-0.1,0.3L58.8,29l-0.4,0.4l-0.2,0.2L58,29.7V29.4z"
                                                        ></path>
                                                        <polygon
                                                            className="st8"
                                                            points="58.1,29.9 58.3,29.6 58.8,29.2 59,29 59.1,28.9 59,28.8 58.8,29.1 58.6,29.3 58.1,29.8
                                            58,29.9 57.9,30.1 57.9,30.1 57.9,30.1                                       "
                                                        ></polygon>
                                                        <path
                                                            className="st8"
                                                            d="M57.9,28.7l0.1-0.1l0.2-0.3l0.3-0.3l0.2-0.2l0.2-0.3l0,0l0.1-0.4L59,26.7l-0.1-0.4l0,0l-0.1,0.2
                                            l-0.1,0.1l-0.5,0.5l-0.2,0.3l-0.1,0.2l-0.1,0.4l0,0.5l0.1,0.4l0,0L57.9,28.7z M58,27.8l0.1-0.3l0.2-0.3l0.4-0.4l0.1-0.1
                                            l0,0.5l0,0.3l-0.1,0.2l-0.4,0.4l-0.2,0.2l-0.2,0.2l0-0.4L58,27.8z"
                                                        ></path>
                                                        <polygon
                                                            className="st8"
                                                            points="58,28.7 58.2,28.4 58.8,27.9 58.9,27.8 59,27.6 59,27.6 59,27.6 58.8,27.8 58.5,28.1
                                            58.3,28.3 58,28.6 57.9,28.7 57.8,28.9 57.8,28.9 57.8,29                                         "
                                                        ></polygon>
                                                        <path
                                                            className="st8"
                                                            d="M57.8,27.5l0.1-0.1l0.2-0.3l0.2-0.3l0.2-0.3l0.2-0.3l0,0l0-0.4l-0.1-0.4l-0.2-0.4l-0.1,0.2l-0.1,0.1
                                            L58,26l-0.3,0.5l0,0l-0.1,0.4l0,0.4l0.1,0.4l0,0l0,0L57.8,27.5z M57.8,26.6l0.1-0.3l0.2-0.3l0.4-0.5l0.1-0.1l0.1,0.5
                                            l0,0.3l-0.1,0.3l-0.4,0.4L58,27.1l-0.1,0.2L57.8,27L57.8,26.6z"
                                                        ></path>
                                                        <polygon
                                                            className="st8"
                                                            points="58.2,27.2 58.6,26.7 58.8,26.5 58.8,26.4 58.8,26.3 58.8,26.3 58.6,26.6 58.4,26.9
                                            58.2,27.1 58,27.4 57.8,27.5 57.8,27.7 57.8,27.7 57.9,27.5                                       "
                                                        ></polygon>
                                                        <path
                                                            className="st8"
                                                            d="M58,25.9l0.3-0.3l0.2-0.3l0.2-0.3l0,0l0,0v-0.4l-0.1-0.4l-0.2-0.4l-0.1,0.2l-0.1,0.1l-0.4,0.6
                                            l-0.2,0.3l-0.1,0.3l0,0l0,0.4l0.1,0.4l0.1,0.4l0-0.1l0.1-0.3L58,25.9z M57.6,25.8v-0.3l0.1-0.3l0.2-0.3l0.4-0.5l0,0l0-0.1
                                            l0.1,0.5v0.3l-0.1,0.3L58,25.7l-0.3,0.4L57.6,25.8z"
                                                        ></path>
                                                        <polygon
                                                            className="st8"
                                                            points="58.5,25.5 58.6,25.3 58.6,25.1 58.6,25.1 58.4,25.4 58.2,25.7 58,25.9 57.8,26.2 57.7,26.5
                                            58,26                                       "
                                                        ></polygon>
                                                        <polygon
                                                            className="st8"
                                                            points="57.6,25 58,24.4 58.2,24.2 58.4,23.9 58.4,23.5 58.3,23.1 58.1,22.7 58,22.9 58,23.1
                                            57.6,23.7 57.5,23.8 57.6,23.6 58,23.1 58.1,23 58.2,23.6 58.2,23.8 58.1,24.1 57.8,24.5 57.5,25 57.4,24.6 57.4,24.3
                                            57.4,24 57.3,24.3 57.3,24.6 57.4,25.1 57.5,25.4 57.5,25.4                                       "
                                                        ></polygon>
                                                        <polygon
                                                            className="st8"
                                                            points="57.4,23.8 57.5,23.6 57.7,23.3 57.9,23 58.1,22.7 58.1,22.7 58.1,22.7 58.1,22.3 57.9,21.8
                                            57.8,21.5 57.8,21.5 57.7,21.7 57.6,21.9 57.4,22.4 57.7,22 57.8,21.9 57.9,22.4 57.9,22.6 57.8,22.9 57.5,23.3
                                            57.2,23.8 57.1,23.4 57.1,23.1 57.1,22.9 57,23.2 57,23.2 57,23.5 57.1,23.9 57.3,24.3 57.3,24.2                                       "
                                                        ></polygon>
                                                        <polygon
                                                            className="st8"
                                                            points="57.4,23.9 57.5,23.8 57.6,23.7 58,23.1 58,22.9 58.1,22.7 58.1,22.7 57.9,23 57.7,23.3
                                            57.5,23.6 57.4,23.8 57.3,24.2 57.3,24.3 57.4,24                                         "
                                                        ></polygon>
                                                        <path
                                                            className="st8"
                                                            d="M57,22.8l0.1-0.1l0.4-0.6l0.2-0.3l0.1-0.3l0,0.1l0-0.5l-0.2-0.4l-0.2-0.3l0,0l0,0l0,0.2l-0.1,0.2
                                            l-0.3,0.7L56.7,22l0,0l0,0.4l0.1,0.5l0.1,0.2l0.1,0.1l0-0.2L57,22.8z M56.8,22.4l0-0.4l0.1-0.4l0.2-0.3l0.3-0.5l0.1-0.1
                                            l0.1,0.5l0,0.3l-0.1,0.3l-0.3,0.5l-0.3,0.5L56.8,22.4z"
                                                        ></path>
                                                        <polygon
                                                            className="st8"
                                                            points="57.1,22.9 57.2,22.8 57.4,22.4 57.4,22.4 57.6,21.9 57.7,21.7 57.8,21.5 57.8,21.5
                                            57.7,21.5 57.6,21.8 57.4,22 57.1,22.7 57,22.8 57,23 57,23.2 57,23.2                                         "
                                                        ></polygon>
                                                        <polygon
                                                            className="st8"
                                                            points="57.2,22.8 57.1,22.9 57.3,22.6 57.4,22.4 57.4,22.4                                       "
                                                        ></polygon>
                                                        <path
                                                            className="st8"
                                                            d="M56.7,21.7l0.1-0.2l0.3-0.6l0.2-0.3l0.1-0.3l-0.1-0.4l-0.2-0.4l-0.2-0.3l0,0l0,0.1l0,0.2l-0.1,0.2
                                            l-0.3,0.6l-0.2,0.7l0,0l0.1,0.4l0.2,0.5l0.2,0.3l0-0.1L56.7,21.7z M56.5,21.1l0-0.3l0-0.3l0.1-0.3l0.3-0.5l0-0.1l0.2,0.5
                                            l0,0.3l0,0.3L56.8,21l-0.3,0.5L56.5,21.1z"
                                                        ></path>
                                                        <polygon
                                                            className="st8"
                                                            points="56.9,21.4 57.3,20.7 57.3,20.5 57.4,20.3 57.4,20.3 57.2,20.6 57.1,20.9 56.7,21.5
                                            56.7,21.7 56.6,21.9 56.6,22 56.7,22                                         "
                                                        ></polygon>
                                                        <path
                                                            className="st8"
                                                            d="M56.3,20.5l0.1-0.2l0.3-0.7l0.2-0.6l-0.1-0.4l-0.2-0.4l-0.2-0.3v0.2l-0.1,0.2l-0.3,0.7l-0.1,0.3
                                            l-0.1,0.3l0.1,0.4l0.1,0.4l0.2,0.3l0,0L56.3,20.5z M56.1,20L56,19.7l0-0.3l0.1-0.3l0.3-0.5l0-0.1l0.1,0.2l0.1,0.3l0.1,0.3
                                            l0,0.3l-0.3,0.5l-0.1,0.2l-0.1,0.3L56.1,20z"
                                                        ></path>
                                                        <path
                                                            className="st8"
                                                            d="M55.8,19.4l0-0.2l0.1-0.3l0.1-0.3l0.1-0.3l0.1-0.3l0,0v0l-0.1-0.4l-0.2-0.4l-0.3-0.3l0,0l0,0v0.2
                                            l-0.1,0.2L55.5,18l-0.2,0.7l0,0l0.1,0.4l0.2,0.4l0.2,0.3l0,0l0,0V19.4z M55.8,19.3l-0.1-0.3l-0.1-0.3l0-0.3l0.1-0.3
                                            l0.3-0.6l0-0.1l0.2,0.5l0.1,0.3v0.3L56,18.8L55.8,19.3z"
                                                        ></path>
                                                        <polygon
                                                            className="st8"
                                                            points="56.1,19.1 56.4,18.4 56.4,18.2 56.4,18.1 56.4,18 56.3,18.3 56.2,18.6 56,18.9 55.9,19.3
                                            55.8,19.4 55.8,19.6 55.8,19.7 55.9,19.4                                         "
                                                        ></polygon>
                                                        <path
                                                            className="st8"
                                                            d="M55.4,18.2l0.1-0.3l0.1-0.3l0.1-0.3l0.1-0.3l-0.1-0.4l-0.2-0.4l-0.3-0.3V16l0,0.2L55,16.9l-0.1,0.3
                                            l-0.1,0.3l0,0l0.1,0.4l0.2,0.4l0.2,0.3l0-0.1L55.4,18.2z M55.1,17.9L55,17.5l0-0.3l0.1-0.3l0.2-0.6l0-0.1l0.1,0.2l0.1,0.2
                                            l0.1,0.3v0.3l-0.2,0.5l-0.2,0.5L55.1,17.9z"
                                                        ></path>
                                                        <polygon
                                                            className="st8"
                                                            points="55.5,18 55.8,17.3 55.9,17.1 55.9,16.9 55.9,16.9 55.8,17.2 55.6,17.5 55.5,17.9 55.4,18.2
                                            55.3,18.5 55.4,18.6 55.4,18.6                                       "
                                                        ></polygon>
                                                        <path
                                                            className="st8"
                                                            d="M54.8,17.3l0-0.2l0.1-0.3l0.1-0.3l0.1-0.3l0.1-0.3l-0.1-0.4L54.9,15l-0.3-0.3l0,0.2l0,0.2l-0.2,0.7
                                            l-0.1,0.3l-0.1,0.3l0,0l0.1,0.3l0.2,0.4l0.2,0.3l0,0L54.8,17.3z M54.7,17.1l-0.2-0.3l-0.1-0.3v-0.3l0.1-0.3l0.2-0.6l0-0.1
                                            l0.3,0.5l0.1,0.3v0.3l-0.2,0.5l-0.1,0.3L54.7,17.1z"
                                                        ></path>
                                                        <path
                                                            className="st8"
                                                            d="M54.3,16.2v-0.2l0.2-0.7l0.1-0.3l0-0.3l-0.1-0.4L54.2,14l-0.3-0.3l0,0.2l0,0.2l-0.2,0.7l-0.1,0.3
                                            l0,0.3l0.2,0.4l0.2,0.4l0.3,0.3l0-0.1V16.2z M53.9,15.8l-0.1-0.3l0-0.3l0.1-0.3l0.2-0.6l0-0.1l0.3,0.5l0.1,0.2l0,0.3
                                            l-0.2,0.5l-0.1,0.5L53.9,15.8z"
                                                        ></path>
                                                        <path
                                                            className="st8"
                                                            d="M53.6,15.2v-0.2l0.1-0.3l0.1-0.3l0.1-0.3l0-0.3l0,0l0,0l-0.2-0.4L53.5,13l-0.3-0.2l0,0.2v0.2
                                            l-0.1,0.7L53,14.5l0,0l0.1,0.3l0.2,0.4l0.3,0.3l0,0l0,0L53.6,15.2z M53.5,15l-0.2-0.3l-0.1-0.3l-0.1-0.3l0-0.4l0.1-0.6
                                            v-0.1l0.3,0.4l0.1,0.2l0,0.3l-0.1,0.5l-0.1,0.3L53.5,15z"
                                                        ></path>
                                                        <polygon
                                                            className="st8"
                                                            points="53.8,14.8 53.9,14.1 54,13.9 53.9,13.7 53.9,13.7 53.9,14 53.8,14.4 53.7,14.7 53.6,15.1
                                            53.6,15.2 53.7,15.4 53.7,15.4 53.7,15.1                                         "
                                                        ></polygon>
                                                        <path
                                                            className="st8"
                                                            d="M52.9,14.1l0.1-0.7l0.1-0.3l0-0.3l0,0l0,0L53,12.3L52.7,12l-0.3-0.2l0.1,0.2v0.2l-0.1,0.7l0,0.3v0.3
                                            l0,0l0.2,0.4l0.3,0.4l0.3,0.3l0-0.1L52.9,14.1z M52.9,14.1l-0.2-0.3l-0.1-0.3l-0.1-0.3l0-0.3l0.1-0.6v-0.1l0.3,0.4
                                            l0.1,0.2l0,0.3l-0.1,0.5l-0.1,0.3L52.9,14.1z"
                                                        ></path>
                                                        <polygon
                                                            className="st8"
                                                            points="53.1,13.8 53.2,13.1 53.2,12.9 53.2,12.7 53.2,12.7 53.1,13 53.1,13.4 52.9,14.1 53,14.4
                                            53,14.5 53,14.5                                         "
                                                        ></polygon>
                                                        <path
                                                            className="st8"
                                                            d="M52.2,13.3v-0.2l0.1-0.7l0.1-0.3v-0.3l0,0l0,0l-0.2-0.4l-0.3-0.3l-0.3-0.2l0,0.2v0.2l-0.1,0.7l0,0.7
                                            l0.1,0.3l0.3,0.3l0.3,0.2l0,0L52.2,13.3z M52.1,13.1l-0.2-0.3l-0.2-0.3l-0.1-0.3v-0.3l0.1-0.6v-0.1l0.2,0.2l0.1,0.2
                                            l0.1,0.2l0.1,0.3l-0.1,0.5l-0.1,0.3L52.1,13.1z"
                                                        ></path>
                                                        <path
                                                            className="st8"
                                                            d="M51.5,12.2l0-0.3l0.1-0.3l0-0.3v-0.3l0,0l0,0l-0.2-0.4l-0.3-0.4l-0.3-0.2l0,0l0,0l0.1,0.2v0.2l0,0.7
                                            l0,0.7l0,0L51,12l0.3,0.4l0.3,0.3l0,0l-0.1-0.1L51.5,12.2z M51.4,12.2l-0.2-0.3L51,11.6l-0.1-0.3v-0.3l0.1-0.6v-0.1
                                            l0.2,0.2l0.2,0.2l0.1,0.2l0.1,0.3l-0.1,0.5L51.4,12.2z"
                                                        ></path>
                                                        <polygon
                                                            className="st8"
                                                            points="51.7,11.2 51.7,11 51.6,10.8 51.6,10.8 51.6,11.1 51.6,11.5 51.5,11.8 51.5,12.2 51.6,12.5
                                            51.6,12.6 51.6,11.9                                         "
                                                        ></polygon>
                                                        <path
                                                            className="st8"
                                                            d="M50.7,11.4l0-0.2l0.1-0.7l0-0.4l0-0.3l-0.2-0.3l-0.3-0.3l-0.3-0.2l0,0l0.1,0.2l0,0.2l0,0.7l0.1,0.6
                                            l0.2,0.3l0.3,0.3l0.3,0.2l-0.1-0.1L50.7,11.4z M50.6,10.8v0.5l-0.3-0.3l-0.2-0.3L50,10.4l0-0.3l0.1-0.6V9.4l0.2,0.2
                                            l0.2,0.2l0.1,0.2l0.1,0.3L50.6,10.8z"
                                                        ></path>
                                                        <polygon
                                                            className="st8"
                                                            points="50.8,11 50.8,10.3 50.8,10.1 50.8,9.9 50.7,9.9 50.8,10.2 50.7,10.5 50.7,11.3 50.7,11.4
                                            50.8,11.6 50.8,11.7 50.8,11.7                                       "
                                                        ></polygon>
                                                        <path
                                                            className="st8"
                                                            d="M57.5,38.9l-0.7,0.3l-0.6,0.3L56,39.8l-0.2,0.4l-0.1,0.4l0.1-0.1l0.1-0.1l0.1-0.1l0.7-0.3l0.3-0.1
                                            l0.3-0.1l0.2-0.3l0.1-0.4l0-0.4l-0.1,0l-0.1,0.1L57.5,38.9z M57.4,39.5l-0.2,0.3L57,39.9l-0.5,0.2l-0.3,0.1L56,40.3
                                            l0.1-0.4l0.2-0.3l0.3-0.3l0.3-0.2l0.6-0.3l0.2-0.1L57.4,39.5z"
                                                        ></path>
                                                        <path
                                                            className="st8"
                                                            d="M57.8,37.7L57.2,38l-0.6,0.4l0,0l-0.2,0.3l-0.1,0.4l-0.1,0.4l0,0l0.1-0.1l0.1-0.1l0.1-0.1l0.3-0.1
                                            l0.3-0.1l0.3-0.1l0.2-0.1l0,0l0.2-0.4l0.1-0.4l0-0.4L58,37.6L57.8,37.7z M57.8,38.3l-0.1,0.3l-0.2,0.2L57,39l-0.5,0.3
                                            l0.1-0.4l0.2-0.3l0.3-0.3l0.3-0.2l0.5-0.2l0.1-0.1L57.8,38.3z"
                                                        ></path>
                                                        <polygon
                                                            className="st8"
                                                            points="57.2,38.9 56.9,39.1 56.6,39.2 56.4,39.3 56.3,39.4 56.2,39.5 56.8,39.2 57.5,38.9
                                            57.6,38.8 57.7,38.7 57.5,38.8                                       "
                                                        ></polygon>
                                                        <path
                                                            className="st8"
                                                            d="M58.2,36.5l-0.7,0.4L57,37.3l0-0.1l-0.2,0.4L56.7,38l-0.1,0.4l0.1-0.1l0.1-0.1l0.1-0.1l0.3-0.1
                                            l0.3-0.1l0.3-0.1l0.3-0.2l0.2-0.4l0.1-0.4v-0.4l-0.1,0.1L58.2,36.5z M58.1,37.1L58,37.4l-0.2,0.2l-0.5,0.2l-0.5,0.3
                                            l0.1-0.4l0.1-0.4l0.3-0.3l0.1-0.1l0.1-0.1l0.6-0.3l0.1-0.1L58.1,37.1z"
                                                        ></path>
                                                        <polygon
                                                            className="st8"
                                                            points="57.6,37.8 57.2,37.9 56.9,38 56.8,38.2 56.7,38.3 56.6,38.4 56.6,38.4 57.2,38 57.8,37.7
                                            58,37.6 58.1,37.4 57.9,37.6                                         "
                                                        ></polygon>
                                                        <path
                                                            className="st8"
                                                            d="M58.6,35L58.6,35l-0.1,0.1l-0.1,0.1l-0.6,0.4l-0.6,0.5v0l-0.1,0.2L57,36.9l0,0.3l0-0.1l0.1-0.2
                                            l0.1-0.1l0.3-0.2l0.3-0.1l0.3-0.2l0.3-0.2l0.2-0.4l0.1-0.4L58.6,35z M58.4,35.9l-0.1,0.2l-0.2,0.2l-0.5,0.3l-0.5,0.3
                                            l0.1-0.3l0.1-0.3l0.2-0.3l0.3-0.2l0.5-0.3l0.1-0.1L58.4,35.9z"
                                                        ></path>
                                                        <polygon
                                                            className="st8"
                                                            points="57.8,36.6 57.5,36.7 57.2,36.9 57.1,37 57,37.2 57,37.2 57,37.3 57.5,36.9 58.2,36.5
                                            58.3,36.4 58.4,36.2 58.2,36.4                                       "
                                                        ></polygon>
                                                    </g>
                                                    <path
                                                        className="st8"
                                                        d="M34.6,2.1L35,2.6l0.4,0.5l0.3,0.1l0.5,0.1l0.4,0l-0.1,0l-0.3-0.2l-0.4-0.6l-0.2-0.3L35.5,2l0.1,0
                                        l-0.1,0l-0.4-0.2l-0.4-0.1l-0.4,0.1l0.2,0.1L34.6,2.1z M35.2,2l0.2,0.1l0.2,0.2l0.3,0.5L36,3l0.1,0.2l-0.3-0.1L35.5,3
                                        l-0.3-0.2L35,2.6L34.7,2l-0.1-0.1L35.2,2z"
                                                    ></path>
                                                    <path
                                                        className="st8"
                                                        d="M33.4,1.9l0.5,0.6L34.3,3h0l0.3,0.1l0.5,0.1h0.4l0,0l-0.1,0L35.2,3l-0.1-0.1l-0.4-0.6L34.5,2l-0.2-0.2
                                        l-0.4-0.1l-0.4-0.1l-0.4,0.1l0.2,0.1L33.4,1.9z M33.9,1.9L34.2,2l0.2,0.1l0.3,0.5L35,3L34.7,3l-0.3-0.1l-0.3-0.2l-0.2-0.3
                                        l-0.3-0.5l-0.1-0.1L33.9,1.9z"
                                                    ></path>
                                                    <polygon
                                                        className="st8"
                                                        points="34.7,2.3 35.1,2.9 35.2,3 35.4,3.1 35.5,3.2 35,2.6 34.6,2.1 34.5,1.9 34.3,1.8 34.5,2
                                                                            "
                                                    ></polygon>
                                                    <path
                                                        className="st8"
                                                        d="M32.1,1.8l0.5,0.5l0.5,0.5l0,0L33.4,3l0.5,0h0.4l-0.1,0L34,2.9l-0.1-0.1l-0.2-0.3l-0.2-0.3l-0.2-0.3
                                        l-0.2-0.2l-0.4-0.1l-0.5,0l-0.4,0.1L32,1.7L32.1,1.8z M32.7,1.8L33,1.8L33.2,2l0.3,0.5l0.1,0.2l0.2,0.2l-0.3-0.1l-0.3-0.1
                                        l-0.3-0.2l-0.2-0.3l-0.4-0.5l-0.1-0.1L32.7,1.8z"
                                                    ></path>
                                                    <polygon
                                                        className="st8"
                                                        points="33.5,2.2 33.7,2.5 33.9,2.8 34,2.9 34.2,3 34.3,3 34.3,3 33.8,2.5 33.4,1.9 33.3,1.8
                                        33.1,1.7 33.3,1.9                                   "
                                                    ></polygon>
                                                    <path
                                                        className="st8"
                                                        d="M30.9,1.8l0.5,0.5l0.5,0.5l0,0l0.4,0.1l0.5,0l0.4,0l-0.1,0l-0.2-0.1l-0.1-0.1l-0.4-0.6l-0.2-0.3
                                        l-0.2-0.2l-0.4-0.1l-0.5,0l-0.4,0.1l0.2,0.1L30.9,1.8z M31.2,1.7h0.3l0.3,0.1L32,1.9l0.3,0.4l0.2,0.2l0.2,0.2h-0.4
                                        l-0.3-0.1l-0.3-0.2l-0.3-0.3L31,1.8L31,1.7H31.2z"
                                                    ></path>
                                                    <polygon
                                                        className="st8"
                                                        points="32.3,2.1 32.7,2.6 32.8,2.7 33,2.8 33.1,2.8 33.1,2.8 32.6,2.4 32.1,1.8 32,1.7 31.9,1.6
                                        32.1,1.8                                    "
                                                    ></polygon>
                                                    <path
                                                        className="st8"
                                                        d="M29.7,1.8l0.5,0.5l0.3,0.3l0.2,0.2L31,2.8h0.5l0.3-0.1l0,0l-0.2-0.1l-0.1-0.1l-0.2-0.3L31,2l-0.2-0.3
                                        l-0.2-0.2l-0.4-0.1l-0.5,0l-0.3,0.1l0.1,0.1L29.7,1.8z M30.2,1.7l0.3,0.1l0.2,0.1l0.4,0.4l0.3,0.4h-0.3l-0.3-0.1l-0.3-0.1
                                        l-0.3-0.2l-0.4-0.5l-0.1-0.1H30.2z"
                                                    ></path>
                                                    <polygon
                                                        className="st8"
                                                        points="31,2 31.3,2.3 31.5,2.6 31.7,2.7 31.8,2.8 31.8,2.8 31.9,2.8 31.4,2.3 30.9,1.8 30.8,1.7
                                        30.6,1.6 30.8,1.8                                   "
                                                    ></polygon>
                                                    <path
                                                        className="st8"
                                                        d="M28.4,1.9L29,2.4l0.3,0.2l0.2,0.2l0.4,0.1h0.4l0.4-0.1l0,0l0,0l-0.3-0.1l-0.5-0.5l-0.2-0.3l-0.3-0.2
                                        l0.1,0l0,0l-0.4-0.1l-0.4,0.1l-0.3,0.1l0.1,0L28.4,1.9z M29,1.7l0.3,0l0.3,0.1l0.4,0.4l0.2,0.2l0.2,0.2l-0.4,0l-0.3-0.1
                                        l-0.3-0.1L29,2.3l-0.4-0.4l-0.1-0.1L29,1.7z"
                                                    ></path>
                                                    <polygon
                                                        className="st8"
                                                        points="29.6,1.8 29.8,2.1 30.3,2.6 30.6,2.7 30.6,2.7 30.4,2.6 30.2,2.3 29.7,1.8 29.5,1.7 29.4,1.6
                                        29.3,1.6                                    "
                                                    ></polygon>
                                                    <path
                                                        className="st8"
                                                        d="M27.2,2l0.5,0.5L28,2.7l0.3,0.2l0,0l0.4,0.1l0.5,0l0.4-0.1l0,0l0,0l-0.3-0.1l-0.5-0.5l-0.3-0.2
                                        l-0.3-0.2l0.1,0l0,0l-0.4-0.1l-0.4,0.1l-0.3,0.1l0.2,0.1L27.2,2z M27.8,1.8l0.3,0L28.3,2l0.4,0.4l0.4,0.4l-0.4,0l-0.3,0
                                        l-0.3-0.1l-0.3-0.2L27.3,2l-0.1-0.1L27.8,1.8z"
                                                    ></path>
                                                    <polygon
                                                        className="st8"
                                                        points="28.4,1.9 28.6,2.1 29.1,2.6 29.4,2.8 29.5,2.8 29.2,2.6 29,2.4 28.4,1.9 28.3,1.8 28.1,1.7
                                        28.1,1.7                                    "
                                                    ></polygon>
                                                    <path
                                                        className="st8"
                                                        d="M26,2.2l0.5,0.5l0.3,0.2L27.1,3l-0.1,0l0,0h0.4l0.4,0l0.4-0.1l0,0l-0.3-0.1l-0.5-0.5L27.1,2l-0.3-0.2
                                        l0,0l0,0l-0.4,0L26,1.9L25.7,2l0.1,0L26,2.2z M26.5,2h0.3L27,2.1l0.4,0.4l0.2,0.2l0.2,0.1l-0.3,0.1l-0.3,0l-0.3-0.1
                                        l-0.3-0.2l-0.5-0.4L26,2.1L26.5,2z"
                                                    ></path>
                                                    <polygon
                                                        className="st8"
                                                        points="27.1,2 27.4,2.2 27.9,2.7 28.3,2.8 28.3,2.8 28.3,2.8 28,2.7 27.8,2.5 27.2,2 27.1,1.9
                                        26.9,1.8 26.9,1.8                                   "
                                                    ></polygon>
                                                    <path
                                                        className="st8"
                                                        d="M24.8,2.4l0.6,0.4L25.7,3l0.3,0.1l0,0l0.4,0l0.4-0.1L27.1,3l-0.3-0.1l-0.3-0.2l-0.3-0.3l-0.3-0.2
                                        L25.7,2l0,0l0,0h-0.4l-0.4,0.1l-0.3,0.2l0.1,0L24.8,2.4z M25.1,2.2l0.3,0h0.3l0.3,0.1l0.4,0.3l0.4,0.3L26.3,3H26l-0.3-0.1
                                        l-0.3-0.2l-0.5-0.4l-0.1-0.1L25.1,2.2z"
                                                    ></path>
                                                    <polygon
                                                        className="st8"
                                                        points="25.9,2.2 26.2,2.4 26.5,2.7 26.7,2.9 27.1,3 27.1,3 26.8,2.8 26.5,2.6 26,2.2 25.8,2.1
                                        25.7,2 25.7,2                                   "
                                                    ></polygon>
                                                    <path
                                                        className="st8"
                                                        d="M23.6,2.7l0.6,0.4l0.6,0.3l0.4,0l0.4-0.1l0.3-0.1l0,0L25.6,3L25,2.6l-0.3-0.2l-0.3-0.1l0.1,0l-0.1,0
                                        l-0.4,0l-0.4,0.1l-0.3,0.2l0.1,0L23.6,2.7z M24.1,2.4h0.3l0.3,0.1l0.4,0.3L25.3,3l0.2,0.1l-0.3,0.1l-0.3,0l-0.3-0.1L24.2,3
                                        l-0.5-0.3l-0.1-0.1L24.1,2.4z"
                                                    ></path>
                                                    <polygon
                                                        className="st8"
                                                        points="24.7,2.4 25,2.6 25.6,3 25.9,3.1 25.9,3.1 25.9,3.1 25.7,3 25.3,2.8 24.8,2.4 24.6,2.3
                                        24.5,2.3 24.4,2.3                                   "
                                                    ></polygon>
                                                    <path
                                                        className="st8"
                                                        d="M22.4,3L23,3.4l0.5,0.3l0,0l0.4,0l0.5-0.1l0.3-0.1l0,0l-0.1,0l-0.3-0.1l-0.3-0.2l-0.3-0.2l-0.3-0.2
                                        l-0.3-0.1l0.1-0.1l-0.1,0l-0.4,0l-0.4,0.1L22,2.9l0.2,0L22.4,3z M22.9,2.7h0.3l0.3,0.1l0.4,0.3l0.2,0.1l0.2,0.1L24,3.5
                                        l-0.3,0l-0.3-0.1L23,3.2l-0.5-0.3l-0.1-0.1L22.9,2.7z"
                                                    ></path>
                                                    <polygon
                                                        className="st8"
                                                        points="23.5,2.7 23.8,2.9 24.1,3.1 24.4,3.3 24.7,3.4 24.8,3.4 24.2,3.1 23.6,2.7 23.4,2.6 23.3,2.6
                                        23.2,2.6                                    "
                                                    ></polygon>
                                                </g>
                                                <path
                                                    className="st8"
                                                    d="M21.2,3.4l0.6,0.3l0.3,0.1L22.5,4l0.4,0l0.4-0.1l0.3-0.2l-0.1,0l-0.3-0.1l-0.3-0.2l-0.3-0.2L22.3,3
                                    L22,2.9l-0.4,0l-0.4,0.1l-0.3,0.2l0.1,0l0.1,0L21.2,3.4z M21.8,3.1l0.2,0l0.3,0.1l0.5,0.3l0.5,0.3l-0.3,0.1l-0.3,0l-0.3-0.1
                                    l-0.3-0.1l-0.5-0.3l-0.1-0.1L21.8,3.1z"
                                                ></path>
                                                <path
                                                    className="st8"
                                                    d="M20.1,3.8l0.7,0.3l0.7,0.2l0,0l0.4-0.1l0.4-0.1L22.5,4l0,0l-0.1,0L22,3.9l-0.6-0.4l-0.3-0.2L21,3.3
                                    l-0.1,0l-0.4,0.1l-0.4,0.1l-0.3,0.2l0.1,0h0.1L20.1,3.8z M20.6,3.5l0.3,0l0.3,0l0.5,0.3l0.2,0.1L22,4.1l-0.3,0.1l-0.3,0
                                    l-0.3,0L20.7,4l-0.5-0.3l-0.1,0L20.6,3.5z"
                                                ></path>
                                                <polygon
                                                    className="st8"
                                                    points="21.4,3.6 22,3.9 22.4,4 22.5,4 22.2,3.9 21.9,3.7 21.2,3.4 21.1,3.3 21,3.3 21.2,3.4
                                    "
                                                ></polygon>
                                                <path
                                                    className="st8"
                                                    d="M18.9,4.3l0.6,0.3l0.3,0.1l0.3,0.1l0.4-0.1L21,4.6l0.3-0.2l-0.1,0l-0.4-0.1L20.3,4L20,3.9l-0.2-0.1
                                    h-0.1l-0.4,0.1L18.9,4l-0.3,0.3h0.2L18.9,4.3z M19.1,4.1L19.4,4l0.3,0l0.3,0l0.5,0.2l0.2,0.1l0.3,0.1l-0.3,0.1l-0.3,0.1
                                    l-0.3,0l-0.3-0.1L19,4.2l-0.1,0L19.1,4.1z"
                                                ></path>
                                                <polygon
                                                    className="st8"
                                                    points="20.3,4 20.9,4.3 21.3,4.4 21.4,4.4 21.4,4.4 20.7,4.1 20.1,3.8 19.9,3.8 19.8,3.8 20,3.9
                                                                    "
                                                ></polygon>
                                                <path
                                                    className="st8"
                                                    d="M17.8,4.8l0.7,0.3l0.7,0.1l-0.1,0.1l0.4-0.1L19.9,5l0.3-0.2l0,0l0,0H20l-0.2,0l-0.7-0.3l-0.3-0.1
                                    l-0.3-0.1l-0.4,0.1l-0.4,0.2l-0.3,0.3h0.2L17.8,4.8z M18.3,4.5l0.3-0.1l0.3,0l0.5,0.2l0.3,0.1l0.3,0.1L19.5,5l-0.3,0.1h-0.3
                                    L18.5,5l-0.5-0.2l-0.1,0L18.3,4.5z"
                                                ></path>
                                                <path
                                                    className="st8"
                                                    d="M16.7,5.4l0.7,0.2l0.3,0.1L18,5.8l0.4-0.1l0.4-0.2l0.2-0.2l0,0h-0.3L18.1,5l-0.3-0.1l-0.3-0.1l0,0h0
                                    L17,4.9l-0.4,0.2l-0.3,0.3h0.2L16.7,5.4z M17.2,5.1L17.5,5h0.3l0.5,0.2l0.5,0.2l-0.3,0.2l-0.3,0.1h-0.3l-0.3-0.1l-0.6-0.2
                                    l-0.1,0L17.2,5.1z"
                                                ></path>
                                                <polygon
                                                    className="st8"
                                                    points="17.8,4.9 18.1,5 18.7,5.3 19.1,5.3 19.1,5.3 19.1,5.2 18.5,5.1 17.8,4.8 17.7,4.8 17.5,4.8
                                    17.5,4.8                                "
                                                ></polygon>
                                                <path
                                                    className="st8"
                                                    d="M15.7,6l0.7,0.2l0.3,0.1L17,6.3l0,0l0.4-0.1L17.8,6l0.3-0.2l0,0l0,0l-0.2,0l-0.2,0l-0.3-0.1L17,5.6
                                    l-0.3-0.1l-0.3-0.1l0,0h0L16,5.5l-0.4,0.2L15.3,6l0.2,0L15.7,6z M16.1,5.7l0.2-0.1h0.3l0.5,0.2l0.5,0.1l-0.3,0.2L17,6.2
                                    l-0.3,0l-0.3-0.1L15.7,6l-0.1,0L16.1,5.7z"
                                                ></path>
                                                <path
                                                    className="st8"
                                                    d="M14.6,6.7l0.7,0.2l0.3,0.1l0.3,0l0,0l0,0l0.4-0.1l0.4-0.2L17,6.4l0,0l-0.3,0l-0.7-0.2l-0.3-0.1l-0.3,0
                                    l0,0l0,0l-0.4,0.1l-0.4,0.3l-0.2,0.3l0.2,0H14.6z M15,6.3l0.3-0.1l0.3,0l0.5,0.2l0.3,0.1l0.3,0.1l-0.3,0.2L16,6.8l-0.3,0
                                    l-0.3-0.1l-0.6-0.1l-0.1,0L15,6.3z"
                                                ></path>
                                                <polygon
                                                    className="st8"
                                                    points="15.6,6.1 15.9,6.2 16.6,6.4 17,6.4 17,6.4 17,6.3 16.7,6.3 16.3,6.2 15.7,6 15.5,6 15.3,6
                                    15.3,6.1                                "
                                                ></polygon>
                                                <path
                                                    className="st8"
                                                    d="M13.6,7.4l0.7,0.1l0.3,0.1H15l0,0l0.4-0.2l0.3-0.3L16,7L15.6,7L15.2,7l-0.3-0.1l-0.3-0.1l-0.3,0l0,0
                                    l0,0l-0.4,0.2l-0.3,0.3l-0.2,0.2l0.1,0H13.6z M14,7l0.3-0.1l0.3,0L15,7l0.5,0.1l-0.3,0.2L15,7.5l-0.3,0.1l-0.3,0l-0.6-0.1
                                    h-0.1L14,7z"
                                                ></path>
                                                <polygon
                                                    className="st8"
                                                    points="14.6,6.8 14.9,6.9 15.2,7 15.6,7 16,7 16,6.9 15.6,6.9 15.3,6.9 14.6,6.7 14.5,6.7 14.3,6.7
                                    14.3,6.7                                "
                                                ></polygon>
                                                <path
                                                    className="st8"
                                                    d="M12.7,8.2l0.7,0.1l0.3,0.1h0.2l0.1,0l0.4-0.2l0.3-0.3L15,7.6l-0.1,0l-0.3,0.1l-0.3-0.1l-0.3-0.1
                                    l-0.3-0.1l-0.3,0l0.1-0.1l-0.1,0l-0.4,0.2l-0.3,0.3l-0.2,0.2l0.1,0H12.7z M13,7.8l0.2-0.1l0.3-0.1l0.5,0.1l0.3,0.1l0.3,0
                                    L14.3,8L14,8.2l-0.3,0.1h-0.3l-0.6-0.1h-0.1L13,7.8z"
                                                ></path>
                                                <polygon
                                                    className="st8"
                                                    points="13.6,7.5 13.9,7.6 14.3,7.6 14.6,7.7 15,7.6 15,7.6 15,7.6 14.7,7.6 14.3,7.6 13.6,7.4
                                    13.4,7.4 13.3,7.4 13.3,7.5                              "
                                                ></polygon>
                                                <path
                                                    className="st8"
                                                    d="M11.7,9l0.7,0.1l0.6,0l0.4-0.2l0.3-0.3L14,8.4h-0.2l-0.2,0.1l-0.7-0.1l-0.3,0h-0.3l0.1-0.1l-0.1,0
                                    l-0.4,0.2l-0.3,0.3L11.3,9L11.5,9H11.7z M12.1,8.5l0.2-0.1l0.3-0.1l0.5,0.1l0.3,0.1l0.3,0l-0.3,0.2l-0.3,0.1L12.7,9l-0.3,0
                                    l-0.6-0.1l-0.1,0L12.1,8.5z"
                                                ></path>
                                                <polygon
                                                    className="st8"
                                                    points="12.6,8.3 12.9,8.3 13.6,8.4 13.9,8.4 13.7,8.4 13.3,8.3 12.7,8.2 12.5,8.2 12.4,8.2 12.3,8.3
                                                                    "
                                                ></polygon>
                                                <path
                                                    className="st8"
                                                    d="M10.8,9.8l0.7,0.1l0.3,0l0.3,0l0,0l0.4-0.2l0.3-0.3l0.3-0.3l-0.1,0l0,0l-0.2,0.1h-0.2l-0.3,0L12,9.1
                                    L11.7,9h-0.3L11,9.2l-0.3,0.3l-0.2,0.3l0.2-0.1H10.8z M10.9,9.5l0.2-0.2l0.2-0.1l0.3-0.1l0.5,0.1l0.3,0.1h0.3l-0.3,0.2
                                    l-0.3,0.2l-0.3,0.1h-0.3l-0.6-0.1h-0.1L10.9,9.5z"
                                                ></path>
                                                <path
                                                    className="st8"
                                                    d="M10,10.7l0.7,0l0.3,0l0.3,0l0,0l0.4-0.2l0.3-0.3l0.2-0.3l-0.1,0L12,9.9l-0.2,0l-0.7-0.1l-0.3,0h-0.3
                                    l-0.4,0.2l-0.3,0.3l-0.2,0.3l0.2-0.1L10,10.7z M10.3,10.2l0.2-0.1l0.3-0.1l0.5,0.1l0.3,0h0.3l-0.3,0.3l-0.3,0.2l-0.3,0.1
                                    h-0.3l-0.6,0H9.9L10.3,10.2z"
                                                ></path>
                                                <path
                                                    className="st8"
                                                    d="M9.1,11.6h0.7l0.3,0l0.3-0.1l0,0l0.3-0.2l0.3-0.3l0.2-0.3l-0.1,0l-0.2,0.1l-0.2,0h-0.3h-0.3l-0.3,0
                                    l-0.3,0L9.2,11L9,11.4l-0.1,0.3L9,11.6L9.1,11.6z M9.4,11.1L9.6,11l0.2-0.1l0.5,0l0.3,0l0.3,0l-0.2,0.3l-0.3,0.2l-0.3,0.1
                                    l-0.3,0.1l-0.6,0l-0.1,0L9.4,11.1z"
                                                ></path>
                                                <polygon
                                                    className="st8"
                                                    points="10.3,10.8 10.6,10.8 10.9,10.8 11.1,10.8 11.3,10.7 11,10.7 10.7,10.7 10,10.7 9.8,10.7
                                    9.6,10.8 9.9,10.8                               "
                                                ></polygon>
                                                <path
                                                    className="st8"
                                                    d="M8.3,12.6L9,12.5l0.3,0l0.3-0.1l0,0l0.3-0.2l0.3-0.3l0.2-0.3l0,0l-0.3,0.1l-0.3,0l-0.3,0H9.1l-0.3,0.1
                                    l0-0.1l-0.1,0L8.4,12l-0.3,0.3L8,12.7l0.1-0.1L8.3,12.6z M8.4,12.3l0.2-0.2l0.2-0.1l0.3-0.1h0.5l0.5,0L9.9,12l-0.3,0.2
                                    l-0.3,0.1L9,12.5l-0.6,0H8.3L8.4,12.3z"
                                                ></path>
                                                <polygon
                                                    className="st8"
                                                    points="9.1,11.7 9.4,11.7 9.8,11.7 10.1,11.7 10.5,11.6 10.5,11.5 10.5,11.5 10.2,11.6 9.8,11.6
                                    9.1,11.6 9,11.6 8.8,11.7 8.8,11.7                               "
                                                ></polygon>
                                            </g>
                                            <path
                                                className="st8"
                                                d="M7.6,13.5l0.7-0.1l0.3-0.1L9,13.3l0,0.1l0,0l0.3-0.3l0.3-0.3l0.2-0.3l0,0l-0.2,0.1l-0.1,0.1l-0.7,0.1
                                l-0.3,0L8,12.7l0-0.1l0,0L7.7,13l-0.3,0.4l-0.1,0.3l0.1-0.1L7.6,13.5z M7.8,13.1L8,12.9l0.2-0.1l0.5-0.1l0.5-0.1l-0.2,0.3
                                l-0.2,0.2l-0.3,0.2l-0.3,0.1l-0.6,0.1l-0.1,0L7.8,13.1z"
                                            ></path>
                                            <path
                                                className="st8"
                                                d="M6.9,14.6l0.7-0.1l0.7-0.2l0,0.1l0,0L8.5,14l0.3-0.3l0.1-0.3l-0.2,0.1l-0.1,0.1l-0.3,0.1l-0.3,0l-0.3,0
                                l-0.3,0.1l0-0.1l0,0L7,14l-0.2,0.4l-0.1,0.3l0.1-0.1L6.9,14.6z M7.1,14.1l0.2-0.2l0.2-0.1l0.5-0.1l0.3,0l0.3-0.1l-0.2,0.3
                                l-0.3,0.2l-0.3,0.2l-0.3,0.1L7,14.5l-0.1,0L7.1,14.1z"
                                            ></path>
                                            <polygon
                                                className="st8"
                                                points="7.6,13.6 7.9,13.6 8.3,13.6 8.6,13.5 8.8,13.5 8.9,13.4 9,13.3 8.6,13.4 8.3,13.5 7.6,13.5
                                7.4,13.6 7.3,13.7 7.3,13.7                          "
                                            ></polygon>
                                            <path
                                                className="st8"
                                                d="M6.3,15.6L7,15.5l0.3-0.1l0.2-0.1l0,0L7.9,15l0.2-0.4l0.1-0.3l-0.1,0.1l-0.1,0.1l-0.3,0.1l-0.3,0.1
                                l-0.3,0.1l-0.3,0.1l0-0.1l-0.1,0.1L6.3,15l-0.2,0.4L6,15.7l0.1-0.1L6.3,15.6z M6.5,15.1l0.2-0.2l0.2-0.1l0.5-0.1l0.5-0.1
                                l-0.2,0.3l-0.2,0.3l-0.3,0.2l-0.3,0.1l-0.6,0.1l-0.1,0L6.5,15.1z"
                                            ></path>
                                            <polygon
                                                className="st8"
                                                points="6.9,14.7 7.2,14.6 7.6,14.6 7.9,14.5 8.1,14.4 8.2,14.3 8.2,14.3 7.6,14.5 6.9,14.6 6.7,14.6
                                6.6,14.7 6.6,14.8                           "
                                            ></polygon>
                                            <path
                                                className="st8"
                                                d="M5.5,16.8l0.2-0.1l0.7-0.1l0.3-0.1l0.2-0.1l0.1,0L7.2,16l0.3-0.4l0.1-0.3l-0.1,0l-0.3,0.2l-0.7,0.2
                                l-0.3,0.1l-0.3,0.1l0-0.1l-0.1,0l-0.3,0.3l-0.2,0.4l-0.1,0.4l0,0l0,0L5.5,16.8z M5.8,16.2L6,16l0.2-0.1l0.5-0.1L7,15.7
                                l0.3-0.1l-0.2,0.3l-0.2,0.3l-0.3,0.2l-0.3,0.1l-0.6,0.1l-0.1,0L5.8,16.2z"
                                            ></path>
                                            <path
                                                className="st8"
                                                d="M5.1,17.8l0.7-0.2l0.6-0.2l0.3-0.3l0.2-0.4L7,16.3l-0.1,0l-0.2,0.2l-0.3,0.1l-0.3,0.1l-0.3,0.1l-0.3,0.1
                                l-0.3,0.3l-0.2,0.4L4.8,18l0.1-0.1L5.1,17.8z M5.2,17.2L5.4,17l0.2-0.1l0.5-0.1l0.5-0.1L6.5,17l-0.2,0.3L6,17.4l-0.3,0.1
                                l-0.6,0.1l-0.1,0L5.2,17.2z"
                                            ></path>
                                            <polygon
                                                className="st8"
                                                points="5.9,16.7 6.3,16.6 6.6,16.5 6.9,16.4 6.7,16.4 6.3,16.6 5.7,16.7 5.5,16.8 5.3,16.9 5.3,16.9
                                5.6,16.8                            "
                                            ></polygon>
                                            <path
                                                className="st8"
                                                d="M4.6,18.9l0.7-0.2l0.3-0.1l0.3-0.1l0.2-0.3l0.2-0.4l0.1-0.4l-0.1,0l0,0l-0.1,0.1l-0.1,0.1l-0.7,0.2
                                l-0.3,0.1L4.8,18l-0.3,0.3l-0.2,0.4l-0.1,0.4L4.4,19L4.6,18.9z M4.7,18.4l0.1-0.2L5.1,18l0.5-0.1l0.5-0.2L6,18l-0.2,0.3
                                l-0.3,0.2l-0.3,0.1l-0.6,0.2l-0.1,0L4.7,18.4z"
                                            ></path>
                                            <polygon
                                                className="st8"
                                                points="5.4,17.8 6.1,17.6 6.2,17.5 6.3,17.4 6.4,17.4 5.8,17.6 5.1,17.8 4.9,17.9 4.8,18 5.1,17.9
                                                            "
                                            ></polygon>
                                            <path
                                                className="st8"
                                                d="M4.1,20.1l0.7-0.3l0.3-0.1l0.3-0.2l0,0.1l0,0l0.2-0.4l0.2-0.4l0.1-0.4l0,0l0,0l-0.1,0.1l-0.1,0.1
                                l-0.3,0.1l-0.3,0.1L4.6,19l-0.3,0.1l0,0l0,0l-0.2,0.4l-0.2,0.4l0,0.4L4,20.1L4.1,20.1z M4.2,19.5l0.1-0.2l0.2-0.2l0.5-0.2
                                l0.5-0.2l-0.1,0.3l-0.2,0.3L5,19.6l-0.3,0.1L4.1,20l-0.1,0L4.2,19.5z"
                                            ></path>
                                            <polygon
                                                className="st8"
                                                points="4.6,19 4.9,18.9 5.2,18.8 5.5,18.7 5.7,18.6 5.8,18.5 5.8,18.4 5.6,18.6 5.3,18.7 4.6,18.9
                                4.4,19 4.3,19.1 4.3,19.1                            "
                                            ></polygon>
                                            <path
                                                className="st8"
                                                d="M3.7,21.2l0.7-0.3l0.3-0.2l0.3-0.2l0,0.1l0,0l0.2-0.4l0.1-0.4l0.1-0.3l-0.3,0.2l-0.3,0.1L4.4,20
                                l-0.3,0.1l-0.3,0.2l0-0.1l0,0l-0.2,0.4l-0.1,0.4l0,0.4l0.1-0.1L3.7,21.2z M3.8,20.7l0.1-0.2l0.2-0.2l0.5-0.2l0.3-0.1l0.2-0.1
                                L5,20.2l-0.2,0.3l-0.2,0.2l-0.3,0.2l-0.6,0.2l-0.1,0.1L3.8,20.7z"
                                            ></path>
                                            <polygon
                                                className="st8"
                                                points="4.1,20.1 4.4,20 4.8,19.9 5.1,19.8 5.4,19.6 5.4,19.5 5.1,19.7 4.8,19.8 4.1,20.1 4,20.1
                                3.9,20.3 3.9,20.3                           "
                                            ></polygon>
                                            <path
                                                className="st8"
                                                d="M3.3,22.4L4,22.1l0.3-0.2l0.3-0.2l0,0.1l0,0l0.2-0.4L4.9,21l0-0.3l-0.1,0.1l-0.1,0.1L4,21.2l-0.3,0.1
                                l-0.3,0.2l0-0.1l-0.1,0.1l-0.2,0.4l-0.1,0.4v0.3l0.1-0.1L3.3,22.4z M3.4,21.8l0.1-0.2l0.2-0.2l0.5-0.2l0.5-0.2l-0.1,0.3
                                l-0.2,0.3l-0.2,0.3L3.9,22l-0.5,0.3l-0.1,0L3.4,21.8z"
                                            ></path>
                                            <path
                                                className="st8"
                                                d="M3,23.6l0.6-0.3l0.3-0.2l0.3-0.2v0.1l0,0l0.2-0.4l0.1-0.4l0-0.3l-0.1,0.1L4.3,22L4,22.2l-0.3,0.1
                                l-0.3,0.1l-0.3,0.2v-0.1L3,22.7l-0.2,0.4l-0.1,0.4l0,0.3l0.1-0.1L3,23.6z M3.1,23l0.1-0.2l0.2-0.2l0.5-0.3l0.5-0.3l-0.1,0.3
                                l-0.1,0.3L3.9,23l-0.3,0.2L3,23.5L3,23.6L3.1,23z"
                                            ></path>
                                            <polygon
                                                className="st8"
                                                points="3.4,22.5 3.7,22.4 4,22.2 4.3,22 4.4,21.9 4.5,21.8 4.5,21.7 4.3,21.9 4,22.1 3.3,22.4
                                3.2,22.5 3.1,22.6 3.1,22.7                          "
                                            ></polygon>
                                            <path
                                                className="st8"
                                                d="M2.8,24.8l0.6-0.3l0.5-0.4l0,0l0.2-0.4l0.1-0.4v-0.3l-0.1,0.1L4,23.2l-0.3,0.2l-0.3,0.1l-0.3,0.2
                                l-0.3,0.2l0-0.1l-0.1,0.1l-0.2,0.4l-0.1,0.4l0,0.3l0.1-0.1L2.8,24.8z M2.7,24.5l0.1-0.3L2.9,24L3,23.8l0.5-0.3l0.3-0.1
                                L4,23.2l-0.1,0.3l-0.1,0.3l-0.2,0.3l-0.3,0.2l-0.5,0.3l-0.1,0.1V24.5z"
                                            ></path>
                                            <polygon
                                                className="st8"
                                                points="3.1,23.7 3.4,23.5 3.7,23.4 4,23.2 4.1,23.1 4.2,22.9 4.2,22.8 3.9,23.1 3.6,23.3 3,23.6
                                2.9,23.7 2.8,23.8 2.8,23.9                          "
                                            ></polygon>
                                            <path
                                                className="st8"
                                                d="M2.6,26l0.6-0.4l0.5-0.4l0.1-0.4l0.1-0.4l0-0.4l-0.1,0l-0.2,0.2l-0.3,0.2l-0.3,0.2l-0.3,0.2l-0.2,0.2
                                l0-0.1l0,0.1l-0.2,0.4l-0.1,0.4l0.1,0.4l0.1-0.1L2.6,26z M2.6,25.5l0.1-0.3L2.8,25l0.5-0.3l0.5-0.3l-0.1,0.3l-0.1,0.3
                                l-0.2,0.3l-0.3,0.2l-0.5,0.3L2.5,26L2.6,25.5z"
                                            ></path>
                                            <polygon
                                                className="st8"
                                                points="2.8,24.9 3.1,24.7 3.4,24.5 3.7,24.3 3.9,24.1 3.4,24.4 2.8,24.8 2.6,24.9 2.6,25 2.6,25.1
                                                            "
                                            ></polygon>
                                            <path
                                                className="st8"
                                                d="M2.4,27.3L3,26.8l0.3-0.2l0.2-0.2L3.7,26l0.1-0.4v-0.4l0,0l0,0l-0.1,0.2l-0.1,0.1l-0.6,0.4l-0.3,0.2
                                l-0.2,0.2l0,0l0,0l-0.1,0.4l0,0.4l0.1,0.4l0.1-0.1L2.4,27.3z M2.4,26.7l0.1-0.3l0.1-0.2l0.5-0.3l0.5-0.3l-0.1,0.3l-0.1,0.3
                                l-0.2,0.3l-0.3,0.2l-0.5,0.3l-0.1,0.1L2.4,26.7z"
                                            ></path>
                                            <polygon
                                                className="st8"
                                                points="2.6,26.1 2.9,25.9 3.5,25.5 3.6,25.4 3.7,25.3 3.7,25.2 3.2,25.6 2.6,26 2.4,26.1 2.4,26.3
                                2.4,26.3                            "
                                            ></polygon>
                                            <path
                                                className="st8"
                                                d="M2.3,28.5l0.6-0.4l0.3-0.3l0.2-0.2l0.1-0.4l0.1-0.4v-0.4l0,0l0,0l-0.2,0.3L3,26.9l-0.3,0.2l-0.3,0.2
                                l-0.2,0.2l0,0l0,0l-0.1,0.4v0.4l0.1,0.4l0.1-0.1L2.3,28.5z M2.3,27.9l0.1-0.3l0.1-0.2l0.5-0.3l0.4-0.3l0,0.4l-0.1,0.3L3,27.7
                                L2.8,28l-0.5,0.4l-0.1,0.1L2.3,27.9z"
                                            ></path>
                                            <polygon
                                                className="st8"
                                                points="2.5,27.3 2.7,27.1 3,26.9 3.3,26.7 3.5,26.4 3.5,26.4 3.3,26.6 3,26.8 2.4,27.3 2.3,27.4
                                2.2,27.5 2.2,27.5                           "
                                            ></polygon>
                                        </g>
                                        <path
                                            className="st8"
                                            d="M2.3,29.7l0.5-0.5L3.1,29l0.2-0.3l0,0l0.1-0.4l0-0.4l0-0.4l0,0l0,0.1l-0.1,0.1l-0.1,0.1l-0.3,0.2
                            l-0.3,0.2l-0.3,0.2l-0.2,0.2l0,0l0,0L2,29.2v0.4L2.1,30l0.1-0.1L2.3,29.7z M2.2,29.2l0.1-0.3l0.1-0.2l0.4-0.3L3,28.1L3.3,28
                            v0.3l-0.1,0.3L3,29l-0.2,0.3l-0.5,0.4l-0.1,0.1V29.2z"
                                        ></path>
                                        <path
                                            className="st8"
                                            d="M2.3,31l0.5-0.5L3,30.2l0.2-0.3l0,0l0.1-0.4v-0.5l-0.1-0.3l0,0L3.2,29l-0.1,0.1l-0.2,0.3l-0.3,0.2
                            l-0.3,0.2L2.1,30l0,0l0,0L2,30.4l0,0.4l0.1,0.3l0-0.1L2.3,31z M2.2,30.1l0.1-0.2l0.4-0.4L3,29.4l0.2-0.2v0.4l-0.1,0.3L3,30.2
                            l-0.2,0.3l-0.4,0.4l-0.1,0.1l0-0.5L2.2,30.1z"
                                        ></path>
                                        <polygon
                                            className="st8"
                                            points="2.3,29.8 2.6,29.6 2.9,29.4 3.1,29.1 3.2,29 3.3,28.8 3.3,28.8 3.3,28.8 3.1,29 2.8,29.2
                            2.3,29.7 2.2,29.9 2.1,30 2.1,30                         "
                                        ></polygon>
                                        <path
                                            className="st8"
                                            d="M2.4,32.2l0.5-0.5l0.4-0.5l0,0l0.1-0.4v-0.5L3.3,30l0,0l-0.1,0.3l-0.2,0.3l-0.3,0.2L2.4,31l-0.2,0.3
                            l0-0.1l0,0.1L2,31.7l0.1,0.4l0.1,0.3l0-0.1L2.4,32.2z M2.3,31.4l0.1-0.3l0.4-0.4L3,30.6l0.2-0.2l0,0.3L3.1,31L3,31.4l-0.2,0.3
                            l-0.4,0.4l-0.1,0.1l-0.1-0.5L2.3,31.4z"
                                        ></path>
                                        <polygon
                                            className="st8"
                                            points="2.4,31 2.6,30.8 2.9,30.6 3.1,30.3 3.3,30 3.3,30 3.3,29.9 3,30.2 2.8,30.5 2.3,31 2.2,31.1
                            2.1,31.2 2.2,31.3                       "
                                        ></polygon>
                                        <path
                                            className="st8"
                                            d="M2.5,33.4L3,32.9l0.2-0.3l0.2-0.3l0,0.1l0,0l0-0.4l0-0.4l-0.1-0.4l0,0l-0.1,0.2l-0.1,0.1l-0.2,0.3L2.7,32
                            l-0.2,0.3l-0.2,0.3l0-0.1l0,0.1l0,0.4l0.1,0.4l0.1,0.3l0.1-0.1L2.5,33.4z M2.4,32.6l0.1-0.3L2.8,32L3,31.8l0.1-0.2l0,0.4
                            l0,0.3l-0.1,0.3l-0.2,0.3l-0.4,0.5l-0.1,0.1l-0.1-0.5V32.6z"
                                        ></path>
                                        <polygon
                                            className="st8"
                                            points="2.4,32.3 2.7,32 2.9,31.8 3.1,31.5 3.2,31.3 3.3,31.2 3.3,31.1 3.3,31.1 2.9,31.7 2.4,32.2
                            2.2,32.3 2.2,32.4 2.2,32.5                      "
                                        ></polygon>
                                        <path
                                            className="st8"
                                            d="M2.7,34.6l0.4-0.6l0.2-0.3l0.1-0.3l0,0.1l0,0l0-0.4l-0.1-0.4l-0.1-0.3l0,0.2l-0.1,0.1L3,33l-0.2,0.3
                            l-0.2,0.3l-0.2,0.3l0-0.1l0,0.1v0.4l0.1,0.4l0.1,0.3l0-0.1L2.7,34.6z M2.5,33.9l0.1-0.3l0.3-0.4L3.1,33l0.1-0.2l0.1,0.3v0.3
                            l-0.1,0.3L3,34.1l-0.4,0.5l-0.1,0.1l-0.1-0.5V33.9z"
                                        ></path>
                                        <polygon
                                            className="st8"
                                            points="2.6,33.5 2.8,33.2 3,33 3.2,32.7 3.3,32.5 3.4,32.4 3.3,32.3 3.2,32.6 3,32.9 2.5,33.4 2.4,33.6
                            2.4,33.7 2.4,33.7                       "
                                        ></polygon>
                                        <path
                                            className="st8"
                                            d="M2.9,35.9l0.4-0.6L3.5,35l0.1-0.3l0,0.1l0,0v-0.4l-0.1-0.4l-0.1-0.3l-0.1,0.3l-0.2,0.3L3,34.4l-0.2,0.3
                            L2.6,35l0-0.1l0,0.1v0.4l0.1,0.4l0.1,0.3l0-0.1L2.9,35.9z M2.7,35.1l0.1-0.3l0.3-0.5l0.3-0.4l0.1,0.3v0.3l-0.1,0.3l-0.2,0.3
                            l-0.4,0.5l0,0.1l-0.1-0.5V35.1z"
                                        ></path>
                                        <polygon
                                            className="st8"
                                            points="2.8,34.7 3,34.4 3.2,34.2 3.4,33.9 3.5,33.6 3.5,33.5 3.3,33.8 3.1,34.1 2.7,34.6 2.6,34.8
                            2.6,34.9 2.6,35                         "
                                        ></polygon>
                                        <path
                                            className="st8"
                                            d="M3.2,37l0.4-0.6l0.2-0.3l0.1-0.2l0-0.1l0-0.4L3.8,35l-0.1-0.3l-0.1,0.3l-0.2,0.3l-0.2,0.3L3,35.9
                            l-0.1,0.3l-0.1-0.1l0,0.1l0,0.4l0.1,0.4l0.1,0.2l0-0.1L3.2,37z M2.9,36.3L3,36l0.3-0.5l0.2-0.2l0.1-0.3l0.1,0.3l0,0.3
                            l-0.1,0.3l-0.1,0.3L3.2,37L3.1,37L3,36.5L2.9,36.3z"
                                        ></path>
                                        <polygon
                                            className="st8"
                                            points="3,35.9 3.2,35.6 3.4,35.4 3.6,35.1 3.6,34.7 3.6,34.7 3.5,35 3.3,35.3 2.9,35.9 2.8,36 2.8,36.1
                            2.8,36.2                        "
                                        ></polygon>
                                        <path
                                            className="st8"
                                            d="M3.5,38.2l0.4-0.6l0.2-0.3l0.1-0.2l0,0l0-0.4l-0.1-0.4l-0.1-0.4l0,0.1l0,0.3l-0.4,0.6l-0.2,0.3l-0.1,0.3
                            l-0.1-0.1l0,0.1l0,0.4l0.1,0.4l0.2,0.3l0-0.2L3.5,38.2z M3.3,37.7l0-0.3l0.1-0.3l0.3-0.4l0.3-0.5L4,36.6L4,37l-0.1,0.3
                            l-0.1,0.3l-0.3,0.5l-0.1,0.1L3.3,37.7z"
                                        ></path>
                                        <polygon
                                            className="st8"
                                            points="3.3,37.1 3.5,36.8 3.8,36.2 3.9,35.9 3.8,36.2 3.6,36.5 3.2,37 3.1,37.2 3.1,37.3 3.2,37.4
                                                    "
                                        ></polygon>
                                        <path
                                            className="st8"
                                            d="M3.9,39.4l0.3-0.6l0.2-0.3l0.1-0.3l0,0l0,0l0-0.4l-0.1-0.4L4.2,37l0,0.1l-0.1,0.3l-0.2,0.3L3.7,38
                            l-0.2,0.3l-0.1,0.3l0,0l0,0l0,0.4l0.1,0.4l0.2,0.3v-0.2L3.9,39.4z M3.8,39.4l-0.2-0.5l0-0.3l0-0.3l0.3-0.5l0.3-0.5l0.1,0.3
                            l0.1,0.3l-0.1,0.3l-0.1,0.3L3.8,39.4L3.8,39.4z"
                                        ></path>
                                        <polygon
                                            className="st8"
                                            points="3.6,38.3 3.7,38 3.9,37.7 4.1,37.4 4.2,37.1 4.1,37.3 3.9,37.6 3.5,38.2 3.5,38.4 3.4,38.6
                            3.5,38.6                        "
                                        ></polygon>
                                        <path
                                            className="st8"
                                            d="M4.3,40.6l0.3-0.7l0.1-0.3l0.1-0.3l0,0l-0.1-0.4l-0.1-0.4l-0.2-0.3l-0.1,0.4l-0.1,0.3l-0.2,0.3l-0.2,0.3
                            l-0.1,0.3l0.1,0.4l0.2,0.4l0.2,0.3v-0.1L4.3,40.6z M4,40.1l0-0.3l0-0.3l0.3-0.5l0.1-0.2l0.1-0.3l0.1,0.3l0.1,0.3l0,0.3
                            l-0.1,0.3l-0.3,0.5l0,0.1L4,40.1z"
                                        ></path>
                                        <path
                                            className="st8"
                                            d="M4.8,41.7L5.1,41l0.2-0.6l0,0L5.2,40l-0.2-0.4l-0.2-0.3l0,0l0,0.3l-0.3,0.6l-0.1,0.3l-0.1,0.3l0,0v0
                            l0.1,0.4l0.2,0.4L4.8,42v-0.2L4.8,41.7z M4.7,41.7l-0.1-0.2l-0.1-0.2L4.4,41l0-0.3l0.3-0.5L4.8,40l0.1-0.3L5.1,40l0.1,0.3
                            l0,0.3L5,41.1L4.7,41.7L4.7,41.7z"
                                        ></path>
                                        <polygon
                                            className="st8"
                                            points="4.4,40.6 4.5,40.3 4.9,39.7 4.9,39.3 4.9,39.3 4.9,39.3 4.8,39.6 4.6,39.9 4.3,40.6 4.3,40.7
                            4.3,40.9 4.3,40.9                       "
                                        ></polygon>
                                        <path
                                            className="st8"
                                            d="M5.3,42.8l0.3-0.7l0.1-0.3l0.1-0.3l0,0l-0.1-0.4l-0.2-0.4l-0.2-0.3l0,0l0,0.3l-0.1,0.3L5,41.4l-0.1,0.3
                            l-0.1,0.3l0,0v0l0.1,0.4l0.2,0.4l0.2,0.3V43L5.3,42.8z M5.3,42.8L5,42.4l-0.1-0.3v-0.3l0.2-0.5l0.1-0.3l0.1-0.3l0.2,0.3
                            l0.1,0.3v0.3l-0.1,0.3L5.3,42.8L5.3,42.8z"
                                        ></path>
                                        <polygon
                                            className="st8"
                                            points="4.9,41.7 5,41.4 5.2,41.1 5.3,40.8 5.3,40.5 5.3,40.4 5.3,40.4 5.1,41 4.8,41.7 4.8,41.9 4.8,42
                            4.8,42.1                        "
                                        ></polygon>
                                        <path
                                            className="st8"
                                            d="M5.9,43.9l0.2-0.7l0.1-0.3l0.1-0.3l0.1,0.1v0l-0.1-0.4L6,41.8l-0.2-0.3l0,0l0,0.2l0,0.2l-0.1,0.3
                            l-0.1,0.3l-0.1,0.3l-0.1,0.3l-0.1-0.1v0.1l0.1,0.4L5.7,44l0.2,0.2v-0.1L5.9,43.9z M5.8,43.9l-0.3-0.5l-0.1-0.3v-0.3l0.2-0.5
                            l0.2-0.5L6,42.2l0.1,0.3l0,0.3l-0.1,0.3L5.8,43.9L5.8,43.9z"
                                        ></path>
                                        <polygon
                                            className="st8"
                                            points="5.4,42.9 5.5,42.6 5.7,42.2 5.8,41.9 5.8,41.7 5.8,41.5 5.8,41.5 5.7,41.5 5.7,41.8 5.6,42.1
                            5.3,42.8 5.3,43 5.3,43.1 5.4,43.2                       "
                                        ></polygon>
                                        <path
                                            className="st8"
                                            d="M6.5,45l0.2-0.7L6.8,44l0-0.3l0.1,0.1l0,0l-0.1-0.4l-0.2-0.4l-0.2-0.2v0.2V43l-0.1,0.3l-0.1,0.3L6,44
                            l-0.1,0.3l-0.1-0.1v0.1L6,44.7L6.3,45l0.2,0.2l0-0.1L6.5,45z M6.5,45l-0.2-0.2l-0.1-0.2l-0.1-0.3l0-0.3l0.2-0.5L6.4,43
                            l0.2,0.3l0.1,0.3l0.1,0.3l-0.1,0.3L6.5,45L6.5,45z"
                                        ></path>
                                        <path
                                            className="st8"
                                            d="M7.2,46l0.1-0.7L7.4,45v-0.3l0.1,0.1l0-0.1l-0.2-0.4l-0.2-0.4l-0.2-0.2l0,0.3l-0.1,0.3l-0.1,0.3L6.6,45
                            l0,0.3l-0.1-0.1l0,0.1l0.2,0.4L7,46.1l0.2,0.2l0-0.1L7.2,46z M7.2,46L7.2,46l-0.3-0.4l-0.1-0.2l0-0.3l0.1-0.5L7,44l0.2,0.3
                            l0.1,0.3L7.4,45l-0.1,0.3L7.2,46L7.2,46z"
                                        ></path>
                                        <polygon
                                            className="st8"
                                            points="6.6,45 6.7,44.7 6.8,44.3 6.9,44 6.9,43.7 6.8,43.6 6.8,44 6.7,44.3 6.5,45 6.5,45.1 6.5,45.2
                            6.6,45.3                        "
                                        ></polygon>
                                        <path
                                            className="st8"
                                            d="M7.9,47L8,46.3l0-0.3v-0.3l0.1,0.1l0,0L8,45.3l-0.3-0.4l-0.2-0.2l0,0.3l-0.1,0.3l-0.1,0.3L7.3,46l0,0.3
                            l-0.1-0.1l0,0.1l0.2,0.4l0.3,0.3l0.2,0.1l0-0.1V47z M7.9,47l-0.3-0.4l-0.1-0.2l-0.1-0.3l0.1-0.5l0.1-0.3l0-0.3l0.2,0.3
                            l0.1,0.3L8,46l0,0.3l-0.1,0.6V47z"
                                        ></path>
                                        <polygon
                                            className="st8"
                                            points="7.3,46 7.4,45.7 7.5,45.4 7.5,45 7.5,44.7 7.4,44.6 7.4,45 7.4,45.3 7.2,46 7.2,46.2 7.2,46.3
                            7.3,46.3                        "
                                        ></polygon>
                                        <path
                                            className="st8"
                                            d="M8.7,48l0.1-0.7l0-0.3v-0.3l-0.2-0.4l-0.3-0.3l-0.2-0.2L8.2,46l0,0.4l-0.1,0.3L8,47v0.3l-0.1-0.1l0,0.1
                            l0.2,0.4l0.3,0.3l0.2,0.2l0-0.1V48z M8.6,48l-0.4-0.4l-0.1-0.2l-0.1-0.3l0.1-0.5l0.1-0.3l0-0.3l0.2,0.3l0.2,0.3l0.1,0.3v0.3
                            l-0.1,0.6V48z"
                                        ></path>
                                        <polygon
                                            className="st8"
                                            points="8,47 8.1,46.7 8.2,46.4 8.2,46 8.1,45.7 8.1,45.6 8.1,46 8,46.3 7.9,47 7.9,47.2 7.9,47.3
                            8,47.3                      "
                                        ></polygon>
                                        <path
                                            className="st8"
                                            d="M9.5,48.9l0.1-0.7l0-0.3l0-0.3l-0.2-0.3l-0.3-0.3l-0.3-0.3v0L8.9,47l0,0.3l-0.1,0.3l0,0.3v0.3l-0.1-0.1
                            l0,0.1l0.2,0.4l0.3,0.3l0.3,0.2l-0.1-0.2V48.9z M9.4,48.9L9,48.5l-0.1-0.2l-0.1-0.3l0.1-0.5L9,47.3V47l0.2,0.3l0.2,0.3
                            l0.1,0.3v0.3L9.4,48.9L9.4,48.9z"
                                        ></path>
                                        <path
                                            className="st8"
                                            d="M10.3,49.8l0.1-0.7v-0.3l0-0.3l0,0l-0.2-0.3l-0.3-0.3l-0.3-0.3l0,0l0,0.1l0.1,0.3l0,0.4l-0.1,0.3L9.5,49
                            v0.3l0.2,0.3L10,50l0.3,0.2l0-0.2L10.3,49.8z M10.3,49.1l-0.1,0.6v0.1L10,49.7l-0.2-0.2l-0.1-0.2L9.6,49l0.1-0.5l0-0.3v-0.3
                            l0.3,0.3l0.2,0.3l0.1,0.3V49.1z"
                                        ></path>
                                        <polygon
                                            className="st8"
                                            points="9.6,48.6 9.6,48.3 9.7,47.9 9.6,47.6 9.6,47.5 9.6,47.9 9.6,48.2 9.5,48.9 9.5,49.1 9.5,49.3
                            9.5,49                      "
                                        ></polygon>
                                        <path
                                            className="st8"
                                            d="M11.2,50.7V50l-0.1-0.7L10.9,49l-0.3-0.3l-0.3-0.2l0,0l0.1,0.2l0,0.2v0.3l0,0.4l0,0.3l0,0.3l0,0l0,0
                            l0.2,0.4l0.3,0.3l0.3,0.2l-0.1-0.2L11.2,50.7z M11.1,50.6L11.1,50.6l-0.4-0.3l-0.1-0.2l-0.1-0.3l0-0.5l0-0.5l0.3,0.3l0.2,0.3
                            l0.1,0.3l0,0.3L11.1,50.6z"
                                        ></path>
                                        <polygon
                                            className="st8"
                                            points="10.4,49.9 10.4,49.5 10.5,49.1 10.5,48.8 10.4,48.6 10.4,48.5 10.4,48.4 10.3,48.4 10.4,48.8
                            10.4,49.1 10.3,49.8 10.3,50 10.4,50.2 10.4,50.2                         "
                                        ></polygon>
                                        <path
                                            className="st8"
                                            d="M12.1,51.5l0-0.7l0-0.3L12,50.1l0.1,0l0,0l-0.3-0.3l-0.3-0.3l-0.3-0.2l0,0l0,0l0.1,0.3l0,0.4l0,0.3v0.3
                            l0,0.3l0,0l0,0l0.3,0.3l0.3,0.3l0.3,0.1l-0.1-0.1L12.1,51.5z M12,51.4v0.1l-0.4-0.3L11.5,51l-0.1-0.2v-0.5v-0.5l0.3,0.2
                            l0.2,0.3l0.1,0.3l0.1,0.3V51.4z"
                                        ></path>
                                        <polygon
                                            className="st8"
                                            points="11.3,50.7 11.3,50.4 11.3,50 11.3,49.7 11.2,49.4 11.1,49.3 11.2,50 11.2,50.7 11.2,50.9
                            11.3,51 11.3,51                         "
                                        ></polygon>
                                        <path
                                            className="st8"
                                            d="M13.1,52.3L13,51.6l-0.1-0.7l0.1,0l0,0l-0.3-0.3l-0.3-0.3L12,50.2l0.1,0.3l0,0.7v0.3l0.1,0.3l-0.1,0
                            l0,0.1l0.3,0.3l0.4,0.3l0.3,0.1l-0.1-0.1L13.1,52.3z M12.6,52l-0.2-0.2l-0.1-0.3V51l0-0.3l0-0.3l0.3,0.2l0.2,0.3l0.2,0.3
                            l0.1,0.3l0,0.6l0,0.1L12.6,52z"
                                        ></path>
                                        <path
                                            className="st8"
                                            d="M14.1,53.1L14,52.4L13.9,52l-0.1-0.3l0.1,0l0,0l-0.3-0.3l-0.3-0.3L12.9,51l0.1,0.1l0.1,0.1l0.1,0.4l0,0.3
                            l0,0.3l0.1,0.3l-0.1,0l0,0l0.3,0.3l0.4,0.2l0.3,0.1l0-0.1L14.1,53.1z M13.5,52.8l-0.2-0.2l-0.1-0.2l-0.1-0.5l-0.1-0.5l0.3,0.2
                            l0.3,0.3l0.1,0.3l0.1,0.3L14,53l0,0.1L13.5,52.8z"
                                        ></path>
                                        <polygon
                                            className="st8"
                                            points="13.2,52.3 13.1,52 13.1,51.6 13.1,51.2 13,51.1 12.9,51 12.9,50.9 13,51.6 13.1,52.3 13.1,52.5
                            13.2,52.6 13.2,52.6                         "
                                        ></polygon>
                                        <path
                                            className="st8"
                                            d="M15.1,53.8l-0.1-0.7l-0.1-0.3l-0.1-0.3l0.1,0.1l-0.1-0.1l-0.3-0.3l-0.4-0.3l-0.2-0.1l0.1,0.1L14,52
                            l0.1,0.3l0,0.3l0.1,0.3l0.1,0.3l-0.1,0l0,0.1l0.3,0.3l0.4,0.2l0.3,0.1l0,0L15.1,53.8z M15,53.8l-0.2-0.1l-0.2-0.1l-0.2-0.2
                            l-0.1-0.2l-0.1-0.5l0-0.3L14.1,52l0.3,0.2l0.2,0.2l0.2,0.3l0.1,0.3L15,53.8L15,53.8z"
                                        ></path>
                                        <polygon
                                            className="st8"
                                            points="14.2,53 14.1,52.7 14.1,52.4 14,52 14,51.9 13.9,51.7 13.8,51.7 13.9,52 14,52.4 14.1,53.1
                            14.1,53.2 14.2,53.3 14.3,53.3                       "
                                        ></polygon>
                                        <path
                                            className="st8"
                                            d="M16.1,54.4L16,53.7l-0.2-0.7l-0.3-0.3l-0.4-0.2l-0.2-0.1l0.1,0.1l0.1,0.1l0.1,0.3l0.1,0.3l0.1,0.3
                            l0.1,0.3l-0.1,0l0.1,0.1l0.3,0.3l0.4,0.2l0.2,0.1l0-0.1L16.1,54.4z M15.6,54.2L15.4,54l-0.1-0.2l-0.1-0.5L15.1,53L15,52.7
                            l0.3,0.2l0.3,0.2l0.2,0.3l0.1,0.3l0.1,0.6l0,0.1L15.6,54.2z"
                                        ></path>
                                        <polygon
                                            className="st8"
                                            points="15.2,53.7 15.1,53.4 15.1,53.1 15,52.7 14.9,52.6 14.9,52.5 14.7,52.4 14.8,52.7 14.9,53.1
                            15.1,53.8 15.1,53.9 15.2,54 15.3,54                         "
                                        ></polygon>
                                        <path
                                            className="st8"
                                            d="M17.2,55L17,54.3L16.9,54l-0.1-0.3l-0.3-0.2l-0.4-0.2l-0.4-0.1l0,0l0,0l0.1,0.1l0.1,0.1l0.1,0.3l0.1,0.3
                            l0.1,0.3l0.1,0.3l-0.2,0l0.1,0.1l0.3,0.3l0.4,0.2l0.3,0.1l-0.1-0.1L17.2,55z M16.6,54.8l-0.2-0.2l-0.1-0.2l-0.1-0.5l-0.1-0.5
                            l0.3,0.2l0.3,0.2l0.2,0.3l0.1,0.3l0.1,0.6l0,0.1L16.6,54.8z"
                                        ></path>
                                        <path
                                            className="st8"
                                            d="M18.3,55.5l-0.2-0.7L18,54.5l-0.1-0.3l0,0L17.5,54l-0.4-0.2l-0.4-0.1l0,0l0,0l0.1,0.2L17,54l0.1,0.3
                            l0.1,0.3l0.1,0.3l0.1,0.3l-0.1,0l0,0l0.3,0.3l0.4,0.2l0.4,0.1l-0.1-0.1L18.3,55.5z M17.7,55.4l-0.2-0.2L17.4,55l-0.1-0.5
                            l-0.1-0.3L17.1,54l0.3,0.1l0.3,0.2l0.2,0.3l0.1,0.3l0.2,0.6l0,0.1L17.7,55.4z"
                                        ></path>
                                        <polygon
                                            className="st8"
                                            points="17.3,55 17.2,54.7 17.1,54.3 17,54 16.9,53.9 16.8,53.7 16.8,53.7 16.9,54 17,54.3 17.2,55
                            17.2,55.2 17.3,55.3 17.4,55.3                       "
                                        ></polygon>
                                        <path
                                            className="st8"
                                            d="M19.4,56.1l-0.2-0.7l-0.3-0.6l0,0l-0.3-0.2l-0.4-0.2l-0.3-0.1l0,0l0.1,0.1l0.1,0.1l0.2,0.7l0.1,0.3
                            l0.1,0.3l0,0l0,0l0.3,0.3l0.4,0.2l0.3,0l-0.1-0.1L19.4,56.1z M18.9,55.9l-0.2-0.1l-0.2-0.2L18.3,55l-0.1-0.3l-0.1-0.3l0.3,0.1
                            l0.3,0.2l0.2,0.3l0.1,0.3l0.1,0.3l0.1,0.3l0,0.1L18.9,55.9z"
                                        ></path>
                                        <polygon
                                            className="st8"
                                            points="18.4,55.6 18.3,55.2 18.1,54.6 18,54.4 17.9,54.3 17.9,54.3 17.8,54.3 18,54.5 18.1,54.9
                            18.3,55.5 18.4,55.7 18.5,55.9 18.5,55.9                         "
                                        ></polygon>
                                        <path
                                            className="st8"
                                            d="M20.5,56.6l-0.3-0.7l-0.1-0.3l-0.2-0.3l0.1,0l0,0L19.7,55l-0.4-0.2l-0.3-0.1l0,0l0.2,0.3l0.1,0.3l0.1,0.3
                            l0.1,0.3l0.1,0.3l-0.1,0l0,0l0.3,0.2l0.4,0.1l0.3,0l-0.1-0.1L20.5,56.6z M20,56.4l-0.2-0.1l-0.2-0.2l-0.2-0.5L19.2,55l0.3,0.1
                            l0.3,0.2l0.2,0.3l0.1,0.3l0.2,0.6l0.1,0.1L20,56.4z"
                                        ></path>
                                        <polygon
                                            className="st8"
                                            points="19.5,56.1 19.4,55.7 19.3,55.4 19.2,55.1 18.9,54.8 18.9,54.8 18.9,54.8 19.2,55.4 19.4,56.1
                            19.5,56.2 19.6,56.3 19.7,56.4                       "
                                        ></polygon>
                                        <path
                                            className="st8"
                                            d="M21.7,57l-0.3-0.7l-0.3-0.6l0.1,0l0,0l-0.4-0.2l-0.4-0.1l-0.3,0l0.1,0.1l0.1,0.1l0.1,0.3l0.1,0.3l0.1,0.3
                            l0.1,0.3l-0.1,0l0.1,0.1l0.4,0.2l0.4,0.1h0.3l-0.1-0.1L21.7,57z M21.2,56.9l-0.3-0.1l-0.2-0.2L20.5,56l-0.2-0.5l0.3,0.1
                            l0.3,0.2l0.3,0.2l0.2,0.3l0.3,0.5l0,0.1L21.2,56.9z"
                                        ></path>
                                        <polygon
                                            className="st8"
                                            points="20.7,56.5 20.5,56.2 20.4,55.9 20.3,55.5 20.2,55.4 20.1,55.3 19.9,55.3 20.1,55.6 20.3,55.9
                            20.5,56.6 20.6,56.7 20.7,56.8 20.8,56.8                         "
                                        ></polygon>
                                        <path
                                            className="st8"
                                            d="M22.9,57.3l-0.3-0.6l-0.4-0.6l0.1,0l0,0l-0.4-0.2l-0.4-0.1l-0.3,0l0.2,0.2l0.3,0.6l0.1,0.3l0.2,0.3h-0.1
                            l0.1,0.1l0.4,0.2l0.4,0.1h0.3l0,0L22.9,57.3z M22.4,57.2l-0.3-0.1l-0.2-0.2l-0.1-0.2l-0.1-0.3l-0.1-0.3l-0.1-0.2l0.3,0.1
                            l0.3,0.2l0.3,0.2l0.2,0.3l0.3,0.5l0.1,0.1L22.4,57.2z"
                                        ></path>
                                        <polygon
                                            className="st8"
                                            points="21.8,56.9 21.7,56.6 21.4,55.9 21.1,55.7 21.1,55.7 21.4,56.3 21.7,57 21.8,57.1 21.9,57.2
                            22,57.2                         "
                                        ></polygon>
                                        <path
                                            className="st8"
                                            d="M24.1,57.6L23.7,57l-0.2-0.3l-0.2-0.2h0.2l-0.1-0.1L23,56.3l-0.4-0.1l-0.3,0l0.2,0.2l0.2,0.3l0.2,0.3
                            l0.1,0.3l0.2,0.3h-0.2l0.1,0.1l0.4,0.2l0.4,0.1l0.3,0l-0.1,0L24.1,57.6z M23.5,57.6l-0.2-0.1l-0.2-0.2l-0.3-0.5l-0.1-0.3
                            l-0.1-0.2l0.3,0.1l0.3,0.1l0.3,0.2l0.2,0.3l0.3,0.5l0.1,0.1L23.5,57.6z"
                                        ></path>
                                        <polygon
                                            className="st8"
                                            points="23,57.2 22.9,57 22.7,56.6 22.5,56.3 22.3,56.1 22.2,56.1 22.6,56.7 22.9,57.3 23,57.5
                            23.1,57.5 23.2,57.5                         "
                                        ></polygon>
                                        <path
                                            className="st8"
                                            d="M25.3,57.9l-0.4-0.6L24.7,57l-0.2-0.3l0,0l0,0l-0.4-0.1l-0.4-0.1h-0.2l0.1,0.1l0.1,0.1l0.2,0.3l0.2,0.3
                            l0.2,0.3l0.2,0.3l-0.1,0l0.1,0.1l0.4,0.1l0.4,0.1l0.2,0l0,0L25.3,57.9z M24.8,57.8l-0.3-0.1l-0.2-0.1L24,57.1l-0.3-0.5
                            l0.3,0.1l0.3,0.1l0.3,0.2l0.2,0.3l0.3,0.5l0.1,0.1L24.8,57.8z"
                                        ></path>
                                        <polygon
                                            className="st8"
                                            points="24.2,57.5 24,57.2 23.8,57 23.6,56.7 23.5,56.6 23.5,56.5 23.3,56.5 23.5,56.7 23.7,57
                            24.1,57.6 24.2,57.8 24.3,57.8 24.4,57.8                         "
                                        ></polygon>
                                        <path
                                            className="st8"
                                            d="M26.5,58.1l-0.4-0.6l-0.2-0.3L25.7,57l0,0l-0.4-0.1l-0.4-0.1l-0.4,0l0.2,0.1l0.1,0.1l0.2,0.3l0.2,0.3
                            l0.2,0.3l0.2,0.2l-0.2,0l0.1,0.1l0.4,0.1l0.5,0.1l0.3,0l-0.1-0.1L26.5,58.1z M25.9,58.1L25.7,58l-0.2-0.1l-0.3-0.5l-0.1-0.3
                            l-0.2-0.2l0.3,0.1l0.3,0.1l0.3,0.2l0.2,0.3l0.3,0.5l0.1,0.1L25.9,58.1z"
                                        ></path>
                                        <path
                                            className="st8"
                                            d="M27.8,58.2l-0.4-0.6l-0.2-0.3l-0.2-0.2l0,0L26.5,57L26.1,57l-0.3,0l0,0l0.3,0.2l0.2,0.3l0.2,0.3l0.2,0.3
                            l0.2,0.2l-0.1,0l0,0l0.4,0.1l0.5,0l0.3-0.1l-0.1-0.1L27.8,58.2z M27.2,58.2l-0.2-0.1L26.7,58l-0.3-0.4l-0.3-0.4l0.3,0l0.3,0.1
                            l0.3,0.2l0.3,0.3l0.4,0.5l0.1,0.1L27.2,58.2z"
                                        ></path>
                                        <polygon
                                            className="st8"
                                            points="26.6,58 26.4,57.8 26.2,57.5 26,57.2 25.7,57 25.7,57 25.7,57 25.9,57.2 26.1,57.5 26.5,58.1
                            26.7,58.2 26.8,58.3 26.8,58.3                       "
                                        ></polygon>
                                        <path
                                            className="st8"
                                            d="M29,58.3l-0.5-0.5l-0.2-0.2L28,57.3l0,0l-0.4-0.1l-0.5-0.1l-0.3,0l0,0l0.1,0.1l0.1,0.1l0.2,0.3l0.2,0.3
                            l0.2,0.3l0.2,0.2l-0.1,0l0,0l0.4,0.1h0.4l0.3-0.1l-0.1-0.1L29,58.3z M28.4,58.4l-0.3-0.1l-0.2-0.1l-0.3-0.4l-0.2-0.2l-0.2-0.2
                            l0.3,0l0.3,0.1l0.3,0.2l0.3,0.3l0.4,0.5l0.1,0.1L28.4,58.4z"
                                        ></path>
                                        <polygon
                                            className="st8"
                                            points="27.8,58.2 27.6,57.9 27.4,57.6 27.2,57.4 27.1,57.2 26.9,57.2 26.9,57.1 26.9,57.2 27.1,57.4
                            27.3,57.6 27.8,58.2 27.9,58.3 28,58.4 28.1,58.4                         "
                                        ></polygon>
                                        <path
                                            className="st8"
                                            d="M30.2,58.4l-0.5-0.5l-0.3-0.3l-0.3-0.2l0.1,0l0,0l-0.4-0.1l-0.4,0l-0.3,0.1l0,0l0.3,0.2l0.2,0.3l0.2,0.3
                            l0.2,0.3l0.2,0.2l-0.1,0l0,0l0.4,0.1l0.5,0l0.3-0.1l-0.1,0L30.2,58.4z M29.6,58.4l-0.3-0.1l-0.2-0.1l-0.3-0.4l-0.2-0.2
                            l-0.2-0.2h0.3l0.3,0.1l0.3,0.1l0.3,0.2l0.4,0.5l0.1,0.1H29.6z"
                                        ></path>
                                        <polygon
                                            className="st8"
                                            points="29.1,58.3 28.9,58 28.6,57.7 28.4,57.5 28.1,57.3 28.1,57.3 28,57.3 28.3,57.5 28.5,57.8
                            29,58.3 29.1,58.4 29.2,58.5 29.3,58.5                       "
                                        ></polygon>
                                        <path
                                            className="st8"
                                            d="M31.5,58.4l-0.5-0.5l-0.3-0.2l-0.3-0.2l0.1,0l0,0L30,57.3h-0.5l-0.3,0l0.3,0.2l0.3,0.2l0.2,0.3l0.2,0.2
                            l0.3,0.2l-0.1,0l0.1,0l0.4,0.1l0.4,0l0.3-0.1l-0.1,0L31.5,58.4z M30.9,58.4l-0.3,0l-0.2-0.1L30,57.9l-0.4-0.4H30l0.3,0
                            l0.3,0.1l0.3,0.2l0.5,0.4l0.1,0.1L30.9,58.4z"
                                        ></path>
                                        <path
                                            className="st8"
                                            d="M32.7,58.3l-0.5-0.5l-0.3-0.2l-0.3-0.2l0.1,0l0,0l-0.4-0.1l-0.5,0l-0.3,0.1l0.3,0.1l0.3,0.2l0.3,0.2
                            l0.2,0.3l0.3,0.2l-0.1,0.1l0.1,0l0.4,0.1l0.4-0.1l0.3-0.1l-0.1,0L32.7,58.3z M32.1,58.4l-0.3,0l-0.3-0.1l-0.4-0.4l-0.4-0.4
                            l0.4,0l0.3,0l0.3,0.1l0.3,0.2l0.5,0.4l0.1,0.1L32.1,58.4z"
                                        ></path>
                                        <polygon
                                            className="st8"
                                            points="31.5,58.3 31.3,58 31,57.8 30.8,57.6 30.5,57.4 30.4,57.4 30.7,57.6 30.9,57.8 31.5,58.4
                            31.6,58.5 31.7,58.5 31.8,58.4                       "
                                        ></polygon>
                                        <path
                                            className="st8"
                                            d="M33.9,58.2l-0.6-0.4l-0.3-0.2l-0.3-0.2l0.1,0l-0.1,0l-0.4,0l-0.5,0l-0.3,0.1l0.3,0.1l0.3,0.2l0.3,0.2
                            l0.3,0.2l0.3,0.2l-0.1,0l0.1,0l0.4,0l0.5-0.1l0.3-0.1l-0.1,0L33.9,58.2z M33.6,58.3l-0.3,0l-0.3,0l-0.3-0.1l-0.4-0.3L32,57.5
                            l0.3,0h0.3l0.3,0.1l0.3,0.2l0.5,0.4l0.1,0.1L33.6,58.3z"
                                        ></path>
                                        <polygon
                                            className="st8"
                                            points="32.8,58.2 32.5,58 32.3,57.7 32,57.5 31.7,57.4 31.6,57.4 31.9,57.6 32.1,57.8 32.7,58.3
                            32.8,58.4 32.9,58.4 33,58.4                         "
                                        ></polygon>
                                        <path
                                            className="st8"
                                            d="M35.2,58l-0.6-0.4l-0.3-0.2L34,57.2l0.1,0l-0.1,0l-0.4,0l-0.5,0.1l-0.3,0.1l0.3,0.1l0.3,0.2l0.3,0.2
                            l0.3,0.2l0.3,0.2l-0.1,0l0.1,0h0.4l0.4-0.1l0.3-0.1l-0.1,0L35.2,58z M34.6,58.1h-0.3l-0.3-0.1l-0.4-0.3l-0.2-0.2l-0.2-0.1
                            l0.4-0.1h0.3l0.3,0.1l0.3,0.2L35,58l0.1,0.1L34.6,58.1z"
                                        ></path>
                                        <polygon
                                            className="st8"
                                            points="34,58.1 33.7,57.9 33.5,57.6 33.2,57.4 32.9,57.3 32.8,57.4 33.1,57.5 33.3,57.7 33.9,58.2
                            34.1,58.2 34.2,58.3 34.3,58.2                       "
                                        ></polygon>
                                        <polygon
                                            className="st8"
                                            points="36.4,57.8 35.8,57.5 36.2,57.8 36.3,57.8 35.8,57.9 35.6,57.9 35.3,57.9 34.9,57.6 34.4,57.2
                            34.7,57.2 35.1,57.2 35.4,57.2 35.2,57.1 35.3,57 35.2,57 34.8,57 34.3,57.1 34.1,57.2 34.3,57.3 34.7,57.5 34.9,57.7
                            35.2,57.9 35.5,58 35.4,58.1 35.5,58.1 35.9,58.1 36.4,58 36.5,57.9 36.5,57.9                         "
                                        ></polygon>
                                        <polygon
                                            className="st8"
                                            points="35.2,57.9 34.9,57.7 34.7,57.5 34.3,57.3 34.1,57.2 34,57.2 34.3,57.4 34.6,57.6 35.2,58
                            35.3,58.1 35.4,58.1 35.5,58                         "
                                        ></polygon>
                                        <path
                                            className="st8"
                                            d="M37.5,57.5L37,57.1l-0.3-0.2l-0.3-0.1l0.1,0h0l-0.4,0l-0.4,0.1L35.3,57l0.2,0.1l0.6,0.4l0.3,0.2l0.3,0.1
                            l-0.2,0.1l0.2,0l0.4,0l0.4-0.2l0.2-0.1l-0.1,0L37.5,57.5z M37,57.7l-0.3,0l-0.3,0L36,57.4l-0.2-0.1l-0.2-0.1l0.3-0.1l0.3,0
                            l0.3,0.1l0.3,0.1l0.5,0.3l0.1,0.1L37,57.7z"
                                        ></path>
                                        <path
                                            className="st8"
                                            d="M38.7,57.2l-0.6-0.4l-0.3-0.1l-0.3-0.1l-0.4,0l-0.4,0.1l-0.3,0.1h0.2l0.1,0.1l0.6,0.4l0.3,0.2l0.3,0.1
                            l-0.1,0.1l0.1,0l0.4,0l0.4-0.1l0.3-0.2l-0.2,0L38.7,57.2z M38.2,57.4l-0.3,0l-0.3,0l-0.5-0.3L37,56.9l-0.2-0.1l0.3-0.1l0.3,0
                            l0.3,0.1l0.3,0.1l0.5,0.3l0.1,0.1L38.2,57.4z"
                                        ></path>
                                        <polygon
                                            className="st8"
                                            points="37.6,57.4 37.3,57.2 36.7,56.8 36.6,56.8 36.4,56.8 36.3,56.8 36.6,56.9 37,57.1 37.5,57.5
                            37.7,57.6 37.8,57.6 37.9,57.5                       "
                                        ></polygon>
                                        <path
                                            className="st8"
                                            d="M39.9,56.8l-0.6-0.3l-0.3-0.1l-0.3-0.1l0,0l-0.4,0.1l-0.4,0.1l-0.4,0.2l0,0l0,0l0.3,0.1l0.3,0.2l0.3,0.2
                            l0.3,0.2l0.3,0.1l0,0l0,0l0.4,0l0.4-0.2l0.3-0.2h-0.1L39.9,56.8z M39.4,57l-0.3,0l-0.3,0l-0.5-0.3l-0.2-0.1l-0.3-0.1l0.3-0.1
                            l0.3,0l0.3,0l0.3,0.1l0.5,0.3l0.1,0.1L39.4,57z"
                                        ></path>
                                        <polygon
                                            className="st8"
                                            points="38.8,57.1 38.5,56.9 38.2,56.7 37.9,56.6 37.5,56.5 37.5,56.5 37.8,56.6 38.1,56.8 38.7,57.2
                            38.9,57.2 39,57.2 39.1,57.2                         "
                                        ></polygon>
                                        <path
                                            className="st8"
                                            d="M41.1,56.4L40.4,56l-0.3-0.1l-0.3-0.1l0.1-0.1l-0.1,0l-0.4,0.1L39,56l-0.3,0.2l0,0l0.3,0.1l0.3,0.1
                            l0.3,0.2l0.3,0.1l0.3,0.1l0,0h0l0.4-0.1l0.4-0.2l0.2-0.2h-0.1L41.1,56.4z M40.8,56.5l-0.3,0.1l-0.3,0l-0.3,0l-0.5-0.3
                            l-0.5-0.2l0.3-0.1l0.3-0.1l0.3,0l0.3,0.1l0.6,0.3l0.1,0L40.8,56.5z"
                                        ></path>
                                        <polygon
                                            className="st8"
                                            points="40,56.7 39.7,56.6 39.4,56.4 39,56.2 38.7,56.1 38.7,56.2 38.6,56.2 38.9,56.3 39.3,56.4
                            39.9,56.8 40.1,56.8 40.2,56.8 40.3,56.8                         "
                                        ></polygon>
                                        <path
                                            className="st8"
                                            d="M42.2,55.9l-0.7-0.3l-0.3-0.1l-0.3-0.1l0.1-0.1l-0.1,0l-0.4,0.1l-0.4,0.2l-0.2,0.1l0.3,0l0.6,0.3l0.3,0.1
                            l0.3,0.1l-0.1,0.1h0.1l0.4-0.1l0.4-0.2l0.2-0.2h-0.1L42.2,55.9z M41.7,56.1l-0.3,0.1l-0.3,0L40.7,56l-0.3-0.1l-0.3-0.1
                            l0.3-0.2l0.3-0.1h0.3l0.3,0.1l0.6,0.2l0.1,0L41.7,56.1z"
                                        ></path>
                                        <path
                                            className="st8"
                                            d="M43.3,55.3l-0.7-0.3L42.3,55L42,54.9l0.1-0.1h0L41.6,55l-0.4,0.2L41,55.4l0.3,0l0.3,0.1l0.3,0.1l0.3,0.1
                            l0.3,0.1l-0.1,0.1h0.1l0.4-0.1l0.4-0.2l0.2-0.2h-0.1L43.3,55.3z M42.8,55.6l-0.3,0.1h-0.3l-0.5-0.2l-0.2-0.1l-0.3-0.1l0.3-0.2
                            l0.3-0.1l0.3,0l0.3,0.1l0.6,0.2l0.1,0L42.8,55.6z"
                                        ></path>
                                        <polygon
                                            className="st8"
                                            points="42.2,55.7 41.9,55.6 41.6,55.5 41.3,55.4 41,55.4 40.9,55.4 41.2,55.5 41.5,55.6 42.2,55.9
                            42.4,55.9 42.5,55.9 42.6,55.8                       "
                                        ></polygon>
                                        <path
                                            className="st8"
                                            d="M44.4,54.7l-0.7-0.2l-0.3-0.1l-0.3-0.1l0.1-0.1h-0.1l-0.4,0.1l-0.4,0.2l-0.2,0.2h0.3l0.3,0.1l0.3,0.1
                            l0.3,0.1l0.3,0.1l-0.1,0.1h0.1l0.4-0.1l0.4-0.2l0.2-0.2l-0.1,0L44.4,54.7z M44.2,55L44,55.1l-0.3,0.1l-0.3,0L42.9,55l-0.3-0.1
                            l-0.3-0.1l0.3-0.2l0.3-0.1l0.3,0l0.3,0.1l0.6,0.2l0.1,0L44.2,55z"
                                        ></path>
                                        <polygon
                                            className="st8"
                                            points="43.4,55.2 43,55.1 42.7,55 42.4,54.9 42.1,54.9 42,54.9 42.3,55 42.6,55.1 43.3,55.3 43.5,55.4
                            43.6,55.4 43.7,55.3                         "
                                        ></polygon>
                                        <path
                                            className="st8"
                                            d="M45.5,54.1L44.8,54l-0.3-0.1l-0.3,0l0.1-0.1l-0.1,0l-0.4,0.1l-0.4,0.2l-0.2,0.2h0.1h0.2l0.7,0.2l0.3,0.1
                            l0.3,0l-0.1,0.1l0.1,0l0.4-0.1l0.4-0.3l0.2-0.2l0,0L45.5,54.1z M45,54.5l-0.2,0.1l-0.3,0L44,54.5l-0.3-0.1l-0.3-0.1l0.3-0.2
                            l0.3-0.1l0.3-0.1l0.4,0.1l0.6,0.2h0.1L45,54.5z"
                                        ></path>
                                        <path
                                            className="st8"
                                            d="M46.5,53.5l-0.7-0.1l-0.3-0.1h-0.3l0.1-0.1l-0.1,0l-0.4,0.2l-0.4,0.3l-0.2,0.2l0.3,0l0.7,0.2l0.3,0.1
                            l0.3,0l-0.1,0.1l0.2,0l0.4-0.2l0.3-0.3l0.2-0.2l0,0H46.5z M46.1,53.8l-0.2,0.1l-0.3,0l-0.5-0.1l-0.5-0.1l0.3-0.2l0.3-0.1
                            l0.3-0.1l0.4,0l0.6,0.1l0.1,0L46.1,53.8z"
                                        ></path>
                                        <polygon
                                            className="st8"
                                            points="45.5,54 45.2,53.9 44.5,53.8 44.2,53.8 44.1,53.9 44.4,53.9 44.8,54 45.5,54.1 45.7,54.2
                            45.7,54.2 45.8,54                       "
                                        ></polygon>
                                        <path
                                            className="st8"
                                            d="M47.5,52.8l-0.7-0.1l-0.3,0h-0.3l0.1-0.1l-0.1,0l-0.4,0.2L45.4,53l-0.2,0.2l0.3,0l0.3,0.1l0.3,0.1
                            l0.3,0.1l0.3,0l-0.1,0.1l0.2,0l0.4-0.2l0.3-0.3l0.1-0.2l0,0H47.5z M47.1,53.1l-0.2,0.1l-0.3,0.1l-0.5-0.1l-0.5-0.1l0.3-0.2
                            l0.3-0.2l0.3-0.1h0.3l0.6,0.1l0.1,0L47.1,53.1z"
                                        ></path>
                                        <polygon
                                            className="st8"
                                            points="46.5,53.3 46.2,53.3 45.9,53.2 45.5,53.1 45.2,53.2 45.1,53.3 45.5,53.3 45.8,53.3 46.5,53.5
                            46.7,53.5 46.8,53.5 46.9,53.3                       "
                                        ></polygon>
                                    </g>
                                    <path
                                        className="st8"
                                        d="M48.5,52l-0.7-0.1l-0.3,0h-0.3l0,0L46.7,52l-0.3,0.3l-0.1,0.2l0.1,0h0.2l0.4,0l0.3,0.1l0.3,0.1h0.3
                        l-0.1,0.1l0.1-0.1l0.4-0.2l0.3-0.3l0.1-0.2l-0.1,0H48.5z M48.1,52.4l-0.2,0.1l-0.3,0.1l-0.5-0.1l-0.3-0.1h-0.3l0.3-0.2l0.3-0.2
                        l0.3-0.1h0.3l0.6,0.1l0.1,0L48.1,52.4z"
                                    ></path>
                                    <path
                                        className="st8"
                                        d="M49.4,51.2l-0.7-0.1l-0.3,0l-0.3,0l0,0l-0.4,0.2l-0.3,0.3l-0.2,0.3l0,0l0.3-0.1l0.7,0.1l0.3,0h0.3L48.7,52
                        l0.1,0l0.3-0.2l0.3-0.3l0.2-0.3l-0.1,0H49.4z M49.1,51.6l-0.2,0.1l-0.3,0.1L48,51.8l-0.3-0.1h-0.3l0.3-0.3l0.3-0.2l0.3-0.1h0.3
                        l0.6,0.1h0.1L49.1,51.6z"
                                    ></path>
                                    <polygon
                                        className="st8"
                                        points="48.5,51.8 48.1,51.8 47.5,51.8 47.1,51.8 47.1,51.9 47.1,51.9 47.4,51.9 47.8,51.9 48.5,52
                        48.7,52 48.7,52 48.8,51.8                   "
                                    ></polygon>
                                    <path
                                        className="st8"
                                        d="M50.3,50.4l-0.7,0h-0.3l-0.3,0l0,0l0,0l-0.3,0.2l-0.3,0.3l-0.2,0.3l0,0l0.3-0.1h0.4l0.3,0.1l0.3,0h0.3l0,0
                        l0.1,0l0.4-0.2l0.3-0.3l0.2-0.3l-0.1,0L50.3,50.4z M50,50.8L49.8,51L49.5,51L49,51l-0.3,0h-0.3l0.3-0.2l0.3-0.2l0.3-0.1h0.3
                        l0.6,0l0.1,0L50,50.8z"
                                    ></path>
                                    <polygon
                                        className="st8"
                                        points="49.5,51.1 49.1,51.1 48.8,51 48.4,51 48.1,51.1 48.1,51.1 48,51.1 48.4,51.1 48.7,51.1 49.4,51.2
                        49.6,51.2 49.7,51.1 49.8,51.1                   "
                                    ></polygon>
                                    <path
                                        className="st8"
                                        d="M51.2,49.5h-0.7l-0.3,0l-0.3,0.1l0.1-0.1l-0.1,0l-0.3,0.3L49.2,50L49,50.3l0.3-0.1l0.7,0h0.3l0.3,0l0,0.1
                        l0,0L51,50l0.3-0.3l0.1-0.3l-0.1,0L51.2,49.5z M50.9,50l-0.2,0.1l-0.2,0.1l-0.5,0l-0.3,0l-0.3,0l0.2-0.3l0.3-0.2l0.3-0.1l0.3,0
                        l0.6,0l0.1,0L50.9,50z"
                                    ></path>
                                    <path
                                        className="st8"
                                        d="M52,48.5l-0.7,0L51,48.6l-0.3,0.1l0.1-0.1l-0.1,0l-0.3,0.3L50,49.2l-0.1,0.2l0.1-0.1l0.2,0l0.3,0h0.4h0.3
                        l0.3,0l0,0.1l0.1,0l0.3-0.3l0.3-0.3l0.1-0.3l-0.1,0L52,48.5z M51.7,49l-0.2,0.1l-0.2,0.1h-0.5l-0.5,0l0.2-0.3l0.3-0.2l0.3-0.1
                        l0.3-0.1l0.6,0H52L51.7,49z"
                                    ></path>
                                    <polygon
                                        className="st8"
                                        points="51.2,49.4 50.9,49.4 50.5,49.4 50.2,49.4 50,49.4 49.9,49.5 49.8,49.6 50.2,49.5 50.5,49.5
                        51.2,49.5 51.4,49.4 51.5,49.4 51.5,49.3                     "
                                    ></polygon>
                                    <path
                                        className="st8"
                                        d="M52.8,47.6l-0.7,0.1l-0.3,0.1l-0.3,0.1l0.1-0.1l-0.1,0L51.1,48l-0.3,0.3l-0.1,0.2l0.3-0.1l0.3,0l0.3,0H52
                        l0.3-0.1l-0.1,0.1l0.1-0.1l0.3-0.3l0.3-0.4l0.1-0.3l-0.1,0L52.8,47.6z M52.5,48.1l-0.2,0.2l-0.2,0.1l-0.6,0l-0.3,0l-0.3,0
                        l0.2-0.3l0.3-0.2l0.3-0.2l0.3-0.1l0.6-0.1h0.1L52.5,48.1z"
                                    ></path>
                                    <polygon
                                        className="st8"
                                        points="52,48.4 51.7,48.4 51.3,48.5 51,48.5 50.7,48.6 50.6,48.7 51,48.6 51.3,48.6 52,48.5 52.2,48.5
                        52.3,48.5 52.3,48.4                     "
                                    ></polygon>
                                    <path
                                        className="st8"
                                        d="M53.6,46.6l-0.7,0.1l-0.3,0.1l-0.3,0.1l0.1-0.1l-0.1,0l-0.3,0.3l-0.3,0.4l-0.1,0.2l0.3-0.1l0.3-0.1l0.4,0
                        l0.3,0l0.3-0.1l0,0.1l0.1,0l0.3-0.3l0.2-0.4l0.1-0.2l0,0L53.6,46.6z M53.3,47.1l-0.2,0.2l-0.2,0.1l-0.5,0.1l-0.3,0l-0.3,0.1
                        l0.2-0.3l0.2-0.3l0.3-0.2l0.3-0.1l0.6-0.1l0.1,0L53.3,47.1z"
                                    ></path>
                                    <polygon
                                        className="st8"
                                        points="52.8,47.5 52.5,47.5 52.1,47.5 51.8,47.6 51.5,47.7 51.4,47.8 51.8,47.7 52.1,47.7 52.8,47.6
                        53,47.5 53.1,47.5 53.1,47.4                     "
                                    ></polygon>
                                    <polygon
                                        className="st8"
                                        points="54.3,45.6 53.7,45.7 54.2,45.6 54.3,45.6 54,46.1 53.8,46.3 53.6,46.4 53.1,46.5 52.5,46.6
                        52.7,46.3 52.9,46.1 53.2,45.9 53.3,45.9 52.9,46 53,45.8 52.9,45.9 52.6,46.2 52.4,46.6 52.3,46.8 52.5,46.7 52.9,46.6
                        53.2,46.5 53.5,46.5 53.8,46.4 53.8,46.5 53.9,46.5 54.2,46.1 54.4,45.8 54.5,45.5 54.5,45.5                   "
                                    ></polygon>
                                    <path
                                        className="st8"
                                        d="M54.2,44.6l-0.6,0.2l-0.1,0.1l-0.3,0.3l-0.2,0.4L53,45.8l0,0l0.1-0.1l0.3-0.1l0.3-0.1l0.3-0.1l0.3-0.1
                        l0,0.2l0.1-0.1l0.3-0.3l0.2-0.4l0.1-0.3l-0.2,0.1L54.2,44.6z M54.7,45l-0.2,0.2l-0.2,0.2l-0.5,0.1l-0.3,0.1l-0.3,0.1l0.2-0.3
                        l0.2-0.3l0.3-0.2l0.3-0.1l0.6-0.1l0.1,0L54.7,45z"
                                    ></path>
                                    <polygon
                                        className="st8"
                                        points="54.9,44.4 54.5,44.5 53.9,44.7 53.7,44.8 53.6,44.9 54.2,44.6 54.9,44.5 55.1,44.4 55.1,44.3
                                            "
                                    ></polygon>
                                    <polygon
                                        className="st8"
                                        points="54.2,45.5 53.9,45.5 53.5,45.6 53.2,45.7 53.1,45.8 53,45.8 52.9,46 53.3,45.9 53.6,45.8
                        53.7,45.7 54.3,45.6 54.5,45.5 54.5,45.5 54.5,45.4                   "
                                    ></polygon>
                                </g>
                            </g>
                        </g>
                        <polygon points="53.7,45.7 53.6,45.8 53.3,45.9 53.6,45.8        "></polygon>
                        <path
                            className="st8"
                            d="M57.3,28.7l-0.1-1.3L57,26l-0.2-1.3l-0.3-1.3l-0.4-1.3l-0.4-1.3l-0.5-1.2l-0.5-1.2l-0.6-1.1l-0.6-1.1
            l-0.7-1.1l-0.7-1l-0.8-1l-0.9-1l-0.9-0.9l-0.9-0.9l-1-0.8l-1-0.8l-1.1-0.7l-1.1-0.7l-1.1-0.6l-1.1-0.6L41,5.4l-1.2-0.5l-1.2-0.4
            l-1.3-0.4L36,3.8l-1.3-0.2l-1.3-0.2L32,3.3l-1.4,0l-1.4,0l-1.3,0.1l-1.3,0.2l-1.3,0.2l-1.3,0.3l-1.3,0.4l-1.2,0.4l-1.2,0.5L19,5.9
            l-1.1,0.6l-1.1,0.6l-1.1,0.7l-1.1,0.7l-1,0.8l-1,0.8l-0.9,0.9l-0.9,0.9l-0.8,1l-0.8,1l-0.7,1l-0.7,1.1L7,17.3l-0.6,1.1l-0.5,1.2
            l-0.5,1.2L5,22.1l-0.4,1.3l-0.3,1.3L4.1,26l-0.2,1.3l-0.1,1.3l0,1.4l0,1.4l0.1,1.3l0.2,1.3l0.2,1.3l0.3,1.3L5,38l0.4,1.2l0.5,1.2
            l0.5,1.2L7,42.8l0.7,1.1L8.4,45l0.7,1.1l0.8,1l0.8,1l0.9,0.9l0.9,0.9l1,0.9l1,0.8l1.1,0.7l1.1,0.7l1.1,0.6l1.1,0.6l1.2,0.5
            l1.2,0.5l1.2,0.4l1.3,0.4l1.3,0.3l1.3,0.2l1.3,0.2l1.3,0.1l1.4,0l1.4,0l1.3-0.1l1.3-0.2l1.3-0.2l1.3-0.3l1.3-0.4l1.2-0.4l1.2-0.5
            l1.2-0.5l1.1-0.6l1.1-0.6l1.1-0.7l1.1-0.7l1-0.8l1-0.9l0.9-0.9l0.9-0.9l0.9-1l0.8-1l0.7-1.1l0.7-1.1l0.6-1.1l0.6-1.1l0.5-1.2
            l0.5-1.2l0.4-1.2l0.4-1.3l0.3-1.3l0.2-1.3l0.2-1.3l0.1-1.3l0-1.4L57.3,28.7z M57.1,31.4L57,32.8l-0.2,1.3l-0.2,1.3l-0.3,1.3
            l-0.4,1.3l-0.4,1.2L55,40.4l-0.5,1.2l-0.6,1.1l-0.6,1.1l-0.7,1.1l-0.7,1l-0.8,1l-0.8,0.9l-0.9,0.9l-0.9,0.9l-1,0.8l-1,0.8l-1,0.7
            l-1.1,0.7l-1.1,0.6L42.1,54l-1.2,0.5L39.7,55l-1.2,0.4l-1.3,0.4L35.9,56l-1.3,0.2l-1.3,0.2l-1.3,0.1l-1.3,0l-1.3,0l-1.3-0.1
            l-1.3-0.2L25.3,56L24,55.7l-1.2-0.4L21.5,55l-1.2-0.5L19.1,54L18,53.4l-1.1-0.6L15.8,52l-1.1-0.7l-1-0.8l-0.9-0.8l-0.9-0.9
            L11,47.9l-0.8-0.9l-0.8-1l-0.7-1l-0.7-1.1l-0.6-1.1l-0.6-1.1l-0.5-1.2l-0.5-1.2l-0.4-1.2l-0.4-1.3l-0.3-1.3l-0.2-1.3l-0.2-1.3
            l-0.1-1.3l0-1.3l0-1.4l0.1-1.3L4.4,26l0.2-1.3l0.3-1.3l0.4-1.3l0.4-1.2l0.5-1.2l0.5-1.2l0.6-1.1l0.6-1.1l0.7-1.1l0.7-1l0.8-1
            l0.8-1l0.9-0.9l0.9-0.9l0.9-0.9l1-0.8l1.1-0.7l1.1-0.7L18,6.7l1.1-0.6l1.2-0.5l1.2-0.5l1.2-0.4L24,4.4l1.3-0.3l1.3-0.2l1.3-0.2
            l1.3-0.1l1.3,0l1.3,0l1.3,0.1l1.3,0.2l1.3,0.2l1.3,0.3l1.3,0.4l1.2,0.4l1.2,0.5l1.2,0.5l1.1,0.6l1.1,0.6l1.1,0.7l1,0.7l1,0.8
            l1,0.9l0.9,0.9l0.9,0.9l0.8,1l0.8,1l0.7,1l0.7,1.1l0.6,1.1l0.6,1.1l0.5,1.2l0.5,1.2l0.4,1.2l0.4,1.3l0.3,1.3l0.2,1.3l0.2,1.3
            l0.1,1.3l0,1.4L57.1,31.4z"
                        ></path>
                        <path
                            className="st8"
                            d="M51.3,29l-0.1-1.1l-0.1-1l-0.2-1l-0.2-1l-0.3-1L50,23L49.7,22l-0.4-0.9l-0.5-0.9l-0.5-0.9l-0.5-0.8l-0.6-0.8
            l-0.6-0.8l-0.7-0.7l-0.7-0.7l-0.7-0.7l-0.7-0.7L43,13.5l-0.8-0.6l-0.8-0.5l-0.9-0.5l-0.9-0.5L38.7,11l-0.9-0.4l-1-0.3l-1-0.3
            l-1-0.2l-1-0.2l-1-0.1l-1.1-0.1l-1.1,0l-1.1,0l-1.1,0.1l-1,0.1l-1,0.2l-1,0.2l-1,0.3l-0.9,0.3L22.5,11l-0.9,0.4l-0.9,0.5l-0.9,0.5
            L19,12.9l-0.8,0.6l-0.8,0.6l-0.8,0.7l-0.7,0.7l-0.7,0.7l-0.7,0.7L14,17.7l-0.6,0.8l-0.5,0.8l-0.5,0.9l-0.5,0.9L11.5,22L11.1,23
            l-0.3,0.9l-0.3,1l-0.2,1l-0.2,1l-0.1,1L9.9,29l0,1.1l0,1.1l0.1,1.1l0.1,1l0.2,1l0.2,1l0.3,1l0.3,1l0.4,0.9l0.4,0.9l0.5,0.9
            l0.5,0.9l0.5,0.8l0.6,0.8l0.6,0.8l0.7,0.7l0.7,0.7l0.7,0.7l0.8,0.7l0.8,0.6l0.8,0.6l0.9,0.5l0.9,0.5l0.9,0.5l0.9,0.4l0.9,0.4
            l0.9,0.3l1,0.3l1,0.2l1,0.2l1,0.1l1.1,0.1l1.1,0l1.1,0l1.1-0.1l1-0.1l1-0.2l1-0.2l1-0.3l1-0.3l0.9-0.4l0.9-0.4l0.9-0.5l0.9-0.5
            l0.8-0.5l0.8-0.6l0.8-0.6l0.7-0.7l0.7-0.7l0.7-0.7l0.7-0.7l0.6-0.8l0.6-0.8l0.5-0.8l0.5-0.9l0.5-0.9l0.4-0.9l0.4-0.9l0.3-1l0.3-1
            l0.2-1l0.2-1l0.1-1l0.1-1.1l0-1.1L51.3,29z M51.1,31.1l-0.1,1l-0.1,1l-0.2,1l-0.2,1l-0.3,1l-0.3,0.9l-0.4,0.9L49.1,39l-0.5,0.9
            l-0.5,0.9l-0.5,0.8l-0.6,0.8l-0.6,0.8l-0.7,0.7l-0.7,0.7l-0.7,0.7l-0.7,0.7l-0.8,0.6l-0.8,0.6l-0.8,0.5l-0.9,0.5l-0.9,0.5L38.6,49
            l-0.9,0.3l-0.9,0.3l-1,0.3l-1,0.2l-1,0.2l-1,0.1l-1,0.1l-1.1,0l-1.1,0l-1-0.1l-1-0.1l-1-0.2l-1-0.2l-1-0.3l-0.9-0.3L22.6,49
            l-0.9-0.4l-0.9-0.5l-0.9-0.5l-0.8-0.5l-0.8-0.6l-0.8-0.6l-0.7-0.7L16,44.6l-0.7-0.7l-0.7-0.7l-0.6-0.8l-0.6-0.8L13,40.7l-0.5-0.9
            L12,39l-0.4-0.9l-0.4-0.9l-0.3-0.9l-0.3-1l-0.2-1l-0.2-1l-0.1-1l-0.1-1l0-1.1l0-1.1l0.1-1l0.1-1l0.2-1l0.2-1l0.3-1l0.3-1l0.4-0.9
            l0.4-0.9l0.5-0.9l0.5-0.9l0.5-0.8l0.6-0.8l0.6-0.8l0.7-0.8l0.7-0.7l0.7-0.7l0.7-0.7l0.8-0.6l0.8-0.6l0.8-0.5l0.9-0.5l0.9-0.5
            l0.9-0.4l0.9-0.4l0.9-0.3l1-0.3l1-0.2l1-0.2l1-0.1l1-0.1l1.1,0l1.1,0l1,0.1l1,0.1l1,0.2l1,0.2l1,0.3l0.9,0.3l0.9,0.4l0.9,0.4
            l0.9,0.5l0.9,0.5l0.8,0.5l0.8,0.6l0.8,0.6l0.7,0.7l0.7,0.7l0.7,0.7l0.7,0.8l0.6,0.8l0.6,0.8l0.5,0.8l0.5,0.9l0.5,0.9l0.4,0.9
            l0.4,0.9l0.3,1l0.3,1l0.2,1l0.2,1l0.1,1l0.1,1l0,1.1L51.1,31.1z"
                        ></path>
                        <path
                            className="st8"
                            d="M51,29l-0.1-1l-0.1-1l-0.2-1l-0.2-1l-0.3-1L49.8,23l-0.4-0.9L49,21.2l-0.4-0.9l-0.5-0.9l-0.5-0.8L47,17.8
            L46.4,17l-0.7-0.7L45,15.6l-0.7-0.7l-0.7-0.7l-0.8-0.6L42,13.1l-0.8-0.5l-0.9-0.5l-0.9-0.4l-0.9-0.4l-0.9-0.4l-0.9-0.3l-0.9-0.3
            l-1-0.2l-1-0.2l-1-0.1l-1-0.1l-1.1,0l-1.1,0l-1,0.1l-1,0.1l-1,0.2l-1,0.2l-1,0.3l-0.9,0.3l-0.9,0.4l-0.9,0.4l-0.9,0.4L20,12.6
            l-0.8,0.5l-0.8,0.6l-0.8,0.6l-0.7,0.7l-0.7,0.7l-0.7,0.7L14.8,17l-0.6,0.8l-0.6,0.8l-0.5,0.8l-0.5,0.9l-0.5,0.9l-0.4,0.9L11.4,23
            L11.1,24l-0.3,1l-0.2,1l-0.2,1l-0.1,1l-0.1,1l0,1.1l0,1.1l0.1,1l0.1,1l0.2,1l0.2,1l0.3,0.9l0.3,0.9l0.4,0.9l0.4,0.9l0.5,0.9
            l0.5,0.9l0.5,0.8l0.6,0.8l0.6,0.8l0.6,0.7l0.7,0.7l0.7,0.7l0.7,0.7l0.8,0.6l0.8,0.6l0.8,0.5l0.8,0.5l0.9,0.4l0.9,0.4l0.9,0.4
            l0.9,0.3l1,0.3l1,0.2l1,0.2l1,0.1l1,0.1l1.1,0l1.1,0l1-0.1l1-0.1l1-0.2l1-0.2l0.9-0.3l0.9-0.3l0.9-0.4l0.9-0.4l0.9-0.4l0.9-0.5
            L42,47l0.8-0.6l0.8-0.6l0.7-0.7l0.7-0.7l0.7-0.7l0.7-0.7l0.6-0.8l0.6-0.8l0.5-0.8l0.5-0.9l0.4-0.9l0.4-0.9l0.4-0.9l0.3-0.9
            l0.3-0.9l0.2-1l0.2-1l0.1-1l0.1-1l0-1.1L51,29z M50.7,31.1l-0.1,1l-0.1,1l-0.2,1l-0.2,1l-0.3,1L49.5,37l-0.3,0.9l-0.4,0.9
            l-0.4,0.9l-0.5,0.8l-0.5,0.8l-0.6,0.8l-0.6,0.8l-0.6,0.7l-0.7,0.7L44.1,45l-0.7,0.6l-0.8,0.6l-0.8,0.6L41,47.3l-0.8,0.5l-0.9,0.5
            l-0.9,0.4L37.5,49l-0.9,0.3l-1,0.3l-1,0.2l-1,0.2l-1,0.1l-1,0.1l-1,0l-1,0l-1-0.1l-1-0.1l-1-0.2l-1-0.2l-1-0.3L23.7,49l-0.9-0.3
            l-0.9-0.4L21,47.8l-0.8-0.5l-0.8-0.5l-0.8-0.6l-0.8-0.6L17,45l-0.7-0.7l-0.7-0.7L15,42.9l-0.6-0.8l-0.6-0.8l-0.5-0.8l-0.5-0.8
            l-0.5-0.9L12,37.9L11.7,37l-0.3-0.9l-0.3-1l-0.2-1l-0.2-1l-0.1-1l-0.1-1l0-1l0-1l0.1-1l0.1-1l0.2-1l0.2-1l0.3-0.9l0.3-0.9l0.3-0.9
            l0.4-0.9l0.5-0.9l0.5-0.9l0.5-0.8l0.6-0.8l0.6-0.7l0.6-0.7l0.7-0.7l0.7-0.7l0.7-0.6l0.8-0.6l0.8-0.6l0.8-0.5l0.8-0.5l0.9-0.4
            l0.9-0.4l0.9-0.3l0.9-0.3l1-0.3l1-0.2l1-0.2l1-0.1l1-0.1l1,0l1,0l1,0.1l1,0.1l1,0.2l1,0.2l1,0.3l0.9,0.3l0.9,0.3l0.9,0.4l0.9,0.4
            l0.8,0.5l0.8,0.5l0.8,0.6l0.8,0.6l0.7,0.6l0.7,0.7l0.7,0.7l0.6,0.7l0.6,0.7l0.6,0.8l0.5,0.8l0.5,0.9l0.4,0.9l0.4,0.9l0.3,0.9
            l0.3,0.9l0.3,0.9l0.2,1l0.2,1l0.1,1l0.1,1l0,1L50.7,31.1z"
                        ></path>
                        <polygon
                            className="st9"
                            points="44,50 44.4,49.1 43.6,49.6 42.8,49 43,50 42.2,50.5 43.1,50.6 43.3,51.6 43.8,50.6 44.8,50.7       "
                        ></polygon>
                        <polygon
                            className="st9"
                            points="18.2,49.8 18.4,48.8 17.6,49.4 16.8,48.9 17.2,49.8 16.4,50.5 17.4,50.4 17.9,51.4 18.1,50.4
            19.1,50.3       "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="16.3,21.1 16.2,21.1 16,21.2 15.7,21.5 15.2,21.2 14.7,21 14.2,20.7 13.8,20.5 13.5,20.4 13.2,20.6
            13.1,20.7 13,20.8 13.1,20.8 13.2,20.8 13.3,20.8 13.4,20.7 13.6,20.7 13.8,20.7 13.9,20.8 14.2,21 14.5,21.1 15,21.4 15.5,21.6
            15.4,21.8 15.3,22 15.1,22 15,22 14.8,22 14.4,21.8 14.2,21.8 14.1,21.8 13.3,22 12.9,22.1 12.6,22.3 12.3,22.5 12.2,22.6
            12.1,22.7 12.2,22.8 12.4,22.7 12.6,22.6 12.9,22.4 13.1,22.3 13.3,22.2 13.5,22.2 13.6,22.2 13.8,22.1 13.9,22.1 14.3,22.1
            14.7,22.2 15.1,22.4 15.5,22.5 16,22.6 15.9,22.5 15.9,22.4 15.7,22.3 15.6,22.2 15.5,22.2 15.4,22.2 15.4,22.1 15.6,22
            15.8,21.8 16,21.6 16.2,21.4 16.3,21.3 16.4,21.3 16.4,21.2       "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="27.1,25.2 27,25.2 26.8,25.1 26.9,25 27.1,25 27.2,25 27.3,24.9 27.1,24.8 26.8,24.8 26.6,24.9
            26.3,24.8 26.3,24.9 26,24.7 25.6,24.5 25.5,24.7 25.7,24.9 25.9,25 26.5,25.3 27.2,25.5 27.2,25.3 27.2,25.2       "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="29.3,25 28,24.9 28,25 28.1,25.1 28.7,25.1 29.4,25.1         "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="34.9,24.9 33.6,24.9 32.5,24.9 31.3,24.9 31.2,24.9 31.1,24.9 31,24.9 31,25 33.4,25 33.5,25.1
            34.3,25 34.7,25 35.1,25.1 35.1,25.1 35.2,25.1 35.4,25.1 35.8,25.1 36,25 36.1,25 36.2,24.9       "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="32,25.1 31.3,25.1 31.1,25.1 31,25.1 30.9,25.1 30.9,25.2 31.9,25.1 31.9,25.1 31.9,25.1       "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="31.5,25.4 31.2,25.4 30.8,25.4 31,25.4 31.2,25.5 31.7,25.4       "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="29.4,25.9 29,25.8 28.7,25.7 28.4,25.5 28.2,25.5 28,25.5 28,25.7 28.2,25.8 28.4,25.9 28.6,25.9
            28.8,26 29.3,26.1 29.7,26.1         "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="15.3,25.8 15.2,25.8 13.8,25.8 13.6,25.9 13.5,25.9 13.3,26 12.3,26.4 11.8,26.5 11.3,26.6
            11.2,26.7 11.1,26.7 11.1,26.7 11,26.8 11.1,27 11.3,27 11.4,26.9 11.6,26.8 11.8,26.8 12.5,26.6 12.8,26.5 13.2,26.3 13.3,26.3
            13.4,26.2 13.5,26.2 13.6,26.1 14.5,26.1 15.4,26 15.4,25.8       "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="31.2,25.5 30.9,25.5 30.9,25.6 31.5,25.5 31.5,25.5       "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="26.7,26.2 26.3,26.1 25.8,26 25.4,25.8 25,25.7 24.7,25.6 24.6,25.7 24.6,25.9 24.9,25.9 25.2,26.1
            25.5,26.2 25.8,26.2 25.9,26.3 26.1,26.3 26.4,26.4 26.6,26.5 26.9,26.5 27.2,26.6 27.2,26.3       "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="35.8,25.5 35.3,25.5 34.7,25.5 34.2,25.5 33.7,25.6 34.1,25.6 34.5,25.6 34.4,25.6 34.3,25.6
            34.8,25.7 35.1,25.7 35.3,25.7 35.2,25.6 35.5,25.5       "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="34.8,25.8 34.6,25.8 34.6,25.9 35.1,25.8         "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="34.2,25.9 33.4,25.9 33.4,26 33.3,26 33.3,26 33.3,26 33.6,26.1 33.1,26.1 32.7,26.1 32.2,26.1
            31.7,26.1 31.2,26.1 30.8,26.2 33.4,26.2 33.6,26.1 33.9,26.1 34.1,26.2 34.3,26.1 34.3,26.1 34.2,26 34.3,26 34.5,26 34.8,26
            34.9,26 35,25.9         "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="28.3,26.5 28.2,26.5 28.1,26.5 28,26.6 28.2,26.7 28.3,26.7 28.4,26.7         "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="44.5,25 44.4,24.9 44.2,24.9 43.9,24.9 43.6,24.9 43.7,25.1 44,25.1 44.2,25.2 44.4,25.2 44.6,25.1
                    "
                        ></polygon>
                        <path
                            d="M48.8,25.2L48.8,25.2L48.8,25.2L48.8,25.2z M49.6,25h-0.2l-0.2,0l-0.2,0l-0.2,0.2l0.1,0h0.2l-0.1,0l-0.1,0l0.1,0h0.1
            l0.1,0l0.1,0h0.5v0L49.6,25z"
                        ></path>
                        <polygon
                            className="st10"
                            points="44.8,25.3 44.6,25.3 44.8,25.3 44.9,25.3 45,25.3         "
                        ></polygon>
                        <path
                            d="M46.3,26.5h0.1H46.3L46.3,26.5z M46.5,26.6L46.5,26.6L46.5,26.6L46.5,26.6z M48.6,25.6L48.6,25.6l0-0.1l-0.1-0.1L48,25.4
            l-0.5,0H47l-0.5,0.1l0.1,0.1v0l0,0l-0.1,0l-0.1,0v-0.1l-0.3-0.1h-0.3l-0.3,0l-0.3-0.1l-0.2,0l-0.2,0h-0.4l0.1,0l0.1,0l0.1,0.1
            l0.1,0l0.2,0.1h0.2l0.2,0.1h0h0l-0.1,0l0,0.1l0,0.2L45.1,26l-0.1,0l0.1,0l0.1,0l-0.1,0.1l-0.1,0v0.1l0,0l-0.1,0l0,0.1l0.1,0.1
            l0.1,0l0,0h0v0.1l0.2,0l0.1,0l0.1-0.1l-0.1-0.1l0.1,0l0.1,0h0.1v-0.1h0l0.1-0.1l-0.1,0l0,0v0l0,0L45.6,26l0.3,0l0-0.1l-0.1-0.1v0
            l0,0l0,0l-0.1,0h-0.1h-0.1l-0.1-0.1l0.2-0.1h0.2h0.1l0,0l0.3,0h0.3h0.3l0.3,0.1v-0.1v-0.1l0.2,0h0.2l0.2,0h0.2l0,0l0.1,0h0.1
            l-0.2,0.1h-0.2v0.1h0.1h0.1l-0.1,0.1l0.1-0.1l0.1,0l0.1,0v0.1l-0.1,0l-0.1,0h0.1l0.1,0l0,0v0l0,0.1l0,0.1l0,0l0,0.1l0,0l-0.1,0
            h-0.1l0.1,0l0.1,0l-0.1,0.1l0.1,0.1l0.1,0l-0.1-0.1v-0.1l0-0.1l-0.1,0l0.2-0.2l0.2-0.2l-0.1-0.1l-0.1-0.1l0.1-0.1h0.1L48.6,25.6
            L48.6,25.6L48.6,25.6L48.6,25.6L48.6,25.6L48.6,25.6z M46.8,25.8l-0.1,0l-0.1,0l-0.1,0l-0.1,0l0,0l0,0l0.1,0l0,0v0l-0.2,0
            l-0.1,0.1L46.2,26l0.3,0l-0.2,0l-0.1,0l-0.1,0.1l0.1,0.1l0.1,0l0.2,0l-0.2,0.1l-0.2,0l0.3,0h0.1l0.1,0l-0.2,0.1l-0.1,0l0,0l0,0.1
            h0.1l0.1,0l0-0.1h0.1l0.1,0.1l0.1,0l0.2-0.1L47,26.4l0.1-0.1l0-0.1l0.1-0.1l0.1,0h-0.1l-0.1-0.1l0.1,0l-0.3,0l0-0.1l0-0.1l0.1,0
            l0.1,0l-0.1,0l-0.1,0l0.1,0h0.1l0.1,0l-0.1-0.1h-0.1l-0.1,0l-0.1,0L46.8,25.8L46.8,25.8z"
                        ></path>
                        <polygon
                            className="st10"
                            points="49,26.3 48.9,26.3 48.7,26.3 48.6,26.3 48.5,26.3 48.5,26.3 48.4,26.3 48.4,26.4 48.5,26.4
            48.6,26.4 48.6,26.4 48.6,26.5 48.7,26.5 48.9,26.5 48.9,26.4 49,26.4 49,26.4 49.1,26.3       "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="32.8,26.6 33.3,26.7 33.6,26.7 33.8,26.6         "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="33.9,26.8 33.3,26.8 32.8,26.7 32.8,26.8 32.7,26.8 32.5,26.8 32.6,26.8 32.7,26.9 32.9,26.9
            33,26.9 33.8,26.9 34.3,26.8 34.7,26.8 34.8,26.8 34.9,26.8 34.9,26.7         "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="25.5,27.3 25.3,27.2 25,27.2 24.7,27.1 24.7,27.3 25,27.4 25.2,27.4 25.8,27.4         "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="49.7,28.5 49.4,28.5 49,28.4 49,28.5 49.7,28.6 50.3,28.5         "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="50.2,29.3 49.9,29.3 49.5,29.3 49.2,29.3 49.2,29.4 49.5,29.4 49.8,29.4 50.5,29.4 50.6,29.3       "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="50.2,31 49.8,31 49.8,31 49.7,31 49.7,31 49.8,31.1 50,31 50.1,31 50.2,31.1 50.4,31.1 50.5,31
            "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="50,31.2 49.8,31.2 49.3,31.2 49.4,31.3 50.2,31.3 50.2,31.2       "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="48.5,32.3 48.1,32.2 47.8,32.2 47.8,32.3 48.5,32.3 48.8,32.3 49.2,32.3 49.2,32.3         "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="49.9,32.3 49.7,32.3 49.4,32.3 49.4,32.3 50,32.3         "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="50.3,32.7 50.2,32.6 50,32.6 49.7,32.6 49.5,32.5 49,32.5 48.6,32.5 48.1,32.4 47.7,32.3 47.6,32.5
            47.9,32.6 48.1,32.7 48.4,32.7 48.7,32.7 48.9,32.7 49.7,32.8 50.4,32.7       "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="49.7,32.4 49.3,32.4 49.3,32.4 49.6,32.5 50,32.5         "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="31,37.1 29.7,37.4 29,37.6 28.4,37.8 28.2,38 28.2,38.1 28.2,38.2 29,38 29.8,37.8 30.1,37.7
            30.5,37.6 30.8,37.5 30.9,37.4 31.1,37.3         "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="27.2,38.2 26.9,38.2 26.6,38.3 25.3,38.6 24,38.9 23.9,39.1 23.9,39.2 24.3,39.2 24.7,39.2
            25.5,38.9 25.7,38.9 25.8,38.9 25.9,38.8 26.6,38.7 27.3,38.4         "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="15.7,40.5 15.1,40.6 14.8,40.6 14.5,40.6 14.1,40.5 13.9,40.5 13.8,40.6 14,40.7 14.1,40.7 15,40.8
            15.4,40.8 15.8,40.7         "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="15.3,41 15,41 14.8,41 14.7,41 14.7,41 14.6,41 14.6,41.1 15.5,41 15.7,41 15.8,40.9 15.8,40.9
            "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="15.8,41.1 15.5,41.2 15.2,41.2 15,41.3 14.8,41.3 14.8,41.4 15.1,41.3 15.4,41.3 15.8,41.2         "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="15.7,41.4 15.7,41.4 15.6,41.5 15.5,41.5 15.4,41.5 15.4,41.5 15.6,41.5 15.7,41.4         "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="28.8,42.5 28.4,42.6 29,42.5         "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="29.6,42.7 29.4,42.7 29.8,42.7 29.8,42.7         "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="28.5,42.7 28.3,42.7 28.1,42.8 28.4,42.8 28.5,42.8 28.5,42.8 28.6,42.8       "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="16.1,43 15.8,43 16.1,43.1 16.4,43       "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="28.8,42.9 28.5,42.9 27.9,43 27.8,43 27.6,43 27.5,43 27.3,43.1 27.6,43.1 27.9,43.1 27.7,43.2
            27.5,43.2 28.4,43.1 28.4,43.1 28.3,43.1 28.2,43.1 28.3,43 28.4,43 28.5,43 28.6,43 28.7,43 28.8,43 29.1,43       "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="24.2,43.2 23.8,43.3 24.2,43.3 24.6,43.2         "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="24,43.4 23.9,43.4 24.3,43.5 24.7,43.5 25.1,43.4 25.5,43.4       "
                        ></polygon>
                        <path
                            d="M27.5,43.5L27.5,43.5L27.5,43.5L27.5,43.5z M27.5,43.4h-0.7h-0.6l1.1,0.1L27.5,43.4L27.5,43.4L27.5,43.4z M28.3,43.4
            l-0.1,0l-0.2,0l-0.3,0l-0.1,0h0l0,0l0,0L28.3,43.4L28.3,43.4z"
                        ></path>
                        <polygon
                            className="st10"
                            points="25.3,43.5 25.5,43.6 25.5,43.5       "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="32.3,43.4 32.2,43.5 32.1,43.5 32.6,43.4         "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="24.3,43.6 24.7,43.6 25.1,43.6       "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="26.6,43.6 26.3,43.6 25.8,43.6 26,43.6 26.3,43.6 26.6,43.6 26.9,43.6         "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="21.6,43.7 21.6,43.7 21.6,43.7 21.5,43.7 21.3,43.8 21.6,43.9 21.4,43.9 21.2,43.9 21.2,43.9
            20.8,44.1 20.2,44.2 20,44.2 19.8,44.1 19.7,44.1 19.6,44.1 19.5,44.1 19.7,44.2 19.7,44.2 19.8,44.2 19.6,44.2 20.7,44.2
            20.7,44.3 20.9,44.2 21.2,44.2 21.1,44.2 21,44.2 21.2,44.2 21.4,44.1 21.4,44.1 21.2,44.1 21,44.1 20.9,44.1 21.1,44 21.2,44
            21.6,44 21.5,44 21.5,43.9 21.7,43.9 22,43.9 21.9,44 22,44 22.1,43.9 22,43.9 22,43.9 22.2,43.8 22.3,43.8 22.7,43.8       "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="30.1,43.6 30,43.6 29.9,43.7 29.7,43.6 29.6,43.6 29.8,43.7 29.9,43.7 30.3,43.7       "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="32.4,43.6 31.7,43.7 32.4,43.7 32.7,43.7 33.1,43.6       "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="29.3,43.7 29.3,43.7 29.4,43.6 29.3,43.6 29.2,43.7 29,43.8 28.6,43.7 28.3,43.7 27.6,43.8
            27.7,43.8 28.9,43.9 29,43.8 29.1,43.8 29.4,43.8 29.3,43.7       "
                        ></polygon>
                        <rect
                            x="31.6"
                            y="43.6"
                            className="st10"
                            width="0"
                            height="0.1"
                        ></rect>
                        <polygon
                            className="st10"
                            points="17.5,43.9 17.3,43.9 17.1,44 17.9,44         "
                        ></polygon>
                        <rect
                            x="29.6"
                            y="43.8"
                            className="st10"
                            width="0"
                            height="0"
                        ></rect>
                        <polygon
                            className="st10"
                            points="29.9,43.8 29.7,43.8 29.9,43.8       "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="30,43.8 30,43.8 30.3,43.8       "
                        ></polygon>
                        <rect
                            x="21.7"
                            y="43.9"
                            className="st10"
                            width="0"
                            height="0"
                        ></rect>
                        <polygon
                            className="st10"
                            points="31.3,43.9 32,43.9 31.5,43.8 31.2,43.9 31.1,43.9 31,44 30.9,43.9 30,44 30.2,44 30.4,44 30.4,44
            30.7,44 31,44.1 31.2,44 31.4,44 31.3,43.9       "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="29.1,44 28.7,44 29.5,44         "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="31.7,44 31.6,44 31.5,44 31.7,44.1 31.9,44       "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="29.2,44.1 28.8,44.1 28.4,44.2 29.9,44.2 30,44.1         "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="20.4,44.3 20.3,44.3 20.4,44.3 20.4,44.3         "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="20.4,44.4 20.3,44.4 20.5,44.4       "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="29.9,44.2 29.1,44.3 29.9,44.3 30.3,44.3 30.6,44.2       "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="21.6,44.5 21.5,44.5 21.2,44.5 21,44.5 20.9,44.6 21.3,44.5 21.6,44.6         "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="31.1,44.4 30.7,44.4 31.5,44.4       "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="21.6,24 21.6,24.2 21.7,24.4 21.7,24 21.7,23.7       "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="22.1,26.7 21.8,27.8 21.4,28.9 21.4,29.1 21.5,29.1 21.6,29 21.7,28.7 21.9,28.3 22,27.8 22,27.6
            22,27.5 22.1,27.3 22.2,27 22.2,26.9         "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="16.4,30.5 16.4,30.2 16.3,30.1 16.2,30 16.1,30.5 16.1,30.8 16.1,31 16.2,31 16.4,31 16.4,30.7
            "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="19.3,30.4 19.1,30.4 19,30.4 18.7,30.6 18.4,30.7 18.1,30.8 18.4,30.8 18.6,30.7 18.9,30.6
            19.1,30.6 19.2,30.6 19.2,30.7 19.3,30.7 19.3,30.6 19.3,30.5         "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="18.1,39.8 18,39.6 17.9,39.1 17.7,38.8 17.7,38.6 17.5,38.6 17.6,38.9 17.7,39.2 17.9,39.8
            18.1,39.9 18.2,40 18.3,40       "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="17.8,39.8 17.9,40.1 18,40.3 18.1,40.4       "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="21.2,39.8 21.2,39.8 21.1,40.4 21.1,41 21,41.1 21,41.3 21.1,41.2 21.1,41.1 21.2,40.8 21.2,40.5
            "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="23.3,43.3 23,43.3 22.9,43.4 22.8,43.4 22.6,43.4 22.6,43.5 21.9,43.5 22.2,43.6 22.6,43.5
            23.2,43.4 23.2,43.4 23.2,43.3       "
                        ></polygon>
                        <polygon points="28.2,19.7 28.2,19.7 28.2,19.7      "></polygon>
                        <path
                            d="M29.2,13.7H29l-0.4,0.1l-0.4,0.1l-0.3,0.2l-0.2,0.3l-0.2,0.3l-0.3,0.2l-0.3,0.2l-0.2,0.2l-0.1,0.2l-0.1,0.3l0,0.3
            l-0.1,0.1l-0.1,0l-0.1,0l0.1,0.5l0.1,0.5l0.1,0.1h0.1l0.3-0.1v2.4l0.1-0.1l0.1-0.1v-2.5l0.1-0.1l0.3-0.1l0.2,0l0.1,0.1v2.5
            l0.1-0.1l0.1,0l0-2.4l0.4-0.3l0.2-0.1l0.2-0.2l0.2-1.4l-0.1-0.1L29,14.7l0-0.4l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0-0.2L29.2,13.7z
             M28.8,15.2L28.8,15.2l-0.2,0.2l-0.2,0.1l-0.2,0.1l0,0.1l0.1,0.1l0.1,0l0,0.1l0,0.1l-0.1,0.1V16l-0.1,0l-0.1,0.1h0l-0.1,0.1
            L28,16.2l-0.4,0.1l-0.3,0.1l-0.3,0.2l-0.5,0.3l0-0.1l0-0.1v-0.1l-0.1-0.1l-0.1-0.3l0.3-0.1l0.3-0.1l0.5-0.3l0.5-0.4l0.5-0.3h0.2
            l0.1,0.1L28.8,15.2z M29.2,14L29,14l-0.3,0.2l-0.2,0.1l-0.2,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0,0.1h-0.1l-0.1,0.1l-0.1,0.1L28.2,15
            l-0.1,0.1L28,15.2l-0.3,0.1l0,0h0l-0.1,0.1l-0.2,0.2l-0.2,0.2L26.9,16L26.6,16l0-0.3l0.1-0.2l0.1-0.2l0.2-0.2l0.4-0.3l0.4-0.3
            v-0.1l0,0l0.1-0.3l0.2-0.2l0.5-0.3h0.3l0.3,0L29.2,14z"
                        ></path>
                        <polygon points="27.8,20.5 28,20.5 28,20.4 27.9,20.4 27.5,20.6 27.8,20.6        "></polygon>
                        <polygon points="27.4,20.4 27.4,20.4 27.3,20.3 27.3,20.6 27.4,20.6 27.7,20.3 27.9,20.2 28,20.2 28,20 27.8,20.1      "></polygon>
                        <polygon points="28.3,19.6 28.3,19.5 28.3,19.4 28.2,19.3 28.2,19.3 28.2,19.7        "></polygon>
                        <polygon
                            points="27.4,19.5 27.5,19.4 27.5,19.3 27.5,19.3 27.6,19.4 27.6,19.5 27.6,19.6 27.5,19.8 27.4,19.9 27.3,20.1
            27.3,20.1 27.3,20.2 27.3,20.3 27.4,20.2 27.6,20.1 27.9,19.9 28,19.8 28,19.7 27.9,19.8 27.8,19.9 27.6,19.9 27.5,20 27.6,19.9
            27.7,19.7 28,19.5 28,19.4 27.7,19.5 27.7,19.3 27.5,19.2 27.4,19.1 27.3,19.2 27.3,19.3 27.3,19.4 27.3,19.4 27.3,19.6         "
                        ></polygon>
                        <path
                            d="M26.8,22.4L26.4,23L26,23.6l-0.6,0.6L25,24.5l-0.2,0.1l-0.1-0.3l-0.1-0.3l0-0.3v-0.3l0.7-0.4l0.3-0.2l0.3-0.3l0.5-0.7
            l0.3-0.4l0.3-0.3l-0.1-0.3l0-0.3l0.1-0.3l0.1-0.1l0.1-0.1v-0.1v-0.2l0,0L27,19.9L26.9,20l-0.1,0.1l-0.1,0.3v0.3l0,0.3l-0.5,0.7
            l-0.5,0.6l-0.6,0.5l-0.7,0.4l0,0v-0.1l-0.1-0.1L24.3,23h-0.2L24,23l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.3-0.2l0-0.2l-0.1-0.1L23,22.1
            L23,22l-0.1-0.1l-0.3-0.2l-0.3-0.2l-0.1-0.1l-0.1-0.1L22,21.1l-0.1-0.1l-0.2-0.3l-0.3-0.3l-0.1-0.1L21,20l-0.1,0l-0.1,0h-0.1
            l-0.1,0l0.1-0.3l0.1-0.3l0.2,0l0.2-0.1l0.2-0.2l0.1-0.3l0.2-0.3l0.1-0.3l0-0.2l0-0.2l-0.1-0.1l-0.1-0.1l0.1-0.1l0-0.1l0-0.3l0-0.1
            l0-0.1v-0.2l0-0.1l0-0.1l0-0.1v-0.1l-0.1-0.2L21.8,16l-0.2-0.2l-0.2-0.1L21,15.4l-0.4-0.1l-0.2,0l-0.2-0.1L20,15.1l-0.4,0
            l-0.4,0.1l-0.3,0.1l-0.3,0.1l-0.2,0.2l-0.1,0.2L18.3,16l-0.1,0.4l-0.1,0.5l0,0.5l0.1,0.2l0.1,0.2L18.1,18l0,0.1v0.2L18,18.5v0.2
            l0.1,0.2l-0.2,0.2l-0.1,0.3l-0.2,0.6l0,0.1l0,0.1v0.1l-0.1,0.1L17,20.6l-0.4,0.3l-0.1,0.2l-0.1,0.2l-0.1,0.2l0,0.3l-0.1,0.5
            L16,22.9l-0.1,0.2l-0.1,0.2v1l-0.1,0.2l-0.1,0.2v0.7l-0.1,0.7l-0.2,1.3l0.1,0.7l0,0.8l-0.1,0.8l-0.1,0.7l-0.1,0.2l-0.2,0.2
            L14.7,31l-0.1,0.2l-0.1,0.1l0,0.1v0.2l0,0.3v0.1l-0.1,0.1l-0.3,0.2l-0.3,0.2l-0.1,0.1l-0.1,0.1L13.4,33l0,0.2l0.1,0.1l0.1,0.1
            l0.2,0.1l0.2,0l0.2-0.1l0.4-0.3l0.3,0.9l0.4,1l0.4,0.8l0.2,0.3l0.2,0.2L16,37.3l0.1,0.3l0,0.1V38l-0.2,1.7l-0.1,0.9l0,0.9L15.8,42
            l-0.1,0.6l0.1,0.1l0.1,0H16l0.1,0.1l0.1,0l0,0.1l0.2,0l-0.1,0.3l-0.1,0.3l0.2,0.1l0.2,0.1l0.4,0l0.9,0l0.1-0.1h0.1h0.1l0.1,0
            l0.1,0.2l0.1,0.1l0.2,0.1l0.2,0l0.1,0.1h0.2h0.3l0.1-0.1l0.1,0l0.2,0.1l0.2,0.1h0.2l0.2-0.1l0.5-0.1l0.4-0.2l0.1-0.1l0.1-0.1
            l0.3-0.1l0.3,0l0.1,0l0.1-0.1l0.3,0l0.2-0.1l0.3-0.1h0.2l0.1,0l0.1-0.1l0.1-0.1v-0.1v-0.3l0-0.3v-0.9v-0.8v-0.9l0-0.9l-0.1-0.9
            L23.5,37l-0.1-0.8l0-0.4l0.1-0.4l0.5-0.5l0-0.1l0.1-0.1l0.1,0l0-0.1l0-1.7L24,31.3l0.1-0.2l0-1.1h0.1l0.1,0l0.1,0l0.1,0l0.1,0
            l0-0.6l0-0.6l0.1-1.2l0.1-1.2v-0.6l-0.1-0.6l0.1-0.1l0.1,0l0.4-0.3l0.3-0.3l0.3-0.3l0.3-0.4l0.5-0.8l0.4-0.8l0.1-0.1v-0.2
            L26.8,22.4z M23.1,23.1l0.1-0.2l0-0.2l0-0.4l0.1,0.1l0.1,0.1v0.1l0.1,0.1l0,0.2l-0.1,0.2l-0.1,0.2v0.2L23.2,24l-0.1-0.4L23.1,23.1
            z M22.9,22L22.9,22l0.1,0.5l0,0.2l0,0.2h-0.1V22L22.9,22z M22.9,31.1l0,0.1l-0.1,0.9L22.7,33l-0.1,0.9l-0.2,0.8l0,0.1l0,0h0.1
            l0.1-0.2l0-0.2l0-0.2l0.1-0.2l0.1-1.1l0.1-1.1v0.4l0.1,0.4l0.1,0.4l0,0.2l0,0.2l0,0.2l-0.1,0.2L23,33.9l0,0.2L23,34.4l0,0.3
            L22.9,35l0,0.4l-0.1,0.4L22.7,36l-0.1,0.1l-0.3,0.1l-0.3,0.1l-0.5,0.1l0.3-1.6l0.3-1.6l0.3-1.1l0.1-0.2l0.1-0.3l0.1-0.5l0.1-0.1
            L22.9,31.1z M22.8,23.2v0.2l0,0.1l0,0.1v0.1l0,0v0l0-0.8L22.8,23.2z M22.4,21.6l0,0.1l0.1,0.3l-0.1,0.3l-0.1-0.2V22v-0.2l-0.1-0.2
            L22.4,21.6z M22.3,22.9l0.1,0.2l0.1,0.1l0.1,0.4l0,0.3l-0.1,0.7h-0.1l0,0l-0.1,0.1l-0.4,1.4l-0.4,1.4l-0.3,0.6L21,28.9h0.1l0-0.1
            l0.1-0.1l0.3-0.7l0.3-0.7l0.1-0.4l0.1-0.4l0.1-0.1l0.1-0.1v0.1h0.1l0.1-0.1v-0.1l0.1-0.1v0.7l-0.1,0.4l-0.1,0.3l0,0.4l-0.1,0.4
            L22,28.7l-0.1,0.4l-0.2,0.2l-0.1,0.2l-0.2,0.4l-0.5,0.9l-0.5,0.9l-0.6,0.8l-0.6,0.8l0.1,0l0.1,0l0.1-0.1l0.1,0l0.3-0.3l0.3-0.4
            l0.5-0.7L21,31l0.5-0.8l0.2-0.4l0.2-0.3L21.9,30l-0.1,0.5l-0.3,1l-0.3,0.5L21,32.5l-0.2,0.6l-0.2,0.5L20.5,34l-0.1,0.3L20.1,35
            l-0.2,0.5l-0.3,0.4l-0.4,0.4l-0.4,0.4l0,0.1l0,0.1l0.1,0.1H19l0.2-0.1l0.3-0.3l0.6-1l0-0.1l0.4-1l0.4-1l0.4-1l0.4-1l0.3-0.6
            l0.1-0.3l0.1-0.3l0.1-0.1l0-0.1l0-0.2l0-0.1l0-0.1h0l-0.1,0.9l-0.3,0.9l-0.1,0.5L21.7,32l-0.1,0.4l-0.1,0.5V33l0,0.1l0.1-0.3
            l0.1-0.3l0.1-0.3l0.1-0.3l0.1-0.2l0.1-0.2l0.1-0.2l0-0.2l0.1-0.4l0.1-0.4l0.1-0.4l0.2-0.9l0.1,0.3l0,0.3l-0.1,0.7l-0.1,0.7
            l-0.1,0.7L22.2,32l0,0.2l0,0.3l-0.4,2.3L21.2,37l-0.1,0.1l-0.2,0.1l-0.3,0l-0.4,0.1l-0.2,0l-0.1,0.1l-0.2,0.1l-0.2,0H19l-0.4,0
            l-0.2,0.1l-0.2,0.1l-0.4,0.1h-0.4l-0.4,0l-0.4-0.1l-0.1-0.1l-0.1-0.1l0-0.3l0.1-0.3v-0.3l0.1,0.1l0.1,0h0.1l0.1,0l0.1,0.1l0.1,0.1
            l0.2,0l0.2-0.1l0.3-0.1l0.7-0.4l0.3-0.3l0.3-0.3l0.3-0.2l0.3-0.3l0.1-0.1l0.1-0.2l0.1-0.2v-0.2l0.1-0.1v-0.1l0-0.2l-0.1-0.2
            l-0.1-0.1l-0.1-0.1l-0.2,0l-0.2,0.1l-0.4,0.2l-0.1-0.1L18.1,33l-0.3-0.8l-0.2-0.4l-0.3-0.3L17,31.2L16.5,31l0-0.1l0.1-0.1l0.1,0.1
            l0.1,0.1l0.1,0.1v-0.1l-0.1-0.1l-0.2-0.2l-0.1-0.1l0-0.1l0-0.1l0.1-0.1l0.2,0L17,30l0.2-0.3h0.1l0,0l0.1-0.1l0-0.1v-0.1l0-0.1h0.1
            l0.1,0l0.2,0.1h0.1l0-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1V29l0.1,0l0.1,0h0.1l0.1-0.1l-0.1-0.1l-0.2-0.1l0-0.1l0.1-0.1l0.1-0.1
            l0.5-0.1l0.6,0l1.1,0l0.3,0h0.3l0,0.1l-0.1,0l-0.2,0l-0.5,0.1l-0.2,0.1l-0.3,0l0,0l0,0l0.4,0l0.2,0l0.2,0l-0.3,0.1l-0.1,0.1
            l-0.1,0.1l0.1,0l1.1-0.1l-0.5,0.4l-0.3,0.1l-0.3,0.1l-0.2,0.1l-0.1,0l-0.1,0.1l0.1,0.1l0.1,0l0.2-0.1l0.5-0.2l0.5-0.3v0.1
            l-0.1,0.1l-0.1,0.1l-0.1,0L20,29.4L20,29.4l-0.1,0.1l-0.3,0.2l-0.3,0.2L19.1,30L18.7,30l0,0.1l0.1,0.1l0.2,0l0.5-0.2l0.5-0.3
            l0.5-0.3l0.4-0.4l0.3-0.9l0.1-0.4l0.1-0.5l0.1-0.3l0.1-0.3l0-0.3l0.1-0.3l0.3-0.9l0-0.2l0.1-0.1l0.1-0.1v-0.2v-0.1l0.1,0l0-0.1
            l0-0.1V23L22.3,22.9L22.3,22.9z M17.1,35.6l-0.2-0.4l0.6-0.3l0.5-0.4l0.5-0.4l0.6-0.3h0.1l0.1,0.1l0.2,0.2l-0.2,0.2l-0.2,0.1
            l-0.4,0.3l-0.4,0.3L18,35.1L17.1,35.6z M20.4,27.8l0.1,0.1l0.1-0.1l0.1-0.1l0.1-0.3l0.1-0.3l0.1-0.3l0-0.3l0.1-0.2l0.2-0.4h0v0.5
            l0,0.1l-0.1,0.1l0,0.5l-0.1,0.5L21,27.8l-0.2,0.1L20.6,28h-0.3h-0.5l-0.3,0l-0.2,0L19.2,28l-0.1-0.1l0.4-0.2l0.4-0.2l0.4-0.3
            l0.2-0.2l0.1-0.2l0.1-0.1l-0.1,0.3l-0.1,0.3l0,0.3L20.4,27.8z M19.1,28.1l-0.6,0h-0.3L18,28l0.1-0.1l0.2,0h0.2l0.1,0.1L19.1,28.1z
             M22.1,21.4l0.1,0.1l0,0.1l-0.1-0.1l0,0.1l0,0.1l0,0.2l-0.1-0.1l0-0.2l-0.1-0.5L22.1,21.4z M21,20.2l0.1,0.1l0.1,0.1l0.1,0.1
            l0.1,0.1l0,0.2l0,0.1l0.1,0.1l0-0.2l0.1,0.1l0.2,0.8l0.1,0.8l-0.1,1.7l-0.1,0.3l-0.1,0.2l-0.1,0.1l-0.3,0.6l-0.4,0.6l-0.2,0.3
            l-0.1,0.3l-0.8,0.6l-0.9,0.5h-0.1l0.4-0.2l0.4-0.3l0.2-0.2l0.2-0.2l0.1-0.2l0.1-0.3l0.4-1l0.2-0.5l0.1-0.5l0-0.1l0.3-0.7l0.1,0.5
            l0,0.3l-0.1,0.3l0.1,0.1l0.1-0.1l0.1-0.1l0.1-0.5l0-0.2l-0.1-0.2V23l-0.1-0.7L21.1,21l0-0.1v-0.1v-0.1L21,20.6L21,20.4l-0.1-0.2
            L21,20.2z M18.9,20.4l0.6,0.3l0.4,0.1l0.4,0.1h0.2l0.2-0.1l0.3-0.2v0.1l0,0.1l0,0.1l-0.1,0.1l-0.3,0.1l-0.3,0L20,21.1l-0.5-0.2
            l-0.3-0.2l-0.3-0.2l-0.7-0.4l0.1-0.1h0.1L18.9,20.4z M18.3,19.9L18.3,19.9l0.1-0.1l0,0.1L18.3,19.9L18.3,19.9z M20.5,19.9
            l-0.1,0.5l0,0h0.1l0-0.1l0.1-0.2l0.1,0.1l0.1,0.1l0.1,0.2l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0l-0.4-0.1l-0.5-0.2L19,20.3l-0.2-0.2
            L18.6,20l0.2-0.1l0.1-0.2l0.2-0.4l0.2-0.9l0.1,0.1l0,0.1l0,0.1l0,0.1l0.3,0.3l0.2,0.1l0.2,0.1l0.2,0.2l0.1,0.1l0,0.1l0,0.1h0.1
            l0.1-0.1l0.1-0.1l0.1,0L20.5,19.9z M18.7,16.5L18.7,16.5l0.1,0.1L18.7,16.5z M18.8,18.9L18.8,18.9L18.8,18.9L18.8,18.9L18.8,18.9z
             M21.6,17.4L21.6,17.4l-0.2,0.1l0-0.1l0.1-0.1L21.6,17.4L21.6,17.4z M21.1,15.9L21.1,15.9l0.2,0l0.1,0l0.1,0.1v0.1L21.3,16
            L21.1,15.9L21.1,15.9z M20.8,15.7L20.8,15.7L20.8,15.7L20.8,15.7L20.8,15.7z M19.9,16L19.9,16l0.2,0l0,0.1L20,16.2l0,0l-0.1,0
            L19.9,16L19.9,16z M19.9,16.4l0.1,0.1l0.1,0.1l-0.1,0l-0.1-0.1l-0.1,0l-0.1,0l-0.1-0.1L19.9,16.4L19.9,16.4l-0.1-0.3L19.9,16.4z
             M19.1,15.4h0.1l-0.1,0.1h-0.1L19,15.5L19.1,15.4z M19,17.4L19,17.4v0.2l0,0.1l0,0.1l0,0.1l0.1,0h0.1l0-0.1v-0.1l-0.1-0.1l0-0.1
            v-0.1l0-0.1l-0.1-0.1l0.1,0.1l0,0l0,0.1l0.1,0.2l0.1,0.1l0.1,0l0.1,0l0.1-0.2l0-0.1v-0.1l0.1-0.2L20,17l0.1-0.2l0-0.2l0.3-0.2
            l0.1,0l0.1-0.1h0.1l0.3,0.1l0.1,0l0.1,0.1l0.1,0l0.1,0.1l0.1,0.2l0,0.2v0.2l-0.2,0.1l-0.1,0.1l-0.1,0.1v0.1l0,0.1l0.1,0.2l0,0.2
            l0,0.1l-0.1,0.1l0,0l0,0.1l0.1,0l0.1-0.1l0-0.1V18l-0.1-0.2h0.1l0.1,0.1l0.1,0.1l-0.1,0.3l-0.1,0.3l-0.1,0.3l-0.1,0.3l-0.2,0.1
            h-0.2l-0.2-0.1l-0.2,0l-0.3-0.1L20,18.9l-0.3-0.2l-0.2-0.3l-0.1-0.2L19.4,18l0,0.1l-0.1,0h-0.2l-0.2-0.2l-0.1-0.1l0-0.1L19,17.4
            L19,17.4z M18.7,15.6L18.7,15.6L18.7,15.6L18.7,15.6z M18.5,17L18.5,17L18.5,17L18.5,17L18.5,17z M18.5,17.9L18.5,17.9l0.1-0.1
            l0.1,0V18v0l0,0v0.1l-0.2,0L18.5,17.9L18.5,17.9z M18.3,18.3L18.3,18.3l0.1-0.1l0,0.1l0,0.1l-0.1,0.1l0,0L18.3,18.3L18.3,18.3z
             M18.1,19.6L18.1,19.6l0.3-0.2l0.2-0.1l-0.2,0.3l-0.2,0.3l0-0.1L18.1,19.6L18.1,19.6z M18.1,19.4L18.1,19.4L18.1,19.4L18.1,19.4z
             M17.7,19.7l0.1-0.2l0.1-0.1l0.1,0.1v0.1l-0.1,0.2l-0.1,0l-0.1,0L17.7,19.7L17.7,19.7z M15.7,25.7l0.1-0.5v-0.5l0.1-0.3l0.1-0.2
            l0-0.2l0.1-0.1l0-0.1v-0.1v-0.1l0.2-0.5l0.1-0.3l0-0.3l0.1-0.4l0.1-0.3l0.2-0.3l0.2-0.3l0.1-0.2l0.1-0.1l0.1-0.1l0.1-0.1l0.3-0.2
            l0.3-0.2l0.3,0.2l0.3,0.2l0.5,0.2l0.4,0.2l0.5,0.2l0.3,0.1h0.2l0.3,0l0.2-0.1L21,21l-0.1,0.2l-0.1,0.1l-0.3,0.1l0.1,0l0.1,0
            l0.1-0.1l0.1-0.1l0-0.1l0.1,0.4l0,0.2l0,0.1L20.9,22l-0.1,0.4l-0.1,0.2l-0.1,0.2l0,0.1l0,0.1V23l-0.1,0.1l0.2-0.1l0.1-0.1l0.2-0.3
            l0,0.3l0,0.1l-0.1,0.1l-0.1,0.3l-0.1,0.3l-0.1,0.5l-0.1,0.2l-0.1,0.3l-0.1,0.5l-0.1,0.4l-0.1,0.4L20,26.1l-0.1,0.2l-0.1,0
            l-0.1-0.1l-0.1-0.1L19.3,26l-0.2-0.1L19,25.7l0.1,0.2l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.2v0.1l-0.1,0.1l-0.3,0.3
            l-0.3,0.2l-0.3,0.1l-0.3,0.2l-0.1-0.2l-0.1-0.2l0-0.2l0.1-0.2l0.1-0.3l0.1-0.3v-0.3l0.1,0l0,0.1l0.1,0.1l0.1,0.1l0.1,0.2l0.1,0.1
            l0-0.3l-0.1-0.3l-0.2-0.5l0.2-0.1l0.2,0V25L18.9,25l-0.1-0.1l-0.1-0.1l0-0.1l0-0.1l0-0.1h0v-0.1l-0.1-0.1l-0.1-0.2l-0.1-0.2l0-0.1
            l0.1-0.1l0-0.1l0-0.2l0.1-0.3l-0.3-0.6l-0.1-0.4l-0.1-0.2L18,21.6l0.1,0.8v0.4L18,23.2v0.3L18,23.6v0.1l0,0.2l0,0.1v0.1L18,24.5
            l-0.1,0l-0.1-0.2l-0.1-0.1L17.6,24V24l0-0.1l-0.1,0l-0.1,0l0,0.1V24l0.1,0.1l0,0.1l0,0.1l0.1,0.1v0.2l0,0.2v0.1l0.1,0.1l0.1,0.6
            v0.3l-0.1,0.3l-0.1-0.2l0-0.2l-0.1-0.2l-0.1-0.1l0,0.4l0.1,0.4l0.1,0.4v0.2l-0.1,0.2l0-0.3l-0.1-0.3l-0.3-0.5l-0.3-0.5l-0.4-0.5
            l-0.1-0.1v-0.1l0.1-0.2l0.1-0.1l0-0.1l-0.1-0.1l-0.1-0.3v-0.1l0-0.1l0.1-0.2l0-0.1l-0.1-0.1l0-0.5l0.2-0.3h0h0l0-0.1v-0.1
            l-0.1-0.1v-0.1V22l0.1-0.1l0.1-0.1l0.1,0.1l0,0l0.1,0l-0.1-0.1l0-0.1v-0.1l0.1-0.2l0.1-0.1l0.1-0.1v-0.1h-0.1L17,21.1L17,21.2
            l0,0.1l0,0.3l-0.1,0.1l-0.1,0.1l0,0.3l0,0.3l-0.1,0.1l-0.1,0.1v0.5l-0.1,0.1l0,0.1v0.3l0.1,0.2v0.1l-0.1,0.1l-0.1,0.2v0.2l0.1,0.2
            l0.1,0.2l-0.5,0.8l-0.2,0.4l-0.1,0.4L15.7,25.7z M14.6,31.8l0.1-0.1l0-0.2v-0.2l0.1-0.1l0.2-0.3l0.3-0.2l0.1-0.1l0.1-0.1l0.1-0.7
            l0-0.8l0-0.7l0-0.7l0.1-0.4l0.1-0.4v-0.1l0-0.1l0.1-0.1v-0.1l0.3-0.4l0.1-0.2l0.2-0.1h0.1l0.1,0l0.2,0.1l0,0.7l-0.1,0.7l-0.1,0.7
            l-0.2,0.6h0l-0.2,0.6l-0.2,0.6L16,30.4l0,0.7l-0.1,0.2l-0.1,0.1l-0.2,0.2l-0.2,0.2L15.2,32l0,0.2l0,0.1h-0.1L15,32.1l0-0.1
            l0.1-0.3l0.1-0.3l0-0.1l0-0.1l0,0.1l-0.1,0l-0.1,0l0,0.1L15,31.4l-0.1,0.1l-0.1,0.2l-0.1,0.2L14.7,32l-0.1,0.1l0-0.1L14.6,31.8z
             M14.3,32.9l-0.1,0.1L14.1,33v0.1h0.1l0.1,0.1l0,0.2l-0.1,0.1l-0.2,0l-0.1,0l-0.1-0.1l0-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0,0l0.2-0.1
            L14.3,32.9L14.3,32.9z M16,36l-0.1-0.2l-0.2-0.4l-0.3-0.9l-0.3-0.9l-0.2-0.3L14.7,33l-0.2-0.3l-0.1-0.1l-0.2-0.1l0.4-0.2l0.3-0.3
            l0.1,0.2l0,0.1l0.1,0l0.1,0l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.2l0.1-0.1l0.1-0.1l0.1-0.2l0.3-0.1l0.2,0l0.2,0l0.4,0.2l0.3,0.3
            l0.3,0.4l0.2,0.4l0.7,1.5l-0.3,0.2l-0.3,0.2l-0.7,0.5L16.8,35l-0.3,0.1l-0.2,0.2l-0.1,0.2l0.1,0.2l0.2,0.1l0.1-0.1l0.1-0.1
            l0.1-0.1l0.1-0.1l0,0.3l-0.1,0.3l-0.1,0.1l-0.1,0l-0.3-0.1L16,36z M16.4,42.7L16.4,42.7l-0.1-0.1l-0.1,0H16l-0.1,0l0-0.2l0-0.1
            l0.1-0.1l0.1-0.6v-0.6l0-0.1l0.1-1.4l0.2-1.4l0.1,0l0.1,0h0.1l0.1,0.1l0.1,0l0,0.7l0,0.6l0,0.5v0.5v0.5l-0.1,0.5l0,0.7L16.4,42.7
            L16.4,42.7z M19.7,42.2l-0.1,0.2v0.2l0,0.5v0.2l-0.1,0.2l-0.1,0.1l-0.3,0.1H19h-0.2l-0.2,0l-0.1-0.1l-0.1-0.1l0-0.1l-0.1-0.1
            l-0.1,0l-0.5,0l-0.5,0.1l-0.5,0h-0.4l0.1-0.4l0.1-0.4l0.1-0.4l0-0.5v-1.4l0-0.7L17,39l0-0.5l0-0.2l0.1-0.2l0.3-0.1l0.3,0l0.7,0
            l0,1.2v0.6l0,0.6l0.1,0l0-0.1v-0.1h0l0,0v-0.1l0-0.6l0-0.6l0.1-1.1l0.3-0.1l0,0.8l0.1,0.4l0.1,0.2l0.1,0.2l0-0.1l0-0.4l0-0.4
            l0.1-0.8h0.1l0.2,2.2l0.1,1.1V42.2z M23.1,42.8l-0.3,0.1L22.6,43l-0.2,0.2l-0.4,0h-0.4l-0.2,0.1l-0.2,0.1l-0.1,0.1L21,43.7
            l-0.3,0.1l-0.3,0l-0.3,0l-0.3-0.1l0-0.4v-0.4l0.1-0.3v-0.2l0-0.2V40v-2.2l0.1-0.2l0.1-0.1l0.3-0.1h0.1l0.1,0.1l0.1,0.1l0.1,0.1
            l0.1,0.3v0.3L21,38.7l0.1,0l0.1-0.2l0.1-0.2l0.1-0.4l0.1-0.4l0.1-0.2l0.1-0.2V37l0-0.1l0.2-0.3l0.1,0l0.1,0l0.1,0.1l0,0.2l0,0.2
            v0.2l0,0.2l0.1,0.3l0,0.3l0,0.3l0.1,0.3l0.1-0.1l0-1.8l0.1-0.1l0.2,0.2l0,0.5l0,0.5h0l-0.1,0.2v0.2l0,0.4l0.1,1.2l0,0.6l0.1,0.6
            l0.1,0.2l0,0.1l0.1,0l0.1-0.2l0-0.2l0-0.5l-0.2-2l-0.1-1l0-1l0-0.1l0-0.1l0-0.1l0.1-0.1l0.1,0l0.1,1.5l0.2,1.4l0.1,1.9l0,1.9
            L23.1,42.8z M23.6,35l-0.1,0.1l-0.3,0.2l-0.1,0.1h-0.1l0-0.1l0.1-0.4l0.1-0.2l0.2-0.1l0.1,0.1l0,0.1l0.1,0l0.1,0L23.6,35z
             M23.9,33.8l-0.3-0.5l-0.1-0.3l-0.1-0.3l0.1,0l0.1,0h0l0.1-0.2l0.1-0.1l0.1,0l0.1,0.2l0,0.3v0.3L23.9,33.8z M23.9,31.2l-0.1,0.1
            l-0.2,0.2l-0.3,0.5l-0.1,0v-0.8l0.4-0.1h0.2l0.2,0L23.9,31.2z M24,30.7l-0.7-0.3l0-0.1l0,0l0.1-0.1l0.3,0.1l0.3,0.1l0,0.1V30.7z
             M24.4,24.4l0,0.7v0.6l-0.1,2.1l0,1v0.9l-0.1,0.1l-0.1,0h-0.3l-0.3,0.1L23.1,30L23,30.1L22.9,29l0.1-1.1l0.1-1.1l0.2-1.1h0l0,1.4
            l0.1,0.1l0.1-0.1l0.1-0.2v-0.2v-0.2h0v-0.9l0-0.9v-0.8l0-0.4L23.5,23l0-0.1l0,0l0.1,0l0.2,0.1l0.1,0.2v0.2v0.2v0.2v0.8l0,0.4
            l0.1,0.4l0.1-0.4l0-0.3v-0.4l0-0.4l0-0.7h0.3l0.1,0.3l0,0.3V24.4z"
                        ></path>
                        <polygon
                            points="28.2,20.2 28.2,20.3 28.2,20.5 28.3,20.5 28.3,20.6 28.2,20.7 28.2,20.7 28.2,21 28.2,21 28.3,20.9 28.3,20.8
            28.3,20.7 28.4,20.7 28.4,20.5 28.4,20.4 28.3,20.4 28.4,20.2 28.3,20.1 28.2,20 28.3,19.9 28.3,19.8 28.2,19.7 28.2,20.2       "
                        ></polygon>
                        <polygon
                            points="28,19.4 28,19.5 28.1,19.5 28.2,19.5 28.2,19.5 28.2,19.6 28.1,19.6 28,19.7 28,19.8 28.1,19.8 28.2,19.9
            28.2,19.9 28,20 28,20.2 28.2,20.1 28.2,20.2 28.2,19.7 28.2,19.7 28.2,19.7 28.2,19.3 28.1,19.3       "
                        ></polygon>
                        <polygon
                            points="27.2,20.3 27.2,20.4 27.2,20.5 27.3,20.5 27.2,20.6 27.1,20.6 27.1,20.7 27.2,20.6 27.3,20.6 27.3,20.6
            27.3,20.3 27.3,20.2 27.2,20.3       "
                        ></polygon>
                        <polygon points="28.1,20.3 28,20.4 28,20.5 28,20.5 28.2,20.5 28.2,20.3 28.2,20.3        "></polygon>
                        <polygon points="28.2,20.7 28.2,20.8 28.2,21 28.2,20.7 28.1,20.7        "></polygon>
                        <polygon points="27.1,19.8 27.3,19.6 27.3,19.6 27.3,19.4 27.2,19.5 27.1,19.7 27.1,19.9      "></polygon>
                        <polygon points="27.9,15.8 27.8,15.8 27.6,15.9 27.5,16 27.4,16.1 27.7,16 27.9,16 28.1,16 28.1,16 28,15.8        "></polygon>
                        <polygon points="27.6,16.1 27.6,16.2 27.7,16.2 27.8,16.2 27.7,16.1      "></polygon>
                        <polygon
                            points="20.6,17.1 20.7,17.1 20.8,17.1 20.9,17.2 21,17.2 21.1,17.2 21,17.1 20.8,17 20.6,17 20.5,17 20.3,17.1
            20.3,17.1 20.3,17.2 20.5,17.1       "
                        ></polygon>
                        <polygon
                            points="20.9,17.5 20.9,17.5 21,17.5 21,17.4 21,17.4 21,17.3 20.8,17.3 20.7,17.3 20.5,17.3 20.6,17.3 20.5,17.4
            20.5,17.4 20.6,17.5 20.7,17.5 20.7,17.6 20.8,17.6       "
                        ></polygon>
                        <polygon points="21.1,18.2 21.2,18.2 21.2,18.1 21.1,18.1        "></polygon>
                        <polygon
                            points="21,18.4 20.8,18.5 21.2,18.6 21.1,18.7 21.1,18.7 21,18.7 20.9,18.7 21,18.8 21,18.8 21.2,18.8 21.3,18.7
            21.3,18.6 21.4,18.5 21.2,18.5 21.1,18.4         "
                        ></polygon>
                        <polygon points="27.1,20.6 27.1,20.7 27.1,20.7      "></polygon>
                        <polygon points="19.3,21.1 19.4,21.2 19.4,21.3 19.5,21.3 19.3,21 19.2,20.9 19.1,20.8 19.2,21.1      "></polygon>
                        <polygon
                            points="28,21.2 28,42.7 27.8,42.8 27.6,42.8 27.4,42.8 27.3,42.8 27.3,42.7 27.3,21.8 27.2,21.9 27.1,22 27.1,42.7
            27.1,42.8 27.2,42.8 27.3,42.9 28,42.9 28.2,42.7 28.2,21 28,21.2         "
                        ></polygon>
                        <polygon points="27.3,21.8 27.3,21.6 27.2,21.7 27.1,21.8 27.1,22 27.2,21.9      "></polygon>
                        <polygon points="27.4,20.6 27.3,20.6 27.3,20.6      "></polygon>
                        <polygon points="28,21 28,21.2 28,21.2 28.2,21 28.2,21      "></polygon>
                        <polygon
                            points="27.3,20.8 27.4,20.8 27.4,20.9 27.4,20.9 27.5,20.9 27.7,20.9 28,20.7 28,20.6 27.8,20.7 27.7,20.8 27.6,20.9
            27.5,20.8 27.4,20.8 27.6,20.7 27.8,20.6 27.5,20.6 27.3,20.7         "
                        ></polygon>
                        <polygon points="28.1,20.6 28,20.6 28,20.7 28.1,20.8 28.2,20.8 28.2,20.7 28.1,20.7 28.1,20.7        "></polygon>
                        <polygon points="28.1,20.9 28,21 28.2,21 28.2,20.8      "></polygon>
                        <polygon
                            points="27.2,20.6 27.1,20.7 27.1,20.7 27.2,20.8 27.3,20.8 27.3,20.8 27.3,20.7 27.3,20.7 27.3,20.7 27.3,20.6
            27.3,20.6 27.3,20.6         "
                        ></polygon>
                        <polygon points="27.1,21.2 27.1,21.8 27.2,21.7 27.3,21.6 27.3,21.2      "></polygon>
                        <polygon points="27.6,20.9 27.7,20.9 27.5,20.9      "></polygon>
                        <polygon points="27.8,20.9 28,20.7 27.7,20.9        "></polygon>
                        <polygon points="27.4,20.9 27.4,20.8 27.3,20.8 27.3,20.9 27.4,20.9 27.5,20.9 27.4,20.9      "></polygon>
                        <polygon points="27.3,20.8 27.2,20.8 27.1,20.7 27.1,20.7 27.1,20.7 27.1,21.2 27.3,21.2 27.3,20.9 27.3,20.8      "></polygon>
                        <polygon points="28.2,20.8 28.1,20.8 28,20.7 28,21 28.1,20.9        "></polygon>
                        <polygon
                            className="st10"
                            points="37.8,21.3 37.7,21.3 37.8,21.3       "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="36.3,29.4 36.1,29.4 36,29.3 35.8,29.1 35.5,28.9 35.4,28.8 35.2,28.8 35.4,29.1 35.5,29.2
            35.6,29.4 36,29.5 36.2,29.6 36.4,29.7 36.9,29.8 36.9,29.6       "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="32.7,34.8 32.8,34.7 32.8,34.6       "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="45.8,37.2 45.8,37.2 45.8,37.2       "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="40.2,37.8 40.1,37.8 40.2,37.8       "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="38.5,38 38.5,38 38.4,38.1 38.1,38.3 38,38.4 38,38.5 38,38.4 38.1,38.4 38.3,38.4 38.3,38.2
            38.4,38.2 38.5,38.2 38.5,38.1 38.6,38.1 38.6,38         "
                        ></polygon>
                        <path d="M38.2,38.6L38.2,38.6L38.2,38.6L38.2,38.6z M38.3,38.4L38.3,38.4l-0.1,0.2L38.3,38.4L38.3,38.4l0.1-0.1L38.3,38.4z"></path>
                        <polygon
                            className="st10"
                            points="37.2,38.4 37.2,38.4 37.2,38.4 37.2,38.4         "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="39.5,38.5 39.5,38.6 39.6,38.6       "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="41.7,38.7 41.7,38.7 41.7,38.8       "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="38.1,38.9 38,38.9 38,39 38.1,39 38.1,38.9       "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="36.2,39 36.1,39.1 36.2,39.2 36.2,39.1 36.2,39       "
                        ></polygon>
                        <rect
                            x="41.2"
                            y="39.6"
                            className="st10"
                            width="0"
                            height="0.1"
                        ></rect>
                        <polygon
                            className="st10"
                            points="44.4,39.9 44.4,39.9 44.4,39.9 44.5,39.9 44.5,39.9       "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="43.3,40 43.4,40 43.4,40 43.6,40 43.6,40         "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="37.3,40.5 37.4,40.5 37.4,40.5       "
                        ></polygon>
                        <rect
                            x="36.2"
                            y="40.5"
                            className="st10"
                            width="0"
                            height="0.1"
                        ></rect>
                        <polygon
                            className="st10"
                            points="36.8,41 36.8,41 36.9,40.9       "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="37.2,41.1 37.2,41.2 37.3,41.1 37.3,41.1         "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="36.4,41.3 36.4,41.3 36.4,41.3       "
                        ></polygon>
                        <rect
                            x="37.7"
                            y="41.4"
                            className="st10"
                            width="0.1"
                            height="0"
                        ></rect>
                        <polygon
                            className="st10"
                            points="36.6,41.7 36.6,41.7 36.5,41.6 36.4,41.5 36.3,41.5 36.1,41.4 35.8,41.5 35.7,41.6 35.6,41.7
            35.6,41.9 35.5,42 35.6,42.4 35.7,42.5 35.8,42.6 35.9,42.5 36.1,42.4 36.2,42.3 36.4,42.3 36.5,42.2 36.6,41.9 36.6,41.8       "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="42.6,41.3 42.6,41.4 42.6,41.4 42.7,41.4 42.8,41.4 42.8,41.3         "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="37.1,41.5 37,41.5 37.1,41.5 37.1,41.4       "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="32.7,41.7 32.5,41.6 32.3,41.5 32.1,41.6 31.8,41.7 31.9,41.8 31.9,41.8 32.2,41.7 32.4,41.8
            32.9,41.9       "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="42.6,41.5 42.6,41.6 42.6,41.5 42.7,41.5         "
                        ></polygon>
                        <rect
                            x="38.4"
                            y="42.4"
                            className="st10"
                            width="0"
                            height="0"
                        ></rect>
                        <polygon
                            className="st10"
                            points="35.6,42.6 35.5,42.6 35.6,42.7       "
                        ></polygon>
                        <rect
                            x="35.7"
                            y="42.7"
                            className="st10"
                            width="0"
                            height="0"
                        ></rect>
                        <polygon
                            className="st10"
                            points="35.9,42.8 35.8,42.9 36,42.8         "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="39.8,43.6 39.8,43.6 39.8,43.7 39.8,43.7 39.9,43.7 39.9,43.6 39.9,43.6       "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="39.2,43.6 39.2,43.7 39.3,43.7 39.3,43.6         "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="37.5,43.8 37.4,43.8 37.4,43.9 37.4,44 37.4,44 37.4,44       "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="34.7,44 34.7,44.1 34.8,44.1         "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="39.4,44.3 39.4,44.3 39.5,44.3       "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="38.4,44.6 38.4,44.7 38.5,44.7 38.5,44.6 38.5,44.6       "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="32.8,45.5 32.8,45.5 32.8,45.5       "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="35.4,45.6 35.3,45.6 35.4,45.6 35.4,45.6         "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="37.4,45.6 37.4,45.7 37.4,45.7 37.4,45.6         "
                        ></polygon>
                        <polygon
                            className="st10"
                            points="34.4,45.9 34.4,46 34.5,45.9         "
                        ></polygon>
                        <rect
                            x="36.5"
                            y="45.9"
                            className="st10"
                            width="0"
                            height="0.1"
                        ></rect>
                        <g>
                            <polygon
                                className="st11"
                                points="46.7,23.6 46.7,23.7 46.9,23.6 46.7,23.5             "
                            ></polygon>
                            <polygon
                                className="st11"
                                points="46.2,23.4 46.8,23.4 46.9,23 46.9,22.8 47,22.7 46.3,22.7 46.1,22.7 46.1,22.8 46,23.1 46.1,23.3
                            "
                            ></polygon>
                            <polygon
                                className="st11"
                                points="46.2,23.5 46.2,23.6 46.3,23.6 46.3,23.7 46.5,23.7 46.5,23.6 46.5,23.6 46.5,23.5 46.5,23.5
                46.3,23.5           "
                            ></polygon>
                            <polygon
                                className="st11"
                                points="46.2,22.5 46.2,22.6 46.4,22.6 46.5,22.6 46.5,22.4 46.3,22.4 46.2,22.4           "
                            ></polygon>
                            <polygon
                                className="st11"
                                points="43.6,24.4 43.6,24.4 43.5,24.4 43.5,24.4 43.8,24.4 43.7,24.4             "
                            ></polygon>
                            <polygon
                                className="st11"
                                points="45,22.5 45,22.6 45.3,22.6 45.3,22.6 45.3,22.5 45.3,22.4 45.3,22.4 45.1,22.4             "
                            ></polygon>
                            <polygon
                                className="st11"
                                points="45.3,21.7 45.3,21.7 45.3,21.6 45.3,21.6 45.3,21.6 45.1,21.6 45.1,21.7 45.1,21.7 45.1,21.7
                "
                            ></polygon>
                            <polygon
                                className="st11"
                                points="46.8,22.4 46.7,22.4 46.7,22.5 46.8,22.6 46.9,22.6 46.9,22.5 46.9,22.4           "
                            ></polygon>
                            <polygon
                                className="st11"
                                points="45.3,22.3 45.5,22.3 45.6,22.3 45.6,22.2 45.7,22.1 45.7,21.9 45.7,21.8 45.1,21.8 44.9,22
                44.9,22.2 44.9,22.3 45.1,22.3           "
                            ></polygon>
                            <polygon
                                className="st11"
                                points="47.4,22.4 47.3,22.5 47.4,22.6 47.6,22.6 47.7,22.5 47.7,22.4 47.6,22.4 47.5,22.4             "
                            ></polygon>
                            <polygon
                                className="st11"
                                points="46.8,22.3 46.9,22 46.9,21.8 46.5,21.8 46.3,21.8 46.2,21.8 46.1,21.9 46.1,22.1 46.1,22.2
                46.2,22.2           "
                            ></polygon>
                            <polygon
                                className="st11"
                                points="47.3,22.2 47.7,22.2 47.8,22.3 47.9,22.2 48,22.2 48,22.1 48.1,21.9 48.1,21.8 47.4,21.8
                47.3,21.9 47.3,22 47.4,22.2             "
                            ></polygon>
                            <polygon
                                className="st11"
                                points="47.9,23.4 47.8,23.4 47.9,23.6 48.2,23.6 48.1,23.5           "
                            ></polygon>
                            <polygon
                                className="st11"
                                points="47.8,22.5 48,22.5 48,22.5 47.9,22.4 47.8,22.4           "
                            ></polygon>
                            <polygon
                                className="st11"
                                points="47.3,23.1 47.3,23.3 47.4,23.4 47.5,23.4 47.6,23.3 47.8,23.3 47.9,23.3 48,23.2 48.1,23.1
                48.2,22.9 48.2,22.8 48.2,22.6 47.9,22.7 47.6,22.7 47.4,22.7 47.3,22.8 47.3,22.9             "
                            ></polygon>
                            <polygon
                                className="st11"
                                points="46.7,21.6 46.7,21.6 46.7,21.5 46.7,21.7 46.7,21.7           "
                            ></polygon>
                            <polygon
                                className="st11"
                                points="47.5,23.5 47.4,23.6 47.7,23.6 47.7,23.4 47.6,23.4           "
                            ></polygon>
                            <polygon
                                className="st11"
                                points="45.6,22.5 45.5,22.5 45.5,22.6 45.7,22.6 45.6,22.5           "
                            ></polygon>
                            <polygon
                                className="st11"
                                points="46.8,24.5 46.7,24.6 46.7,24.7 46.9,24.7 47,24.7 46.9,24.6           "
                            ></polygon>
                            <polygon
                                className="st11"
                                points="47.2,24 47.2,23.9 47.3,23.8 47.3,23.7 47.3,23.7 47.2,23.4 47.2,23.2 47.2,22.9 47.3,22.7
                47.1,22.7 47,22.9 47,23.1 47,23.4 47.1,23.6 47.1,23.6 47.2,23.7 47.2,23.8 47.1,23.8 47.1,23.9 47.1,24.2 47.1,24.4 47.1,24.5
                47.2,24.6 47.2,24.5 47.2,24.4 47.2,24.2             "
                            ></polygon>
                            <polygon
                                className="st11"
                                points="46.1,23.8 46,23.8 45.9,23.9 45.9,24.1 45.9,24.2 46,24.4 46.2,24.4 46.4,24.4 46.5,24.4
                46.7,24.4 46.9,24.3 46.9,24.2 47,24 47,23.8 46.2,23.7           "
                            ></polygon>
                            <polygon
                                className="st11"
                                points="46.2,24.6 46.1,24.6 46.3,24.7 46.5,24.7 46.5,24.5 46.3,24.5             "
                            ></polygon>
                            <polygon
                                className="st11"
                                points="47.5,24.6 47.5,24.7 47.6,24.7 47.7,24.7 47.7,24.5 47.6,24.5             "
                            ></polygon>
                            <polygon
                                className="st11"
                                points="44.2,23.1 44.5,22.6 44.8,22 44.4,22.6 43.9,23.2 43.6,23.8 43.3,24.3 43.6,24.3 43.8,24.2
                44,23.7             "
                            ></polygon>
                            <polygon
                                className="st11"
                                points="45.8,23.8 45.7,23.9 45.7,24.1 45.7,24.3 45.7,24.4 45.8,24.4 45.8,24.2 45.8,23.9 45.9,23.8
                45.9,23.7           "
                            ></polygon>
                            <polygon
                                className="st11"
                                points="47.9,24.5 47.8,24.6 47.8,24.6 47.9,24.7 48,24.7 48.3,24.7 48.1,24.5             "
                            ></polygon>
                            <polygon
                                className="st11"
                                points="45,24.4 44.7,24.4 44.6,24.5 44.5,24.6 44.9,24.6 45,24.6 45.2,24.6 45.2,24.4 45.1,24.4           "
                            ></polygon>
                            <polygon
                                className="st11"
                                points="45.4,24.5 45.4,24.6 45.5,24.6 45.5,24.6 45.5,24.6           "
                            ></polygon>
                            <polygon
                                className="st11"
                                points="44.6,23.6 44.5,23.4 44.5,23.2 44.6,23 44.6,22.8 44.8,22.6 44.7,22.3 44.2,23.4 44,23.9
                43.9,24.5 44.1,24.6 44.2,24.6 44.2,24.4 44.2,24.2 44.2,24 44.4,23.9             "
                            ></polygon>
                            <polygon
                                className="st11"
                                points="45.6,23.6 45.6,23.6 45.5,23.5 45.4,23.5 45.4,23.6 45.4,23.6 45.5,23.6           "
                            ></polygon>
                            <polygon
                                className="st11"
                                points="44.6,23.7 44.4,23.9 44.4,24.1 44.4,24.2 44.4,24.3 44.4,24.4 44.5,24.4 44.7,24.3 45.1,24.3
                45.3,24.3 45.5,24.4 45.5,24.2 45.5,24 45.6,23.8 45.1,23.7           "
                            ></polygon>
                            <polygon
                                className="st11"
                                points="45.3,22.7 45,22.6 44.8,22.7 44.8,22.7 44.7,22.8 44.6,22.9 44.6,23 44.6,23.2 44.8,23.3
                45.1,23.3 45.3,23.3 45.5,23.4 45.5,23.2 45.6,23.1 45.6,23 45.7,22.7             "
                            ></polygon>
                            <polygon
                                className="st11"
                                points="45.3,23.5 45.2,23.4 45.1,23.4 44.9,23.4 44.8,23.5 44.8,23.6 44.8,23.6 45.3,23.6             "
                            ></polygon>
                            <path
                                d="M48.8,24.7h-0.1l-0.1-0.1l-0.1-0.2l0-0.2l0-0.2l0.1-0.1l0.1-0.1l0.1,0v0l-0.1-0.1l-0.1-0.1l-0.1-0.3l0-0.2l0-0.1l0.1-0.3
                l-0.2-0.1l-0.1-0.2v-0.2l0.1-0.2l0.1-0.1l-0.2-0.1l-0.2,0l-0.1-0.1l-0.1-0.1l-0.1,0v0.3l-0.1,0.1l-0.1,0l-0.1,0l-0.1,0.2L47.2,22
                l-0.1,0.4L47,22.4L47,22.2V22l0-0.1l0.1-0.1l-0.1,0l-0.1,0l-0.1-0.2l-0.1-0.2l-0.1-0.1l-0.1,0.1v0.1l0,0.2l-0.1,0.1l-0.1,0h-0.3
                l-0.1,0.2L46,22.2l0,0.2l0.1,0.2L46,22.8L46,23l0,0.2l0.1,0.2v0.1l0.1,0.1l0,0L46,23.7l-0.1-0.1l-0.1-0.1l-0.1-0.2l0-0.2l0.1-0.2
                l0.1-0.1l0-0.1v-0.1l-0.1-0.1l-0.1-0.2v-0.1l0.1-0.1l0.1-0.1l0-0.1v-0.1l-0.2,0l-0.2-0.1v-0.1l0,0l0.1,0.1h0.1l0-0.1l0-0.1
                l-0.1-0.1L45,21.7L44.7,22L44,22.8l-0.5,0.8l-0.4,0.6l0.1,0.3l0.5,0.1l0.6,0.2l0.6,0.3l0.1,0.1l0.2,0.1l0.3,0l0.4,0l0.3,0.1
                l1.1,0l0.5,0l0.5,0l0.2-0.1l0.1-0.1l0.2-0.3L48.8,24.7z M48.2,23.6h-0.4l0-0.2l0.1,0l0.1,0.1L48.2,23.6z M47.3,21.9l0.1-0.1h0.7
                l-0.1,0.2L48,22.1l0,0.1l-0.1,0.1l-0.1,0l-0.1-0.1l-0.4,0h0L47.3,22L47.3,21.9z M48,22.5L48,22.5l-0.2,0v-0.1L48,22.5L48,22.5z
                 M47.7,22.4L47.7,22.4l-0.1,0.2l-0.3,0l0-0.1l0-0.1l0.1-0.1L47.7,22.4L47.7,22.4z M47.3,22.8l0.1-0.1l0.1,0h0.3l0.3,0v0.1
                l-0.1,0.1l-0.1,0.2L48,23.2l-0.1,0.1l-0.1,0h-0.1l-0.2,0.1l-0.1,0l-0.1-0.1l-0.1-0.2v-0.2L47.3,22.8z M47.7,23.4v0.2h-0.3l0-0.1
                L47.7,23.4L47.7,23.4z M46.7,21.5L46.7,21.5l0.1,0.1v0h-0.1V21.5z M46.1,22.1l0-0.1l0.1-0.1l0.1,0l0.2,0h0.4l0,0.2l-0.1,0.3
                l-0.7,0L46.1,22.1L46.1,22.1z M46.9,22.5L46.9,22.5l-0.1,0.1l-0.1,0v-0.1L46.9,22.5L46.9,22.5L46.9,22.5z M46.5,22.4v0.2l-0.2,0
                l-0.2,0v-0.1l0-0.1l0.1-0.1H46.5z M46,23.1l0.1-0.3l0.1-0.1l0.1-0.1H47l-0.1,0.1L46.9,23l-0.1,0.3l-0.6,0l-0.1-0.1L46,23.1z
                 M46.9,23.6l-0.2,0.1l0-0.1l0-0.1L46.9,23.6z M46.5,23.5L46.5,23.5v0.1l-0.1,0.1h-0.2l-0.1,0l-0.1,0l0-0.1l0.1-0.1L46.5,23.5
                L46.5,23.5z M45.1,21.7L45.1,21.7l0.1-0.1h0.1l0,0.1l0,0.1l-0.1,0L45.1,21.7L45.1,21.7L45.1,21.7z M44.9,22.2l0.1-0.1l0.1-0.2
                h0.7v0.1l-0.1,0.1l-0.1,0.1v0.1h-0.2h-0.2l-0.2,0h-0.2V22.2z M45.7,22.6h-0.2l0-0.1L45.7,22.6L45.7,22.6L45.7,22.6z M45.3,22.4
                L45.3,22.4v0.1l-0.1,0H45l0.1-0.1l0.1-0.1H45.3L45.3,22.4z M43.5,24.4L43.5,24.4L43.5,24.4l0.1-0.1h0.1l0,0.1H43.5z M43.6,24.3
                l-0.3,0l0.3-0.6l0.4-0.6l0.4-0.6l0.5-0.5l-0.3,0.5l-0.3,0.6L44,23.7l-0.2,0.5L43.6,24.3z M44.2,24l0,0.2v0.2l0,0.2h-0.2l-0.2-0.1
                l0.1-0.6l0.2-0.6l0.5-1.1l0.1,0.3l-0.1,0.2L44.6,23l-0.1,0.2l0,0.2l0.1,0.2l-0.3,0.3L44.2,24z M45.2,24.6l-0.2,0l-0.1,0l-0.3-0.1
                l0.1-0.1l0.1-0.1l0.3,0h0.1l0.1,0.1L45.2,24.6z M45.5,24.6h-0.1v-0.1L45.5,24.6L45.5,24.6L45.5,24.6z M45.5,24l-0.1,0.1v0.2
                l-0.2-0.1h-0.2l-0.4,0l-0.1,0h-0.1l-0.1,0l0-0.1v-0.1l0.1-0.2l0.1-0.1h0.5l0.5,0L45.5,24z M44.8,23.6L44.8,23.6l0.1-0.1l0.2,0
                l0.1,0l0,0.1v0.1L44.8,23.6L44.8,23.6z M45.4,23.6L45.4,23.6l0.2,0l0,0l0,0.1l-0.1,0L45.4,23.6L45.4,23.6z M45.6,23.1l-0.1,0.1
                l0.1,0.2l-0.2-0.1h-0.2l-0.4,0l-0.1-0.2l0-0.1v-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.2,0l0.2,0l0.5,0L45.6,23L45.6,23.1z M45.8,23.9
                l0,0.3v0.3l-0.1-0.1l-0.1-0.1v-0.2l0-0.2L45.8,23.9l0.1-0.1L45.8,23.9L45.8,23.9z M46.5,24.7l-0.2,0l-0.2-0.1l0.1-0.1l0.1,0h0.3
                V24.7z M46.9,24.7h-0.2l0-0.1l0.1,0l0.1,0.1l0.1,0.1H46.9z M47,24l-0.1,0.1v0.2l-0.3,0.1l-0.1,0l-0.1-0.1h-0.2H46l-0.1-0.1l0-0.1
                l0-0.1l0.1-0.1l0.1,0l0.1,0l0.8,0L47,24z M47.2,24.5v0.1V24.5l-0.1-0.1l0-0.2l0-0.3l0.1-0.1l0.1-0.1l0-0.1l-0.1-0.1l-0.1-0.1
                L47,23.4l0-0.2v-0.2l0.1-0.2l0.2-0.1l-0.1,0.2l0,0.3l0,0.3l0.1,0.2l0,0.1l0,0l-0.1,0.1L47.2,24v0.2v0.2L47.2,24.5z M47.7,24.7
                h-0.1l-0.1,0v-0.1l0.1-0.1l0.1,0V24.7z M48,24.7l-0.1,0l-0.1-0.1v-0.1l0.1-0.1l0.2,0l0.2,0.1L48,24.7z M48.3,24.3L48.3,24.3
                l-0.5,0.1l-0.5-0.1l0-0.1v-0.1l0.1-0.2l0.1-0.1h0.9L48.3,24.3L48.3,24.3z"
                            ></path>
                        </g>
                        <path
                            d="M49.8,30L49.8,30l-0.1-0.1l-0.2-0.2l-0.2-0.1L49,29.4l-0.3-0.1l-0.6-0.1h-0.6l-0.3-0.1L47,29.1l-0.2-0.2l-0.2-0.2L46,28.1
            l-0.5-0.7l-0.6-0.7l-0.3-0.3l-0.3-0.3L44.2,26v-0.1l-0.1-0.2L44,25.5l-0.1-0.2l-0.2-0.2l-0.2-0.2l-0.2-0.3l0-0.1l-0.2,0l0.1,0.2
            l0.2,0.3l0.2,0.3l-0.3,0.1L43,25.4l-0.4,0.3L42.4,26l0,0.2l0,0.2h-0.1l-0.2-0.3l-0.3-0.3v-0.1l-0.1-0.1l-0.1-0.1l0-0.1l0.1-0.1
            l0-0.1l-0.1-0.1l-0.2,0.3l-0.1,0.1l-0.1,0l-0.1,0.3l0.1,0.1l0.1,0.1v0.3l0,0.3l-0.1,0.3L41.2,27l-0.1,0.1l-0.3,0.3l-0.3,0.2
            l-0.1,0.1l-0.1,0.1L40.3,28l0.1,0.1h0.1l0.1-0.1l0.1,0l0.1-0.1l0.1-0.1l0.1,0L41,28.1l-0.1,0.1l-0.2,0.2l-0.2,0.2l-0.1,0.1
            L40.3,29h0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1h0.1l0.1-0.1h0.2l-0.1,0.3l-0.1,0.3l0.1,0h0.1l0.1-0.1l0.1,0l0,0.1
            l-0.1,0.1l-0.2,0.2h-0.5l-0.6-0.1l-0.4-0.1l-0.2,0h-0.2l-0.1,0.1l-0.1,0l-0.1,0l-0.1,0h-0.4l-0.3,0h-0.3l-0.1,0.1l-0.2,0.1
            l-0.2,0.1l-0.2,0l-0.1-0.1l-0.1-0.2l0-0.3l0.1-0.3l0-0.3l0-0.2l0-0.2L37,27.6l-0.1-0.2L36.6,27l-0.1-0.2l0-0.2l0-0.2l0.1-0.2
            l0.2-0.3l0.2-0.4l0.1-0.3V25L37,24.9v0.2l-0.1,0.2l-0.2,0.4L36.5,26l-0.2,0.4l-0.2-0.1l-0.2-0.1L36,26l0.1-0.3l0.2-0.3h0l0.1,0h0
            l0-0.1l0-0.3l0.1-0.3l0.1-0.3l0.1-0.3l0.2-0.3l0.3-0.2l0.6-0.3l0.1,0.1l0,0.2l0,0.4l0.1,0.4l0.2,0.3l0.3,0.3l0.3,0.3l0.1,0.2
            l0.1,0l0.1,0l0.3-0.3l0.3-0.3l0.5-0.7l0.5-0.6l0.3-0.3l0.3-0.3l0.3,0.1l0.6,0.3l0.3,0.2l0.2,0.3l0.2,0.4l0.1,0.2l0.1-0.1L43,24.1
            l-0.3-0.5l-0.2-0.2l-0.3-0.2L42,23l-0.3-0.1l-0.2-0.1l-0.1-0.1l-0.1-0.1l-0.1,0h-0.1L41,22.6l-0.1-0.1l-0.1-0.1l-0.1-0.2V22
            l-0.1-0.1l0-0.1l0-0.1l0-0.2l-0.1-0.2l0.1-0.2l0.1-0.1l0.1-0.1l0.1-0.2l0.1-0.1l0.1-0.1l0.1-0.2l0-0.4l-0.1-0.4L41,19.1l-0.3-0.3
            l-0.2-0.1l-0.2,0l-0.2-0.1L40,18.5l-0.6-0.2l-0.3,0h-0.3l-0.8,0.3l-0.4,0.2l-0.4,0.2L37,19.2L37,19.3l0,0.2l0.1,0.3v0.3l0,0.5
            l0.1,0.1l-0.1,0.1L37,20.9v0.3l0.2,0.7l0.1,0.3l0.1,0.1l0.1,0.1l0.2,0.1H38h0.2l0.2-0.1l0.1,0.2l0.1,0.2h-0.1l-0.1-0.1h-0.1
            L38.1,23L38,23l-0.2,0.2l-0.1,0.1l-0.1,0l-0.3,0.1L37,23.6l-0.3,0.3l-0.1,0.1l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2v0.3l-0.1,0
            l-0.1,0.2L36,25.6l-0.2,0.5v0.1l0,0.1l-0.1,0.1l-0.2,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1v0.2v0.2l-0.1,0.2l-0.5-0.1l-0.5,0.1
            h-0.5l-1,0l-0.1-0.1l-0.1,0L32.1,27L32,26.9l-0.3-0.1h-0.2l-0.1-0.1l-0.2-0.2l-0.2-0.2l-0.1-0.1l-0.1-0.1l-0.3,0l0.2-0.6l0.2-0.5
            l0.1-0.1V25l0-0.1l0-0.1l0,0l0.1,0.1l0.1-0.1l-0.1,0l0-0.1v-0.1l0.1,0.1l0.1,0l0-0.1l-0.1-0.1l0,0H31l0,0.1l0.1-0.1l0,0L31,24.5h0
            l0.1,0.1l0.1,0l0.1-0.1l-0.1,0l-0.1,0l0-0.1l0,0l0,0l0.1,0l0-0.1l-0.1-0.1l0,0l0,0h0h0.1l0-0.1l0-0.1L31.2,24h0l0.1,0.1l0.1,0
            l0.1-0.1h-0.1l-0.1,0l0,0h0.1h0l0,0l-0.2,0l0-0.1h0.1l0.1,0l0-0.1l-0.1-0.1l0.1-0.1l0.1-0.1h-0.1l-0.1,0l0.2-0.2l-0.1,0l0,0
            l-0.1,0l0.1-0.1l0.1-0.1l-0.1,0v-0.1V23v0l-0.1,0l-0.1,0l-0.1,0.1h0h0l0.1,0.1v0.1l-0.1-0.1v0.1l0.1,0.1l0.1,0.1l-0.1,0.1l-0.1,0
            h-0.1l0.1,0.1v0.1l-0.1,0.1l-0.1-0.1L31,23.7l0,0.1l0.1,0h0.1v0.1l0,0.1l0,0L31,23.9l0,0l0,0.1l0,0.1l0.1,0.1l-0.1,0.1l0-0.1h0
            l0.1,0.2l0,0l0,0l-0.1,0v0.1l0,0.1v0l0,0.1l-0.1-0.1l0,0.1l0,0.1l0.1,0.1l0,0.1l-0.1-0.1v0h-0.1l0,0.1l0,0l0,0v0.1l0,0l0,0l0,0
            l0,0.1l0,0.1L30.3,26l0-1.3l0.1-0.2l-0.1,0h0l-0.1,0l0.1-0.1l0-0.1l0-0.1l-0.1,0.1h-0.1v-0.1l0.1-0.1l0.1,0l0.1-0.2l0-0.2
            l-0.1,0.1V24l0.1-0.1l0.1-0.1l0-0.1l-0.1,0.1h-0.1l0,0l0.2-0.1v-0.1l0-0.1l-0.1,0.1l-0.1,0l-0.1-0.1l0-0.1l0.1-0.1l0.1-0.1
            l-0.1,0.1l0,0.1l-0.1,0l0,0l0.1-0.1l0.1-0.1l0-0.1l-0.1,0.1l-0.1-0.1l0.1-0.1l0-0.1V23l-0.1,0l-0.1,0l0-0.1l0.2,0l0-0.1v-0.1
            l-0.1,0.1h-0.1l0.1-0.1l0.1,0l-0.1-0.1l-0.1,0.1L30,22.7l0.1,0.1l0.1,0v0l-0.1,0l0,0.1l0.1,0.1l0.1,0l0.1,0.1h0H30L30,23l0.1,0.1
            l0,0l0,0.1l-0.1,0v0l0-0.1l-0.1,0l0,0.2l0,0.1l0.1,0v0.1l0,0L30,23.3l0,0.1l0.1,0.1l0,0l0,0l-0.1,0l0.1,0.2l0.1,0.1h0H30l-0.1,0
            V24l0,0.1l0.1,0.1l-0.1,0l0.1,0.1l0,0.1v0.1L30,24.3l0.1,0.1l0.1,0.1l0,0H30v0l0,0l0.1,0.7v0.7l0,0.1l-0.3-1.3v-0.1v-0.1l0,0.1
            h-0.1l0-0.1l0,0l0,0l0.1-0.1v-0.1l0-0.1l-0.1,0.1l-0.1-0.1l0.1-0.1l0-0.1l0-0.1l-0.1,0.1v0l0-0.1l0.1-0.1v-0.1L29.4,24V24l0,0v0.1
            l0,0l0-0.1V24h0l0-0.1l-0.1-0.1v0.1l-0.1,0.1l-0.1-0.1l0-0.1l0-0.1l-0.1-0.1v0.1l0,0l0,0l0-0.1l0-0.1l0-0.1l0-0.1l-0.1,0.1
            l-0.1-0.1l0,0l0.1-0.1l0,0l-0.1,0l0,0.1l-0.1,0l0.1-0.1l0-0.1L29,23.1l-0.1,0l0,0l-0.1,0l0,0l0-0.1V23l0,0h0l-0.1,0l-0.1-0.1v-0.1
            h-0.1l0,0.1l0,0.1h-0.1l-0.1-0.1l0,0.1l0.1,0.1l0.1,0l0.1,0.1l-0.1,0l0,0.1l0.1,0l0.1,0l0.1,0.1l-0.1,0l0,0.1l0.1,0l0.1,0l0,0l0,0
            l0,0h-0.1l0.1,0.1l0.1,0.1v0.1l0,0l-0.1,0l0.1,0l0.1,0l0.1,0.1V24l-0.1,0V24h0.1l0.1,0l0.1,0.1l0,0.1l-0.1,0h0h-0.1v0.1h0.1h0.1
            l0.1,0l0,0.1l0,0.1h-0.1l0,0l0.1,0h0.1l0,0v0.1l0,0l-0.1,0l0.1,0h0l0,0.1l-0.2,0l0.1,0.1l0.1,0l0.1,0l-0.1,0h-0.1l0.1,0.1l0.1,0.1
            l0.1,0.6l0.1,0.6h-0.2l-0.1,0l0,0.1l-0.1,0.1l-0.1,0l-0.1,0l-0.1,0.1l0,0.1l0.1,0.1l-0.1,0.2l0.1,0.2l0.1,0.1l0,0.2l0.1,0.1
            l0.1,0.1l0,0.1l0.1,0.1l0.2,0.1l-0.2,0.9l-0.2,0.9l0.2,0.1l0.2-0.6l0.1-0.6l0.1-0.1l0,0.8v0.9l0.1,0l0.1,0l-0.1-1.7l0.3,1.2
            l0.2-0.1l-0.2-0.9l-0.1-0.5l-0.1-0.5l0.3,0.1l0.3,0l0.4-0.1l1.5,0.4l1.5,0.4l0.4,0l0.4,0.1h0.4l0.4,0l0.2,0.2l0.1,0.2l0.4,0.1h0.1
            l0.1,0.1l0.1,0.3l0.1,0.1l0.1,0.1v0.1l0,0.1l-0.1,0.2l0,0.1v0.1l-0.2,0.2l-0.2,0.1L36.2,31l-0.5,0.1l-0.5,0.2l-0.5,0.2l-0.5,0.1
            l-0.1,0.1l0,0h-0.1L33.7,32l-0.5,0.2l-0.5,0.2l-0.5,0.2L32,32.7l-0.3,0.2l-0.3,0.2l-0.1,0.1l-0.1,0.1l0,0.4L31,34.1l0,0.3l0.1,0.3
            l0,0.7l0.1,0.7l0.1,0.9l0.1,0.4l0.1,0.4l0.3,1.2l0.2,1.2l-0.3,0.7l-0.1,0.3l-0.1,0.3L31,42.2l-0.1,0.3l-0.1,0.2l-0.1,0.1L30.5,43
            l-0.1,0.1l-0.2,0.3l0,0.1l0.1,0.1l0.1,0.1l0.6,0h0.3l0.3-0.1l0.2,0l0.2-0.1l0.4-0.2l0.4-0.2l0.4-0.1l0.4-0.1l0.4-0.1l0.3,0h0.3
            l0.7-0.1l0.2,0l0.1,0l0.1,0.1L35.4,43L35.3,43l-0.2,0.1L35,43.2l-0.2,0.1l-0.1,0.1l-1.1,0.3l-0.2,0.1l-0.2,0l-0.2,0.1L32.7,44
            l0.1,0.1l-0.2,0.1l-0.2,0l-0.1,0.1l-0.1,0l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l-0.2,0.1l-0.2,0.1h0.1h0l0.1,0.1l0,0.1l0.1,0h0.1
            l0.2,0l0.2,0.1l-0.1,0.1l-0.1,0.1l-0.1,0l-0.1,0.1v0.1l0.1,0.1v0.1l0.2,0.2l0.1,0.1l0.1,0l0.1,0.1l0,0.1l0,0.1l0,0.1l0.3-0.1
            l0.3-0.1l0.2,0.1l0.2,0.1l-0.3,0.1l-0.4,0.1v0l0,0l0.1-0.1l1.3,0h-0.1l-0.1,0l0-0.1v0.1l-0.1,0h-0.1l-0.2,0l0.2-0.1l0.2,0l0.2,0
            l0.1,0l0.1-0.1l0.1,0l0.3-0.1h0.1h0.1l0,0.1L35.8,46h-0.1l0.1,0l0.1-0.1l0.1,0.1h0.1h0.1l0.1,0l-0.1,0.1l0.1,0l0.1,0h0.1l0.1,0
            l0,0.1l0.2,0l0.1,0l0.1-0.1h-0.2l-0.1,0l-0.1-0.1l0.2,0V46l0-0.1l0.1,0l0,0.1l0-0.1l0.1,0h-0.1l-0.1-0.1l-0.1-0.1l-0.1,0h0
            l-0.1-0.1l0.1,0l0.1,0h0.1l0.1,0l0.1,0l0.1,0.1l0.1,0.1h0.1l0.1,0l0,0l0,0.1l0.2,0.1l0.3,0l0.3,0l0.2-0.1l0.1,0l0.1,0l0.2,0.1
            l-0.2-0.1l0-0.1l0,0l-0.2,0l-0.1-0.1v-0.1l0.1-0.1l0.1,0l0.1-0.1h0.1h0.1l0.1,0h0.1l0.2,0.2l0.3,0.1l0.6,0.1l-0.1,0.1l-0.1,0h-0.1
            l-0.1,0l0.2,0.1l0.1,0l0.1-0.1l0.1,0.1h0.2h0.2l0.1-0.1L40,45.8l0.3-0.1l0.2-0.1l0.2,0l0.1-0.1l0.1-0.1l0.2,0l0.1-0.1l0.1,0l0.1,0
            l0.1-0.1l0.6-0.1l0.6-0.1v0H42l0-0.1h0.1h0.1l0.1-0.1l0.4,0.1l0.4-0.1l-0.3,0h-0.3l-0.1,0l0-0.1l0.1,0l0,0l0.1-0.1h0.2h0.2
            l0.1-0.1h-0.2l-0.2,0v0l0.1-0.1l0-0.1h0.1l0.1,0.1l0.1,0l0.1,0l0.1,0l0.3,0l0.3-0.1l-0.4,0l-0.1-0.1l0,0h-0.1l-0.3,0H43l-0.1,0
            l0.2-0.2l0.1-0.1l0.1-0.1l0.3-0.3l0.3-0.3l0.2-0.3l0.1-0.4l0.1-0.1l0-0.1v-0.1l0.1-0.1l0.1-0.2l0.1-0.2l0.3-0.4L45,41l0.2-0.2
            v-0.1l0.1-0.2l0-0.2l0.1-0.5l0.2-0.2l0.2-0.3l0.1-0.3l0.1-0.3l0-0.2v-0.2l-0.1-0.3l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.3l0-0.4l0-0.4
            l0.1-0.1l0.1-0.2l0.1-0.4l0.1-0.4l-0.1-0.4L47,35l0.1-0.2l0.1-0.2l0-0.2l0-0.5l0-0.5l0.1-0.2l0.1-0.2l0.1-0.1l0-0.1l0-0.3l0-0.1
            l0-0.1l0.1-0.1l0.2-0.1v-0.1l0-0.1l0.1,0l-0.1-0.1l0-0.1v-0.2l0.1-0.1L48,31l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1h0.1l0,0.2
            l0.1,0.2l0.1,0.1l0.1,0.1H49l0.1,0l0.1,0.1l0.1,0l0.2,0l0.1-0.1l0.1-0.1l0.1-0.3l0.1-0.1l0-0.1l0.1-0.2L49.8,30z M38.1,22.4
            l-0.3,0l-0.1,0l-0.1-0.1l-0.2-0.5l-0.2-0.5l0-0.1l0.1-0.1l0-0.1v-0.1l0,0.1l0.1,0.1l0.1,0l0-0.1v0.1l0,0l0,0.2l-0.1,0.2l0.1,0.2
            l0.1,0l0.1,0h0.1h0.1l0.1,0l-0.2-0.1l-0.1-0.1l-0.1-0.1l0.2-0.4l0-0.2l0-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1,0.1l0.1,0.1l0,0.1h0
            l0,0h0v-0.1v-0.1l0-0.2v-0.2l0.1-0.2v-0.1l0.1-0.1h0.1l0.1-0.1h0.1l0.1,0.1l0.3-0.1H38l0.1,0l0,0.1l0.1,0.1l0.1,0l0.1-0.1l0,0.1
            l0,0.1l0.1,0.1h0.1l0.2,0.2l0,0.1l0.1,0.1l0.1,0l0.1,0.1l0.1,0.1l0.1,0.2l0.1,0.2l0.3,0.3l0.1,0l0,0.1l-0.1,0.1l-0.1,0h-0.1
            l-0.1,0.1l0,0.2l-0.1,0.2l-0.1,0.2L39,22l-0.4,0.2L38.1,22.4z M38.4,23L38.4,23l0.2,0l0.1,0.2l0.1-0.1v-0.1L38.7,23l0-0.1
            l-0.1-0.2l-0.1-0.2l0.5-0.3l0.2-0.2l0.2-0.2l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0l0.1,0.1l0.1,0.1h0.1l0.1,0.1l0.1,0l0.1,0.1
            l0.1,0.1h0.1L40.7,23l-0.1,0.2l-0.1,0.2l-0.1,0.2L40,23.9l-0.3,0.2L39,24.4h-0.1l-0.1,0l-0.1-0.1L38.6,24l-0.1-0.3l-0.1-0.3V23
            L38.4,23z M38.2,23L38.2,23l0,0.2l0,0.2l0.2,0.4l0.2,0.4l0.3,0.3l0.3-0.1l0.3-0.1L40,24l0.3-0.3l0.2-0.3l0.3-0.3l0.3-0.3l0,0l0,0
            h0v0v0.1l0,0.1L41,23.2l-0.6,0.6l-0.3,0.3l-0.3,0.3l-0.2,0.1l-0.2,0.1l-0.2,0.1l-0.2,0.1l-0.1,0h-0.1l-0.1-0.1l-0.3-0.4l-0.3-0.4
            l-0.1-0.2l0-0.2l0-0.2L38.2,23z M30.3,26.1L30.3,26.1l0.3-1.1l0.1,0.1l-0.2,0.5L30.3,26.1L30.3,26.1z M30.2,24.8L30.2,24.8
            l0.1,1.4h-0.1V24.8L30.2,24.8z M29.6,25L29.6,25L29.6,25l0.2,0.6l0.2,0.6l-0.1,0L29.6,25z M29.4,26.5L29.4,26.5h0.2l0.1,0.1l0.1,0
            l0,0.1v0.1l-0.1,0l-0.1,0l-0.2-0.1V26.5L29.4,26.5z M29.4,27.1L29.4,27.1l-0.1-0.1l0.1-0.1l0.4,0H30l0.2,0L30.1,27L29.9,27h-0.1
            l-0.1,0H29.4z M29.6,27.4L29.6,27.4l-0.1-0.1v0l0.4-0.1h0.2l0.2,0l-0.1,0.1L30,27.3l-0.1,0l-0.1,0.1H29.6z M29.9,27.6L29.9,27.6
            l-0.2,0l0-0.1l0.3-0.2l0.2,0h0.2l-0.1,0.1l-0.1,0l-0.2,0.1L29.9,27.6z M30,28.7l-0.2,1l0,0h-0.1L30,28.7l0-0.9l0.1,0l0.1,0
            L30,28.7z M30.2,30.1l0-2.4h0.1l0.1,2.4L30.2,30.1z M30.5,28.6l0.2,0.9h-0.1h0L30.5,28.6l-0.2-0.9v0l0,0h0v0L30.5,28.6z M36,28.6
            l-0.3,0.1l-1.1-0.1L34,28.4l-0.5-0.2L33,28.1l-0.5-0.1l-0.5-0.1l-0.5-0.2l-0.1-0.3l0,0.1l0,0.1l-0.1,0.2l-0.4,0h-0.2l-0.2-0.1
            l0-0.1l0.1-0.1l0-0.1l-0.1-0.1l0-0.1l0,0l0-0.1L30.4,27h-0.1l0-0.1l0-0.1l-0.2-0.1l-0.2,0v-0.1l0.4,0.1l0.2,0.1l0.1,0.1l0,0.1
            l0.1,0.1l0.1,0l0.1-0.1L30.8,27l-0.1-0.2l-0.1-0.1l-0.1-0.1l-0.2-0.1H30l-0.2,0l-0.1-0.1l-0.1-0.1l0.3-0.1l0.3,0h0.2l0.2,0.1
            l0.3,0.1l0.5,0.5l0.1,0l0.8,0.2l0.9,0.1l0.9,0l0.9,0l0.4,0.1h0.2l0.2-0.1l0.1,0l0.1,0.1l0.1,0.1H36l0.2,0.1l0.1,0.1l0.1,0.2
            l0.1,0.1l0,0.3l0,0.3L36,28.6z M36.9,29l-0.1,0.1l-0.1,0l-0.3-0.1L36.3,29l-0.1-0.2l0.2,0.2l0.1,0.1l0.1,0l0.1-0.2l0-0.2v-0.4
            l-0.2-0.7l-0.1-0.3l-0.2-0.2L36,27l-0.3-0.1L35.5,27l-0.1,0.1l0-0.1l0-0.1l0.1-0.2l0.3-0.3H36l0.1,0l0.1,0.1l0.1,0.2l0.1,0.2
            l0.1,0.5l0.2,0.4l0.1,0.4l0.1,0.4L36.9,29z M42.4,27.1l0.1-0.5l0.1-0.5l0.2-0.4l0.3-0.2l0.2-0.1l0.2,0l0,0h0.1l0,0l0.1,0l0.1,0.1
            l0.1,0.1l0.2,0.3v0.1l-0.1,0l-0.1-0.1l-0.1-0.1l-0.1,0l-0.2,0.3l-0.1,0.3L43,26.9l0,0.6v0.6l0.1,0.2l0.1,0.2l0.2-0.1l0.1-0.2
            l0.2-0.3l0.1,0.1l-0.2,0.4l-0.1,0.2l-0.2,0.1l-0.2-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.3-0.7V27.1z M42.6,29.8l0.1-0.1l0.1-0.1
            l0.1-0.1v-0.7l0.1,0l0.1,0.1l0.1,0l0.1,0l0.2-0.1l0.2-0.2l0.1-0.2l0.1-0.2l1.3,0.9l0,0l0.1,0.1l-0.1,0l1.2,0.9l-0.3,0.3l-0.1,0.2
            l0,0.2l-0.2,0.1l-0.2,0.2l-0.1,0.3l0,0.3l-0.2,0.1l-0.1,0.1L45,32.2L45,32.6l0,0.4l-0.2,0.2l-0.1,0.2l-0.1,0.5l-0.1,0.1l-0.1-0.1
            l-0.1-0.1L44,33.3l-0.2-0.3L43.6,33l-0.2,0l-0.1-0.2l-0.2-0.2L43,32.5l-0.2-0.1v-0.3l0-0.3l-0.1-0.6l-0.1-0.6l-0.1-0.7L42.6,29.8z
             M41.8,34.8v-0.1l0-0.1l0.1-0.2l0.2-0.1l0.2-0.1l0.2,0.1l0.2,0.1l0.4,0l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1,0.3l-0.2,0l-0.2,0.1
            l-0.3,0.2l-0.2,0.3l-0.2,0.3l-0.1,0l-0.1,0l-0.2,0.1L42,35.4l0,0.1l-0.1-0.1h-0.1l-0.3,0l-0.1,0.1l-0.1,0.1l-0.1,0.1L41,35.8
            l-0.2-0.1l-0.2,0l0.3-0.4l0.4-0.4l0.2-0.1L41.8,34.8z M42.3,41.1L42.3,41.1l0.1,0.1h-0.1l-0.1,0.1H42l-0.1-0.1l-0.1-0.3l0-0.1
            l0.1-0.1H42l0.1,0.1l0.1,0.2L42.3,41.1L42.3,41.1z M42.3,40.3L42.3,40.3l-0.1,0l0,0.1l-0.1,0h0l0,0l-0.2-0.1l-0.1,0l-0.1-0.1
            l-0.1-0.3l0.1-0.1l0.1,0l0,0.1l0.1,0l0.1,0l0.1,0.1l0,0.1l0,0.1V40.3z M41.9,39.5L41.9,39.5l-0.1,0.1l-0.2-0.1l-0.2-0.1v-0.1
            l0.1-0.1h0.1l0.1,0l0.1,0h0l0,0l0.1,0l0.1,0.2l0.1,0.2L41.9,39.5z M41,43l-0.3,0.2l-0.3,0.1l0.2-0.3l0.2-0.2l0.2-0.2l0.2-0.2
            l0.3-0.4l0.1,0.1l0,0.1l-0.1,0.2L41,43z M41.3,43l-0.1,0.2l-0.1,0.2L41,43.5l-0.2,0.1l0.1-0.2l0.1-0.1L41.3,43z M38.9,43.8
            L38.9,43.8L38.9,43.8L38.9,43.8L38.9,43.8L38.9,43.8L38.9,43.8z M38.9,43.5L38.9,43.5L38.9,43.5l0.1-0.1v0.1v0.1L38.9,43.5
            L38.9,43.5z M39,42.3L39,42.3h-0.1l-0.1,0l-0.1-0.1l-0.1-0.3l-0.1-0.4V41l0.1-0.4l0.1,0l0,0.1l0.1,0.1l0.1,0l0,0.1H39l0,0.1v0
            l0.1,0.1h0.1v0.1v0.1l0.1,0l0.2,0.3l0.1,0.1l0,0.2l0,0.1l0,0.1L39.5,42l0,0.1v0.1l-0.1,0.1v0.2l-0.1-0.1l0,0.1l-0.1,0.1l-0.1,0.1
            l-0.1-0.1l0-0.1L39,42.3L39,42.3z M38.8,43.6L38.8,43.6v0.1l0,0.1L38.8,43.6L38.8,43.6L38.8,43.6z M38.4,43.6L38.4,43.6h0.1H38.4
            l0,0.2l-0.1-0.1L38.4,43.6z M38.4,43.2L38.4,43.2L38.4,43.2L38.4,43.2L38.4,43.2z M38.5,42.3L38.5,42.3l-0.2,0.1l-0.1,0.1
            l-0.1-0.1H38L38,42.3l-0.1-0.1l0-0.4l0.1-0.4l0.2-0.3l0.3-0.3l0,0.2l0,0.2v0.1v0.1v0.1l-0.1,0.1l0,0.1l0,0h0.1v0.1l-0.1,0.1l0,0
            l0,0.1l0.1,0.1l0,0.1v0.1l0.1,0.1l0,0.1l0,0l-0.1,0l0,0.1L38.5,42.3L38.5,42.3L38.5,42.3L38.5,42.3z M37.8,43.5L37.8,43.5
            l-0.2,0.1l-0.1-0.1l0-0.1l0.2-0.1l0.1,0l0.1,0L37.8,43.5z M37.9,44.3L37.9,44.3l0,0.1h-0.1L37.9,44.3L37.9,44.3L37.9,44.3z
             M36.2,43.2L36.2,43.2v0.1h-0.1h0L36.2,43.2L36.2,43.2z M36.2,43L36.2,43L36.2,43L36.2,43z M36.2,41L36.2,41l-0.1,0.1l-0.1,0
            l0,0.1h-0.1l0,0l-0.1-0.2l0-0.2l0.1-0.1H36v0l0.1-0.2l0.1-0.1h0.1l-0.1,0.2l0,0.2L36.2,41z M36,43.1L36,43.1l-0.1,0.1L36,43.1
            L36,43.1z M35.9,43.4l0,0.1l-0.1,0.1l0-0.1l0-0.1L35.9,43.4z M36.1,40.3l0-0.1l0,0H36l0-0.1L35.9,40l-0.1,0l0.1-0.1l0-0.1l0.2-0.2
            l0.1-0.1h0.1h0.1l0.1,0.1l0.1,0l0.1,0l0.2,0l0.2,0.1l0,0.2l0.1,0.2l0-0.1l0-0.1l0,0.2l0,0.1h-0.1L37,40l-0.1,0l-0.1,0v0l-0.1,0
            l-0.1,0.1l-0.1,0.1L36.1,40.3z M35.6,39.8L35.6,39.8l-0.1,0.1l-0.1,0l0,0.1l-0.1-0.1l-0.2-0.1l-0.1-0.2v-0.2l0-0.2l0.1-0.1
            l0.3,0.3l0.1,0.2v0.1L35.6,39.8z M35.6,40.6l0,0.1l-0.1,0.1l-0.1,0l-0.2,0l-0.1-0.2L35,40.5v-0.2l0.1-0.2l0.2,0.1l0.2,0.1l0.1,0.1
            l0.1,0.1L35.6,40.6z M35.6,39.1L35.6,39.1L35.6,39.1L35.5,39v0v0L35.4,39l0-0.1l-0.1-0.1l0-0.4l0.1-0.2l0.1-0.1l0.2,0.3l0.1,0.4
            l-0.1,0.1l0,0.1L35.6,39.1L35.6,39.1z M36.2,37.6l0.1,0.2v0.1L36.2,38V38l0,0.1L36,38.3l-0.1,0h-0.1l0,0h-0.1v-0.1v0h-0.1v-0.1
            l0,0V38l-0.1-0.1l0-0.1l0-0.1l0.1-0.2l0.1-0.1l0.3-0.2l0,0.1v0.1L36.2,37.6z M37.5,37.8L37.5,37.8l0.2,0v0.1l-0.1,0.1l0,0V38
            l0.1-0.1h0L37.7,38v0l0.1,0l-0.1,0.1l-0.1,0.1v0.1l0.2-0.2l0.2-0.1l-0.1,0.1l0.1,0l0.1-0.1l0.1-0.1l0.1-0.1l-0.1,0.1L38,38.3
            l-0.1,0.2l-0.1,0.1l0-0.1l0.1-0.1l0-0.1l-0.1-0.1l-0.1,0.2l-0.1,0.1l0-0.1l-0.1,0l0.1-0.1l0-0.1l-0.1-0.1l-0.1,0.1l0-0.1l-0.1,0.1
            l-0.1,0l-0.1,0l-0.1,0.1v-0.1v-0.1v0l-0.1,0v-0.1v0l0,0L37,38.2l-0.1,0.1l0,0.2l-0.1,0l0.1-0.3h-0.1l-0.1,0v0.1v0.2h-0.1v-0.1
            l0.1-0.1l0.1-0.2l-0.1,0l-0.1,0.1l-0.1,0.1l0.1-0.3l0.2-0.1l0.2,0H37.5z M37,37v-0.1l0.1-0.2l0.1-0.1l0.2-0.1l0.2-0.1l0.2,0v0.1
            l-0.2,0.4L37.5,37l-0.1,0.1l-0.1,0l-0.1,0l-0.1,0L37,37L37,37z M41,36.8l0.1,0.4l0.1,0.3L41,37.5l-0.1-0.1l-0.1-0.2L40.5,37
            L40.4,37l-0.1-0.1l-0.1-0.1l0.1-0.4l0.1-0.2l0.1-0.1l0.1,0l0.2,0H41l0,0.3L41,36.8z M41.4,40.5L41.4,40.5v0.2l0,0.2l-0.1,0.2h-0.1
            l0,0l0,0l0,0.1l0,0l-0.1,0h0l0,0.1h-0.2l-0.2,0.1l0,0.1v0.1l-0.1,0h-0.1l0-0.1v-0.1l0.1-0.1l0.1,0l-0.2-0.3l-0.1-0.2l0-0.2
            l0.1-0.2l0.2-0.1l0.3-0.1l0.1,0.1l0.1,0.1L41.4,40.5L41.4,40.5z M41.5,41.6l-0.1,0.1l-0.1,0.1h0v-0.2H41.5z M41.1,41.6L41.1,41.6
            l-0.1,0.1l-0.1,0l0,0.1h-0.1l-0.1-0.1v-0.1v-0.1l0.1,0L41.1,41.6L41.1,41.6z M40.2,42.8l-0.2,0.1l0-0.2l0.3-0.1l0.2-0.1l0.3-0.2
            l-0.3,0.4L40.2,42.8z M40.2,43.3l-0.1,0.1l-0.2,0l0.1-0.1L40.2,43.3z M39,40.2l0.3-0.1h0.3l0.2,0.1l0,0l0,0.1h0v0l0,0.1l0.1,0
            l0.1,0.1l-0.1,0h0h-0.1l0,0.1l-0.2-0.1l-0.1-0.1l-0.4-0.1l-0.3,0L39,40.2z M38.9,40.1L39,40l0.2-0.1h0.2l0.1,0.1L38.9,40.1z
             M39.4,38.8l0.1-0.1l0.1-0.1l0.2-0.1l0.1,0l-0.1,0.1l-0.1,0.1L39.3,39L39.4,38.8z M39.3,37L39.3,37L39.3,37L39.1,37l-0.2-0.1
            l-0.1-0.1v-0.1l0.1-0.1l0.1-0.1h0.3l0.2,0.1l0.1,0.2L39.5,37l-0.1,0H39.3z M39.5,38.9L39.5,38.9l0.2-0.1h0.1l0.1-0.1l0.1-0.1h0.1
            l-0.2,0.2L39.5,38.9L39.5,38.9z M39.7,39.1L39.7,39.1l0.2-0.1H40l0,0.1l0,0.1l-0.1,0.1l-0.1,0l-0.1,0L39.7,39.1L39.7,39.1
            L39.7,39.1z M40,40.6l0.1,0.1L40,40.6L40,40.6L40,40.6z M40,39.8L40,39.8l-0.2,0.1l0-0.1v-0.1l0.1-0.1l0.1-0.1l0.1-0.1h0.1
            l0.1,0.1l-0.1,0.1l0,0v0.1h-0.1v0.1L40,39.8L40,39.8z M40.1,38.9l0.1-0.1l0.1,0l0.1,0l0,0.2l-0.1,0.2L40.2,39L40.1,38.9L40.1,38.9
            z M40.1,40l0.2-0.2l0.2-0.1h0.1h0.1h0.1l0,0.1l0.1,0.1l0.1,0.1V40l-0.2,0.1l-0.2,0.1l-0.3,0.2l-0.2-0.2l-0.1-0.1V40z M40.5,38.7
            L40.5,38.7L40.5,38.7l-0.1-0.1l0-0.1l0.1-0.1l0.1,0l0.1,0.1v0.1L40.5,38.7L40.5,38.7z M40.6,39.2L40.6,39.2l-0.1,0.1v-0.1H40.6
            L40.6,39.2L40.6,39.2z M40.5,38.3L40.5,38.3L40.5,38.3L40.5,38.3v-0.1V38.3L40.5,38.3z M40.7,41.9L40.7,41.9l0.1-0.1l0,0.1V42
            L40.7,41.9L40.7,41.9z M41.1,39.5L41.1,39.5l-0.3,0l0-0.1l0.1-0.1l0.2,0l0,0.1l0.1,0.1L41.1,39.5z M40.6,38.9L40.6,38.9l0.1-0.1
            l0.2-0.1l0,0.2H40.6z M40.7,38L40.4,38l0-0.2l0-0.1l0-0.1l0.1-0.1h0.1l0.2,0.1l0.1,0.1l0.1,0l0.1,0.1v0.1l0,0.1L41,38L40.7,38z
             M40.3,37.1L40.3,37.1l0.1,0.1l0,0.1l-0.1,0.1l-0.2,0.1l-0.3,0l-0.2-0.1l0-0.1l-0.1-0.1l0-0.1l0-0.1l0.1-0.1l0.1-0.1h0.2l0.1,0.1
            L40.3,37.1z M40.1,36.6v0.1l-0.5-0.2l0-0.1l0.1-0.1l0.1-0.1l0.1,0l0.1,0.1l0.1,0.2L40.1,36.6z M39.7,35.6l-0.1,0.2l-0.1,0.1
            L39.4,36L39.2,36l-0.3,0.1l-0.2-0.3l0-0.1v-0.1l0.3-0.2l0.2-0.1h0.1l0.2-0.1h0.1l0.1,0L39.7,35.6z M38.9,39.1h0.3l0.1,0l0.1,0.1
            l-0.1,0.1l0,0v0.1l0.2,0.1l0.2,0.1v0.2l-0.1,0.1l-0.1,0.1l-0.2,0l-0.2,0H39l-0.2-0.1l-0.1-0.2l-0.1-0.1l-0.1-0.1l0-0.1l0.1-0.1
            L38.9,39.1z M38.7,40.1L38.7,40.1l-0.1,0.1l-0.1,0v-0.1L38.7,40.1L38.7,40.1L38.7,40.1z M38.7,36.6l-0.1,0.1l-0.3,0.2l-0.2,0
            l-0.2-0.1l0-0.2l0-0.1l0.1-0.1l0.2-0.1l0.2,0l0.2,0.1L38.7,36.6z M37.8,43L37.8,43L37.8,43L37.8,43L37.8,43L37.8,43L37.8,43z
             M37.8,43.2L37.8,43.2l-0.2,0.1v-0.1l0.1,0L37.8,43.2L37.8,43.2z M37.8,42.8L37.8,42.8l0.1,0L37.8,42.8L37.8,42.8z M37.9,42.6
            L37.9,42.6l0.1-0.1h0l0,0.1L37.9,42.6L37.9,42.6L37.9,42.6z M37.8,41.3L37.8,41.3l-0.1,0.1l0-0.1l0-0.1l0-0.2l0.1-0.2l0.2-0.2
            l0.2-0.1l0.2,0l-0.2,0.2L38,40.9L38,41l-0.1,0.1L37.8,41.3z M38.4,39.5L38.4,39.5l0.1,0.1l-0.1,0.1l-0.1,0.1v0.1L38.2,40L38.2,40
            l0,0.1l-0.3,0.2l-0.3,0.1l-0.1-0.1l-0.1-0.2l0-0.2l0.1-0.2l0.1-0.2l0.2-0.1l0.1-0.1H38l0.3,0.1L38.4,39.5L38.4,39.5z M38.3,40.4
            L38.3,40.4l-0.2,0.1l-0.1,0v-0.1l0-0.1l0.1-0.1l0.1,0l0.1,0v0.1l0,0l0,0l-0.1,0.1L38.3,40.4z M36.9,37l-0.1,0.3l0,0.1l-0.1,0.1
            l-0.1,0.1h-0.1l-0.1-0.1v-0.1l-0.1-0.1l-0.1-0.1l0-0.1l0.1-0.2l0.2-0.1l0.2-0.1l0.2,0L36.9,37z M36.3,38.6L36.3,38.6l0.6,0
            l0.2,0.1l0.2,0.1l0.4,0v0.1l0.2,0l-0.1,0.1l0,0.1l0,0.1l-0.1,0.1l0-0.1l0,0l0,0.1l0,0.1h0l0,0v-0.1l0-0.1l0,0l0,0l-0.1,0.1l0,0.1
            h0v0l0,0l-0.5-0.1l-0.3,0h-0.3l-0.1-0.1h-0.1l-0.1,0L36,39.2v-0.1l0.1-0.3L36.3,38.6L36.3,38.6z M37.2,40.3L37,40.5l-0.2,0.2
            l-0.1,0.1l-0.1,0.1l-0.1-0.1v-0.1l0.1-0.2l0.1-0.1l0.2-0.1l0.1,0L37.2,40.3z M36.4,42.8l0.1-0.1l0.1-0.1l0.2-0.2l0.3-0.1l0.1-0.1
            l0.1-0.1l0.2-0.1l0.1,0l0.1,0.1v0.1l0,0.1l0,0.1v0.1l-0.1,0.2l-0.1,0.2l0,0.2l-0.1,0.2l-0.3-0.1L37,43l-0.1,0h-0.1l-0.1,0l0,0.1
            v0.1l-0.1,0.1l-0.1,0l-0.1-0.1l0-0.1L36.4,42.8z M37.6,44.1L37.6,44.1l-0.1,0.1L37.6,44.1L37.6,44.1z M37.6,44.5L37.6,44.5
            L37.6,44.5L37.6,44.5L37.6,44.5L37.6,44.5z M38,43.7L38,43.7l0.1-0.1h0.1l0,0l0,0.1l-0.1,0H38L38,43.7L38,43.7L38,43.7z M38,43.1
            L38,43.1L38,43.1h0.2H38z M38.1,42.9v-0.1l0.1,0h0h0.1L38.1,42.9L38.1,42.9z M38.2,43.3L38.2,43.3L38.2,43.3L38.2,43.3L38.2,43.3z
             M39.3,41l-0.3-0.3l-0.3-0.3l0.1,0l0.2,0l0.3,0.1l0,0.1h0.1l0,0.1v0.1l0.1,0.1l0-0.1v0h0.1v0.1l0,0.1l0.1,0l0,0.1l0.1,0l0,0.1v0.1
            l0.1,0l0.1,0.1l0,0.1l0,0.1l0,0.1l0,0l0,0l-0.1,0l0,0l0,0v0.1v0.1l0,0l-0.1,0.1L40,41.9L39.9,42h-0.1l-0.1-0.1l0-0.1l-0.1-0.2
            l-0.2-0.3L39.3,41z M39.7,43.9l0.1-0.1l0.1,0l0.1-0.1l0.2,0h0.1l0.1,0L40.2,44l-0.1,0.1v0.2l-0.3-0.1l-0.1-0.1l-0.1-0.1L39.7,43.9
            z M40.3,43.6l0.1-0.1l0.1-0.1l0,0.1l-0.1,0.1L40.3,43.6z M41.5,43.1l0.1-0.2h0l0,0.2l-0.1,0.1l-0.1,0.1L41.5,43.1z M41.6,38.6
            L41.6,38.6L41.6,38.6l-0.1,0.1l0,0l-0.1,0.1h-0.1h-0.1l-0.1,0.1l-0.1-0.1l-0.1-0.1L41,38.4l0.1-0.1h0.2l0.3,0.1L41.6,38.6
            L41.6,38.6L41.6,38.6z M30.4,43.2L30.4,43.2l0.1-0.1l0.2-0.1l0,0.1l-0.1,0.1l-0.2,0.1L30.4,43.2L30.4,43.2z M31.2,43.6h-0.2
            l-0.5,0l-0.3-0.1h0v-0.1l0.3,0.1l0.3,0.1L31.2,43.6l0.2-0.1L31.2,43.6z M31.6,43.3l-0.1,0.1l-0.1,0l0-0.1l0.1-0.1v-0.1l-0.1,0
            l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0l0.1-0.1l0.1-0.1l0-0.1l-0.1,0L31,43.2l-0.2,0.1l0-0.1l0.1-0.1l0.1-0.1l0-0.1l-0.2,0.1
            l-0.2,0.2h-0.1v-0.1l0.2-0.2L31,43l0.3-0.1h0.1l0.1,0.1l0.2,0.1l0.1,0.1L31.6,43.3z M32,43L32,43l-0.4-0.1l-0.2-0.1h-0.2l-0.2,0
            l-0.2,0.1v-0.1l0.1-0.1l0.6,0l0.3,0.1L32,43L32,43z M31,42.5l0.3-0.2l0.2-0.1l0.2,0l0.1,0.4l0,0.1l-0.1,0l-0.1,0l-0.1-0.1h-0.1
            l-0.3-0.1L31,42.5z M32.3,43L32.3,43l-0.1-0.1l-0.1-0.2l0-0.2L32,42.3h0h0.1l0.1,0.1l0.1,0.1L32.3,43L32.3,43L32.3,43z M32.4,42.8
            l-0.1-0.2l0-0.2l0.3,0.1l0.2,0.1L32.4,42.8z M35.2,42.6l-0.2,0l-0.3,0l-0.3,0L34,42.7l-0.6-0.1l-0.6-0.2l-0.2-0.1l-0.2-0.1
            l-0.4-0.1h-0.4l-0.4,0.1l0.2-0.3l0.1-0.3l0.1-0.3l0.1-0.3l0.2-0.5l0.2-0.5l0.2-0.5l0.1-0.5l0.1-0.3l0.1-0.3l0.1-0.6l0.1-0.6
            l0.1-0.5l0.1-0.3v-0.1l-0.1-0.1l-0.2,0.5l-0.1,0.5l-0.1,0.9l-0.1,0.8l-0.2,0.7L32,39.7v-0.1v-0.1l-0.1-0.1v-0.1v-0.1l-0.1-0.1
            l0-0.3l-0.1-0.3l-0.1-0.3l0-0.3l-0.2-1l0-0.5v-0.5l-0.1-0.3l0-0.4l-0.1-0.8v-0.3l0-0.3l0.1-0.3v-0.3l0.2-0.2l0.3-0.1l0.3-0.1
            l0.2-0.2l1.3-0.5l1.3-0.5l1-0.4l0.1-0.1l0.1-0.1l0.3-0.1l0.3,0h0.3h0.3l0.3-0.1l-0.5,0l0.1-0.1l0-0.1l0.1-0.1l0.2-0.5l0.1-0.2
            l0.1-0.1l0.1,0l0,0.1l0,0.1l0.1-0.1l0.1,0l0.1,0v0.1l0,0.1l0.1-0.1l0.1-0.1v0.1l0,0.1L38,30.2l0.1,0l0.1-0.1l0.1-0.2l0.1-0.1
            l0.1-0.1l0.1-0.1h0.1h0.1l0.1,0.1l0.1-0.1l0.1-0.1l0.1,0l0,0.1v0.1l-0.1,0.1l0,0.1l0,0l0.1,0l0.1-0.1l0.1-0.1l0.2-0.3v0.1v0.1h0.1
            l0.1-0.1l0.1-0.1l0.1,0v0.1l0,0.1l0,0.1l0.1,0.1l0.1-0.1l0.1-0.1l0.3-0.1l0.1,0h0.1l0.3,0L41,29.8l-0.1,0.1l0,0.1l0.1,0.1l0.1,0.1
            l0.1,0h0.2l0.1,0l0.1,0l0.1,0.1v0.2l-0.1,0.4v0.2l-0.1,0.1l-0.1,0.2l0,0.2l-0.3,0.2l-0.3,0.1L40,32l-0.1,0l0,0l0,0.1l0,0l0.1,0
            l0.5,0l0.5,0.1l0,0.1l-0.1,0.1L41,32.6l-0.2,0l-0.2,0l-0.1,0.1l0.2,0.1l0.2,0l0.2,0l0.1,0l0.1,0.1L41,33.2l-0.3,0.1l-0.6,0.1
            l-0.4,0.1l-0.4,0h-0.8l-0.9-0.1h-0.5l-0.5,0l0,0v0l0.7,0.1l0.7,0.1l0.2,0l0.2,0l0.4,0.1h0.5l0.5,0.1l-0.1,0.1l-0.1,0h-0.1
            l-0.1,0.1l0.3,0l0.3,0l0.7-0.1l-0.3,0.2l-0.3,0.1l-0.3,0.1l-0.3,0.1l-0.7,0H38l-0.4-0.1h-0.2l-0.2,0h-0.1L37,34.6l0.3,0.2l0.3,0.1
            l0.7,0.2l-0.1,0.1l-0.1,0h-0.1l-0.1,0h-0.2l-0.3,0l-0.5-0.1L36.4,35h-0.2l-0.3,0l-0.1,0.1l0,0.1l0,0.1l0.1,0.1l0.1,0l0.3,0l0.1,0
            l0.1,0l0.1,0l0.2,0.1l0.2,0l0.1,0.1l0.1,0l0.1,0l0,0.1L36.5,36l-0.7,0.2v0.1l0.1,0.1l0.5-0.1l0.5-0.1l-0.1,0.1l-0.1,0l-0.2,0.1
            L36,36.6l-0.5,0.3l-0.5,0.3l-0.5,0.3l-0.4,0.3l0.1,0l0.1,0l0.4-0.2l0.2-0.1l0.2,0l0.1,0l0,0.1v0h0.1v0.1l-0.5,0.5l-0.3,0.3
            l-0.3,0.2l-0.2,0.2l-0.3,0.2l-0.5,0.3v0l0.5-0.1l0.4-0.2l0.8-0.4v0.1l-0.1,0.1l-0.1,0.2l-0.4,0.5l-0.4,0.4V40H34l0.5-0.3l0.4-0.3
            l-0.1,0.2l-0.1,0.1l-0.1,0.1l-0.1,0.2l-0.5,0.5L33.4,41l0.1,0l0.1,0l0.2-0.1l0.5-0.3l0.3-0.2l0.3-0.2l0,0.1l-0.1,0.1l-0.1,0.1
            l0,0.1l-0.1,0.1v0.1h0.2l-0.3,0.4l-0.3,0.4l-0.4,0.3l-0.4,0.3v0.1l0.1,0.1l0.2,0l0.3,0l0.2-0.1l0.2,0h0.3l0.3-0.1l0.2-0.1l0.1-0.1
            l0.2,0.3L35.2,42.6z M35.4,42.1L35.4,42.1l-0.1-0.1l0-0.2l0.1,0l0.1,0.1V42.1z M35.6,41.5L35.6,41.5L35.6,41.5l-0.2,0.1l-0.1,0
            l-0.1-0.1l-0.1-0.1l0-0.3l0-0.1h0.1l0.3,0.2l0.1,0l0.1,0.1l0.1,0.1v0.1L35.6,41.5L35.6,41.5z M35.6,43.1L35.6,43.1l0.1-0.1l0,0.1
            l0,0.1l-0.1,0.1L35.6,43.1L35.6,43.1z M35.5,43.4L35.5,43.4l0.1-0.1v0.1H35.5v0.1l0,0.1l-0.1-0.1L35.5,43.4z M35.5,43.7L35.5,43.7
            L35.5,43.7l-0.1,0.1L35.5,43.7L35.5,43.7z M35.2,43.2l0.1-0.1l0.1,0l0,0.1l0,0L35.2,43.2l0.1,0.1l-0.1,0l0,0V43.2L35.2,43.2z
             M35.1,43.6L35.1,43.6L35.1,43.6L35.1,43.6L35.1,43.6L35.1,43.6z M34.7,43.7L34.7,43.7l0.1-0.1l-0.1-0.1v-0.1l0.1,0l0.1-0.1
            l-0.1,0.1l-0.1,0v0.1l0.1,0.1v0.1l-0.1,0L34.7,43.7L34.7,43.7z M34.2,44.2l0.1-0.2l0.1-0.1h0.2l0,0l0,0h0.1l0,0v0.1l-0.1,0.1
            l-0.1,0.1l-0.1,0.1l-0.1,0.1v0.1h-0.1l-0.1-0.1L34.2,44.2z M33.5,44.6l0.1-0.1l0.1-0.1l0.1-0.1h0.1l0.1-0.1l0,0.1v0.1l0,0.1
            l-0.1,0.1l-0.1,0.1h-0.2h-0.2L33.5,44.6z M33.8,45.5L33.8,45.5L33.8,45.5l-0.1,0.1L33.8,45.5L33.8,45.5z M33.3,44.7l-0.1,0.1H33
            l0.1-0.1h0.1H33.3z M33.3,45.5L33.3,45.5L33.3,45.5L33.3,45.5l-0.1,0.1v0L33.3,45.5L33.3,45.5z M32.9,44.4v-0.2l-0.1,0l0,0l0,0
            l-0.1,0h-0.1l0.5-0.1L33,44.3L32.9,44.4z M32.9,45.5L32.9,45.5L32.9,45.5L32.9,45.5L32.9,45.5z M33.5,45.8L33.5,45.8h-0.3l-0.3,0
            L33.5,45.8L33.5,45.8z M33.5,45.6L33.5,45.6L33.5,45.6L33.5,45.6L33.5,45.6L33.5,45.6z M33.5,45.4L33.5,45.4l-0.1,0l0,0H33.5
            l0.1-0.1L33.5,45.4L33.5,45.4z M33.9,45.8L33.9,45.8l-0.1,0l-0.1,0.1l-0.1,0.1l0-0.1l0.1,0L33.9,45.8L33.9,45.8L33.9,45.8
            L33.9,45.8z M34.2,45.6L34.2,45.6L34.2,45.6L34.2,45.6L34.2,45.6z M34.4,45h-0.2l-0.1,0.1l0,0.1v0.1l-0.1,0.1v-0.1l0,0l0,0
            L34,45.3h-0.1l-0.1-0.1l0-0.1l0-0.2l0.2-0.1l0.1-0.1h0.1l0,0.1l0,0.1l0.1,0l0.1,0l0.1-0.1h0.1l0,0.1l0,0.1L34.4,45z M34.9,45.8
            l-0.1,0l-0.2,0.1l0.3-0.1l0.2-0.1L34.9,45.8z M35,45.3l-0.1-0.1l-0.1-0.1l-0.1-0.2v-0.2l0-0.2l0.1-0.2l0.2-0.1l0.1-0.1l0.1,0v0.1
            l0,0.1l-0.1,0.1L35,44.4l0.1,0.1l0.1,0l0.1,0l0.1,0l0.1,0h0.2l-0.1-0.1l-0.1-0.1l-0.1-0.1l0.1-0.1l-0.1,0l0.3,0l0.1,0.1l0.1,0.1
            l0.1,0.3v0.1L36,45L36,44.9l-0.1,0.2l-0.1,0.2l-0.1,0.1h-0.1l-0.1,0L35,45.3z M36.3,45.8L36.3,45.8l-0.1-0.1h-0.1l-0.1,0l-0.1-0.1
            h-0.1l-0.2,0h-0.1l-0.1,0l-0.2,0.1l0.1-0.1l0.1-0.1l0.3-0.1H36l0.3,0L36.3,45.8L36.3,45.8z M36.8,45L36.8,45l-0.1,0.1l-0.1,0
            l0,0.1l0,0.1l0,0l0,0h0l-0.1,0l0,0l-0.1,0.1v0.1l0,0.1l-0.1-0.1l-0.1-0.1l0.1-0.3l0.1-0.1l0.1-0.1h0.1l0.1,0l0.1,0.1L36.8,45
            L36.8,45z M36.8,44.8L36.8,44.8l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1,0h-0.1l0,0l0.1,0.1l0.1,0.1l-0.2,0l-0.1,0l-0.1-0.2l-0.1-0.2
            L36,44.2v-0.1L36,44l0.5-0.4l0.4-0.5h0.2h0.1l0.1,0.1l-0.1,0.4l0,0.2l0,0.3L37,44.3v0.2l0,0.1L36.8,44.8L36.8,44.8z M37.3,44.2
            L37.3,44.2L37.3,44.2l-0.1,0.2L37.3,44.2L37.3,44.2z M36.8,45.5L36.8,45.5L36.8,45.5L36.8,45.5z M36.9,45.2L36.9,45.2L36.9,45.2
            L36.9,45.2L36.9,45.2L36.9,45.2z M37.1,44.8L37.1,44.8L37,44.9l0-0.1H37.1L37.1,44.8L37.1,44.8L37.1,44.8L37.1,44.8z M37.2,45.4
            L37.2,45.4L37.2,45.4L37.2,45.4L37.2,45.4z M37.3,45L37.3,45l-0.1,0.2h-0.1v-0.1L37.3,45L37.3,45L37.3,45z M37.3,44.5L37.3,44.5
            l-0.1,0.1v-0.1L37.3,44.5L37.3,44.5L37.3,44.5L37.3,44.5z M37.3,43.7L37.3,43.7l0.1-0.2h0.1l0,0l0,0.1l0,0.1L37.3,43.7z
             M37.4,44.9L37.4,44.9l0-0.1h0.1l0,0.1L37.4,44.9L37.4,44.9z M37.6,45.2L37.6,45.2L37.6,45.2L37.6,45.2l0-0.1L37.6,45.2L37.6,45.2
            z M37.7,43.8L37.7,43.8l0.1-0.1l0,0v0.1H37.7L37.7,43.8l0,0.1L37.7,43.8L37.7,43.8z M37.8,44.9L37.7,45h0v-0.1v-0.1l0,0l0.1-0.1
            L37.8,44.9L37.8,44.9z M38,45.2L38,45.2L38,45.2L38,45.2L38,45.2L38,45.2z M38,44.5L38,44.5L38,44.7l0.1,0H38l-0.1-0.1L38,44.5
            L38,44.5L38,44.5z M38.1,44L38.1,44l0,0.1H38V44V44H38.1L38.1,44L38.1,44L38.1,44z M38.2,44.4L38.2,44.4l0-0.2l0.1-0.1l0,0.1
            L38.2,44.4z M38.4,45.2L38.4,45.2L38.4,45.2L38.4,45.2L38.4,45.2z M38.3,44.1L38.3,44.1L38.3,44.1l0-0.1l0.1-0.1l0,0.1L38.3,44.1
            L38.3,44.1z M38.5,44.4L38.5,44.4l-0.1-0.1l0.1-0.1l0,0.1l0,0.1L38.5,44.4z M38.5,43.3L38.5,43.3l0.1-0.1l0.1,0l0,0.1l0,0.1
            L38.5,43.3L38.5,43.3L38.5,43.3z M38.6,44.1L38.6,44.1V44l0,0l0.1,0L38.6,44.1L38.6,44.1z M40.1,44.8L40.1,44.8L39.9,45v0.1
            l-0.1,0.1l0,0.1l-0.1,0.1l-0.2,0.1h-0.1l-0.2,0l-0.2,0l-0.2-0.1l-0.1-0.2l0-0.1v-0.1l0-0.2l0.1-0.1l0.1-0.1l0.1-0.1l0.1,0h0.1
            l-0.1,0.2l0.1,0l0,0v0.1l0.1,0h0.1l0.1-0.1l0-0.1l-0.1-0.1v-0.1h0.1l0.1,0.1l0.2,0.1l0.1,0.2l0,0.1L40.1,44.8z M40.8,44.8
            L40.8,44.8l0,0.1V45l-0.2,0.1l-0.1,0l-0.1,0l0-0.1l0,0l-0.1,0l0,0l-0.1-0.1l0-0.1l0-0.1l0.2-0.1l0.2-0.1l0.1,0.1h0.1v0.1
            L40.8,44.8L40.8,44.8z M41,44.1l-0.1,0.1l-0.1,0.1l-0.2,0l-0.1,0.1l-0.1,0l-0.1,0l0-0.1l0-0.2l0.1-0.1l0.1-0.1l0.3-0.1h0.1l0.1,0
            l0.1,0.1l0,0.1L41,44.1z M41.4,44.4l-0.2,0.2h0l0.1-0.1h-0.1l0,0l-0.1-0.1l0,0L41,44.4v-0.1l0-0.1l0-0.1l0.1-0.1l0.1-0.1l0.1,0
            l0.1,0.1l0,0.1l0,0.2L41.4,44.4z M41.9,43.8L41.9,43.8l-0.1,0.1h-0.2l0,0l-0.1-0.1l-0.1-0.1l0-0.1l0.1-0.1l0.2-0.1l0.1,0l0.1,0.1
            l0.1,0.1V43.8L41.9,43.8z M42.2,43l-0.1,0.2l-0.1-0.1H42l0,0.1l-0.1,0l-0.1-0.2l0-0.1l0-0.1l0-0.1l0.1-0.1l0.1,0l0.1,0.1l0.1,0.1
            L42.2,43z M42.4,42.2L42.4,42.2l-0.1,0.1l-0.1,0.1l-0.1-0.1L42,42.1L41.8,42v-0.1l0-0.1l0.1-0.1l0.1-0.1l0.1,0.1l0.1,0.1
            L42.4,42.2L42.4,42.2L42.4,42.2z M42.6,41.8L42.6,41.8L42.6,41.8l0-0.1l0,0L42.6,41.8z M42.7,41.9L42.7,41.9L42.7,41.9L42.7,41.9
            L42.7,41.9L42.7,41.9z M43,40.5l-0.1,0.1l-0.1,0.1l0.1,0l-0.1,0.1l0,0.1l0.1,0l-0.1,0.1V41l0.1,0l-0.1,0v0.1l0,0.1l-0.1,0l0-0.3
            l-0.1-0.2l0-0.2v-0.2l-0.1-0.3l-0.1-0.3l0-0.2v-0.2l-0.1-0.1L42,39.1l-0.1-0.3l-0.1-0.3l-0.1-0.2l-0.1-0.1l-0.1-0.2l0-0.2
            l-0.1-0.4l-0.1-0.4v-0.5l0.1-0.4l0.2-0.1l0.2-0.1l0.1,0.1V36l0.1,0.2l0.1,0.2l0.1,0.2l0,0.2l0,0.2l0.1,0.2v0.1l0.1,0.1l0,0.1v0.1
            l0.1,0.2l0,0.1l0.1,0.1l0,0.2l0.1,0.2v0.1l0.1,0.1v0.1l0,0.1l0,0.1l-0.1,0.1l0.1,0v0.1l0,0.1l0.1,0V39l0,0l-0.1,0.1l0.1,0v0.1l0,0
            l-0.1,0.1l0.1,0l0.1,0l0.1,0l-0.1,0.2h0.1h0.1l-0.1,0.1l-0.1,0.1l0.1,0.1l-0.1,0.1l0,0.1l0.1,0.1l0.1,0L42.7,40h0.2l0,0.1
            l-0.1,0.1h0.1l-0.1,0.1l0.1,0l-0.1,0.1l-0.1,0.1H43z M43.6,41.4L43.6,41.4l-0.1,0.1l0-0.1l0,0L43.6,41.4L43.6,41.4z M43.7,41.2
            L43.7,41.2l-0.1,0.1l0,0v-0.1L43.7,41.2L43.7,41.2L43.7,41.2z M43.6,41l-0.1,0l-0.1-0.1l0-0.1L43.6,41L43.6,41z M43.6,40.7
            l0.1,0.1l-0.2,0l-0.1-0.2v-0.1l0-0.1l0.2,0l-0.1,0l-0.1,0.1l0.2,0L43.6,40.7z M43.5,40.2L43.5,40.2v0.1V40.2h-0.1l0-0.1l0-0.1h0.1
            l0.1,0L43.5,40.2z M43.7,39.1L43.7,39.1l0,0.1l0.1,0l-0.1,0.1l-0.1,0.1h0.3l-0.1,0l-0.1,0.1l0,0.1l0,0.1l-0.1,0.1l0.1,0.1h0.1
            l-0.2,0.1l-0.1,0l-0.1-0.2l-0.1-0.2l-0.3-0.9l-0.3-0.9l-0.2-0.4l-0.1-0.5l-0.1-0.5l0-0.5l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.3-0.1
            v0.1l0.1,0.1l0,0.1v0l0,0l0,0.1v0.1l0.1,0l-0.1,0l0,0L43,36l0,0.1l0.1,0.1l0.1,0.4l0.1,0.5l0,0.2h0l0,0.1l0.1,0.1l0.1,0.1v0.1
            l0.1,0.1v0v0.1l0,0.1l0,0l0,0.1l0,0.1l-0.1,0.1h0.2l-0.1,0.1l-0.1,0.1l0,0l0.1,0l0.1,0v0.1l0,0.1l0,0.1l0.1,0v0.1l0,0.1l0,0.1v0.1
            l0.1,0l-0.1,0.1L43.7,39l0,0h0l0,0l0.1,0L43.7,39.1z M44.6,39.1l-0.1,0.1l0.1,0v0.1l-0.1,0.1l0.1,0.1l0,0v0.1l-0.1,0.1l0.1,0.1
            l-0.1,0.1l-0.1,0l-0.1-0.1l0.1-0.1l-0.1-0.1l0-0.1v-0.1l-0.1-0.1v-0.1l0.1,0l-0.1,0v-0.1v-0.1L44.2,39l0-0.1l-0.1-0.1v-0.1l0-0.1
            l-0.1-0.1L44,38.3l-0.1-0.4L43.5,37l-0.1-0.4l-0.1-0.4l-0.1-0.5v-0.5l0.1-0.3l0.1-0.1l0.1-0.1l0.1,0.1l0,0.1v0.1l0,0v0l0,0h0.1
            v0.1l0,0.1l0.5,1.3l0,0l0.1,0l0,0.1v0.1l0.1,0l0,0.1l0,0.1h0.1l0,0.1l0,0l0.1,0l0,0.1l0,0l0,0h0.1l0,0.1l0,0l0.1,0l-0.1,0.1l0.1,0
            l-0.1,0.1l0,0.1l0.1,0.1l0,0l0.1,0.1l0,0.1l0,0l0,0h0h0v0.1l0,0.1l-0.1,0.1l0.1,0l-0.1,0.1l0,0l0,0h0.1l0,0.1l-0.1,0.1l-0.1,0
            l-0.1,0.1h0.1l0.1,0l-0.1,0.1l0.1,0l0,0.1L44.6,39.1L44.6,39.1L44.6,39.1z M45.3,38.9L45.3,38.9l-0.1-0.1l0.1-0.1l0,0.1L45.3,38.9
            L45.3,38.9z M45.3,38.6L45.3,38.6l-0.1,0l-0.1-0.1L45,38.3l0-0.1L45,38.1L44.9,38l0-0.1l-0.1-0.1l-0.3-0.8l-0.3-0.8l-0.1-0.4
            L44,35.5l-0.1-0.4v-0.5l0.1-0.2l0.1-0.1l0.1-0.1l0.1,0h0.1l0,0l0.1,0.1l0,0v0l0.1,0.1v0v0.1l0.1,0.3l0.1,0.4l0.1,0.4h0l0.1,0.1
            l0,0.1v0v0H45V36l-0.1,0.1l0.1,0l0,0.1v0.1l0,0.1h0v0.1l0,0.1v0.1h0.1v0.1L45,36.7v0.1l0,0.1l0,0.1V37h0.1l0.1-0.1L45.1,37l0,0.1
            l0,0.1L45,37.4l0.1,0l0,0l0.1-0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1h0.2l0,0.1l0.1,0V38V38l0.1,0l0,0.1l-0.1,0.1h0.1v0.1l0,0l-0.1,0.1
            L45.3,38.6L45.3,38.6L45.3,38.6z M45.8,37.1L45.8,37.1l-0.1,0l0-0.1L45.8,37.1L45.8,37.1z M45.8,36.9L45.6,37l-0.1-0.1l0.1-0.1
            L45.8,36.9L45.8,36.9l0.1-0.1L45.8,36.9z M45.6,36.6L45.6,36.6L45.6,36.6l0.2-0.1v0.1l0,0.1l-0.1,0.1h-0.1l-0.3-0.7L45,35.4
            l-0.2-0.7L44.6,34v-0.3l0-0.2l0.1-0.1v0.1l0,0l0.1-0.1l0.1,0l0.2,0.7l0.2,0.6l0,0.1l0.1,0.1l0,0.1L45.5,35l0.1,0l0,0.2h0.1l0,0.2
            h0.1v0.1l-0.1,0.1l-0.1,0.1h0.1l0,0l0.1-0.1l0.1,0l-0.1,0.2l-0.1,0.2h0.1l0.1,0l0.1-0.1L45.7,36l-0.1,0.1l0.1,0l0,0l0.1-0.1
            l-0.1,0.1l-0.1,0.1l0,0l0.1,0l0.1-0.1L45.6,36.6z M46.3,34.9l0,0.2l-0.1,0.1l0,0l0,0l0.1,0l-0.1,0.2l0.1,0l-0.1,0.2h0h0.1l0,0.1
            l-0.1,0.1l0.1,0h0.1l-0.1,0.1l-0.1,0l-0.7-1.6l-0.3-0.8L45,32.7l0-0.3l0.1-0.2l0.1-0.2l0.2-0.1l0.1,0.3v0.2l0,0.2l0.1,0.1l0,0.2
            l0.1,0.2l0.1,0.2l0,0.2l0.1,0.2l0,0.2V34l0,0.2h0l0,0.1l0,0l0,0.1l0,0.1l0.1-0.1l-0.1,0.2h0.1l-0.1,0.1l0,0.1l0.1,0l0.1,0V34.9
            l-0.1,0l-0.1,0.1l0,0.1L46.3,34.9z M46.7,34.5l-0.3-0.7l-0.3-0.7l-0.3-0.7L45.7,32l-0.1-0.4l0-0.2l0.1-0.2l0.1-0.1l0.2-0.1
            l0.1,0.1l0,0.1v0.2l0.3,0.8l0.1,0.3l-0.1,0.2l0.1,0.1v0.1v0l0.1,0l-0.1,0.2l0.1,0v0.1l0,0.1l0.1,0l0-0.1l0,0.1l-0.1,0.1h0.1l0,0
            l0.1-0.1l-0.1,0.2l0.1,0l0.1-0.1l-0.1,0.1v0.1l0,0.1l0.1,0l-0.1,0.1l-0.1,0.1h0.1l0,0l0.1-0.1l0,0.1l-0.1,0.1l0,0.1V34l0.1-0.1
            L46.7,34l-0.1,0.1h0.1v0l0,0.1l0,0.1l0.1,0.1v0.1L46.7,34.5L46.7,34.5L46.7,34.5z M47,32.8L47,32.8l-0.1,0l-0.1-0.1l-0.1-0.2
            L46.5,32l-0.2-0.5l-0.1-0.3l0-0.3l0-0.3l0.1-0.3l0.1-0.1l0.1-0.1h0.1v0.1h0l0.1,0.1l0.1,0.4l0.1,0.4l-0.1,0.1l0,0.4h0l-0.1,0.1
            l0,0.1v0.1l-0.1,0.1l0.1,0h0l0,0.1l0,0.1l0,0.1h0l-0.1,0.1l-0.1,0.1l0.1,0v0l0,0.1H47l0,0.1l0.1,0.1L47,32.5v0.1l0,0l0,0L47,32.7
            L47,32.8L47,32.8L47,32.8L47,32.8z M47.5,30.7L47.5,30.7L47.5,30.7l-0.1,0.2l0,0l0,0V31L47.4,31l0,0.1l0.1,0.1l-0.1,0.1l0.1,0.1
            l-0.1,0.1l-0.1,0l0.1,0.1l-0.1-0.1l0,0l-0.1-0.2L47.1,31l-0.1-0.6v-0.1l0.1-0.1l0.1,0h0.1h0.1l0.1,0.1h0.1l0,0.1l0.1,0.1l0,0.1
            L47.5,30.7L47.5,30.7L47.5,30.7z M47.9,30.5L47.9,30.5l-0.1-0.1v-0.1h0.1v0.1V30.5L47.9,30.5L47.9,30.5L47.9,30.5z M48.9,30.6
            L48.9,30.6L48.9,30.6L48.9,30.6L48.9,30.6z M49.2,30L49.2,30L49.2,30L49.2,30L49.2,30z M49.3,29.7l-0.1,0l-0.1,0l-0.3-0.1l-1,0
            l-0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.3,0.1v0.1l0,0l-0.1,0l-0.3-0.1h-0.3h-0.6l-0.1-0.1L47,30l-0.1,0l-0.1-0.1l-0.2-0.2
            l-0.2-0.2L45,28.6l-1.4-1l-0.2-0.2l-0.1-0.3l0-0.3l0.1-0.4l0.1-0.2l0.1-0.1l0.1-0.1l0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.3l0.2,0.3
            l0.5,0.6l0.5,0.6l0.3,0.3l0.3,0.3l0.5,0.5l0.3,0.2l0.1,0l0.2,0l0.4,0l0.4,0l0.8,0.1l0.1,0l0.1,0L49.3,29.7L49.3,29.7z M48.4,29.8
            L48.4,29.8L48.4,29.8L48.4,29.8L48.4,29.8z M49.5,30.4L49.5,30.4L49.5,30.4L49.5,30.4L49.5,30.4L49.5,30.4z"
                        ></path>
                        <polygon points="43.1,24.5 43.3,24.5 43.1,24.3 43,24.4      "></polygon>
                        <polygon points="38.5,20.2 38.2,20.2 38,20.2 38,20.3 38,20.3 38,20.3 38,20.3 38.2,20.3 38.4,20.3 38.7,20.3 38.6,20.3        "></polygon>
                        <polygon
                            points="38.5,20.5 38.4,20.6 38.4,20.6 38.3,20.5 38.2,20.5 38,20.5 38,20.6 38,20.6 38.1,20.7 38.1,20.8 38.2,20.8
            38.3,20.8 38.3,20.7 38.3,20.7 38.4,20.7 38.5,20.7 38.5,20.7 38.5,20.6 38.5,20.6         "
                        ></polygon>
                        <polygon points="37.7,21.6 37.5,21.6 37.5,21.6 37.5,21.7 37.6,21.8 37.8,21.7 38,21.7 38.1,21.7 37.8,21.6        "></polygon>
                        <polygon points="37.8,21.8 37.5,21.8 37.6,21.9 37.6,22 37.8,21.9 37.9,21.9 38,21.9 38,21.8 37.9,21.8        "></polygon>
                        <polygon points="40.1,24.9 40.3,24.8 40.4,24.6 40.2,24.7 40.1,24.9 39.8,25.3 39.9,25.1      "></polygon>
                        <polygon
                            points="40,25.8 39.7,26.1 39.7,26.2 39.5,26.3 39.5,26.3 39.4,26.4 39.4,26.5 39.5,26.5 39.9,26.1 40.1,25.7 40.7,24.9
            40.2,25.5       "
                        ></polygon>
                        <polygon points="38.2,26 38.3,26.1 38.3,26.2 38.5,26.3 38.4,26 38.1,25.8 38,25.7 37.9,25.5 38,25.8      "></polygon>
                        <polygon
                            points="38.6,28.6 38.4,28.5 38.2,28.4 37.9,28.2 37.8,28 37.7,27.9 37.6,27.9 37.6,27.9 37.7,28 37.8,28.1 37.8,28.1
            37.9,28.2 38.1,28.4 38.4,28.6 38.7,28.7 38.8,28.7 38.9,28.6 39,28.6 39.1,28.5 39.1,28.4 38.8,28.5       "
                        ></polygon>
                        <polygon
                            points="38.7,30.9 38.9,30.8 39.2,30.7 39.1,30.7 39,30.7 38.9,30.7 38.7,30.8 38.4,30.8 38,30.8 37.9,30.8 37.7,31
            38,31       "
                        ></polygon>
                        <polygon
                            points="39.5,31.4 40.2,31.4 40.5,31.3 40.8,31.2 40.7,31.2 40.7,31.2 40.7,31.2 40.5,31.2 40.2,31.2 39.7,31.2
            39.6,31.3 39.4,31.4 39.1,31.3 38.8,31.4 38.4,31.4 38,31.4 37.7,31.4 38.6,31.5       "
                        ></polygon>
                        <polygon points="36,32.2 35.7,32.2 35.4,32.1 35.1,31.9 34.7,31.9 34.6,31.9 34.5,31.9 34.5,32 35.3,32.1      "></polygon>
                        <polygon
                            points="33.1,33 33.3,33.1 34.6,33.2 34.7,33.2 34.9,33.2 35.1,33.2 35.2,33.3 36.4,33.1 36.6,33.1 36.8,33.1 37.2,33.3
            37.4,33.3 37.4,33.3 37.5,33.2 37.3,33.1 37.1,33.1 36.9,33.1 36.7,33 36.5,33 36.3,33 36,33 35.8,32.9 35.1,33 34.8,33 34.4,33
            33.8,33 33.6,32.9 33.3,32.9 33,32.9 32.8,32.9 32.7,32.9 33,33       "
                        ></polygon>
                        <polygon points="34,33.8 33.1,33.8 32.6,33.9 33.1,33.9      "></polygon>
                        <polygon
                            points="33.6,34.5 33.9,34.3 33.9,34.2 33.9,34 33.7,34 33.5,34 33.1,34 32.6,34.1 32.2,34.1 32.2,34.1 32.3,34.2
            32.4,34.4 32.7,34.5 33,34.6 33.3,34.6       "
                        ></polygon>
                        <polygon points="32.1,34.1 32,34.2 32,34.2 32,34.3 32,34.3 32.1,34.2 32.1,34.3 32.1,34.3 32.2,34.3 32.1,34.2        "></polygon>
                        <polygon points="32.5,34.8 32.6,34.7 32.6,34.7 32.6,34.7        "></polygon>
                        <polygon
                            points="32.5,34.7 32.6,34.5 32.5,34.6 32.5,34.6 32.4,34.5 32.4,34.5 32.3,34.6 32.3,34.6 32.3,34.6 32.5,34.8
            32.5,34.8 32.5,34.7         "
                        ></polygon>
                        <polygon points="32.6,34.7 32.6,34.7 32.7,34.6 32.6,34.6        "></polygon>
                        <line x1="38.7" y1="40.1" x2="38.7" y2="40"></line>
                        <polygon points="44.4,28.5 44.3,28.5 44.2,28.5 43.9,28.5 43.8,28.6 44.5,28.6        "></polygon>
                        <polygon points="44.7,28.8 44.8,28.8 44.2,28.7 43.7,28.7 43.7,28.8 44.2,28.8        "></polygon>
                        <polygon points="44.7,29 45.1,28.9 45,28.9 44.3,28.9 43.6,28.8 43.6,28.9 44.3,29        "></polygon>
                        <polygon points="45.3,29.1 45.3,29 45.2,29 45.2,29 45.2,29      "></polygon>
                        <polygon points="44.8,29.1 44.3,29.1 44.3,29.2 44.6,29.2 44.8,29.2 45.1,29.1 45.3,29.1 45.2,29 45.2,29 45,29.1      "></polygon>
                        <polygon points="44.1,30.2 44.4,30.2 44.8,30.1 45,30.2 45.3,30.2 45,30.1 44.7,30.1 44.4,30.1 44.1,30.2      "></polygon>
                        <polygon points="42.9,30.6 43,30.6 43.5,30.6 44,30.6 44,30.5 43.4,30.5 43.1,30.5 42.8,30.5 42.8,30.5        "></polygon>
                        <polygon points="44.9,30.5 44.8,30.5 44.6,30.5 44.6,30.6 45,30.5        "></polygon>
                        <polygon points="45,30.9 45.3,30.9 45.6,30.9 45.6,30.8 45,30.9      "></polygon>
                        <polygon
                            points="43,31.6 43.2,31.7 43.5,31.7 43.7,31.8 43.9,31.9 44.4,32 45,32 45.1,31.9 45.2,31.8 45.2,31.7 44.6,31.6
            44.1,31.6 43.4,31.4 42.8,31.2 42.8,31.4 42.8,31.5       "
                        ></polygon>
                        <polygon points="44.4,32.8 43.9,33 44,33.1 44.3,33.1 44.6,33 44.6,32.9 44.7,32.9 44.8,32.8 44.8,32.8 44.8,32.7 44.8,32.6        "></polygon>
                        <path
                            d="M16.6,32.9h0.1l0.1,0l0.1,0.1V33l-0.1,0l-0.1,0L16.6,32.9l0,0.1l0.1,0.1l0.1,0L17,33l0-0.1l-0.1-0.1l-0.1,0l-0.1,0h-0.1
            l-0.1,0v-0.1l0-0.1h0.1l0.1,0.1l0.1-0.1l-0.1-0.1h-0.1l-0.1,0.1l0,0.1L16.6,32.9z M16.4,33.4L16.2,33l0.3,0.3l0.1,0l-0.3-0.5
            l-0.1,0.1l0.2,0.3l-0.3-0.3l-0.1,0L16.4,33.4L16.4,33.4z M15.7,33.4l0.1,0.1l0.1,0.1l0.1,0l0.1,0l0.1-0.1l0.1-0.1l0-0.1l-0.1-0.1
            L16,33.1L15.9,33l-0.1,0l-0.1,0l-0.1,0.1l-0.1,0.1l0,0.1L15.7,33.4z M15.8,33.1L15.8,33.1l0.2,0.1l0.1,0.1v0.1l-0.1,0l-0.1,0l0,0
            l-0.1-0.1l-0.1-0.1L15.8,33.1z M17.7,32.5l-0.1-0.1l-0.2,0.1l0.1,0.1L17.7,32.5z M17.5,32.7l-0.3-0.5l-0.1,0.1L17.5,32.7
            L17.5,32.7z M16.9,32.5l0.2,0.4l0.1,0L16.9,32.5l0.2-0.1l-0.1-0.1l-0.3,0.2l0.1,0.1L16.9,32.5z M15.9,34l-0.1-0.1l-0.3,0.2
            l0.1,0.1l0.1-0.1l0.2,0.5l0.1-0.1l-0.3-0.4L15.9,34z M16.6,33.5l0.3,0.5l0.1,0L16.6,33.5L16.6,33.5z M17.9,33.5L17.6,33L17.5,33
            l0.2,0.4l-0.3-0.3l-0.1,0.1l0.3,0.5l0.1,0l-0.2-0.4L17.9,33.5L17.9,33.5z M16.6,33.6l-0.1-0.1l-0.3,0.2l0.1,0.1l0.1-0.1l0.3,0.5
            l0.1-0.1l-0.3-0.4L16.6,33.6z M17.2,33.3L17.2,33.3L17,33.3l-0.1,0l-0.1,0.1l-0.1,0.1l0,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0l0.1,0
            l0.1-0.1l0-0.1v-0.1l-0.1-0.1L17.2,33.3z M17.2,33.8l-0.1,0L17,33.6L17,33.5l0.1-0.1l0.1,0l0,0l0.1,0.1l0.1,0.1L17.2,33.8z
             M15.6,33.8L15.6,33.8l0.1-0.1l0-0.2l-0.1,0.1v0.1l-0.1,0.1h-0.1l-0.1-0.1l-0.1-0.1l0.1-0.1h0.1l0.1,0.1l0.1-0.1l-0.1-0.1
            l-0.1-0.1l-0.1,0l-0.1,0.1l0,0.1l0.1,0.2l0.2,0.2H15.6L15.6,33.8z M16.2,33.7L16.2,33.7l0.1,0.4l0.1,0.1l-0.1,0.1h-0.1l-0.1-0.1
            L16,33.9l-0.1,0.1l0.2,0.3l0.1,0.1l0.1,0l0.1,0l0.1-0.1l-0.1-0.2L16.2,33.7z"
                        ></path>
                        <path
                            className="st8"
                            d="M18,10.7l-0.2-0.3c0.2-0.1,0.3-0.2,0.5-0.2l0-0.3l-0.6,0.4l-0.4-0.6l0.1,0c0.2-0.1,0.4-0.2,0.5-0.2l0-0.4
            l-1,0.6c0.1,0.1,0.2,0.2,0.4,0.4l0.5,0.8c0.1,0.2,0.2,0.3,0.2,0.5l0.4-0.3C18.3,11,18.2,10.9,18,10.7z M23.1,8.6
            C23.1,8.6,23.1,8.6,23.1,8.6l-0.3-0.7c0.2,0,0.3-0.1,0.4,0l0.1-0.3l-0.6,0.2l-0.2-0.7l0,0C22.8,7,22.9,7,23.1,7l0-0.4l-1,0.3
            c0.1,0.1,0.2,0.3,0.2,0.5l0.3,0.9c0.1,0.2,0.1,0.3,0.1,0.5l1-0.3l0-0.4C23.6,8.4,23.3,8.5,23.1,8.6z M16.5,10.3
            c-0.2,0-0.5,0-0.7,0.2c-0.3,0.2-0.4,0.5-0.4,0.8c0,0.2,0.1,0.5,0.2,0.7c0.2,0.3,0.5,0.4,0.8,0.4c0.2,0,0.5-0.1,0.7-0.2
            c0.2-0.2,0.3-0.4,0.4-0.6c0-0.3,0-0.6-0.2-0.8C17,10.4,16.7,10.3,16.5,10.3z M16.9,11.9c-0.2,0.1-0.4,0.2-0.6,0.1
            c-0.2-0.1-0.3-0.2-0.5-0.3c-0.3-0.3-0.2-0.7,0.1-1c0.2-0.1,0.4-0.2,0.7-0.1c0.2,0.1,0.3,0.2,0.4,0.3
            C17.2,11.3,17.2,11.7,16.9,11.9z M19.6,8.4c0.2-0.1,0.4-0.2,0.5-0.1l0-0.4l-1.3,0.7l0.1,0.3c0.1-0.2,0.3-0.3,0.5-0.4l0.6,1.1
            C20,9.8,20,10,20,10.2l0.4-0.2c-0.1-0.1-0.2-0.3-0.3-0.4L19.6,8.4z M21.8,7.7c-0.1-0.2-0.1-0.3-0.1-0.5l-0.4,0.2
            c0.1,0.1,0.2,0.3,0.3,0.4l0.1,0.3l-0.8,0.3l-0.1-0.3c-0.1-0.2-0.1-0.3-0.1-0.5l-0.4,0.2c0.1,0.1,0.2,0.3,0.3,0.4l0.4,0.9
            c0.1,0.2,0.1,0.3,0.1,0.5l0.4-0.2c-0.1-0.1-0.2-0.3-0.3-0.4l-0.1-0.3l0.8-0.3l0.1,0.3C21.9,8.9,22,9.1,22,9.2L22.4,9
            c-0.1-0.1-0.2-0.3-0.3-0.4L21.8,7.7z"
                        ></path>
                        <path
                            className="st8"
                            d="M40.2,8.3c0.1-0.3,0.1-0.6,0-0.8C40,7.3,39.8,7.1,39.6,7c-0.3-0.1-0.6-0.1-0.9,0c-0.2,0.1-0.4,0.3-0.5,0.5
            c-0.1,0.3-0.1,0.6,0,0.9c0.1,0.2,0.3,0.4,0.5,0.5c0.2,0.1,0.5,0.1,0.7,0C39.8,8.8,40,8.6,40.2,8.3z M39.8,8.3
            c-0.2,0.4-0.5,0.6-0.9,0.4c-0.2-0.1-0.4-0.2-0.4-0.5c0-0.2,0-0.4,0-0.6c0.2-0.4,0.5-0.6,0.9-0.5c0.2,0.1,0.4,0.3,0.4,0.5
            C39.9,7.9,39.9,8.1,39.8,8.3z M40.8,7.6c0,0.2-0.1,0.3-0.2,0.5l-0.4,0.9c-0.1,0.2-0.2,0.3-0.3,0.4l0.4,0.2c0-0.2,0.1-0.3,0.2-0.5
            l0.2-0.4C40.8,8.8,40.9,8.9,41,9l0.3-0.2l-0.6-0.3L41,7.9l0.1,0c0.2,0.1,0.3,0.2,0.4,0.3l0.3-0.2L40.8,7.6z"
                        ></path>
                        <path
                            className="st8"
                            d="M26.4,52.2c-0.3-0.1-0.6,0-0.9,0.2c-0.2,0.1-0.3,0.3-0.4,0.6c-0.1,0.4,0.1,0.8,0.4,1c0.1,0.1,0.3,0.1,0.4,0.2
            c0.1,0,0.1,0,0.2,0c0.2,0.3,0.4,0.5,0.6,0.5c0,0,0,0,0.1,0l0.2,0c-0.2-0.1-0.3-0.4-0.4-0.6c0.3-0.1,0.6-0.4,0.6-0.8
            c0.1-0.3,0-0.6-0.2-0.8C26.9,52.4,26.7,52.2,26.4,52.2z M26.9,53.4c-0.1,0.4-0.4,0.6-0.8,0.6c-0.2,0-0.4-0.2-0.5-0.4
            c-0.1-0.2-0.1-0.4-0.1-0.6c0.1-0.4,0.4-0.7,0.8-0.6c0.2,0,0.4,0.2,0.5,0.4C26.9,53,26.9,53.2,26.9,53.4z M29,54l0.1-1
            c0-0.2,0.1-0.4,0.1-0.5l-0.5,0c0,0.2,0.1,0.3,0,0.5l-0.1,0.7c0,0.2,0,0.3-0.2,0.4c-0.1,0.1-0.2,0.1-0.4,0.1
            c-0.2,0-0.3-0.1-0.4-0.2c0-0.1,0-0.2,0-0.4l0.1-0.7c0-0.2,0.1-0.4,0.1-0.5l-0.5,0c0,0.2,0.1,0.3,0,0.5l-0.1,0.8c0,0.1,0,0.3,0,0.4
            c0,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.4,0,0.6-0.2l0,0.2l0.4,0C29,54.4,29,54.2,29,54z M30.2,52.6l-0.8,1.5
            c-0.1,0.2-0.2,0.3-0.3,0.5l0.5,0c0,0,0,0,0-0.1c0-0.1,0-0.2,0.1-0.3l0.1-0.3l0.7,0l0.1,0.3c0,0.1,0.1,0.1,0,0.2c0,0,0,0.1,0,0.1
            l0.5,0c-0.1-0.2-0.2-0.3-0.3-0.4L30.2,52.6z M29.9,53.7l0.3-0.6l0.2,0.6L29.9,53.7z M23.4,52l-0.3,0.9c-0.1,0.2-0.1,0.3-0.2,0.5
            l1,0.3l0.2-0.3c-0.3,0-0.5,0-0.7-0.1c0,0,0,0-0.1,0l0.2-0.7c0.2,0.1,0.3,0.1,0.4,0.2l0.3-0.2l-0.6-0.2l0.2-0.7l0,0
            c0.2,0.1,0.4,0.1,0.5,0.3l0.2-0.3l-1.1-0.3C23.5,51.6,23.4,51.8,23.4,52z M21.7,52c0-0.1-0.1-0.2-0.2-0.3
            c-0.1-0.2-0.2-0.3-0.1-0.5c0.1-0.1,0.2-0.2,0.4-0.1c0.1,0.1,0.2,0.2,0.2,0.3l0.2-0.3c-0.1-0.1-0.2-0.2-0.3-0.2
            c-0.3-0.1-0.7,0-0.8,0.3c-0.1,0.2,0,0.4,0.1,0.6c0.1,0.2,0.3,0.3,0.2,0.5c-0.1,0.1-0.2,0.2-0.4,0.1c-0.2-0.1-0.2-0.2-0.2-0.4
            l-0.2,0.4c0.1,0.1,0.2,0.1,0.3,0.2c0.4,0.2,0.7,0.1,0.9-0.2C21.8,52.2,21.8,52.1,21.7,52z M22.6,52.1c-0.1-0.2-0.2-0.3-0.2-0.5
            c0.1-0.2,0.2-0.2,0.4-0.1c0.1,0.1,0.2,0.1,0.3,0.3l0.1-0.3c-0.1-0.1-0.2-0.2-0.3-0.2c-0.3-0.1-0.7,0-0.8,0.3
            c-0.1,0.2,0,0.4,0.2,0.6c0.2,0.2,0.3,0.3,0.2,0.5c-0.1,0.1-0.2,0.2-0.4,0.1c-0.2-0.1-0.2-0.2-0.3-0.4l-0.2,0.4
            c0.1,0.1,0.2,0.1,0.3,0.2c0.4,0.2,0.7,0.1,0.9-0.3c0.1-0.1,0.1-0.3,0-0.4C22.8,52.3,22.7,52.2,22.6,52.1z M19.6,51.8l0.3-0.6
            c0.2,0.1,0.3,0.2,0.3,0.3l0.3-0.2L20.1,51l0.3-0.6l0,0c0.2,0.1,0.3,0.2,0.4,0.3l0.3-0.2l-1-0.5c0,0.2-0.1,0.3-0.2,0.5l-0.4,0.9
            c-0.1,0.2-0.2,0.3-0.3,0.4l0.9,0.5l0.3-0.2C20.2,52,19.9,52,19.6,51.8C19.7,51.8,19.7,51.8,19.6,51.8z M39.1,52.9l-0.3-0.7
            c0.2-0.1,0.3-0.1,0.4-0.1l0.1-0.3L38.7,52l-0.3-0.6l0,0c0.2-0.1,0.4-0.1,0.5-0.1l0-0.4l-1,0.4c0.1,0.1,0.2,0.3,0.3,0.5l0.4,0.9
            c0.1,0.2,0.1,0.3,0.1,0.5l1-0.4l0-0.4C39.6,52.6,39.4,52.8,39.1,52.9C39.1,52.9,39.1,52.9,39.1,52.9z M40.5,50.9
            c-0.2-0.4-0.5-0.4-1-0.2l-0.3,0.1c0.1,0.1,0.2,0.3,0.3,0.4l0.4,0.9c0.1,0.2,0.1,0.3,0.1,0.5l0.4-0.2c-0.1-0.1-0.2-0.3-0.3-0.4
            L40,51.7c0.6,0.4,1,0.5,1.1,0.5l0.3-0.1c-0.4-0.1-0.7-0.3-1-0.5C40.6,51.3,40.6,51.1,40.5,50.9z M40,51.6l-0.3-0.7
            c0.3-0.1,0.5-0.1,0.6,0.2C40.3,51.2,40.3,51.5,40,51.6z M37.4,51.6c-0.2,0-0.4,0-0.7,0.1l-0.2,0.1c0.1,0.1,0.2,0.3,0.2,0.5
            l0.3,0.9c0.1,0.2,0.1,0.4,0.1,0.5l0.3-0.1c0.3-0.1,0.5-0.2,0.6-0.3c0.2-0.2,0.3-0.6,0.2-1C38.1,52,37.8,51.7,37.4,51.6z
             M37.7,53.3c-0.1,0.1-0.2,0.1-0.3,0.2l0,0l-0.4-1.6c0.5-0.1,0.9,0.2,1,0.6C38,52.7,37.9,53.1,37.7,53.3z M41.7,51.4l-0.4-0.9
            c-0.1-0.2-0.1-0.3-0.2-0.5l-0.4,0.2c0.1,0.1,0.2,0.3,0.3,0.4l0.4,0.9c0.1,0.2,0.1,0.3,0.2,0.5l0.4-0.2
            C41.8,51.7,41.7,51.6,41.7,51.4z M35.3,52.1c0,0,0,0.1,0.1,0.1c0,0.1,0,0.1,0,0.2l-0.2,1.2l-0.5-0.9c-0.1-0.2-0.2-0.3-0.2-0.4
            c0,0,0-0.1,0-0.1l-0.5,0.1c0.2,0.1,0.3,0.3,0.4,0.5l0.8,1.3l0.2,0l0.3-1.5c0.1-0.3,0.1-0.5,0.2-0.7c0,0,0,0,0,0L35.3,52.1z
             M33.1,53c0-0.2,0-0.4,0.1-0.5l-0.4,0c0,0.1,0,0.1-0.1,0.2l-0.6,0.8l-0.7-0.7c-0.1-0.1-0.1-0.1-0.1-0.2l-0.5,0l0.2,0.2l0.1,1.3
            c0,0.2,0,0.4,0,0.5l0.5,0c-0.1-0.2-0.1-0.3-0.1-0.5l-0.1-0.9l0.7,0.8l0.6-0.9l0.1,0.9c0,0.2,0,0.4,0,0.5l0.5,0
            c-0.1-0.2-0.1-0.3-0.1-0.5L33.1,53z M36.3,52.4c0-0.2-0.1-0.4,0-0.5L35.8,52l0,0l0,0c0,0,0,0,0,0c0.1,0.1,0.2,0.3,0.2,0.5l0.2,0.9
            c0,0.2,0.1,0.4,0,0.5l0.5-0.1c-0.1-0.1-0.2-0.3-0.2-0.5L36.3,52.4z"
                        ></path>
                        <path
                            className="st8"
                            d="M27.2,11.1c0,0.1,0,0.1,0,0.2L27,11.8l-0.3-0.4c-0.1-0.1-0.1-0.1-0.1-0.2c0,0,0,0,0-0.1l-0.4,0.1
            c0.1,0.1,0.2,0.2,0.2,0.2l0.5,0.6l0.1,0.3c0,0.2,0,0.3,0,0.5l0.4-0.1c-0.1-0.1-0.1-0.3-0.1-0.4L27.1,12l0.3-0.7
            c0-0.1,0.1-0.2,0.1-0.3L27.2,11.1C27.2,11.1,27.2,11.1,27.2,11.1z M28.9,12.3c0.4-0.6,0.6-0.9,0.6-1.2c0-0.3-0.2-0.4-0.5-0.4
            c-0.3,0-0.5,0.2-0.5,0.5c0,0,0,0,0,0.1l0.3,0.1c0-0.1,0-0.1,0-0.2c0-0.2,0.1-0.3,0.2-0.3c0.2,0,0.3,0.1,0.3,0.2
            c0,0.2-0.3,0.7-0.8,1.4l1.3-0.1l0-0.3C29.6,12.3,29.3,12.3,28.9,12.3z M24.3,12.3c-0.1-0.2-0.1-0.4,0-0.5l-0.4,0.1
            c0,0.1,0,0.1,0,0.2l-0.3,0.8l-0.8-0.5c-0.1,0-0.1-0.1-0.1-0.1l-0.4,0.1l0.2,0.1l0.4,1c0.1,0.2,0.1,0.3,0.1,0.5l0.4-0.1
            c-0.1-0.1-0.2-0.2-0.2-0.4l-0.3-0.7l0.8,0.5l0.3-0.9l0.3,0.7c0.1,0.2,0.1,0.3,0.1,0.5l0.4-0.2c-0.1-0.1-0.2-0.2-0.2-0.4L24.3,12.3
            z M26.3,12.6l-1-1.2L25,12.9c0,0.2-0.1,0.3-0.1,0.5l0.4-0.1c0,0,0,0,0,0c0-0.1,0-0.2,0-0.3l0-0.3l0.6-0.2l0.2,0.2
            c0,0,0.1,0.1,0.1,0.2c0,0,0,0,0,0.1l0.4-0.1C26.5,12.8,26.4,12.7,26.3,12.6z M25.3,12.5l0.1-0.5l0.3,0.4L25.3,12.5z M34.7,11.2
            l-0.1,0.3c0.1-0.1,0.3-0.1,0.4,0l0.4,0.1l-0.6,0.9c-0.1,0.2-0.2,0.3-0.3,0.4l0.4,0.1c0,0,0-0.1,0-0.1c0-0.1,0.1-0.2,0.2-0.4
            l0.8-1.1L34.7,11.2z M38.1,12.5c0.3,0.1,0.4,0.2,0.5,0.3l0.2-0.2l-0.9-0.4c0,0.1,0,0.2-0.1,0.3L37.5,13c0.1,0,0.2,0,0.2,0.1
            c0.2,0.1,0.3,0.3,0.2,0.5c-0.1,0.2-0.2,0.2-0.4,0.2c-0.1-0.1-0.2-0.2-0.2-0.3l-0.3,0.2c0.1,0.1,0.2,0.2,0.4,0.3
            c0.3,0.2,0.7,0,0.8-0.3c0.1-0.3,0-0.5-0.3-0.7c0,0-0.1,0-0.1,0L38.1,12.5L38.1,12.5z M36.3,11.6l-0.1,0.3c0.1-0.1,0.3,0,0.4,0
            L37,12l-0.7,0.9c-0.1,0.2-0.3,0.3-0.4,0.4l0.4,0.1c0,0,0-0.1,0-0.1c0-0.1,0.1-0.2,0.2-0.3l0.9-1.1L36.3,11.6z M33.9,11l-0.5,0.3
            l0.2,0l-0.1,0.9c0,0.2-0.1,0.3-0.1,0.4l0.4,0.1c0-0.1,0-0.3,0-0.5L33.9,11z M30.6,10.7c-0.2,0-0.4,0.1-0.5,0.3
            C30,11.2,30,11.4,30,11.6c0,0.5,0.3,0.9,0.6,0.9c0.2,0,0.4-0.1,0.5-0.3c0.1-0.2,0.2-0.4,0.2-0.6C31.2,11,31,10.7,30.6,10.7z
             M30.6,12.2c-0.2,0-0.4-0.2-0.3-0.7c0-0.4,0.2-0.6,0.4-0.6c0.2,0,0.4,0.2,0.3,0.6C31,12,30.8,12.2,30.6,12.2z M31.7,12.2
            c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2c0,0.1-0.1,0.2-0.1,0.2l0,0.1c0.1-0.1,0.3-0.3,0.3-0.5
            C31.9,12.3,31.8,12.2,31.7,12.2z"
                        ></path>
                        <path
                            className="st8"
                            d="M34.8,47.1l-1.1,0.3l0.1,0.3c0.1-0.1,0.2-0.2,0.4-0.2l0.4-0.1l-0.2,1.1c0,0.2-0.1,0.4-0.1,0.5l0.4-0.1
            c0,0,0-0.1,0-0.1c0-0.1,0-0.2,0-0.4L34.8,47.1z M36.2,46.8l-1.1,0.3l0.1,0.3c0.1-0.1,0.2-0.2,0.4-0.2l0.4-0.1l-0.1,1.1
            c0,0.2-0.1,0.4-0.1,0.5l0.4-0.1c0,0,0-0.1,0-0.1c0-0.1,0-0.2,0-0.4L36.2,46.8z M23.9,46.3l-1.1,1c-0.1,0.1-0.3,0.2-0.4,0.3
            l0.4,0.2c0,0,0,0,0,0c0-0.1,0.1-0.1,0.2-0.2l0.2-0.2l0.6,0.2l0,0.3c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0.1l0.4,0.2
            c0-0.2-0.1-0.3-0.1-0.4L23.9,46.3z M23.3,47.2l0.4-0.4l0,0.5L23.3,47.2z M37.4,47.6c-0.1-0.3-0.4-0.4-0.6-0.3c0,0-0.1,0-0.1,0
            l0.1-0.4c0.1-0.2,0.1-0.3,0.2-0.5l-0.4,0.1c0,0.1,0,0.2,0,0.4c-0.1,0.3-0.1,0.5-0.2,0.7c0,0.1,0,0.2,0,0.3
            c0.1,0.3,0.4,0.4,0.7,0.3c0.2-0.1,0.3-0.2,0.3-0.3C37.5,47.9,37.5,47.7,37.4,47.6z M37,48.1c-0.2,0.1-0.3,0-0.4-0.2
            c-0.1-0.2,0-0.3,0.2-0.4c0.2-0.1,0.3,0,0.4,0.2C37.3,47.9,37.2,48,37,48.1z M26,47L25.7,47c0,0.2,0,0.3-0.1,0.5l-0.2,0.8
            c0,0.2-0.1,0.3-0.2,0.4l0.4,0.1c0-0.2,0-0.3,0.1-0.5l0.1-0.3c0.2,0.6,0.4,0.9,0.4,1l0.2,0.1c-0.2-0.3-0.3-0.6-0.4-0.9
            c0.2,0,0.4-0.1,0.5-0.3C26.6,47.4,26.4,47.2,26,47z M26.3,47.7c-0.1,0.2-0.2,0.3-0.5,0.2l0.2-0.7C26.2,47.3,26.3,47.5,26.3,47.7z
             M25.5,47.5c0.1-0.2,0-0.4-0.1-0.5c-0.1-0.1-0.2-0.1-0.4-0.2l-0.3-0.1c0,0.2,0,0.3-0.1,0.5l-0.3,0.8c-0.1,0.2-0.1,0.3-0.2,0.4
            l0.4,0.1c0-0.2,0-0.3,0.1-0.5l0.1-0.3c0.1,0,0.1,0.1,0.2,0.1C25.1,47.9,25.4,47.8,25.5,47.5z M25.2,47.4c-0.1,0.2-0.3,0.3-0.5,0.2
            l0.3-0.7l0,0C25.2,47,25.3,47.2,25.2,47.4z M27,47.3c0,0.1,0,0.3,0,0.5l-0.2,0.8c0,0.2-0.1,0.3-0.2,0.4l0.4,0.1c0-0.1,0-0.3,0-0.5
            l0.2-0.8c0-0.2,0.1-0.3,0.2-0.4L27,47.3z M30.5,49.2c0.4-0.5,0.7-0.9,0.6-1.1c0-0.3-0.2-0.4-0.5-0.4c-0.3,0-0.5,0.2-0.5,0.5
            c0,0,0,0,0,0.1l0.3,0.1c0-0.1,0-0.1,0-0.2c0-0.2,0.1-0.3,0.2-0.3c0.2,0,0.2,0.1,0.3,0.2c0,0.2-0.3,0.6-0.8,1.3l1.3,0l0-0.3
            C31.2,49.1,30.9,49.2,30.5,49.2z M33.4,47.4l-0.4,0.5l0.2,0l0.2,0.9c0,0.2,0,0.3,0,0.5l0.4-0.1c-0.1-0.1-0.1-0.3-0.2-0.4
            L33.4,47.4z M31.8,49c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2c0,0.1,0,0.2-0.1,0.3l0,0c0.1-0.1,0.2-0.3,0.2-0.5
            C32,49.1,31.9,49,31.8,49z M27.9,47.9c0-0.2,0.1-0.3,0.1-0.4l-0.4-0.1c0,0.1,0,0.3,0,0.5l-0.1,0.8c0,0.2-0.1,0.3-0.1,0.4l0.9,0.1
            c0.1-0.1,0.1-0.2,0.2-0.3C28.2,49,28,49,27.7,49L27.9,47.9z M29.8,47.6L29.4,48l0.2,0l0,0.9c0,0.2,0,0.3-0.1,0.5l0.4,0
            c-0.1-0.1-0.1-0.3-0.1-0.5L29.8,47.6z"
                        ></path>
                        <path
                            className="st8"
                            d="M6.7,23.7l2,0.6c0.3,0.1,0.6,0.2,0.8,0.4l0.2-0.7c-0.3,0-0.6,0-0.9-0.1l-2-0.6c0.1-0.3,0.2-0.6,0.4-0.8
            l-0.5-0.4l-0.7,2.4l0.5,0.3C6.5,24.4,6.6,24.1,6.7,23.7z M8,32.8c0.3,0,0.6,0,0.9,0.1l-0.1-0.8c-0.3,0.1-0.5,0.2-0.8,0.2l-0.7,0
            c1-0.8,1.4-1.2,1.4-1.4l0-0.5c-0.4,0.5-0.9,0.9-1.4,1.2c-0.2-0.4-0.5-0.6-0.9-0.6c-0.6,0-0.9,0.5-0.9,1.4l0,0.5
            c0.3-0.1,0.5-0.2,0.8-0.2L8,32.8z M6.5,31.7c0.4,0,0.7,0.2,0.7,0.7l-1.3,0.1C5.9,32,6.1,31.7,6.5,31.7z M7.9,30.2
            c0.3,0,0.6,0.1,0.8,0.2l0.1-1.7l-0.5-0.3c0.1,0.4,0.2,0.8,0.2,1.2c0,0,0,0.1,0,0.1l-1.2,0c0-0.3,0.1-0.5,0.2-0.7L7,28.6l0,1
            l-1.1,0l0,0c0-0.4,0.1-0.6,0.2-0.8l-0.5-0.3l-0.1,1.8c0.3-0.1,0.5-0.1,0.9-0.1L7.9,30.2z M52.4,36.4c-0.3-0.1-0.5-0.2-0.8-0.4
            l-0.5,1.6c0.2,0.1,0.3,0.3,0.5,0.4c0-0.4,0-0.8,0.1-1.3l2,0.6c0.3,0.1,0.5,0.2,0.8,0.4l0.2-0.7c-0.3,0-0.6,0-0.8-0.1L52.4,36.4z
             M8.7,28.2l0.1-0.8c0,0-0.1,0-0.1,0c-0.1,0-0.3-0.1-0.4-0.2l-0.4-0.3l0.2-1.1l0.5-0.1c0.1,0,0.2,0,0.4,0c0,0,0.1,0,0.1,0l0.2-0.8
            c-0.3,0.1-0.6,0.2-0.8,0.3l-2.8,0.7L8,27.6C8.3,27.8,8.5,28,8.7,28.2z M7.6,26.7l-0.8-0.6l1-0.2L7.6,26.7z M10.6,19.2
            c0.3,0.2,0.5,0.3,0.7,0.5l0.8-1.5l-0.3-0.5c-0.1,0.4-0.2,0.8-0.4,1.1c0,0,0,0.1-0.1,0.1l-1-0.6c0.2-0.3,0.3-0.4,0.5-0.5l-0.3-0.5
            l-0.5,0.9l-1-0.6l0,0c0.2-0.3,0.4-0.5,0.6-0.6l-0.3-0.5l-0.9,1.5c0.3,0,0.5,0.1,0.8,0.3L10.6,19.2z M8.3,20.7
            c0.4,0.2,0.6,0.1,1-0.2c0.3-0.2,0.6-0.4,0.9-0.3c0.2,0.1,0.3,0.3,0.2,0.6c-0.1,0.2-0.3,0.4-0.7,0.4l0.7,0.3
            c0.1-0.1,0.2-0.3,0.3-0.4c0.3-0.7,0.2-1.2-0.4-1.4c-0.2-0.1-0.4-0.1-0.6,0c-0.2,0.1-0.4,0.2-0.5,0.3c-0.3,0.2-0.5,0.3-0.8,0.2
            c-0.2-0.1-0.3-0.4-0.2-0.6c0.1-0.2,0.3-0.3,0.5-0.4l-0.5-0.3c-0.2,0.2-0.3,0.3-0.3,0.5C7.6,19.9,7.8,20.5,8.3,20.7z M53.2,23.8
            c-0.5,0.1-0.9,0.4-1.1,0.9c-0.2,0.3-0.2,0.7-0.1,1.1c0.1,0.3,0.2,0.7,0.5,1l0.5-0.1c-0.3-0.3-0.5-0.6-0.6-0.9
            c-0.2-0.7,0.2-1.2,0.9-1.4c0.8-0.2,1.4,0.1,1.5,0.8c0.1,0.3,0.1,0.7-0.1,0.9l0.6-0.1c0-0.3,0-0.6-0.1-0.8
            c-0.1-0.5-0.4-0.9-0.7-1.1C54.2,23.7,53.7,23.6,53.2,23.8z M47,15.6l0.5,0.6c0.1-0.2,0.3-0.4,0.6-0.6l0.5-0.4
            c-0.4,1.2-0.4,1.8-0.4,1.9l0.3,0.4c0.1-0.6,0.2-1.2,0.4-1.8c0.4,0.2,0.8,0.2,1.1,0c0.5-0.4,0.5-1-0.1-1.7l-0.3-0.4
            c-0.1,0.2-0.3,0.4-0.6,0.6l-1.2,1C47.5,15.4,47.2,15.5,47,15.6z M49.7,14.2c0.3,0.4,0.3,0.8,0,1c-0.3,0.2-0.7,0.2-1-0.2L49.7,14.2
            z M48.3,12.8c0-0.4-0.2-0.8-0.5-1.1c-0.4-0.4-0.9-0.5-1.4-0.5c-0.4,0-0.7,0.2-1,0.5c-0.4,0.4-0.5,0.9-0.5,1.3
            c0.1,0.4,0.2,0.7,0.5,1c0.3,0.3,0.7,0.4,1.1,0.5c0.5,0,0.9-0.2,1.2-0.6C48.2,13.7,48.3,13.2,48.3,12.8z M47.8,13
            c0,0.3-0.2,0.5-0.4,0.8c-0.5,0.5-1.1,0.5-1.6,0.1c-0.3-0.2-0.4-0.6-0.3-1c0-0.3,0.2-0.6,0.4-0.8c0.5-0.5,1.1-0.6,1.6-0.2
            C47.7,12.2,47.8,12.6,47.8,13z M52.6,29.6c0,0-0.1,0-0.2,0l0.1,0.8c0.2-0.2,0.5-0.4,0.7-0.5l2.5-1.4l-2.6-1
            c-0.3-0.1-0.6-0.3-0.8-0.4l0.1,0.8c0,0,0.1,0,0.1,0c0.1,0,0.3,0,0.5,0.1l0.4,0.2l0.1,1.2L53,29.4C52.9,29.5,52.8,29.5,52.6,29.6z
             M54.7,28.5L53.8,29l-0.1-0.8L54.7,28.5z M53.3,30.7c-0.3,0-0.6-0.1-0.8-0.2l-0.1,0.8c0.3-0.1,0.5-0.1,0.9-0.1l0.7,0
            c-1.1,0.6-1.6,1-1.6,1.2l0,0.5c0.5-0.4,1-0.7,1.6-1c0.1,0.4,0.4,0.7,0.8,0.7c0.6,0,1-0.4,1-1.3l0-0.5c-0.3,0.1-0.5,0.1-0.9,0.1
            L53.3,30.7z M54.7,32c-0.4,0-0.6-0.3-0.6-0.8l1.3,0.1C55.3,31.8,55,32.1,54.7,32z M51.4,16.6l-1.7,1.1c-0.3,0.2-0.5,0.3-0.8,0.3
            l0.4,0.7c0.2-0.2,0.4-0.4,0.6-0.6l1.7-1.1c0.2,0.3,0.3,0.6,0.3,0.8l0.6-0.1l-1.3-2.1l-0.5,0.2C50.9,16,51.1,16.3,51.4,16.6z
             M50.7,19.5c-0.3,0.1-0.5,0.2-0.8,0.2l0.3,0.7c0.2-0.2,0.4-0.4,0.7-0.5l0.5-0.3l0.6,1.2l-0.5,0.3c-0.3,0.1-0.5,0.2-0.8,0.2
            l0.3,0.7c0.2-0.2,0.4-0.4,0.7-0.5l1.4-0.7c0.3-0.1,0.5-0.2,0.8-0.2l-0.3-0.7c-0.2,0.2-0.4,0.4-0.7,0.5l-0.5,0.2l-0.6-1.2l0.5-0.2
            c0.3-0.1,0.5-0.2,0.8-0.2l-0.3-0.7c-0.2,0.2-0.4,0.4-0.7,0.5L50.7,19.5z M33.9,5.5c0.4,0,0.6,0.1,0.8,0.3L35,5.3L32.6,5l-0.2,0.5
            c0.3-0.1,0.6-0.1,1-0.1l-0.2,2c0,0.3-0.1,0.6-0.2,0.8l0.8,0.1c-0.1-0.3-0.1-0.6,0-0.9L33.9,5.5z M31.2,4.9l-1.3,2.5
            c-0.2,0.3-0.3,0.6-0.5,0.8l0.8,0c0,0,0-0.1,0-0.1c0-0.1,0.1-0.3,0.1-0.5l0.2-0.4l1.2,0l0.2,0.5c0,0.1,0.1,0.2,0.1,0.3
            c0,0,0,0.1,0,0.2l0.8,0c-0.2-0.3-0.3-0.5-0.4-0.7L31.2,4.9z M30.7,6.8l0.4-0.9l0.4,0.9L30.7,6.8z M51.2,39.7
            c-0.3-0.2-0.5-0.3-0.7-0.5l-0.4,0.6c0.3,0,0.5,0.1,0.8,0.3l1.2,0.7l-2.3,0.3c-0.1,0-0.2,0-0.4-0.1l-0.3,0.6
            c0.2,0.1,0.5,0.2,0.7,0.3l1.4,0.8c0.3,0.1,0.5,0.3,0.7,0.5l0.4-0.6c-0.3,0-0.5-0.1-0.8-0.3l-1.4-0.8l2.5-0.3c0.1,0,0.3,0,0.4,0
            l0,0l0.4-0.7l-0.6,0.1L51.2,39.7z M28.7,5.4c0.4,0,0.7,0,0.9,0.1l0.2-0.6l-2.5,0.2l-0.1,0.6c0.3-0.2,0.6-0.2,1-0.3l0.1,2
            c0,0.3,0,0.6-0.1,0.9L29,8.3c-0.1-0.3-0.2-0.5-0.2-0.8L28.7,5.4z M48.9,42l-0.5,0.6c0,0,0.1,0,0.1,0.1c0.1,0.1,0.2,0.2,0.3,0.4
            l0.2,0.4l-0.7,0.9l-0.5-0.1c-0.1,0-0.2-0.1-0.3-0.1c0,0-0.1-0.1-0.1-0.1l-0.5,0.7c0.3,0,0.6,0,0.8,0.1l2.8,0.7l-1.2-2.5
            C49.1,42.6,49,42.3,48.9,42z M48.7,44.6l0.5-0.6l0.5,0.9L48.7,44.6z M8,36.4c1-0.2,1.4-1,1.2-2.1c-0.1-0.3-0.2-0.7-0.5-1.1
            c-0.1,0.1-0.3,0.1-0.5,0.2l-0.4,0.1c-0.3,0.1-0.5,0.1-0.6,0l0.2,0.8c0.1-0.2,0.4-0.3,0.7-0.4l0.4-0.1c0.1,0.2,0.2,0.4,0.2,0.5
            c0.2,0.7-0.2,1.3-1,1.5C7,36,6.5,35.7,6.3,35c-0.1-0.4-0.1-0.7,0.1-1l-0.5,0.1c0,0.3,0,0.6,0.1,0.9c0.1,0.5,0.3,0.9,0.6,1.1
            C7,36.4,7.5,36.5,8,36.4z M35.4,6.2L35,7.8c-0.1,0.3-0.2,0.6-0.3,0.8l1.7,0.4l0.4-0.5c-0.4,0.1-0.8,0.1-1.2,0c0,0-0.1,0-0.1,0
            l0.3-1.2c0.3,0.1,0.5,0.2,0.7,0.3l0.4-0.4l-1-0.2L36,5.8l0,0c0.3,0.1,0.6,0.2,0.8,0.4l0.4-0.5l-1.7-0.4
            C35.5,5.7,35.5,5.9,35.4,6.2z M45.7,9.9l-0.6-0.4c-0.1,0.3-0.2,0.5-0.3,0.8l-0.9,1.4l-0.2-2.5c0-0.1,0-0.3,0.1-0.4l0,0l-0.7-0.4
            l0.1,0.6l-1,1.6c-0.2,0.3-0.3,0.5-0.6,0.6l0.6,0.4c0.1-0.3,0.2-0.5,0.3-0.8l0.7-1.2l0.2,2.3c0,0.1,0,0.2-0.1,0.4l0.6,0.4
            c0.1-0.2,0.2-0.5,0.4-0.7l0.8-1.4C45.3,10.3,45.5,10.1,45.7,9.9z M11.4,42.3c0.3-0.1,0.5-0.2,0.8-0.3l-0.4-0.7
            c-0.2,0.2-0.4,0.4-0.7,0.5l-0.5,0.3L10,40.9l0.5-0.3c0.3-0.2,0.5-0.2,0.8-0.3l-0.4-0.7c-0.2,0.2-0.4,0.4-0.7,0.5L8.9,41
            c-0.3,0.1-0.5,0.3-0.8,0.3l0.4,0.7c0.2-0.2,0.4-0.4,0.7-0.5l0.5-0.3l0.7,1.2l-0.5,0.3c-0.3,0.2-0.5,0.2-0.8,0.3l0.4,0.7
            c0.2-0.2,0.4-0.4,0.7-0.5L11.4,42.3z M12.1,43.2l-1.7,1.2c-0.2-0.3-0.3-0.6-0.3-0.8l-0.6,0.1l1.4,2l0.5-0.2
            c-0.3-0.2-0.5-0.4-0.8-0.7l1.7-1.2c0.3-0.2,0.5-0.3,0.8-0.4l-0.4-0.6C12.6,42.8,12.4,43,12.1,43.2z M10,39.7
            c0.3-0.1,0.6-0.2,0.8-0.2l-0.7-1.6l-0.6,0c0.3,0.3,0.5,0.6,0.7,1c0,0,0,0.1,0,0.1l-1.1,0.5c-0.1-0.3-0.2-0.5-0.1-0.7l-0.6-0.1
            l0.4,0.9l-1,0.5l0,0c-0.1-0.3-0.2-0.6-0.2-0.9l-0.6,0l0.7,1.6c0.2-0.2,0.4-0.3,0.7-0.5L10,39.7z M10.8,14.5L12,17
            c0.1,0.3,0.3,0.6,0.3,0.9l0.5-0.6c0,0-0.1,0-0.1-0.1c-0.1-0.1-0.2-0.2-0.3-0.4l-0.2-0.4l0.7-0.9l0.5,0.2c0.1,0,0.2,0.1,0.3,0.1
            c0,0,0.1,0.1,0.1,0.1l0.5-0.6c-0.3,0-0.6,0-0.8-0.1L10.8,14.5z M12,16.1l-0.5-0.9l1,0.3L12,16.1z M15,14.2l-1.4-1.5
            c-0.2-0.2-0.4-0.5-0.5-0.7l-0.6,0.5c0.3,0.1,0.5,0.3,0.7,0.5l1.1,1.1c0.2,0.2,0.4,0.5,0.5,0.7l1.2-1.1c-0.1-0.2-0.1-0.4-0.2-0.6
            C15.6,13.5,15.4,13.9,15,14.2z M51.7,38.4c-0.3-0.1-0.5-0.2-0.7-0.4l-0.3,0.7c0.3,0,0.6,0.1,0.8,0.2l1.5,0.6
            c0.3,0.1,0.5,0.2,0.7,0.4l0.3-0.7c-0.3,0-0.6-0.1-0.8-0.2L51.7,38.4z M26.4,6.7c-0.4-0.1-0.6-0.2-0.6-0.5c0-0.3,0.1-0.5,0.4-0.5
            c0.2,0,0.4,0,0.6,0.2l-0.1-0.6c-0.2,0-0.4,0-0.6,0c-0.6,0.1-0.9,0.6-0.8,1.1c0.1,0.4,0.3,0.6,0.8,0.7c0.4,0.1,0.7,0.2,0.7,0.5
            c0,0.2-0.1,0.4-0.4,0.5c-0.3,0-0.5-0.1-0.7-0.3l0.1,0.7c0.2,0,0.4,0,0.5,0c0.7-0.1,1.1-0.5,1-1.1c0-0.2-0.2-0.4-0.3-0.5
            C26.8,6.9,26.6,6.8,26.4,6.7z"
                        ></path>
                        <g>
                            <path
                                className="st8"
                                d="M55,33.6c-0.2-0.3-0.5-0.5-0.9-0.6c-0.5-0.1-1,0-1.4,0.3c-0.3,0.2-0.5,0.6-0.6,1c-0.1,0.4,0,0.8,0.2,1.2
                c0.2,0.4,0.6,0.7,1.1,0.8c0.5,0.1,0.9,0,1.3-0.2c0.3-0.2,0.6-0.6,0.7-1C55.3,34.5,55.2,34,55,33.6z M54.9,34.9
                c-0.1,0.4-0.3,0.6-0.7,0.8c-0.2,0.1-0.5,0.1-0.9,0c-0.7-0.2-1-0.7-0.9-1.3c0.1-0.3,0.3-0.6,0.7-0.8c0.3-0.1,0.6-0.1,0.9-0.1
                C54.6,33.7,55,34.3,54.9,34.9z"
                            ></path>
                        </g>
                    </g>
                </g>
            </svg>
            {!param.logoText && (
                <div>
                    <div
                        className={"logo-text"}
                        style={{
                            display: "block",
                            fontSize: "36px",
                            color: "#fff",
                            lineHeight: "30px",
                            marginLeft: "-1px",
                            letterSpacing: "5px"
                        }}
                    >
                        PCDU
                    </div>
                    <div
                        style={{
                            fontWeight: "500",
                            fontSize: "13px",
                            color: "#fff",
                            display: "block",
                            lineHeight: "13px"
                        }}
                    >
                        PHP Contract Data Utility
                    </div>
                </div>
            )}

        </div>
    );
};
