import React, { useEffect, useState } from "react";
import { Main } from "../../common/Main/index.js";
import { Button, Form, Modal, Pagination, Radio } from "antd";
import { Table } from "../../common/Table/index.js";
import { handleControlledDefaultSortOrder } from "../../../utils/handleControlledDefaultSortOrder.js";
import { SelectableDetail } from "../../common/SelectableDetail/index.js";
import { daysTypeDropdownOptions, typeMapping } from "./constants/index.js";
import { MODIFY_CONSTANT, CREATE_CONSTANT } from "../../../constants/mutations.js";
import { useMutation, useQuery } from "@apollo/client";
import { handleGraphQLError, handleMutation, handleMutations } from "../../../utils/errorHandling.js";
import { EditableDetail } from "../../common/EditableDetail/index.js";
import { otherSettingsQuery } from "./queries.js";
import { ClearFiltersButton } from "../../common/ClearFiltersButton/index.js";
import { usePreloadedData } from "../../Application/PreloadedData/index.js";

const { confirm } = Modal;

export const OtherSettings = () => {
    const { refetchPreloadedData } = usePreloadedData();
    const [form] = Form.useForm();

    const defaultSortOn = "text";
    const defaultSortBy = "ascend";
    const [sortOn, setSortOn] = useState(defaultSortOn);
    const [sortBy, setSortBy] = useState(defaultSortBy);
    const [dropdownSelection, setDropdownSelection] = useState("");
    const [rowSelection, setRowSelection] = useState([]);
    const [constantModalVis, setConstantModalVis] = useState(false);
    const [constantTitle, setConstantTitle] = useState("");
    const [tablePage, setTablePage] = useState(false);
    const [tablePageSize, setTablePageSize] = useState(10);
    const [dataToUse, setDataToUse] = useState([]);
    const [modified, setModified] = useState({});
    const [activeFilter, setActiveFilter] = useState(false);

    const [modifyConstant] = useMutation(MODIFY_CONSTANT);
    const [createConstant] = useMutation(CREATE_CONSTANT);

    const { loading, error, data, refetch } = useQuery(otherSettingsQuery, {
        fetchPolicy: "no-cache",
        variables: {
            activeFilter,
            // tells backend to cast 'text' to int when sorting :: 
            sortAsNumber: sortOn === "text",
            type: dropdownSelection,
            sortOn,
            sortBy,
            limit: tablePageSize,
            offset: (tablePage - 1) * tablePageSize
        }
    });

    const clearSearch = () => {
        setTablePage(1);
        setTablePageSize(10);
        setSortOn(defaultSortOn);
        setSortBy(defaultSortBy);
        setActiveFilter(false);
        setRowSelection([]);
    };

    useEffect(() => {
        if (data) {
            setDataToUse(data?.constantsType?.constantsType);
        }
    }, [data]);


    useEffect(() => {
        //Clear row selection when settings type dropdown value is changed.
        setRowSelection([]);
    }, [dropdownSelection]);


    if (error) {
        return handleGraphQLError(error);
    }

    const dropdown = <div style={{ paddingTop: "10px", display: "flex", alignItems: "center" }}>
        <div style={{ width: "500px" }}>
            <SelectableDetail
                title={"Settings Type"}
                value={dropdownSelection}
                onValueUpdated={setDropdownSelection}
                options={daysTypeDropdownOptions.map(({ label, key }) => {
                    return {
                        key: key,
                        text: label,
                        value: key
                    };
                })}
            />
        </div>
        <div>
            <Button
                type="primary"
                style={{
                    float: "right"
                }}
                disabled={dropdownSelection === ""}
                onClick={() => {
                    setConstantModalVis(true);
                    setConstantTitle("Create New Value");
                }}
            >
                Create {dropdownSelection !== "" ? `${typeMapping[dropdownSelection]}` : "Type"}
            </Button>
        </div>
    </div>;

    const showConfirmArchive = (id, deleted, text) => {
        const action = deleted ? "De-archive" : "Archive";

        confirm({
            title:
                `Are you sure you want to ${action} ${typeMapping[dropdownSelection]} value ${text}?`,
            okText: `Yes, ${action}`,
            async onOk() {
                setModified({ ...modified, deleted });
                await handleMutation(
                    modifyConstant({
                        variables: {
                            id,
                            deleted: !deleted,
                            type: dropdownSelection
                        }
                    })
                    , {
                        showSuccess: true,
                        successMessage: `${typeMapping[dropdownSelection]} value ${text} is successfully ${deleted ? "de-archived" : "archived"}`
                    });
                if (dataToUse.length === 1) {
                    setTablePage(1);
                }
                await refetch();
                await refetchPreloadedData();
            }
        });
    };

    const rightAlignedContent = dropdownSelection ? <Radio.Group
        className="ownLine"
        defaultValue={activeFilter}
        value={activeFilter}
        onChange={(e) => {
            setActiveFilter(e.target.value);
            setTablePage(1);
            setRowSelection([]);
        }}
        buttonStyle="solid"
    >
        <Radio.Button value={false} >Active {typeMapping[dropdownSelection]}</Radio.Button>
        <Radio.Button value={true}>Inactive {typeMapping[dropdownSelection]}</Radio.Button>
    </Radio.Group> : null;

    const table = (
        <>
            <Table
                rightAlignedButtonBarElement={rightAlignedContent}
                clearFiltersButton={<ClearFiltersButton
                    clearFilters={clearSearch}
                    filtersData={[
                        { currentValue: activeFilter, defaultValue: false },
                        { currentValue: rowSelection, defaultValue: [] }
                    ]}
                />}
                style={{
                    backgroundColor: "#fff"
                }}
                size="middle"
                pagination={false}
                loading={loading}
                columns={handleControlledDefaultSortOrder({ sortOn, sortBy }, [
                    {
                        title: "Text",
                        dataIndex: "text",
                        width: "30%",
                        sorter: true,
                        render: (text) => text,
                        sortDirections: ["ascend", "descend", "ascend"]
                    },
                    {
                        title: "Status",
                        dataIndex: "deleted",
                        key: "status",
                        render: (deleted) => {
                            return deleted ? "Inactive" : "Active";
                        },
                        width: "40%"
                    },
                    {
                        title: "Actions",
                        key: "actions",
                        width: "30%",
                        render: ({ id, deleted, text }) => {
                            return (
                                <div>
                                    <Button
                                        type="danger"
                                        size="small"
                                        onClick={() => showConfirmArchive(id, deleted, text)}
                                    >
                                        {deleted ? "De-Archive" : "Archive"}
                                    </Button>
                                </div>
                            );
                        }
                    }
                ])}
                rowKey="id"
                dataSource={dataToUse}
                rowSelection={{
                    selectedRowKeys: rowSelection,
                    onChange: (selectedRowKeys) => {
                        setRowSelection((prevState) => [...prevState, ...selectedRowKeys.filter((key) => !prevState.includes(key))]);
                    }
                }}
                onChange={(_pagination, _filters, { field, order }) => {
                    setSortOn(field);
                    setSortBy(order);
                }}

            />
            <Modal
                title={constantTitle}
                open={constantModalVis}
                destroyOnClose="true"
                onOk={async (e) => {
                    e.preventDefault();
                    if (form.getFieldError("value").length === 0){
                        await handleMutation(
                            createConstant({
                                variables: {
                                    constant: {
                                        enumType: dropdownSelection,
                                        text: modified.text
                                    }
                                }
                            })
                        );
                        setConstantModalVis(false);
                        setModified({});
                        form.setFieldsValue({value: ""});
                        await refetchPreloadedData();
                        await refetch();
                    }
                }
                }
                okText="Save"
                okButtonProps={{
                    disabled: typeof modified.text === "undefined" || modified.text === ""
                }}
                onCancel={() => {
                    setModified({});
                    setConstantModalVis(false);
                }}
            >
                <Form form={form}>
                    <EditableDetail
                        title="Enter the value"
                        key="constantValue"
                        inputId={"value"}
                        name={"value"}
                        required={true}
                        strict={true}
                        form={form}
                        additionalRules={[{pattern: "^[0-9]*$", message: "Should be a valid number"}]}
                        value={modified.text}
                        onValueUpdated={(e) => {
                            setModified({
                                ...modified,
                                text: e.target.value
                            });
                        }}
                    />    
                </Form> 
            </Modal>
        </>
    );

    const pagination = (
        <Pagination
            style={{
                display: "flex",
                justifyContent: "center",
                padding: "20px 0 50px 0"
            }}
            showSizeChanger
            onShowSizeChange={(targetPage, pageSize) => {
                setTablePage(targetPage);
                setTablePageSize(pageSize);
            }}
            onChange={(targetPage, pageSize) => {
                setTablePage(targetPage);
                setTablePageSize(pageSize);
            }}
            current={tablePage}
            defaultCurrent={tablePage}
            pageSize={tablePageSize}
            defaultPageSize={tablePageSize}
            pageSizeOptions={["10", "25", "50", "100"]}
            total={data?.constantsType?.count}
        />
    );

    const showBulkConfirmArchive = () => {
        const textArray = data?.constantsType?.constantsType
            .filter(({ id }) => rowSelection.includes(id))
            .map(({ text }) => text)
            .sort((a,b) => a - b);

        confirm({
            title:
                `Are you sure you want to ${activeFilter ? "De-Archive" : "Archive"} ${rowSelection.length} ${typeMapping[dropdownSelection].includes("Days") ? "date values" : "values"} from the ${typeMapping[dropdownSelection]}: ${textArray.join(", ")
                }?`,
            okText: "Yes",
            async onOk() {
                const mutations = rowSelection.map((id) => {
                    return modifyConstant({
                        variables: {
                            id,
                            deleted: !activeFilter
                        }
                    });
                });

                // ERROR HANDLER ITERATION 
                await handleMutations(mutations, {
                    showSuccess: true,
                    successMessage: `${typeMapping[dropdownSelection]} successfully ${activeFilter ? "de-archived" : "archived"}`
                });
                if (data?.constantsType?.constantsType.length === rowSelection.length) {
                    setTablePage(1);
                }
                await refetch();
                await refetchPreloadedData();
                setRowSelection([]);
            }
        });
    };

    const bulkArchiveButton = <div className="main-content-margin-top">
        <Button
            style={{
                marginBottom: "10px"
            }}
            className="ownLine"
            type="danger"
            onClick={showBulkConfirmArchive}
            disabled={rowSelection.length <= 0}
        >
            {activeFilter ? "De-Archive" : "Archive"} {rowSelection.length} {typeMapping[dropdownSelection]}
        </Button>
    </div>;

    return (
        <Main
            title="Other Settings"
            breadcrumbs={
                ["Settings", "Other Settings"]
            }
        >
            {dropdown}
            {dropdownSelection && bulkArchiveButton}
            {table}
            {pagination}
        </Main>
    );
};


