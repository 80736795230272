import moment from "moment";
import { FREQUENCY_OPTIONS } from "../constants/index.js";
import { isUndefinedOrNull } from "./functions.js";

const periodTimeShifts = {
    [FREQUENCY_OPTIONS.daily]: { shiftAmount: 1, shiftUnit: "days" },
    [FREQUENCY_OPTIONS.weekly]: { shiftAmount: 1, shiftUnit: "weeks" },
    [FREQUENCY_OPTIONS.biweekly]: { shiftAmount: 2, shiftUnit: "weeks" },
    [FREQUENCY_OPTIONS.monthly]: { shiftAmount: 1, shiftUnit: "months" },
    [FREQUENCY_OPTIONS.quarterly]: { shiftAmount: 1, shiftUnit: "quarters" },
    [FREQUENCY_OPTIONS.yearly]: { shiftAmount: 1, shiftUnit: "years" }
};

export const calculateInclusiveEndDate = (firstPeriod, frequency, startDate) => {

    const isSame = firstPeriod.isSame(startDate, "day");
    
    let timeShift = { shiftAmount: 1, shiftUnit: "day" };
    
    if (frequency in periodTimeShifts) {
        timeShift = periodTimeShifts[frequency];
    }

    let endDate = firstPeriod.clone()
        .add(timeShift.shiftAmount, timeShift.shiftUnit)
        .subtract(1, "day");
    
    if (frequency === FREQUENCY_OPTIONS.monthly && isSame) {
        endDate = firstPeriod.clone().endOf("month");
    }

    if (frequency === FREQUENCY_OPTIONS.quarterly && isSame) {
        endDate = firstPeriod.clone().endOf("quarter");
    }
   
    if (frequency === FREQUENCY_OPTIONS.yearly && isSame) {
        endDate = firstPeriod.clone().endOf("year");
    }

    if (frequency === FREQUENCY_OPTIONS.weekly) {
        endDate = firstPeriod.clone().endOf("week");
    }

    if (frequency === FREQUENCY_OPTIONS.biweekly  && isSame) {
        endDate = firstPeriod.clone().add(1, "weeks").endOf("week");
    }

    return endDate;
};

export const calculateOffsetFromYearStart = (firstPeriodSelected) => {

    const january1 = moment().startOf("year");

    const nMonths = firstPeriodSelected.diff(january1, "months");
    const nDays = firstPeriodSelected.clone().subtract(nMonths, "months").diff(january1, "days");

    return {
        months: nMonths,
        days: nDays
    };
};

export const calculateOffsetFromStartDate = (firstPeriodSelected, startDate, frequency) => {
    const isSame = firstPeriodSelected.isSame(startDate, "day");
    if (isSame) {
        return {
            months: 0,
            days: 0
        };
    }
    if (frequency === FREQUENCY_OPTIONS.yearly) {
        return calculateOffsetFromYearStart(firstPeriodSelected);
    }
    const nMonths = firstPeriodSelected.format("M") - startDate.format("M"); //firstPeriodSelected.diff(startDate, "months");
    return {
        months: nMonths,
        days: (firstPeriodSelected.date() - 1)
    };
};

/**
 * Setting First Period from Submission Type start date
 * @returns Date
 */
export const setFirstPeriodOfYear = ({ months, days }, startDt, frequency) => { 
    if (months === 0 && days === 0 && !isUndefinedOrNull(startDt)) {
        return startDt;
    }
    const january1 = moment().startOf("year");
    const currentStartDate = moment(startDt);
    /* Since YEARLY offset is stored in DB as actual month number so First period is calculated from start of the year*/
    if (frequency === FREQUENCY_OPTIONS.yearly) {
        return january1.clone().add(months, "months").add(days, "days");
    }
    
    const currentMonth = months + currentStartDate.month();
    const firstPeriodDate = january1.clone().add(currentMonth, "months").add(days, "days");
    return firstPeriodDate;
};

/**
 * Setting First Period from start of the current year
 * @returns Date
 */
export const setFirstPeriodFromYearStart = ({ months, days }) => {
    const january1 = moment().startOf("year");
    const startDate = january1.clone().add(months, "months").add(days, "days");
    return startDate;
};

export const calculateFirstDueDate = (frequency, dueOffset, contractStartDate, firstPeriod) => {
    const startDt = moment(contractStartDate);
    const endDate = calculateInclusiveEndDate(firstPeriod, frequency, startDt);
    let firstDueDate = endDate.clone();

    if (dueOffset.months) {
        const dayOfMonth = startDt.format("D");
        /**
         * If start date is 1 then month end date is added.
         */
        if (Number(dayOfMonth) === 1) {
            firstDueDate.add(dueOffset.months, "months").endOf("month");
        } else {
            firstDueDate.add(dueOffset.months, "months");
        }
        
    }
    if (dueOffset.days) {
        firstDueDate.add(dueOffset.days, "days");
    }

    return firstDueDate;
};