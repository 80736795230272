import React from "react";
import { Button, DatePicker } from "antd";

const { RangePicker } = DatePicker;

export const DatePickerDropdown = ({ setFilter, filter, resetFilter, title }) => {
    
    const resetCallback = resetFilter ? resetFilter : () => {
        setFilter([]);
    };

    return (
        <div style={{ padding: 8 }}>
            {title ?? null}
            <RangePicker
                allowClear={false}
                value={filter}
                onChange={(selected) => {
                    return setFilter(selected);
                }}
            />
            <br />
            <Button onClick={resetCallback} size="small" style={{ width: 90, display: "block", marginTop: "10px" }}>
                Reset
            </Button>
        </div>
    );
};
