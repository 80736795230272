import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { Layout, Menu } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAngleDown,
    faUserCog,
    faSignOutAlt
} from "@fortawesome/free-solid-svg-icons";
import { Logo } from "../Logo/index.js";
import { UserContext } from "../Application/UserContext.js";
import { PCDULink } from "../common/PCDULink/index.js";
import { SubmissionOptionsSortedByRole } from "../NavigationSidebar/submission-options-sorted-byrole.js";
import { DownloadsDropdownLabel, makeDownloadsDropdownChildren } from "./downloadsDropdown.js";

const { Header } = Layout;

const MENU_KEYS = {
    submissions: "submissions",
    calendar: "calendar",
    submissionTypes: "submission-types",
    contracts: "contracts",
    documents: "documents",
    library: "library",
    directory: "directory",
    analytics: "analytics",
    downloads: "downloads"
};

const pathSpecifiers = {
    "export-tables": MENU_KEYS.analytics,
    "web-logs": MENU_KEYS.analytics,
    "changelogs":MENU_KEYS.analytics,
    "submissions": MENU_KEYS.submissions,
    "calendar": MENU_KEYS.calendar,
    "submission-types": MENU_KEYS.submissionTypes,
    "contracts": MENU_KEYS.contracts,
    "contract-types": MENU_KEYS.contracts,
    "documents": MENU_KEYS.documents,
    "library": MENU_KEYS.library,
    "organizations": MENU_KEYS.directory,
    "users": MENU_KEYS.directory
};

const getHighlightedMenuKey = (path) => {
    if (path === "/") {
        return [MENU_KEYS.submissions];
    }

    const pathComponent = (path[0] === "/" ? path.slice(1) : path).split("/")[0].toLowerCase();

    if (pathComponent) {
        return [pathSpecifiers[pathComponent]];
    }

    return [];
};


export const NavigationBar = (({
    currentDownloads,
    failedDownloads,
    removeFailedDownload
}) => {
    const location = useLocation();
    const {
        userPermViewContracts,
        username,
        userPermViewSettingPage
    } = useContext(UserContext);

    const defaultKey = SubmissionOptionsSortedByRole()[0].key;
    const defaultSubmissionPageByRoleKey = defaultKey === "submissions" ? defaultKey : `submissions/${defaultKey}`;

    const navigationBarMenuItems = [
        {
            key: MENU_KEYS.submissions,
            label: <PCDULink 
                to={`/${defaultSubmissionPageByRoleKey}`} 
                resetBreadcrumbs={true}
                resetPersistentState={true}
            > 
                Submissions 
            </PCDULink>
        },
        {
            key: MENU_KEYS.calendar,
            label: <PCDULink 
                to={`/${MENU_KEYS.calendar}`} 
                resetBreadcrumbs={true}
                resetPersistentState={true}
            >
                Calendar
            </PCDULink>
        },
        {
            key: MENU_KEYS.submissionTypes,
            label: <PCDULink 
                to={`/${MENU_KEYS.submissionTypes}`} 
                resetBreadcrumbs={true}
                resetPersistentState={true}
            >
                Submission Types
            </PCDULink>
        },
        (
            userPermViewContracts
                ? { 
                    key: MENU_KEYS.contracts, 
                    label: <PCDULink 
                        to={`/${MENU_KEYS.contracts}`} 
                        resetBreadcrumbs={true}
                        resetPersistentState={true}
                    >
                        Contracts
                    </PCDULink> 
                }
                : null
        ),
        {
            key: MENU_KEYS.documents,
            label: <PCDULink 
                to={`/${MENU_KEYS.documents}`} 
                resetBreadcrumbs={true}
                resetPersistentState={true}
            >
                Documents
            </PCDULink>
        },
        {
            key: MENU_KEYS.library,
            label: <PCDULink 
                to={`/${MENU_KEYS.library}`} 
                resetBreadcrumbs={true}
                resetPersistentState={true}
            >
                Library
            </PCDULink>
        },
        {
            key: MENU_KEYS.directory,
            label: <PCDULink 
                to={"/organizations"} 
                resetBreadcrumbs={true}
                resetPersistentState={true}
            >
                Directory
            </PCDULink>
        },
        {
            key: MENU_KEYS.analytics,
            label: <PCDULink 
                to={"/export-tables"} 
                resetBreadcrumbs={true}
                resetPersistentState={true}
            >
                Analytics
            </PCDULink>
        },
        {
            key: MENU_KEYS.downloads,
            label: <DownloadsDropdownLabel currentDownloads={currentDownloads} failedDownloads={failedDownloads} />,
            children: makeDownloadsDropdownChildren({ currentDownloads, failedDownloads, removeFailedDownload })
        }
    ].filter(Boolean);

    const userProfileMenuItems = [
        {
            key: "UserProfile",
            label: <span>
                {username}
                <FontAwesomeIcon icon={faAngleDown} style={{ marginLeft: "10px" }} />
            </span>,
            children: [
                (
                    userPermViewSettingPage
                        ? {
                            key: "settings",
                            label: <PCDULink 
                                to={"/settings/library"} 
                                resetBreadcrumbs={true}
                                resetPersistentState={true}
                            >
                                <FontAwesomeIcon icon={faUserCog} style={{ marginRight: "10px" }} />
                                Settings
                            </PCDULink>
                        }
                        : null
                ),
                {
                    key: "logout",
                    label: <>
                        <FontAwesomeIcon icon={faSignOutAlt} style={{ marginRight: "10px" }} />
                        <PCDULink 
                            to={"/logout"} 
                            reloadDocument={true}
                            resetBreadcrumbs={true}
                            resetPersistentState={true}
                        >
                            Log Out
                        </PCDULink>
                    </>
                }
            ].filter(Boolean)
        }
    ];

    return (
        <Header
            style={{
                width: "100%",
                position: "sticky",
                zIndex: "10",
                top: "0px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingLeft: "0",
                paddingRight: "0"
            }}
        >
            <div
                style={{
                    minHeight: "64px",
                    display: "inline-block",
                    verticalAlign: "bottom",
                    padding: "8px 7.5px 0",
                    cursor: "default",
                    width:"260px"
                }}
            >
                <Logo />
            </div>
            <Menu
                theme="dark"
                mode="horizontal"
                style={{
                    lineHeight: "64px",
                    padding: "0",
                    marginRight: "25px",
                    flex: "1 1 auto",
                    alignItems: "stretch"
                }}
                selectedKeys={getHighlightedMenuKey(location.pathname)}
                items={navigationBarMenuItems}
            />
            <Menu
                theme="dark"
                mode="horizontal"
                style={{
                    lineHeight: "64px",
                    color: "#fff",
                    flex: "0 1 auto"
                }}
                selectedKeys={[]}
                items={userProfileMenuItems}
            />
        </Header>
    );
});