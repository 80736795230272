import React, { useContext, useState } from "react";
import { Button, Modal, Checkbox, Divider } from "antd";
import { gql, useMutation, useQuery } from "@apollo/client";
import { SelectableDetail } from "../common/SelectableDetail/index.js";
import { handleGraphQLError, handleMutation, handleMutations } from "../../utils/errorHandling.js";
import { PaperClipOutlined } from "@ant-design/icons";
import { ArchiveModal } from "./archive-modal.js";
import {
    MODIFY_SUBMISSION,
    MODIFY_COMMENT,
    MODIFY_ISSUES,
    MODIFY_REVIEW
} from "../../constants/mutations.js";
import {
    SUBMISSION_STATUS_LIST,
    DISPOSITION_OPTIONS,
    ISSUE_STATUS_LIST,
    NOTIFICATION_TYPES,
    SUBMISSION_STATUS_SUCCESS,
    S3_UPLOAD_STATUS,
    FEEDBACK_DOCUMENTS_BLOCKING_SEND_FEEDBACK_MESSAGE,
    DOCUMENT_DQ_STATUS_LIST
} from "../../constants/index.js";
import { UserContext } from "../Application/UserContext.js";

const { confirm, warning } = Modal;


const allTrue = (conditions) => conditions.every(condition => condition === true);

const sendFeedbackModalButtonDisabled = ({ dispositionNoIssue, disposition, stance, feedbackIssues }, atLeastOneOpenIssue) => {
    if (!dispositionNoIssue && !disposition) {
        return true;
    }

    if (stance === SUBMISSION_STATUS_LIST.final_requested) {
        return atLeastOneOpenIssue;
    }

    if (stance === SUBMISSION_STATUS_LIST.version) {
        return !(Array.isArray(feedbackIssues) && feedbackIssues.length > 0);
    }

    return false;
};

const countSelectedComments = (modifiedObj) => {
    const issueIds = Array.isArray(modifiedObj?.feedbackIssues) ? modifiedObj.feedbackIssues : [];
    return Object.keys(modifiedObj).reduce((selectedCommentsCount, key) => {
        if (issueIds.includes(key)) {
            const selectedCountForIssue = Array.isArray(modifiedObj[key]) ? modifiedObj[key].length : 0;
            return selectedCommentsCount + selectedCountForIssue;
        } else {
            return selectedCommentsCount;
        }
    }, 0);
};

const countTotalComments = (issues) => {
    return !Array.isArray(issues) ? 0 : issues.reduce((commentsCount, issue) => {
        return commentsCount + (issue?.comments?.filter?.(comment => !comment?.isCorrectionNote)?.length ?? 0);
    }, 0);
};

const displaySelectAllCommentsCheckbox = (activeIssues) => {
    // only show the "Select All Comments" checkbox if there is at least one active issue with a comment that is NOT a correction note :: 
    return activeIssues.some(issue => Array.isArray(issue?.comments) && issue.comments.some(comment => comment && comment.isCorrectionNote !== true));
};


export const SubmissionButtons = ({ options }) => {

    const {
        submission,
        userPermFinalizeSubmission,
        userReviewerFeedBackPerm,
        userCompleteReviewPerm,
        userPermCancelDocumentDQProcess,
        isDqProcessing,
        getActiveIssue,
        currentUserId,
        isUserAdmin,
        isUserSubmitter,
        isUserPrimaryReviewer,
        refetch,
        uploadStatus,
        setRescindWithIssueFlag,
        setRefetchDocumentsFlag
    } = options;

    const {constantsData} = useContext(UserContext);

    const getDispositionOption = (specifier, field) => constantsData.DISPOSITION_OPTIONS.find((option) => option.text === specifier)[field];
    
    const [modified, setModified] = useState({});
    const [feedbackVis, setFeedbackVis] = useState(false);
    const [showSubmissionAccept, setShowSubmissionAccept] = useState(false);
    const [indeterminateIssues, setIndeterminateIssues] = useState(false);
    const [checkAllIssues, setCheckAllIssues] = useState(false);
    const [indeterminateComments, setIndeterminateComments] = useState(false);
    const [checkAllComments, setCheckAllComments] = useState(false);
    const [archiveModalMode, setArchiveModalMode] = useState(null);

    const [modifyComment] = useMutation(MODIFY_COMMENT);
    const [modifySubmission] = useMutation(MODIFY_SUBMISSION);
    const [modifyIssues] = useMutation(MODIFY_ISSUES);
    const [modifyReview] = useMutation(MODIFY_REVIEW);

    const { data, loading, error } = useQuery(gql`
        query ArchiveReasonsQuery {
            archiveReasons {
                id 
                text 
                defaultSelection 
            }
        }
    `, { fetchPolicy: "cache-and-network" });

    if (error) {
        handleGraphQLError(error);
    }

    const feedbackAlreadySent = (submission.status === SUBMISSION_STATUS_LIST.final || submission.status === SUBMISSION_STATUS_LIST.version || submission.status === SUBMISSION_STATUS_LIST.final_requested);
    const isPackagedSubmission = submission.obligation.submissionType.packagedSubmission;
    const skipFinalVersionRequest = submission.obligation.submissionType?.category.finalVersionRequestFlag === false;
    const finalizeEnabledStatus = (
        submission.status === SUBMISSION_STATUS_LIST.final_version_review 
        || (skipFinalVersionRequest && !feedbackAlreadySent)
    );
    const isDQDocument = submission.submissionDocuments.some(doc => doc.documentNewestVersion?.dqFlag);
    const isDQIncomplete = submission.submissionDocuments.some(doc => doc.documentNewestVersion?.dqStatus === DOCUMENT_DQ_STATUS_LIST.Processing);
    const hasAtLeastOneOpenIssue = Array.isArray(getActiveIssue) && getActiveIssue.length > 0;
    const dqProcess = isDqProcessing ? (userPermCancelDocumentDQProcess ? false : true) : false;
    const getCurrentReview = () => {
        return currentUserId === null ? submission.submissionNewestVersion.reviews[0] :
            submission.submissionNewestVersion.reviews.find(({ reviewer: { id } }) => id === currentUserId);
    };

    const currentReview = getCurrentReview();

    // submisison can be finalized if all of these conditions are true ::  
    //   1.) user has permission; 
    //   2.) submission upload has finished successfully 
    //   3.) If DQ document, dq process needs to be complete OR user is admin (and can therefore override)
    //   4.) Submission is not archived 
    //   5.) Valid submission status based on final version request flag of related submission type category  
    const submissionCanBeFinalized = allTrue([
        (userPermFinalizeSubmission || userReviewerFeedBackPerm), 
        (uploadStatus === SUBMISSION_STATUS_SUCCESS),
        (isDQDocument ? (!isDQIncomplete || isUserAdmin) : true),
        (submission.status !== SUBMISSION_STATUS_LIST.archive),
        finalizeEnabledStatus
    ]);

    // can send feedback if ALL of the following are true: 
    //   1.) user has proper permissions; 
    //   2.) If DQ document, dq process needs to be complete OR user is admin (and can therefore override) 
    //   3.) Submission file upload status is successful;
    //   4.) the submission has not already had feedback sent;
    //   5.) the submission is not archived; 
    //   6.) the submission either a.) has final version request workflow; or b.) has one or more open issues; 
    //   7.) the submission either a.) is not in status "Final Version Review"; or b.) has at least one open issue
    const renderSendFeedbackButton = allTrue([
        (userReviewerFeedBackPerm || isUserAdmin),
        (isDQDocument ? (!isDQIncomplete || isUserAdmin) : true),
        (uploadStatus == SUBMISSION_STATUS_SUCCESS),
        (!feedbackAlreadySent),
        (!submission.archived),
        (!skipFinalVersionRequest || hasAtLeastOneOpenIssue),
        (submission.status !== SUBMISSION_STATUS_LIST.final_version_review || hasAtLeastOneOpenIssue)
    ]);

    // can complete review if all of the following conditions are true: 
    //   1.) user has proper permissions;
    //   2.) user is NOT primary reviewer and is NOT admin; 
    //   3.) if DQ document, process needs to be complete; 
    //   4.) current review is not completed 
    //   5.) submission is not archived 
    //   6.) send feedback button is not being rendered 
    const renderCompleteReviewButton = allTrue([
        userCompleteReviewPerm,
        (!isUserPrimaryReviewer && !isUserAdmin),
        (isDQDocument ? !isDQIncomplete : true),
        currentReview?.completed === false,
        !submission.archived,
        !renderSendFeedbackButton
    ]);

    // can archive if 
    //   1.) submission is not archived 
    //   2.) submission has finished being uploaded successfully  
    //   3.) user is the submitter or Contract admin/System Admin
    //   4.) submission status is awaiting review or awaiting final review  
    const renderArchiveButton = allTrue([
        submission.archived === false,
        (uploadStatus === SUBMISSION_STATUS_SUCCESS),
        (isUserSubmitter || isUserAdmin),
        (submission.status === SUBMISSION_STATUS_LIST.review || submission.status === SUBMISSION_STATUS_LIST.final_version_review)
    ]);

    // can de-archive if 
    //   1.) submission IS archived 
    //   2.) submission status is archived 
    //   3.) user is plan admin 
    const renderDeArchiveButton = allTrue([
        submission.archived === true, 
        submission.status === SUBMISSION_STATUS_LIST.archive,
        isUserAdmin
    ]);

    
    const showConfirmComplete = () => {
        confirm({
            title: "Are you completely finished reviewing this submission? Once you click on Complete Review you can't create new issues.",
            okText: "Yes, complete my review",
            onOk: async () => {
                await handleMutation(
                    modifyReview({
                        variables: {
                            id: currentReview.id,
                            completed: true,
                            submissionId: submission.id,
                            notify: true
                        }
                    })
                );
                await refetch();
            }
        });
    };

    const showConfirmRescind = (isUserAdmin = false, isUserPrimaryReviewer = false) => {
        confirm({
            title: "Are you certain you want to rescind this submission?",
            okText: isUserAdmin || isUserPrimaryReviewer ? "Yes, rescind with issue" : "Yes, rescind",
            onOk: isUserAdmin || isUserPrimaryReviewer
                ? () => setRescindWithIssueFlag(true)
                : async () => {
                    const updateSubmission = {
                        id: submission.id,
                        status: SUBMISSION_STATUS_LIST.version,
                        action: NOTIFICATION_TYPES.SUBMISSION_RESCINDED
                    };

                    await handleMutation(
                        modifySubmission({
                            variables: {
                                updateSubmission
                            }
                        })
                    );
                    await refetch();
                }
        });
    };

    const hasPendingOrFailedFeedbackDocuments = (activeIssues) => {
        for (const issue of activeIssues) {
            const pendingFeedbackDocuments = issue?.feedbackDocuments?.filter?.(({ S3UploadStatus }) => S3UploadStatus !== S3_UPLOAD_STATUS.uploaded) ?? [];
            if (pendingFeedbackDocuments.length > 0) {
                return true;
            }
        }
        return false;
    };

    const showSelectAll = () => {
        const issueIds = getActiveIssue.map(({ id }) => id);

        const newModifiedObj = {
            ...modified,
            feedbackIssues: issueIds
        };

        issueIds.forEach(id => {
            if (id in newModifiedObj) {
                newModifiedObj[id] = [];
            }
        });

        setModified(newModifiedObj);
        setIndeterminateIssues(false);
        setCheckAllIssues(true);
        setCheckAllComments(false);
        setIndeterminateComments(false);
    };

    const completionButton = (
        <span className="spaceAbove-sm">
            <Button
                style={{ display: renderCompleteReviewButton ? "inherit" : "none" }}
                type="primary"
                onClick={() => showConfirmComplete()}
            >
                Complete Review
            </Button>
            {error ? null : <Button
                style={{ display: (renderArchiveButton || renderDeArchiveButton) ? "inherit" : "none" }}
                className="ownLineArchiveSubmission"
                loading={loading}
                type="primary"
                onClick={() => setArchiveModalMode("submission")}
            >
                {renderDeArchiveButton ? "De-Archive " : "Archive "} Submission
            </Button>}
            {error ? null : <Button
                style={{ display: (renderArchiveButton) ? "inherit" : "none" }}
                className="ownLineArchiveSubmission"
                loading={loading}
                type="primary"
                onClick={() => setArchiveModalMode("version")}
            >
                Archive Submission Version
            </Button>}
        </span>
    );

    const sendFeedBackButton = (
        <>
            <Button
                style={{ display: renderSendFeedbackButton ? "inherit" : "none" }}
                className="ownLine"
                type="primary"
                onClick={() => {
                    const shouldAllowSendFeedback = !hasPendingOrFailedFeedbackDocuments(getActiveIssue) || isDQIncomplete;
                    if (shouldAllowSendFeedback) {
                        // show the send feedback modal and select all issues by default :: 
                        showSelectAll();
                        setFeedbackVis(true);
                    } else {
                        // instead show warning about at least one issue with pending or failed feedback documents :: 
                        warning({
                            title: "Warning message",
                            content: FEEDBACK_DOCUMENTS_BLOCKING_SEND_FEEDBACK_MESSAGE
                        });
                    }
                }}
            >
                Send Feedback to Organization
            </Button>
            <Modal
                title="Send Feedback"
                open={feedbackVis}
                destroyOnClose={true}
                onOk={async (e) => {
                    e.preventDefault();

                    if (!skipFinalVersionRequest && modified.stance === SUBMISSION_STATUS_LIST.final_requested) {
                        // mark all isuue to be closed

                        const currentVersion = submission.submissionNewestVersion.specifier;
                        const issueList = submission.issues
                            .filter(({ status, raisedInReview }) => (status !== ISSUE_STATUS_LIST.reject) && currentVersion === (raisedInReview.submissionVersion.specifier))
                            .map(({ id }) => {
                                return {
                                    id,
                                    status: ISSUE_STATUS_LIST.close,
                                    submissionId: submission.id,
                                    readyForReview: false
                                };
                            });


                        if (issueList && issueList.length > 0) {
                            await handleMutation(
                                modifyIssues({
                                    variables: {
                                        newIssues: issueList,
                                        notify: false
                                    }
                                })
                            );
                        }
                    } else if (Array.isArray(modified.feedbackIssues)) {

                        // mark all issue to sent for review = true

                        const reviewIssueList = modified.feedbackIssues.map((id) => {
                            //update comments -- do not "await"
                            if (Array.isArray(modified[id])) {
                                const mutations = modified[id].map((commentId) => {
                                    const newComment = {
                                        readyForReview: true
                                    };
                                    return modifyComment({
                                        variables: {
                                            id: commentId,
                                            newComment
                                        }
                                    });
                                });
                                handleMutations(mutations);
                            }
                            return {
                                id,
                                submissionId: submission.id,
                                readyForReview: true
                            };
                        });

                        //mark remaining issue to be Rejected
                        const rejectedIssueList = getActiveIssue
                            .filter(({ id }) => {
                                return modified.feedbackIssues.every((issueId) => issueId !== id);
                            }).map((issue) => {
                                return {
                                    id: issue.id,
                                    submissionId: submission.id,
                                    status: ISSUE_STATUS_LIST.reject
                                };
                            });

                        const totalIssue = [...rejectedIssueList, ...reviewIssueList];
                        if (totalIssue && totalIssue.length > 0) {
                            await handleMutation(
                                modifyIssues({
                                    variables: {
                                        newIssues: totalIssue,
                                        notify: false
                                    }
                                })
                            );
                        }
                    }

                    const updateSubmission = {
                        id: submission.id,
                        status: skipFinalVersionRequest ? SUBMISSION_STATUS_LIST.version : modified.stance,
                        dqStatus: isDQIncomplete ? DOCUMENT_DQ_STATUS_LIST.Cancel : null,
                        disposition: modified.disposition ? modified.disposition : modified.dispositionNoIssue,
                        action: NOTIFICATION_TYPES.FEEDBACK_SENT
                    };

                    await handleMutation(
                        modifySubmission({
                            variables: {
                                updateSubmission
                            }
                        })
                    );

                    await refetch();
                    setFeedbackVis(false);
                    setModified({});
                    setCheckAllIssues(false);
                    setIndeterminateIssues(false);
                    setIndeterminateComments(false);
                    setCheckAllComments(false);
                    setRefetchDocumentsFlag(true);
                }}
                okText="Send"
                okButtonProps={{
                    disabled: sendFeedbackModalButtonDisabled(modified, hasAtLeastOneOpenIssue)
                }}
                afterClose={() => {
                    setModified({});
                    setCheckAllIssues(false);
                    setIndeterminateIssues(false);
                    setIndeterminateComments(false);
                    setCheckAllComments(false);
                }}
                onCancel={() => {
                    setModified({});
                    setFeedbackVis(false);
                    setCheckAllIssues(false);
                    setIndeterminateIssues(false);
                    setIndeterminateComments(false);
                    setCheckAllComments(false);
                }}
            >
                <div style={{ marginBottom: "20px", display: isDQIncomplete ? "inherit" : "none" }}>
                    <span style={{ color: "red" }}>Please Note</span><span> - <b> The Submisison is awaiting Data Quality (DQ) Feedback ,sending feedback will be cancelling DQ Feedback</b>. </span>
                </div>
                <div style={{ marginBottom: "20px" }}>
                    <span style={{ color: "red" }}>Important Note</span><span> - When you complete the feedback process, all pending reviews will be marked complete for this version, and the submission will be moved out of review. </span>
                </div>
                <SelectableDetail
                    title="Stance"
                    passedKey="stance"
                    multiple={false}
                    value={skipFinalVersionRequest ? SUBMISSION_STATUS_LIST.version : modified.stance}
                    readOnly={skipFinalVersionRequest}
                    onValueUpdated={(value) => {
                        setModified({
                            ...modified,
                            stance: value,
                            dispositionNoIssue: undefined,
                            disposition: undefined
                        });
                    }}
                    options={[
                        {
                            text: isPackagedSubmission ? "All documents are acceptable as is" : "This document is acceptable as it is",
                            value: SUBMISSION_STATUS_LIST.final_requested,
                            id: "final_requested"
                        },
                        {
                            text: isPackagedSubmission ? "One or more documents need revision" : "This document needs revision",
                            value: SUBMISSION_STATUS_LIST.version,
                            id: "revision"
                        }
                    ]}
                />

                {modified.stance === SUBMISSION_STATUS_LIST.final_requested && (
                    hasAtLeastOneOpenIssue
                        ? <span> There is at least one active issue for this submission. All issues must be closed or rejected before you can mark this submission as acceptable. </span>
                        : <>
                            <SelectableDetail
                                title="Disposition"
                                passedKey="dispositionNoIssue"
                                multiple={false}
                                value={modified.dispositionNoIssue}
                                onValueUpdated={(value) => {
                                    setModified({
                                        ...modified,
                                        dispositionNoIssue: value
                                    });
                                }}
                                options={[
                                    {
                                        text: getDispositionOption(DISPOSITION_OPTIONS.NO_ISSUE, "text") ,
                                        value: getDispositionOption(DISPOSITION_OPTIONS.NO_ISSUE, "text"),
                                        id: getDispositionOption(DISPOSITION_OPTIONS.NO_ISSUE, "id")
                                    }
                                ]}
                            />
                        </>
                )}

                {(skipFinalVersionRequest || modified.stance === SUBMISSION_STATUS_LIST.version) && (
                    !hasAtLeastOneOpenIssue
                        ? <span> There are no active issues for this submission. You must create at least one issue for this submission in order to mark it for revision. </span>
                        : <div>
                            <SelectableDetail
                                title="Disposition"
                                passedKey="disposition"
                                multiple={false}
                                value={modified.disposition}
                                onValueUpdated={(value) => {
                                    setModified({
                                        ...modified,
                                        disposition: value
                                    });
                                }}
                                options={[
                                    {
                                        text: getDispositionOption(DISPOSITION_OPTIONS.SIGNIFICANT_ISSUE, "text"),
                                        value: getDispositionOption(DISPOSITION_OPTIONS.SIGNIFICANT_ISSUE, "text"),
                                        id: getDispositionOption(DISPOSITION_OPTIONS.SIGNIFICANT_ISSUE, "id")
                                    },
                                    {
                                        text: getDispositionOption(DISPOSITION_OPTIONS.ISSUE, "text"),
                                        value: getDispositionOption(DISPOSITION_OPTIONS.ISSUE, "text"),
                                        id: getDispositionOption(DISPOSITION_OPTIONS.ISSUE, "text")
                                    }
                                ]}
                            />

                            {getActiveIssue.length > 0 && (
                                <>
                                    <h4 style={{ display: "flex" }} className="spaceAbove-sm">
                                        Select the issues and comments to send back to the organization:
                                    </h4>
                                    <Checkbox
                                        key="selectAllIssues"
                                        indeterminate={indeterminateIssues}
                                        onChange={(e) => {

                                            const issueIds = getActiveIssue.map(({ id }) => id);

                                            const newModifiedObj = {
                                                ...modified,
                                                feedbackIssues: e.target.checked ? issueIds : []
                                            };

                                            issueIds.forEach(id => {
                                                if (id in newModifiedObj) {
                                                    newModifiedObj[id] = [];
                                                }
                                            });

                                            setModified(newModifiedObj);
                                            setIndeterminateIssues(false);
                                            setCheckAllIssues(e.target.checked);
                                            setCheckAllComments(false);
                                            setIndeterminateComments(false);
                                        }}
                                        checked={checkAllIssues}>
                                        Select all Issues
                                    </Checkbox>
                                    {checkAllIssues && displaySelectAllCommentsCheckbox(getActiveIssue) && <Checkbox
                                        key="selectAllComments"
                                        indeterminate={indeterminateComments}
                                        onChange={e => {
                                            const newModifiedObj = { ...modified };
                                            if (e.target.checked) {
                                                getActiveIssue.forEach(({ id, comments = [] }) => {
                                                    newModifiedObj[id] = comments.map(({ id }) => id);
                                                });
                                            } else {
                                                getActiveIssue.forEach(({ id }) => {
                                                    newModifiedObj[id] = [];
                                                });
                                            }
                                            setModified(newModifiedObj);
                                            setIndeterminateComments(false);
                                            setCheckAllComments(e.target.checked);
                                        }}
                                        checked={checkAllComments}
                                    >
                                        Select all comments
                                    </Checkbox>}
                                    <Divider style={{ margin: "10px 0px" }} />

                                    <Checkbox.Group
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            paddingBottom: "10px",
                                            maxHeight: "500px",
                                            overflowY: "auto"
                                        }}
                                        value={modified.feedbackIssues}
                                        onChange={e => {
                                            const newModifiedObj = {
                                                ...modified,
                                                feedbackIssues: e
                                            };

                                            getActiveIssue.forEach(({ id }) => {
                                                if (id in newModifiedObj && !e.includes(id)) {
                                                    newModifiedObj[id] = [];
                                                }
                                            });

                                            const commentsSelected = countSelectedComments(newModifiedObj);
                                            const allCommentsChecked = commentsSelected === countTotalComments(getActiveIssue);
                                            const commentsIndeterminate = commentsSelected > 0 && !allCommentsChecked;
                                            setModified(newModifiedObj);
                                            setCheckAllComments(allCommentsChecked);
                                            setIndeterminateComments(commentsIndeterminate);
                                            setIndeterminateIssues(!!e.length && e.length < getActiveIssue.length);
                                            setCheckAllIssues(e.length === getActiveIssue.length);
                                        }}
                                    >
                                        {getActiveIssue
                                            .map(({ id, reason, comments, specifier, feedbackDocuments }) => {
                                                const activeFeedbackDocument = feedbackDocuments?.filter?.(({ S3UploadStatus }) => S3UploadStatus === S3_UPLOAD_STATUS.uploaded) ?? [];
                                                const hasActiveFeedbackDocument = activeFeedbackDocument.length > 0;
                                                return (
                                                    <div key={`outer-div-issues-${id}`}>
                                                        <Checkbox
                                                            value={id}
                                                            style={{
                                                                margin: "10px 0px 0px 0px"
                                                            }}
                                                            onChange={(e) => {
                                                                let commentIds = [];
                                                                let unsetCommentIds = [];

                                                                if (e.target.checked) {
                                                                    commentIds[id] = comments.map(({ id }) => id);
                                                                    setModified({
                                                                        ...modified,
                                                                        ...commentIds
                                                                    });
                                                                } else {
                                                                    unsetCommentIds[id] = [];
                                                                    setModified({
                                                                        ...modified,
                                                                        ...unsetCommentIds
                                                                    });
                                                                }

                                                            }}
                                                        >
                                                            {`${specifier}.) ${reason}`} {hasActiveFeedbackDocument && <PaperClipOutlined />}
                                                        </Checkbox>
                                                        <div>
                                                            <Checkbox.Group
                                                                style={{ margin: "6px 0px 0px 30px" }}
                                                                value={modified[id]}
                                                                onChange={(e) => {
                                                                    const newModifiedObj = {
                                                                        ...modified,
                                                                        [id]: e
                                                                    };
                                                                    const commentsSelected = countSelectedComments(newModifiedObj);
                                                                    const allCommentsChecked = commentsSelected === countTotalComments(getActiveIssue);
                                                                    const commentsIndeterminate = commentsSelected > 0 && !allCommentsChecked;
                                                                    setCheckAllComments(allCommentsChecked);
                                                                    setIndeterminateComments(commentsIndeterminate);
                                                                    setModified(newModifiedObj);
                                                                }}
                                                            >
                                                                {comments.filter(comment => !comment.isCorrectionNote && modified?.feedbackIssues?.includes?.(id)).map(({ id, content }) => {
                                                                    return (
                                                                        <div key={`inner-div-comments-${id}`}>
                                                                            <Checkbox
                                                                                style={{
                                                                                    margin: "0px"
                                                                                }}
                                                                                value={id}
                                                                            >
                                                                                {content}
                                                                            </Checkbox>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </Checkbox.Group>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </Checkbox.Group>
                                </>
                            )}
                        </div>
                )}
            </Modal>
        </>
    );

    const rescindButton = (
        <Button
            style={{
                display: ((userReviewerFeedBackPerm || userPermFinalizeSubmission) && (submission.status === SUBMISSION_STATUS_LIST.final)) ? "inherit" : "none",
                float: "right",
                marginLeft: "20px"
            }}
            className="ownLine"
            type="primary"
            onClick={() => showConfirmRescind(isUserAdmin, isUserPrimaryReviewer)}
        >
            Rescind Submission
        </Button>
    );

    const finalizeSubmissionButton = (
        <>
            <Button
                style={{
                    float: "right",
                    marginLeft: "20px",
                    display: (!hasAtLeastOneOpenIssue && !dqProcess) ? "inherit" : "none"
                }}
                onClick={() => {
                    setShowSubmissionAccept(true);
                }}
                type="primary"
            >
                Finalize Submission
            </Button>
            <Modal
                title="Finalize Submission"
                open={submissionCanBeFinalized && showSubmissionAccept}
                onOk={async () => {
                    setShowSubmissionAccept(false);
                    const updateSubmission = {
                        id: submission.id,
                        status: SUBMISSION_STATUS_LIST.final,
                        finalized: true,
                        dqStatus: isDQIncomplete ? DOCUMENT_DQ_STATUS_LIST.Cancel : null,
                        action: NOTIFICATION_TYPES.SUBMISSION_FINALIZED
                    };

                    await handleMutation(
                        modifySubmission({
                            variables: {
                                updateSubmission
                            }
                        })
                    );

                    await refetch();
                    setRefetchDocumentsFlag(true);

                    setShowSubmissionAccept(false);
                }}
                okText="Finalize Submission"
                okType="danger"
                onCancel={() => setShowSubmissionAccept(false)}
            >
                <>
                    {isDQIncomplete
                        ? <div><span style={{ color: "red" }}>Please Note</span><span> -The Submisison is awaiting Data Quality (DQ) Feedback,finalizing will be cancelling DQ Feedback.</span>
                            <b></b> </div> : ""}
                </>
                Finalizing a submission moves the submission out of Active status, into Accepted status, and Finalizes the submission.
            </Modal>
        </>
    );

    return (
        <div>
            {submissionCanBeFinalized && finalizeSubmissionButton}
            {sendFeedBackButton}
            {completionButton}
            {rescindButton}
            {submissionCanBeFinalized && hasAtLeastOneOpenIssue &&
                <div style={{ margin: "15px 0" }}>
                    <strong style={{ color: "red" }}>Note</strong>
                    <div><p>Submission cannot be finalized because there is at least one active issue.</p></div>
                </div>
            }
            {Array.isArray(data?.archiveReasons) && data.archiveReasons.length > 0
                ? <ArchiveModal
                    mode={archiveModalMode}
                    setMode={setArchiveModalMode}
                    archiveReasonOptions={data?.archiveReasons}
                    submission={submission}
                    submitter={isUserSubmitter}
                    isDQIncomplete ={isDQIncomplete}
                    renderArchiveButton={renderArchiveButton}
                    renderDeArchiveButton={renderDeArchiveButton}
                    refetch={refetch}
                    setRefetchDocumentsFlag={setRefetchDocumentsFlag}
                />
                : null
            }
        </div>
    );
};