import React from "react";
import { DeleteTwoTone } from "@ant-design/icons";
import { Modal } from "antd";
import { useMutation } from "@apollo/client";
import { DELETE_FEEDBACK_DOCUMENT } from "../../../../constants/mutations.js";
import { handleMutation } from "../../../../utils/errorHandling.js";

export const DeleteFeedbackDocument = ({ 
    feedbackDocument, 
    actionHappening,
    setActionHappening, 
    refetch,
    issueRefetch
}) => { 
    const [deleteFeedbackDocument] = useMutation(DELETE_FEEDBACK_DOCUMENT);

    const { confirm } = Modal;

    const id = feedbackDocument?.id; 

    const handleDeletion = () => {
        const modal = confirm({
            title:
                "Are you sure you want to delete this feedback document?",
            okText: "Yes",
            onOk: async () => {
                setActionHappening(true);
                modal.update({ cancelButtonProps: { disabled: true }});
                await handleMutation(
                    deleteFeedbackDocument({
                        variables: { id }
                    })
                );
                setActionHappening(false);
                await refetch();
                if (issueRefetch) await issueRefetch();
            }
        });
    };
    



    return id && typeof id === "string" 
        ? <DeleteTwoTone 
            style={{
                fontSize: "24px"
            }}
            twoToneColor="red"
            id="feedback-delete-button"
            onClick={() => {
                if (!actionHappening) {
                    handleDeletion();
                }
            }}
        />
        : null;
};
