import React, { useState, useEffect } from "react";
import { Collapse, Spin } from "antd";
import ExportTableForm from "./form.js";

const ExportTablesCollapseMenus = ({
    data,
    report,
    index
}) => { 

    const [lastUpdatedDate, setLastUpdatedDate] = useState();
    const [exportProcessing, setExportProcessing] = useState(false);

    useEffect(() => {
        const latestRecord = data.exportTableRequestAllLogs.find(({ type }) => report === type);
        if (latestRecord) {
            setLastUpdatedDate(latestRecord.dateOfLastSuccessfulExport);
            if (latestRecord.canExport) {
                setExportProcessing(false);
            }
        }
    }, [data.exportTableRequestAllLogs]);

    const updateHeaderPanel = ({ lastExportedDate, loading }) => {
        setExportProcessing(loading);
        if (lastExportedDate) {
            setLastUpdatedDate(lastExportedDate);
        }
    };

    const extraPanelContent = () => {
        return (
            <div style={{
                fontSize: "small",
                fontWeight: 100
            }}
            onClick={(event) => {
                // If you don't want click extra trigger collapse, you can prevent this:
                event.stopPropagation();
            }}
            >
                {
                    exportProcessing ? (
                        <>
                            <span className="export-processing">Exporting... &nbsp;<Spin size="small" /></span>
                        </>
                    ) : (
                        <span>Last Exported:&nbsp;{lastUpdatedDate ?? "--"}</span>
                    )
                }
            </div>
        );
    };

    const items = [{
        key: "1",
        label: report,
        children: <ExportTableForm
            type={report}
            data={data}
            refetchExportFileStatus={data.exportTableRefetch}
            updateHeaderPanel={updateHeaderPanel}
        />,
        extra: extraPanelContent(report)
    }];

    return (
        <Collapse key={index} expandIconPosition="end" items={items} />
    );
};

export default ExportTablesCollapseMenus;