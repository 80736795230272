import React from "react";
import { Button } from "antd";
import { UndoOutlined } from "@ant-design/icons";
import { clearFiltersButtonShouldBeDisabled } from "./filterIsAtDefault.js";

export const ClearFiltersButton = ({
    clearFilters,
    styleToUse,
    filtersData 
}) => <Button 
    type="primary" 
    icon={<UndoOutlined />}
    onClick={clearFilters}
    style={styleToUse}
    disabled={clearFiltersButtonShouldBeDisabled(filtersData)}
>
    Reset Filters    
</Button>;