import React from "react";
import { Logo } from "../../Logo/index.js";
import BGImg from "../../../assets/bgimage.jpeg";

export const AuthenticationLayout = ({ children }) => {
    return (
        <div id="AuthComponent" style={{
            background: `url(${BGImg}) 100% 100% no-repeat`,
            width: "100%",
            backgroundSize: "cover",
            backgroundPosition: 0,
            height: "100vh"
        }}>
            <header>
                <div style={{
                    backgroundColor: "#153356",
                    height: "80px"
                }}>
                    &nbsp;
                </div>
                <div style={{
                    display: "flex",
                    color: "rgb(21, 51, 86)",
                    fontSize: "24px"
                }}>
                    <Logo height="170px" logoText="false" style={{
                        position: "relative",
                        bottom: "24px",
                        marginLeft: "15px"
                    }}/>
                    <div style={{
                        padding: "21px 24px 0 16px",
                        lineHeight: "22px"
                    }}>
                        <span style={{
                            display: "block",
                            fontSize: "20px"
                        }}>NC DEPARTMENT OF</span>
                        <span style={{
                            fontWeight: "700",
                            letterSpacing: "2px"
                        }}>HEALTH AND <br />HUMAN SERVICES</span>
                    </div>
                    <div style={{
                        backgroundColor: "rgb(21, 51, 86)",
                        margin: "10px",
                        width: "1px"
                    }}>&nbsp;</div>
                    <div style={{
                        flex: 1,
                        padding: "21px 2px 0 16px",
                        lineHeight: "22px"
                    }}>
                        <span style={{
                            display: "block",
                            fontWeight: "700",
                            fontSize: "30px",
                            letterSpacing: "2px"
                        }}>PCDU</span>
                        <span style={{
                            fontWeight: "200",
                            fontSize: "16px"
                        }}>PHP Contract Data Utility</span>
                    </div>
                </div>

                { children }

            </header>
        </div>

    );
};
