import React from "react";
import { useParams } from "react-router-dom";
import { OrganizationChangelogsQuery } from "./queries.js";
import { ChangelogsTable } from "./changelogs-table.js";
import { PCDULink } from "../common/PCDULink/index.js";

export const OrganizationChangelogs = (({ 
    actions,
    roles,
    subjectData,
    setSubjectData 
}) => {
    const params = useParams();
    const currentPageSubjectId = params.id;
    const subjectBreadcrumb = {
        label: "Organization Changelogs",
        path: "/changelogs/organization"
    };

    return (
        <div style={{minWidth: "900px"}}>
            <ChangelogsTable 
                subjectBreadcrumb={subjectBreadcrumb}
                subjectData={subjectData}
                setSubjectData={setSubjectData}
                filterOptions={{
                    actions,
                    roles
                }}
                exportFilename={"Organization_Changelogs.csv"}
                query={OrganizationChangelogsQuery}
                subjectHeader={{label: "Organization", key: "specifier" }}
                subjectColumn={
                    {
                        title: "Organization",
                        dataIndex: ["subject", "specifier"],
                        render: (highlightableText, { subject }) => {
                            return subject 
                                ? (<>
                                    <PCDULink 
                                        to={`/organizations/${subject.id}`}
                                        newBreadcrumbs={[ 
                                            { label: "Changelogs", path: "/changelogs" }, 
                                            subjectBreadcrumb
                                        ]}
                                    > 
                                        {highlightableText} 
                                    </PCDULink> 
                                    { currentPageSubjectId !== subject.id && 
                                        <div>
                                            <span>
                                                <PCDULink to={`/changelogs/organization/${subject.id}`}> Changelogs </PCDULink>
                                            </span>
                                        </div> 
                                    }
                                </>)
                                : null;
                        }
                    }
                }
            />
        </div>
    );
});