import React, { useState, useContext } from "react";
import { Button, Modal } from "antd";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { UserContext } from "../Application/UserContext.js";
import { LoadingContent } from "../common/LoadingContent/index.js";
import { Main } from "../common/Main/index.js";
import { HeaderDetail } from "../common/HeaderDetail/index.js";
import { isUndefinedOrNull } from "../../utils/functions.js";

import {
    MODIFY_ISSUES,
    CREATE_REACTIONS 
} from "../../constants/mutations.js";

import {
    ISSUE_STATUS_LIST,
    ISSUE_REACTION,
    SUBMISSION_STATUS_LIST,
    S3_UPLOAD_STATUS,
    FEEDBACK_DOCUMENT_BLOCKING_SEND_FEEDBACK_MESSAGE
} from "../../constants/index.js";

import { PCDULink } from "../common/PCDULink/index.js";
import { NoContent } from "../common/NoContent/index.js";
import { handleGraphQLError, handleMutation } from "../../utils/errorHandling.js";
import { DisagreeModal } from "../common/DisagreeModal/index.js";
import { CommentsList } from "../common/CommentsList/index.js";
import { TextAreaDetail } from "../common/TextAreaDetail/index.js";
import { issueDetailQuery } from "./query.js";
import { FeedbackDocumentsTable } from "./FeedbackDocumentsTable/index.js";
import { RelatedDocumentsTable } from "./relatedDocumentsTable.js";
import { ReactionsTable } from "./reactionsTable.js";

const NO_EDITING_MESSAGE_TEXT = "You cannot change your stance on this issue, and the issue cannot be edited."; 

const NO_EDITING_REASON_TEXT = {
    inactive: "This issue has been closed or rejected.",
    newVersionRequested: "Another version of the submission has been requested."
};

const { confirm, warning } = Modal;

const makeNoEditingMessage = (reason) => {
    if (!(reason in NO_EDITING_REASON_TEXT)) {
        return null;
    } else {        
        const message = `${NO_EDITING_REASON_TEXT[reason]} ${NO_EDITING_MESSAGE_TEXT}`;
        return (<div><p style={{fontSize: "16px", marginTop: "15px", color: "red"}}> { message } </p></div>);
    }
};

export const IssueDetail = (() => {
    const {
        userPermRejectnCloseIssue,
        userPermSelectPersonalStance,
        userIsSubmitter,
        userIsAdmin,
        userId
    } = useContext(UserContext);

    const [rejecting, setRejecting] = useState(false);
    const [disagreedIssue, disagreeWithIssue] = useState(null);
    const [editableIssueReason, setEditableIssueReason] = useState(null);
    const [editingReason, setEditingReason] = useState(false);
    const [createReactions] = useMutation(CREATE_REACTIONS);
    const [modifyIssues] = useMutation(MODIFY_ISSUES);

    const params = useParams();

    const { loading, error, data, refetch } = useQuery(
        issueDetailQuery, 
        {
            variables: {
                id: params.id
            },
            fetchPolicy: "no-cache"
        }
    );

    if (error) {
        return handleGraphQLError(error);
    }

    if (loading) {
        return <LoadingContent />;
    }

    if (isUndefinedOrNull(data.issue)) {
        return <NoContent />;
    }
    
    const { issue } = data;
    const userCreatedThisIssue = issue?.raisedInReview?.reviewer?.id === userId;
    const userCanRejectnCloseIssue = userPermRejectnCloseIssue(issue?.submission.obligation.submissionType.id);
    const userCanSelectPersonalStance = userPermSelectPersonalStance(issue?.submission.obligation.submissionType.id);
    const submissionUnderReview = issue?.submission?.status === SUBMISSION_STATUS_LIST.review || issue?.submission?.status === SUBMISSION_STATUS_LIST.final_version_review; 
    const issueIsActive = issue.status === ISSUE_STATUS_LIST.active;
    const noEditingReason = !issueIsActive ? "inactive" : (!submissionUnderReview ? "newVersionRequested" : "");
    const newBreadcrumbs = [
        {
            label: issue?.submission?.specifier,
            path: `/submissions/${issue?.submission?.id}`,
            tooltip: `Submission: ${issue?.submission?.specifier}`
        },
        { 
            label: `Issue - ${issue.specifier}`,
            path: `/submissions/${issue?.submission?.id}/issues/${issue.id}`,
            tooltip: `Submission ${issue?.submission?.specifier}: Issue ${issue.specifier}`
        }
    ];

    // comments can be edited only when issue is active, and when user is not submitter and the submission is under review 
    const disableCommentsEditing = !issueIsActive || (!userIsSubmitter && submissionUnderReview);

    const triggerCreateReactions = async (value) => {
        const newReactions = [
            {
                issueId: issue.id,
                specifier:issue.specifier,
                disposition: value
            }
        ];

        await handleMutation(
            createReactions({
                variables: {
                    newReactions
                }
            })
        );

        refetch();
    };

    const personalStance = Array.isArray(issue.reactions) ?
        (issue.reactions.map(({ reviewerId }) => reviewerId)
            .includes(userId) ?
            issue.reactions.find(({ reviewerId }) => reviewerId === userId).disposition :
            "None"
        ) :
        "None";
    
    const warningMessage = () => {
        return (
            warning({
                title: "Warning message",
                content: FEEDBACK_DOCUMENT_BLOCKING_SEND_FEEDBACK_MESSAGE 
            })
        );
    };

    const issueHasFailedFeedbackDoc = () => {
        if (issue.feedbackDocuments.length > 0) {
            const activeIssue = issue.feedbackDocuments.find(({ active }) => active === true);
            if (
                activeIssue &&
                ((activeIssue["S3UploadStatus"] === S3_UPLOAD_STATUS.error) ||
                    (activeIssue["S3UploadStatus"] === S3_UPLOAD_STATUS.uploading))
            ) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    };

    const confirmAction = (verb) => {
        if (issueHasFailedFeedbackDoc()) {
            warningMessage();
        } else {
            confirm({
                title:
                    `Are you sure you want to ${verb} this issue?`,
                okText: "Yes",
                onOk: async () => {
                    await handleMutation(
                        modifyIssues({
                            variables: {
                                newIssues: [{
                                    id: issue.id,
                                    submissionId: issue?.submission?.id,
                                    status: ISSUE_STATUS_LIST.close
                                }],
                                notify: true
                            }
                        })
                    );

                    refetch();
                }
            });
        }
    };

    const rejectAction = (verb) => {
        if (issueHasFailedFeedbackDoc()) {
            warningMessage();
        } else {
            confirm({
                title:
                `Are you sure you want to ${verb} this issue?`,
                okText: "Yes",
                onOk: async () => {
                    setRejecting(true);
                    disagreeWithIssue(issue);
                }
            });
        }
    };

    const editingReasonContent = (
        <div style={{ marginTop: "-10px" }}>
            <div style={{ height: "30px" }}> 
                { !editingReason && <div style={{ marginTop: "15px" }}>
                    <Button 
                        size="small"
                        type="primary"
                        disabled={ !submissionUnderReview }
                        onClick={(e) => {
                            e.preventDefault();
                            setEditingReason(true);
                        }}
                    > 
                        Edit 
                    </Button>
                </div>
                }
            </div> 
            { editingReason && 
                <>
                    <TextAreaDetail 
                        title="New Reason for Issue"
                        strict={true}
                        value={editableIssueReason ?? issue.reason}
                        onValueUpdated={(e) => {
                            setEditableIssueReason(e.target.value);
                        }}
                    /> 
                    <div style={{ display: "flex", justifyContent: "space-between", width: "130px" }}> 
                        <Button 
                            size="small"
                            type="ghost"
                            onClick={(e) => {
                                e.preventDefault();
                                setEditableIssueReason(null);
                                setEditingReason(false);
                            }}
                        > 
                            Cancel 
                        </Button> 
                        { editableIssueReason && 
                            <Button 
                                size="small"
                                type="primary" 
                                onClick={async (e) => {
                                    e.preventDefault();
                                    await handleMutation(
                                        modifyIssues({
                                            variables: {
                                                newIssues: [{
                                                    id: issue.id,
                                                    submissionId: issue?.submission?.id,
                                                    reason: editableIssueReason
                                                }], 
                                                notify: true 
                                            }
                                        })
                                    );
                                    await refetch();
                                    setEditingReason(false);
                                    setEditableIssueReason(null);
                                }}
                            > 
                                Save  
                            </Button>
                        }
                    </div>
                </>
            }
        </div>
    );

    const details = (
        <div id="details">
            <HeaderDetail
                title="Issue Status"
                text={issue.status}
            />
            { !editingReason && <HeaderDetail
                title="Reason"
                text={issue.reason}
                textClassName="text-white-space"
            />}
            {issue.severity && <HeaderDetail
                title="Severity"
                text={issue.severity}
            />}
            { userCreatedThisIssue && issueIsActive && editingReasonContent }
            <HeaderDetail
                title="Creator"
                to={`/users/${issue?.raisedInReview.reviewer.id}`}
                linkTitle={issue?.raisedInReview.reviewer.name}
                newBreadcrumbs={newBreadcrumbs}
            />
            <HeaderDetail
                title="Submission"
                to={`/submissions/${issue?.submission.id}`}
                linkTitle={issue?.submission.specifier}
                newBreadcrumbs={newBreadcrumbs}
            />
            <HeaderDetail
                title="Submission Status"
                text={issue?.submission.status}
            />
            <HeaderDetail
                title="Submission Organization"
                to={`/organizations/${issue?.submission.submitter.id}`}
                linkTitle={issue?.submission.submitter.name}
                newBreadcrumbs={newBreadcrumbs}
            />
            <HeaderDetail
                title="Submission Type"
                to={`/submission-types/${issue?.submission.obligation.submissionType.id}`}
                linkTitle={issue?.submission.obligation.submissionType.specifier}
                newBreadcrumbs={newBreadcrumbs}
            />
            <HeaderDetail
                title=" Raised in Version"
                text={`Version ${issue.raisedInReview.submissionVersion.specifier + 1}`}
            />
            { personalStance && typeof personalStance === "string" && 
                <HeaderDetail
                    title="Personal Stance"
                    text={personalStance.toUpperCase()}
                />
            }
            { issueIsActive && 
                <> 
                    <br />
                    <div
                        style={{ paddingTop: "10px", display: userCanSelectPersonalStance || userIsAdmin ? "inherit" : "none" }}
                    >
                        <h3> Change Personal Stance </h3>
                        <Button
                            size="small"
                            onClick={(e) => {
                                e.preventDefault();
                                triggerCreateReactions(ISSUE_REACTION.agree);
                            }}
                            disabled={personalStance === ISSUE_REACTION.agree || !submissionUnderReview}
                        >
                            Agree
                        </Button>
                        <Button
                            size="small"
                            className="spaceBetween-md"
                            onClick={(e) => {
                                e.preventDefault();
                                disagreeWithIssue(issue);
                            }}
                            disabled={personalStance === ISSUE_REACTION.disagree || !submissionUnderReview}
                        >
                            Disagree
                        </Button>
                    </div>
                    <div style={{ paddingTop: "15px", display: "flex" }}>
                        <div style={{ display: userCanRejectnCloseIssue || userIsAdmin ? "inherit" : "none" }}>
                            <Button
                                className="buttonLine"
                                size="small"
                                type="primary"
                                onClick={() => {
                                    confirmAction("close");
                                }}
                                disabled={!submissionUnderReview}
                            >
                            Mark Issue as Closed
                            </Button>
                        </div> 
                        <div
                            style={{ display: userCanRejectnCloseIssue || userIsAdmin ? "inherit" : "none" }}
                        > 
                            <Button
                                className="buttonLine"
                                size="small"
                                type="danger"
                                onClick={() => {
                                    rejectAction("reject");
                                }}
                                disabled={!submissionUnderReview}
                            >
                            Reject Issue
                            </Button>
                        </div>
                    </div>
                </> 
            }
            { (userCanRejectnCloseIssue || userIsAdmin || userCreatedThisIssue) && noEditingReason && makeNoEditingMessage(noEditingReason) }
        </div>
    );

    return (
        <Main
            title={`Issue - ${issue.specifier}`}
            details={details}
            breadcrumbs={[
                <PCDULink to="/submissions" tooltip="All Submissions">Submissions</PCDULink>,
                <PCDULink 
                    to={`/submissions/${issue.submission.id}`} 
                    tooltip={`Submission: ${issue.submission.specifier}`}
                >
                    {issue.submission.specifier}
                </PCDULink>,
                `Issue - ${issue.specifier}`
            ]}
        >
            <FeedbackDocumentsTable 
                issueId={issue?.id}
                issueStatus={issue?.status}
                submissionStatus={issue?.submission.status}
                submissionTypeId={issue?.submission.obligation.submissionType.id}
                issueRefetch={refetch}
            />
            <RelatedDocumentsTable
                persistState
                newBreadcrumbs={newBreadcrumbs}
                loading={loading}
                issue={issue}
                refetch={refetch}
            />
            <ReactionsTable 
                persistState
                issue={issue}
                newBreadcrumbs={newBreadcrumbs}
            />
            <CommentsList
                parent={issue}
                userId={userId}
                refetch={() => refetch()}
                isUserSubmitter={userIsSubmitter}
                disableEditing={disableCommentsEditing}
            />
            <DisagreeModal 
                rejecting={rejecting}
                issues={disagreedIssue ? [disagreedIssue] : null}  
                onOk={() => {
                    disagreeWithIssue(null); 
                    setRejecting(false); 
                    refetch(); 
                }}
                onCancel={() => {
                    disagreeWithIssue(null); 
                    setRejecting(false); 
                }}
                afterClose={() => {
                    disagreeWithIssue(null); 
                    setRejecting(false); 
                }}
            />
        </Main>
    );
});
