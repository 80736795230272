import { gql } from "@apollo/client";

export const organizationsQuery = gql`
    query OrganizationsQuery (
        $limit: Int
        $offset: Int
        $nameSearch: String
        $serviceIdSearch: String
        $contactSearch: String
        $contactEmailSearch: String
        $sortOn: String
        $sortBy: String
    ) {
        organizationsList(
            limit: $limit
            offset: $offset
            nameSearch: $nameSearch
            specifierSearch: $serviceIdSearch
            primaryContactSearch:$contactSearch
            primaryContactEmailSearch: $contactEmailSearch
            sortOn: $sortOn,
            sortBy: $sortBy
        ) {
            organizations {
                id
                name
                specifier
                primaryContact {
                    id
                    name
                    email
                }
            }
            count
        }
    }
`;