import React from "react";
import { EXPORTSIZE } from "../../constants/index.js";
import { formatDate } from "../../utils/functions.js";
import { CsvExport } from "../common/CsvExport/index.js";
import { contractsQuery } from "./query.js";

const csvHeaders = [
    { label: "Contract ID", key: "contractId" },
    { label: "Contract Type", key: "contractType" },
    { label: "Submitting Organization", key: "submittingOrg" },
    { label: "Start Date", key: "startDate" },
    { label: "End Date", key: "endDate" }
];

const transformContractsDataForCSV = (data) => {
    return data.contractsList.contracts.map((contract) =>{
        return {
            contractId: contract.specifier,
            contractType: contract.contractType.name,
            submittingOrg: contract.promisor.name,
            startDate: formatDate(contract.startDate),
            endDate: formatDate(contract.endDate)
        };
    });
};

export const ContractsCsvExport = ({variables}) => {
    return <CsvExport
        csvFilename={"Contracts_Report.csv"}
        csvHeaders={csvHeaders}
        query={contractsQuery}
        variables={{...variables, offset: 0, limit: EXPORTSIZE}}
        transformData={transformContractsDataForCSV}
    />;
};