import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Result } from "antd";
import { LoadingContent } from "../common/LoadingContent/index.js";
import { Main } from "../common/Main/index.js";
import { CommentsList } from "../common/CommentsList/index.js";
import { HeaderDetail } from "../common/HeaderDetail/index.js";
import { NoContent } from "../common/NoContent/index.js";
import { SelectableDetail } from "../common/SelectableDetail/index.js";
import { SubmissionButtons } from "./submission-buttons.js";
import {
    DHHS_NAME,
    SUBMISSION_STATUS_SUCCESS,
    S3_UPLOAD_STATUS,
    SUBMISSION_STATUS_LIST, 
    ISSUE_STATUS_LIST,
    SUBMISSION_STATUS_REVIEWER,
    DOCUMENT_DQ_STATUS_LIST
} from "../../constants/index.js";
import {
    formatTimeValue,
    isUndefinedOrNull,
    formatDate
} from "../../utils/functions.js";
import { submissionDetailQuery } from "./queries.js";
import { handleGraphQLError } from "../../utils/errorHandling.js";
import { getHistoryBreadcrumbs } from "../../utils/getHistoryBreadcrumbs.js";
import { SubmissionIssues } from "./submission-issues.js";
import { CorrectionNoteModal } from "./correction-note-modal.js";
import { ExtendDueDate } from "../common/ExtendDueDate/index.js";
import { SubmissionCriteriaTable } from "./submission-criteria-table.js";
import { DocumentsTable } from "../common/DocumentsTable/index.js";
import { UserContext } from "../Application/UserContext.js";
import { IssuesTableHeader } from "../common/IssueTable/index.js";
import { UploadSubmissionDocument } from "../common/UploadSubmissionDocument/index.js";
import { SubmissionOptionsSortedByRole, getDefaultPageLink } from "../NavigationSidebar/submission-options-sorted-byrole.js";
import { DownloadButton } from "../common/DownloadButton/index.js";
import { PCDULink } from "../common/PCDULink/index.js";
import { SubmissionReviewersTable } from "./SubmissionReviewersTable.js";

const blankSpace = "     ";


export const SubmissionDetail = () => {
    const params = useParams();
    const {
        userId, 
        userIsAdmin, 
        userPermFinalizeSubmission, 
        userPermCreateSubmissionComment,
        userOrganizationName, 
        userIsSubmitter,
        userPermCreateSubmission, 
        userPermPrimaryReviewer, 
        userPermSendFeedBack,
        userPermToCompleteReview, 
        userPermViewUserAuditLog,
        userPermViewSubmissionChangeLogs,
        userPermCancelDocumentDQProcess
    } = useContext(UserContext);

    const submissionDefaultPage = getDefaultPageLink();
    const submissionDefaultText = submissionDefaultPage.props.text;
    const submissionsBreadcrumbLinkTarget = SubmissionOptionsSortedByRole()[0].key;
    const lastHistoryBreadcrumb = getHistoryBreadcrumbs(-1);

    const [refetchData, setRefetchData] = useState(false);
    const [correctionModalOpen, setCorrectionModal] = useState(false);
    const [displayedVersion, setDisplayedVersion] = useState(null);
    const [refetchDocumentsFlag, setRefetchDocumentsFlag] = useState(false);
    const [rescindWithIssueFlag, setRescindWithIssueFlag] = useState(false);

    
    const { loading, error, data, refetch } = useQuery(submissionDetailQuery, {
        variables: {
            id: params.id
        },
        fetchPolicy: "no-cache"
    });

    const submissionObject = data ? data.submission : null;
    const submissionNewestVersionId = submissionObject ? data.submission.submissionNewestVersion.id : null;
    const additionalCommentViewingPermission = submissionObject && userOrganizationName === submissionObject.submitter.name || userOrganizationName === DHHS_NAME;

    useEffect(() => {
        if (refetchData === true) {
            setDisplayedVersion(null);
            setRefetchData(false);
            refetch();
        }
    }, [refetchData]); 

    useEffect(() => {
        if (data?.submission?.status && data?.submission?.submissionNewestVersion?.id) {
            setDisplayedVersion(submissionNewestVersionId);
        }
    }, [data?.submission?.status]);

    useEffect(() => {
        if (refetchDocumentsFlag) {
            setRefetchDocumentsFlag(false);
        }
    }, [refetchDocumentsFlag]);

    if (error) {
        return handleGraphQLError(error);
    }

    if (loading) {
        return <LoadingContent />;
    }

    if (isUndefinedOrNull(data.submission)) {
        return <NoContent />;
    }

    const submissionType = data.submission?.obligation?.submissionType;
    const dqFlag = data.submission?.obligation?.submissionType.dqFlag;
    const dqProcessing = data.submission?.submissionDocuments.find(({ documentNewestVersion }) => {
        return documentNewestVersion.dqStatus === DOCUMENT_DQ_STATUS_LIST.Processing;
    });
    const isDqProcessing = (dqFlag && dqProcessing) ? true : false ;
    const isUserPrimaryReviewer = userPermPrimaryReviewer(submissionType?.id);
    const isPackagedSubmission = Boolean(submissionType?.packagedSubmission);
    const submission = data.submission ?? {
        obligation: null,
        contract: null,
        submissionDocuments: [],
        comments: []
    };

    const newBreadcrumbForLinks = {
        label: submission.specifier,
        path: `/submissions/${params.id}`,
        tooltip: `Submission: ${submission.specifier}`
    };

    const activeIssues = submission?.issues?.filter?.(({ status }) => status === ISSUE_STATUS_LIST.active) ?? [];
    const isArchived = submission?.archived ?? false;
    const submitterIssues = submission?.issues?.filter?.(({ status, readyForReview }) => status === ISSUE_STATUS_LIST.active && readyForReview);
    const userReviewerFeedBackPerm = userPermSendFeedBack(submissionType?.id);
    const userCompleteReviewPerm = userPermToCompleteReview(submissionType?.id);

    const uploadStatus = submission.uploadStatus;

    if (displayedVersion === null && Array.isArray(submission.submissionVersions)) {
        setDisplayedVersion(submissionNewestVersionId);
    }

    const selectedSubmissionVersion = submission?.submissionVersions?.find?.(version => version.id === displayedVersion);
    const criteriaList = submissionType?.allCriteria;
    const submissionVersionDate = selectedSubmissionVersion?.createdAt; 
    
    // display the active issues (latest version) detail if all the following are true: 
    //   1.) submission is not archived 
    //   2.) submission is not finalized 
    //   3.) user is not submitter OR new version is being requested 

    const showActiveIssuesDetail = !isArchived && 
        !submission.finalized && submission.status !== SUBMISSION_STATUS_LIST.final && 
        (!userIsSubmitter || [SUBMISSION_STATUS_LIST.version, SUBMISSION_STATUS_LIST.final_requested].includes(submission?.status));

    const versionType = SUBMISSION_STATUS_LIST.final_requested === data?.submission?.status ? "Final" : "New";
    
    const getSubmissionStatus = () => {
        const reviewStatus = Object.keys(SUBMISSION_STATUS_REVIEWER);
        const currentStatus = reviewStatus.find((id) => SUBMISSION_STATUS_REVIEWER[id] === submission.status);
        return (submission && (submission.status === SUBMISSION_STATUS_LIST.archive || currentStatus === undefined));
    };

    const revAssignments = submissionType?.reviewerAssignments ?? [];
    const combinedReviewers = [
        ...submission.obligation === null ? [] : revAssignments
    ];

    const details = (
        <div id="details">
            { isArchived ? <span style={{ fontSize: "16px", color: "red" }}> Archived </span> : null } 
            { (userPermViewUserAuditLog || userPermViewSubmissionChangeLogs ) && submission.id && 
                <div style={{margin: "10px 0px", fontSize: "16px"}}>
                    <PCDULink 
                        to={`/changelogs/submissions/${submission.id}`}
                        newBreadcrumbs={newBreadcrumbForLinks}
                    > 
                        Changelogs 
                    </PCDULink>
                </div>
            }
            <HeaderDetail
                title="Submission Type"
                newBreadcrumbs={newBreadcrumbForLinks}
                text=""
                to={`/submission-types/${submissionType.id}`}
                linkTitle={`${submissionType.specifier} - ${submissionType.name}`}
            />
            {(
                typeof submissionType?.description === "undefined"
                    ? "" : (submissionType?.description ? <p>
                        {submissionType.description}
                    </p> : "")
            )}
            {(
                (!submission || !submission?.submitter?.id || !submission?.submitter?.name )
                    ? <></>
                    : <HeaderDetail
                        title="Organization Name"
                        newBreadcrumbs={newBreadcrumbForLinks}
                        text=""
                        to={`/organizations/${submission.submitter.id}`}
                        linkTitle={submission.submitter.name}
                    />
            )}
            {isUndefinedOrNull(submission.timeline)
                ? <>{}</>
                : <HeaderDetail
                    title="Submission Period"
                    text={
                        `${formatDate(submission.timeline.startDate)} - ${formatDate(submission.timeline.endDate)} `
                    }
                />
            }
            { submission.createdAt === null || typeof submission.createdAt === "undefined"
                ? <></>
                : <HeaderDetail
                    title="Date Submitted"
                    text={formatTimeValue(submissionVersionDate)}
                />
            }
            { uploadStatus !== SUBMISSION_STATUS_SUCCESS 
                ? < HeaderDetail
                    title="Submission Status"
                    text={submission.uploadStatus}
                />
                : <HeaderDetail
                    title="Submission Status"
                    text={submission.status}
                />
            }
            { isArchived  
                ? <HeaderDetail
                    title="Submission Archive Reason"
                    text={submission?.archiveReason?.text ?? "None"}
                /> 
                : null 
            }
            { !submission.finalized && submissionObject?.submitterDueDate && 
                <div style={{marginBottom: "15px"}}>
                    <HeaderDetail 
                        title={`${versionType} Version Due By`}
                        text={formatDate(submissionObject.submitterDueDate)}
                    />
                    <ExtendDueDate
                        submission={submissionObject} 
                        latestSubmissionVersionId={submissionNewestVersionId}
                        refetch={()=>refetch()} 
                    />
                </div>
            }
            { !submission.finalized
                ? <></>
                : <HeaderDetail
                    title="Date Finalized"
                    text={formatTimeValue(submission.finalizedAt)}
                />
            }
            { submission.adhocDueDate === null || typeof submission.adhocDueDate === "undefined" 
                ? <></>
                : <HeaderDetail
                    title="Adhoc Due Date"
                    text={formatDate(submission.adhocDueDate)}
                />
            }
            { showActiveIssuesDetail && 
                <HeaderDetail 
                    title="Active Issues (Latest Version)"
                    text={activeIssues.length}
                />    
            }
            { uploadStatus !== SUBMISSION_STATUS_SUCCESS
                ? <></>
                : <>
                    { selectedSubmissionVersion?.archived === true  
                        ? <HeaderDetail
                            title={`Version ${parseInt(selectedSubmissionVersion.specifier) + 1} Archive Reason`}
                            text={selectedSubmissionVersion?.archiveReason?.text ?? "None"}
                        /> 
                        : null
                    }
                    <div
                        className="detail-panel spaceAbove-sm"
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "150px"
                        }}
                    >
                        <SelectableDetail
                            title="Version"
                            containerStyle={{ width: "100%" }}
                            passedKey="submission-detail-version-select"
                            options={submission.submissionVersions.map(({ id, specifier, archived }) => {
                                return {
                                    id,
                                    text: `${parseInt(specifier) + 1}${archived ? " - Archived" : ""}`,
                                    value: id
                                };
                            })}
                            value={displayedVersion}
                            onValueUpdated={(value) => {
                                setDisplayedVersion(value);
                            }}
                            refetch={() => refetch()}
                        />
                    </div>
                </> 
            }
            <SubmissionButtons
                options={{
                    submission,
                    userPermFinalizeSubmission,
                    userReviewerFeedBackPerm,
                    userPermCancelDocumentDQProcess,
                    userCompleteReviewPerm,
                    isDqProcessing,
                    getActiveIssue: activeIssues,
                    isUserAdmin: userIsAdmin,
                    isUserSubmitter: userIsSubmitter,
                    isUserPrimaryReviewer,
                    currentUserId: userId,
                    displayedVersion,
                    uploadStatus,
                    setRescindWithIssueFlag,
                    refetch: () => refetch(),
                    setRefetchDocumentsFlag
                }}
            />
        </div>
    );

    const renderUploadDocumentsButton = userPermCreateSubmission && 
        submission.status !== SUBMISSION_STATUS_LIST.archive && 
        !submission.archived;

    const uploadDocumentsButton = renderUploadDocumentsButton 
        ? <div style={{float: "right" }}>
            <UploadSubmissionDocument
                isDisable={getSubmissionStatus() ? true : false}
                newVersion={true}
                afterUpload={() => {
                    setCorrectionModal(true);
                    setRefetchDocumentsFlag(true);
                }}
                createData={
                    {
                        days: submissionType.daysToReview,
                        submissionTypeId: submissionType.id
                    }
                }
                combinedReviewers={combinedReviewers}
                buttonTitle={isPackagedSubmission ? `Upload ${versionType} Version of Document(s)` : `Upload ${versionType} Version of Document`}
                useAttestation={submissionType.useAttestation}
                documentUploadTitle={`Upload ${versionType} Version of Document(s) for ${submissionType?.name}`}
                submissionTypeData={data.submission?.obligation}
                submissionTypeVersionData={data.submission?.submissionTypeVersion}
                submissionData={data.submission}
                setRefetchData={setRefetchData}
                submissionTypeId={submissionType.id}
                refetchData={refetchData}
                maxDocumentCount={isPackagedSubmission ? 100 : 1}
            />
        </div>
        : <></>;

    const renderDownloadDocumentsButton = submission.uploadStatus === SUBMISSION_STATUS_SUCCESS;

    const downloadDocumentsButton = renderDownloadDocumentsButton
        ? <div style={{float: "right" }}>
            <DownloadButton 
                loading={loading}
                style={{ 
                    marginBottom: "5px", 
                    marginRight: renderUploadDocumentsButton ? "10px" : "0",
                    display: "block" 
                }}
                disabled={submission.submissionDocuments.length < 1}
                text={loading ? blankSpace : "Download All"}
                submissionDocumentsArray={submission.submissionDocuments}
            />
        </div>
        : <></>;

    return (
        <Main
            title={`Submission ${submission.specifier}`}
            submissionTitle={submission.specifier}
            details={details}
            breadcrumbs={[
                lastHistoryBreadcrumb?.label?.includes("Submissions") || lastHistoryBreadcrumb?.path === "/submissions/create"
                    ? null 
                    : <PCDULink 
                        to={`/submissions/${submissionsBreadcrumbLinkTarget}`}
                        tooltip={submissionDefaultText}
                    >
                        Submissions
                    </PCDULink>, 
                submission.specifier
            ]}
        >
            <SubmissionCriteriaTable 
                criteriaList={ criteriaList } 
                submissionVersionDate={ submissionVersionDate }
                latestVersionSelected={ selectedSubmissionVersion && submissionNewestVersionId && selectedSubmissionVersion.id === submissionNewestVersionId } 
                submissionStatus={ submission?.status } 
                selectedSubmissionVersion={ selectedSubmissionVersion }
            />
            <DocumentsTable 
                persistState
                submissionId={submission.id}
                forceRefetchOfDocuments={refetchDocumentsFlag}
                forceRefetchOfDocumentsSetter={setRefetchDocumentsFlag}
                afterSuccessfulUpload={refetch}
                newBreadcrumbs={newBreadcrumbForLinks}
                onStatusChangeOfDocuments={(_, currentDocuments) => {
                    // the "after upload" callback will handle switching it to Pending; this is just for when it has resolved -- 
                    // i.e., all documents are not uploading :: 
                    if (currentDocuments.every(doc => doc.S3UploadStatus !== S3_UPLOAD_STATUS.uploading)) {
                        refetch();
                    }
                }}
            /> 
            { uploadDocumentsButton }
            { downloadDocumentsButton }
            <SubmissionReviewersTable 
                userIsSubmitter={userPermCreateSubmission}
                newBreadcrumbForLinks={newBreadcrumbForLinks}
                submission={submission}
            />
            <div>
                <IssuesTableHeader />
                {
                    isUndefinedOrNull(submission.submissionNewestVersion)
                        ? <Result
                            title={"There was an error loading data for this table."}
                            status={"error"}
                        />
                        : <SubmissionIssues
                            setRefetchData={setRefetchData}
                            submission={submission}
                            newBreadcrumbs={newBreadcrumbForLinks}
                            submitterIssues={submitterIssues}
                            isPackagedSubmission={isPackagedSubmission}
                            showRescindAndContinue={rescindWithIssueFlag}
                            setShowRescindAndContinue={setRescindWithIssueFlag}
                        />
                }
            </div>
            
            { (userPermCreateSubmissionComment || additionalCommentViewingPermission) &&
                // note: need a large top margin here, otherwise it messes up the Create Issue button when signed in as contract admin :: 
                <div style={{ marginTop: "60px" }}>
                    <CommentsList
                        title="Submission Comments"
                        parent={submission}
                        userId={userId}
                        refetch={() => refetch()} 
                        isUserSubmitter={userIsSubmitter}
                        showTitleWithoutComments={userPermCreateSubmissionComment || additionalCommentViewingPermission }
                    />
                </div>
            }
            { userIsSubmitter && Array.isArray(submitterIssues) && submitterIssues.length > 0 && 
                    <CorrectionNoteModal
                        isOpen={userIsSubmitter && correctionModalOpen}
                        closeModal={() => setCorrectionModal(false)}
                        submitterIssues={submitterIssues}
                        refetch={() => setRefetchData(true)}
                        latestSubmissionVersionId={submission?.submissionNewestVersion?.id}
                    />
            }
        </Main>
    );
}; 
