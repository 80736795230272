import { message } from "antd";
import { EXPORT_WARNING } from "../../../constants/index.js";

const { success, error, warning } = message;

let isDisplayingMessage = false,
    isDisplayingError = false;

export const DEFAULT_MESSAGE_DURATION = 5;

export const ERROR_MESSAGE = "There was an error fetching data for export.";

export const resetExportNotificationState = () => {
    isDisplayingMessage = false;
    isDisplayingError = false;
};

export const handleNotification = (message, duration, type = "warning") => {
    const messageToUse = message && typeof message === "string" ? message : EXPORT_WARNING;
    const durationToUse = duration && Number.isSafeInteger(duration) ? duration : DEFAULT_MESSAGE_DURATION;
    if (type === "success") {
        success(messageToUse, durationToUse);
    } else if (type === "error" && !isDisplayingError) {
        isDisplayingError = true;
        error(messageToUse, durationToUse, () => {
            isDisplayingError = false;
        });
    } else if (!isDisplayingMessage) {
        isDisplayingMessage = true;
        warning(messageToUse, durationToUse, () => {
            isDisplayingMessage = false;
        });
    }
};
