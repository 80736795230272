export const structurePreloadedData = (initialData, latestData) => {
    const initialConstants = initialData.constants;
    const dataToUse = { ...initialData, ...latestData };
    const dhhsSpecifier = dataToUse.constants.DHHS_ORG_SPECIFIER;
    const preloadedData = {
        businessUnits: dataToUse.businessUnits.filter(Boolean),
        categories: dataToUse.categories,
        contractTypes: dataToUse.contractTypes,
        documentTypes: dataToUse.documentTypes,
        libraryAssociatedContractTypes: dataToUse.libraryAssociatedContractTypes,
        constants: {
            ALL_AUDIT_REPORT_TYPES: initialConstants.ALL_AUDIT_REPORT_TYPES,
            DHHS_ORG_SPECIFIER: initialConstants.DHHS_ORG_SPECIFIER,
            STATUS_FILTERS_BY_SUBMISSION_TAB: initialConstants.STATUS_FILTERS_BY_SUBMISSION_TAB,
            INVALID_SPECIFIER_CHARACTERS: initialConstants.INVALID_SPECIFIER_CHARACTERS,
            VALID_FIRST_POSITION_SPECIFIER_CHARACTERS: initialConstants.VALID_FIRST_POSITION_SPECIFIER_CHARACTERS,
            CHANGELOG_ACTIONS: initialConstants.CHANGELOG_ACTIONS,
            CHANGELOG_ROLE_SPECIFIERS: initialConstants.CHANGELOG_ROLE_SPECIFIERS,
            DOCUMENT_DQ_STATUS_DESCRIPTIONS: initialConstants.DOCUMENT_DQ_STATUS_DESCRIPTIONS,
            ISSUE_STATUS_LIST: [ ...initialConstants.ISSUE_STATUS_LIST],
            REPORT_TEMPLATE_VERSIONS: [ ...initialConstants.REPORT_TEMPLATE_VERSIONS],
            FREQUENCY_OPTIONS: [ ...initialConstants.FREQUENCY_OPTIONS],
            SUBMISSION_STATUS_LIST: [ ...initialConstants.SUBMISSION_STATUS_LIST],
            DISPOSITION_OPTIONS: [ ...initialConstants.DISPOSITION_OPTIONS],
            DAY_TYPE: [ ...initialConstants.DAY_TYPE],
            // these constants may potentially change :: 
            DAYS_TO_RESUBMIT: [ ...dataToUse.constants.DAYS_TO_RESUBMIT ],
            DAYS_TO_REVIEW: [ ...dataToUse.constants.DAYS_TO_REVIEW ]
        },
        libraryBaseFolder: dataToUse.libraryBaseFolder,
        organizations: dataToUse.organizationsList.organizations,
        primaryReviewers: dataToUse.primaryReviewers,
        submitterOrganizations: dataToUse.organizationsList.organizations.filter(org => org.specifier !== dhhsSpecifier),
        submissionTypes: dataToUse.submissionTypes,
        user: dataToUse.user
    };
    Object.freeze(preloadedData);
    return preloadedData;
};