import { gql } from "@apollo/client";

export const FilterDomainOptionsQuery = gql`
    query FilterDomainOptionsQuery {
        constants {
            CHANGELOG_ACTIONS
        }
        roles {
            id 
            specifier 
        }
    }
`;

export const SubmissionChangelogsQuery = gql`
    query SubmissionChangelogsQuery (
        $limit: Int 
        $offset: Int 
        $sortOn: String 
        $sortBy: String 
        $actionFilter: [String]
        $dateFilter: [String] 
        $roleFilter: [ID] 
        $subjectId: ID 
        $usernameSearch: String
        $ncidSearch: String
        $subjectSearch: String
    ) {
        changelogs (
            type: submissions 
            limit: $limit 
            offset: $offset 
            sortOn: $sortOn 
            sortBy: $sortBy 
            actionFilter: $actionFilter 
            dateFilter: $dateFilter 
            roleFilter: $roleFilter 
            subjectId: $subjectId 
            usernameSearch: $usernameSearch
            ncidSearch: $ncidSearch
            subjectSearch: $subjectSearch
        ) {
            count 
            id
            createdAt 
            roleSpecifier
            action 
            description 
            data 
            subject {
                ...on Submission {
                    id 
                    specifier 
                    submitter {
                        id 
                        name 
                    }
                }
            }
            user {
                id 
                name 
                ncid 
            }
        }
    }
`;

export const SubmissionTypesChangelogsQuery = gql`
    query SubmissionTypesChangelogsQuery (
        $limit: Int 
        $offset: Int 
        $sortOn: String 
        $sortBy: String 
        $actionFilter: [String]
        $dateFilter: [String] 
        $roleFilter: [ID] 
        $subjectId: ID 
        $usernameSearch: String
        $ncidSearch: String
        $subjectSearch: String
    ) {
        changelogs (
            type: submissionTypes 
            limit: $limit 
            offset: $offset 
            sortOn: $sortOn 
            sortBy: $sortBy 
            actionFilter: $actionFilter 
            dateFilter: $dateFilter 
            roleFilter: $roleFilter 
            subjectId: $subjectId 
            usernameSearch: $usernameSearch
            ncidSearch: $ncidSearch
            subjectSearch: $subjectSearch
        ) {
            count 
            id
            createdAt 
            roleSpecifier
            action 
            description 
            data 
            subject {
                ...on SubmissionType {
                    id 
                    specifier
                }
            }
            user {
                id 
                name 
                ncid
            }
        }
    }
`;

export const ActingUserQuery = gql`
    query ActingUserQuery (
        $limit: Int 
        $offset: Int 
        $sortOn: String 
        $sortBy: String 
        $actionFilter: [String]
        $dateFilter: [String] 
        $userId: ID 
        $requiredUserId: ID!
    ) { 
        user (id: $requiredUserId) {
            id 
            name
            ncid  
        }
        changelogs (
            type: actingUser
            userId: $userId
            actionFilter: $actionFilter 
            dateFilter: $dateFilter 
            sortOn: $sortOn 
            sortBy: $sortBy 
            limit: $limit 
            offset: $offset 
        ) {
            count 
            id
            createdAt 
            roleSpecifier
            action 
            description 
            data 
            user {
                id 
                name 
                ncid 
            }
        }
    }
`;

export const ContractTypePlanChangelogsQuery = gql`
    query ContractTypePlanChangelogsQuery (
        $limit: Int 
        $offset: Int 
        $sortOn: String 
        $sortBy: String 
        $actionFilter: [String]
        $dateFilter: [String] 
        $roleFilter: [ID] 
        $subjectId: ID 
        $usernameSearch: String
        $ncidSearch: String
        $subjectSearch: String
    ) {
        changelogs (
            type: contractTypePlans 
            limit: $limit 
            offset: $offset 
            sortOn: $sortOn 
            sortBy: $sortBy 
            actionFilter: $actionFilter 
            dateFilter: $dateFilter 
            roleFilter: $roleFilter 
            subjectId: $subjectId 
            usernameSearch: $usernameSearch
            ncidSearch: $ncidSearch
            subjectSearch: $subjectSearch
        ) {
            count 
            id
            createdAt 
            roleSpecifier
            action 
            description 
            data 
            subject {
                ...on ContractTypePlans {
                    id 
                    specifier
                    componentName
                }
            }
            user {
                id 
                name 
                ncid 
            }
        }
    }
`;
export const OrganizationChangelogsQuery = gql`
    query OrganizationChangelogsQuery (
        $limit: Int 
        $offset: Int 
        $sortOn: String 
        $sortBy: String 
        $actionFilter: [String]
        $dateFilter: [String] 
        $roleFilter: [ID] 
        $subjectId: ID 
        $usernameSearch: String
        $ncidSearch: String
        $subjectSearch: String
    ) {
        changelogs (
            type: organization 
            limit: $limit 
            offset: $offset 
            sortOn: $sortOn 
            sortBy: $sortBy 
            actionFilter: $actionFilter 
            dateFilter: $dateFilter 
            roleFilter: $roleFilter 
            subjectId: $subjectId 
            usernameSearch: $usernameSearch
            ncidSearch: $ncidSearch
            subjectSearch: $subjectSearch
        ) {
            count 
            id
            createdAt 
            roleSpecifier
            action 
            description 
            data 
            subject {
                ...on Organization {
                    id 
                    specifier 
                    name 
                }
            }
            user {
                id 
                name 
                ncid
            }
        }
    }
`;

export const UsersChangelogsQuery = gql`
    query UsersChangelogsQuery (
        $limit: Int 
        $offset: Int 
        $sortOn: String 
        $sortBy: String 
        $actionFilter: [String]
        $dateFilter: [String] 
        $roleFilter: [ID] 
        $subjectId: ID 
        $usernameSearch: String
        $ncidSearch: String
        $subjectSearch: String
    ) {
        changelogs (
            type: user 
            limit: $limit 
            offset: $offset 
            sortOn: $sortOn 
            sortBy: $sortBy 
            actionFilter: $actionFilter 
            dateFilter: $dateFilter 
            roleFilter: $roleFilter 
            subjectId: $subjectId 
            usernameSearch: $usernameSearch
            ncidSearch: $ncidSearch
            subjectSearch: $subjectSearch
        ) {
            count 
            id
            createdAt 
            roleSpecifier
            action 
            description 
            data 
            subject {
                ...on User {
                    id 
                    name
                }
            }
            user {
                id 
                name 
                ncid
            }
        }
    }
`;

export const LibraryChangelogsQuery = gql`
    query LibraryChangelogsQuery (
        $limit: Int 
        $offset: Int 
        $sortOn: String 
        $sortBy: String 
        $actionFilter: [String]
        $dateFilter: [String] 
        $roleFilter: [ID] 
        $subjectId: ID 
        $usernameSearch: String
        $ncidSearch: String
        $subjectSearch: String
    ) {
        changelogs (
            type: libraryFolder
            limit: $limit 
            offset: $offset 
            sortOn: $sortOn 
            sortBy: $sortBy 
            actionFilter: $actionFilter 
            dateFilter: $dateFilter 
            roleFilter: $roleFilter 
            subjectId: $subjectId 
            usernameSearch: $usernameSearch
            ncidSearch: $ncidSearch
            subjectSearch: $subjectSearch
        ) {
            count 
            id
            createdAt 
            roleSpecifier
            action 
            description 
            subjectId 
            data 
            user {
                id 
                name 
                ncid
            }
        }
    }
`;

export const SubmissionTypeCategoryChangelogsQuery = gql`
    query SubmissionTypeCategoryChangelogsQuery (
        $limit: Int 
        $offset: Int 
        $sortOn: String 
        $sortBy: String 
        $actionFilter: [String]
        $dateFilter: [String] 
        $roleFilter: [ID] 
        $subjectId: ID 
        $usernameSearch: String
        $ncidSearch: String
        $subjectSearch: String
    ) {
        changelogs (
            type: submissionTypeCategory
            limit: $limit 
            offset: $offset 
            sortOn: $sortOn 
            sortBy: $sortBy 
            actionFilter: $actionFilter 
            dateFilter: $dateFilter 
            roleFilter: $roleFilter 
            subjectId: $subjectId 
            usernameSearch: $usernameSearch
            ncidSearch: $ncidSearch
            subjectSearch: $subjectSearch
        ) {
            count 
            id
            createdAt 
            roleSpecifier
            action 
            description 
            data 
            subject {
                ...on SubmissionTypeCategory {
                    id 
                    name
                }
            }
            user {
                id 
                name 
                ncid 
            }
        }
    }
`;