import { gql } from "@apollo/client";

export const DocumentDetailQuery = gql`
    query DocumentDetailQuery ($id: ID!) {
        submissionDocument(id: $id) {
            id
            specifier
            submissionId 
            nickname
            status
            currentStatusDueDate
            containsPii
            containsPhi
            deleted 
            S3UploadStatus 
            documentType {
                id 
                specifier 
                name 
            }
            criteria {
                id
                specifier
                citation
                description 
                deleted 
            }
            documentNewestVersion {
                id
                name
                specifier
                createdAt 
                dqFlag
                dqStatus
                S3UploadStatus
            }
            documentVersions {
                id
                specifier
                name
                createdAt
                submissionVersionSpecifier
                S3UploadStatus
                containsPii
                containsPhi
            }
            submission { 
                specifier
                archived 
                uploadStatus
                status
                submitter {
                    __typename
                    ... on User {
                        id
                        name
                    }
                    ... on Organization {
                        id
                        name
                    }
                }
                submissionNewestVersion {
                    id
                    specifier
                    createdAt
                    archived 
                    reviews {
                        id
                        completed
                        reviewer {
                            id
                            name
                            email
                        }
                        roleSpecifier
                        deleted
                    }
                    submitter {
                        name
                    }
                    attestationRoleName
                }
                submissionVersions {
                    id
                    specifier
                    createdAt 
                    archived 
                    reviews {
                        id
                        completed
                        reviewer {
                            id
                            name
                        }
                    }
                }
                reviewerAssignments {
                    role {
                        id
                        specifier
                    }
                    assigneeId
                    assignee {
                        __typename
                        ... on User {
                            id
                            name
                            email
                        }
                    }
                }
                obligation {
                    submissionType {
                        id
                        name
                        specifier
                        dqFlag
                        daysToReview
                        useAttestation
                        packagedSubmission
                        reviewerAssignments {
                            role {
                                id
                                specifier
                            }
                            assigneeId
                            assignee {
                                __typename
                                ... on User {
                                    id
                                    name
                                    email
                                }
                            }
                        }
                        criteria {
                            id
                            specifier
                            citation
                            description 
                            deleted 
                        } 
                        allCriteria {
                            id 
                            specifier 
                            citation 
                            description 
                            createdAt 
                            deleted 
                            versions {
                                id
                                createdAt
                                description
                                citation
                                deleted
                            }
                        }
                        obligations {
                            id
                            obligatorId
                            criteria {
                                description
                            }
                        }
                    }
                }
            }
            issues {
                id
                specifier
                reason 
                resolvedForDocument 
                criteria {
                    id
                    specifier
                }
                raisedInReview {
                    id
                    submissionVersion { 
                        id 
                        specifier 
                        createdAt 
                    }
                    reviewer {
                        id
                        name
                    }
                }
                status
                readyForReview
                reactions {
                    reviewerId
                    disposition 
                    comment 
                    createdAt 
                }
                comments {
                    id
                    content 
                    createdAt 
                    author {
                        id
                        email
                        name
                        organizations {
                            name
                        }
                    }
                    readyForReview
                }
            }
            comments {
                id
                content 
                createdAt 
                modifiedAt 
                author {
                    id
                    email
                    name
                    organizations {
                        name
                    }
                }
            }
        }
    }
`; 
