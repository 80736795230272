import React from "react";
import { Menu } from "antd";
import { FileSearchOutlined, SettingOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder, faGear } from "@fortawesome/free-solid-svg-icons";
import { safeTruncate } from "../../utils/functions.js";
import { PCDULink } from "../common/PCDULink/index.js";

const TRUNCATE_LENGTH = 5;

const determineSelectedAndDefaultKeys = (path) => {
    if (path === "/library") {
        return {
            selectedKeys: ["library-search"],
            defaultOpenKeys: undefined
        };
    } else {
        const pathArray = path.split("/").filter(Boolean);
        if (path.includes("settings")) {
            const contractTypeId = pathArray[2];
            return {
                selectedKeys: [`contract-type-settings-${contractTypeId}`],
                defaultOpenKeys: ["library-settings-submenu"]
            };
        } else {
            const contractTypeId = pathArray[1];
            const baseFolderId = pathArray[3];
            return { 
                selectedKeys: [`${contractTypeId}-${baseFolderId}`],
                defaultOpenKeys: [contractTypeId]
            };
        }
    }
};

export const LibrarySidebar = ({ 
    data, 
    path,
    sidebarOpen,
    userIsContractAdmin, 
    userIsSuperUser, 
    userIsSystemAdmin 
}) => { 
    const truncateAndSpanify = (name) => {
        if (sidebarOpen) {
            return null;
        } else {
            const truncatedText = safeTruncate(name, TRUNCATE_LENGTH, true);
            return !truncatedText ? null : <span style={{ marginLeft: "5px" }}> { truncatedText } </span>;
        }
    };

    const isPermittedToAccessContractTypeSettings = (userIsContractAdmin || userIsSuperUser || userIsSystemAdmin); 

    const librarySearchItems = [
        {
            key: "library-search",
            icon: <FileSearchOutlined />, 
            style: {
                marginBottom: "-5px"
            }, 
            label: <PCDULink 
                to={"/library"} 
                resetPersistentState={true}
                resetBreadcrumbs={true}
            >
                Search Library
            </PCDULink>
        }
    ];

    const marginAdjustment = sidebarOpen ? {} : { marginLeft: "-30px" };

    const contractTypeFolders = data?.libraryAssociatedContractTypes?.map?.(({ name: contractTypesName, id: contractTypeId }) => {  
        const childFolders = data?.libraryBaseFolder?.map(({ name: baseFolderName, id }) => {
            return {
                key: `${contractTypeId}-${id}`,
                icon: <FontAwesomeIcon icon={faFolder} />,
                label: <PCDULink
                    to={{
                        pathname: `/library/${contractTypeId}/${baseFolderName}/${id}`
                    }}
                    state={{ isBaseFolder: true }}
                    resetPersistentState={true}
                    resetBreadcrumbs={true}
                >
                    { baseFolderName } 
                </PCDULink>
            };
        });

        // when sidebar is collapsed, include parent folder name as first item of popup submenu :: 
        if (!sidebarOpen) {
            childFolders.unshift({
                key: `parent-menu-name-${contractTypeId}`,
                label: <h3 className="inert-menu-item"> { contractTypesName } </h3>,
                disabled: true, 
                className: "inert-menu-item"
            });
        }

        return {
            key: contractTypeId, 
            icon: <div style={marginAdjustment}>
                <FontAwesomeIcon icon={ faFolder } /> 
                { truncateAndSpanify(contractTypesName) }
            </div>,
            label: <span>
                { contractTypesName }
            </span>,
            children: childFolders ?? []
        };
    }) ?? []; 

    const contractTypeItemsForSettings = data?.libraryAssociatedContractTypes?.filter(({ deleted }) => !deleted).map(({ id, name }) => { 
        return {
            key: `contract-type-settings-${id}`, 
            icon: <FontAwesomeIcon icon={faGear} />,
            label: <span><PCDULink
                to={`/library/settings/${id}`}
                style={{ marginLeft: "10%" }}
                resetPersistentState={true}
                resetBreadcrumbs={true}
            >
                { name }
            </PCDULink></span>
        };
    }); 

    // when sidebar is collapsed, include parent menu name as first item of popup submenu :: 
    if (!sidebarOpen) {
        contractTypeItemsForSettings.unshift({
            key: "parent-menu-name-contract-type-settings",
            label: <h3 className="inert-menu-item"> Contract Type Settings </h3>,
            disabled: true, 
            className: "inert-menu-item"
        });
    }

    const displayContractTypeSettings = isPermittedToAccessContractTypeSettings  
        && Array.isArray(contractTypeItemsForSettings) 
        && contractTypeItemsForSettings.length > 0;

    const contractTypeSettings = !displayContractTypeSettings 
        ? [] 
        : [
            {
                icon: <SettingOutlined />,
                key: "library-settings-submenu", 
                label: <span> Contract Type Settings </span>, 
                children: contractTypeItemsForSettings 
            }
        ];

    const { selectedKeys, defaultOpenKeys } = determineSelectedAndDefaultKeys(path);

    return (
        <Menu
            selectedKeys={selectedKeys}
            defaultOpenKeys={sidebarOpen ? defaultOpenKeys : undefined}
            mode="inline"
            style={{ height: "100%", boderRight: 0 }}
            items={[...librarySearchItems, ...contractTypeFolders, ...contractTypeSettings ]}
        />
    );
};