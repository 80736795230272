import { gql } from "@apollo/client";

export const createContractQuery = gql`
    query CreateContractQuery (
        $submittingOrgList: Boolean
    ) {
        organizationsList(submittingOrgList: $submittingOrgList) {
            organizations {
                id
                name
                specifier
            }
        }
        contractTypes {
            id
            name
            specifier
            contractTypeNewestVersion {
                startDate
                endDate
            }
        }
    }
`;

export const contractDetailQuery = gql`
    query ContractDetailQuery (
        $id: ID!
    ) {
        contract(id: $id) {
            id
            name
            specifier
            startDate
            endDate
            promisor {
                id
                name
                specifier
            }
            contractType {
                id
                name
                description
                contractTypeNewestVersion {
                    startDate
                    endDate
                    }
                submissionTypes {
                    id
                    name
                }
            }
        }
    }
`;