import React, { useState, useContext, useEffect } from "react";
import { Button, Table, Modal } from "antd";
import { useQuery } from "@apollo/client";
import { UserContext } from "../../Application/UserContext.js";

import {
    ISSUE_STATUS_LIST,
    S3_UPLOAD_STATUS,
    SUBMISSION_STATUS_LIST
} from "../../../constants/index.js";

import { S3ErrorMessage } from "../../common/S3ErrorMessage/index.js";
import { ErrorMessage } from "../../ErrorMessage/index.js";
import { FeedbackDocumentActions } from "./actions.js";
import { feedbackDocumentsQuery } from "./query.js";
import { UploadDocumentModal } from "./modal.js";
import { FeedbackDocumentStatus } from "../../common/FeedbackDocumentStatus/index.js";

const FEEDBACK_DOCUMENTS_NOTE = "There can only be one active feedback document for an issue. The table below includes all feedback documents for this issue, but any feedback documents marked as 'Inactive' may be outdated.";

export const FeedbackDocumentsTable = (({ 
    issueId, 
    issueStatus,
    submissionStatus, 
    submissionTypeId,
    issueRefetch
}) => { 
    const { getFeedbackDocumentPermissions } = useContext(UserContext);
    const { canCreate } = getFeedbackDocumentPermissions();
    const [uploadModal, setUploadModal] = useState(false);
    const [uploadIsUnderway, setUploadIsUnderway] = useState(false); 

    // for avoiding unnecessary refetch :: 
    const [initialLoadWithStatus, setInitialLoadWithStatus] = useState(true);

    const { confirm } = Modal;

    const { 
        data, 
        previousData, 
        loading, 
        error, 
        refetch 
    } = useQuery(feedbackDocumentsQuery, {
        variables: {
            id: issueId
        },
        fetchPolicy: "no-cache"
    }); 

    // component did mount :: 
    useEffect(() => { 
        setInitialLoadWithStatus(false);
    }, []);

    useEffect(() => {
        // if the issue status changes to inactive, we want to refetch -- 
        // wrapping in conditional so that we don't refetch on initial load or when issueStatus 
        // prop goes undefined during issue modification :: 
        if (!initialLoadWithStatus && issueStatus && issueStatus !== ISSUE_STATUS_LIST.active) { 
            refetch();
        }
    }, [ issueStatus ]);

    if (error) {
        return <ErrorMessage />;
    } 

    const feedbackDocuments = data?.issue?.feedbackDocuments ?? previousData?.isssue?.feedbackDocuments;

    // Only allow uploads if all the following are true :: 
    // 1.) The associated submission is under review (i.e., status is "Awaiting Review" or "Awaiting Final Review");
    // 2.) The user is permitted to upload feedback documents; 
    // 3.) The status of the issue is "Active" 

    const isUnderReview = submissionStatus === SUBMISSION_STATUS_LIST.review || 
        submissionStatus === SUBMISSION_STATUS_LIST.final_version_review;
    
    const allowUploads = isUnderReview && 
        canCreate && 
        issueStatus === ISSUE_STATUS_LIST.active;

    // Render the Upload button, but disable it if ANY of the following are true :: 
    // 1.) Component is loading; or 
    // 2.) An upload is underway; or 
    // 2.) Any feedback document has not uploaded successfully 

    const allDocumentsUploaded = feedbackDocuments?.every((doc) => doc?.S3UploadStatus === S3_UPLOAD_STATUS.uploaded);
    const failedDocumentExists = feedbackDocuments?.some((doc) => doc.S3UploadStatus === S3_UPLOAD_STATUS.error || doc.S3UploadStatus === S3_UPLOAD_STATUS.quarantine);

    const disableUploadButton = loading || uploadIsUnderway || !allDocumentsUploaded;

    const handleUploadDisplay = () =>{
        if (feedbackDocuments[0]?.active){
            confirm({
                title:
                    "An active document already exists. Uploading a new document will inactivate the existing document.",
                okText: "Upload",
                onOk: () => {
                    setUploadModal(true);
                }
            });
        }
        else {
            setUploadModal(true);}
    };

    const columns = [
        { 
            title: "ID", 
            dataIndex: "specifier", 
            render: (specifier) => `Document-${specifier}`
        },
        { 
            title: "Name", 
            dataIndex: "name" 
        },
        { 
            title: "File Upload Status", 
            dataIndex: "S3UploadStatus", 
            render: (status, feedbackDocument) => <FeedbackDocumentStatus 
                allowDownload={false}
                feedbackDocument={feedbackDocument}
                status={status}
                statusUpdateCallback={() =>{
                    refetch();
                    issueRefetch();
                }}
            />
        }, 
        { 
            title: "Error Message",
            dataIndex: "S3UploadStatus", 
            render: S3ErrorMessage 
        },
        { 
            title: "Status", 
            dataIndex: "active", 
            render: (active, feedbackDocument) => { 
                if (feedbackDocument.S3UploadStatus === S3_UPLOAD_STATUS.uploading) {
                    // blank if uploading :: 
                    return "";
                } else {
                    return active ? "Active" : "Inactive";
                }
            } 
        }, 
        { 
            title: "Actions", 
            render: (feedbackDocument, S3UploadStatus) => <FeedbackDocumentActions 
                feedbackDocument={feedbackDocument} 
                submissionTypeId={submissionTypeId}
                allowModifications={ isUnderReview && issueStatus === ISSUE_STATUS_LIST.active && S3UploadStatus !== S3_UPLOAD_STATUS.uploading}
                refetch={refetch}
                issueRefetch={issueRefetch}
            /> 
        }
    ]; 

    return (
        <div style={{marginTop: "20px"}}>
            <h3> Feedback Documents </h3> 
            <p>{ FEEDBACK_DOCUMENTS_NOTE }</p>
            <div style={{ display: "flex" }}>
                <div>
                    { allowUploads && 
                    <Button 
                        onClick= {handleUploadDisplay}
                        type="primary"
                        disabled={disableUploadButton}
                        style={{ marginBottom: "12px" }}
                    > 
                        Upload 
                    </Button> 
                    }
                </div>
                <div style={{ paddingLeft: "15px", paddingTop: "5px" , color: "red" }}>
                    { disableUploadButton && failedDocumentExists &&
                    <p> There is currently a failed feedback document. Please re-upload or delete the existing document. </p>
                    }
                </div>
            </div>
            <Table 
                id="issue-detail-feedback-documents-table"
                rowKey={"id"}
                loading={loading}
                dataSource={feedbackDocuments}
                columns={columns}
            /> 
            <UploadDocumentModal 
                issueId={data?.issue?.id}
                actionHappening={uploadIsUnderway}
                setActionHappening={setUploadIsUnderway}
                refetch={refetch}
                modalIsOpen={uploadModal}
                setModalIsOpen={setUploadModal}
                issueRefetch={issueRefetch}
            />
        </div>
    );
});
