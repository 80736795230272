import React, { useState } from "react";
import { Button } from "antd";
import { UploadDocumentModal } from "../modal.js";

export const ReuploadFeedbackDocument = ({ 
    feedbackDocument, 
    actionHappening,
    setActionHappening, 
    refetch,
    issueRefetch
}) => { 
    const [reuploadModal, setReuploadModal] = useState(false);

    const id = feedbackDocument?.id; 

    return id && typeof id === "string" 
        ? <>
            <Button 
                type="primary"
                size="small"
                id="feedback-reupload-button"
                onClick={() => {
                    if (!actionHappening) {
                        setReuploadModal(true);
                    }
                }}
            > 
                Re-Upload 
            </Button>
            <UploadDocumentModal 
                modalIsOpen={reuploadModal}
                setModalIsOpen={setReuploadModal}
                refetch={refetch}
                issueRefetch={issueRefetch}
                actionHappening={actionHappening}
                setActionHappening={setActionHappening}
                reuploadDocumentId={id}
            />
        </> 
        : null;
};
