import React, { useState } from "react";
import { ContractTypesNotification } from "../_shared/contractTypes.js";
import { SubmissionNotificationTypes } from "./submissionNotificationTypes.js";
import { useQuery } from "@apollo/client";
import { submissionNotificationsQuery } from "../query.js";
import { SUBMISSION } from "../../../constants/index.js";
import { LoadingContent } from "../../common/LoadingContent/index.js";
import { handleGraphQLError } from "../../../utils/errorHandling.js";
import { useAutoFlag } from "../../../utils/useAutoFlag.js";
import { NotificationSaveButtons } from "../_shared/saveButtons.js";
import { NoNotifications } from "../_shared/noNotifications.js";

const submissionContractTypeNotificationsMessage = <>
    <span> Select the contract types for which you would like to receive submission notifications.</span> 
    <span style={{ color: "red" }}> These settings apply across all Submission Type Categories.</span>
</>;


export const SubmissionNotifications = ({ 
    categoryName, 
    categoryId
}) => {
    const [modified, setModified] = useState({});
    const [submissionTypeModified, setSubmissionTypeModified] = useState({});
    const [resetFlag, setResetFlag] = useAutoFlag(false);

    const { loading, data, error, refetch } = useQuery(submissionNotificationsQuery, {
        fetchPolicy: "no-cache",
        variables: {
            type: SUBMISSION,
            categoryId 
        }
    });

    if (loading) {
        return <LoadingContent />;
    }

    if (error) {
        return handleGraphQLError(error);
    }

    const {
        category,
        notificationPreferencesByContractType,
        notificationPreferencesByNotificationType,
        notificationPreferencesBySubmissionType
    } = data;

    const contractTypesToUse = notificationPreferencesByContractType?.map(pref => {
        const contractType = pref.contractType;
        return {
            ...contractType, 
            active: pref.active && !contractType.deleted 
        };
    });

    const receivesNoNotifications = notificationPreferencesByNotificationType.length === 0 &&
        notificationPreferencesBySubmissionType.length === 0 && 
        notificationPreferencesByContractType.length === 0;


    return receivesNoNotifications 
        ? <NoNotifications /> 
        : (
            <>
                <NotificationSaveButtons 
                    changedNotifications={{ ...modified, ...submissionTypeModified }}
                    reset={() => {
                        setModified({});
                        setSubmissionTypeModified({});
                        setResetFlag(true);
                    }}
                    refetch={refetch}
                />
                <ContractTypesNotification
                    type={SUBMISSION}
                    message={submissionContractTypeNotificationsMessage}
                    resetFlag={resetFlag}
                    contractTypePreferences={notificationPreferencesByContractType}
                    addChanges={(changes) => setModified({ ...modified, ...changes })}
                />
                <SubmissionNotificationTypes 
                    resetFlag={resetFlag}
                    finalVersionRequestFlag={category.finalVersionRequestFlag}
                    modified={modified}
                    setModified={setModified}
                    submissionTypeModified={submissionTypeModified}
                    setSubmissionTypeModified={setSubmissionTypeModified}
                    categoryName={categoryName}
                    notificationTypePreferences={notificationPreferencesByNotificationType}
                    notificationPreferencesBySubmissionType={notificationPreferencesBySubmissionType}
                    loading={loading}
                    excludedContractTypes={contractTypesToUse.filter(ctPref => !ctPref.active)}
                    contractTypesFilterOptions={contractTypesToUse.filter(ctPref => ctPref.active)}
                />
            </>
        );
};