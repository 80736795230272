import React from "react";
import { Pagination, Spin } from "antd";

export const TablePagination = ({
    loading,
    setPage,
    setPageSize,
    total,
    hideOnSinglePage,
    currentPageNumber,
    defaultPage,
    defaultPageSize = 10
}) => {
    return (
        <Spin spinning={loading === true}>
            <Pagination
                style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "20px 0 50px 0"
                }}
                current={currentPageNumber}
                defaultCurrent={defaultPage}
                showSizeChanger={true}
                onShowSizeChange={(targetPage, pageSize) => {
                    setPage(targetPage ? targetPage : 1);
                    setPageSize(pageSize);
                }}
                hideOnSinglePage={Boolean(hideOnSinglePage)}
                onChange={(targetPage, pageSize) => {
                    setPage(targetPage ? targetPage : 1);
                    setPageSize(pageSize);
                }}
                pageSize={defaultPageSize}
                pageSizeOptions={["10", "25", "50", "100"]}
                total={total}
            />
        </Spin>
    );
};
