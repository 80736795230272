import React, { useState, useContext } from "react";
import { Button, Form, message } from "antd";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../Application/UserContext.js";
import { useAttemptToCreateOrganization } from "../../utils/attemptMutationHooks.js";
import { EditableDetail } from "../common/EditableDetail/index.js";
import { Main } from "../common/Main/index.js";
import { NoPermission } from "../common/NoPermission/index.js";
import { PCDULink } from "../common/PCDULink/index.js";
import { usePreloadedData } from "../Application/PreloadedData/index.js";


export const CreateOrganization = (() => {
    const navigate = useNavigate();
    const { preloadedData } = usePreloadedData();
    const [modified, setModified] = useState({});
    const [attemptToCreateOrganization] = useAttemptToCreateOrganization();
    const [form] = Form.useForm();

    const {
        userPermCreateOrganization,
        userPermEditOrganization
    } = useContext(UserContext);

    if (!userPermCreateOrganization) {
        return <NoPermission />;
    }

    const { constants } = preloadedData;
    const invalidSpecifierCharacters = constants?.INVALID_SPECIFIER_CHARACTERS ?? [];
    const invalidSpecifierMessage = `The Service Center ID cannot contain these characters: ${invalidSpecifierCharacters.join(", ")}`;
    const validFirstPositionSpecifierCharacters = constants?.VALID_FIRST_POSITION_SPECIFIER_CHARACTERS ?? []; 
    const invalidFirstPositionMessage = "The Service Center ID must begin with a letter or a digit.";
    const changeDetected = typeof modified.name !== "undefined" || typeof modified.specifier !== "undefined" || typeof modified.email !== "undefined";

    const details = (
        <div id="details"> 
            <Form name="organizationDetails" form={form}>
                {[
                    {
                        title: "Name",
                        key: "name",
                        required: true
                    },
                    {
                        title: "Service Center ID",
                        key: "specifier",
                        required: true, 
                        additionalRules: [{
                            message: invalidSpecifierMessage,
                            validator: (_, specifier) => {
                                if (specifier && invalidSpecifierCharacters.some(char => specifier.includes(char))) {
                                    return Promise.reject(new Error(invalidSpecifierMessage));
                                } else {
                                    return Promise.resolve();
                                }
                            }
                        }, {
                            message: invalidFirstPositionMessage,
                            validator: (_, specifier) => {
                                if (specifier && validFirstPositionSpecifierCharacters.length > 0 && !validFirstPositionSpecifierCharacters.some(char => char === specifier[0])) {
                                    return Promise.reject(new Error(invalidFirstPositionMessage));
                                } else {
                                    return Promise.resolve();
                                }
                            }
                        }]
                    },
                    {
                        title: "Email",
                        key: "email",
                        required: true,
                        additionalRules: [{type: "email", message: "Should be a valid email"}]
                    }
                ].map(({ title, key, required, additionalRules }) => {
                    return <div key={`editable-org-details-${key}`}>
                        <EditableDetail
                            title={title}
                            inputId={key}
                            required={required}
                            additionalRules={additionalRules}
                            readOnly={!userPermEditOrganization}
                            value={modified[key]}
                            onValueUpdated={(e) => {
                                setModified({
                                    ...modified,
                                    [key]: e.target.value
                                });
                            }}
                        />
                    </div>;
                })}

                <br />
                <Form.Item>
                    <Button
                        type="primary"
                        style={{
                            display: (userPermCreateOrganization && changeDetected)
                                ? "inherit"
                                : "none"
                        }}
                        onClick={async () => {
                            try {
                                await form.validateFields();

                                const newOrganization = {
                                    name: modified.name,
                                    specifier: modified.specifier,
                                    email: modified.email
                                };
                               
                                const response = await attemptToCreateOrganization({
                                    variables: {
                                        newOrganization
                                    }
                                });

                                if (response?.success) {
                                    const id = response.result.data.createOrganization.id;
                                    navigate(`/organizations/${id}`);
                                } 
                            } catch {
                                message.error({
                                    content: "Please correct form errors and try again."
                                });
                            }
                        }}
                    >
                        Create Organization
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );

    return (
        <Main
            title={"Create an Organization"}
            details={details}
            breadcrumbs={[
                <PCDULink to="/organizations" tooltip="All Organizations">Organizations</PCDULink> 
            ]}
        />
    );
});