import { Route, Routes } from "react-router-dom";
import React from "react";
import { Application } from "./components/Application/index.js";
import { NoActiveUser } from "./components/NoActiveUser/index.js";
import { LoggedOut } from "./components/LoggedOut/index.js";
import Login from "./components/Login/index.js";

export const PageRouting = () => {

    return (
        <Routes>
            <Route path="/entry" element={<Login />} >
                <Route
                    path="inactive"
                    key="inactive-user"
                    caseSensitive={true}
                    element={<Login />}
                />
            </Route>
            <Route path="/no-active-user" element={<NoActiveUser />} />
            <Route path="/logged-out" element={<LoggedOut />} />
            <Route path="/*" key="application" element={<Application />} />
        </Routes>
    );
};
