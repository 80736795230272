import React from "react";
import { Breadcrumb } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { PCDULink } from "../PCDULink/index.js";
import { getDefaultPageLink } from "../../NavigationSidebar/submission-options-sorted-byrole.js";
import { getHistoryBreadcrumbs } from "../../../utils/getHistoryBreadcrumbs.js";

const truncateBreadcrumbs = (breadcrumbs) => {
    if (breadcrumbs.length > 8) {
        return [ 
            ...breadcrumbs.slice(0, 4), 
            "...", 
            ...breadcrumbs.slice(-4) 
        ];
    } else {
        return breadcrumbs;
    }
};

export const PCDUBreadcrumbs = ({ breadcrumbs }) => {
    const historyBreadcrumbs = getHistoryBreadcrumbs();
    const defaultPage = getDefaultPageLink();
    const defaultPageDestination = defaultPage.props.to;
    const baseBreadcrumb = <PCDULink 
        to={defaultPageDestination} 
        tooltip={defaultPage.props.text}
        resetBreadcrumbs={true}
    >
        <FontAwesomeIcon
            style={{
                marginRight: "6px"
            }}
            icon={faHome}
        />
    </PCDULink>;

    const additionalCrumbs = Array.isArray(breadcrumbs) ? breadcrumbs : [];
    const allBreadcrumbs = [baseBreadcrumb].concat(historyBreadcrumbs.map(({ label, path, tooltip }, index) => {
        if (additionalCrumbs.length == 0 && index === historyBreadcrumbs.length - 1) {
            // this breadcrumb will be the last one and shouldn't be a link :: 
            return label;
        } else {
            return <PCDULink 
                to={path} 
                breadcrumbIndex={index}
                tooltip={tooltip ? tooltip : label}
            >
                { label }
            </PCDULink>;
        }
    }).concat(additionalCrumbs)).filter(Boolean);

    const breadcrumbsToDisplay = truncateBreadcrumbs(allBreadcrumbs).map((item) => ({ title: item }));

    return <Breadcrumb items={breadcrumbsToDisplay} />;
};