import React from "react";
import Highlighter from "react-highlight-words";
import { SearchDropdown } from "../components/common/SearchDropdown/index.js";
import { SearchIcon } from "../components/common/SearchIcon/index.js";
import defaultTableRender from "./defaultTableRender.js"; 


export const searchable = ({
    title,
    dataIndex,
    className,
    key,
    sorter,
    sortDirections,
    defaultSortOrder,
    width,
    render = defaultTableRender,
    handleSearch,
    setPage, 
    startShown,
    alwaysOn,
    transform,
    handleReset,
    searchedText,
    filteredValue,
    ellipsis
}) => {
    const handleSearchToUse = typeof setPage === "function" 
        ? (...args) => {
            setPage(1);
            handleSearch(...args);
        }
        : handleSearch;
    
    return {
        title,
        dataIndex,
        className,
        key,
        sorter,
        startShown,
        alwaysOn,
        filteredValue,
        sortDirections,
        defaultSortOrder,
        width,
        ellipsis,
        filterDropdown: ({ selectedKeys, confirm, clearFilters }) => {
            return (
                <SearchDropdown
                    title={title}
                    searchedText={searchedText}
                    dataIndex={dataIndex}
                    selectedKeys={selectedKeys}
                    confirm={confirm}
                    handleSearch={handleSearchToUse}
                    handleReset={handleReset}
                    clearFilters={clearFilters}
                />
            );
        },
        onFilter: (value, record) => {
            return record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase());
        },
        filterIcon: () => <SearchIcon 
            active={searchedText && typeof searchedText === "string"}
        />,
        render: (text, obj) => {
            if (typeof transform === "function") {
                text = transform(text);
            }
            return render(
                <Highlighter
                    highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                    searchWords={[searchedText]}
                    autoEscape
                    textToHighlight={(typeof text === "string" ? text : "")}
                />,
                obj
            );
        }
    };
};