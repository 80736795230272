import React from "react";
import { EXPORTSIZE } from "../../constants/index.js";
import { CsvExport } from "../common/CsvExport/index.js";
import { organizationsQuery } from "./query.js";

const csvHeaders = [
    { label: "Service Center ID", key: "serviceCenterID" },
    { label: "Name", key: "name" },
    { label: "Primary Contact Name", key: "primaryContactName" },
    { label: "Primary Contact Email", key: "primaryContactEmail" }
];

const transformOrganizationsDataForCSV = (data) => {
    return data.organizationsList.organizations.map((organization) => {
        return {
            serviceCenterID: organization.specifier,
            name: organization.name,
            primaryContactName: organization.primaryContact?.name,
            primaryContactEmail: organization.primaryContact?.email
        };
    });
};

export const OrganizationsCsvExport = ({ variables }) => {
    return <CsvExport
        csvFilename={"Organizations_Report.csv"}
        csvHeaders={csvHeaders}
        query={organizationsQuery}
        variables={{...variables, offset: 0, limit: EXPORTSIZE}}
        transformData={transformOrganizationsDataForCSV}
    />;
};