import React, { useState } from "react";
import { Button, Modal } from "antd";
import { useMutation } from "@apollo/client";
import { SelectableDetail } from "../common/SelectableDetail/index.js";
import { handleMutation } from "../../utils/errorHandling.js";
import { ExclamationCircleTwoTone } from "@ant-design/icons";
import {
    MODIFY_SUBMISSION,
    MODIFY_VERSION
} from "../../constants/mutations.js";
import {
    SUBMISSION_STATUS_LIST,
    NOTIFICATION_TYPES,
    DOCUMENT_DQ_STATUS_LIST
} from "../../constants/index.js";

const CONFIRM_MODAL_MESSAGES = {
    "Archive": {
        submission: "Are you certain you want to archive this submission?",
        version: "Are you certain you want to archive this version of the submission"
    },
    "De-Archive": {
        submission: "Are you certain you want to de-archive this submission?",
        version: "Are you certain you want to de-archive this version of the submission"
    }
};


export const ArchiveModal = ({ 
    mode, 
    setMode, 
    refetch, 
    archiveReasonOptions,
    submission, 
    isDQIncomplete,
    submitter,
    renderArchiveButton,
    renderDeArchiveButton,
    setRefetchDocumentsFlag
}) => {
    const defaultArchiveReason = archiveReasonOptions.find(opt => opt.defaultSelection === true);
    const defaultValueArchiveReasonId = defaultArchiveReason?.id;
    const showModal = mode === "version" || mode === "submission";
    const [archiveReasonId, setArchiveReasonId] = useState(defaultValueArchiveReasonId);
    const [modifySubmission] = useMutation(MODIFY_SUBMISSION);
    const [modifyVersion] = useMutation(MODIFY_VERSION);

    // all situations where we do not want this modal to be operational -- return null early :: 
    if (!submission) {
        return null;
    }

    if (mode === "version" && !renderArchiveButton) {
        return null;
    }

    if (mode === "submission" && !renderArchiveButton && !renderDeArchiveButton) {
        return null;
    }

    const action = mode === "version" 
        ? (submission.submissionNewestVersion.archived ? "De-Archive" : "Archive")
        : (renderDeArchiveButton ? "De-Archive" : "Archive");

    const message = mode === "version"
        ? CONFIRM_MODAL_MESSAGES[action].version
        : CONFIRM_MODAL_MESSAGES[action].submission;

    const submissionVersion = submission.submissionNewestVersion.specifier + 1; 
    
    const title = mode === "version"
        ? `${message} [ Version:${submissionVersion} ] ?`
        : message;
    
    const okText = `Yes, ${action}`;

    const onModalConfirm = async () => {
        if (mode === "version") {
            await handleMutation(
                modifyVersion({
                    variables: {
                        id: submission.submissionNewestVersion.id,
                        archived: !submission.submissionNewestVersion.archived,
                        submissionId: submission.id,
                        dqStatus: isDQIncomplete ? DOCUMENT_DQ_STATUS_LIST.Cancel : null,
                        archiveReasonId,
                        action: NOTIFICATION_TYPES.SUBMISSION_VERSION_ARCHIVE
                    }
                })
            );
            await refetch();
            setRefetchDocumentsFlag(true);
        } else if (mode === "submission") {
            await handleMutation(
                modifySubmission({
                    variables: {
                        updateSubmission: {
                            id: submission.id,
                            status: renderDeArchiveButton ? SUBMISSION_STATUS_LIST.version : SUBMISSION_STATUS_LIST.archive,
                            archived: renderDeArchiveButton ? false : true,
                            dqStatus: isDQIncomplete ? DOCUMENT_DQ_STATUS_LIST.Cancel : null,
                            archiveReasonId, 
                            action: NOTIFICATION_TYPES.SUBMISSION_ARCHIVE
                        }
                    }
                })
            );
            await refetch();
            setRefetchDocumentsFlag(true);
        } 
        setArchiveReasonId(defaultValueArchiveReasonId);
        setMode(null);
    };

    // only have the reasons dropdown for non-submitters when archiving (not dearchiving) ::  
    const renderReasonDropdown = action === "Archive" && !renderDeArchiveButton;

    const ReasonDropdown = renderReasonDropdown 
        ? <div className="ant-modal-confirm-content">
            <SelectableDetail 
                containerStyle={{ marginTop: "15px", minWidth: "600px" }}
                title="Reason for Archival"
                value={archiveReasonId}
                passedKey={"reason-for-archival"}
                strict={true}
                options={archiveReasonOptions.map(({ id, text }) => ({ value: id, text, key: id }))}
                onValueUpdated={setArchiveReasonId}
            />
        </div> 
        : null; 
    
    // reason dropdown needs to be quite wide due to lengthy text of the options :: 
    const modalStyle = renderReasonDropdown ? { minWidth: "750px" } : undefined;

    return <Modal
        open={showModal}
        style={modalStyle}
        footer={null}
        className="ant-modal-confirm"
        closable={false}
        destroyOnClose={true}
        afterClose={() => setArchiveReasonId(defaultValueArchiveReasonId)}
    >
        <div className="ant-modal-confirm-body-wrapper">
            <div className="ant-modal-confirm-body">
                <ExclamationCircleTwoTone />
                <span className="ant-modal-confirm-title">
                    {
                        (isDQIncomplete  && !submitter && !renderDeArchiveButton) ?
                            <div><span style={{ color: "red" }}>Please Note</span><span> -  The Submisison is awaiting Data Quality (DQ) Feedback ,archiving will be cancelling DQ Feedback.</span>
                            </div> : ""
                    }
                    <b></b>  {title}
                </span>
                {ReasonDropdown}
            </div>
            <div className="ant-modal-confirm-btns">
                <Button type="default" onClick={() => setMode(null)}>
                    Cancel
                </Button>
                <Button type="primary" onClick={onModalConfirm}>
                    {okText}
                </Button>
            </div>
        </div>
    </Modal>;
};