import { gql } from "@apollo/client";


export const CREATE_MEMBERSHIP = gql`
    mutation CREATE_MEMBERSHIP ($membershipData: MembershipInput) {
        createMembership(membershipData: $membershipData) {
            id,
            status,
            success
        }
    }
`;

export const CREATE_ASSIGNMENTS = gql`
    mutation CREATE_ASSIGNMENTS ($newAssignments: [AssignmentInput]) {
        createAssignments(newAssignments: $newAssignments) {
            status,
            success
        }
    }
`;

export const DELETE_ASSIGNMENT = gql`
    mutation DELETE_ASSIGNMENT ($id: ID) {
        deleteAssignment(id: $id) {
            status,
            success
        }
    }
`;

export const CREATE_CONTRACT_TYPE = gql`
    mutation CREATE_CONTRACT_TYPE ($contractType: ContractTypeInput) {
        createContractType(
                contractType: $contractType
        ) {
            id
            status
            success 
        }
    }
`;

export const CREATE_CONTRACT_TYPE_VERSION = gql`
    mutation CREATE_CONTRACT_TYPE_VERSION (
        $contractType: ContractTypeInput, 
        $submissionTypeIdList: [ID],
        $withSubmissionTypesOfVersionId: ID
    ) {
        createContractTypeVersion(
            contractType: $contractType
            submissionTypeIdList: $submissionTypeIdList
            withSubmissionTypesOfVersionId: $withSubmissionTypesOfVersionId
        ){
            id
            status
            success
        }
    }
`;

export const MODIFY_CONTRACT_TYPE_VERSION = gql`
    mutation MODIFY_CONTRACT_TYPE_VERSION ($contractType: ContractTypeInput) {
        modifyContractTypeVersion(
            contractType: $contractType
        ){
            status
            success
            id
        }
    }
`;

export const CREATE_CONTRACT_TYPE_VERSION_FROM_LATEST = gql`
    mutation CREATE_CONTRACT_TYPE_VERSION_FROM_LATEST ($contractTypeId: ID) {
        createContractTypeVersionFromLatest(
            contractTypeId: $contractTypeId
        ) {
            id
            status
            success 
        }
    }
`;

export const CREATE_COMMENT = gql`
    mutation CREATE_COMMENT ($newComment: CommentInput) {
        createComment(newComment: $newComment) {
            status 
            success
        }
    }
`;

export const MODIFY_COMMENT = gql`
    mutation MODIFY_COMMENT ($id: ID, $newComment: CommentInput) {
        modifyComment(id: $id, newComment: $newComment) {
            status
            success
        }
    }
`;

export const CREATE_CONTRACT = gql`
    mutation CREATE_CONTRACT ($newContract: ContractInput) {
        createContract(newContract: $newContract) {
            id
            status,
            success
        }
    }
`;

export const MODIFY_CONTRACT = gql`
    mutation MODIFY_CONTRACT ($id: ID, $newContract: ContractInput) {
        modifyContract(id: $id, newContract: $newContract) {
            status,
            success
        }
    }
`;

export const MODIFY_CONTRACT_TYPE = gql`
    mutation MODIFY_CONTRACT_TYPE ($id: ID, $contractType: ContractTypeInput) {
        modifyContractType(id: $id, contractType: $contractType) {
            id,
            status,
            success
        }
    }
`;

export const CREATE_CRITERIA = gql`
    mutation CREATE_CRITERIA ($newCriteria: CriteriaInput) {
        createCriteria(newCriteria: $newCriteria) {
            status
            success 
        }
    }
`;

export const MODIFY_CRITERIA = gql`
    mutation MODIFY_CRITERIA ($id: ID, $newCriteria: CriteriaInput) {
        modifyCriteria(id: $id, newCriteria: $newCriteria) {
            status
            success 
        }
    }
`;

export const CREATE_ISSUE = gql`
    mutation CREATE_ISSUE ($newIssue: NewIssueInput) {
        createIssue(
            newIssue: $newIssue
        ) {
            status
            success 
        }
    }
`;

export const MODIFY_ISSUES = gql`
    mutation MODIFY_ISSUES ($newIssues: [EditIssueInput], $notify: Boolean) {
        modifyIssues( newIssues: $newIssues, notify: $notify) {
            status
            success 
        }
    }
`;

export const DELETE_FEEDBACK_DOCUMENT = gql`
    mutation DELETE_FEEDBACK_DOCUMENT ($id: ID!) {
        deleteFeedbackDocument(id: $id) {
            status,
            success
        }
    }
`;

export const MODIFY_MEMBERSHIP = gql`
    mutation MODIFY_MEMBERSHIP ($id: ID, $deleted: Boolean, $organizationId: ID) {
        modifyMembership(id: $id, deleted: $deleted, organizationId: $organizationId) {
            status
            success 
        }
    }
`;

export const MODIFY_NOTIFICATION_PREFERENCES = gql`
    mutation MODIFY_NOTIFICATION_PREFERENCES ($newPreferences: [NotificationPreferenceInput]) {
        modifyNotificationPreferences(
            newPreferences: $newPreferences
        ) {
            status
            success 
        }
    }
`;

export const CREATE_OBLIGATIONS = gql`
    mutation CREATE_OBLIGATIONS ($obligatorId: ID, $submissionTypeIds: [ID]) {
        createObligations(
            obligatorId: $obligatorId,
            submissionTypeIds: $submissionTypeIds
        ) {
            status
            success 
        }
    }
`;

export const DELETE_OBLIGATIONS = gql`
    mutation DELETE_OBLIGATIONS ($obligatorId: ID, $submissionTypeIds: [ID]) {
        deleteObligations(
            obligatorId: $obligatorId
            submissionTypeIds: $submissionTypeIds,
        ) {
            status
            success 
        }
    }
`;

export const CREATE_ORGANIZATION = gql`
    mutation CREATE_ORGANIZATION ($newOrganization: OrganizationInput) {
        createOrganization(newOrganization: $newOrganization) {
            id
            status
            success
        }
    }
`;

export const MODIFY_ORGANIZATION = gql`
    mutation MODIFY_ORGANIZATION ($id: ID!, $newOrganization: OrganizationInput) {
        modifyOrganization(id: $id, newOrganization: $newOrganization) {
            status
            success
        }
    }
`;

export const CREATE_REACTIONS = gql`
    mutation CREATE_REACTIONS ($newReactions: [ReactionInput]) {
        createReactions(newReactions: $newReactions) {
            status
            success 
        }
    }
`;

export const MODIFY_REVIEW = gql`
    mutation MODIFY_REVIEW ($id: ID, $completed: Boolean, $submissionDocumentId: ID, $submissionId: ID, $notify: Boolean) {
        modifyReview(
            id: $id,
            completed: $completed,
            submissionDocumentId: $submissionDocumentId,
            submissionId: $submissionId,
            notify: $notify
        ) {
            status
            success 
        }
    }
`;

export const CREATE_REVIEWER_ASSIGNMENT = gql`
    mutation CREATE_REVIEWER_ASSIGNMENT ($newAssignment: AssignmentInput) {
        createReviewerAssignment(
            newAssignment: $newAssignment
        ) {
            id
            status 
            success 
        }
    }
`;

export const CREATE_SUBMISSION = gql`
    mutation CREATE_SUBMISSION (
        $newSubmission: SubmissionInput,
        $newSubmissionDocuments: [SubmissionDocumentInput],
        $submissionTypeId: ID
    ) {
        createSubmission(
            newSubmission: $newSubmission,
            newSubmissionDocuments: $newSubmissionDocuments,
            submissionTypeId: $submissionTypeId
        ) {
            id
            status
            success 
        }
    }
`;

export const MODIFY_SUBMISSION = gql`
    mutation MODIFY_SUBMISSION ($updateSubmission: SubmissionInput) {
        modifySubmission(updateSubmission: $updateSubmission) {
            id
            status 
            success 
        }
    }
`;

export const CREATE_DOCUMENT_TYPE = gql`
    mutation CREATE_DOCUMENT_TYPE (
        $documentTypeName: String,
        $submissionTypeId: ID,
    ) {
        createDocumentType(
            documentTypeName: $documentTypeName,
            submissionTypeId: $submissionTypeId,
        ) {
            id
            status 
            success
        }
    }
`;

export const DELETE_DOCUMENT_TYPE = gql`
    mutation DELETE_DOCUMENT_TYPE (
        $documentTypeId: ID
    ) {
        deleteDocumentType(
            id: $documentTypeId
        ) {
            status
            success 
        }
    }
`;

export const CREATE_SUBMISSION_TYPE = gql`
    mutation CREATE_SUBMISSION_TYPE ($newSubmissionType: SubmissionTypeInput, $contractTypeVersionId: ID) {
        createSubmissionType(newSubmissionType: $newSubmissionType, contractTypeVersionId: $contractTypeVersionId) {
            id
            status
            success 
        }
    }
`;

export const MODIFY_SUBMISSION_TYPE = gql`
    mutation MODIFY_SUBMISSION_TYPE ($id: ID!, $newSubmissionType: SubmissionTypeInput , $contractTypeVersionId: ID) {
        modifySubmissionType(id: $id, newSubmissionType: $newSubmissionType, contractTypeVersionId :$contractTypeVersionId ) {
            status
            success 
        }
    }
`;

export const MODIFY_DUE_DATE = gql`
    mutation MODIFY_DUE_DATE ($timelineId: ID!, $newDueDate: IsoDate, $active: Boolean) {
        modifyDueDate(timelineId: $timelineId, newDueDate: $newDueDate, active: $active) {
            status
            success
        }
    }
`;

export const CREATE_USER = gql`
    mutation CREATE_USER ($newUser: UserInput) {
        createUser(newUser: $newUser) {
            status,
            success,
            id
        }
    }
`;

export const MODIFY_USER = gql`
    mutation MODIFY_USER ($id: ID!, $newUser: UserInput) {
        modifyUser(id: $id, newUser: $newUser) {
            status,
            success
        }
    }
`;

export const CREATE_VERSION = gql`
    mutation CREATE_VERSION (
        $reviewerIds: [ID],
        $submissionId: ID,
        $status:String
        $submissionDocuments: [SubmissionDocumentInput],
        $newSubmissionDocuments: [SubmissionDocumentInput],
        $submissionTypeId: ID,
        $attestationFlag: Boolean,
        $attestationRoleName: String,
        $reportTemplateVersion:String,
        $adhocEndDate:String
    ) {
        createVersion(
            reviewerIds: $reviewerIds,
            submissionId: $submissionId,
            status: $status
            submissionDocuments: $submissionDocuments
            newSubmissionDocuments: $newSubmissionDocuments
            submissionTypeId: $submissionTypeId,
            attestationFlag: $attestationFlag,
            attestationRoleName: $attestationRoleName,
            reportTemplateVersion:$reportTemplateVersion,
            adhocEndDate: $adhocEndDate
        ) {
            status
            success 
        }
    }
`;

export const MODIFY_VERSION = gql`
    mutation MODIFY_VERSION (
        $id: ID,
        $requestForArchive:Boolean
        $archived: Boolean
        $archiveReasonId: ID 
        $submissionId: ID
        $action: String
        $dqStatus: String
    ) {
        modifyVersion(
            id: $id,
            requestForArchive: $requestForArchive,
            archived: $archived,
            archiveReasonId: $archiveReasonId,
            submissionId: $submissionId,
            dqStatus : $dqStatus
            action :$action
        ) {
            status
            success 
        }
    }
`;

export const CREATE_CATEGORY = gql`
    mutation($categoryInput: CategoryInput) {
        createCategory(categoryInput: $categoryInput) {
            status
            success
            id 
        }
    }
`;

export const MODIFY_CATEGORY = gql`
    mutation($categoryInput: CategoryInput) {
        modifyCategory(categoryInput: $categoryInput) {
            status
            success 
        }
    }
`;

export const CREATE_BUSINESS_UNIT = gql`
    mutation CREATE_BUSINESS_UNIT ($businessUnitName: String) {
        createBusinessUnit(businessUnitName: $businessUnitName) {
            status
            success 
        }
    }
`;

export const MODIFY_BUSINESS_UNIT = gql`
    mutation MODIFY_BUSINESS_UNIT ($id: ID!, $businessUnitName: String, $deleted: Boolean) {
        modifyBusinessUnit(id: $id, businessUnitName: $businessUnitName, deleted: $deleted) {
            status 
            success 
        }
    }
`;

export const CREATE_CONSTANT = gql`
mutation CREATE_CONSTANT ($constant: ConstantInput) {
    createConstant(constant: $constant) {
        id
        status
        success 
    }
}
`;
export const MODIFY_CONSTANT = gql`
    mutation MODIFY_CONSTANT ($id: ID!, $deleted: Boolean, $type: String) {
        modifyConstant(id: $id, deleted: $deleted, type: $type) {
            status 
            success 
        }
    }
`;

export const CREATE_LIBRARY_FOLDER = gql`
    mutation CREATE_LIBRARY_FOLDER ($libraryFolderInput: LibraryFolderInput ) {
        createLibraryFolder(libraryFolderInput: $libraryFolderInput) {
            status
            success 
        }
    }
`;

export const CREATE_LIBRARY_DOCUMENT = gql`
    mutation CREATE_LIBRARY_DOCUMENT ($libraryDocumentInput: LibraryDocumentInput) {
        createLibraryDocument(libraryDocumentInput: $libraryDocumentInput) {
            id 
            status
            success 
        }
    }
`;

export const CREATE_LIBRARY_DOCUMENT_VERSION = gql`
    mutation CREATE_LIBRARY_DOCUMENT_VERSION ($libraryDocumentInput: LibraryDocumentInput) {
        createLibraryDocumentVersion(libraryDocumentInput: $libraryDocumentInput) {
            id 
            status
            success 
        }
    }
`;

export const MODIFY_SUBMISSION_TYPE_REPORT_TEMPLATE = gql`
    mutation MODIFY_SUBMISSION_TYPE_REPORT_TEMPLATE (
        $reportTemplateId: ID!,
        $submissionTypesIds: [ID!]!,
        $deleted: Boolean!
    ) {
        modifySubmissionTypesReportTemplate(
            reportTemplateId: $reportTemplateId,
            submissionTypesIds: $submissionTypesIds,
            deleted: $deleted
        ) {
            id 
            status
            success 
        }
    }
`;

export const REUPLOAD_SUBMISSION_DOCUMENT = gql`
    mutation REUPLOAD_SUBMISSION_DOCUMENT ($DocumentInput: DocumentInput) {
        reUploadSubmissionDocument(DocumentInput: $DocumentInput) {
            status
            success 
        }
    }
`;

export const REUPLOAD_LIBRARY_DOCUMENT = gql`
    mutation REUPLOAD_LIBRARY_DOCUMENT ($DocumentInput: DocumentInput) {
        reUploadLibraryDocument(DocumentInput: $DocumentInput) {
            status
            success 
        }
    }
`;

export const CREATE_CONTRACT_TYPE_CATEGORY = gql`
    mutation CREATE_CONTRACT_TYPE_CATEGORY ($input: ContractTypeCategoryInput) {
        createContractTypeCategory(contractTypeCategoryInput: $input) {
            status
            success 
        }
    }
`;

export const MODIFY_CONTRACT_TYPE_CATEGORY = gql`
    mutation MODIFY_CONTRACT_TYPE_CATEGORY ($input: ContractTypeCategoryInput) {
        modifyContractTypeCategory(contractTypeCategoryInput: $input) {
            status
            success 
        }
    }
`;

export const MODIFY_LIBRARY_FOLDER = gql`
    mutation MODIFY_LIBRARY_FOLDER ($id: ID!,  $active: Boolean) {
        modifyLibraryFolder(id: $id, active: $active) {
            status
            success 
        }
    }
`;

export const MODIFY_LIBRARY_DOCUMENT = gql`
    mutation MODIFY_LIBRARY_DOCUMENT ($id: ID!,  $active: Boolean, $startDate: IsoDate){
        modifyLibraryDocument(id: $id, active: $active, startDate: $startDate) {
            status
            success 
        }
    }
`;

export const MODIFY_RELATED_DOCUMENT_STATUS = gql`
    mutation MODIFY_RELATED_DOCUMENT_STATUS ($submissionDocumentId: ID!, $issueId: ID!, $resolved: Boolean!) {
        modifyRelatedDocumentStatus(
            submissionDocumentId: $submissionDocumentId,
            issueId: $issueId,
            resolved: $resolved
        ) {
            id 
            status 
            success 
        }
    }
`;

export const MODIFY_REACTIONS = gql`
    mutation MODIFY_REACTIONS (
        $newReactions: [ReactionInput]
    ) {
        modifyReactions(
            newReactions: $newReactions 
        ) {
            status 
            success 
        }
    }
`;

export const CHANGE_RELATED_DOCUMENTS = gql`
    mutation CHANGE_RELATED_DOCUMENTS (
        $submissionDocumentIds: [ID!]! 
        $issueId: ID!
    ) {
        changeRelatedDocuments(
            submissionDocumentIds: $submissionDocumentIds
            issueId: $issueId
        ) {
            status 
            success 
        } 
    }
`;

export const CREATE_TIMELINE_COMMENT = gql`
    mutation CREATE_TIMELINE_COMMENT (
        $newTimelineComment: TimelineCommentInput 
    ) {
        createTimelineComment (
            newTimelineComment: $newTimelineComment 
        ) {
            status 
            success 
        }
    }
`;

export const MODIFY_TIMELINE_COMMENT = gql`
    mutation MODIFY_TIMELINE_COMMENT (
        $id: ID! 
        $content: String 
    ) { 
        modifyTimelineComment (
            id: $id 
            content: $content 
        ) {
            status 
            success 
        }
    }
`;

export const CREATE_FEEDBACK_DOCUMENT = gql`
    mutation CREATE_FEEDBACK_DOCUMENT (
        $issueId: ID! 
        $feedbackDocumentInput: FeedbackDocumentInput 
    ) {
        createFeedbackDocument (
            issueId: $issueId 
            feedbackDocumentInput: $feedbackDocumentInput 
        ) {
            status 
            success 
        }
    }
`;

export const MODIFY_FEEDBACK_DOCUMENT = gql`
    mutation MODIFY_FEEDBACK_DOCUMENT (
        $id: ID! 
        $feedbackDocumentInput: FeedbackDocumentInput 
    ) {
        modifyFeedbackDocument (
            id: $id 
            feedbackDocumentInput: $feedbackDocumentInput 
        ) {
            status 
            success 
        }
    }
`;

export const CANCEL_AUDIT_REPORT_EXPORT = gql`
    mutation CANCEL_AUDIT_REPORT_EXPORT ($exportId: ID!) {
        cancelAuditReportExport(exportId: $exportId) {
            status 
            success
            wasCancelled
            requestLog {
                id 
                dateOfLastSuccessfulExport 
            }
        }
    }
`;

export const CREATE_REPORT_TEMPLATE = gql`
    mutation CREATE_REPORT_TEMPLATE (
        $reportTemplateInput: ReportTemplateInput
    ) {
        createReportTemplate (
            reportTemplateInput: $reportTemplateInput
        ) {
            id 
            status 
            success 
        }
    }
`;