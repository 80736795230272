import React from "react";
import { EXPORTSIZE, PRIMARY_REVIEWER } from "../../constants/index.js";
import { CsvExport } from "../common/CsvExport/index.js";
import { submissionTypesQuery } from "./query.js";

export const csvHeaders = [
    { label: "ID", key: "id" },
    { label: "Name", key: "name" },
    { label: "Primary Reviewer", key: "primaryReviewer" },
    { label: "Contract Type", key: "contractType" },
    { label: "Business Unit", key: "businessUnit" },
    { label: "Category", key: "category" }
];

const checkPrimaryReviewer = (reviewerAssignments) =>{
    const assignment = reviewerAssignments.find((assignment) => assignment.role.specifier === PRIMARY_REVIEWER);
    const primaryReviewer = assignment ? assignment.assignee.name : "";
    return primaryReviewer;
};

const transformSubmissionTypesDataForCSV = (data) => {
    const csvDataArray = data.submissionTypesList.submissionTypes.map((submissionType) => {
        return {
            id: submissionType.specifier,
            name: submissionType.name,
            primaryReviewer: submissionType.reviewerAssignments.length > 0 ? checkPrimaryReviewer(submissionType.reviewerAssignments) : "",
            contractType: submissionType.contractType.name,
            businessUnit: submissionType.businessUnit.name,
            category: submissionType.categoryName
        };
    });
    return csvDataArray;
};

export const SubmissionTypesCsvExport = ({variables}) => {
    return <CsvExport
        csvFilename={"Submission_Types_Report.csv"}
        csvHeaders={csvHeaders}
        query={submissionTypesQuery}
        variables={{...variables, offset: 0, limit: EXPORTSIZE}}
        transformData={transformSubmissionTypesDataForCSV}
    />;
};