import React from "react";
import { Table as AntdTable } from "antd";
import { ExportButtonBar } from "../ExportButtonBar/index.js";


export const Table = ({
    id,
    columns,
    dataSource, 
    loading,
    rowKey,
    className,
    rowSelection,
    pagination = false,
    size = "middle",
    expandedRowRender,
    onChange,
    rowExpandable,
    clearFiltersButton = null,
    exportButton = null,
    rightAlignedButtonBarElement = null,
    buttonBarStyle,
    ...otherProps
}) => {
    const renderButtonBar = Boolean(clearFiltersButton || exportButton || rightAlignedButtonBarElement);
    return (
        <>
            { renderButtonBar
                ? <ExportButtonBar 
                    clearFiltersButton={clearFiltersButton} 
                    exportButton={exportButton}
                    rightAligned={rightAlignedButtonBarElement}
                    style={buttonBarStyle}
                /> 
                : null 
            }
            <AntdTable
                className={className ? className : "TABLE"}
                onChange={onChange}
                id={id}
                size={size}
                columns={columns}
                loading={loading}
                dataSource={dataSource}
                rowKey={rowKey}
                rowSelection={rowSelection}
                pagination={pagination}
                expandedRowRender={expandedRowRender}
                rowExpandable={rowExpandable}
                { ...otherProps }
            />
        </>
    );
};