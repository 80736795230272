import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { LoadingContent } from "../LoadingContent/index.js";

export const PCDURedirect = ({ to, redirectIf, children }) => {
    const navigate = useNavigate();
    
    useEffect(() => {
        if (typeof redirectIf === "function") {
            if (redirectIf()) {
                navigate(to);
            }
        } else {
            navigate(to);
        }
    }, []);

    return typeof redirectIf === "function" && redirectIf() ? <LoadingContent /> : children;
};