import { useLazyQuery } from "@apollo/client";
import { Button, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { EXPORTSIZE } from "../../../constants/index.js";
import { ExportOutlined } from "@ant-design/icons";
import { 
    handleNotification, 
    resetExportNotificationState, 
    DEFAULT_MESSAGE_DURATION, 
    ERROR_MESSAGE 
} from "./exportNotifications.js";

const BATCH_SIZE = 100;
const MAX_FETCHES = 10;
const BASIC_STYLE = { marginBottom: "15px" };
const RIGHT_ALIGN_DEFAULT_STYLE = {
    float: "right",
    display: "inline-block",
    ...BASIC_STYLE 
};

let totalFetches = 0;

const onComponentUnmounting = () => {
    // clean up non-react state for this component and the export notification widget when this component unmounts :: 
    resetExportNotificationState();
    totalFetches = 0;
};

export const CsvExport = ({
    query,
    dangerouslyOverrideExportSize = false,
    customMessage = null,
    variables,
    transformData,
    csvFilename,
    fetchInBatches = false,
    csvHeaders = [],
    csvHeadersGetter,
    style,
    rightAligned = false,
    batchExport = false
}) => {
    const appliedStyles = style && typeof style === "object" ? style : {};
    const styleToUse = Object.assign(rightAligned ? RIGHT_ALIGN_DEFAULT_STYLE : BASIC_STYLE, appliedStyles);

    const csvLinkRef = useRef();
    const [clicked, setClicked] = useState(false);
    const [csvData, setCsvData] = useState([]);
    const [headersToUse, setHeadersToUse] = useState(null);

    const [fetchData, { loading, data, error }] = useLazyQuery(query, { fetchPolicy: "no-cache" }); 

    const resetState = () => {
        setClicked(false);
        setCsvData([]);
        totalFetches = 0;
    };


    const initiateDownload = () => {
        const timeoutId = setTimeout(() => {
            clearTimeout(timeoutId);
            csvLinkRef.current.link.click();
            resetState();
        }, 0);
    };

    useEffect(() => {
        if (clicked && !loading && data && !batchExport) {
            let headers = csvHeaders;
            const csvDataArray = transformData(data);
            
            if (!dangerouslyOverrideExportSize && csvDataArray.length === EXPORTSIZE) {
                handleNotification(customMessage);
            }
            
            if (typeof csvHeadersGetter === "function") {
                headers = csvHeadersGetter(data);
            }
            setHeadersToUse(Array.isArray(headers) ? headers : []);
        }
        if (batchExport && clicked && !loading && data) {
            const notificationType = data?.auditReport?.success ? "success" : "error";
            handleNotification(data.auditReport.status, DEFAULT_MESSAGE_DURATION, notificationType);
        }
    }, [clicked, loading, data]);

    useEffect(() => {
        if (clicked && !loading && data && !error  && !batchExport) {
            const newCsvData = transformData(data);

            if (fetchInBatches !== true) { 
                if (newCsvData.length >= EXPORTSIZE) {
                    handleNotification();
                }
                
                setCsvData(newCsvData);
            } else {
                const dataToUse = [...csvData, ...newCsvData];
                if (dataToUse.length >= EXPORTSIZE) {
                    handleNotification();
                }
                setCsvData(dataToUse.slice(0, EXPORTSIZE + 1));
            }
        }
    }, [clicked, loading, data]);

    useEffect(() => {
        if (!error && csvData.length > 0 && csvLinkRef.current?.link) {
            if (fetchInBatches !== true) {
                initiateDownload();
            } else {
                const keepFetching = csvData.length < EXPORTSIZE && csvData.length % BATCH_SIZE === 0 && totalFetches < MAX_FETCHES;
                if (keepFetching) {
                    fetchData({
                        variables: {
                            ...variables,
                            offset: csvData.length,
                            limit: BATCH_SIZE
                        }
                    });
                    totalFetches++;
                } else {
                    initiateDownload();
                }
            }
        }
    }, [csvData]);

    useEffect(() => {
        if (error) {
            handleNotification(ERROR_MESSAGE, DEFAULT_MESSAGE_DURATION, "error");
            resetState();
        }

        return onComponentUnmounting;
    }, [error]);

    return <>
        <Button
            type={"primary"}
            style={styleToUse}
            disabled={loading}
            icon={<ExportOutlined />}
            onClick={async () => {
                fetchData({ 
                    variables: {
                        ...variables, 
                        offset: 0,
                        limit: batchExport ? null : (fetchInBatches === true ? BATCH_SIZE : EXPORTSIZE),
                        batchExport: true
                    } 
                });
                setClicked(true);
                totalFetches++;
            }}
        >
            {loading ? <Spin /> : "Export"}
        </Button>
        <CSVLink
            data={csvData}
            filename={csvFilename}
            headers={headersToUse}
            ref={csvLinkRef}
        />
    </>;
};
