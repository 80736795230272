import { gql } from "@apollo/client";

export const businessUnitSettingsQuery = gql`
    query BusinessUnitSettingsQuery (
        $limit: Int
        $offset: Int
        $activeFilter: Boolean
        $nameSearch: String
        $sortOn: String
        $sortBy: String
    ) {
        businessUnitsList (
            limit: $limit
            offset: $offset
            nameSearch: $nameSearch
            activeFilter :$activeFilter
            sortOn: $sortOn
            sortBy: $sortBy
        ) {
            businessUnits {
                id
                name
                deleted
            } 
            count
        }
    }
`; 

export const categoriesSettingsQuery = gql`
    query CategoriesSettingsQuery (
        $limit: Int
        $offset: Int
        $activeFilter: Boolean
        $nameSearch: String
        $sortOn: String
        $sortBy: String
    ) {
        categoriesList (
            limit: $limit
            offset: $offset
            nameSearch: $nameSearch
            activeFilter :$activeFilter
            sortOn: $sortOn
            sortBy: $sortBy
        ) {
            categories {
                id
                name
                deleted
                editableFields
            } 
            count
        }
    }
`;

export const categoryDetail = gql`
    query(
        $id: ID!
    ) {
        category(id: $id) {
            id
            name
            deleted
            readonlyFinalizedFlag
            finalVersionRequestFlag
            secondaryReviewerFlag
            secondaryNotifierFlag
            bundledSubmissionFlag
            dqEnabledFlag 
            bidpEnabledFlag
            editableFields
        }
    }
`;

export const otherSettingsQuery = gql`
    query OtherSettingsQuery (
        $limit: Int
        $offset: Int
        $activeFilter: Boolean
        $type: String
        $sortOn: String
        $sortBy: String
        $sortAsNumber: Boolean
    ) {
        constantsType (
            limit: $limit
            offset: $offset
            activeFilter: $activeFilter
            type: $type
            sortOn: $sortOn
            sortBy: $sortBy
            sortAsNumber: $sortAsNumber 
        ) {
            constantsType {
                id
                text
                deleted
            } 
            count
        }
    }
`; 