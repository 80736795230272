import React ,  { useEffect } from "react";
import { Button } from "antd";
import { useParams } from "react-router-dom";
import { CenteredBoxWithLogo } from "../common/CenteredBoxWithLogo/index.js";
import { AuthenticationLayout } from "../common/AuthenticationLayout/index.js";
import { PersistentState } from "../../utils/PersistentState.js";

const { resetAllPersistentState } = PersistentState();

const Login = () => {
    const params = useParams();
    let message = "Log in to access the application";

    if (params.reason === "inactive") {
        message = "You have been logged out due to inactivity.  Please log in again to continue using the application.";
    }
    
    useEffect(() => {
        let myStorage = window.sessionStorage;
        // eslint-disable-next-line no-restricted-globals
        myStorage.setItem("currentPath", location.pathname);
    });

    return (
        <AuthenticationLayout>
            <CenteredBoxWithLogo showWarningMsg="true">
                <h3 style={{
                    color: "#ffffff",
                    textAlign: "center"
                }}>{message}</h3>
                <Button
                    type="primary"
                    size="large"
                    block
                    onClick={() => {
                        resetAllPersistentState();
                        window.location.replace("/login");
                    }}
                    style={{
                        marginTop: "10px"
                    }}
                >
                    Log In
                </Button>
                
            </CenteredBoxWithLogo>
            
        </AuthenticationLayout>
    );
};

export default Login;
