import { gql } from "@apollo/client";

export const archiveFoldersQuery = gql`
    query ArchiveFoldersQuery {
        archiveFolders 
            {
                id 
                name 
                folderPath
                deleted
            }
        }
`;
