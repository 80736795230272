import { gql } from "@apollo/client";

export const issueDetailQuery = gql`
    query IssueDetailQuery ($id: ID!) {
        issue (id: $id) {
            id
            specifier
            reason
            status
            severity
            readyForReview
            raisedInReview {
                reviewer {
                    id
                    name
                }
                submissionVersion {
                    specifier 
                }
            }
            submission {
                id 
                status 
                specifier 
                submitter {
                    id
                    name
                }
                obligation {
                    submissionType {
                        id
                        name
                        specifier 
                        packagedSubmission 
                    }
                }
                submissionDocuments {
                    id 
                    specifier 
                    nickname 
                    documentNewestVersion {
                        id 
                        name 
                    }
                }
            }
            relatedDocuments {
                id
                nickname
                status
                specifier 
                issueResolved 
                documentNewestVersion {
                    id
                    name
                }
            }
            reactions {
                reviewerId
                disposition
                comment 
                createdAt 
                issueId 
                reviewer {
                    id 
                    name 
                    organizations {
                        id 
                        name 
                    }
                }
            }
            comments {
                id
                content
                parentId
                isCorrectionNote 
                author {
                    id 
                    name
                    organizations {
                        id 
                        name 
                    }
                }
                createdAt
                readyForReview
            }
            feedbackDocuments {
                S3UploadStatus
                active
                id 
                name 
            } 
        }
    }
`;
