import React from "react";
import { FileTextTwoTone, PartitionOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder } from "@fortawesome/free-solid-svg-icons";
import { PCDULink } from "../common/PCDULink/index.js";

const ICON_STYLE = { marginRight: "10px" };

const NO_LINK = <span>N/A</span>;

const LIBRARY_SUBJECTS = { 
    document: "document", 
    folder: "folder", 
    category: "category",
    none: "none" 
};

const getLinkType = (action) => {
    const toMatch = action.toLowerCase();
    if (toMatch.includes("document") || toMatch.includes("template")) {
        return LIBRARY_SUBJECTS.document;
    } else if (toMatch.includes("folder")) {
        return LIBRARY_SUBJECTS.folder;
    } else if (toMatch.includes("category")) {
        return LIBRARY_SUBJECTS.category;
    } else {
        return LIBRARY_SUBJECTS.none;
    }
};

const extractData = (json, subjectId) => {
    try {
        const data = JSON.parse(json);
        const normalizedContractTypeId = [
            data?.updated?.contractType,
            data?.updated?.contractTypeId,
            data?.updated?.contract_type_id,
            subjectId 
        ].find(Boolean);
        return {
            success: true,
            contractTypeId: normalizedContractTypeId,
            baseFolderName: data?.updated?.baseFolderName,
            baseFolderId: data?.updated?.baseFolder,
            documentName: data?.updated?.documentName,
            categoryName: data?.updated?.name
        };
    } catch (err) {
        return { success: false };
    }
};

const _SubjectLink = ({ 
    icon, 
    text, 
    to, 
    subjectBreadcrumb 
}) => {
    return <PCDULink 
        newBreadcrumbs={[ 
            { label: "Changelogs", path: "/changelogs" }, 
            subjectBreadcrumb
        ]}
        to={to}
    > 
        { icon }
        { text } 
    </PCDULink>;
};

export const LibraryChangelogsSubjectLink = ({ 
    action, 
    subjectId, 
    data: json, 
    subjectBreadcrumb 
}) => {
    const type = getLinkType(action);
    const {
        success,
        contractTypeId,
        baseFolderName,
        baseFolderId,
        documentName,
        categoryName
    } = extractData(json, subjectId);

    if (success !== true) {
        return NO_LINK;
    }

    switch (type) {
        case LIBRARY_SUBJECTS.none:
            return NO_LINK;
        case LIBRARY_SUBJECTS.document:
            return <_SubjectLink 
                icon={<FileTextTwoTone style={ICON_STYLE} />}
                text={documentName ?? "Go to Document"}
                to={{pathname: `/library/documents/${subjectId}`}}
                subjectBreadcrumb={subjectBreadcrumb}
            />;
        case LIBRARY_SUBJECTS.folder:
            if ([contractTypeId, baseFolderName, baseFolderId].some(datum => !datum)) {
                // cannot construct a proper link :: 
                return NO_LINK;
            } else {
                const pathname = `/library/${contractTypeId}/${baseFolderName}/${baseFolderId}`;
                const icon = <FontAwesomeIcon icon={faFolder} style={ICON_STYLE} />;
                return <_SubjectLink 
                    icon={icon}
                    text={baseFolderName}
                    to={{ 
                        pathname, 
                        state: {
                            currentFolderName: "",
                            currentFolderId: ""
                        }
                    }}
                    subjectBreadcrumb={subjectBreadcrumb}
                />;
            }
        case LIBRARY_SUBJECTS.category:
            return !contractTypeId 
                ? NO_LINK 
                : <_SubjectLink 
                    icon={<PartitionOutlined style={ICON_STYLE} />}
                    text={categoryName ?? "Go to Category"}
                    to={{ pathname: `/library/settings/${contractTypeId}` }}
                    subjectBreadcrumb={subjectBreadcrumb}
                />; 
        default:
            return NO_LINK;
    }
};