import React from "react";
import { useParams } from "react-router-dom";
import { SubmissionChangelogsQuery } from "./queries.js";
import { ChangelogsTable } from "./changelogs-table.js";
import { PCDULink } from "../common/PCDULink/index.js";

export const SubmissionChangelogs = (({
    actions,
    roles,
    subjectData,
    setSubjectData 
}) => {
    const params = useParams();
    const currentPageSubjectId = params.id;

    const subjectBreadcrumb = {
        path: "/changelogs/submissions",
        label: "Submission Changelogs"
    };
    
    return (
        <div style={{minWidth: "900px"}}>
            <ChangelogsTable 
                subjectBreadcrumb={subjectBreadcrumb}
                subjectData={subjectData}
                setSubjectData={setSubjectData}
                filterOptions={{ 
                    actions,
                    roles
                }}
                query={SubmissionChangelogsQuery}
                exportFilename={"Submission_Changelogs.csv"}
                subjectHeader={{label: "Submission", key: "specifier" }}
                subjectColumn={{
                    title: "Submission",
                    dataIndex: ["subject", "specifier"],
                    render: (highlightableText, { subject }) => {
                        return (
                            <>
                                <PCDULink 
                                    to={`/submissions/${subject.id}`}
                                    newBreadcrumbs={[ 
                                        { label: "Changelogs", path: "/changelogs" }, 
                                        subjectBreadcrumb
                                    ]}
                                > 
                                    {highlightableText} 
                                </PCDULink> 
                                { currentPageSubjectId !== subject.id && 
                                    <div>
                                        <span>
                                            <PCDULink to={`/changelogs/submissions/${subject.id}`}> Changelogs </PCDULink>
                                        </span>
                                    </div> 
                                }
                            </>
                        );
                    }
                }}
            />
        </div>
    );
});
