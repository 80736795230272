import { jsPDF } from "jspdf";

export const CreateAttestationForm = ({ name, title }) => {

    const doc = new jsPDF();

    doc.setFontSize(18);
    doc.text(`Contractor Attestation of Contractual Reporting and Information 
Requirements:`, 10, 25);

    doc.setFontSize(14);
    doc.text(`
In accordance with 42 C.F.R. § 438.606, the Contractor must provide 
certification concurrently with the submission of all reports, data, information or other 
documentation (Reports and Information) required under federal and state law and under 
this Contract to the Department. The Department has provided the appropriate formats,
instructions, submission timetables, and technical assistance to Contractor for the submission 
of reports, data and information.`, 10, 40);

    doc.setFontSize(18);
    doc.text("By choosing the Agree button, Contractor attests as follows:", 10, 90);

    doc.setFontSize(14);
    doc.text(`1. The submitted Reports and Information comply with Department required  formats, 
    instructions and submission timetables.`, 15, 100);
    doc.text(`2. All Reports and Information are hereby submitted electronically or as otherwise 
    required by the Department.`, 15, 120);
    doc.text(`3. Based on best information, knowledge, and belief, the Reports and Information are 
    accurate, complete, and truthful.`, 15, 140);
    doc.text("4. All Reports and Information are specific to each region covered in the contract.", 15, 160);
    doc.text(`5. If not signed by the Chief Executive Officer (CEO) or Chief Financial Officer (CFO),
    the person signing reports directly to the CEO or CFO and has been delegated 
    authority by the CEO or CFO to sign.`, 15, 175);

    doc.setFontSize(18);
    doc.text("Accept Terms and Conditions:", 10, 210);
    doc.setFontSize(14);
    doc.text("Accepted", 100, 210);
    doc.setFontSize(18);
    doc.text("Attesting Authority Name:", 10, 225);
    doc.setFontSize(14);
    doc.text(name, 100, 225);
    doc.setFontSize(18);
    doc.text("Attesting Authority Title:", 10, 240);
    doc.setFontSize(14);
    doc.text(title, 100, 240);

    doc.save("attestation.pdf");

};