import { gql } from "@apollo/client";

export const documentsQuery = gql`
    query DocumentsQuery (
        $limit: Int
        $offset: Int
        $sortOn: String
        $sortBy: String
        $createdDateFilter: [String],
        $deletedFilter: [Boolean],
        $submissionTypeFilter: [ID],
        $submitterFilter: [ID],
        $documentTypeFilter: [ID],
        $contractTypesFilter: [ID],
        $businessUnitFilter: [String],
        $statusFilter: [String],
        $documentUploadStatusFilter: [String],
        $categoryFilter:[ID],
        $nameSearch: String,
        $documentSpecifierSearch: String,
        $primaryReviewerSearch: String,
        $yearFilter: Int
    ) {
        submissionDocumentsList (
            excludeCount: true, 
            limit: $limit,
            offset: $offset,
            sortOn: $sortOn,
            sortBy: $sortBy,
            createdDateFilter: $createdDateFilter,
            categoryFilter: $categoryFilter,
            submissionTypeFilter: $submissionTypeFilter,
            submitterFilter: $submitterFilter,
            deletedFilter: $deletedFilter,
            documentTypeFilter: $documentTypeFilter,
            contractTypesFilter: $contractTypesFilter,
            businessUnitFilter: $businessUnitFilter,
            nameSearch: $nameSearch,
            statusFilter: $statusFilter,
            documentUploadStatusFilter: $documentUploadStatusFilter,
            documentSpecifierSearch: $documentSpecifierSearch,
            primaryReviewerSearch: $primaryReviewerSearch,
            yearFilter: $yearFilter
        ) {
            submissionDocuments {
                id
                specifier
                nickname
                status
                documentUploadStatus
                deleted
                createdAt
                primaryReviewerName
                documentType {
                    id
                    specifier
                    name
                }
                submission {
                    status
                    uploadStatus
                    submitter {
                        id
                        name
                    }
                    obligation {
                        submissionType {
                            id
                            name
                            specifier
                            category {
                                id 
                                name 
                                readonlyFinalizedFlag 
                            }
                            businessUnit {
                                id
                                name
                            }
                            contractType {
                                id
                                name
                            }
                        }
                    }
                }
                documentNewestVersion {
                    id
                    name
                    createdAt
                }
                documentVersions {
                    specifier
                    id
                    name
                }
            }
        }
    }
`;

export const documentsCountQuery = gql`
    query(
        $createdDateFilter: [String],
        $deletedFilter: [Boolean],
        $submissionTypeFilter: [ID],
        $submitterFilter: [ID],
        $documentTypeFilter: [ID],
        $contractTypesFilter: [ID],
        $businessUnitFilter: [String],
        $statusFilter: [String],
        $documentUploadStatusFilter: [String],
        $categoryFilter:[ID],
        $nameSearch: String,
        $documentSpecifierSearch: String,
        $primaryReviewerSearch: String,
        $yearFilter: Int 
    ) {
        submissionDocumentsList (
            excludeCount: false,
            limit: 1,
            createdDateFilter: $createdDateFilter,
            categoryFilter: $categoryFilter,
            submissionTypeFilter: $submissionTypeFilter,
            submitterFilter: $submitterFilter,
            deletedFilter: $deletedFilter,
            documentTypeFilter: $documentTypeFilter,
            contractTypesFilter: $contractTypesFilter,
            businessUnitFilter: $businessUnitFilter,
            nameSearch: $nameSearch,
            statusFilter: $statusFilter,
            documentUploadStatusFilter: $documentUploadStatusFilter,
            documentSpecifierSearch: $documentSpecifierSearch,
            primaryReviewerSearch: $primaryReviewerSearch,
            yearFilter: $yearFilter 
        ) {
            count 
        }
    }
`;