import React from "react";
import { formatTimeValue } from "../../../utils/index.js"; 
import { Divider } from "antd";

export const CriteriaVersionDrilldown = ({ 
    criterion, 
    title, 
    version, 
    returnData = false,
    hideArchiveStatus = false, 
    hideBottomBorder = false, 
    hideCreatedAt = false, 
    previousCreatedAt = null 
}) => {
    const { 
        id, 
        deleted,
        citation,
        description
    } = criterion; 

    const createdAt = parseInt(criterion?.createdAt, 10);
    const start = parseInt(previousCreatedAt, 10); 
    const end = createdAt;
    
    let timeDescription, archiveStatus;
    if (Number.isNaN(createdAt)) {
        timeDescription = null;
    } else if (!Number.isNaN(start)) {
        timeDescription = (
            <p> 
                <span> {`${ deleted ? "Archived from" : "In effect from" }`} </span> 
                <span> {formatTimeValue(start, true)} to {formatTimeValue(end, true)}  </span> 
            </p>
        );
    } else {
        timeDescription = <p> <span>Created at</span> <br/> <span> {formatTimeValue(createdAt, true)} </span> </p>; 
    }

    if (deleted) {
        archiveStatus = <p> Status: <span style={{ color: "red", fontWeight: "700" }}> Archived </span></p>;
    } else { 
        // currently active, previous version archived -- display explicit De-Archived status :: 
        archiveStatus = <p> Status: <span style={{ color: "green", fontWeight: "700" }}> Active </span></p>;
    } 

    
    if (returnData) {
        return {
            id: criterion.id,
            status: deleted ? "Archived" : "Active",
            citation,
            description,
            version,
            deleted,
            start: formatTimeValue(start, true),
            end: formatTimeValue(end, true)
        };
    } else {
        return (
            <div style={{ marginLeft: "10px" }} key={id}> 
                { title && typeof title === "string" && <p style={{ fontWeight: 900 }}> { title } </p> }
                { hideArchiveStatus !== true && archiveStatus ? archiveStatus : null }
                <p> <span>Citation</span> <br/> <span> {citation} </span> </p>
                <p> <span>Description</span> <br/> <span> {description} </span> </p>
                { hideCreatedAt === true ? null : timeDescription }
                { hideBottomBorder === true ? null : <Divider /> }
            </div>
        );
    }
};
