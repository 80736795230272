import React, { useState, useContext, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { UserContext } from "../Application/UserContext.js";
import { Button, Modal } from "antd";
import { Table } from "../common/Table/index.js";
import { SelectableDetail } from "../common/SelectableDetail/index.js";
import { PCDULink } from "../common/PCDULink/index.js";
import {
    CREATE_MEMBERSHIP,
    MODIFY_MEMBERSHIP,
    MODIFY_USER
} from "../../constants/mutations.js";
import { handleMutation } from "../../utils/errorHandling.js";

export const OrganizationTable = (({
    newBreadcrumbs,
    userDetails,
    assignmentData,
    creating,
    refetch
}) => {
    const [createMemberships, setCreateMemberships] = useState(false);
    const [modifyMemberships, setModifyMemberships] = useState(false);
    const [membershipsModalVis, setMembershipsModalVis] = useState(false);
    const [modified, setModified] = useState({});
    const [loading, setLoading] = useState(false);
    const [createMembership] = useMutation(CREATE_MEMBERSHIP);
    const [modifyMembership] = useMutation(MODIFY_MEMBERSHIP);
    const [modifyUser] = useMutation(MODIFY_USER);
    const [userDetail, setUserDetail] = useState(userDetails);
    const [assignableOrgs, setAssignableOrgs] = useState([]);

    const {
        userPermEditUsers,
        userPermCreateOrgMembership
    } = useContext(UserContext);

    useEffect(()=>{
        if (userDetails) {
            setUserDetail(userDetails);
        }
    }, [userDetails]);

    useEffect(() => {
        if (assignmentData.data) {
            const orgsList = assignmentData?.data?.organizationsList?.organizations;

            const dhhsOrg = orgsList.find(({ specifier }) => specifier === "DHHS");
            if (userDetails) {
                if (userDetails.serviceAccount) {
                    setAssignableOrgs((assignableOrgs) => [
                        ...assignableOrgs,
                        dhhsOrg
                    ]);
                } else {
                    setAssignableOrgs((assignableOrgs) => [
                        ...assignableOrgs,
                        ...orgsList
                    ]);
                }
            }
        }
    }, [assignmentData]);


    const modifyUserMembership = async () => {
        const membershipId = userDetail?.memberships[0]?.id;
        const success = await handleMutation(
            modifyMembership({
                variables: {
                    id: membershipId,
                    deleted: false,
                    organizationId: modified["selectedOrganizationId"]
                }
            })
        );

        // only update user and delete previous assignments IF the modification was successful :: 
        if (success) {
            // Update User 
            const newUser = {
                attestationFlag: false,
                attestationRoleName: null
            };

            await handleMutation(
                modifyUser({
                    variables: {
                        id: userDetail?.id,
                        newUser
                    }
                })
            );
            refetch();
            setModifyMemberships(false);
            setMembershipsModalVis(false);
            setModified({});
        }
    };

    const createUserMembership = async() => {
        
        const membershipData = {
            userId: userDetail?.id,
            organizationId: modified["selectedOrganizationId"]
        };

        await handleMutation(
            createMembership({
                variables: { membershipData }
            })
        );

        refetch();
        setCreateMemberships(false);
        setMembershipsModalVis(false);
        setModified({});
    };

    const displayAddOrganization = (userPermCreateOrgMembership &&
        (creating || userDetail?.memberships?.length <= 0))
        ? "inherit" : "none";

    const modifyMembershipByAdmin = () => {
        const orgContentLoaded = !assignmentData.loading && assignmentData.data.organizationsList.organizations;
        const organizationId = userDetail?.memberships[0]?.organization.id;
        
        const selectedOrganizationId = modified.selectedOrganizationId ?? organizationId;
        const title = createMemberships ? `Add ${userDetail?.name} to an Organization` : `Update Organization for ${userDetail?.name}`;
        return orgContentLoaded ? (
            <Modal
                destroyOnClose={true}
                maskClosable={false}
                title={title}
                open={membershipsModalVis}
                onOk={async () => {
                    try {
                        setLoading(true);
                        if (createMemberships) await createUserMembership();
                        if (modifyMemberships) await modifyUserMembership();
                    } finally {
                        setLoading(false);
                    }
                }}
                okText="Save"
                okButtonProps={{
                    loading,
                    disabled: !selectedOrganizationId || selectedOrganizationId === organizationId
                }}
                cancelButtonProps={{
                    disabled: loading
                }}
                onCancel={() => {
                    setModified({});
                    setCreateMemberships(false);
                    setModifyMemberships(false);
                    setMembershipsModalVis(false);
                }}
            >
                <SelectableDetail
                    title={"Select Organization"}
                    passedKey="modifyOrganizationId"
                    multiple={false}
                    value={selectedOrganizationId}
                    defaultValue={organizationId}
                    onValueUpdated={(value) => {
                        setModified({
                            ...modified,
                            selectedOrganizationId: value
                        });
                    }}
                    options={assignableOrgs.map(({ id, name, specifier }) => {
                        return {
                            text: `${specifier} - ${name}`,
                            value: id,
                            key: id
                        };
                    })}
                />
            </Modal>
        ) : "";
    };

    let organizationTableColumn = [
        {
            title: "ID",
            dataIndex: "organization",
            key: "id",
            width: 200,
            sorter: (a, b) => a.node.specifier < b.node.specifier,
            sortDirections: ["ascend", "descend", "ascend"],
            render: ({ id, specifier }) => {
                return (
                    <PCDULink 
                        to={`/organizations/${id}`}
                        newBreadcrumbs={newBreadcrumbs}
                    >
                        {specifier}
                    </PCDULink>
                );
            }
        },
        {
            title: "Name",
            dataIndex: "organization",
            key: "name",
            width: 400,
            sorter: (a, b) => a.node.name < b.node.name,
            sortDirections: ["ascend", "descend", "ascend"],
            render: ({ id, name }) => {
                return (
                    <PCDULink 
                        to={`/organizations/${id}`}
                        newBreadcrumbs={newBreadcrumbs}
                    >
                        {name}
                    </PCDULink>
                );
            }
        }
    ];

    if (userPermEditUsers) {
        organizationTableColumn.push({
            title: "Actions",
            key: "actions",
            width: "10%",
            render: ({ id }) => {
                return (
                    <Button
                        type="danger"
                        size="small"
                        onClick={() => {
                            setModifyMemberships(true);
                            setMembershipsModalVis(true);
                            setModified({
                                ...modified,
                                id
                            });
                        }}
                    >
                        Change
                    </Button>

                );
            }
        });
    }

    return (
        <>
            <h2>Organization</h2>
            {!creating && (<Button
                style={{ display: displayAddOrganization }}
                size="small"
                id="addMembership"
                className="spaceBelow-xs btn btn-sm btn-primary"
                onClick={() => {
                    setCreateMemberships(true);
                    setMembershipsModalVis(true);
                }}
            >
                Add
            </Button>)}
            <Table
                key="organizationsTableKey"
                id="organizationsTable"
                rowKey={(record) => `org-table-${record.id}-${record?.organization?.id}`}
                columns={organizationTableColumn}
                dataSource={userDetail?.memberships ?? []}
            />
            {userPermCreateOrgMembership && modifyMembershipByAdmin()}
        </>
    );
});