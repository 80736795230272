// safe way to access into objects using a string; returns undefined instead of throwing error:: 
// e.g., safeAccess("organizations[0].name", someUser) ==> "Eastpointe"
// pass in truthy value as third parameter (default: false) to log the first error encountered in trying to access value
const safeAccess = (pathStr, obj, debug = false) => {
    try {
        // split at these characters: . [ ]
        // and remove empty strings caused by brackets
        const accessorArray = pathStr
            .split(/\u002e|\u005b|\u005d/)
            .filter((accessor) => accessor !== "");
        // there will be some empty strings from the split function if brackets were used, so filter those out::
        const value = accessorArray.reduce((currentObj, nextKey) => {
            // Note: a string value for currentObj at the wrong place can cause this function to index into
            // a string and return a single character when it should return 'undefined'
            if (typeof currentObj !== "string") {
                return currentObj[nextKey];
            } else {
                return undefined;
            }
        }, obj);
        return value;
    } catch (err) {
        if (debug) {
            console.error(err);
        }
        return undefined;
    }
};

const safeSorter = (obj1, obj2, pathStr, callback, debug = false) => {
    const valueA = safeAccess(pathStr, obj1, debug);
    const valueB = safeAccess(pathStr, obj2, debug);
    if (valueA !== undefined && valueB !== undefined) {
        return callback(valueA, valueB);
    } else {
        if (debug) {
            console.error(`Sorting Table Failed for "${pathStr}"`);
        }
        return 0;
    }
};

const safeSortCallback = (a, b) => {
    if (typeof a === "string" && typeof b === "string") {
        return a.trim().toLowerCase() > b.trim().toLowerCase() ? 1 : -1;
    } else {
        return 0;
    }
};

// wrapper function for sorting objects based on the values of the objects at a particular key (string)
const safeTableSorter = (pathStr, debug = false) => {
    return (a, b) => safeSorter(a, b, pathStr, safeSortCallback, debug);
};

export default safeTableSorter;
