import { gql } from "@apollo/client";

export const submissionNotificationsQuery = gql`
    query SubmissionNotificationsQuery (
        $categoryId: ID!
        $type: String 
    ) {
        category (id: $categoryId) {
            id 
            name 
            finalVersionRequestFlag 
        }
        notificationPreferencesByContractType(
            type: $type 
            categoryId: $categoryId
        ) {
            id 
            active 
            contractType {
                id 
                name 
                specifier 
            }
        }
        notificationPreferencesByNotificationType(
            categoryId: $categoryId
        ) {
            id
            required 
            notificationType {
                id
                name
            }
            submissionTypeCategory {
                id
                name
            }
            active
        }
        notificationPreferencesBySubmissionType(
            categoryId: $categoryId
        ) {
            id
            active
            submissionType {
                id
                name
                specifier
                categoryName
                contractType {
                    id
                    name
                }
            }
        }
    }
`;

export const libraryNotificationsQuery = gql`
    query LibraryNotificationsQuery (
        $type: String 
    ) {
        notificationPreferencesByContractType(
            type: $type 
        ) {
            id 
            active 
            contractType {
                id 
                name 
                specifier 
            }
        }
        notificationPreferencesByLibraryDocument {
            id
            active
            notificationType {
                id
                name
            }
        }
    }
`;