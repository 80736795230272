import React, { useContext } from "react";
import { DocumentUploadModal } from "../DocumentUploadModal/index.js";
import { LabeledDetail } from "../LabeledDetail/index.js";
import { UserContext } from "../../Application/UserContext.js";
import { SUBMISSION_FILE_EXTENSION_ARRAY } from "../../../utils/index.js";

const DEFAULT_TITLE = "Upload Feedback Document";

export const UploadFeedbackDocument = ({ 
    setFeedbackDocument,
    feedbackDocument, 
    title 
}) => {
    const { getFeedbackDocumentPermissions } = useContext(UserContext);
    const { canCreate } = getFeedbackDocumentPermissions();
    const titleToUse = title && typeof title === "string" ? title : DEFAULT_TITLE;
    return canCreate === true && (
        <LabeledDetail title={titleToUse}>
            <p> You can upload a feedback or &quot;redline&quot; document that contains more information about the issue </p>
            <DocumentUploadModal 
                maxDocumentCount={1}
                setUploadFileMetaData={setFeedbackDocument}
                uploadFileMetaData={feedbackDocument}
                acceptingFileTypeList={SUBMISSION_FILE_EXTENSION_ARRAY}
            />
        </LabeledDetail>
    );
};
