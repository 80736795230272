import { gql } from "@apollo/client";

export const feedbackDocumentStatusQuery = gql`
    query FeedbackDocumentStatusQuery ($id: ID!) {
        feedbackDocument (id: $id) {
            id 
            name 
            fileSize 
            S3UploadStatus 
        }
    }
`;
