import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { Modal, Input, Button } from "antd";
import { CREATE_COMMENT } from "../../constants/mutations.js";
import { handleMutation } from "../../utils/errorHandling.js";
import { isASCII } from "../../utils/functions.js";

const { TextArea } = Input;
const { confirm } = Modal;

const CorrectionComment = ({ issue, addComment }) => {
    const [content, setContent] = useState("");
    const [invalidInput, setInvalidInput] = useState(false);
    const [addingComment, setAddingComment] = useState(false);
    const [savedComment, setSavedComment] = useState("");

    let buttonText = "Add Note";
    if (content && !addingComment) {
        buttonText = "Edit";
    } else if (addingComment) {
        buttonText = "Save";
    }

    const handleButtonClick = () => {
        if (addingComment && !invalidInput) {
            addComment(issue.id, content);
            setSavedComment(content);
        } else {
            setSavedComment("");
        }

        if (!invalidInput) {
            const newValue = !addingComment;
            if (newValue === true) {
                // focus on text box
                setAddingComment(newValue);
                addComment(issue.id, null);
                const intId = setTimeout(() => {
                    const textAreaEl = document.getElementById(`text-area-${issue.id}`);
                    if (textAreaEl) {
                        textAreaEl.focus();
                    }
                    clearTimeout(intId);
                }, 0);
            } else {
                setAddingComment(newValue);                    
            }
        }
    };

    return ( 
        <div style={{
            marginTop: "10px"
        }}>
            <div style={{ marginBottom: "10px" }}> { issue.specifier } - { issue.reason } </div>
            { addingComment && <TextArea
                id={`text-area-${issue.id}`}
                style={{
                    border: invalidInput ? "2px solid red" : "",
                    marginTop: "10px"
                }}
                rows={4}
                value={content}
                onChange={(e) => {
                    const text = e.target.value;
                    if (!isASCII(text)) {
                        setInvalidInput(true); 
                    } else {
                        setInvalidInput(false);
                    }
                    setContent(text);
                }}
            /> }
            { savedComment && <div style={{ marginTop: "10px" }}> <div style={{ color: "red" }}>Correction Note</div> { savedComment } </div> }
            <Button 
                onClick={handleButtonClick}
                size="small"
                style={{ marginTop: "10px" }}
            >
                { buttonText }
            </Button>
            <div 
                className="errorMessage"
                style={{ visibility: invalidInput ? "visible" : "hidden" }}
            >
                Plain text only
            </div> 
        </div> 
    );
};


export const CorrectionNoteModal = ({
    isOpen,
    closeModal,
    submitterIssues, 
    refetch,
    latestSubmissionVersionId
}) => {
    const [allComments, setAllComments] = useState({});
    const [createComment] = useMutation(CREATE_COMMENT);
    const [disableButton, setDisableButton] = useState(false); 
    
    const addComment = (issueId, text) => {
        setAllComments({
            ...allComments,
            [issueId]: text
        });
    };

    useEffect(() => {
        const allValid = Object.values(allComments).every(comment => typeof comment === "string" && isASCII(comment));
        const atLeastOneComment = Object.values(allComments).some(comment => typeof comment === "string" && comment.trim());
        setDisableButton( (allValid && atLeastOneComment ? false : true) );
    });

    return (
        <Modal 
            title="Add Correction Notes"
            open={isOpen}
            okText={"Submit"}
            maskClosable={false}
            cancelText={"Skip"}
            okButtonProps={{ 
                disabled: disableButton
            }}
            onCancel={() => {
                confirm({
                    content: "Are you sure you want to skip adding correction notes for this upload?",
                    onOk: () => closeModal(),
                    okText: "Yes",
                    cancelText: "No"
                });
            }}
            afterClose={() => refetch()}
            onOk={async () => {
                const newComments = Object.keys(allComments).map(issueId => {
                    const content = allComments[issueId];
                    if (content && typeof content === "string") {
                        const trimmedContent = content.trim();
                        if (trimmedContent) {
                            return {
                                content: trimmedContent,
                                parentId: issueId,
                                readyForReview: true,
                                isCorrectionNote: true,
                                submissionVersionId: latestSubmissionVersionId
                            };
                        }
                    } 
                }).filter(comment => comment);

                const addComments = newComments.map(async (newComment) => {
                    return handleMutation(
                        createComment({
                            variables: {
                                newComment
                            }
                        })
                    );
                });
                await Promise.all(addComments);
                
                closeModal();
            }}
        >
            <h3> You may add a comment for the following issues </h3>
            <div> If necessary, use these comments to provide notes to DHHS regarding how the issue has been corrected in the new version of the submission that you just uploaded. </div>
            <br />
            { submitterIssues.map(issue => <CorrectionComment key={`comment-correction-note-${issue.id}`} issue={issue} addComment={addComment} />) }
        </Modal>
    );
};
