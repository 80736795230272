import { currentUserIncludesPermission} from "./functions.js";

export const currentUserIncludesChangeLogPermission = (permissionsSet) => {
    const changeLogPermissions = [ 
        "VIEW_SUBMISSION_CHANGELOGS",
        "VIEW_SUBMISSION_TYPE_CHANGELOGS",
        "VIEW_CONTRACT_TYPE_PLAN_CHANGELOGS",
        "VIEW_USER_CHANGELOGS",
        "VIEW_ORGANIZATION_CHANGELOGS",
        "VIEW_LIBRARY_CHANGELOGS",
        "VIEW_SUBMISSION_TYPE_CATEGORY_CHANGELOGS"
    ];

    const hasChangeLogPermission = [];
    for (const permission of changeLogPermissions){
        hasChangeLogPermission.push(currentUserIncludesPermission(permissionsSet, permission));
    }
    return hasChangeLogPermission.includes(true);   
};

export const checkUserChangeLogViews = ({
    userPermViewSubmissionLogs,
    userPermViewUserLogs,
    userPermViewSubmissionTypeLogs,
    userPermViewSubmissionTypeCategoryLogs,
    userPermViewContractTypePlanLogs,
    userPermViewOrganizationLogs,
    userPermViewLibraryLogs
}, changeLogArray) => {
    const changelogs = [...changeLogArray];
    const changeLogIterate = [...changeLogArray];

    let index = 0;
    for (const changelog of changeLogIterate) {
        const key = changelog.key;
        if (key === "submissions" && !userPermViewSubmissionLogs){
            changelogs.splice(index, 1);
        } else if (key === "submission-types" && !userPermViewSubmissionTypeLogs){
            changelogs.splice(index, 1);
        } else if (key === "contract-type-plans" && !userPermViewContractTypePlanLogs){
            changelogs.splice(index, 1);
        } else if (key === "user" && !userPermViewUserLogs) {
            changelogs.splice(index, 1);
        } else if (key === "organization" && !userPermViewOrganizationLogs) {
            changelogs.splice(index, 1);
        } else if (key === "libraryFolder" && !userPermViewLibraryLogs){
            changelogs.splice(index, 1); 
        } else if (key === "submissionTypeCategory" && !userPermViewSubmissionTypeCategoryLogs){
            changelogs.splice(index, 1); 
        } else {
            index = index + 1; 
        }
    }
    return changelogs;
};

