import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form } from "antd";
import { useQuery } from "@apollo/client";
import { UserContext } from "../Application/UserContext.js";
import { Main } from "../common/Main/index.js";
import { EditableDetail } from "../common/EditableDetail/index.js";
import { SelectableDetail } from "../common/SelectableDetail/index.js";
import { formatDate } from "../../utils/functions.js";
import { LoadingContent } from "../common/LoadingContent/index.js";
import { NoPermission } from "../common/NoPermission/index.js";
import { LabeledDetail } from "../common/LabeledDetail/index.js";
import { PCDULink } from "../common/PCDULink/index.js";
import { useAttemptToCreateContract } from "../../utils/attemptMutationHooks.js";
import { handleGraphQLError } from "../../utils/errorHandling.js";
import { createContractQuery } from "./query.js";


export const CreateContract = () => {
    const navigate = useNavigate();

    const [modified, setModified] = useState({});
    const [attemptToCreateContract] = useAttemptToCreateContract();
    const [contractStartDate, setContractStartDate] = useState("");
    const [contractEndDate, setContractEndDate] = useState("");

    const {
        userPermCreateContracts,
        userPermEditContracts 
    } = useContext(UserContext);

    const { loading, error, data } = useQuery(
        createContractQuery, 
        {
            variables: {
                submittingOrgList: true
            },
            fetchPolicy: "no-cache"
        }
    );

    if (error) {
        return handleGraphQLError(error);
    }

    if (loading) {
        return <LoadingContent />;
    }

    if (!userPermCreateContracts) {
        return <NoPermission />;
    }

    const { organizationsList, contractTypes } = data;
    const { organizations } = organizationsList;

    const orgOptions = organizations
        .map(({ id, name, specifier }) => {
            return {
                id,
                value: id,
                text: `${specifier} - ${name}`
            };
        });

    const contractTypeOptions = contractTypes.map(({ id, name }) => {
        return {
            id,
            value: id,
            text: name
        };
    });

    const buildContractName = () => {
        const orgSpecifier = organizations.find(({ id }) => id === modified.promisorId).specifier;
        const contractType = contractTypes.find(({ id }) => id === modified.contractTypeId).name;

        return `${orgSpecifier} - ${contractType}`;
    };

    const allFieldsModified = modified.contractTypeId
        && modified.specifier
        && modified.startDate
        && modified.endDate
        && modified.promisorId;

    const CreateContractButton = () => (
        <Button
            type="primary"
            className="spaceAbove-xs"
            disabled={!allFieldsModified}
            onClick={async () => {
                const newContract = {
                    specifier: modified.specifier,
                    promisorId: modified.promisorId,
                    contractTypeId: modified.contractTypeId,
                    name: buildContractName(),
                    startDate: modified.startDate,
                    endDate: modified.endDate
                };

                const { success, result } = await attemptToCreateContract({
                    variables: {
                        newContract
                    }
                });

                if (success) {
                    setModified({});
                    const id = result?.data?.createContract?.id;
                    if (id && typeof id === "string") {
                        navigate(`/contracts/${id}`);
                    }
                }
            }}
        >
            Create Contract
        </Button>
    );

    const details = (
        <Form
            layout="vertical"
            autoComplete="off"
        >
            <div id="details">
                {
                    <EditableDetail
                        title="Contract ID"
                        key="specifier"
                        value={modified.specifier}
                        readOnly={!userPermEditContracts}
                        onValueUpdated={(e) => {
                            setModified({
                                ...modified,
                                specifier: e.target.value
                            });
                        }}
                    />
                }
                <SelectableDetail
                    title="Contract Type"
                    passedKey="contractTypeId"
                    value={modified.contractTypeId}
                    onValueUpdated={(value) => {
                        let startDate = (contractTypes.find(({ id }) => id === value)).contractTypeNewestVersion.startDate;
                        let endDate = (contractTypes.find(({ id }) => id === value)).contractTypeNewestVersion.endDate;

                        setContractStartDate(formatDate(startDate));
                        setContractEndDate(formatDate(endDate));

                        setModified({
                            ...modified,
                            contractTypeId: value,
                            startDate: startDate,
                            endDate: endDate
                        });
                    }}
                    options={contractTypeOptions}
                    readOnly={!userPermCreateContracts}
                />
                <SelectableDetail
                    title="Submitting Organization"
                    passedKey="promisorId"
                    value={modified.promisorId}
                    onValueUpdated={(value) => {
                        setModified({
                            ...modified,
                            promisorId: value
                        });
                    }}
                    options={orgOptions}
                    readOnly={!userPermCreateContracts}
                />
                <>
                    <LabeledDetail title="Start Date">
                        {contractStartDate}
                    </LabeledDetail>
                    <br />
                    <LabeledDetail title="End Date">
                        {contractEndDate}
                    </LabeledDetail>
                </>
                <CreateContractButton />
            </div>
        </Form>
    );

    return (
        <Main
            title={"Create a Contract"}
            breadcrumbs={[
                <PCDULink to="/contracts" tooltip="Contracts">Contracts</PCDULink> 
            ]}
            details={details}
        />
    );
};