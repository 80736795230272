import React, { useState, useContext } from "react";
import { Button, Modal } from "antd";
import { useMutation } from "@apollo/client";
import { UserContext } from "../Application/UserContext.js";
import { SelectableDetail } from "../common/SelectableDetail/index.js";
import { Table } from "../common/Table/index.js";
import {
    ROLE_DISPLAY_CONVERSION,
    DUPLICATE_PRIMARY_REVIEWER_MESSAGE,
    PRIMARY_REVIEWER,
    SECONDARY_REVIEWER
} from "../../constants/index.js";

import {
    CREATE_ASSIGNMENTS,
    DELETE_ASSIGNMENT
} from "../../constants/mutations.js";

import { PCDULink } from "../common/PCDULink/index.js";
import { handleMutation } from "../../utils/errorHandling.js";
import { getNewBreadcrumbs } from "./_helpers.js";

const { confirm } = Modal;

const ROLES_TABLE = {
    NOTIFIER: {
        title: "Notify Reviewer",
        description: "These reviewers get notified when submissions of this submission type are Finalized.",
        primaryButton: "Add Notifier User",
        modalTitle: "Add Secondary Notifier",
        dataSource: "notifierAssignments"
    },
    REVIEWER: {
        title: "Reviewer",
        description: "This reviewer has been assigned to perform reviews on submissions of this submission type.",
        primaryButton: "Assign a Reviewer",
        modalTitle: "New Reviewers",
        dataSource: "reviewerAssignments"
    },
    REVIEWERS: {
        title: "Reviewers",
        description: "These reviewers have been assigned to perform reviews on submissions of this submission type.",
        primaryButton: "Assign a Reviewer",
        modalTitle: "New Reviewers",
        dataSource: "reviewerAssignments"
    }
};

export const displayErrorModal = (error) => {
    let errorMsg;
    if (typeof error === "object") {
        errorMsg = "Something went wrong!!";
        if (error.message.search(/duplicate key/) >= 0) {
            errorMsg = "Submission Type ID should be unique. Submission type already present with this ID";
        }
    } else {
        errorMsg = error;
    }

    Modal.error({
        title: "Error",
        content: errorMsg
    });
};

export const ReviewersTable = ({
    submissionType,
    secondaryNotifierFlag,
    refetchSubmissions,
    roles = [],
    notifierRole,
    tableType
}) => {
    const newBreadcrumbs = getNewBreadcrumbs(submissionType);
    const [createAssignmentsVis, setCreateAssignmentsVis] = useState(false);
    const [createNotifyAssignmentsVis, setCreateNotifyAssignmentsVis] = useState(false);
    const [modified, setModified] = useState({});
    const [loading, setLoading] = useState(false);
    const [createAssignments] = useMutation(CREATE_ASSIGNMENTS);
    const [deleteAssignment] = useMutation(DELETE_ASSIGNMENT);

    const {
        userPermEditSubmissionType,
        userIsSubmitter,
        userPermAddSecondaryReviewer
    } = useContext(UserContext);

    const dataSource = submissionType[ROLES_TABLE[tableType].dataSource];
    // const validNewAssignees = submissionType?.validNewAssignees ?? [];

    const findExistingPrimaryRoles = submissionType?.reviewerAssignments?.find((list) => {
        return list.role.specifier === PRIMARY_REVIEWER;
    });

    const reviewerRoles = roles.filter((role) => {
        return secondaryNotifierFlag === true 
            ? role.specifier.toUpperCase() === PRIMARY_REVIEWER
            : role.specifier.toUpperCase() === PRIMARY_REVIEWER || role.specifier.toUpperCase() === SECONDARY_REVIEWER;
    });

    const primaryRoleId = reviewerRoles.find((role) => {
        return role.specifier === PRIMARY_REVIEWER;
    });
    const hasPrimaryReviewer = submissionType?.reviewerAssignments?.some?.(assignment => primaryRoleId?.id && (primaryRoleId.id === assignment?.role?.id)) ?? false;

    const roleOptions = reviewerRoles.filter(({ id }) => {
        if (primaryRoleId?.id === id && hasPrimaryReviewer) {
            return false;
        } else {
            return true;
        }
    }).map(({ id, specifier }) => {
        return {
            text: ROLE_DISPLAY_CONVERSION[specifier],
            value: id,
            key: id
        };
    });

    const showConfirmReviewer = (id) => {
        confirm({
            title:
                "Are you sure you want to remove this reviewer from all present and future reviews of this submission type?",
            okText: "Yes, Remove",
            async onOk() {
                await handleMutation(
                    deleteAssignment({
                        variables: { id }
                    })
                );
                refetchSubmissions();
            }
        });
    };

    const reviewersColumn = [
        {
            title: "Name",
            width: "30%",
            dataIndex: "assignee",
            render: ({ id, name }) => {
                return (
                    userIsSubmitter ? name : (
                        <PCDULink to={`/users/${id}`} newBreadcrumbs={newBreadcrumbs}>{name}</PCDULink>
                    )
                );
            }
        },
        {
            title: "Role",
            dataIndex: "role",
            width: "30%",
            render: (role) => ROLE_DISPLAY_CONVERSION[role.specifier]
        },
        {
            title: "Email",
            width: "30%",
            dataIndex: ["assignee", "email"]
        }
    ];

    if (userPermEditSubmissionType) {
        const actionColumn = {
            title: "Actions",
            key: "actions",
            width: "10%",
            render: ({ id }) => {
                return (
                    <Button
                        type="danger"
                        size="small"
                        onClick={() => {
                            showConfirmReviewer(id);
                        }}
                    >
                        Remove
                    </Button>
                );
            }
        };
        reviewersColumn.splice(3, 0, actionColumn);
    }

    const reviewersTable = (
        <>
            <Table
                id="reviewersTable"
                columns={reviewersColumn}
                rowKey="id"
                dataSource={dataSource}
                pagination={true}
            />
        </>
    );

    const createModifyReviewersModal = (
        <Modal
            title={"New Reviewers"}
            open={createAssignmentsVis}
            maskClosable={false}
            destroyOnClose={true}
            onOk={async () => {
                try {
                    setLoading(true);
                    if (primaryRoleId.id === modified.roleId && findExistingPrimaryRoles !== undefined) {
                        displayErrorModal("Primary Reviewer already present. Please assign different role");
                    } else {
    
                        if (primaryRoleId.id === modified.roleId && modified.userIds.length > 1) {
                            modified.userIds.splice(1);
                        }
                        const newAssignments = modified.userIds.map((id) => {
                            return {
                                nodeId: submissionType.id,
                                assigneeId: id,
                                roleId: modified.roleId
                            };
                        });
    
                        const createAssignmentsMutation = createAssignments({
                            variables: {
                                newAssignments
                            }
                        });
    
                        await handleMutation(createAssignmentsMutation, {
                            showSuccess: true
                        });
    
                        await refetchSubmissions();
                    }
    
    
                    setCreateAssignmentsVis(false);
                    setModified({});
                } finally {
                    setLoading(false);
                }
            }}
            okText="Save"
            okButtonProps={{
                loading,
                disabled:
                    typeof modified.userIds === "undefined" ||
                    modified.userIds.length === 0 ||
                    typeof modified.roleId === "undefined"
            }}
            cancelButtonProps={{
                disabled: loading
            }}
            onCancel={() => {
                setModified({});
                setCreateAssignmentsVis(false);
            }}
        >
            {hasPrimaryReviewer
                ? <div style={{ marginBottom: "12px" }}>
                    <span> {DUPLICATE_PRIMARY_REVIEWER_MESSAGE} </span>
                    <br />
                </div>
                : null}
            {roleOptions?.length && roleOptions.length > 0
                ? <SelectableDetail
                    title="Role"
                    passedKey="roleId"
                    multiple={false}
                    value={modified.roleId}
                    onValueUpdated={(value) => {
                        setModified({
                            ...modified,
                            roleId: value
                        });
                    }}
                    options={roleOptions}
                />
                : null
            }
            {modified.roleId && <SelectableDetail
                title="User(s)"
                passedKey="primary-reviewer-assignment"
                multiple={primaryRoleId.id === modified.roleId ? false : true}
                value={modified?.userIds ?? []}
                onValueUpdated={(value) => {
                    setModified({
                        ...modified,
                        userIds: typeof value === "string" ? [value] : [...value]
                    });
                }}
                // this should be changed to do only users that are a member of the promisee of the contract ?
                options={submissionType.validNewAssignees.map(({ id, name, email }) => {
                    return {
                        text: `${name} - ${email}`,
                        value: id,
                        key: id
                    };
                })}
            />}
        </Modal>
    );

    const secondaryNotifierModal = (
        <Modal
            title={"Add Secondary Notifier"}
            open={createNotifyAssignmentsVis}
            maskClosable={false}
            destroyOnClose={true}
            onOk={async () => {
                try {
                    setLoading(true);
                    const newAssignments = modified.userIds.map((id) => {
                        return {
                            nodeId: submissionType.id,
                            assigneeId: id,
                            roleId: notifierRole.id
                        };
                    });
    
                    await handleMutation(
                        createAssignments({
                            variables: {
                                newAssignments
                            }
                        })
                    );
    
                    await refetchSubmissions();
    
                    setCreateNotifyAssignmentsVis(false);
                    setModified({});
                } finally {
                    setLoading(false);
                }
            }}
            okText="Save"
            okButtonProps={{
                loading,
                disabled:
                    typeof modified.userIds === "undefined"
            }}
            cancelButtonProps={{
                disabled: loading
            }}
            onCancel={() => {
                setModified({});
                setCreateNotifyAssignmentsVis(false);
            }}
        >
            <SelectableDetail
                title="User(s)"
                passedKey="notifier-user-assignment"
                multiple={true}
                value={modified.userIds}
                onValueUpdated={(value) => {
                    setModified({
                        ...modified,
                        userIds: [...value]
                    });
                }}
                // this should be changed to do only users that are a member of the promisee of the contract ?
                options={submissionType.validNewAssignees.map(({ id, name, email }) => {
                    return {
                        text: `${name} - ${email}`,
                        value: id,
                        key: id
                    };
                })}
            />
        </Modal>
    );

    return (
        <>
            <h2>{ROLES_TABLE[tableType].title}</h2>
            <p>
                {ROLES_TABLE[tableType].description}
            </p>

            <Button
                id="createReviewer"
                type="primary"
                style={{ display: (userPermAddSecondaryReviewer(submissionType.id)) || userPermEditSubmissionType ? "inherit" : "none" }}
                className="spaceBelow-xs btn btn-sm btn-primary"
                onClick={() => tableType === "NOTIFIER" ? setCreateNotifyAssignmentsVis(true) : setCreateAssignmentsVis(true)}
            >
                {ROLES_TABLE[tableType].primaryButton}
            </Button>
            {reviewersTable}
            {createModifyReviewersModal}
            {secondaryNotifierModal}
        </>
    );
};