import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { UploadSubmissionDocument } from "../common/UploadSubmissionDocument/index.js";
import { CriteriaTable } from "../common/CriteriaTable/index.js";
import { UserContext } from "../Application/UserContext.js";
import { LoadingContent } from "../common/LoadingContent/index.js";

import {
    submitterNextDueDateQuery
} from "./query.js";
import {
    formatDate
} from "../../utils/functions.js";
import { useQuery } from "@apollo/client";

export const DueDateSubmission = (({
    submissionType,
    data
}) => {

    const {
        userOrganizationId,
        userPermCreateSubmission
    } = useContext(UserContext);

    let nextToDoData;
    const params = useParams();

    if (userPermCreateSubmission) {
        nextToDoData = useQuery(submitterNextDueDateQuery,
            {
                variables: {
                    id: submissionType.id,
                    toDoOrganization: userOrganizationId
                },
                fetchPolicy: "no-cache",
                errorPolicy: "ignore"
            }
        );
    }

    if (userPermCreateSubmission && typeof nextToDoData.data === "undefined") {
        return <LoadingContent />;
    }
    const nextToDo = nextToDoData ? nextToDoData?.data?.nextToDo : null;

    const criteriaTableForUploadModal =
        <CriteriaTable
            criteriaList={submissionType?.allCriteria}
            versionFilterTimestamp={Date.now()}
        />;
    return (
        <>
            <h2>Next Due Date</h2>
            <p>{nextToDo === null
                ? "This submission type has not been requested from your organization."
                : `The next submission of this type is due ${formatDate(nextToDo?.timeline.currentDueDate)}.`}
            </p>
            { userPermCreateSubmission
                && submissionType.contractType.contractTypeNewestVersion.contractActive
                && <span style={{ display: nextToDo === null && !submissionType.deleted ? "none" : "initial" }}>
                    <UploadSubmissionDocument
                        isDisable={nextToDo === null ? false : true}
                        create={true}
                        createData={
                            {
                                days: submissionType.daysToReview,
                                obligationId: nextToDo?.obligation?.id,
                                organizationId: userOrganizationId,
                                contractId: nextToDo?.contract?.id,
                                timelineId: nextToDo?.timeline.id,
                                submissionTypeId: submissionType.id,
                                currentDueDate: nextToDo?.timeline.currentDueDate
                            }
                        }
                        criteriaTable={criteriaTableForUploadModal}
                        buttonTitle="Create Submission"
                        useAttestation={submissionType.useAttestation}
                        packagedSubmission={submissionType.packagedSubmission}
                        documentUploadTitle={submissionType?.packagedSubmission ? `Upload New Document(s) for ${submissionType?.name ?? ""}` : `Upload New Document for ${submissionType?.name ?? ""}`}
                        submissionTypeData={data}
                        submissionTypeId={submissionType.id}
                        maxDocumentCount={submissionType?.packagedSubmission ? 100 : 1}
                        dueDateModelDisplay={true}
                        newBreadcrumbs={[
                            { label: "Submission Types", path: "/submission-types" },
                            { label: `${submissionType.specifier}`, path: `/submission-types/${params?.id}` }
                        ]}
                    />
                </span>
            }
        </>
    );
});