import moment from "moment";

const isObject = (possibleObj) => possibleObj && typeof possibleObj === "object" && !Array.isArray(possibleObj);

const isMomentInstance = (item) => item instanceof moment;

const areMatchedArrays = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
        return false;
    } else if (arr1.length > 0 && arr1.every(isMomentInstance) && arr2.every(isMomentInstance)) {
        for (let i = 0; i < arr1.length; i++) {
            if (!arr1[i].isSame(arr2[i])) {
                return false;
            }
        }
        return true;
    } else {
        return arr1.every(item => arr2.includes(item));
    }
};

const filterIsAtDefault = ({ currentValue, defaultValue }) => {
    if (Array.isArray(currentValue) && Array.isArray(defaultValue)) {
        return areMatchedArrays(currentValue, defaultValue);
    } else if (isObject(currentValue) && isObject(defaultValue)) {
        return areMatchedArrays(Object.values(currentValue), Object.values(defaultValue));
    } else {
        return isMomentInstance(currentValue) && isMomentInstance(defaultValue) 
            ? currentValue.isSame(defaultValue) 
            : currentValue === defaultValue;
    }
};

export const clearFiltersButtonShouldBeDisabled = (filters) => {
    if (!Array.isArray(filters) || filters.length == 0) {
        // bad filters data has been passed -- disable :: 
        return true;
    } else if (filters.every(filterIsAtDefault)) {
        // filters are unchanged -- disable :: 
        return true;
    } else {
        // filters have been changed from default -- do not disable :: 
        return false;
    }
};