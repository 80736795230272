import React from "react";
import { SelectableDetail } from "../SelectableDetail/index.js";

const NAME_LENGTH_LIMIT = 50;

export const RELATED_DOCUMENTS_NULL_OPTION = {
    text: "This issue does not relate to any specific submission document.",
    value: "none", 
    id: "none" 
};

export const RelatedDocumentsSelection = ({ 
    submissionDocuments = [], 
    selectedRelatedDocuments = [], 
    setSelectedRelatedDocuments, 
    readOnly = false 
}) => { 
    
    const allOptions = submissionDocuments.map(({ id, specifier, documentNewestVersion }) => { 
        const truncatedNickname = `${documentNewestVersion.name.trim().slice(0, NAME_LENGTH_LIMIT)}${documentNewestVersion.name.length > NAME_LENGTH_LIMIT ? "..." : ""}`;
        return {
            text: specifier,
            additionalText: truncatedNickname,
            id,
            value: id
        };
    }).concat(RELATED_DOCUMENTS_NULL_OPTION);

    return (
        <SelectableDetail 
            title="Related Document(s)"
            multiple={true}
            readOnly={readOnly}
            blurOnSelectionId={"related-documents-dropdown"}
            value={selectedRelatedDocuments}
            onValueUpdated={(selectedDocuments) => {
                const selectedDocumentsToUse = selectedDocuments.includes(RELATED_DOCUMENTS_NULL_OPTION.id) 
                    ? [RELATED_DOCUMENTS_NULL_OPTION.id] 
                    : [...selectedDocuments];

                setSelectedRelatedDocuments(selectedDocumentsToUse);
            }}
            options={allOptions}
        />
    );
};
