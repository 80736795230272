import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { LIBRARY, NOTIFICATION_TYPES } from "../../../constants/index.js";
import { Checkbox } from "antd";
import { isUndefinedOrNull } from "../../../utils/functions.js";
import { handleGraphQLError } from "../../../utils/errorHandling.js";
import { LoadingContent } from "../../common/LoadingContent/index.js";
import { NOTIFICATION_TYPE_DISPLAY_NAMES } from "../_shared/sharedConstants.js";
import { isBeingModified } from "../_shared/helpers.js";
import { ContractTypesNotification } from "../_shared/contractTypes.js";
import { SettingsCard } from "../_shared/settingsCard.js";
import { libraryNotificationsQuery } from "../query.js";
import { NotificationSaveButtons } from "../_shared/saveButtons.js";
import { useAutoFlag } from "../../../utils/useAutoFlag.js";
import { NoNotifications } from "../_shared/noNotifications.js";

const getUnsavedChangeText = (modifiedObj, preference) => isBeingModified(modifiedObj, preference) ? " (Unsaved Change)" : "";


export const LibraryNotifications = () => {
    const [modified, setModified] = useState({});
    const [resetFlag, setResetFlag] = useAutoFlag(false);

    const { data, refetch, loading, error } = useQuery(libraryNotificationsQuery, { 
        fetchPolicy: "no-cache", 
        variables: { type: LIBRARY }
    });

    const notificationPreferencesByContractType = data?.notificationPreferencesByContractType ?? [];
    const notificationPreferencesByLibraryDocument = data?.notificationPreferencesByLibraryDocument ?? [];
    const libraryPreference = notificationPreferencesByLibraryDocument[0] ?? {};

    if (loading) {
        return <LoadingContent />;
    }

    if (error) {
        return handleGraphQLError(error);
    }

    const isCurrentlyChecked = preference => !isUndefinedOrNull(modified[preference.id]) ? modified[preference.id].active : preference.active;

    const libraryNotificationText = `${NOTIFICATION_TYPE_DISPLAY_NAMES[NOTIFICATION_TYPES.LIBRARY_DOCUMENT_UPLOAD]}${getUnsavedChangeText(modified, libraryPreference)}`;

    const renderContractTypeSection = Boolean(
        Array.isArray(notificationPreferencesByContractType) && 
        notificationPreferencesByContractType.length > 0
    );

    const handleLibraryNotificationClick = () => {
        setModified({
            ...modified,
            [libraryPreference.id]: {
                active: !isCurrentlyChecked(libraryPreference),
                isModified: !isBeingModified(modified, libraryPreference)
            }
        });
    };

    const notificationLibraryDocument = !libraryPreference?.id 
        ? <NoNotifications /> 
        : (
            <div style={{ marginLeft: "8px" }} className="spaceBelow-md">
                <Checkbox
                    key="notificationLibDocs"
                    defaultChecked={libraryPreference.active}
                    checked={isCurrentlyChecked(libraryPreference)}
                    onChange={handleLibraryNotificationClick}
                >
                    <span style={{ cursor: "pointer" }} onClick={(evt) => {
                        evt.stopPropagation();
                        evt.preventDefault();
                        handleLibraryNotificationClick();
                    }}>{ libraryNotificationText }</span>
                </Checkbox>
            </div>
        );

    return !renderContractTypeSection && !libraryPreference?.id
        ? <NoNotifications />
        : <>
            <NotificationSaveButtons 
                changedNotifications={modified}
                reset={() => {
                    setModified({});
                    setResetFlag(true);
                }}
                refetch={refetch}
            />
            <ContractTypesNotification 
                type={LIBRARY}
                addChanges={(changes) => setModified({ ...modified, ...changes })}
                resetFlag={resetFlag}
                message="Select which contract types for which you would like to receive library notifications."
                contractTypePreferences={notificationPreferencesByContractType}
            />
            <SettingsCard
                title="Notification Types"
                subtitle="For the selected contract types, select which notifications you wish to receive."
                canSaveChanges={false}
                saveDisabled={true}
            >
                { notificationLibraryDocument }
            </SettingsCard>
        </>;
};