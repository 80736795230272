import React from "react";

export const LabeledDetail = ({
    title,
    value,
    children
}) => {
    
    let textInsert;
    
    if (value) {
        textInsert = <span>{ value }</span>;
    }

    return <span className={"input input--filled"} style={{
        marginBottom: "5px",
        marginTop: "5px",
        fontSize: "14px"
    }}>
        <label className="input__label input__label--sae">
            <span className="input__label-content input__label-content--sae">
                {title}
            </span>
        </label>
        <span className="input__field input__field--sae">
            { textInsert ? textInsert : children }
        </span>
    </span>;
};