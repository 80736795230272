import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faCalendarXmark } from "@fortawesome/free-solid-svg-icons"; 


export const CalendarIcon = (active) => {
    return <FontAwesomeIcon
        style={{ padding: "4px"}}
        icon={active ? faCalendarXmark : faCalendar} size="lg"
    />;
};
