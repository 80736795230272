import React, { useEffect, useState, useContext } from "react";
import { Checkbox, Table, Button, Modal } from "antd";
import { formatDate } from "../../utils/functions.js";
import { gql, useQuery } from "@apollo/client";
import moment from "moment";
import { UserContext } from "../Application/UserContext.js";
import { ExtendDueDate } from "../common/ExtendDueDate/index.js";
import { CommentsList } from "../common/CommentsList/index.js";
import { useAttemptToModifyDueDate } from "../../utils/attemptMutationHooks.js"; 
import { PersistentState } from "../../utils/PersistentState.js";
import { handleControlledDefaultSortOrder } from "../../utils/handleControlledDefaultSortOrder.js";
import { TablePagination } from "../common/TablePagination/index.js";
const { usePersistentState } = PersistentState();

const { confirm } = Modal;


export const TimelinesTable = ({ submissionTypeId, refetchTimelines, setRefetchTimelines, setRefetchSubmissions }) => {
    const [attemptToModifyDueDate] = useAttemptToModifyDueDate(); 
    const [priorData, setPriorData] = useState(null);
    const [dataToUse, setDataToUse] = useState(null);

    // Persistent State :: 
    const [showPreviousDueDates, setShowPreviousDueDates] = usePersistentState(false); 
    const [activeFilter, setActiveFilter] = usePersistentState(true);
    const [sortOn, setSortOn] = usePersistentState("currentDueDate");
    const [sortBy, setSortBy] = usePersistentState("ascend");
    const [pageSize, setPageSize] = usePersistentState(10);
    const [page, setPage] = usePersistentState(1);
    const [offset, setOffset] = usePersistentState(0);
    
    const { userId, userPermViewDueDateSchedule, userPermCreateExtension } = useContext(UserContext);

    const { loading, error, data, refetch } = useQuery(
        gql`
            query TimelinesTableQuery (
                $id: ID! 
                $includePreviousDueDates: Boolean 
                $sortOn: String
                $sortBy: String
                $limit: Int 
                $offset: Int 
                $activeFilter: Boolean 
            ) {
                timelinesBySubmissionTypeList (
                    submissionTypeId: $id
                    includePreviousDueDates: $includePreviousDueDates
                    sortOn: $sortOn
                    sortBy: $sortBy
                    limit: $limit
                    offset: $offset 
                    activeFilter: $activeFilter 
                ) {
                    timelinesBySubmissionType {
                        id
                        startDate
                        endDate
                        initialDueDate
                        currentDueDate 
                        active 
                        frequency
                        comments {
                            id
                            content
                            previousDueDate
                            updatedDueDate,
                            createdAt,
                            modifiedAt,
                            author { 
                                id  
                                name 
                                ncid 
                                email 
                                organizations {
                                    id 
                                    name 
                                }
                            }                     
                        }
                    }
                    count
                }
            }
        `,
        {
            variables: {
                id: submissionTypeId,
                activeFilter,
                sortOn,
                sortBy,
                limit: pageSize,
                offset,
                includePreviousDueDates: showPreviousDueDates
            },
            fetchPolicy: "no-cache"
        }
    );

    useEffect(() => {
        setOffset((page - 1) * pageSize);
    }, [page, pageSize]);

    useEffect(() => {
        if (refetchTimelines === true) {
            refetch();
            setRefetchTimelines(false);
        }
    }, [refetchTimelines, setRefetchTimelines]);

    useEffect(() => {
        if (data) {
            setDataToUse(data);
        }
    }, [data]);

    if (error) {
        return <p>Error loading Due Dates</p>;
    }

    if (!userPermViewDueDateSchedule) {
        return null;
    }

    // inserting dummy table data makes the ui much smoother, especially with larger page sizes -- 
    // prevents layout jumps when loading :: 

    const timelines = dataToUse?.timelinesBySubmissionTypeList?.timelinesBySubmissionType ?? priorData;
    const count = dataToUse?.timelinesBySubmissionTypeList?.count ?? 0;

    const showConfirmModal = (timeline) => {
        const { active, startDate, endDate, initialDueDate, currentDueDate, id } = timeline;
        confirm({
            okText: active ? "Deactivate" : "Activate",
            okType: active ? "danger" : "primary",
            title: active ? "Confirm Deactivation" : "Confirm Activation",
            content: (
                <div>
                    <div>
                        {active
                            ? "If deactivated, this timeline will still apply to any existing submissions, but will not be applied to any new submissions."
                            : "Once activated, this timeline will be applied to new submissions."
                        }
                    </div>

                    <div style={{ margin: "15px 0px" }}> Are you sure you want to {active ? "deactivate" : "activate"} this due date? </div>

                    <ul>
                        <li>Start Date: {formatDate(startDate)}</li>
                        <li>End Date: {formatDate(endDate)}</li>
                        <li>Original Due Date: {formatDate(initialDueDate)}</li>
                        <li>Current Due Date: {formatDate(currentDueDate)}</li>
                    </ul>
                </div>
            ),
            onOk: async () => {
                const { success } = await attemptToModifyDueDate({
                    variables: {
                        timelineId: id,
                        newDueDate: formatDate(currentDueDate),
                        active: !active
                    }
                });

                if (success) {
                    const timelineCountAfterUpdate = timelines.length - 1;
                    if (timelineCountAfterUpdate <= 0 && page > 1) {
                        setPage(page - 1);
                    }
                    refetch();
                }
            }
        });
    };

    return <>
        <div style={{ display: "flex", justifyContent: "space-between", alignContent: "end" }}>
            <div>
                <h2>Due Date Schedule</h2>
                <p> Due dates for this submission type. </p> 
            </div>
            <div 
                style={{ display: "flex", flexDirection: "column", justifyContent: "start" }}
            > 
                <Button 
                    style={{ 
                        marginTop: "26px", 
                        marginBottom: "10px",
                        visibility: userPermCreateExtension ? "visible" : "hidden", 
                        disabled: !userPermCreateExtension 
                    }}
                    id="archive-toggle"
                    type="primary"
                    ghost={activeFilter}
                    onClick={() => {
                        if (userPermCreateExtension) {
                            setActiveFilter(!activeFilter);
                            setPage(1);
                        }
                    }}
                > 
                    { activeFilter ? "Inactive Timelines" : "Active Timelines" }
                </Button> 
                <Checkbox 
                    style={{ marginBottom: "12px" }}
                    onChange={e => {
                        if (!e.target.checked) {
                            setOffset(0);
                            setPage(1);
                        }
                        setPriorData(timelines);
                        setShowPreviousDueDates(e.target.checked);
                    }}
                    checked={showPreviousDueDates}
                >
                    Show Previous Due Dates
                </Checkbox>
            </div>
        </div>
        <div style={{ marginBottom: "50px" }}>
            <Table
                id="timelines"
                size="middle" 
                expandable={{
                    rowExpandable: (timeline) => Array.isArray(timeline?.comments) && timeline.comments.length > 0,
                    expandedRowRender: (timeline) => {
                        return (
                            <CommentsList 
                                title={""}
                                parent={timeline}
                                forTimeline={true}
                                userId={userId}
                                refetch={refetch}
                                disableCreating={true}
                            />
                        ); 
                    } 
                }}
                columns={handleControlledDefaultSortOrder({ 
                    sortOn, 
                    sortBy,
                    customHandler: ({ key }) => key === sortOn 
                }, [
                    {
                        title: "Start Date",
                        key: "startDate",
                        dataIndex: "startDate",
                        render: formatDate,
                        sorter: true,
                        sortDirections: ["ascend", "descend", "ascend"]
                    },
                    {
                        title: "End Date",
                        key: "endDate",
                        dataIndex: "endDate",
                        render: formatDate,
                        sorter: true,
                        sortDirections: ["ascend", "descend", "ascend"]
                    },
                    {
                        title: "Original Due Date",
                        key: "initialDueDate",
                        dataIndex: "initialDueDate",
                        render: formatDate,
                        sorter: true,
                        sortDirections: ["ascend", "descend", "ascend"]
                    },
                    {
                        title: "Current Due Date",
                        key: "currentDueDate",
                        dataIndex: "currentDueDate",
                        render: (date, { initialDueDate }) => {
                            const nDayDiff = moment(date).diff(moment(initialDueDate), "days");
                            return nDayDiff > 0
                                ? `${formatDate(date)} (+${nDayDiff} days)`
                                : nDayDiff < 0 ? `${formatDate(date)} (${nDayDiff} days)` : formatDate(date);
                        },
                        sorter: true,
                        defaultSortOrder: "ascend",
                        sortDirections: ["ascend", "descend", "ascend"]
                    },
                    {
                        title: "Frequency",
                        key: "frequency",
                        dataIndex: "frequency",
                        sorter: true,
                        sortDirections: ["ascend", "descend", "ascend"]
                    },
                    userPermCreateExtension
                        ? {
                            title: "Actions",
                            key: "actions",
                            width: "280px",
                            render: (timeline) => (
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}>
                                    { timeline.active !== false && <ExtendDueDate 
                                        timeline={timeline}
                                        refetch={() => {
                                            setRefetchSubmissions(true);
                                            refetch();
                                        }} 				 
                                    /> }
                                    <Button 
                                        size="small"
                                        type={timeline.active !== false ? "danger" : "primary"}
                                        onClick={() => showConfirmModal(timeline)} 
                                    > 
                                        { timeline.active !== false ? "Deactivate" : "Activate" }
                                    </Button>
                                </div>
                            )
                        }
                        : null
                ].filter(Boolean))}
                rowKey="id"
                loading={loading}
                dataSource={timelines}
                pagination={false}
                onChange={(_, __, { columnKey, order }) => {
                    setSortOn(columnKey);
                    setSortBy(order);
                }}
            />
            <TablePagination
                setPage={setPage}
                setPageSize={setPageSize}
                total={count}
                defaultPageSize={pageSize}
                currentPageNumber={page}
            />
        </div>
    </>;
};