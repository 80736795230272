import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { S3UploadStatus } from "../S3UploadStatus/index.js";
import { S3_UPLOAD_STATUS, S3_UPLOAD_STATUS_FETCH_ERROR } from "../../../constants/index.js";
import { pollingTimeByFileSize } from "../../../utils/index.js";

const defaultStatusKeyName = "S3UploadStatus";

const ActiveStatusTracker = ({ 
    fileSize, 
    status, 
    query, 
    fetchOptions, 
    documentTypeName,
    statusKeyName
}) => {
    // we want to set a single polling interval that's used throughout lifecycle :: 
    const [pollingInterval] = useState(pollingTimeByFileSize(fileSize));
    const [documentStatus, setDocumentStatus] = useState(status); 

    const {
        data, 
        error, 
        stopPolling, 
        startPolling 
    } = useQuery(query, fetchOptions);

    useEffect(() => { 
        if (data) {
            const currentStatus = data?.[documentTypeName]?.[statusKeyName]; 
            if (currentStatus === S3_UPLOAD_STATUS.uploading) {
                startPolling(pollingInterval);
            } else { 
                stopPolling(); 
                setDocumentStatus(currentStatus);
            } 
        }

        if (error) {
            stopPolling(); 
            setDocumentStatus(S3_UPLOAD_STATUS_FETCH_ERROR);
        }
    
        return stopPolling;
    }, [data, error]); 

    return (
        <div>
            { S3UploadStatus(documentStatus) }
        </div>
    );
};


export const DocumentUploadStatusActiveTracker = ({
    fetchOptions,
    query,
    documentTypeName,
    statusKeyName,
    status = null,
    fileSize 
}) => { 
    const statusKeyNameToUse = statusKeyName && typeof statusKeyName === "string" 
        ? statusKeyName 
        : defaultStatusKeyName;
    
    switch (status) { 
        // in these cases, we don't need to fetch data :: 
        case S3_UPLOAD_STATUS.error: 
        case S3_UPLOAD_STATUS.quarantine: 
        case S3_UPLOAD_STATUS.uploaded: 
            return (
                <div>
                    {S3UploadStatus(status)}
                </div>
            );
        default: 
            // if status is uploading, or invalid -- nullish (i.e., unknown), nonsensical, etc. -- we fetch and render the status :: 
            return <ActiveStatusTracker 
                fileSize={fileSize} 
                status={status}
                fetchOptions={fetchOptions}
                query={query}
                documentTypeName={documentTypeName}
                statusKeyName={statusKeyNameToUse}
            />;
    }
};