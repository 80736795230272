import React, { useState, useEffect } from "react";
import { Button, Modal, Table } from "antd";
import { useMutation, useQuery } from "@apollo/client";
import { PCDULink } from "../common/PCDULink/index.js";
import { SelectableDetail } from "../common/SelectableDetail/index.js";
import { searchable } from "../../utils/searchable.js";
import {
    isUndefinedOrNull
} from "../../utils/functions.js";
import {
    CREATE_CONTRACT_TYPE_VERSION,
    CREATE_OBLIGATIONS,
    DELETE_OBLIGATIONS

} from "../../constants/mutations.js";
import { handleMutation } from "../../utils/errorHandling.js";
import { submissionTypesOfVersion } from "./query.js";
import { ErrorMessage } from "../ErrorMessage/index.js";

const makeSearchFilter = (searchText, key) => {
    const shouldFilter = searchText && typeof searchText === "string"; 
    return shouldFilter 
        ? (item) => item[key].toLowerCase().includes(searchText.toLowerCase()) 
        : null; 
}; 

export const SubmissionTypesTable = (({
    newBreadcrumb,
    userPermCreateSubmissionType,
    editingSubmissionTypes,
    setEditingSubmissionTypes,
    applyFilters,
    submissionTypes,
    displayedContractTypeVersion,
    contractType,
    userPermCreateContractTypes,
    clearOut,
    handleDataUpdate, 
    displayedVersionText
}) => {

    const [modified, setModified] = useState({});
    const [createObligationsVis, setCreateObligationsVis] = useState(false);
    const [saveSubmissionTypeChangesVis, setSaveSubmissionTypeChangesVis] = useState(false);
    const [submissionTypeNameSearch, setSubmissionTypeNameSearch] = useState("");
    const [submissionTypeSpecifierSearch, setSubmissionTypeSpecifierSearch] = useState("");
    const [updateUnderway, setUpdateUnderway] = useState(false);

    const [createObligations] = useMutation(CREATE_OBLIGATIONS);
    const [deleteObligations] = useMutation(DELETE_OBLIGATIONS);
    const [createContractTypeVersion] = useMutation(CREATE_CONTRACT_TYPE_VERSION);
    
    const { data, previousData, loading, error, refetch } = useQuery(submissionTypesOfVersion, {
        variables: {
            versionId: displayedContractTypeVersion.id,
            contractTypeId: contractType.id
        },
        fetchPolicy: "no-cache"
    });

    const submissionTypesOfDisplayedVersion = data 
        ? (data?.submissionTypesOfVersion?.submissionTypes ?? [])
        : (previousData?.submissionTypesOfVersion?.submissionTypes ?? []);

    const filteredSubmissionTypesOfVersion = applyFilters(submissionTypesOfDisplayedVersion, [
        makeSearchFilter(submissionTypeNameSearch, "name"),
        makeSearchFilter(submissionTypeSpecifierSearch, "specifier")
    ]);

    const contractSubmissionTypeIds = submissionTypesOfDisplayedVersion.map(({ id }) => id);
    
    const addableSubmissionTypes = submissionTypes.filter(({ id, deleted }) => {
        return !deleted && !contractSubmissionTypeIds.includes(id);
    });

    const contractActive = displayedContractTypeVersion?.contractActive === true;
    const mutationsAllowed = !loading && !updateUnderway && contractActive;

    const postMutationCleanup = () => {
        clearOut();
        setModified({});
        setSubmissionTypeNameSearch("");
        setSubmissionTypeSpecifierSearch("");
        setCreateObligationsVis(false);
        setSaveSubmissionTypeChangesVis(false);
        setEditingSubmissionTypes(false);
        setUpdateUnderway(false);
    };

    useEffect(() => {
        if (displayedContractTypeVersion.id) {
            postMutationCleanup();
        }
    }, [displayedContractTypeVersion?.id]);

    const createAmendmentHandler = async () => {
        if (mutationsAllowed) {
            setUpdateUnderway(true);
            const initialSubmissionTypes = Array.isArray(data?.submissionTypesOfVersion?.submissionTypes)
                ? data.submissionTypesOfVersion.submissionTypes.map(({ id }) => id)
                : [];
        
            const submissionTypesToAdd = Array.isArray(modified?.addSubmissionTypeIds)
                ? modified.addSubmissionTypeIds
                : [];
    
            const submissionTypesToRemove = Array.isArray(modified?.removeSubmissionTypeIds)
                ? modified.removeSubmissionTypeIds
                : [];
    
            const combinedIdList = [
                ...initialSubmissionTypes,
                ...submissionTypesToAdd
            ];
    
            const distinctIdList = [...new Set(combinedIdList)];
    
            const finalIdList = distinctIdList.filter(id => !submissionTypesToRemove.includes(id));
    
            const createCTVMutation = createContractTypeVersion({
                variables: {
                    contractType: {
                        id: contractType?.id,
                        versionSpecifier: displayedContractTypeVersion?.specifier,
                        startDate: displayedContractTypeVersion?.startDate,
                        endDate: displayedContractTypeVersion?.endDate
                    },
                    submissionTypeIdList: finalIdList
                }
            });
    
            await handleMutation(createCTVMutation, { showSuccess: true });
            handleDataUpdate(postMutationCleanup);
        }
    };

    const modifyCurrentPlanHandler = async () => {
        if (mutationsAllowed) {
            setUpdateUnderway(true);
            
            const addingOnly = modified?.addSubmissionTypeIds && !modified?.removeSubmissionTypeIds;
            if (typeof modified.addSubmissionTypeIds !== "undefined") {
                await handleMutation(
                    createObligations({
                        variables: {
                            obligatorId: displayedContractTypeVersion?.id,
                            submissionTypeIds: modified.addSubmissionTypeIds
                        }
                    }),
                    { showSuccess: addingOnly }
                );
            }

            if (typeof modified.removeSubmissionTypeIds !== "undefined") {
                await handleMutation(
                    deleteObligations({
                        variables: {
                            obligatorId: displayedContractTypeVersion?.id,
                            submissionTypeIds: modified.removeSubmissionTypeIds
                        }
                    }),
                    { showSuccess: true }
                );
            }

            postMutationCleanup();
            refetch();
        }
    };

    const submissionTypeAssignmentModal = () => {
        return (<Modal
            title={`Save Submission Types Assignment Changes for ${contractType?.name}`}
            open={saveSubmissionTypeChangesVis}
            destroyOnClose={true}
            onOk={createAmendmentHandler}
            onCancel={modifyCurrentPlanHandler}
            footer={[
                <Button
                    key="modifyPlan"
                    className="spaceBetween-sm"
                    loading={updateUnderway}
                    disabled={loading || !contractActive}
                    onClick={modifyCurrentPlanHandler}
                >
                    Modify Current Plan
                </Button>,
                <Button
                    key="createAmendment"
                    className="spaceBetween-sm"
                    type="primary"
                    loading={updateUnderway}
                    disabled={loading || !contractActive}
                    onClick={createAmendmentHandler}
                >
                    Create New Amendment
                </Button>
            ]}
        >

            <div
                style={{ display: typeof modified.addSubmissionTypeIds === "undefined" ? "none" : "inherit" }}
            >
                <h3>Add:</h3>
                {modified.addSubmissionTypeIds?.map((addId, i) => {
                    return (
                        <h4 key={`sub-type-ids-add-${i}`}>{addableSubmissionTypes?.find(({ id }) => id === addId)?.name}</h4>
                    );
                })}
            </div>
            <div
                style={{ display: typeof modified.removeSubmissionTypeIds === "undefined" ? "none" : "inherit" }}
            >
                <h3>Remove:</h3>
                {modified.removeSubmissionTypeIds?.map((removeId, i) => {
                    return (
                        <h4 key={`sub-type-ids-remove-${i}`}>{submissionTypesOfDisplayedVersion?.find(({ id }) => id === removeId)?.name}</h4>
                    );
                })}
            </div>
        </Modal>
        );
    };

    const assignSubmissionTypeModal = () => {
        return (
            <Modal
                title={`Assign Submission Types to ${contractType?.name}`}
                open={createObligationsVis}
                maskClosable={false}
                destroyOnClose={true}
                onOk={() => {
                    setSaveSubmissionTypeChangesVis(true);
                    setCreateObligationsVis(false);
                }}
                okText="Add"
                okButtonProps={{
                    disabled: typeof modified.addSubmissionTypeIds === "undefined"
                }}
                onCancel={() => {
                    // don't need to "clearOut", just remove addSubmissionTypeIds from modified obj :: 
                    const newModifiedObj = { ...modified };
                    delete newModifiedObj.addSubmissionTypeIds;
                    setModified(newModifiedObj);
                    setCreateObligationsVis(false);
                }}
            >
                <h4 className="warning">
                    WARNING: Adding submission types to an existing contract type will
                    update the requirements of all existing contracts using this contract
                    type
                </h4>
                <SelectableDetail
                    title={"Submission Types"}
                    passedKey="submissionTypeIds"
                    multiple={true}
                    value={modified.addSubmissionTypeIds}
                    onValueUpdated={(value) => {
                        setModified({
                            ...modified,
                            addSubmissionTypeIds: [...value]
                        });
                    }}
                    options={addableSubmissionTypes?.map(({ id, name }) => {
                        return {
                            text: name,
                            value: id,
                            id: `add-${id}`,
                            key: `add-${id}`
                        };
                    })}
                />
            </Modal>
        );
    };

    const SubmissionTypesTable = () => {
        return (
            <>
                <Table
                    loading={loading}
                    className="TABLE"
                    id="submissionTypesTable"
                    pagination={{
                        showSizeChanger: true,
                        pageSizeOptions: ["10", "20", "50"]
                    }}
                    columns={[
                        searchable({
                            title: "ID",
                            dataIndex: "specifier",
                            key: "specifier",
                            sorter: (a, b) => a.specifier.localeCompare(b.specifier),
                            sortDirections: ["ascend", "descend", "ascend"],
                            defaultSortOrder: "ascend",
                            width: "30%",
                            render: (specifier, { id }) => {
                                return (
                                    <PCDULink 
                                        to={`/submission-types/${id}`}
                                        newBreadcrumbs={newBreadcrumb}
                                    >
                                        {specifier}
                                    </PCDULink>
                                );
                            },
                            handleSearch: setSubmissionTypeSpecifierSearch,
                            searchedText: submissionTypeSpecifierSearch,
                            handleReset: () => {
                                setSubmissionTypeSpecifierSearch("");
                            }
                        }),
                        searchable({
                            title: "Name",
                            dataIndex: "name",
                            handleSearch: setSubmissionTypeNameSearch,
                            searchedText: submissionTypeNameSearch,
                            width: "60%",
                            sorter: (a, b) => a.name.localeCompare(b.name),
                            sortDirections: ["ascend", "descend", "ascend"]
                        }),
                        !editingSubmissionTypes || !contractActive 
                            ? {} 
                            : {
                                title: "Actions",
                                key: "actions",
                                width: "10%",
                                render: ({ id }) => {
                                    return (
                                        <Button
                                            type="danger"
                                            id={`remove-${id}`}
                                            style={{ visibility: modified.removeSubmissionTypeIds?.includes(id) ? "hidden" : "visible" }}
                                            size="small"
                                            onClick={() => {
                                                const removedSubmissionTypeIds = isUndefinedOrNull(modified.removeSubmissionTypeIds)
                                                    ? []
                                                    : modified.removeSubmissionTypeIds;
                                                return setModified({
                                                    ...modified,
                                                    removeSubmissionTypeIds: [
                                                        ...removedSubmissionTypeIds,
                                                        id
                                                    ]
                                                });
                                            }}
                                        >
                                            Remove
                                        </Button>
                                    );
                                }
                            }
                    ]}
                    rowKey="id"
                    dataSource={filteredSubmissionTypesOfVersion}
                />

            </>
        );
    };

    const ButtonBar = () => {
        return (
            <>
                { userPermCreateSubmissionType 
                    ? <PCDULink
                        className="ant-btn ant-btn-primary link-button"
                        to={{
                            pathname: "/submission-types/create",
                            search: `?contract_type=${contractType?.id}`
                        }}

                        style={{
                            float: "right"
                        }}
                    >
                        Create New Submission Type
                    </PCDULink>
                    : null
                }
                { !editingSubmissionTypes 
                    ? <Button
                        style={{ 
                            display: userPermCreateContractTypes ? "inline-block" : "none" 
                        }}
                        size="default"
                        id="startEditing"
                        disabled={!contractActive}
                        className="spaceBelow-xs ant-btn ant-btn-primary"
                        onClick={() => {
                            clearOut();
                            setEditingSubmissionTypes(true);
                        }}
                    >
                        Edit
                    </Button>
                    : null 
                }
                {   editingSubmissionTypes 
                    ? <Button
                        style={{ 
                            display: userPermCreateContractTypes ? "inline-block" : "none",
                            marginRight: "4px"
                        }}
                        size="default"
                        type="primary"
                        disabled={!contractActive}
                        id="createObligation"
                        className="ownButton spaceBelow-xs btn btn-sm btn-primary"
                        onClick={() => setCreateObligationsVis(true)} >
                            Add Submission Types
                    </Button>
                    : null 
                }
                { editingSubmissionTypes
                    ? <Button
                        style={{ 
                            display: userPermCreateContractTypes ? "inline-block" : "none"
                        }}
                        disabled={!Array.isArray(modified.addSubmissionTypeIds) && !Array.isArray(modified.removeSubmissionTypeIds)}
                        type="primary"
                        size="default"
                        id="saveSubmissionTypeChanges"
                        className="spaceBelow-xs spaceBetween-xs"
                        onClick={() => setSaveSubmissionTypeChangesVis(true)}
                    >
                        Save Changes
                    </Button>
                    : null 
                }
                { editingSubmissionTypes 
                    ? <Button
                        style={{ 
                            display: userPermCreateContractTypes ? "inline-block" : "none"
                        }}
                        size="default"
                        id="startEditing"
                        className="spaceBelow-xs spaceBetween-xs"
                        onClick={() => {
                            // don't need to "clearOut", just remove removeSubmissionTypeIds from modified obj :: 
                            const newModifiedObj = { ...modified };
                            delete newModifiedObj.removeSubmissionTypeIds;
                            setModified(newModifiedObj);
                            setEditingSubmissionTypes(false);
                        }}
                    >
                        Cancel
                    </Button>
                    : null 
                }
            </>
        );
    };

    return (
        <>
            <h2>Submission Types</h2>
            <div style={{ height: "50px" }}>
                { contractActive 
                    ? <ButtonBar />
                    : userPermCreateContractTypes 
                        ? <p>{ `Submission Types cannot be added or removed from ${displayedVersionText} because it is not currently active based on its start date and/or end date.`}</p>
                        : null
                }
            </div>
            { error ? <ErrorMessage /> : <SubmissionTypesTable />}
            {submissionTypeAssignmentModal()}
            {assignSubmissionTypeModal()}
        </>
    );
});