import React from "react";
import { LibraryChangelogsQuery } from "./queries.js";
import { ChangelogsTable } from "./changelogs-table.js";
import { LibraryChangelogsSubjectLink } from "./library-changelogs-subject-link.js";

export const LibraryChangelogs = (({  
    actions,
    roles,
    subjectData,
    setSubjectData 
}) => {
    const subjectBreadcrumb = {
        label: "Library Changelogs",
        path: "/changelogs/libraryFolder"
    };
    
    return (
        <div style={{minWidth: "900px"}}>
            <ChangelogsTable 
                subjectBreadcrumb={subjectBreadcrumb}
                subjectData={subjectData}
                setSubjectData={setSubjectData}
                filterOptions={{
                    actions,
                    roles
                }}
                exportFilename={"Library_Changelogs.csv"}
                query={LibraryChangelogsQuery}
                searchableSubject={false}
                suppressDrilldown={true}
                subjectColumn={
                    {
                        title: "Library Resource",
                        dataIndex: "subjectId",
                        sorter: false,
                        render: (subjectId, { action, data }) => {
                            return <LibraryChangelogsSubjectLink 
                                subjectBreadcrumb={subjectBreadcrumb}
                                subjectId={subjectId}
                                action={action}
                                data={data}
                            />;
                        }
                    }
                }
            />
        </div>
    );
});