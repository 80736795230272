import React, { useEffect, useLayoutEffect, useState } from "react";
import { Checkbox, Row, Col } from "antd";
import { NoNotifications } from "./noNotifications.js";

const DEFAULT_TITLE = "Contract Types";
const DEFAULT_MESSAGE = "Select which contract types for which you would like to receive notifications.";

const isChanged = (preference, selected) => {
    if (preference.active && !selected.includes(preference.id)) {
        return true;
    } else if (!preference.active && selected.includes(preference.id)) {
        return true;
    } else {
        return false;
    }
};

const makeTwoColumns = (preferences) => {
    const breakPoint = preferences.length % 2 === 0 
        ? preferences.length / 2
        : Math.ceil(preferences.length / 2);

    if (preferences.length >= 6) {
        return [preferences.slice(0, breakPoint), preferences.slice(breakPoint)];
    } else {
        return [preferences];
    }
};

const HeaderedContainer = ({ title, message, children }) => {
    return <div style={{ margin: "30px 0px 15px 4px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h2 className="header-color" style={{ fontWeight: 600, marginTop: "-5px" }}>
                { title }
            </h2>
        </div>
        { message ? <p>{ message }</p> : null }
        { children }
    </div>;
};


export const ContractTypesNotification = ({ 
    type,
    contractTypePreferences, 
    addChanges,
    resetFlag,
    title = DEFAULT_TITLE, 
    message = DEFAULT_MESSAGE
}) => {
    const sortedContractTypePreferences = Array.isArray(contractTypePreferences) ? 
        contractTypePreferences.slice().sort((pref1, pref2) => {
            return pref1.contractType.name.localeCompare(pref2.contractType.name);
        })
        : [];

    const activePreferenceIds = sortedContractTypePreferences.filter(({ active }) => active).map(({ id }) => id);

    const [selected, setSelected] = useState(activePreferenceIds);

    useLayoutEffect(() => {
        if (resetFlag === true) {
            setSelected(activePreferenceIds);
        }
    }, [resetFlag]);

    useEffect(() => {
        const changes = sortedContractTypePreferences.reduce((obj, pref) => {
            const isSelected = selected.includes(pref.id);
            if (isSelected && !pref.active) {
                obj[pref.id] = {
                    active: true,
                    isModified: true,
                    type
                };
            } else if (!isSelected && pref.active) {
                obj[pref.id] = {
                    active: false,
                    isModified: true,
                    type
                };
            } else {
                obj[pref.id] = {
                    active: pref.active,
                    isModified: false,
                    type
                };
            }
            return obj;
        }, {});
        addChanges(changes);
    }, [selected]);

    if (sortedContractTypePreferences.length === 0) {
        return <HeaderedContainer title={title} message={message}>
            <NoNotifications />
        </HeaderedContainer>;
    }

    const checkboxColumns = makeTwoColumns(sortedContractTypePreferences);

    const calculateSelections = (newSelections, column) => {
        const selectionsInOtherColumn = selected.filter(id => !checkboxColumns[column].some((pref) => id === pref.id));
        return [...selectionsInOtherColumn, ...newSelections];
    };

    return (
        <HeaderedContainer title={title} message={message}>
            <div style={{ minWidth: "800px" }}>
                <Row>
                    { checkboxColumns.map((col, i, arr) => {
                        return <Col key={`ctp-col-${i}`} span={arr.length === 2 ? 12 : 24}>
                            <Checkbox.Group 
                                className="checkbox-column-fit-content-label"
                                key={`ctp-checkbox-group-${i}`}
                                style={{ display: "flex", flexDirection: "column" }}
                                value={selected}
                                onChange={(selections) => {
                                    if (arr.length === 1) {
                                        // only one column, don't need to do any further calculations :: 
                                        setSelected(selections);
                                    } else {
                                        setSelected(calculateSelections(selections, i));
                                    }
                                }}
                                options={col.map(pref => ({ 
                                    key: `ctp-checkbox-${pref.id}`,
                                    label: `${pref.contractType.name} - ${pref.contractType.specifier}${isChanged(pref, selected) ? " (Unsaved Change)" : ""}`, 
                                    value: pref.id 
                                }))}
                            />
                        </Col>;
                    })}
                </Row>
            </div>
        </HeaderedContainer>
    );
};