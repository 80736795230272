import React from "react";
import defaultRender from "./defaultTableRender.js";
import { DatePickerDropdown } from "../components/common/DatePickerDropdown/index.js";
import { CalendarIcon } from "../components/common/CalendarIcon/index.js";


export const dateable = ({
    title,
    dataIndex,
    className,
    key,
    sorter,
    sortDirections,
    width,
    defaultSortOrder,
    startShown,
    alwaysOn,
    render = defaultRender,
    handleReset,
    setFilter,
    setPage,
    filter,
    includeTitle = false,
    ellipsis
}) => {
    const setFilterToUse = typeof setPage === "function" 
        ? (...args) => {
            setPage(1);
            setFilter(...args);
        }
        : setFilter;
    
    return {
        title,
        dataIndex,
        className,
        key,
        sorter,
        defaultSortOrder,
        startShown,
        alwaysOn,
        sortDirections,
        width,
        ellipsis,
        filterDropdown: () => {
            return <DatePickerDropdown 
                setFilter={setFilterToUse} 
                filter={filter} 
                resetFilter={handleReset} 
                title={includeTitle ? title : ""} 
            />;
        },
        onFilter: (value, record) => {
            return record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase());
        },
        filterIcon: () => { 
            const isActive = Array.isArray(filter) && filter.length > 0;
            return CalendarIcon(isActive); 
        },
        render
    };
};
