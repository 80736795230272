import { gql } from "@apollo/client";

export const userDetailQuery = gql`
    query UserDetailQuery (
        $id: ID!
    ) {
        user(id: $id) {
            id
            serviceAccount
            email
            ncid
            name
            active
            attestationFlag
            attestationRoleName
            isSubmitter
            memberships {
                id
                organization {
                    id
                    specifier
                    name
                }
            }
            assignments {
                id
                node {
                    __typename
                    ... on SubmissionType {
                        id
                        name
                        specifier
                    }
                    ... on Organization {
                        id
                        name
                    }
                    
                }
                role {
                    id
                    specifier
                }
            }
        }
        
    }
`;

/*
    
*/
export const userAssignmentQuery = gql`
    query UserAssignmentQuery (
        $sortOn: String
        $sortBy: String
    ) {
        organizationsList {
            organizations {
                id
                name
                specifier
            }
        }
        roles {
            id
            specifier
        }
        submissionTypesList (
            sortOn: $sortOn
            sortBy: $sortBy
        ) {
            submissionTypes {
                id
                name
                category {
                    id 
                    name 
                    secondaryNotifierFlag
                    secondaryReviewerFlag
                }
                hasPrimaryReviewer
                specifier
            }
        }
    }
`;
