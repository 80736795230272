import React, { useState } from "react";

import { Input } from "antd";

const { TextArea } = Input;

export const TextAreaDetail = ({
    title,
    value,
    onValueUpdated,
    readOnly = false,
    rows = 4,
    strict = false,
    placeholder,
    passedClasses,
    preventNewLine = false // to skip the 'Enter' key to create new line
}) => {
    const [focused, setFocused] = useState(false);
    const [updatedValue, setUpdatedValue] = useState(value);

    if (!strict) {
        if (typeof value === "undefined" && typeof updatedValue !== "undefined") {
            setUpdatedValue(undefined);
        }
    }

    const classNames = `${passedClasses ?? ""} input input--sae input--filled`;

    const label = (
        <label className="input__label input__label--sae">
            <span className="input__label-content input__label-content--sae">
                {title}
            </span>
        </label>
    );

    const input = (
        <TextArea
            style={{ border: focused ? "1px black solid" : "", paddingLeft: "2px" }}
            value={strict ? value : updatedValue}
            rows={rows}
            readOnly={readOnly}
            placeholder={placeholder}
            className="input__field input__field--saeTmp "
            onChange={(e) => {
                if (!strict) {
                    setUpdatedValue(e.target.value);
                }
                onValueUpdated(e);
            }}
            onFocus={() => {
                setFocused(true);
            }}
            onBlur={() => {
                setFocused(false);
            }}
            // to prevent user to create new line while press "Enter"
            onKeyPress={(e) => {
                if (preventNewLine && e && e.key === "Enter"){
                    e.preventDefault();
                }
            }}
        />
    );

    return (
        <span className={classNames}>
            {label}
            {input}
        </span>
    );
};
