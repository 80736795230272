import React from "react";
import { CenteredBoxWithLogo } from "../common/CenteredBoxWithLogo/index.js";
import { AuthenticationLayout } from "../common/AuthenticationLayout/index.js";

export const NoActiveUser = () => {
    return (
        <AuthenticationLayout>
            <CenteredBoxWithLogo>
                <div style={{
                    color: "white",
                    textAlign: "center",
                    margin: "10px 0"
                }}>
                    <p style={{ fontWeight: "600" }}>This NCID is not active in the PCDU application.</p>
                    <p>To login as a different user, first close your browser to terminate your session.</p>
                </div>
            </CenteredBoxWithLogo>
        </AuthenticationLayout>
    );
};
