import React from "react";
import NCFlagImg from "../../../assets/ncflag.png";

export const NCWarning = () =>
    <div
        id="NCWarning"
        style={{
            position: "relative",
            width: "100%",
            bottom: "10%"
        }}
    >
        <div
            className="auth-box-shadow"
            style={{
                color: "#8a6d3b",
                margin: "40px auto 0 auto",
                textAlign: "center",
                clear: "both",
                maxWidth: "300px",
                padding: "20px 10px 10px"
            }}
        >
            <span style={{
                paddingRight: "10px",
                float: "left"
            }}>
                <span style={{
                    background: `url(${NCFlagImg}) 100% 100% no-repeat`,
                    width: "60px",
                    height: "40px",
                    display: "inline-block",
                    backgroundSize: "cover",
                    backgroundPosition: 0
                }}>
                    &nbsp;
                </span>
            </span>
            <p>
                WARNING: This is a government computer system, which may be accessed and used only for authorized business by authorized personnel.
                Unauthorized access or use of this computer system may subject violators to criminal, civil and/or administrative action.
                <br />NCC743
            </p>
        </div>
    </div>
;