import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faFilterCircleXmark } from "@fortawesome/free-solid-svg-icons";

export const FilterIcon = (active) => {
    return <FontAwesomeIcon
        style={{ padding: "4px" }}
        icon={active ? faFilterCircleXmark : faFilter} size="lg"
    />;
};
