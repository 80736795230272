import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faMagnifyingGlassPlus } from "@fortawesome/free-solid-svg-icons";

export const SearchIcon = ({ active }) => {
    return <FontAwesomeIcon 
        size="lg" 
        style={{ padding: "4px" }} 
        icon={active ? faMagnifyingGlassPlus : faSearch} 
    />;
};