import { gql } from "@apollo/client";

export const userLogQuery = gql`
    query UserLogQuery (
        $limit: Int
        $offset: Int
        $userNcid: String
        $sortOn: String 
        $sortBy: String 
        $activeFilter: Boolean 
        $dateFilter: [String] 
    ) {
        userLogList (
            limit: $limit
            offset: $offset
            userNcid: $userNcid 
            sortOn: $sortOn 
            sortBy: $sortBy 
            activeFilter: $activeFilter 
            dateFilter: $dateFilter 
        ) {
            userLogs {
                id
                userId
                userNcid
                username
                action
                browserName
                iPAddress
                activityDate
                count
            } 
        }
    }
`;