import React from "react";
import { useQuery } from "@apollo/client";
import { message, Modal } from "antd";
import { createSubmissionTypeQuery } from "./query.js";
import { CriteriaVersionDrilldown } from "../common/CriteriaVersionDrilldown/index.js";
import { Table } from "../common/Table/index.js";
import { 
    TRUE_FALSE_OPTIONS, 
    OPERATIONAL_REPORTS,
    CONTRACTUAL_DELIVERABLES
} from "../../constants/index.js";


export const getNewBreadcrumbs = (submissionType, lastHistoryBreadcrumb) => {
    const { id, specifier } = submissionType;
    return [
        lastHistoryBreadcrumb?.path === "/submission-types" 
            ? null 
            : { path: "/submission-types", label: "Submission Types", tooltip: "All Submission Types" },
        { path: `/submission-types/${id}`, label: `${specifier}`, tooltip: `Submission Type: ${specifier}` }
    ];
};

export const newSubmissionTypeRequiredFields = [
    "contractTypeId",
    "name",
    "specifier",
    "categoryId",
    "businessUnitId",
    "daysToReview",
    "daysToReviewType",
    "daysToReSubmit",
    "daysToReSubmitType",
    "useAttestation",
    "packagedSubmission",
    "bidpFlag",
    "dqFlag",
    "frequency"
];

export const unEditableMessage = "This submission type cannot be edited because it is currently archived.";
export const unArchivableMessage = "This submission type cannot be edited, archived, or de-archived, because its Submission Type Category is currently archived.";
export const SAVE_BUTTON_FIELDS = {
    contractTypeId: true,
    specifier: true,
    description: true,
    name: true,
    daysToReview: true,
    daysToReviewType: true,
    daysToReSubmit: true,
    daysToReSubmitType: true,
    businessUnitId: true,
    useAttestation: true,
    packagedSubmission: true,
    frequency: true,
    startDate: true,
    bidpFlag: true,
    dqFlag: true,
    adhocDueDate: true,
    dueHours: true,
    dueDays: true,
    dueMonths: true,
    categoryId: true,
    periodDays: true,
    periodMonths: true,
    periodHours: true
};

export const SUBMISSION_TYPES_NEW_VERSION_FIELDS = {
    "frequency": "Submission Frequency",
    "startDate": "Start Date",
    "adhocDueDate": "Adhoc Due Date",
    "periodHours": "First Period of the Year",
    "periodDays": "First Period of the Year",
    "periodMonths": "First Period of the Year",
    "dueHours": "Due Offset",
    "dueDays": "Due Offset",
    "dueMonths": "Due Offset"
};

export const validDocumentTypeName = (name) => {
    const INVALID_DOCUMENT_TYPE_CHARACTERS = ["+"];
    if (name && typeof name === "string" && name.trim()) {
        return INVALID_DOCUMENT_TYPE_CHARACTERS.every(invalidChar => !name.includes(invalidChar));
    } else {
        return false;
    }
};

export const buildNewSubmissionType = (modified, isNullingAdhocDueDate) => {
    return {
        contractTypeId: modified.contractTypeId,
        specifier: modified.specifier,
        description: modified.description,
        name: modified.name,
        daysToReview: modified.daysToReview,
        daysToReviewType: modified.daysToReviewType,
        daysToReSubmit: modified.daysToReSubmit,
        daysToReSubmitType: modified.daysToReSubmitType,
        businessUnitId: modified.businessUnitId,
        useAttestation: modified.useAttestation,
        packagedSubmission: modified.packagedSubmission,
        frequency: modified.frequency,
        startDate: modified.startDate,
        bidpFlag: modified.bidpFlag,
        dqFlag: modified.dqFlag,
        adhocDueDate: modified.adhocDueDate,
        periodHours: modified.periodHours,
        periodDays: modified.periodDays,
        periodMonths: modified.periodMonths,
        dueHours: modified.dueHours,
        dueDays: modified.dueDays,
        dueMonths: modified.dueMonths,
        categoryId: modified.categoryId,
        isNullingAdhocDueDate,
        firstPeriodDate: modified.firstPeriodDate
    };
};

export const activeVersionsCount = (versions) => {
    if (!Array.isArray(versions) || !versions.length) {
        return 0;
    } else {
        return versions.filter(v => v?.deleted === false).length;
    }
};

export const VersionList = ({ versions, createdAt: originalCreatedAt }) => {
    const sortedVersions = versions.slice().sort((a, b) => b.createdAt - a.createdAt); 
    const criteriaVersionsData = sortedVersions.map((criterion, i, arr) => {
        const isFirstVersion = i === sortedVersions.length - 1;
        return CriteriaVersionDrilldown({ 
            criterion, 
            title: `Criteria Version ${sortedVersions.length - i}`,  
            hideBottomBorder: isFirstVersion, 
            version: sortedVersions.length - i,
            previousCreatedAt: isFirstVersion ? originalCreatedAt : arr[i + 1]?.createdAt, 
            returnData: true 
        });
    });

    const filteredCriteriaVersions = criteriaVersionsData
        .filter(v => v?.status === "Active")
        .map((v, i, a) => ({ versionToShow: a.length - i, ...v}) );

    return (
        <div style={{ margin: "25px" }}>
            <div style={{ borderLeft: "2px solid slategray" }}> 
                <div style={{ marginLeft: "12px" }}>
                    <h3> Criteria History </h3>
                    <Table 
                        rowKey={"id"}
                        dataSource={filteredCriteriaVersions} 
                        columns={[
                            {
                                title: "Version",
                                dataIndex: "versionToShow",
                                sorter: false,
                                key: "version"
                            }, 
                            {
                                title: "Citation",
                                dataIndex: "citation",
                                sorter: false,
                                key: "citation"
                            }, 
                            {
                                title: "Description",
                                dataIndex: "description",
                                sorter: false,
                                key: "description"
                            },
                            {
                                title: "Active from",
                                dataIndex: "start",
                                sorter: false,
                                key: "start"
                            }, 
                            {
                                title: "Active to",
                                dataIndex: "end",
                                sorter: false,
                                key: "end"
                            }
                        ]}
                    />
                </div>
            </div>
        </div>
    );
}; 

export const getLatestTimestampForCriteria = (criteria) => {
    const originalCreatedAt = criteria.createdAt;
    let latestTimestamp;
    if (Array.isArray(criteria?.versions) && criteria.versions.length > 0) {
        latestTimestamp = Math.max(...criteria.versions.map(version => version.createdAt));
    }
    return latestTimestamp ?? originalCreatedAt;
};

export const getDayTypeDisplayValueFromText = (dayTypeConstants, text) => {
    return dayTypeConstants.find((dayType) => dayType.displayValue === text)?.text;
};

export const useCreateDataQuery = (userPermEditSubmissionType) => {
    const result = useQuery(createSubmissionTypeQuery,
        {
            variables: {
                activeFilter: true
            },
            fetchPolicy: "no-cache"
        }
    );

    const blankDataResponse = {
        contractTypes: [],
        submissionTypes: []
    };

    return userPermEditSubmissionType 
        ? result 
        : { 
            data: blankDataResponse, 
            error: undefined,
            loading: false 
        };
};

export const validateSubmissionTypeSpecifier = (newSpecifier, submissionTypes, constants) => {
    try {
        const validFirstPositionSpecifierCharacters = constants.VALID_FIRST_POSITION_SPECIFIER_CHARACTERS;
        const invalidSpecifierCharacters = constants.INVALID_SPECIFIER_CHARACTERS;
        const invalidSpecifierMessage = `The Submission Type ID cannot contain these characters: ${invalidSpecifierCharacters.join(", ")}`;
        const invalidFirstPositionMessage = "The Submission Type ID must begin with a letter or a digit.";
        const submissionTypeSpecifierExists = submissionTypes.some(({ specifier }) => specifier === newSpecifier);
        
        if (invalidSpecifierCharacters.some(char => newSpecifier.includes(char))) {
            message.error(invalidSpecifierMessage);
            return false;
        } else if (!validFirstPositionSpecifierCharacters.some(char => char === newSpecifier[0])) {
            message.error(invalidFirstPositionMessage);
            return false;
        } else if (submissionTypeSpecifierExists) {
            Modal.error({
                title: "Error Creating Submission Type",
                content: "A Submission Type with this ID already exists. Please use a unique value."
            });
            return false;
        } else {
            return true;
        }
    } catch (err) {
        message.error("There was an error when validating the Submission Type ID");
        return false;
    }
};

// helper function for getSubmissionTypeSelectFields -- not exported :: 
const getDaysToResubmit = (
    constantsData,
    forOperationalReport, 
    forContractualDeliverable 
) => {
    // case of "Operational Reports", skip option 14
    // case of "Contractual Deliverables", skip option 7
    // else show all values
    const filteredDaysToResubmit = constantsData?.DAYS_TO_RESUBMIT ? constantsData?.DAYS_TO_RESUBMIT?.filter(option => option.deleted === false) : [];

    if (forOperationalReport) {
        return Object.values(filteredDaysToResubmit)
            .filter((val) => val.text !== "14")
            .map((option) => ({text: option.text, value: option.text}))
            .sort((a,b) => a.text - b.text);
    } else if (forContractualDeliverable){
        return Object.values(filteredDaysToResubmit)
            .filter((val) => val.text !== "7" )
            .map((option) => ({text: option.text, value: option.text}))
            .sort((a,b) => a.text - b.text);
    } else {
        return Object.values(filteredDaysToResubmit)
            .map((option) => ({text: option.text, value: option.text}))
            .sort((a,b) => a.text - b.text);
    }
};

export const getSubmissionTypeSelectFields = ({
    category,
    businessUnits,
    constants,
    submissionType
}) => {
    const filteredBusinessUnits = businessUnits ? businessUnits?.filter(bu => bu.deleted === false && bu.id !== submissionType?.businessUnitId) : [];
    const filteredDaysToReview = constants?.DAYS_TO_REVIEW ? constants?.DAYS_TO_REVIEW?.filter(option => option.deleted === false) : [];
    const forOperationalReport = category?.name === OPERATIONAL_REPORTS;
    const forContractualDeliverable = category?.name === CONTRACTUAL_DELIVERABLES;

    const businessUnitForSubmissionType = { text: submissionType?.businessUnitName, value: submissionType?.businessUnitId };

    const activeBusinessUnitOptions = Object.values(filteredBusinessUnits).map((option) => {
        return {
            text: option.name,
            value: option.id
        };
    });

    return [
        {
            title: "Business Unit",
            key: "businessUnitId",
            options: submissionType ? [businessUnitForSubmissionType, ...activeBusinessUnitOptions] : activeBusinessUnitOptions
        },
        {
            title: "Days to Complete Review",
            key: "daysToReview",
            options: Object.values(filteredDaysToReview).map((option) => {
                return {
                    text: option.text,
                    value: option.text
                };
            }).sort((a,b) => a.text - b.text),
            daysTypeFields: true
        },
        {
            title: "Days to Resubmit",
            key: "daysToReSubmit",
            options: getDaysToResubmit(
                constants,
                forOperationalReport,
                forContractualDeliverable
            ),
            daysTypeFields: true
        },
        {
            title: "Requires Attestation",
            key: "useAttestation",
            options: TRUE_FALSE_OPTIONS
        },
        {
            title: "Bidp Flag",
            key: "bidpFlag",
            options: TRUE_FALSE_OPTIONS,
            readOnly: category?.bidpEnabledFlag === false 
        },
        {
            title: "DQ Flag",
            key: "dqFlag",
            options: TRUE_FALSE_OPTIONS,
            readOnly: category?.dqEnabledFlag === false 
        },
        {
            title: "Bundled Submission",
            key: "packagedSubmission",
            options: TRUE_FALSE_OPTIONS,
            readOnly: category?.bundledSubmissionFlag === false
        }
    ];
};

export const getModifiedForCategory = (category, modified) => {
    return {
        ...modified,
        packagedSubmission: category?.bundledSubmissionFlag === true ? (modified?.packagedSubmission ?? false) : false,
        bidpFlag: category?.bidpEnabledFlag === true ? (modified?.bidpFlag ?? false) : false,
        dqFlag: category?.dqEnabledFlag === true ? (modified?.dqFlag ?? false) : false,
        categoryId: category?.id
    };
};