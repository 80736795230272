import React from "react";
import { Modal } from "antd";
import { CriteriaTable } from "../common/CriteriaTable/index.js";
import { isUndefinedOrNull } from "../../utils/functions.js";
import { SUBMISSION_STATUS_LIST } from "../../constants/index.js";

const criteriaHaveChanged = (criteriaList, timestamp) => { 
    const ts = parseInt(timestamp, 10);
    const criteriaHaveBeenAdded = criteriaList?.some?.(criteria => criteria?.createdAt > ts);
    const criteriaHaveChanged = criteriaList?.some?.(criteria => criteria?.versions?.some?.(version => version?.createdAt > ts));
    return criteriaHaveBeenAdded || criteriaHaveChanged;
};

const criteriaTitle = (version) => {
    const specifier = version?.specifier;
    const versionNumber = !isUndefinedOrNull(specifier) 
        ? parseInt(specifier, 10) + 1
        : "";
    
    return `Criteria ${versionNumber ? `(Submission Version ${versionNumber})` : ""}`;
};

const SubmissionCriteriaMessage = ({ 
    submissionNewestVersionSpecifier, 
    submissionNewestVersionDate, 
    criteriaList 
}) => {

    const versionNumber = !isUndefinedOrNull(submissionNewestVersionSpecifier) 
        ? parseInt(submissionNewestVersionSpecifier, 10) + 1
        : "";

    return (
        <div>
            <p style={{marginBottom: "5px"}}>
                <span style={{color: "red"}}>Important Note</span>
            </p>
            <p>
                The criteria for this submission type have been modified since the latest version of the submission was submitted. The new criteria are displayed below.  
                Click <span 
                    style={{
                        color: "blue", 
                        textDecoration: "underline", 
                        cursor: "pointer"
                    }}
                    onClick={() => Modal.info({
                        style: {
                            minWidth: "70%"
                        },
                        title: "Versioned Criteria",
                        content: (
                            <div style={{marginTop: "20px"}}>
                                <p> Criteria in effect when submission version {versionNumber} was created </p>
                                <CriteriaTable 
                                    criteriaList={criteriaList} 
                                    uniquePersistentStateKey={"criteria-in-effect-table"}
                                    versionFilterTimestamp={submissionNewestVersionDate} 
                                    loadingOnUndefined={true}
                                    noHeader={true}
                                />
                            </div>
                        )
                    })}
                > here </span> to view the criteria that were in effect when Submission Version {versionNumber} was created. 
            </p>
        </div>
    );
};

export const SubmissionCriteriaTable = ({
    criteriaList,
    submissionVersionDate,
    submissionStatus,
    latestVersionSelected, 
    selectedSubmissionVersion 
}) => {
    // ========================== 
    // CRITERIA TABLE LOGIC 

    // we show latest criteria and give option to see versioned criteria, if: 
    //   - displayed version is latest version 
    //   - the criteria have changed since latest submission version was made 
    //   - the submission status is "Awaiting New Version" or "Awaiting Final Version" 

    const showLatestCriteria = latestVersionSelected
        && [SUBMISSION_STATUS_LIST.version, SUBMISSION_STATUS_LIST.final_requested].includes(submissionStatus)
        && criteriaHaveChanged(criteriaList, submissionVersionDate);
        
    return (
        <CriteriaTable 
            persistState
            criteriaList={criteriaList} 
            versionFilterTimestamp={showLatestCriteria ? Date.now() : submissionVersionDate} 
            loadingOnUndefined={true}
            title={showLatestCriteria ? "Criteria (Latest)" : criteriaTitle(selectedSubmissionVersion)}
            additionalMessage={
                showLatestCriteria 
                    ? <SubmissionCriteriaMessage 
                        submissionNewestVersionSpecifier={selectedSubmissionVersion?.specifier}
                        submissionNewestVersionDate={submissionVersionDate}
                        criteriaList={criteriaList}
                    />
                    : null 
            }
        />
    );
};