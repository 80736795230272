export const isBeingModified = (modifiedObj, preference) => {
    if (!modifiedObj || !preference) {
        return false;
    } else {
        return Object.hasOwn(modifiedObj, preference.id) && modifiedObj[preference.id].active !== preference.active;
    }
};

export const sortNotificationPreferences = ({ sortOn, sortBy, data }) => {
    let sortFunction;
    switch (sortOn) {
        case "specifier":
            sortFunction = (a, b) => a.submissionType.specifier.trim().localeCompare(b.submissionType.specifier.trim());
            break;
        case "name": 
            sortFunction = (a, b) => a.submissionType.name.trim().localeCompare(b.submissionType.name.trim());
            break;
        case "category":
            sortFunction = (a, b) => a.submissionType.categoryName.trim().localeCompare(b.submissionType.categoryName.trim());
            break;
        default: 
            sortFunction = (a, b) => a - b;
    }
    const sortedAscending = data.slice().sort(sortFunction);
    return sortBy === "ascend" ? sortedAscending : sortedAscending.reverse();
};

const filterArrayToLookup = (arr) => {
    return Array.isArray(arr) && arr.length > 0
        ? arr.reduce((lookup, key) => {
            if (key && typeof key === "string") {
                lookup[key] = true;
            }
            return lookup;
        }, {})
        : null;
};

const isIn = (obj, key, nullValue = false) => obj ? Object.hasOwn(obj, key) : nullValue;

export const filterNotificationPreferences = (preferences, { 
    modified, 
    excludedContractTypes, 
    nameSearchText, 
    specifierSearchText,
    categoryFilter, 
    notifyFilter,
    contractTypeFilter
}) => {
    const filterByName = nameSearchText && typeof nameSearchText === "string";
    const filterBySpecifier = specifierSearchText && typeof specifierSearchText === "string";

    // gather filters into lookup for better performance :: 
    const filterLookups = {
        categories: filterArrayToLookup(categoryFilter),
        contractTypes: filterArrayToLookup(contractTypeFilter),
        excludedContractTypes: filterArrayToLookup(excludedContractTypes?.map?.(({ name }) => name))
    };

    if (!Array.isArray(preferences)) {
        // normalize data as empty array :: 
        return [];
    } else {
        return preferences.filter(preference => { 
            if (!isIn(filterLookups.categories, preference?.submissionType?.categoryName, true)) {
                // doesn't match category filter :: 
                return false;
            }

            if (!isIn(filterLookups.contractTypes, preference?.submissionType?.contractType?.name, true)) {
                // doesn't match category filter :: 
                return false;
            }

            if (isIn(filterLookups.excludedContractTypes, preference?.submissionType?.contractType?.name)) {
                // exclude submission type notifications that are silenced or inactive contract types :: 
                return false;
            }

            if (Array.isArray(notifyFilter) && notifyFilter.length > 0) {
                let include = false;
                const booleanNotifyValues = notifyFilter.filter(val => typeof val === "boolean");
                
                if (booleanNotifyValues.length > 0) {
                    if (booleanNotifyValues.some(bool => bool === preference.active)) {
                        include = true;
                    }
                }

                // independently, include record if "Unsaved Changes" option was selected, and record is being modified :: 
                if (notifyFilter.includes("modified") && modified?.[preference.id]?.isModified) {
                    include = true;
                }

                if (!include) {
                    return false;
                }
            }

            const submissionType = preference?.submissionType; 
            const { name, specifier } = submissionType; 
            const nameMatches = name
                .toLowerCase()
                .includes(nameSearchText.toLowerCase());

            const specifierMatches = specifier
                .toLowerCase()
                .includes(specifierSearchText.toLowerCase());

            if (filterByName && filterBySpecifier) {
                return nameMatches && specifierMatches; 
            } else if (filterByName) {
                return nameMatches;
            } else if (filterBySpecifier) {
                return specifierMatches; 
            } else {
                // passes all filters :: 
                return true; 
            }
        });
    }
};