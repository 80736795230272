import React, { useContext, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { Space } from "antd";
import { UserContext } from "../Application/UserContext.js";
import { Main } from "../common/Main/index.js";
import { exportTableRequestAllLogsQuery } from "./queries.js";
import ExportTablesCollapseMenus from "./menu.js";
import { POLLING_DELAY } from "./__exportTableConstants.js";
import { usePreloadedData } from "../Application/PreloadedData/index.js";

export const ExportTables = (() => {
    const { auditReportTypes } = useContext(UserContext);
    const { preloadedData } = usePreloadedData();

    const {
        data,
        refetch, 
        startPolling, 
        stopPolling 
    } = useQuery(
        exportTableRequestAllLogsQuery,
        { fetchPolicy: "no-cache" }
    );

    useEffect(() => {
        if (data?.exportTableRequestAllLogs?.some?.(report => !report.canExport)) {
            // at least one report is still processing; initiate polling for updates :: 
            startPolling(POLLING_DELAY);
        } else {
            stopPolling();
        }
    }, [data]);

    return (
        <>
            <Main
                title="Export Tables"
                breadcrumbs={["Export Tables"]}
            >
                <div className="export-tables-container">
                    <Space direction={"vertical"} size={"middle"} style={{ display: "flex" }}>
                        <Space direction="vertical" size="middle"><div></div></Space>
                        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
                            {auditReportTypes.map((report, i) => {
                                return (
                                    <ExportTablesCollapseMenus
                                        key={i}
                                        index={i}
                                        report={report}
                                        data={{
                                            businessUnits: preloadedData.businessUnits,
                                            categories: preloadedData.categories,
                                            allAuditReportTypes: preloadedData.constants.ALL_AUDIT_REPORT_TYPES,
                                            organizations: preloadedData.organizations,
                                            primaryReviewersList: preloadedData.primaryReviewers,
                                            submissionTypes: preloadedData.submissionTypes,
                                            exportTableRequestAllLogs: data?.exportTableRequestAllLogs ?? [],
                                            exportTableRefetch: refetch
                                        }} />
                                );
                            })}
                        </Space>
                    </Space>
                </div>
            </Main>
        </>
    );
});