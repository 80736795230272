import React from "react";

const CURRENT_DOWNLOADS = new Map();
const FAILED_DOWNLOADS = new Map();

export const createRegisterDownload = (setCurrentDownloads, setFailedDownloads) => {
    return (data) => {
        CURRENT_DOWNLOADS.set(data, { ...data, progress: 0 });
        setCurrentDownloads(Array.from(CURRENT_DOWNLOADS.values()));
        return (progress) => {
            if (progress > 99) {
                CURRENT_DOWNLOADS.delete(data);
            } else if (typeof progress !== "number" || Number.isNaN(progress)) {
                CURRENT_DOWNLOADS.delete(data);
                const failedDownloadId = Symbol("failed download unique identifier");
                FAILED_DOWNLOADS.set(failedDownloadId, { ...data, failedDownloadId });
                // reverse order so that the most recent failed download is first :: 
                setFailedDownloads(Array.from(FAILED_DOWNLOADS.values()).reverse());
            } else {
                CURRENT_DOWNLOADS.set(data, { ...data, progress });
            }
            setCurrentDownloads(Array.from(CURRENT_DOWNLOADS.values()));
        };
    };
};

export const createRemoveFailedDownload = (setFailedDownloads) => {
    return (failedDownloadId) => {
        FAILED_DOWNLOADS.delete(failedDownloadId);
        // reverse order so that the most recent failed download is first :: 
        setFailedDownloads(Array.from(FAILED_DOWNLOADS.values()).reverse());
    };
};

export const RegisterDownloadContext = React.createContext();