import { Layout } from "antd";
import React from "react";
import { NCWarning } from "../NCWarning/index.js";

export const CenteredBoxWithLogo = ({ children, showWarningMsg}) => {

    return (
        <Layout.Content style={{
            display: "flex",
            alignItems: "center",
            height: "50vh"
        }}>
            <div style={{
                width: "100%",
                margin: "0 auto"
            }}>
                <div
                    className="auth-box-shadow"
                    style={{
                        margin: "inherit",
                        width: "100%",
                        maxWidth: "300px",
                        padding: "10px"
                    }}
                >
                    {children}
                </div>
                {showWarningMsg && (
                    <NCWarning></NCWarning>
                )}
            </div>
        </Layout.Content>
    );
};
