import React, { useState, useEffect, useContext } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { CategoryAdditionalOptions } from "./CategoryAdditionalOptions.js";
import { Main } from "../../common/Main/index.js";
import { Col, Row, Form, Button } from "antd";
import { isUndefinedOrNull } from "../../../utils/functions.js";
import { UserContext } from "../../Application/UserContext.js";
import {
    MODIFY_CATEGORY
} from "../../../constants/mutations.js";
import { useMutation, useQuery } from "@apollo/client";
import { handleMutation, handleGraphQLError } from "../../../utils/errorHandling.js";
import { categoryDetail } from "./queries.js";
import { LoadingContent } from "../../common/LoadingContent/index.js";
import { PCDULink } from "../../common/PCDULink/index.js";
import { normalizeCategoryData } from "./_helpers.js";

const getModifiedEntries = (original, modifications) => {
    const normalizedOrig = normalizeCategoryData(original);
    const normalizedMods = normalizeCategoryData(modifications);
    return Object.entries(normalizedMods).filter(([key, newValue]) => {
        const originalValue = normalizedOrig[key];
        return !isUndefinedOrNull(newValue) && newValue !== originalValue;
    });
};


export const CategoryDetail = ({ refetchSideNavCategories, setCategoryListRefetch }) => {
    const params = useParams();    
    const location = useLocation();
    const navigate = useNavigate();
    const [modified, setModified] = useState({});
    const [updating, setUpdating] = useState(false);
    const [editCategory, setEditCategory] = useState(location.state?.editCategory ?? false);
    const [form] = Form.useForm();

    const {
        userPermCreateSubTypeCategory
    } = useContext(UserContext);

    const [modifyCategory] = useMutation(MODIFY_CATEGORY);

    const { loading, error, data, refetch } = useQuery(categoryDetail,
        {
            variables: {
                id: params.id
            },
            fetchPolicy: "no-cache"
        }
    );

    useEffect(() => {
        if (data?.category) {
            const categoryData = normalizeCategoryData(data.category);
            form.setFieldsValue(categoryData);
        }
    }, [data]);

    if (loading) {
        return (
            <LoadingContent />
        );
    }

    if (error) {
        return handleGraphQLError(error);
    }

    const modifiedFields = getModifiedEntries(data.category, modified).map(([key]) => key);
    const buttonsDisabled = modifiedFields.length === 0;

    const onSave = async () => {
        setUpdating(true);
        const id = data.category.id;
        const modifiedEntries = getModifiedEntries(data.category, modified);
        await handleMutation(
            modifyCategory({ 
                variables: { 
                    categoryInput: {
                        id,
                        ...Object.fromEntries(modifiedEntries)
                    }
                }
            }),
            {
                showSuccess: true,
                successMessage: "Category successfully modified!"
            }
        );
        setCategoryListRefetch(true);
        refetch()
            .then(({ data }) => { 
                const newValues = normalizeCategoryData(data.category);
                form.setFieldsValue(newValues);
            })
            .finally(() => {
                setUpdating(false);
                setModified({});
                setEditCategory(false);
            });
    };

    const onReset = () => {
        const originalValues = normalizeCategoryData(data.category);
        setModified({});
        setUpdating(false);
        form.setFieldsValue(originalValues);
    };

    const onCancel = () => {
        setEditCategory(false);
        onReset();
        if (location.state?.editCategory) {
            // user came from Edit button on category list page -- navigate back there :: 
            navigate("/submission-types/category");
        }
    };

    // show edit button if all of the following are true: 
    // 1.) User has permission; 
    // 2.) There is at least one editable field; 
    // 3.) We are not already in edit category mode; 
    // 4.) Category is not already archived 
    const showEditButton = userPermCreateSubTypeCategory && data.category.editableFields.length > 0 && !editCategory && !data.category.deleted;

    return (
        <Main
            showArchive={userPermCreateSubTypeCategory && data.category.editableFields.includes("deleted")}
            showEdit={showEditButton}
            editCallback={() => {
                setEditCategory(true);
            }}
            archived={data?.category?.deleted}
            confirmAction={async () => {
                setUpdating(true);
                await handleMutation(
                    modifyCategory({
                        variables: {
                            categoryInput: {
                                id: data?.category.id,
                                deleted: !data?.category?.deleted
                            }
                        }
                    }),
                    {
                        showSuccess: true,
                        successMessage: `Category successfully ${data?.category?.deleted ? "De-" : ""}Archive `
                    }
                );

                setCategoryListRefetch(true);
                refetchSideNavCategories();
                setUpdating(false);
                refetch();
            }}
            confirmTitle={`Are you sure you want to ${data?.category?.deleted ? "de-" : ""}archive ${data?.category?.name}?`}
            
            title={(editCategory ?  "Edit   " : "") + data?.category?.name}
            breadcrumbs={[
                "Settings",
                <PCDULink
                    to="/submission-types/category"
                    tooltip="Submission Types Categories"
                >
                    Category
                </PCDULink>,
                data?.category?.name
            ]}
        >
            <div>
                <Form
                    className="high-contrast-radio-form"
                    form={form} 
                    layout="vertical" 
                    autoComplete="off"
                >
                    <CategoryAdditionalOptions
                        setModified={(e) => {
                            setModified({
                                ...modified,
                                [e.target.name]: e.target.value
                            });
                        }}
                        modified={modified}
                        readOnly={!userPermCreateSubTypeCategory || data.category.deleted || !editCategory}
                        isArchived={data.category.deleted}
                        enabledFields={data?.category?.editableFields ?? []}
                        modifiedFields={modifiedFields}
                    />
                    {userPermCreateSubTypeCategory && editCategory 
                        ? <Row>
                            <Col>
                                <Button
                                    type="primary"
                                    onClick={onSave}
                                    loading={updating}
                                    disabled={buttonsDisabled}
                                >
                                    Save Changes
                                </Button>

                                <Button
                                    onClick={onReset}
                                    className="spaceBetween-sm"
                                    disabled={buttonsDisabled || updating}
                                    type="danger"
                                    htmlType="reset"
                                >
                                    Discard Changes
                                </Button>

                                <Button
                                    type="secondary"
                                    onClick={onCancel}
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                        : null 
                    }
                </Form>
            </div>
        </Main>
    );
};