import React from "react";
import { Table } from "../../common/Table/index.js";

const DEFAULT_SCROLL = { x: 1200 };

export const applyColumnBookendStyle = (columns, ignoreFirstColumn = false) => columns.map((column, i) => {
    const existingClassNames = column.className ? column.className : "";
    let fullClassName = existingClassNames;
    if (i === 0 && !ignoreFirstColumn) {
        fullClassName = `${existingClassNames} first-column`;
    } else if (i === columns.length - 1) {
        fullClassName = `${existingClassNames} last-column`;
    } 
    return { 
        ...column, 
        className: fullClassName.trim() 
    };
});

export const ScrollableTable = ({
    id,
    columns,
    dataSource, 
    loading,
    rowKey,
    rowSelection,
    pagination = false,
    expandedRowRender,
    onChange,
    expandable,
    rowExpandable,
    scroll,
    noBorders,
    ...otherProps 
}) => {
    const isExpandable = Boolean(rowExpandable || expandable);
    const isSelectable = Boolean(rowSelection);
    const allClassNames = ["TABLE"];
    const additionalTableClassName = isExpandable ? "table-expansion-border" : (isSelectable ? "table-selection-border" : "");
    let columnsToUse = columns;
    if (!noBorders) {
        allClassNames.push("bordered-scroll-table");
        if (additionalTableClassName) {
            allClassNames.push(additionalTableClassName);
        }
        if (!dataSource || dataSource.length === 0) {
            allClassNames.push("no-bottom-border");
        }
        columnsToUse = applyColumnBookendStyle(columns, Boolean(isExpandable || isSelectable));
    }
    return (
        <Table
            className={allClassNames.join(" ")}
            onChange={onChange}
            size={"small"}
            id={id}
            scroll={scroll ? scroll : DEFAULT_SCROLL}
            columns={columnsToUse}
            loading={loading}
            dataSource={dataSource}
            rowKey={rowKey}
            rowSelection={rowSelection}
            pagination={pagination}
            expandedRowRender={expandedRowRender}
            rowExpandable={rowExpandable}
            { ...otherProps }
        />
    );
};