// ______________________________
// GENERAL 

export const DHHS_NAME = "Department of Health and Human Services";
export const oneGB = 1000 * 1000 * 1000;
export const tenGB = 10 * oneGB;
export const twoGB = 2 * oneGB;
export const EXPORTSIZE = 1000;
export const TRUE_FALSE_OPTIONS = [
    {
        text: "Yes",
        value: true
    },
    {
        text: "No",
        value: false
    }
];

// ______________________________
// Table Constant
export const defaultTablePageSize = 10;

// ______________________________
// NAV BAR 

export const MENU_KEYS = {
    submissions: "submissions",
    calendar: "calendar",
    submissionTypes: "submission-types",
    contracts: "contracts",
    documents: "documents",
    library: "library",
    directory: "directory",
    analytics: "analytics"
}; 



// ______________________________
// ROLES

export const CONTRACT_PROMISOR = "CONTRACT_PROMISOR";
export const CONTRACT_PROMISEE = "CONTRACT_PROMISEE";
export const READ_ONLY = "READ_ONLY";
export const SYSTEM_ADMINISTRATOR = "SYSTEM_ADMINISTRATOR";
export const DATA_QUALITY_ADMIN = "DATA_QUALITY_ADMIN";
export const PRIMARY_REVIEWER = "PRIMARY_REVIEWER";
export const SECONDARY_REVIEWER = "SECONDARY_REVIEWER";
export const CONTRACT_ADMINISTRATOR = "CONTRACT_ADMINISTRATOR";
export const SERVICE_ACCOUNT = "SERVICE_ACCOUNT";
export const SUPER_USER = "SUPER_USER";
export const SUBMITTER = "SUBMITTER";
export const SUBMITTER_CEO = "SUBMITTER_CEO";
export const SUBMITTER_CFO = "SUBMITTER_CFO";
export const SUBMITTER_CFO_DELEGATE = "SUBMITTER_CFO_DELEGATE";
export const SUBMITTER_CEO_DELEGATE = "SUBMITTER_CEO_DELEGATE";
export const SECONDARY_NOTIFIER = "SECONDARY_NOTIFIER";
export const INTERNAL_AUDITOR = "INTERNAL_AUDITOR";
export const SECURITY_ADMINISTRATOR = "SECURITY_ADMINISTRATOR";
export const SUBMITTER_NONE = "SUBMITTER_NONE";


export const REVIEWER_ROLES = {
    PRIMARY_REVIEWER,
    SECONDARY_REVIEWER,
    SECONDARY_NOTIFIER
};

export const DHHS_ORRGANIZATION_ROLES = [
    SYSTEM_ADMINISTRATOR,
    READ_ONLY,
    CONTRACT_ADMINISTRATOR,
    INTERNAL_AUDITOR,
    SUPER_USER,
    SECURITY_ADMINISTRATOR
];

export const DHHS_ADMIN_ROLES = {
    SYSTEM_ADMINISTRATOR,
    CONTRACT_ADMINISTRATOR 
};

export const SERVIVE_ACCOUNT_ROLES = [
    DATA_QUALITY_ADMIN
];



export const SUBMITTER_ADDITIONAL_ROLE = {
    SUBMITTER_CEO,
    SUBMITTER_CFO,
    SUBMITTER_CFO_DELEGATE,
    SUBMITTER_CEO_DELEGATE, 
    SUBMITTER_NONE 
}; 

export const ROLE_DISPLAY_CONVERSION = {
    SUBMITTER: "Submitter",
    PRIMARY_REVIEWER: "Primary Reviewer",
    SECONDARY_REVIEWER: "Secondary Reviewer",
    CONTRACT_ADMINISTRATOR: "Contract Administrator",
    DATA_QUALITY_ADMIN:"Data Quality Admin",
    SYSTEM_ADMINISTRATOR: "System Administrator",
    READ_ONLY: "Read-Only",
    SECURITY_ADMINISTRATOR: "Security Administrator",
    SECONDARY_NOTIFIER: "Secondary Notifier",
    INTERNAL_AUDITOR: "Internal Auditor",
    SERVICE_ACCOUNT: "Service Account",
    SUPER_USER: "Super User",
    CONTRACT_PROMISOR: "Contract Promisor",
    CONTRACT_PROMISEE: "Contract Promisee",
    SUBMITTER_CEO: "Submitter CEO",
    SUBMITTER_CFO: "Submitter CFO",
    SUBMITTER_CFO_DELEGATE: "Submitter CFO Delegate",
    SUBMITTER_CEO_DELEGATE: "Submitter CEO Delegate",
    SUBMITTER_NONE: "No Attestation Authority"
};



// ______________________________
// SUBMISSION TYPE CATEGORIES 

export const OPERATIONAL_REPORTS = "Operational Reports";
export const CONTRACTUAL_DELIVERABLES = "Contractual Deliverables";
export const DATA_EXTRACTS = "Data Extracts";


// ______________________________
// SUBMISSION TYPE (FREQUENCIES)  

export const FREQUENCY_OPTIONS = {
    daily: "DAILY",
    weekly: "WEEKLY",
    biweekly: "BI_WEEKLY",
    monthly: "MONTHLY",
    quarterly: "QUARTERLY",
    yearly: "YEARLY",
    adhoc: "AD_HOC"
};


export const FIRST_PERIOD_OF_YEAR_FIELDS = [
    FREQUENCY_OPTIONS.weekly,
    FREQUENCY_OPTIONS.biweekly,
    FREQUENCY_OPTIONS.monthly,
    FREQUENCY_OPTIONS.quarterly,
    FREQUENCY_OPTIONS.yearly
];

export const DUE_OFFSET_FIELDS = [
    FREQUENCY_OPTIONS.daily,
    FREQUENCY_OPTIONS.weekly,
    FREQUENCY_OPTIONS.biweekly,
    FREQUENCY_OPTIONS.monthly,
    FREQUENCY_OPTIONS.quarterly,
    FREQUENCY_OPTIONS.yearly
];


// ______________________________
// SUBMISSIONS  

export const SUBMISSION_STATUS_REVIEWER = {
    review: "Awaiting Review",
    final_version_review: "Awaiting Final Review"
};

export const SUBMISSION_STATUS_SUBMITTER = {
    version: "New Version Requested",
    final_requested: "Final Version Requested"
};

export const SUBMISSION_STATUS_PENDING_FAILED = {
    pending: "Pending",
    failed: "Failed"
};

export const SUBMISSION_STATUS_SUCCESS = "Success";

export const SUBMISSION_STATUS_LIST = {
    review: SUBMISSION_STATUS_REVIEWER.review,
    version: SUBMISSION_STATUS_SUBMITTER.version,
    final_requested: SUBMISSION_STATUS_SUBMITTER.final_requested,
    final_version_review: SUBMISSION_STATUS_REVIEWER.final_version_review,
    final: "Finalized",
    archive: "Archived"
};

export const SUBMISSION_DOCUMENT_STATUS_MAPPING = {
    "review": [SUBMISSION_STATUS_LIST.review],
    "revision": [SUBMISSION_STATUS_LIST.version],
    "final_revision": [SUBMISSION_STATUS_LIST.final_requested],
    "final": [SUBMISSION_STATUS_LIST.final],
    "archive": [SUBMISSION_STATUS_LIST.archive]
};



// ______________________________
// OPERATIONAL REPORTS 
export const REPORT_TEMPLATE_VERSIONS = {
    v01: "v01",
    v02: "v02",
    v03: "v03",
    v04: "v04",
    v05: "v05",
    v06: "v06",
    v07: "v07",
    v08: "v08",
    v09: "v09",
    v10: "v10",
    v11: "v11",
    v12: "v12",
    v13: "v13",
    v14: "v14",
    v15: "v15",
    v16: "v16",
    v17: "v17",
    v18: "v18",
    v19: "v19",
    v20: "v20",
    v21: "v21",
    v22: "v22",
    v23: "v23",
    v24: "v24",
    v25: "v25",
    v26: "v26",
    v27: "v27",
    v28: "v28",
    v29: "v29",
    v30: "v30",
    v31: "v31",
    v32: "v32",
    v33: "v33",
    v34: "v34",
    v35: "v35",
    v36: "v36",
    v37: "v37",
    v38: "v38",
    v39: "v39",
    v40: "v40",
    v41: "v41",
    v42: "v42",
    v43: "v43",
    v44: "v44",
    v45: "v45",
    v46: "v46",
    v47: "v47",
    v48: "v48",
    v49: "v49",
    v50: "v50"
};



// ______________________________
// DOCUMENTS 

export const SUBMISSION_MAX_FILE_SIZE_UPLOAD = oneGB;

export const LIBRARY_MAX_FILE_SIZE_UPLOAD = oneGB;

export const LIBRARY_BASE_FOLDER = {
    bulletins: "Bulletins",
    guidance_documents: "Guidance Documents",
    report_templates: "Report Templates"
};

export const S3_UPLOAD_STATUS = {
    uploading: "uploading",
    uploaded: "uploaded",
    error: "error",
    quarantine: "quarantine"
};

export const DOCUMENT_STATUS = {
    uploading: "Uploading",
    uploaded: "Uploaded",
    error: "Failed",
    quarantine: "Quarantine"
};

export const DOCUMENT_UPLOAD_TYPES = {
    LIBRARY: "LIBRARY", 
    SUBMISSION: "SUBMISSION", 
    FEEDBACK: "FEEDBACK",
    ARCHIVED: "ARCHIVED"
};


// ______________________________
// ISSUES 

export const ISSUE_REACTION = {
    agree: "agree",
    disagree: "disagree",
    read: "read"
}; 

export const DOCUMENT_DQ_STATUS_LIST = {
    Processing: "PROCESSING",
    Error: "ERROR",
    Issues: "ISSUES",
    Success: "SUCCESS",
    Cancel: "CANCEL"
};

export const DISPOSITION_OPTIONS = {
    SIGNIFICANT_ISSUE: "Significant Issues Identified",
    ISSUE: "Issues Identified",
    NO_ISSUE: "No Issues Identified"
}; 

export const ISSUE_STATUS_LIST = {
    active: "Active",
    reject: "Rejected",
    close: "Closed"
};



// ______________________________
// NOTIFICATION TYPES 

export const SUBMISSION = "submission";
export const LIBRARY = "library";

export const NOTIFICATION_TYPES = {
    EXTENSION_GIVEN: "EXTENSION_GIVEN",
    SUBMISSION_REVIEW_COMPLETED: "SUBMISSION_REVIEW_COMPLETED",
    SUBMISSION_NEW_VERSION_UPLOADED: "SUBMISSION_NEW_VERSION_UPLOADED",
    SUBMISSION_CREATED: "SUBMISSION_CREATED",
    FEEDBACK_SENT: "FEEDBACK_SENT",
    SUBMISSION_RESCINDED: "RESCINDED",
    SUBMISSION_FINALIZED: "FINALIZED",
    SUBMISSION_ARCHIVE: "SUBMISSION_ARCHIVE",
    SUBMISSION_REQUEST_ARCHIVE: "SUBMISSION_REQUEST_ARCHIVE",
    SUBMISSION_RESCIND_REQUEST_ARCHIVE: "SUBMISSION_RESCIND_REQUEST_ARCHIVE",
    SUBMISSION_VERSION_ARCHIVE: "SUBMISSION_VERSION_ARCHIVE",
    SUBMISSION_VERSION_REQUEST_ARCHIVE: "SUBMISSION_VERSION_REQUEST_ARCHIVE",
    SUBMISSION_VERSION_RESCIND_REQUEST_ARCHIVE: "SUBMISSION_VERSION_RESCIND_REQUEST_ARCHIVE",
    SUBMISSION_FAILURE: "SUBMISSION_FAILURE",
    SUBMISSION_DUE_DATE_EXTENSION: "SUBMISSION_DUE_DATE_EXTENSION",
    DOCUMENT_ADDED_TO_SUBMISSION: "DOCUMENT_ADDED_TO_SUBMISSION",
    ISSUE_CREATED: "ISSUE_CREATED",
    ISSUE_MODIFIED: "ISSUE_MODIFIED",
    SUBMISSION_FINAL_VERSION_UPLOADED: "SUBMISSION_FINAL_VERSION_UPLOADED",
    REACTION_CREATED: "REACTION_CREATED",
    LIBRARY_DOCUMENT_UPLOAD: "LIBRARY_DOCUMENT_UPLOAD",
    SUBMISSION_TYPE_UPDATES: "SUBMISSION_TYPE_UPDATES", 
    DOCUMENT_ADDED_TO_FINALIZED_SUBMISSION: "DOCUMENT_ADDED_TO_FINALIZED_SUBMISSION",
    NEW_SUBMISSION_TYPE_ADDED: "NEW_SUBMISSION_TYPE_ADDED" 
};



// ______________________________
// MESSAGES 

export const DUPLICATE_PRIMARY_REVIEWER_MESSAGE = 
    "Primary reviewer already assigned. If you want to change the primary reviewer, you must first remove the current primary reviewer.";

export const S3_UPLOAD_STATUS_FETCH_ERROR = "FETCH_ERROR";

export const EXPORT_WARNING = "The data you downloaded has been limited to the maximum export size of 1000 rows.";

export const FEEDBACK_DOCUMENTS_BLOCKING_SEND_FEEDBACK_MESSAGE = "The upload status of one or more feedback documents is pending or failed. Please re-upload or delete the pending or failed file(s) before sending feedback."; 
export const FEEDBACK_DOCUMENT_BLOCKING_SEND_FEEDBACK_MESSAGE = "The upload status of feedback document is pending or failed. Please re-upload or delete the pending or failed file before sending feedback.";

export const feDbConstants = {
    ISSUE_STATUS_LIST: { enum_name: "Issue_Status_List", enum_code: "1008" },
    REPORT_TEMPLATE_VERSIONS: { enum_name: "Report_Template_Versions", enum_code: "1009" },
    DAYS_OPTIONS_TO_RESUBMIT: { enum_name: "Days_Options_To_Resubmit", enum_code: "1010" },
    DAYS_OPTIONS: { enum_name: "Days_Options", enum_code: "1011"},
    TRUE_FALSE_OPTIONS: { enum_name: "True_False_Options", enum_code: "1012" }
};

export const CHANGE_LOG_DATE_KEYS = [
    "Start Date",
    "First Period of the Year"
];