import React, { useEffect, useState } from "react";
import { DOCUMENT_UPLOAD_TYPES } from "../../../../constants/index.js";
import { DownloadButton } from "../../../common/DownloadButton/index.js";

export const DownloadFeedbackDocument = ({ 
    feedbackDocument,
    actionHappening,
    setActionHappening 
}) => { 
    const [isMounted, setIsMounted] = useState(true);

    // on component unmount, set a flag for use later :: 
    useEffect(() => () => setIsMounted(false), []);

    const id = feedbackDocument?.id;

    return id && typeof id === "string" 
        ? <DownloadButton 
            size="small" 
            loading={actionHappening}
            document={feedbackDocument}
            type={DOCUMENT_UPLOAD_TYPES.FEEDBACK}
            beforeDownload={() => !actionHappening ? setActionHappening(true) : null}
            afterDownload={() => isMounted ? setActionHappening(false) : null}
        />
        : null;
};