import { useState, useEffect } from "react";

// boolean that automatically resets 

export const useAutoFlag = (initialValue) => {
    const [flag, setFlag] = useState(Boolean(initialValue));
    useEffect(() => {
        if (flag !== initialValue) {
            setFlag(initialValue);
        }
    }, [flag]);
    return [flag, setFlag];
};