import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Pagination } from "antd";
import { useParams } from "react-router";
import { useQuery } from "@apollo/client";
import { Table } from "../common/Table/index.js";
import { PCDULink } from "../common/PCDULink/index.js";
import { LoadingContent } from "../common/LoadingContent/index.js";
import { ClearFiltersButton } from "../common/ClearFiltersButton/index.js";
import { ROLE_DISPLAY_CONVERSION, CHANGE_LOG_DATE_KEYS } from "../../constants/index.js";
import { dateable, filterable, formatTimeValue, safeJSONParse, searchable, formatDate } from "../../utils/index.js";
import { handleGraphQLError } from "../../utils/errorHandling.js";
import { handleControlledDefaultSortOrder } from "../../utils/handleControlledDefaultSortOrder.js";
import { getHistoryBreadcrumbs } from "../../utils/getHistoryBreadcrumbs.js";
import { ChangelogsExport } from "./export.js";
import { UserContext } from "../Application/UserContext.js";

const dataToMarkupList = (data, reactKey) => {
    if (!data) {
        return null;
    } else {
        return Object.keys(data).map(key => <li key={`change-${reactKey}-${key}`}><strong>{key}</strong>: {CHANGE_LOG_DATE_KEYS.includes(key) ? formatDate(data[key]) : (data[key])}</li>);
    }
};

const ExpandedTableRender = (dataAsJSON, changelogId) => {
    const parsedData = safeJSONParse(dataAsJSON);
    const { updated, previous, type } = parsedData;

    const previousMarkup = dataToMarkupList(previous, `${changelogId}-previous`);
    const updatedMarkup = dataToMarkupList(updated, `${changelogId}-updated`);

    if (!previousMarkup && !updatedMarkup) {
        return null; 
    } else {
        return (
            <div>
                <div style={{display: "flex", justifyContent: "space-between", maxWidth: "800px", margin: "20px"}}> 
                    { previousMarkup && 
                        <div style={{ marginRight: "100px", maxWidth: "300px"}}>
                            <h4> {previousMarkup && updatedMarkup ? "Previous" : "Removed"} {type} </h4>
                            { previousMarkup }
                        </div>
                    }
                    { updatedMarkup &&  
                        <div style={{ maxWidth: "300px" }}> 
                            { <h4> {previousMarkup && updatedMarkup ? "Updated" : "Created"} {type} </h4>}
                            { updatedMarkup }
                        </div> 
                    }
                </div>
            </div>
        );
    }
};


export const ChangelogsTable = (({ 
    query, 
    subjectBreadcrumb, 
    filterOptions, 
    subjectColumn, 
    searchableSubject = true,
    searchableUser = true, 
    suppressDrilldown = false, 
    suppressDrilldownActions = [],
    subjectHeader,
    subjectTransform,
    exportFilename,
    subjectData, 
    setSubjectData, 
    passedVariables
}) => {
    const params = useParams();
    const location = useLocation();
    const lastHistoryBreadcrumb = getHistoryBreadcrumbs(-1);
    const passedVariablesToUse = (
        passedVariables && 
        typeof passedVariables === "object" && 
        !Array.isArray(passedVariables)
    ) ? passedVariables : {};

    const {
        getUserPermForUserActivity
    } = useContext(UserContext);

    const onActingUserPage = location.pathname.includes("acting-user");
    const defaultSortOn = "createdAt";
    const defaultSortBy = "descend";

    const [sortOn, setSortOn] = useState(defaultSortOn);
    const [sortBy, setSortBy] = useState(defaultSortBy);
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [actionFilter, setActionFilter] = useState([]);
    const [createdAtDateFilter, setCreatedAtDateFilter] = useState([]);
    const [roleFilter, setRoleFilter] = useState([]);    
    const [usernameSearch, setUsernameSearch] = useState("");
    const [ncidSearch, setNcidSearch] = useState("");
    const [subjectSearch, setSubjectSearch] = useState("");
    const [dataToUse, setDataToUse] = useState(null);

    const offset = (page - 1) * pageSize;    
    const subjectId = onActingUserPage ? null : params.id;
    const changelogs = dataToUse?.changelogs ?? [];
    const total = dataToUse?.changelogs?.[0]?.count ?? 0; 

    const variables = {
        limit: pageSize, 
        subjectId,
        offset, 
        sortOn, 
        sortBy, 
        actionFilter, 
        dateFilter: createdAtDateFilter, 
        roleFilter, 
        usernameSearch,
        ncidSearch,
        subjectSearch, 
        ...passedVariablesToUse 
    };

    const { data, loading, error } = useQuery(query, {
        fetchPolicy: "no-cache",
        variables
    });

    useEffect(() => {
        if (onActingUserPage && !subjectData && data?.user) {
            setSubjectData(data.user);
        }
    }, [loading]); 

    useEffect(() => {
        if (data) {
            setDataToUse(data);
        }
    }, [data]);

    useEffect(() => {
        if (!onActingUserPage) {
            if (!subjectData && subjectId && changelogs[0]?.subject) {
                setSubjectData(changelogs[0].subject);
            }
        }
    });

    useEffect(() => {
        setPage(1); 
    }, [location]);

    if (error) {
        return handleGraphQLError(error);
    } 

    if (!dataToUse) {
        return <LoadingContent />;
    }
    
    const newBreadcrumbs = [
        lastHistoryBreadcrumb?.label?.includes("Changelogs")
            ? null 
            : { label: "Changelogs", path: "/changelogs" },
        onActingUserPage 
            ? { 
                label: data?.user?.name, 
                path: `/changelogs/acting-user/${data?.user?.id}`, 
                tooltip: "Changelogs By User"
            }
            : subjectBreadcrumb
    ];

    const { actions = [], roles = [] } = filterOptions;

    const clearAllFilters = () => {
        setPage(1);
        setPageSize(10);
        setSortOn(defaultSortOn);
        setSortBy(defaultSortBy);
        setUsernameSearch("");
        setNcidSearch("");
        setSubjectSearch("");
        setActionFilter([]);
        setCreatedAtDateFilter([]);
        setRoleFilter([]);
    };

    const safeFilterable = (domain, base, extension) => {
        if (Array.isArray(domain) && domain.length > 0) {
            return filterable(Object.assign({}, base, extension));
        } else {
            return base;
        }
    };

    const userRender = (text, { user }) => {
        return (
            <>
                <PCDULink 
                    to={`/users/${user.id}`}
                    newBreadcrumbs={newBreadcrumbs}
                >
                    {text}
                </PCDULink>
                { variables.userId !== user.id && getUserPermForUserActivity() &&
                    <div>
                        <span>
                            <PCDULink 
                                to={`/changelogs/acting-user/${user.id}`}
                                newBreadcrumbs={newBreadcrumbs}
                            > 
                                User Activity
                            </PCDULink>
                        </span>
                    </div> 
                }
            </>
        );
    };

    const isExpandable = (changelog) => {
        if (suppressDrilldown === true) {
            return false;
        } else if (Array.isArray(suppressDrilldownActions) && suppressDrilldownActions.includes(changelog.action)) {
            return false;
        } else {
            return Boolean(changelog?.data);
        }
    };

    const columns = [
        searchableUser ? 
            searchable({
                title: "User",
                key: "username",
                dataIndex: ["user", "name"],
                sorter: true,
                sortDirections: ["ascend", "descend", "ascend"], 
                handleSearch: setUsernameSearch,
                setPage: setPage,
                handleReset: () => setUsernameSearch(""),
                searchedText: usernameSearch, 
                render: userRender
            })
            : {
                title: "User",
                key: "username",
                dataIndex: ["user", "name"],
                sorter: true,
                sortDirections: ["ascend", "descend", "ascend"],
                render: userRender
            }
        ,
        searchableUser ? 
            searchable({ 
                title: "NCID",
                key: "ncid",
                dataIndex: ["user", "ncid"],
                sorter: true,
                sortDirections: ["ascend", "descend", "ascend"],
                handleSearch: setNcidSearch,
                setPage: setPage,
                handleReset: () => setNcidSearch(""),
                searchedText: ncidSearch
            })
            : {
                title: "NCID",
                key: "ncid",
                dataIndex: ["user", "ncid"],
                sorter: true,
                sortDirections: ["ascend", "descend", "ascend"]
            }
        , 
        safeFilterable(roles, {
            title: "Role",
            key: "roleSpecifier",
            dataIndex: "roleSpecifier",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            render: (specifier) => ROLE_DISPLAY_CONVERSION[specifier]
        }, {
            filter: roleFilter,
            setFilter: setRoleFilter, 
            setPage: setPage,
            domain: roles.map(({ specifier, id }) => {
                return {
                    label: ROLE_DISPLAY_CONVERSION[specifier],
                    value: id, 
                    id: id
                };
            }) 
        }),
        dateable({
            title: "Date",
            key: "createdAt",
            dataIndex: "createdAt",
            sorter: true,
            sortDirections: ["descend", "ascend", "descend"],
            render: (tsString) => formatTimeValue(tsString, true),
            filter: createdAtDateFilter,
            setFilter: setCreatedAtDateFilter,
            setPage: setPage
        }),
        safeFilterable(actions, {
            title: "Action",
            key: "action",
            dataIndex: "action",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"]
        }, {
            filter: actionFilter,
            setFilter: setActionFilter,
            setPage: setPage,
            domain: actions.map((action) => {
                return {
                    label: action,
                    value: action
                };
            })
        }),
        {
            title: "Action Details",
            key: "description",
            dataIndex: "description",
            sorter: false 
        }
    ];

    const defaultSubjectColumn = {
        title: "Subject",
        dataIndex: "subject",
        key: "subject",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"]
    };

    const searchableSubjectColumnAdditions = {
        handleSearch: setSubjectSearch,
        setPage: setPage,
        handleReset: () => setSubjectSearch(""),
        searchedText: subjectSearch
    };

    if (subjectColumn && !Array.isArray(subjectColumn) && typeof subjectColumn === "object") {
        const newColumn = searchableSubject 
            ? searchable(Object.assign({}, defaultSubjectColumn, subjectColumn, searchableSubjectColumnAdditions))
            : Object.assign({}, defaultSubjectColumn, subjectColumn);
        columns.unshift(newColumn); // adding subject column as first column in all changelogs table
    }

    const exportButton = <ChangelogsExport 
        query={query}
        variables={variables} 
        filename={exportFilename}
        subjectHeader={subjectHeader}
        subjectTransform={
            typeof subjectTransform === "function" 
                ? subjectTransform 
                : (changelog) => {
                    const key = subjectHeader?.key && subjectHeader.key === "string" ? subjectHeader.key : null;
                    return key ? changelog?.subject?.[key] : changelog?.subject?.specifier; 
                }
        } 
        suppressDrilldown={suppressDrilldown}
        suppressDrilldownActions={suppressDrilldownActions}
    />;

    return (
        <div style={{ marginTop: "20px" }}>
            <Table 
                exportButton={exportButton}
                clearFiltersButton={<ClearFiltersButton 
                    clearFilters={clearAllFilters} 
                    filtersData={[
                        { currentValue: actionFilter, defaultValue: [] },
                        { currentValue: createdAtDateFilter, defaultValue: [] },
                        { currentValue: roleFilter, defaultValue: [] },
                        { currentValue: usernameSearch, defaultValue: "" },
                        { currentValue: ncidSearch, defaultValue: "" },
                        { currentValue: subjectSearch, defaultValue: "" } 
                    ]}
                />}
                rowKey={"id"}
                pagination={false}
                loading={loading}
                dataSource={changelogs}
                onChange={(_, __, { columnKey, order }) => {
                    setSortOn(columnKey);
                    setSortBy(order);
                }}
                expandable={{
                    rowExpandable: (changelog) => isExpandable(changelog),
                    expandedRowRender: ({ id, data: dataAsJSON }) => ExpandedTableRender(dataAsJSON, id)
                }}
                columns={handleControlledDefaultSortOrder({ 
                    sortOn, 
                    sortBy, 
                    customHandler: ({ key }) => key === sortOn 
                }, columns)}
            />
            <Pagination
                style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "20px 0 50px 0"
                }}
                current={page}
                pageSize={pageSize}
                showSizeChanger
                onChange={(targetPage, pageSize) => {
                    setPage(targetPage ? targetPage : 1);
                    setPageSize(pageSize);
                }}
                pageSizeOptions={["10", "25", "50", "100"]}
                total={total}
            />        
        </div>
    ); 
});
