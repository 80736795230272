import { gql } from "@apollo/client";

export const exportTableRequestAllLogsQuery = gql`
    query ExportTableRequestAllLogsQuery {
        exportTableRequestAllLogs {
            id
            type
            createdAt
            canExport
            dateOfLastSuccessfulExport 
        }
    }
`;

export const auditReportQuery = gql`
    query AuditReportQuery (
        $type: String!
        $categoryFilter: [ID]
        $organizationFilter: [ID]
        $createdAtFilter: [String]
        $dueDateFilter: [String]
        $submissionTypeFilter: [ID]
        $businessUnitFilter: [ID]
        $primaryReviewerFilter: [ID]
        $submissionFilter: [ID]
        $includeArchivedCriteriaFilter: Boolean
    ) {
        auditReport (
            type: $type
            categoryFilter: $categoryFilter
            organizationFilter: $organizationFilter
            createdAtFilter: $createdAtFilter
            dueDateFilter: $dueDateFilter
            submissionTypeFilter: $submissionTypeFilter
            businessUnitFilter: $businessUnitFilter
            primaryReviewerFilter: $primaryReviewerFilter
            submissionFilter: $submissionFilter
            includeArchivedCriteriaFilter: $includeArchivedCriteriaFilter
        ) {
            status 
            success 
            type
            exportId 
        }
    }
`;

export const lastExportedQuery = gql`
    query LastExportedQuery (
        $exportId: ID
    ) {
        exportTableRequestLogs (
            exportId: $exportId 
        ) {
            id
            type
            createdAt
            canExport
            dateOfLastSuccessfulExport 
        }
    }
`;