import React from "react";

export const ExportButtonBar = ({
    clearFiltersButton,
    exportButton,
    exportCancelButton,
    rightAligned,
    style = {}
}) => <div className="table-button-bar">
    <div style={{...style}}>
        { clearFiltersButton }
        <span style={{ marginLeft: exportButton && clearFiltersButton ? "10px" : "0px" }}>{exportButton}</span>
        {exportCancelButton &&
            <span style={{ marginLeft: exportButton && clearFiltersButton ? "10px" : "0px" }}>{exportCancelButton}</span>
        }
    </div>
    { rightAligned ? rightAligned : null }
</div>;