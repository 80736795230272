import React from "react";
import {
    ApolloClient,
    ApolloLink,
    ApolloProvider,
    InMemoryCache
} from "@apollo/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import { unregister } from "./serviceWorker/index.js";
import { PageRouting } from "./PageRouting.js";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { onError } from "@apollo/client/link/error";
import { PersistentState } from "./utils/PersistentState.js";
import { createRoot } from "react-dom/client";

const { resetAllPersistentState } = PersistentState();

// Apollo Cache 
const cache = new InMemoryCache({
    typePolicies: {
        ConstantResponse: { 
            // for Constants, we always want to overwrite :: 
            merge(_existing, incoming) {
                return incoming;
            }
        }
    }
});

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors){
        graphQLErrors.forEach(({ message, locations, path }) =>
            console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
        );
        return forward(operation);
    }
    
    if (networkError) {
        if (networkError.statusCode === 401) {
            const cacheData = operation.getContext()?.cache?.data?.data;
            const cacheContainsData = cacheData && Object.keys(cacheData).length > 0;

            if (cacheContainsData) {
                resetAllPersistentState();
                window.location.href = "/entry/inactive";
            } else {
                window.location.href = "/entry";
            }
        } else {
            console.log(`[Network error]: ${networkError}`);
        }
    }
});

const uploadLink = createUploadLink({
    uri: "/graphql",
    credentials: "same-origin",
    headers: {
        "Apollo-Require-Preflight": true
    }
});

// Apollo Client
const client = new ApolloClient({
    link: ApolloLink.from([
        errorLink,
        uploadLink
    ]),
    cache: cache
});

const container = document.getElementById("root");
const root = createRoot(container);
// Render
root.render(
    <ApolloProvider client={client}>
        <BrowserRouter>
            <PageRouting/>
        </BrowserRouter>
    </ApolloProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister();
