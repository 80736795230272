import React from "react";
import { Progress } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
    faFileDownload, 
    faFile 
} from "@fortawesome/free-solid-svg-icons";
import { CloseCircleOutlined, DownloadOutlined, WarningOutlined } from "@ant-design/icons";
import { isUndefinedOrNull } from "../../utils/functions.js";

const stopMenuCloseOnClick = (evt) => evt.stopPropagation();

const CurrentDownloadContent = ({ name, progress }) => {
    return <div>
        { !isUndefinedOrNull(progress) && progress > 0 
            ? <Progress 
                style={{ marginRight: "6px" }}
                showInfo={false} 
                type="circle" 
                strokeColor={"white"}
                trailColor={"rgba(21, 51, 86)"}
                width={20} 
                percent={progress} 
            /> 
            : null 
        }
        <FontAwesomeIcon icon={faFile}/> 
        <span style={{ marginLeft: "6px" }}>{ name }</span> 
    </div>;
};


export const DownloadsDropdownLabel = ({ currentDownloads = [], failedDownloads = [] }) => {
    const downloadsCount = currentDownloads.length;
    const failedDownloadsCount = failedDownloads.length;
    const downloadIconColor = failedDownloadsCount > 0 ? "#ff4d4f" : (downloadsCount > 0 ? "#1890ff" : "");
    return (
        <div>
            { downloadsCount > 0 ? <span style={{marginRight: "5px"}}>{downloadsCount}</span> : "" } 
            <FontAwesomeIcon icon={faFileDownload} size={"lg"} color={downloadIconColor} />
        </div>
    );
};

export const makeDownloadsDropdownChildren = ({ currentDownloads = [], failedDownloads = [], removeFailedDownload}) => {
    const downloadItems = [{
        key: "download-children-title",
        label: <div onClick={stopMenuCloseOnClick} className="inert-menu-item"> {currentDownloads.length > 0 ? <DownloadOutlined style={{marginRight: "6px"}} /> : "No "}Current Downloads </div>
    }].concat(currentDownloads.map((doc, i) => ({
        key: `download-child-${i}`,
        label: <div onClick={stopMenuCloseOnClick} className="inert-menu-item" key={`download-child-${i}`}>
            <CurrentDownloadContent name={doc?.name ?? ""} progress={doc?.progress} />
        </div>
    })));

    // only use the five most recent failed downloads to display :: 
    const failedDownloadsToUse = Array.isArray(failedDownloads) ? failedDownloads.slice(0, 5) : [];
    const failedDownloadsAdditionalMessage = failedDownloads.length > 5 
        ? "(only displaying latest 5)" 
        : "";
    const failedDownloadItems = failedDownloadsToUse.length > 0 
        ? [{
            key: "failed-download-children-title",
            label: <div onClick={stopMenuCloseOnClick}
            ><WarningOutlined /><span className="inert-menu-item" margin={{ marginLeft: "6px" }}>Failed Downloads {failedDownloadsAdditionalMessage}</span></div>
        }].concat(failedDownloadsToUse.map((doc, i, arr) => ({
            key: `failed-download-${i}`,
            label:  <div onClick={(evt) => {
                if (arr.length > 1) {
                    stopMenuCloseOnClick(evt);
                }
                removeFailedDownload(doc?.failedDownloadId);
            }} key={`failed-download-label-${i}`}>
                <><CloseCircleOutlined /> FAILED: { doc?.name }</>
            </div>
        })))
        : [];

    return [ ...failedDownloadItems, ...downloadItems ];
};