import { gql } from "@apollo/client";

export const contractsQuery = gql`
    query ContractsQuery (
        $limit: Int
        $offset: Int
        $conTypes: [ID]
        $promisorFilter: [ID]
        $startDateFilter: [String]
        $endDateFilter: [String]
        $activeFilter: Boolean
        $specifierSearch: String
        $sortOn: String
        $sortBy: String
    ) {
        contractsList(
            limit: $limit
            offset: $offset
            typesFilter: $conTypes
            promisorFilter: $promisorFilter
            startDateFilter: $startDateFilter
            endDateFilter: $endDateFilter
            activeFilter: $activeFilter
            specifierSearch: $specifierSearch
            sortOn: $sortOn
            sortBy: $sortBy
        ) {
            contracts {
                id
                specifier
                name
                startDate
                endDate
                contractType {
                    id
                    name
                }
                promisor {
                    id
                    name
                }
            }
            count
        }
    }
`;