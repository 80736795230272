import React from "react";
import { EXPORTSIZE } from "../../constants/index.js";
import { formatTimeValue } from "../../utils/functions.js";
import { CsvExport } from "../common/CsvExport/index.js";
import { documentsQuery } from "./query.js";

export const csvHeaders = [
    {
        label: "ID",
        key: "id"
    },
    {
        label: "Name",
        key: "name"
    },
    {
        label: "Sub-Submission Type (Child)",
        key: "documentType"
    },
    {
        label: "Submitter",
        key: "submitter"
    },
    {
        label: "Primary Reviewer",
        key: "primaryReviewer"
    },
    {
        label: "Submission Type",
        key: "submissionType"
    },
    { 
        label: "Contract Type", 
        key: "contractType" 
    },
    { 
        label: "Business Unit", 
        key: "businessUnit" 
    },
    {
        label: "Created At",
        key: "submissionDate"
    },
    {
        label: "Status",
        key: "status"
    }
];

export const transformDocumentsDataForCSV = (data) => {
    return data.submissionDocumentsList.submissionDocuments.map((submissionDocument) => {
        return {
            id: submissionDocument.specifier,
            name: submissionDocument.nickname,
            documentType: submissionDocument.documentType === null ? "" : submissionDocument.documentType.name,
            submitter: submissionDocument.submission.submitter.name,
            primaryReviewer: submissionDocument.primaryReviewerName,
            submissionType: (`${submissionDocument.submission.obligation.submissionType.specifier} - ${submissionDocument.submission.obligation.submissionType.name}`),
            contractType: submissionDocument.submission.obligation.submissionType.contractType.name,
            businessUnit: submissionDocument.submission.obligation.submissionType.businessUnit.name,
            submissionDate: formatTimeValue(submissionDocument.documentNewestVersion.createdAt, true),
            status: submissionDocument.status
        };
    });
};

export const DocumentsCsvExport = ({variables}) => {
    return <CsvExport
        csvFilename={"Documents_Report.csv"}
        csvHeaders={csvHeaders}
        query={documentsQuery}
        variables={{...variables, offset: 0, limit: EXPORTSIZE}}
        transformData={transformDocumentsDataForCSV}
    />;
};