import React from "react";
import { Card, Button } from "antd";


export const SettingsCard = ({
    title = "",
    subtitle = "",
    canSaveChanges,
    saveDisabled,
    saveChanges,
    cancel,
    children 
}) => { 
    return (
        <div style={{ minWidth: "400px", marginTop: "30px" }}>
            <Card
                hoverable={true}
                style={{
                    cursor: "default" 
                }}
            >
                <div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <h3>
                            { title }
                        </h3>
                        <div style={{ minHeight: "40px" }}>
                            { canSaveChanges 
                                ? <>
                                    <Button type="primary" disabled={saveDisabled} onClick={saveChanges}>Save Changes</Button>
                                    <Button type="secondary" onClick={cancel} style={{ marginLeft: "8px" }}>Cancel</Button>
                                </>
                                : null 
                            }
                        </div>
                    </div>
                    <h4 style={{ color: "#000000E0", fontWeight: 300 }}>{subtitle}</h4>
                </div>
                { title || subtitle ? <b></b> : null }
                { children }
            </Card>
        </div>
    );
};