
const BASE = "unique-react-key-";
let TIMESTAMP = Date.now().toString();
let COUNTER = 0;

export default function getUniqueKey() {
    if (COUNTER === Number.MAX_SAFE_INTEGER) {
        COUNTER = 0;
        TIMESTAMP = Date.now().toString();
    } else {
        COUNTER++;
    }
    return BASE + TIMESTAMP + COUNTER.toString();
}
