import React, { useState, useContext } from "react";
import { Button, Modal } from "antd";
import { useMutation } from "@apollo/client";
import { UserContext } from "../Application/UserContext.js";
import { EditableDetail } from "../common/EditableDetail/index.js";
import { Table } from "../common/Table/index.js";

import {
    CREATE_DOCUMENT_TYPE,
    DELETE_DOCUMENT_TYPE
} from "../../constants/mutations.js";

import { handleMutation } from "../../utils/errorHandling.js";

import {
    validDocumentTypeName
} from "./_helpers.js";
import { handleControlledDefaultSortOrder } from "../../utils/handleControlledDefaultSortOrder.js";
import { PersistentState } from "../../utils/PersistentState.js";

const { usePersistentState } = PersistentState();

const { confirm } = Modal;

export const SubSubmissionTypeTable = (({ 
    submissionType,
    refetchSubmissions
}) => {
    const [modified, setModified] = useState({});
    const [createDocumentTypeVis, setCreateDocumentTypeVis] = useState(false);
    const [createDocumentType] = useMutation(CREATE_DOCUMENT_TYPE);
    const [deleteDocumentType] = useMutation(DELETE_DOCUMENT_TYPE);

    // Persistent State :: 
    const [page, setPage] = usePersistentState(1);
    const [pageSize, setPageSize] = usePersistentState(10);
    const [sortOn, setSortOn] = usePersistentState("specifier");
    const [sortBy, setSortBy] = usePersistentState("ascend");
        
    const {
        userPermEditSubmissionType
    } = useContext(UserContext);

    const showConfirmDocumentType = (id, name) => {
        confirm({
            title:
                `Are you sure you want to remove the Sub-Submission Type (Child) ${name} from this submission type?`,
            okText: "Yes, remove",
            async onOk() {
                await handleMutation(
                    deleteDocumentType({
                        variables: {
                            documentTypeId: id
                        }
                    })
                );
                refetchSubmissions();
            }
        });
    };

    const documentTypesColumns = [
        {
            title: "ID",
            dataIndex: "specifier",
            sorter: (a, b) => a.specifier - b.specifier,
            sortDirections: ["ascend", "descend", "ascend"]
        },
        {
            title: "Name",
            dataIndex: "name",
            width: "50%",
            sorter: (a, b) => (a.name ? a.name.trim().toLowerCase() : "") > (b.name ? b.name.trim().toLowerCase() : "") ? 1 : -1,
            sortDirections: ["ascend", "descend", "ascend"]
        }
    ];

    if (userPermEditSubmissionType) {
        const actionColumn = {
            title: "Actions",
            key: "actions",
            width: "10%",
            render: ({ id, name }) => {
                return (
                    <Button
                        type="danger"
                        size="small"
                        onClick={() => {
                            showConfirmDocumentType(id, name);
                        }}
                    >
                        Remove
                    </Button>
                );
            }
        };
        documentTypesColumns.splice(2, 0, actionColumn);
    }

    const documentTypesTable = (
        <>
            <Table
                id="documentTypesTable"
                columns={handleControlledDefaultSortOrder({ sortOn, sortBy }, documentTypesColumns)}
                rowKey="id"
                dataSource={submissionType.documentTypes}
                pagination={{
                    defaultCurrent: page,
                    defaultPageSize: pageSize 
                }}
                onChange={({ current, pageSize }, _filters, { field, order }) => {
                    setPage(current);
                    setPageSize(pageSize);
                    setSortOn(field);
                    setSortBy(order);
                }}
            />
        </>
    );

    const addSubSubmissionModal = (
        <Modal
            title={"New Sub-Submission Type (Child) Association"}
            open={createDocumentTypeVis}
            onOk={async (e) => {
                e.preventDefault();

                await handleMutation(
                    createDocumentType({
                        variables: {
                            documentTypeName: modified.documentTypeName,
                            submissionTypeId: submissionType.id
                        }
                    })
                );

                await refetchSubmissions();
                setCreateDocumentTypeVis(false);
                setModified({});
            }}
            okText="Save"
            okButtonProps={{
                disabled: !validDocumentTypeName(modified.documentTypeName)
            }}
            onCancel={() => {
                setModified({});
                setCreateDocumentTypeVis(false);
            }}
        >
            <EditableDetail
                title="Sub-Submission Type (Child)"
                key="documentTypeName"
                placeholder="Add Sub-Submission Type (Child)..."
                value={modified.documentTypeName}
                onValueUpdated={(e) => {
                    setModified({
                        ...modified,
                        documentTypeName: e.target.value
                    });
                }}
            />
            <p>{"Sub-Submission Type (Child) cannot have plus signs (+) in their name."}</p>
        </Modal>
    );

    return (
        <div>
            <h2>Sub-Submission Type (Child)</h2>
            <p>Types of documents that are allowed to be used to identify documents uploaded to a submission of this submission type.</p>
            <Button
                style={{ display: userPermEditSubmissionType ? "inherit" : "none" }}
                type="primary"
                id="createDocumentTypeObligation"
                className="spaceBelow-xs btn btn-sm btn-primary"
                onClick={() => setCreateDocumentTypeVis(true)}
            >
                Add
            </Button>
            {documentTypesTable}
            {addSubSubmissionModal}

        </div>
    );
});