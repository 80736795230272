import React from "react";
import { Divider } from "antd";


export const ButtonPanel = ({ children }) => {
    const buttonPanelHeight = children.filter(Boolean).length * 30;
    if (buttonPanelHeight === 0) {
        return null;
    } else {
        return <>
            <div style={{ 
                marginBottom: "10px", 
                marginTop: "-5px", 
                height: buttonPanelHeight,
                display: "flex",
                width: "120px",
                flexDirection: "column",
                justifyContent: "space-between"
            }}>
                { children }
            </div>
            <Divider />
        </>;
    }
};