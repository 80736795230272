import React from "react";
import { Button, Modal } from "antd";
import { ExportOutlined, StopOutlined } from "@ant-design/icons";
import { ClearFiltersButton } from "../common/ClearFiltersButton/index.js";
import { ExportButtonBar } from "../common/ExportButtonBar/index.js";

const { confirm } = Modal;

const ExportTablesButtons = ({
    exportLoading,
    filtersData,
    resetFilters,
    buttonDisabled = false,
    cancelExportRequest,
    type
}) => (
    <ExportButtonBar
        style={{ paddingLeft: "10px" }}
        clearFiltersButton={<ClearFiltersButton
            clearFilters={resetFilters}
            filtersData={filtersData}
        />}
        exportButton={<Button 
            loading={exportLoading}
            type="primary" 
            htmlType="submit"
            icon={<ExportOutlined />}
            disabled={buttonDisabled}
        > 
            Export
        </Button>}
        exportCancelButton={<Button 
            danger
            icon={<StopOutlined />}
            disabled={!buttonDisabled}
            onClick={() => {
                confirm({
                    title: type.includes("Export") ? `Do you Want to Cancel ${type}?` : `Do you Want to Cancel ${type} Export?`,
                    okText: "Yes",
                    cancelText: "No",
                    onOk() {
                        if (cancelExportRequest) cancelExportRequest();
                    },
                    onCancel() {
                        // No Action
                    }
                });
                
            }}
        > 
            Cancel Export
        </Button>}
    />
);

export default ExportTablesButtons;