import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { Button, Modal } from "antd";
import { CREATE_LIBRARY_FOLDER } from "../../../../constants/mutations.js";
import { handleMutation } from "../../../../utils/errorHandling.js";
import { EditableDetail } from "../../../common/EditableDetail/index.js";
import { SelectableDetail } from "../../../common/SelectableDetail/index.js";


export const CreateLibraryFolder = ({ 
    restrictToCategories, 
    categories, 
    disabled, 
    afterFolderCreation, 
    contractTypeId, 
    baseFolderName,
    baseFolderId, 
    currentFolderId  
}) => {
    const [libraryFolderName, setLibraryFolderName] = useState("");
    const [uploadFolderModal, setUploadFolderModal] = useState(false);
    const [selectedCategoryId, setSelectedCategoryId] = useState("");
    const [createLibraryFolder] = useMutation(CREATE_LIBRARY_FOLDER);

    const resetState = () => {
        setLibraryFolderName("");
        setUploadFolderModal(false);
        setSelectedCategoryId("");
    };

    const categoryOptions = categories.map(({ name, id }) => {
        return {
            text: name,
            value: id,
            id
        };
    });

    const validInput = libraryFolderName && typeof libraryFolderName === "string";
    const disableFolderCreation = disabled || (restrictToCategories && categoryOptions.length < 1);

    return (
        <>
            <Modal
                title="Create Folder"
                destroyOnClose={true}
                open={uploadFolderModal}
                okText="Save"
                onCancel={() => {
                    resetState();
                }}
                okButtonProps={{
                    disabled: !validInput
                }}
                onOk={async (e) => {
                    try {
                        e.preventDefault();
                        await handleMutation(
                            createLibraryFolder({
                                variables: {
                                    libraryFolderInput: {
                                        folderName: libraryFolderName,
                                        contractTypeId,
                                        baseFolderName,
                                        baseFolderId,
                                        libraryFolderId: currentFolderId
                                    }
                                }
                            })
                        );
                        if (typeof afterFolderCreation === "function") {
                            afterFolderCreation();
                        }
                    } finally {
                        resetState();                        
                    }
                }}
            >
                { restrictToCategories
                    ? <SelectableDetail
                        title="Select Name from Available Categories"
                        passedKey="select-folder-name-ct-category"
                        strict={true}
                        value={selectedCategoryId}
                        onValueUpdated={(id) => {
                            setSelectedCategoryId(id);
                            const catName = categories.find(category => category.id === id)?.name ?? "";
                            if (catName) {
                                setLibraryFolderName(catName);
                            }                                                  
                        }}
                        options={categoryOptions}
                    />
                    : <EditableDetail
                        title="Folder Name"
                        key="libraryFolderName"
                        placeholder="Add new folder name ..."
                        value={libraryFolderName}
                        onValueUpdated={(e) => {
                            setLibraryFolderName(e.target.value);
                        }}
                    />
                }
            </Modal>
            <Button
                type="primary"
                size="small"
                onClick={() => {
                    setUploadFolderModal(true);
                    setSelectedCategoryId("");
                }}
                disabled={disableFolderCreation}
            >
                Create Folder
            </Button>
        </>
    );
};