import React from "react";
import { Layout, Button, Modal } from "antd";
import { PCDUBreadcrumbs } from "../PCDUBreadcrumbs/index.js";

const { confirm } = Modal;

export const Main = ({
    children,
    title,
    submissionTitle,
    details = "",
    breadcrumbs = [],
    showArchive,
    archived,
    okText = "",
    showActivate,
    activated,
    confirmAction,
    confirmTitle,
    showEdit,
    editCallback
}) => {

    const showConfirm = () => {
        confirm({
            title: confirmTitle,
            okText: okText ? okText : (archived ? "Yes, De-Archive" : "Yes, Archive"),
            onOk() {
                confirmAction();
            }
        });
    };

    return (
        <Layout.Content id="main" style={{ padding: "0", minWidth: 700 }}>
            <nav
                style={{
                    backgroundColor: "#ddd",
                    color: "#666",
                    padding: "10px 24px 8px 24px"
                }}
            >
                <PCDUBreadcrumbs
                    breadcrumbs={breadcrumbs}
                />
            </nav>
            <header
                style={{
                    backgroundColor: "#eaeaea",
                    padding: "18px",
                    overflow: "hidden"
                }}
            >
                <Button
                    style={{ display: showArchive ? "initial" : "none" }}
                    className="floatRight"
                    type="danger"
                    size="small"
                    onClick={() => {
                        showConfirm();
                    }}
                >
                    {archived ? "De-Archive" : "Archive"}
                </Button>

                <Button
                    style={{ display: showActivate ? "initial" : "none" }}
                    className="floatRight"
                    type="danger"
                    size="small"
                    onClick={() => {
                        showConfirm();
                    }}
                >
                    {activated ? "Deactivate" : "Activate"}
                </Button>

                <Button
                    style={{ display: showEdit ? "initial" : "none" , marginRight: "5px"}}
                    className="floatRight"
                    type="primary"
                    size="small"
                    onClick={() => {
                        editCallback();
                    }}
                >
                    Edit
                </Button>
                <div>
                    <h1
                        style={{
                            display: "flex",
                            alignItems: "flex-start"
                        }}
                    >
                        {title}
                    </h1>
                    <div style={{display: "none"}}>
                        {submissionTitle}
                    </div>
                </div>          
                {details}
            </header>
            <Layout.Content style={{ padding: "0 24px 24px", flex: "1 0 auto" }}>
                {children}
            </Layout.Content>
        </Layout.Content>
    );
};