import React from "react";
import { useParams } from "react-router-dom";
import { ActingUserQuery } from "./queries.js";
import { ChangelogsTable } from "./changelogs-table.js";

export const ActorChangelogs = (({
    actions,
    setSubjectData,
    subjectData
}) => {
    const params = useParams();

    const actingUserId = params.id;

    const ALL_ACTIONS = Object.keys(actions).reduce((allActions, key) => {
        const actionSet = Object.values(actions[key]);
        return allActions.concat(actionSet);
    }, []);

    const filterOptions = {
        actions: ALL_ACTIONS,
        roles: []
    };

    const libraryActionsNoDrilldown = Object.values(actions.library);
    
    return (
        <div style={{minWidth: "900px"}}>
            <ChangelogsTable 
                filterOptions={filterOptions}
                query={ActingUserQuery}
                setSubjectData={setSubjectData}
                exportFilename={"User_Activity_Changelogs.csv"}
                subjectData={subjectData}
                passedVariables={{ userId: actingUserId, requiredUserId: actingUserId }}
                searchableSubject={false}
                searchableUser={false}
                subjectColumn={null} 
                suppressDrilldownActions={libraryActionsNoDrilldown}
            />
        </div>
    );
});
