export const normalizeCategoryData = (category) => {
    const keysToExclude = ["__typename", "secondaryNotifierFlag", "secondaryReviewerFlag"];
    const reviewersOption = category.secondaryNotifierFlag === true 
        ? "secondaryNotifierFlag" 
        : category.secondaryReviewerFlag === true 
            ? "secondaryReviewerFlag" 
            : undefined;
    
    return Object.keys(category)
        .filter(objKey => !keysToExclude.includes(objKey))
        .reduce((newObj, key) => {
            newObj[key] = category[key];
            return newObj;
        }, { reviewersOption });
};