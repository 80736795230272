import React, { useContext } from "react";
import { Button } from "antd";
import { useMutation } from "@apollo/client";
import { CommentsList } from "../CommentsList/index.js";
import { Table } from "../Table/index.js";
import { UserContext } from "../../Application/UserContext.js";
import {
    MODIFY_RELATED_DOCUMENT_STATUS
} from "../../../constants/mutations.js";
import {
    ISSUE_STATUS_LIST,
    ISSUE_REACTION,
    SUBMISSION_STATUS_LIST
} from "../../../constants/index.js";
import { handleMutation } from "../../../utils/errorHandling.js";
import safeTableSorter from "../../../utils/safeTableSorter.js";
import { findApplicableCriterionVersionByTimestamp } from "../../../utils/filterCriteriaVersions.js";
import { CriteriaVersionDrilldown } from "../CriteriaVersionDrilldown/index.js";
import { FilterIcon } from "../FilterIcon/index.js";
import { FeedbackDocumentStatus } from "../FeedbackDocumentStatus/index.js";
import { ScrollableTable } from "../ScrollableTable/index.js";
import { PCDULink } from "../PCDULink/index.js";
import { PersistentState } from "../../../utils/PersistentState.js";
import { handleControlledDefaultSortOrder } from "../../../utils/handleControlledDefaultSortOrder.js";
import { useLocation } from "react-router";

const { usePersistentState } = PersistentState();


const isValidFilter = (filter) => Array.isArray(filter) && filter.length > 0;

const filterIssues = (statusFilter, raisedInFilter, issues) => {
    return issues.filter(issue => {
        let matchStatus = true,
            matchRaisedIn = true;
        
        if (isValidFilter(statusFilter)) {
            matchStatus = statusFilter.some(status => status === issue?.status);
        }
        if (isValidFilter(raisedInFilter)) {
            matchRaisedIn = raisedInFilter.some(versionSpecifier => versionSpecifier === issue?.raisedInReview?.submissionVersion?.specifier);
        }
        return matchStatus && matchRaisedIn;
    });
};


export const IssueTable = (({
    persistState,
    submission,
    issues,
    submitterIssues,
    issueSelection = [],
    setIssueSelection,
    isPackagedSubmission,
    setRefetchData,
    newBreadcrumbs,
    hideRowSelection = false,
    displayFeedbackDocsRow = true,
    displayResolvedDocCol = false,
    displayForSubmissionDetail = false,
    submissionId,
    documentId
}) => {

    const [modifyRelatedDocument] = useMutation(MODIFY_RELATED_DOCUMENT_STATUS);

    const [sortOn, setSortOn] = usePersistentState("specifier", persistState);
    const [sortBy, setSortBy] = usePersistentState("ascend", persistState);
    const [page, setPage] = usePersistentState(1, persistState);
    const [pageSize, setPageSize] = usePersistentState(10, persistState);
    const [statusFilter, setStatusFilter] = usePersistentState(null, persistState);
    const [raisedInFilter, setRaisedInFilter] = usePersistentState(null, persistState);
    
    const location = useLocation();

    const { 
        userIsSubmitter, 
        userId, 
        userIsAdmin,
        userPermRejectnCloseIssue, 
        userPermCreateComments  
    } = useContext(UserContext);

    const userCanCreateComments = userPermCreateComments(submission.obligation.submissionType.id);
    const userCanRejectnCloseIssue = userPermRejectnCloseIssue(submission.obligation.submissionType.id); 
    const documentStatusReviewed = (submission.status === SUBMISSION_STATUS_LIST.final || submission.status === SUBMISSION_STATUS_LIST.version || submission.status === SUBMISSION_STATUS_LIST.final_requested || submission.archived);
    const allCriteria = submission.obligation.submissionType.allCriteria;
    const currentUserId = userId;

    const areCommentModificationsEnabled = ({
        documentStatusReviewed, 
        userCanCreateComments,
        issueStatus
    }) => { 
        if (!userCanCreateComments) { 
            return false; 
        } else {
            // comments can be edited or created BY DHHS USERS with proper permissions, 
            // but only if the document is under review or the issue is no longer active :: 
            return !documentStatusReviewed || issueStatus !== ISSUE_STATUS_LIST.active; 
        }
    };

    const changeResolution = async (issueId, submissionDocumentId, newResolution) => {
        return await handleMutation(
            modifyRelatedDocument({
                variables: {
                    issueId,
                    submissionDocumentId,
                    resolved: newResolution
                }  
            })
        );
    };

    const RelatedDocumentsTable = ({ issue, submissionId, permitted, canResolve, mutationCallback }) => {
        const { id: issueId, relatedDocuments } = issue;
        if (!Array.isArray(relatedDocuments) || relatedDocuments.length < 1) {
            return null;
        } else {
            return (
                <div style={{ marginLeft: "10px" }}>
                    <h3> Related Documents </h3>
                    <Table 
                        id="related-documents-subtable"
                        rowKey={({ id }) => `related-docs-${issueId}-${id}`}
                        dataSource={relatedDocuments}
                        columns={[
                            {
                                title: "ID",
                                render: (document) => {
                                    return <PCDULink 
                                        to={`/submissions/${submissionId}/documents/${document.id}`}
                                        newBreadcrumbs={newBreadcrumbs}
                                    >
                                        {document.specifier}
                                    </PCDULink>;
                                }
                            },
                            {
                                title: "Name",
                                dataIndex: ["documentNewestVersion","name"]
                            },
                            {
                                title: "Resolved For Document",
                                dataIndex: "issueResolved",
                                render: (bool) => bool === true ? "Yes" : "No"
                            },
                            (permitted && typeof mutationCallback === "function" && {
                                title: "Action",
                                render: ({ id, issueResolved }) => {
                                    return (
                                        <Button
                                            size="small"
                                            disabled={!canResolve}
                                            type={issueResolved ? "danger" : "primary"}
                                            onClick={async () => {
                                                await mutationCallback(issueId, id, !issueResolved);
                                            }}
                                        > 
                                            {issueResolved ? "Reopen" : "Resolve"} 
                                        </Button>
                                    );
                                }
                            })
                        ].filter(col => col)}
                    />
                </div>
            );
        }
    };

    let issueTableColumns = [
        {
            title: "ID",
            key: "specifier",
            width: 100,
            sorter: (a, b) => a.specifier - b.specifier,
            sortDirections: ["ascend", "descend", "ascend"],
            defaultSortOrder: "ascend",
            render: ({ specifier, id }) => {
                return <PCDULink to={location.pathname.includes("documents") && !location.pathname.includes("submissions") ? `/documents/${documentId}/issues/${id}` :  `/submissions/${submission?.id || submissionId}/issues/${id}`} newBreadcrumbs={newBreadcrumbs}>{`Issue - ${specifier}`}</PCDULink>;
            }
        },
        {
            title: "Reason",
            key: "reason",
            className: "large-column",
            dataIndex: "reason",
            render: (reason) => {
                return <span className="text-white-space">{reason}</span>;
            }
        },
        {
            title: "Criteria ID",
            key: "criteria",
            width: 100,
            dataIndex: ["criteria", "specifier"],
            render: (criteriaId) => <div className="centeredText"> { criteriaId } </div>
        },
        {
            title: "Status",
            key: "status",
            sorter: safeTableSorter("status"),
            sortDirections: ["ascend", "descend", "ascend"],
            filters: Object.keys(ISSUE_STATUS_LIST).map((key) => {
                return {
                    text: ISSUE_STATUS_LIST[key],
                    value: ISSUE_STATUS_LIST[key]
                };
            }),
            filteredValue: statusFilter,
            filterIcon: FilterIcon,
            render: ({ status }) => {
                return status;
            }
        },
        {
            title: "Raised In",
            key: "versions",
            filters: submission.submissionVersions.map((submissionVersion) => {
                return {
                    text: `Version ${submissionVersion.specifier + 1}`,
                    value: parseInt(submissionVersion.specifier, 10)
                };
            }),
            filteredValue: raisedInFilter,
            filterIcon: FilterIcon,
            dataIndex: "raisedInReview",
            render: ({ submissionVersion }) => `Version ${submissionVersion.specifier + 1}`
        }
    ];

    const resolvedDocColumn = displayResolvedDocCol ? [
        {
            title: "Resolved for this Document",
            key: "resolved-for-document",
            dataIndex: "resolvedForDocument",
            render: (resolved) => { 
                const text = resolved === true ? "Yes" : "No"; 
                return <div className="centeredText">{ text }</div>;
            }
        }
    ] : null;

    const reviewersColumn = userIsSubmitter ? null : [
        {
            title: "Raised By",
            dataIndex: "raisedInReview",
            key: "raisedBy",
            sorter: safeTableSorter("raisedInReview.reviewer.name"),
            sortDirections: ["ascend", "descend", "ascend"],
            render: (raisedInReview) => raisedInReview?.reviewer?.name
        },
        {
            title: "Agreement",
            key: "agreement",
            dataIndex: "reactions",
            render: (reactions) => {
                let agreements = 0;
                if (Array.isArray(reactions)) {
                    agreements = reactions.filter(reaction => typeof reaction === "object" && reaction.disposition === ISSUE_REACTION.agree).length;
                }
                return <div className="centeredText">{ agreements }</div>;
            }
        },
        {
            title: "Disagreement",
            dataIndex: "reactions",
            key: "disagreement",
            render: (reactions) => {
                let disagreements = 0;
                if (Array.isArray(reactions)) {
                    disagreements = reactions.filter(reaction => typeof reaction === "object" && reaction.disposition === ISSUE_REACTION.disagree).length;
                }
                return <div className="centeredText">{ disagreements }</div>;
            }
        }
    ];

    const feedBackColumns = displayFeedbackDocsRow ?
        [
            {
                title: "Feedback Document",
                key: "activeFeedbackDocument",
                dataIndex: "activeFeedbackDocument",
                render: (activeFeedbackDocument) => {
                    if (activeFeedbackDocument) {
                        return (
                            <FeedbackDocumentStatus
                                feedbackDocument={activeFeedbackDocument}
                                status={activeFeedbackDocument?.S3UploadStatus}
                                statusUpdateCallback={setRefetchData}
                            />
                        );
                    } else {
                        return null;
                    }
                }
            },
            {
                title: "Inactive Docs",
                key: "inactiveFeedbackDocumentsCount",
                dataIndex: "inactiveFeedbackDocumentsCount",
                render: (inactiveDocsCount) => <div className="centeredText">{ inactiveDocsCount }</div>
            }
        ] : null;

    if (resolvedDocColumn) {
        issueTableColumns = issueTableColumns.concat(resolvedDocColumn);
    }
    
    if (reviewersColumn) {
        issueTableColumns = issueTableColumns.concat(reviewersColumn);
    }

    if (feedBackColumns) {
        if (displayForSubmissionDetail){
            issueTableColumns.splice(2,0,feedBackColumns[0]);
            issueTableColumns.push(feedBackColumns[1]);
        } else {
            issueTableColumns = issueTableColumns.concat(feedBackColumns);
        }
    }

    const issuesToDisplay = userIsSubmitter 
        ? filterIssues(statusFilter, raisedInFilter, submitterIssues) 
        : filterIssues(statusFilter, raisedInFilter, issues);

    return (
        <>
            <ScrollableTable
                id="issuesTable"
                rowKey="id"
                scroll={{ x: 1500 }}
                noBorders={true} 
                pagination={{
                    defaultCurrent: page,
                    defaultPageSize: pageSize
                }}
                onChange={(
                    { current, pageSize }, 
                    { status, versions }, 
                    { columnKey, order }
                ) => {
                    setStatusFilter(status);
                    setRaisedInFilter(versions);
                    setPage(current);
                    setPageSize(pageSize);
                    setSortOn(columnKey);
                    setSortBy(order);
                }}
                columns={handleControlledDefaultSortOrder({
                    sortOn, 
                    sortBy,
                    customHandler: ({ key }) => key === sortOn
                }, issueTableColumns)}
                dataSource={issuesToDisplay}
                rowSelection={userIsSubmitter || documentStatusReviewed || hideRowSelection ? undefined : {
                    selectedRowKeys: issueSelection,
                    onChange: (selectedRowKeys) => {
                        setIssueSelection(selectedRowKeys);
                    },
                    getCheckboxProps: (record) => {
                        return {
                            disabled: record.status === ISSUE_STATUS_LIST.reject || record.status === ISSUE_STATUS_LIST.close
                        };
                    }
                }}
                expandedRowRender={(issue) => {
                    const commentModificationsEnabled = areCommentModificationsEnabled({
                        documentStatusReviewed,
                        userCanCreateComments,
                        issueStatus: issue.status
                    });

                    let criteriaToDisplay;
                    if (issue?.criteria?.specifier !== undefined) {
                        const criteriaSpecifier = parseInt(issue.criteria.specifier);
                        const criteriaFilterDate = parseInt(issue.raisedInReview.submissionVersion.createdAt);
                        const criterion = allCriteria.find(({ specifier }) => parseInt(specifier) === criteriaSpecifier);
                        criteriaToDisplay = findApplicableCriterionVersionByTimestamp(criterion, criteriaFilterDate);
                    }
                    
                    return (
                        <div>
                            {criteriaToDisplay &&
                                CriteriaVersionDrilldown({
                                    criterion: criteriaToDisplay,
                                    title: `Criteria ${issue.criteria.specifier}`,
                                    hideArchiveStatus: true,
                                    hideCreatedAt: true
                                })
                            }
                            {isPackagedSubmission && <RelatedDocumentsTable
                                canResolve={!documentStatusReviewed}
                                mutationCallback={async (issueId, documentId, newResolution) => {
                                    await changeResolution(issueId, documentId, newResolution);
                                    setRefetchData(true);
                                }}
                                permitted={(userCanRejectnCloseIssue || userIsAdmin) && issue.status === ISSUE_STATUS_LIST.active}
                                issue={issue}
                                submissionId={submission.id}
                            />}
                            <CommentsList
                                parent={issue}
                                userId={currentUserId}
                                refetch={() => setRefetchData(true)}
                                isUserSubmitter={userIsSubmitter}
                                disableEditing={!commentModificationsEnabled}
                                disableCreating={!commentModificationsEnabled}
                                latestSubmissionVersionId={submission?.submissionNewestVersion?.id}
                                submissionVersions={submission?.submissionVersions}
                            />
                        </div>
                    );
                }}
                rowExpandable={(issue) => (Array.isArray(issue?.comments) && issue.comments.length > 0) || areCommentModificationsEnabled({
                    documentStatusReviewed,
                    userCanCreateComments,
                    issueStatus: issue.status
                })}
            />
        </>
    );
});


export const IssuesTableHeader = () => {
    return (
        <>
            <h2>Issues</h2>
            <p>Reviewers may raise issues with a submission during their review. If any issues are raised, they are displayed below. Submitters should ensure these issues are resolved before uploading a new version of a submission.</p>
        </>
    );
};