import { gql } from "@apollo/client";

export const feedbackDocumentsQuery = gql`
    query FeedbackDocumentsQuery ($id: ID!) {
        issue (id: $id) {
            id 
            feedbackDocuments {
                id 
                specifier 
                name
                S3UploadStatus
                fileSize 
                active
                authorId
                issueId 
                createdAt 
                modifiedAt 
                isDeletable
            } 
        }
    }
`;
