import { useMutation } from "@apollo/client";
import {
    CREATE_CONTRACT,
    CREATE_CONTRACT_TYPE_VERSION_FROM_LATEST,
    CREATE_ORGANIZATION,
    CREATE_SUBMISSION_TYPE,
    MODIFY_CONTRACT,
    MODIFY_DUE_DATE,
    MODIFY_NOTIFICATION_PREFERENCES,
    MODIFY_ORGANIZATION
} from "../constants/mutations.js";
import { displayGenericError, handleMutationResult } from "./errorHandling.js";

// NOTE: any of these are valid options for error handling functions, which will also be be passed through from the fields in the object passed into "useAttemptToExecuteMutation":  

//   showSuccess: BOOLEAN (default: false) -- will display a success message if no errors 
//   showError: BOOLEAN (default: true) -- will display an error modal when error occurs 
//   before: STRING (default: "") -- inserted before message 
//   after: STRING (default: "") -- inserted after message 
//   title: STRING (default: "") -- title of error message modal 
//   message: STRING (default: "") -- message displayed in error modal. Any string set here will override the status message sent from server 
//   status: STRING (default: "") -- overrides the status sent from the server; will only have an effect if the string matches a valid error status (e.g., "INSUFFICIENT_PERMISSION")
//   successMessage: STRING (default: "") -- sets the message displayed in the success modal; will override the status message sent by server 


export const useAttemptToModifyContract = () => useAttemptToExecuteMutation({
    mutation: MODIFY_CONTRACT,
    mutationName: "modifyContract",
    title: "Error Modifying Contract",
    showSuccess: true
});

export const useAttemptToCreateContract = () => useAttemptToExecuteMutation({
    mutation: CREATE_CONTRACT,
    mutationName: "createContract",
    title: "Error Creating Contract"
});

export const useAttemptToModifyNotificationPreferences = () => useAttemptToExecuteMutation({
    mutation: MODIFY_NOTIFICATION_PREFERENCES,
    mutationName: "modifyNotationPreferences",
    title: "Error Saving Notification Preferences",
    showSuccess: true
});

export const useAttemptToModifyOrganization = () => useAttemptToExecuteMutation({
    mutation: MODIFY_ORGANIZATION,
    mutationName: "modifyOrganization",
    title: "Error Modifying Organization",
    showSuccess: true
});

export const useAttemptToCreateOrganization = () => useAttemptToExecuteMutation({
    mutation: CREATE_ORGANIZATION,
    mutationName: "createOrganization",
    title: "Error Creating Organization"
});

export const useAttemptToCreateSubmissionType = () => useAttemptToExecuteMutation({
    mutation: CREATE_SUBMISSION_TYPE,
    mutationName: "createSubmissionType",
    title: "Error Creating Submission Type"
});

export const useAttemptToModifyDueDate = () => useAttemptToExecuteMutation({
    mutation: MODIFY_DUE_DATE,
    mutationName: "modifyDueDate",
    title: "Error Modifying Due Date"
});

export const useAttemptToCreateContractTypeVersionFromLatest = () => useAttemptToExecuteMutation({
    mutation: CREATE_CONTRACT_TYPE_VERSION_FROM_LATEST,
    mutationName: "createContractTypeVersionFromLatest",
    title: "Error Creating Contract Type Version"
});

const useAttemptToExecuteMutation = ({
    mutation,
    mutationName,
    ...errorHandlingOptions
}) => {
    const [executeMutation, result] = useMutation(mutation);

    const attemptToExecuteMutation = async (options) => {
        try {
            const result = await executeMutation(options);
            
            const isSuccess = handleMutationResult(result, errorHandlingOptions);

            return { success: isSuccess, result};
        } catch (e) {
            displayGenericError({ title: errorHandlingOptions.title });
            return { success: false };
        }
    };

    const status = result.data?.[mutationName]?.status;
    const wasSuccessful = result?.data?.[mutationName]?.success;
    const attemptResult = {
        success: wasSuccessful,
        status,
        ...result
    };

    return [attemptToExecuteMutation, attemptResult];
};