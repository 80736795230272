// takes in a folder pathway (array) and constructs a pathname and the correct initial state for navigating to nested library folders 
export const getLibraryLinkData = (pathway) => { 
    if (!Array.isArray(pathway) || pathway.length < 1) {
        return null;
    } else {
        const contractTypeId = pathway[0]?.contractType?.id ?? "";
        const baseFolderName = pathway[0]?.baseFolder?.name ?? "";
        const baseFolderId = pathway[0]?.baseFolder?.id ?? "";
        const currentFolderName = pathway[pathway.length - 1]?.name;
        const currentFolderId = pathway[pathway.length - 1]?.id;
        if (!contractTypeId || !baseFolderName || !baseFolderId || !currentFolderName || !currentFolderId) {
            return null;
        } else {
            return {
                pathname: `/library/${contractTypeId}/${baseFolderName}/${baseFolderId}`,
                state: {
                    currentFolderName,
                    currentFolderId
                }
            };
        }
    }
};
