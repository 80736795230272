import React, { useEffect, useState } from "react";
import { Button,Modal } from "antd";
import { useAttemptToModifyNotificationPreferences } from "../../../utils/attemptMutationHooks.js";
import { useUnsavedChanges } from "../../Application/UnsavedChangesContext.js";
import { SUBMISSION } from "../../../constants/index.js";

export const NotificationSaveButtons = ({ 
    changedNotifications, 
    reset, 
    refetch 
}) => {
    // eslint-disable-next-line no-unused-vars
    const [_unused, setUnsavedChanges] = useUnsavedChanges();
    const [loading, setLoading] = useState(false);
    const [modifyPreferences] = useAttemptToModifyNotificationPreferences();

    const isVisible = changedNotifications && typeof changedNotifications === "object"
        ? Object.values(changedNotifications).some(pref => pref && pref.isModified) 
        : false;

    const { confirm } = Modal;

    useEffect(() => {
        setUnsavedChanges(isVisible);
    }, [isVisible]);


    const handleSaveProcess = async (newPreferences) => {
        try {
            setLoading(true);
            const success = await modifyPreferences({
                variables: { newPreferences }
            });
            if (success) {
                await refetch();
            } 
        } finally {
            setLoading(false);
            reset();
        }
    };

    const settingsConfirmPopup = (newPreferences) => {
        confirm({
            title: "Please note that your new contract type notification settings will apply across all Submission Type Categories.",
            okText: "Submit",
            onOk: async () => {
                await handleSaveProcess(newPreferences);
            },
            destroyOnClose: true
        });
    };


    return isVisible 
        ? <div style={{ 
            position: "fixed", 
            top: "160px",
            right: "24px",
            zIndex: "1000"
        }}>
            <Button type="primary" loading={loading} onClick={async () => {
                let contractTypeSubmissionModified = false;
                const newPreferences = Object.entries(changedNotifications).reduce((toUpdate, entry) => {
                    const [id, pref] = entry;
                    if (pref.isModified) {
                        toUpdate.push({
                            id, 
                            active: pref.active
                        });
                    }
                    if (pref.type === SUBMISSION) {
                        contractTypeSubmissionModified = true;
                    }
                    return toUpdate;
                }, []);

                if (contractTypeSubmissionModified === true) {
                    settingsConfirmPopup(newPreferences);
                } else {
                    await handleSaveProcess(newPreferences);
                }
            }}>
                Save Changes
            </Button>

            <Button type="secondary" disabled={loading} onClick={reset} style={{ marginLeft: "8px" }}>Cancel</Button>
        </div>
        : null;
};