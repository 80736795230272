import { useQuery } from "@apollo/client";
import { Pagination } from "antd";
import { Table } from "../common/Table/index.js";
import React, { useEffect, useState, useContext } from "react";
import { UserContext } from "../Application/UserContext.js";
import { searchable } from "../../utils/index.js";
import { LoadingContent } from "../common/LoadingContent/index.js";
import { Main } from "../common/Main/index.js";
import { OrganizationsCsvExport } from "./export.js";
import { organizationsQuery } from "./query.js";
import { handleGraphQLError } from "../../utils/errorHandling.js";
import Highlighter from "react-highlight-words";
import { ClearFiltersButton } from "../common/ClearFiltersButton/index.js";
import { PCDULink } from "../common/PCDULink/index.js";
import { PersistentState } from "../../utils/PersistentState.js";
import { handleControlledDefaultSortOrder } from "../../utils/handleControlledDefaultSortOrder.js";

const { usePersistentState } = PersistentState();

const NEW_BREADCRUMB = {
    label: "Organizations",
    path: "/organizations", 
    tooltip: "All Organizations"
};

export const Organizations = () => {
    const { userPermCreateOrganization } = useContext(UserContext);
    const defaultSortOn = "name";
    const defaultSortBy = "ascend";
    
    // normal state :: 
    const [dataToUse, setDataToUse] = useState(null);
    
    // persistent state :: 
    const [organizationTablePage, setOrganizationTablePage] = usePersistentState(1);
    const [organizationTablePageSize, setOrganizationTablePageSize] = usePersistentState(10);
    const [nameSearch, setNameSearch] = usePersistentState("");
    const [serviceIdSearch, setServiceIdSearch] = usePersistentState("");
    const [contactSearch, setContactSearch] = usePersistentState("");
    const [contactEmailSearch, setContactEmailSearch] = usePersistentState("");
    const [sortOn, setSortOn] = usePersistentState(defaultSortOn);
    const [sortBy, setSortBy] = usePersistentState(defaultSortBy);

    const clearSearch = () => {
        setOrganizationTablePage(1);
        setOrganizationTablePageSize(10);
        setSortOn(defaultSortOn);
        setSortBy(defaultSortBy);
        setNameSearch("");
        setServiceIdSearch("");
        setContactSearch("");
        setContactEmailSearch("");
    };

    let queryVariables = {
        offset: (organizationTablePage - 1) * organizationTablePageSize,
        limit: organizationTablePageSize,
        nameSearch,
        serviceIdSearch,
        contactSearch,
        contactEmailSearch,
        sortOn,
        sortBy
    };

    const { loading, error, data } = useQuery(organizationsQuery, {
        variables: queryVariables,
        fetchPolicy: "no-cache"
    });

    useEffect(() => {
        if (data) {
            setDataToUse(data);
        }
    },[data]);

    if (error) {
        return handleGraphQLError(error);
    }

    if (!dataToUse) {
        return <LoadingContent />;
    }

    const { organizationsList } = dataToUse;
    const { organizations, count: organizationsCount } = organizationsList;

    const orgsTable = (
        <Table
            exportButton={<OrganizationsCsvExport variables={queryVariables} />}
            clearFiltersButton={<ClearFiltersButton 
                clearFilters={clearSearch} 
                filtersData={[
                    { currentValue: nameSearch, defaultValue: "" },
                    { currentValue: serviceIdSearch, defaultValue: "" },
                    { currentValue: contactSearch, defaultValue: "" },
                    { currentValue: contactEmailSearch, defaultValue: "" } 
                ]}
            />}
            style={{
                backgroundColor: "#fff"
            }}
            size="middle"
            pagination={false}
            columns={handleControlledDefaultSortOrder({ sortOn, sortBy }, [
                searchable({
                    title: "Service Center ID",
                    searchedText: serviceIdSearch,
                    handleSearch: setServiceIdSearch,
                    setPage: setOrganizationTablePage,
                    handleReset: () => setServiceIdSearch(""),
                    dataIndex: "specifier",
                    width: "15%",
                    sorter: true,
                    sortDirections: ["ascend", "descend", "ascend"],
                    render: (specifier, { id }) => {
                        return (
                            <PCDULink to={`/organizations/${id}`}>{specifier}</PCDULink>
                        );
                    }
                }),
                searchable({
                    title: "Name",
                    dataIndex: "name",
                    handleSearch: setNameSearch,
                    setPage: setOrganizationTablePage,
                    searchedText: nameSearch,
                    key: "name",
                    sorter: true,
                    sortDirections: ["ascend", "descend", "ascend"],
                    handleReset: () => setNameSearch("")
                   
                }),
                searchable({
                    title: "Primary Contact Name",
                    dataIndex: ["primaryContact", "name"],
                    width: "30%",
                    searchedText: contactSearch,
                    handleSearch: setContactSearch,
                    setPage: setOrganizationTablePage,
                    handleReset: () => setContactSearch(""),
                    sorter: false, // Needs support on backend w/ new resolvers 
                    sortDirections: ["ascend", "descend", "ascend"],
                    render: (_text, { primaryContact }) => {
                        return !primaryContact ? (
                            ""
                        ) : (
                            <PCDULink 
                                to={`/users/${primaryContact.id}`}
                                newBreadcrumbs={NEW_BREADCRUMB}
                            >
                                <Highlighter
                                    highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                                    searchWords={[contactSearch]}
                                    autoEscape
                                    textToHighlight={(primaryContact.name ? primaryContact.name : "")}
                                />
                            </PCDULink>
                        );
                    }
                }),
                searchable({
                    title: "Primary Contact Email",
                    dataIndex: ["primaryContact", "email"],
                    searchedText: contactEmailSearch,
                    handleSearch: setContactEmailSearch,
                    setPage: setOrganizationTablePage,
                    handleReset: () => setContactEmailSearch(""),
                    sorter: false, // Needs support on backend w/ new resolvers 
                    sortDirections: ["ascend", "descend", "ascend"],
                    render: (_text, { primaryContact }) => {
                        return !primaryContact ? (
                            ""
                        ) : (
                            <Highlighter
                                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                                searchWords={[contactEmailSearch]}
                                autoEscape
                                textToHighlight={(primaryContact.email ? primaryContact.email : "")}
                                className="break-word"
                                unhighlightClassName="break-word"
                            />
                        );
                    }
                })
            ])}
            dataSource={organizations}
            loading={loading}
            onChange={(_pagination, _filters, { field, order }) => {
                setSortOn(field);
                setSortBy(order);
            }}
            rowKey={(org) => org.id}
        />
    );

    const orgsTablePagination = (
        <Pagination
            style={{
                display: "flex",
                justifyContent: "center",
                padding: "20px 0 50px 0"
            }}
            showSizeChanger
            onShowSizeChange={(targetPage, pageSize) => {
                setOrganizationTablePage(targetPage);
                setOrganizationTablePageSize(pageSize);
            }}
            onChange={(targetPage, pageSize) => {
                setOrganizationTablePage(targetPage);
                setOrganizationTablePageSize(pageSize);
            }}
            current={organizationTablePage}
            pageSize={organizationTablePageSize}
            pageSizeOptions={["10", "25", "50", "100"]}
            total={organizationsCount}
        />
    );

    return (
        <Main
            title="All Organizations"
            breadcrumbs={["Organizations"]}
            details={
                <PCDULink
                    className="ant-btn ant-btn-primary link-button"
                    to="/organizations/create"
                    style={{
                        float: "right",
                        display: userPermCreateOrganization ? "inline-block" : "none"
                    }}
                >
                    Create an Organization
                </PCDULink>
            }
        >
            <div className="main-content-margin-top">
                {orgsTable}
                {orgsTablePagination}
            </div>
        </Main>
    );
};
