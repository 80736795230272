import React, { useState, useContext, useEffect } from "react";
import { Button, Modal, Pagination } from "antd";
import { useMutation, useQuery } from "@apollo/client";
import { searchable } from "../../utils/index.js";
import { UserContext } from "../Application/UserContext.js";
import { Table } from "../common/Table/index.js";
import { handleControlledDefaultSortOrder } from "../../utils/handleControlledDefaultSortOrder.js";
import { submissionTypesQuery } from "./query.js";
import { PCDULink } from "../common/PCDULink/index.js";
import { handleGraphQLError, handleMutation } from "../../utils/errorHandling.js";
import { LoadingContent } from "../common/LoadingContent/index.js";
import { SelectableDetail } from "../common/SelectableDetail/index.js";
import { usePreloadedData } from "../Application/PreloadedData/index.js";
import { OPERATIONAL_REPORTS } from "../../constants/index.js";
import { MODIFY_SUBMISSION_TYPE_REPORT_TEMPLATE } from "../../constants/mutations.js";
import dayjs from "dayjs";

export const SubmissionTypesTable = ({
    reportTemplate,
    contractTypeId,
    archive

}) => {
    const [modified, setModified] = useState({});
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [sortOn, setSortOn] = useState("specifier");
    const [sortBy, setSortBy] = useState("ascend");
    const [nameSearch, setNameSearch] = useState("");
    const [specifierSearch, setSpecifierSearch] = useState("");
    const [reportTemplateId, setReportTemplateId] = useState(reportTemplate?.id);
    const [addSubmissionTypesModelVis, setAddSubmissionTypesModelVis] = useState(false);
    const { preloadedData } = usePreloadedData();
    const [modifySubmissionTypeReportTemplate] = useMutation(MODIFY_SUBMISSION_TYPE_REPORT_TEMPLATE);

    const operationalReportsCategory = preloadedData?.categories?.find(({ name }) => name === OPERATIONAL_REPORTS);
    const { confirm } = Modal;

    const {
        userPermEditSubmissionType,
        userIsSuperUser
    } = useContext(UserContext);

    useEffect(() => {
        if (reportTemplate && reportTemplate.id) {
            setReportTemplateId(reportTemplate.id);
        }
    }, [reportTemplate]);

    const queryVariables = {
        offset: (page - 1) * pageSize,
        limit: pageSize,
        nameSearch,
        categoriesFilter: operationalReportsCategory?.id ? [operationalReportsCategory.id] : [],
        contractTypesFilter: [contractTypeId],
        specifierSearch,
        reportTemplateId,
        sortOn,
        sortBy
    };

    const { loading, error, data, refetch } = useQuery(submissionTypesQuery, {
        variables: queryVariables,
        fetchPolicy: "no-cache"
    });

    if (error) {
        return handleGraphQLError(error);
    }

    const assignedSubmissionTypes = data?.assignedSubmissionTypes?.submissionTypes ?? [];
    const assignedSubmissionTypesCount = data?.assignedSubmissionTypes?.count ?? 0;
    const availableSubmissionTypes = data?.availableSubmissionTypes?.submissionTypes ?? [];

    const modifyReportTemplate = async ({ deleted, submissionTypesIds }) => {
        await handleMutation(
            modifySubmissionTypeReportTemplate({
                variables: {
                    reportTemplateId,
                    submissionTypesIds: submissionTypesIds,
                    deleted: deleted
                }
            })
        );
        refetch();
    };

    const addSubmissionTypesModel = (
        <div>
            <Modal
                title="Submission Types"
                open={addSubmissionTypesModelVis}
                okText="Save"
                closable={false}
                maskClosable={false}
                okButtonProps={{
                    disabled: (typeof modified.submissionTypesIds === "undefined")
                }}
                onOk={async () => {
                    await modifyReportTemplate({
                        deleted: false, 
                        submissionTypesIds: modified.submissionTypesIds
                    });
                    setModified({});
                    setAddSubmissionTypesModelVis(false);
                }}
  
                onCancel={() => {
                    setModified({});
                    setAddSubmissionTypesModelVis(false);
                }}
                destroyOnClose={true}
            >
                <div>
                    <div style={{ fontSize: "14px" }}>
                        <p>
                            <span>Select All Submission Types that will use this Report Template version: </span>
                        </p>
                        <SelectableDetail
                            title="Submission type"
                            multiple={true}
                            value={modified?.submissionTypesIds ?? []}
                            onValueUpdated={(value) => {
                                setModified({
                                    ...modified,
                                    submissionTypesIds: typeof value === "string" ? [value] : [...value]
                                });
                            }}
                            options={availableSubmissionTypes.map(({ 
                                id, 
                                name, 
                                specifier 
                            }) => {
                                return {
                                    text: `${specifier} - ${name}`,
                                    value: id,
                                    key: id
                                };
                            })}
                        />
                    </div>
                </div>
            </Modal>
        </div>
    );

    
    const showConfirmRemove = (id) => {
        confirm({
            title:
                "Are you sure you want to remove this submission type?",
            okText: "Yes, Remove",
            async onOk() {
                modifyReportTemplate({
                    deleted: true,
                    submissionTypesIds: [id]
                });
            }
        });
    };

    const submissionTypesColumns = [
        searchable({
            title: "ID",
            dataIndex: "specifier",
            key: "specifier",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            render: (specifier, { id }) => {
                return (
                    <PCDULink 
                        to={`/submission-types/${id}`}
                    >
                        {specifier}
                    </PCDULink>
                );
            },
            handleSearch: (textToSearch) => {
                setSpecifierSearch(textToSearch);
                setPage(1);
            },
            searchedColumn: () => { },
            searchedText: specifierSearch
        }),
        searchable({
            title: "Name",
            dataIndex: "name",
            handleSearch: (textToSearch) => {
                setNameSearch(textToSearch);
                setPage(1);
            },
            searchedText: nameSearch,
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"]
        }),
        {
            title: "Date Added",
            dataIndex: "reportTemplateAssignedOn",
            render: (addedOnDate) => addedOnDate ? dayjs(addedOnDate).format("MM/DD/YYYY") : "N/A"
        },
        userPermEditSubmissionType || userIsSuperUser 
            ? {
                title: "Actions",
                key: "actions",
                width: "10%",
                render: ({ id }) => {
                    return (
                        <Button
                            type="primary"
                            size="small"
                            onClick={() => {
                                showConfirmRemove(id);
                            }}
                        >
                            Remove
                        </Button>
                    );
                }
            } : null
    ].filter(Boolean);

    return (
        <div style={{ marginTop: "60px" }}>

            <div>
                <div style={{ display: "flex", marginBottom: "10px", marginTop: "10px"  }}>
                    <div><span style={{
                        fontSize: "16px",
                        fontWeight: "600"
                    }}>Submission Types</span></div>

                    {( archive && (userPermEditSubmissionType || userIsSuperUser)) && (<div style={{ marginLeft: "16px" }}>
                        <Button
                            loading={loading}
                            type="primary"
                            onClick={() => {
                                setAddSubmissionTypesModelVis(true);
                            }}
                        >Add</Button>
                    </div>
                    )}

                </div>
                
                <div style={{ marginTop: "24px" }}>
                    {loading && typeof data === "undefined" &&
                        <LoadingContent />
                    }
                    <Table
                        id="submissionTypesTable"
                        columns={handleControlledDefaultSortOrder({ sortOn, sortBy }, submissionTypesColumns)}
                        rowKey="id"
                        dataSource={assignedSubmissionTypes}
                        pagination={false}
                        onChange={(_pagination, _filters, { field, order }) => {
                            setSortOn(field);
                            setSortBy(order);
                        }}
                    />
                    <Pagination
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            padding: "20px 0 50px 0"
                        }}
                        showSizeChanger
                        onChange={(targetPage, pageSize) => {
                            setPage(targetPage);
                            setPageSize(pageSize);
                        }}
                        current={page}
                        pageSize={pageSize}
                        pageSizeOptions={["10", "25", "50", "100"]}
                        total={assignedSubmissionTypesCount}
                    />
                    {addSubmissionTypesModel}
                </div>
            </div>
        </div>
    );
};