import { NOTIFICATION_TYPES } from "../../../constants/index.js";

export const NOTIFICATION_TYPE_DISPLAY_ORDER = [
    NOTIFICATION_TYPES.SUBMISSION_CREATED,
    NOTIFICATION_TYPES.SUBMISSION_NEW_VERSION_UPLOADED,
    NOTIFICATION_TYPES.SUBMISSION_FINAL_VERSION_UPLOADED,
    NOTIFICATION_TYPES.DOCUMENT_ADDED_TO_SUBMISSION,
    NOTIFICATION_TYPES.DOCUMENT_ADDED_TO_FINALIZED_SUBMISSION, 
    NOTIFICATION_TYPES.SUBMISSION_REVIEW_COMPLETED,
    NOTIFICATION_TYPES.ISSUE_CREATED,
    NOTIFICATION_TYPES.ISSUE_MODIFIED,
    NOTIFICATION_TYPES.FEEDBACK_SENT,
    NOTIFICATION_TYPES.EXTENSION_GIVEN,
    NOTIFICATION_TYPES.SUBMISSION_FINALIZED,
    NOTIFICATION_TYPES.SUBMISSION_RESCINDED,
    NOTIFICATION_TYPES.SUBMISSION_ARCHIVE,
    NOTIFICATION_TYPES.SUBMISSION_VERSION_ARCHIVE,
    NOTIFICATION_TYPES.SUBMISSION_FAILURE,
    NOTIFICATION_TYPES.REACTION_CREATED,
    NOTIFICATION_TYPES.SUBMISSION_DUE_DATE_EXTENSION,
    NOTIFICATION_TYPES.NEW_SUBMISSION_TYPE_ADDED, 
    NOTIFICATION_TYPES.SUBMISSION_TYPE_UPDATES 
];

export const NOTIFICATION_TYPE_DISPLAY_NAMES = {
    [NOTIFICATION_TYPES.SUBMISSION_CREATED]: "Submission Created",
    [NOTIFICATION_TYPES.SUBMISSION_NEW_VERSION_UPLOADED]: "New Submission Version Uploaded",
    [NOTIFICATION_TYPES.SUBMISSION_FINAL_VERSION_UPLOADED]: "Final Version Uploaded",
    [NOTIFICATION_TYPES.DOCUMENT_ADDED_TO_SUBMISSION]: "Document(s) Added to Submission",
    [NOTIFICATION_TYPES.ISSUE_CREATED]: "Issue Created",
    [NOTIFICATION_TYPES.ISSUE_MODIFIED]: "Issue Modified",
    [NOTIFICATION_TYPES.SUBMISSION_REVIEW_COMPLETED]: "Submission Review Completed",
    [NOTIFICATION_TYPES.FEEDBACK_SENT]: "Feedback Sent",
    [NOTIFICATION_TYPES.EXTENSION_GIVEN]: "Due Date Extension Given",
    [NOTIFICATION_TYPES.SUBMISSION_FINALIZED]: "Submission Finalized",
    [NOTIFICATION_TYPES.SUBMISSION_RESCINDED]: "Submission Rescinded",
    [NOTIFICATION_TYPES.SUBMISSION_ARCHIVE]: "Submission Archived",
    [NOTIFICATION_TYPES.SUBMISSION_VERSION_ARCHIVE]: "Submission Version Archived",
    [NOTIFICATION_TYPES.REACTION_CREATED]: "Issue Reaction",
    [NOTIFICATION_TYPES.LIBRARY_DOCUMENT_UPLOAD]: "New Library Document Upload",
    [NOTIFICATION_TYPES.SUBMISSION_DUE_DATE_EXTENSION]: "Submission Due Date Extension",
    [NOTIFICATION_TYPES.SUBMISSION_TYPE_UPDATES]: "Submission Type Modified",
    [NOTIFICATION_TYPES.SUBMISSION_FAILURE]: "Submission Upload Failed", 
    [NOTIFICATION_TYPES.DOCUMENT_ADDED_TO_FINALIZED_SUBMISSION]: "New Document(s) Added to Finalized Submission",
    [NOTIFICATION_TYPES.NEW_SUBMISSION_TYPE_ADDED]: "New Submission Type Created"
};
