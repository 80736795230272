export const handleControlledDefaultSortOrder = ({ sortOn, sortBy, customHandler }, columns) => {
    return columns.map(col => {
        if (typeof customHandler === "function") {
            if (customHandler(col)) {
                col.defaultSortOrder = sortBy;
                col.sortOrder = sortBy;
            }
        } else if (col.dataIndex === sortOn) {
            col.defaultSortOrder = sortBy;
            col.sortOrder = sortBy;
        }
        return col;
    });
};