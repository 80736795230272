import { useQuery } from "@apollo/client";
import React, { useEffect, useState, useContext } from "react";
import { UserContext } from "../Application/UserContext.js";
import { DOCUMENT_STATUS, S3_UPLOAD_STATUS, SUBMISSION_STATUS_LIST } from "../../constants/index.js";
import { formatTimeValue, inclusiveDateTimeRange, currentYear } from "../../utils/functions.js";
import { dateable, filterable, searchable } from "../../utils/index.js";
import { LoadingContent } from "../common/LoadingContent/index.js";
import { Main } from "../common/Main/index.js";
import { DocumentsCsvExport } from "./export.js";
import { documentsCountQuery, documentsQuery } from "./query.js";
import { handleGraphQLError } from "../../utils/errorHandling.js";
import { SelectableDetail } from "../common/SelectableDetail/index.js";
import { ScrollableTable } from "../common/ScrollableTable/index.js";
import { SubmissionYearDropDown } from "../common/SubmissionYearSelection/index.js";
import { ClearFiltersButton } from "../common/ClearFiltersButton/index.js";
import { DownloadButton } from "../common/DownloadButton/index.js";
import { PCDULink } from "../common/PCDULink/index.js";
import { handleControlledDefaultSortOrder } from "../../utils/handleControlledDefaultSortOrder.js";
import { useAutoFlag } from "../../utils/useAutoFlag.js";
import { PersistentState } from "../../utils/PersistentState.js";
import { TablePagination } from "../common/TablePagination/index.js";
import { usePreloadedData } from "../Application/PreloadedData/index.js";

const DOCUMENTS_BREADCRUMB = { label: "Documents", path: "/documents" };

const { usePersistentState } = PersistentState();

export const Documents = () => {
    const defaultSortOn = "created_at";
    const defaultSortBy = "descend";

    const { preloadedData } = usePreloadedData();

    // normal state :: 
    const [dataToUse, setDataToUse] = useState(null);
    const [documentsCount, setDocumentsCount] = useState(0);
    const [resetFilterSearch, setResetFilterSearch] = useAutoFlag(false);

    // persistent state :: 
    const [documentTablePage, setDocumentTablePage] = usePersistentState(1);
    const [documentTablePageSize, setDocumentTablePageSize] = usePersistentState(10);
    const [nameSearch, setNameSearch] = usePersistentState("");
    const [documentSpecifierSearch, setDocumentSpecifierSearch] = usePersistentState("");
    const [primaryReviewerSearch, setPrimaryReviewerSearch] = usePersistentState("");
    const [contractTypesFilter, setContractTypesFilter] = usePersistentState([]);
    const [businessUnitFilter, setBusinessUnitFilter] = usePersistentState([]);
    const [statusFilter, setStatusFilter] = usePersistentState([]);
    const [documentUploadStatusFilter, setDocumentUploadStatusFilter] = usePersistentState([]);
    const [documentTypeFilter, setDocumentTypeFilter] = usePersistentState([]);
    const [submitterFilter, setSubmitterFilter] = usePersistentState([]);
    const [deletedFilter, setDeletedFilter] = usePersistentState([]);
    const [submissionTypeFilter, setSubmissionTypeFilter] = usePersistentState([]);
    const [createdDateFilter, setCreatedDateFilter] = usePersistentState([]);
    const [sortOn, setSortOn] = usePersistentState(defaultSortOn);
    const [sortBy, setSortBy] = usePersistentState(defaultSortBy);
    const [categoryFilter, setCategoryFilter] = usePersistentState([]);
    const [yearFilter, setYearFilter] = usePersistentState(currentYear);

    const {
        userPermViewContractTypes,
        userPermCreateSubmission,
        userIsSubmitter,
        userPermPrimaryReviewer,
        userPermSecondaryNotifier,
        userIsSystemAdmin,
        userIsContractAdmin
    } = useContext(UserContext);

    const resetAllFilters = () => {
        setDocumentTablePage(1);
        setDocumentTablePageSize(10);
        setSortOn(defaultSortOn);
        setSortBy(defaultSortBy);
        setNameSearch("");
        setPrimaryReviewerSearch("");
        setDocumentSpecifierSearch("");
        setStatusFilter([]);
        setDocumentTypeFilter([]);
        setDocumentUploadStatusFilter([]);
        setSubmitterFilter([]);
        setDeletedFilter([]);
        setSubmissionTypeFilter([]);
        setCreatedDateFilter([]);
        setCategoryFilter([]);
        setYearFilter(currentYear);
        setContractTypesFilter([]);
        setBusinessUnitFilter([]);
        setResetFilterSearch(true);
    };

    const offset = (documentTablePage - 1) * documentTablePageSize;

    // Query variables shared between both queries :: 
    const queryVariables = {
        createdDateFilter: inclusiveDateTimeRange(createdDateFilter),
        submissionTypeFilter,
        submitterFilter,
        deletedFilter,
        documentTypeFilter,
        contractTypesFilter,
        businessUnitFilter,
        nameSearch,
        statusFilter,
        documentUploadStatusFilter,
        categoryFilter,
        documentSpecifierSearch,
        primaryReviewerSearch,
        libraryAssociated: userPermCreateSubmission,
        yearFilter
    };

    const { loading, error, data } = useQuery(documentsQuery, {
        variables: {
            sortOn,
            sortBy,
            limit: documentTablePageSize,
            offset,
            ...queryVariables 
        },
        fetchPolicy: "no-cache"
    });

    const { 
        loading: pageDataLoading,
        data: pageData  
    } = useQuery(documentsCountQuery, {
        variables: queryVariables,
        fetchPolicy: "no-cache"
    });

    useEffect(() => {
        if (data) {
            setDataToUse(data);
        }
    },[data]);

    useEffect(() => {
        if (pageData) {
            const count = pageData?.submissionDocumentsList?.count;
            setDocumentsCount(Number.isInteger(count) ? count : 0);
        }
    }, [pageData]);

    if (error) {
        return handleGraphQLError(error);
    }

    if (!dataToUse) {
        return <LoadingContent />;
    }

    const { documentTypes, categories } = preloadedData;
    const contractTypes = userPermCreateSubmission ? preloadedData.libraryAssociatedContractTypes : preloadedData.contractTypes;
    const submissionTypes = preloadedData.submissionTypes.slice().sort((st1, st2) => st1.name.localeCompare(st2.name));
    const organizations = preloadedData.submitterOrganizations;
    const businessUnits = preloadedData.businessUnits.filter((bu) => bu !== null);
    const submissionDocuments = dataToUse?.submissionDocumentsList?.submissionDocuments ?? [];

    const categoryMenu = (
        <SelectableDetail
            title={"Category Type"}
            blurOnSelectionId={"library-search-ct-dropdown"}
            showArrow={true}
            multiple={true}
            value={categoryFilter}
            strict={true}
            onValueUpdated={(value) => {
                setCategoryFilter(value);
                setDocumentTablePage(1);
            }}
            options={categories.map(({ name, id }) => {
                return {
                    key: id,
                    text: `${name}`,
                    value: id
                };
            })}
        />);


    const allowDocumentDownload = (documentUploadStatus, submissionType, status) => {
        const isReadonlyFinalized = submissionType?.category?.readonlyFinalizedFlag === true;
        const submissionTypeId = submissionType?.id; 
        if (documentUploadStatus !== S3_UPLOAD_STATUS.uploaded) {
            // disable download, regardless of anything else :: 
            return false;
        } else {
            if (isReadonlyFinalized) {
                // if submission status is finalized, user can download; 
                // otherwise, user needs special permission as an assigned reviewer, an admin, or the submitter :: 
                const userCanDownloadNonFinalized = (
                    userPermPrimaryReviewer(submissionTypeId) || 
                    userPermSecondaryNotifier(submissionTypeId) || 
                    userIsSystemAdmin || 
                    userIsContractAdmin || 
                    userIsSubmitter
                );

                return status === SUBMISSION_STATUS_LIST.final
                    ? true 
                    : userCanDownloadNonFinalized;
            } else {
                return true;
            }
        }
    };

    const documentTableColumns = [
        searchable({
            title: "ID",
            key: "specifier",
            dataIndex: "specifier",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            searchedText: documentSpecifierSearch,
            handleSearch: setDocumentSpecifierSearch,
            setPage: setDocumentTablePage,
            render: (highlightedId, { id }) => {
                return (
                    <PCDULink to={`/documents/${id}`}>{highlightedId}</PCDULink>
                );
            }
        }),
        searchable({
            title: "Name",
            dataIndex: ["documentNewestVersion", "name"],
            className: "medium-column",
            key: "name",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            searchedText: nameSearch,
            handleSearch: setNameSearch,
            setPage: setDocumentTablePage,
            render: (highlightedNickname) => {
                return (<span>{highlightedNickname}</span>);
            }
        }),
        filterable({
            title: "Sub-Submission Type (Child)",
            dataIndex: "documentType",
            className: "medium-column",
            key: "sub_submission",
            render: (documentType) => {
                return documentType !== null ? documentType.name : "";
            },
            searchFilters: true,
            resetFilterSearch,
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            filter: documentTypeFilter,
            setFilter: setDocumentTypeFilter,
            setPage: setDocumentTablePage,
            domain: documentTypes.map(({ id, name, submissionTypeName, contractTypeName }, i) => {
                const toReturn = {
                    key: id,
                    value: id,
                    label: name
                };
                if (name === documentTypes[i + 1]?.name || name === documentTypes[i - 1]?.name) {
                    toReturn.additionalText = `under "${submissionTypeName}" for ${contractTypeName}`;
                }
                return toReturn;
            })
        }),
        searchable({
            title: "Primary Reviewer",
            dataIndex: "primaryReviewerName",
            key: "primaryReviewer",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            handleSearch: setPrimaryReviewerSearch,
            setPage: setDocumentTablePage,
            searchedText: primaryReviewerSearch
        }),
        filterable({
            title: "Submission Type",
            key: "submission_type",
            render: ({
                submission: {
                    obligation: {
                        submissionType: { id, name, specifier }
                    }
                }
            }) => {
                return (
                    <PCDULink 
                        to={`/submission-types/${id}`}
                        newBreadcrumbs={DOCUMENTS_BREADCRUMB}
                    >
                        {specifier} - {name}
                    </PCDULink>
                );
            },
            searchFilters: true,
            resetFilterSearch,
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            filter: submissionTypeFilter,
            setFilter: setSubmissionTypeFilter,
            setPage: setDocumentTablePage,
            domain: submissionTypes.map(({ id, name }) => {
                return {
                    key: id,
                    value: id,
                    label: name
                };
            })
        }),
        filterable({
            title: "Submission Status",
            dataIndex: "status",
            key: "status",
            setFilter: setStatusFilter,
            setPage: setDocumentTablePage,
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            filter: statusFilter,
            domain: Object.values({...SUBMISSION_STATUS_LIST}).map((status) => {
                return {
                    key: status,
                    value: status,
                    label: status
                };
            }),
            render: (submissionStatus) => submissionStatus
        }),
        filterable({
            title: "Document Status",
            dataIndex: "documentUploadStatus",
            key: "documentUploadStatus",
            setFilter: setDocumentUploadStatusFilter,
            setPage: setDocumentTablePage,
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            filter: documentUploadStatusFilter,
            domain: Object.values(S3_UPLOAD_STATUS).map((status) => {
                return {
                    key: status,
                    value: DOCUMENT_STATUS[status],
                    label: DOCUMENT_STATUS[status]
                };
            }),
            render: (documentStatus) => {
                return <div style={{color: documentStatus === "error" ? "red" : "initial", fontWeight: documentStatus === "error" ? "bold" : "initial"}}> 
                    {DOCUMENT_STATUS[documentStatus]}
                </div>;
            }
        }),
        filterable({
            title: "Contract Type",
            dataIndex: "submission",
            key: "contractType",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            searchFilters: true,
            resetFilterSearch,
            filter: contractTypesFilter,
            setFilter: setContractTypesFilter,
            setPage: setDocumentTablePage,
            domain: contractTypes.map(({ id, name }) => {
                return {
                    label: name,
                    value: id
                };
            }),
            render: (submission) => {
                const id = submission.obligation.submissionType.contractType.id;
                const name = submission.obligation.submissionType.contractType.name;
                return userPermViewContractTypes
                    ? <PCDULink 
                        to={`/contract-types/${id}`}
                        newBreadcrumbs={DOCUMENTS_BREADCRUMB}
                    >
                        {name}
                    </PCDULink>
                    : name;
            }
        }),
        filterable({
            title: "Business Unit",
            dataIndex: "submission",
            key: "businessUnit",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            searchFilters: true,
            resetFilterSearch,
            filter: businessUnitFilter,
            setFilter: setBusinessUnitFilter,
            setPage: setDocumentTablePage,
            domain: businessUnits.map(({ id, name }) => {
                return {
                    label: name,
                    value: id
                };
            }),
            render: (submission) => {
                const businessUnit = submission.obligation.submissionType.businessUnit;
                return businessUnit !== null ? businessUnit.name : "";
            }
        }),
        dateable({
            title: "Created At",
            key: "created_at",
            render: ({ createdAt }) => {
                return formatTimeValue(createdAt, true);
            },
            filter: createdDateFilter,
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            setFilter: setCreatedDateFilter,
            setPage: setDocumentTablePage
        }),
        {
            title: "Actions",
            key: "download",
            width: "130px",
            render: ({ documentNewestVersion, documentUploadStatus, submission, status }) => {
                const submissionType = submission?.obligation?.submissionType;
                const disableDownloadButton = allowDocumentDownload(documentUploadStatus, submissionType, status) !== true;
                return (
                    <DownloadButton
                        disabled={disableDownloadButton}
                        size="small"
                        style={{ marginLeft: "6px" }}
                        document={documentNewestVersion}
                    />
                );
            }
        }
    ];

    if (!userIsSubmitter) {
        const submitterOrgColumn = filterable({
            title: "Submitter",
            key: "submitting_organization_name",
            render: ({
                submission: {
                    submitter: { id, name }
                }
            }) => {
                return <PCDULink 
                    to={`/organizations/${id}`}
                    newBreadcrumbs={DOCUMENTS_BREADCRUMB}
                >
                    {name}
                </PCDULink>;
            },
            searchFilters: true,
            resetFilterSearch,
            sorter: true,
            truncate: 30,
            sortDirections: ["ascend", "descend", "ascend"],
            filter: submitterFilter,
            setFilter: setSubmitterFilter,
            setPage: setDocumentTablePage,
            domain: organizations.map(({ id, name }) => {
                return {
                    key: id,
                    value: id,
                    label: name
                };
            })
        });

        documentTableColumns.splice(3, 0, submitterOrgColumn);
    }
    const documentsTable = (
        <ScrollableTable
            exportButton={<DocumentsCsvExport variables={queryVariables} />}
            clearFiltersButton={<ClearFiltersButton 
                clearFilters={resetAllFilters} 
                filtersData={[
                    { currentValue: nameSearch, defaultValue: "" },
                    { currentValue: documentSpecifierSearch, defaultValue: "" },
                    { currentValue: primaryReviewerSearch, defaultValue: "" },
                    { currentValue: contractTypesFilter, defaultValue: [] },
                    { currentValue: businessUnitFilter, defaultValue: [] },
                    { currentValue: statusFilter, defaultValue: [] },
                    { currentValue: documentUploadStatusFilter, defaultValue: [] },
                    { currentValue: documentTypeFilter, defaultValue: [] },
                    { currentValue: submitterFilter, defaultValue: [] },
                    { currentValue: deletedFilter, defaultValue: [] },
                    { currentValue: submissionTypeFilter, defaultValue: [] },
                    { currentValue: createdDateFilter, defaultValue: [] },
                    { currentValue: categoryFilter, defaultValue: [] },
                    { currentValue: yearFilter, defaultValue: currentYear }
                ]}
            />}
            pagination={false}
            columns={handleControlledDefaultSortOrder({
                sortOn, 
                sortBy, 
                customHandler: ({ key }) => key === sortOn 
            }, documentTableColumns)}
            dataSource={submissionDocuments}
            loading={loading}
            onChange={(_pagination, _filters, { columnKey, order }) => {
                setSortOn(columnKey);
                setSortBy(order);
            }}
            rowKey="id"
        />
    );

    return (
        <Main title="All Documents"
            breadcrumbs={["Documents"]}
        >
            <div className="year-category-dropdown-container">
                <div className="year-dropdown">
                    <SubmissionYearDropDown
                        yearFilter = {yearFilter}
                        setYearFilter={setYearFilter}
                        title="Document Created Year"
                        onChange={() => {
                            setDocumentTablePage(1);
                        }}
                    />
                </div>
                <div className="category-dropdown">
                    {categoryMenu}
                </div>
            </div>
            {documentsTable}
            <TablePagination
                loading={pageDataLoading}
                setPage={setDocumentTablePage}
                setPageSize={setDocumentTablePageSize}
                total={documentsCount}
                defaultPage={documentTablePage}
                defaultPageSize={documentTablePageSize}
                currentPageNumber={documentTablePage}
            />
        </Main>
    );
};