import React from "react";
import { Button, Modal } from "antd";
import { useMutation } from "@apollo/client";
import { handleMutation } from "../../../../utils/errorHandling.js";
import { MODIFY_FEEDBACK_DOCUMENT } from "../../../../constants/mutations.js";

export const InactivateFeedbackDocument = ({ 
    feedbackDocument, 
    actionHappening,
    setActionHappening, 
    refetch 
}) => { 
    const [modifyFeedbackDocument] = useMutation(MODIFY_FEEDBACK_DOCUMENT);

    const id = feedbackDocument?.id; 
    const { confirm } = Modal;

    const handleInactivation = () => {
        const modal = confirm({
            title:
                "Are you sure you want to inactivate this feedback document?",
            okText: "Yes",
            onOk: async () => {
                setActionHappening(true);
                modal.update({ cancelButtonProps: { disabled: true }});
                await handleMutation(
                    modifyFeedbackDocument({
                        variables: {
                            id: feedbackDocument.id,
                            feedbackDocumentInput: {
                                active: false
                            }
                        }
                    })
                );
                setActionHappening(false);
                await refetch();
            }
        });
    };

    return id && typeof id === "string" 
        ? <Button 
            type="default"
            size="small"
            danger
            id="feedback-inactivate-button"
            onClick={() => {
                if (!actionHappening) {
                    handleInactivation();
                }
            }}
        > 
            Inactivate 
        </Button> 
        : null;
};
