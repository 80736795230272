import React from "react";
import { Tag } from "antd";
import {
    CheckCircleOutlined,
    SyncOutlined,
    CloseCircleOutlined 
} from "@ant-design/icons";
import { S3_UPLOAD_STATUS } from "../../../constants/index.js";

const S3_ERROR_TEXT_TO_DISPLAY = {
    [S3_UPLOAD_STATUS.uploading]: "Processing",
    [S3_UPLOAD_STATUS.uploaded]: "No Error",
    [S3_UPLOAD_STATUS.error]: "Technical Error",
    [S3_UPLOAD_STATUS.quarantine]: "Technical Error"
};
const DEFULAT_ERROR_TEXT = "Unknown";

export const getS3ErrorText = (status) => status && typeof status === "string"
    ? (S3_ERROR_TEXT_TO_DISPLAY[status] ?? DEFULAT_ERROR_TEXT)
    : DEFULAT_ERROR_TEXT;

export const S3ErrorMessage = (S3UploadStatus) => {
    switch (S3UploadStatus) {
        case S3_UPLOAD_STATUS.uploading:
            return <Tag icon={<SyncOutlined spin />} color="processing">
                { getS3ErrorText(S3UploadStatus) }
            </Tag>;
        case S3_UPLOAD_STATUS.uploaded:
            return <Tag icon={<CheckCircleOutlined />} color="success">
                { getS3ErrorText(S3UploadStatus) }
            </Tag>;
        case S3_UPLOAD_STATUS.error:
        case S3_UPLOAD_STATUS.quarantine:
            return <Tag icon={<CloseCircleOutlined />} color="error">
                { getS3ErrorText(S3UploadStatus) }
            </Tag>;
        default:
            return <Tag icon={<CloseCircleOutlined />} color="default">
                { getS3ErrorText(S3UploadStatus) }
            </Tag>;
    }
};
