import React from "react";
import { filterCriteriaVersionsByTimestamp } from "../../../utils/filterCriteriaVersions.js";
import { isUndefinedOrNull } from "../../../utils/functions.js";
import { ScrollableTable } from "../ScrollableTable/index.js";
import { PersistentState } from "../../../utils/PersistentState.js";
import { handleControlledDefaultSortOrder } from "../../../utils/handleControlledDefaultSortOrder.js";

const { usePersistentState } = PersistentState();

export const CriteriaTable = ({
    persistState = false,
    criteriaList, 
    uniquePersistentStateKey = null,
    versionFilterTimestamp, 
    loadingOnUndefined = false,
    additionalMessage = null,
    noHeader = false, 
    title = null
}) => { 

    const [sortOn, setSortOn] = usePersistentState("specifier", persistState, uniquePersistentStateKey);
    const [sortBy, setSortBy] = usePersistentState("ascend", persistState, uniquePersistentStateKey);
    const [currentPage, setCurrentPage] = usePersistentState(1, persistState, uniquePersistentStateKey);
    const [pageSize, setPageSize] = usePersistentState(10, persistState, uniquePersistentStateKey);

    // for persistent state, set default to the last page / page size that the user had selected 
    // (if persistState prop is not true, or if component is mounting for first time, etc., then these will default to page 1, page size 10) :: 
    const startingPage = currentPage;
    const startingPageSize = pageSize;

    const titleToUse = title ? title : "Criteria";
    
    const criteriaTable = Array.isArray(criteriaList) ? (
        <>
            { noHeader === true ? null : <h2> { titleToUse } </h2>}
            { noHeader === true ? null : <p>The following criteria must be met by any document uploaded for this submission. These criteria are detailed in the relevant contract. If a document fails to meet any of these criteria, reviewers will raise it as an issue for the submitter to resolve.</p> }
            { additionalMessage }
            <ScrollableTable
                rowKey="id"
                size="small"
                id="criteriaTable"
                noBorders={true}
                loading={loadingOnUndefined === true && (!Array.isArray(criteriaList) || versionFilterTimestamp)}
                columns={handleControlledDefaultSortOrder({ sortOn, sortBy }, [
                    {
                        title: "ID",
                        dataIndex: "specifier",
                        width: 150,
                        sorter: (a, b) => a.specifier - b.specifier,
                        sortDirections: ["ascend", "descend", "ascend"]
                    },
                    {
                        title: "Citation",
                        dataIndex: "citation",
                        width: 150,
                        sorter: (a, b) => (a.citation ? a.citation.trim().toLowerCase() : "") > (b.citation ? b.citation.trim().toLowerCase() : "") ? 1 : -1,
                        sortDirections: ["ascend", "descend", "ascend"]
                    },
                    {
                        title: "Description",
                        dataIndex: "description",
                        sorter: (a, b) => (a.description ? a.description.trim().toLowerCase() : "") > (b.description ? b.description.trim().toLowerCase() : "") ? 1 : -1,
                        sortDirections: ["ascend", "descend", "ascend"]
                    }
                ])}
                dataSource={isUndefinedOrNull(versionFilterTimestamp) ? criteriaList : filterCriteriaVersionsByTimestamp(criteriaList, versionFilterTimestamp)}
                pagination={{ 
                    defaultCurrent: startingPage, 
                    defaultPageSize: startingPageSize 
                }}
                onChange={(pagination, _filters, { field, order }) => {
                    const { current, pageSize } = pagination;
                    setCurrentPage(current);
                    setPageSize(pageSize);
                    setSortOn(field);
                    setSortBy(order);
                }}
            />
        </>
    ) : (<><p>No criteria to display</p></>);

    return (
        <div>
            {criteriaTable}
        </div>
    );
};