import React from "react";
import { EXPORTSIZE } from "../../constants/index.js";
import { formatTimeValue } from "../../utils/functions.js";
import { CsvExport } from "../common/CsvExport/index.js";
import { userLogQuery } from "./query.js";

export const csvHeaders = [
    { label: "User NCID", key: "ncid" },
    { label: "Activity Type", key: "activityType" },
    { label: "Brower Name", key: "browserName" },
    { label: "Activity Date", key: "activityDate" },
    { label: "IP Address", key: "iPAddress" }
];

export const transformDataForWebLogsCSV = (data) => {
    return data.userLogList.userLogs.map((userLog) => {
        return {
            ncid: userLog.userNcid,
            activityType: userLog.action,
            browserName: userLog.browserName,
            activityDate: formatTimeValue(userLog.activityDate, true),
            iPAddress: userLog.iPAddress
        };
    });
};

export const WebLogCsvExport = ({variables}) => {
    return <CsvExport
        csvFilename={"User_Audit_Logs.csv"}
        csvHeaders={csvHeaders}
        query={userLogQuery}
        variables={{...variables, offset: 0, limit: EXPORTSIZE}}
        transformData={transformDataForWebLogsCSV}
    />;
};
