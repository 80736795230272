import React, { useContext } from "react";
import { Spin, Tag, Tooltip } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import { DOCUMENT_DQ_STATUS_LIST } from "../../../constants/index.js";
import { UserContext } from "../../Application/UserContext.js";

export const DocumentDQStatus = ({ 
    document, 
    loading, 
    showIconOnSuccess = false,
    showSpinner = true
}) => {
    const { constantsData } = useContext(UserContext);
    const DOCUMENT_DQ_STATUS_DESCRIPTIONS = constantsData?.DOCUMENT_DQ_STATUS_DESCRIPTIONS;

    if (loading && showSpinner) {
        return <Spin size="medium" />;
    } else if (!DOCUMENT_DQ_STATUS_DESCRIPTIONS) { 
        // for some reason, DQ_STATUS_DESCRIPTIONS failed to load 
        return "N/A";
    } else {
        const dqFlag = document?.dqFlag;
        const dqStatus = document?.dqStatus;
        if (!dqFlag) {
            return "N/A";
        } else {
            if (!dqStatus) {
                return DOCUMENT_DQ_STATUS_DESCRIPTIONS[DOCUMENT_DQ_STATUS_LIST.Processing];
            } else if (dqStatus === DOCUMENT_DQ_STATUS_LIST.Success && showIconOnSuccess === true) {
                return <Tag icon={<CheckCircleOutlined />} color="success">
                    <Tooltip title={DOCUMENT_DQ_STATUS_DESCRIPTIONS[dqStatus]} placement="bottom"> No Issues </Tooltip>
                </Tag>;
            } else {
                const dqStatusDisplay = DOCUMENT_DQ_STATUS_DESCRIPTIONS[dqStatus];
                return dqStatusDisplay && typeof dqStatusDisplay === "string"
                    ? dqStatusDisplay
                    : "N/A";
            }
        }
    }
};