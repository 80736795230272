import {
    formatDate,
    isoDateRange,
    isUndefinedOrNull,
    currentYear
} from "../../utils/functions.js";
import { searchable, filterable, dateable } from "../../utils/index.js";
import React, { useEffect, useContext } from "react";
import moment from "moment";
import { useQuery } from "@apollo/client";
import { Table, Spin, Layout } from "antd";
import { UserContext } from "../Application/UserContext.js";
import {
    SUBMISSION_STATUS_LIST,
    SUBMISSION_STATUS_SUBMITTER,
    FREQUENCY_OPTIONS
} from "../../constants/index.js";
import { handleGraphQLError } from "../../utils/errorHandling.js";
import { TablePagination } from "../common/TablePagination/index.js";
import { ExtendDueDate } from "../common/ExtendDueDate/index.js";
import { CommentsList } from "../common/CommentsList/index.js";
import { SubmissionYearDropDown } from "../common/SubmissionYearSelection/index.js";
import { PCDULink } from "../common/PCDULink/index.js";
import { getNewBreadcrumbs } from "./_helpers.js";
import { PersistentState } from "../../utils/PersistentState.js";
import { usePreloadedData } from "../Application/PreloadedData/index.js";
import { handleControlledDefaultSortOrder } from "../../utils/handleControlledDefaultSortOrder.js";
import { submissionsOfSubmissionTypeQuery } from "./query.js";

const { usePersistentState } = PersistentState();


export const SubmissionsTable = ({
    submissionType,
    refetchSubmissions,
    setRefetchSubmissions
}) => {
    const newBreadcrumbs = getNewBreadcrumbs(submissionType);
    const { preloadedData } = usePreloadedData();

    // Persistent State :: 
    const [submissionsTablePage, setSubmissionsTablePage] = usePersistentState(1);
    const [submissionsTablePageSize, setSubmissionsTablePageSize] = usePersistentState(10);
    const [specifierSearch, setSpecifierSearch] = usePersistentState("");
    const [organizationsFilter, setOrganizationsFilter] = usePersistentState([]);
    const [statusFilter, setStatusFilter] = usePersistentState([]);
    const [dueDateFilter, setDueDateFilter] = usePersistentState([]);
    const [sortOn, setSortOn] = usePersistentState("specifier");
    const [sortBy, setSortBy] = usePersistentState("ascend");
    const [dataToUse, setDataToUse] = usePersistentState(null);
    const [yearFilter, setYearFilter] = usePersistentState(currentYear);

    const {
        userPermCreateExtension,
        userId,
        userPermCreateSubmission
    } = useContext(UserContext);

    const { loading, error, data, refetch } = useQuery(
        submissionsOfSubmissionTypeQuery,
        {
            variables: {
                offset: (submissionsTablePage - 1) * submissionsTablePageSize,
                limit: submissionsTablePageSize,
                submissionTypesFilter: [submissionType.id],
                specifierSearch,
                organizationsFilter,
                statusFilter,
                currentOrAdhocDueDateFilter: isoDateRange(dueDateFilter),
                sortOn,
                sortBy,
                yearFilter
            },
            fetchPolicy: "no-cache"
        }
    );

    useEffect(() => {
        if (refetchSubmissions === true) {
            refetch();
            setRefetchSubmissions(false);
        }
    }, [refetchSubmissions, setRefetchSubmissions]);

    useEffect(() => {
        if (data) {
            setDataToUse(data);
        }
    }, [data]);
 
    if (!dataToUse) {
        return <Layout.Content style={{ padding: "20px", textAlign: "center", width: "100%"}}>
            <Spin spinning size="large" />
        </Layout.Content>;
    }

    if (error) {
        return handleGraphQLError(error);
    }

    if (!dataToUse) {
        return null;
    }

    const { submissionsList } = dataToUse;
    const { organizations } = preloadedData;
    const { submissions, count: submissionsCount } = submissionsList;

    const statusOptions = Object.values(SUBMISSION_STATUS_LIST);
    const isSubmissionRequestedPresent = submissions.some(sub => sub.status === SUBMISSION_STATUS_SUBMITTER.final_requested || sub.status === SUBMISSION_STATUS_SUBMITTER.version);
 
    return <>
        <h2>Submissions</h2>
        <p>Submissions of this submission type.</p>
        <div className="year-dropdown">
            <SubmissionYearDropDown
                yearFilter = {yearFilter}
                setYearFilter={setYearFilter}
                title="Submission Year"
                onChange={() => {
                    setSubmissionsTablePage(1);
                }}
            />
        </div>
        <Table
            id="submissionsTable"
            size="middle"
            columns={handleControlledDefaultSortOrder({ 
                sortOn, 
                sortBy,
                customHandler: ({ key }) => key === sortOn 
            }, [
                searchable({
                    title: "ID",
                    key: "specifier",
                    dataIndex: "specifier",
                    searchedText: specifierSearch,
                    handleSearch: setSpecifierSearch,
                    setPage: setSubmissionsTablePage,
                    handleReset: () => setSpecifierSearch(""),
                    sorter: true,
                    sortDirections: ["ascend", "descend", "ascend"],
                    render: (text, { id }) => {
                        return (
                            <PCDULink to={`/submissions/${id}`} newBreadcrumbs={newBreadcrumbs}>{text}</PCDULink>
                        );
                    }
                }),
                userPermCreateSubmission ? {
                    title: "Submitting Organization",
                    key: "submitter",
                    dataIndex: "submitter",
                    render: ({ id, name }) => {
                        return (
                            <PCDULink to={`/organizations/${id}`} newBreadcrumbs={newBreadcrumbs}>{name}</PCDULink>
                        );
                    }
                }
                    : filterable({
                        title: "Submitting Organization",
                        key: "submitter",
                        dataIndex: "submitter",
                        domain: organizations.map(({ id, name }) => ({
                            label: name,
                            value: id
                        })),
                        filter: organizationsFilter,
                        setFilter: setOrganizationsFilter,
                        setPage: setSubmissionsTablePage,
                        render: ({ id, name }) => {
                            return (
                                <PCDULink to={`/organizations/${id}`} newBreadcrumbs={newBreadcrumbs}>{name}</PCDULink>
                            );
                        }
                    }),
                filterable({
                    title: "Status",
                    key: "status",
                    dataIndex: "status",
                    domain: statusOptions.map(value => ({
                        label: value,
                        value
                    })),
                    filter: statusFilter,
                    setFilter: setStatusFilter,
                    setPage: setSubmissionsTablePage
                }),
                dateable({
                    title: "Original Due Date",
                    key: "currentOrAdhocDueDate",
                    filter: dueDateFilter,
                    setFilter: setDueDateFilter,
                    setPage: setSubmissionsTablePage,
                    sorter: true,
                    render: (submission) => {
                        const nDayDiff = moment(submission?.timeline?.currentDueDate).diff(moment(submission?.timeline?.initialDueDate), "days");
                        if (submission.submissionTypeVersion.frequency === FREQUENCY_OPTIONS.adhoc) {
                            return isUndefinedOrNull(submission.adhocDueDate) ? "Ad Hoc" : formatDate(submission.adhocDueDate);
                        } else if (submission?.timeline?.currentDueDate) {
                            return nDayDiff > 0
                                ? `${formatDate(submission?.timeline?.currentDueDate)} (+${nDayDiff} days)`
                                : formatDate(submission?.timeline?.currentDueDate);
                        } else {
                            return "";
                        }
                    },
                    sortDirections: ["ascend", "descend", "ascend"]
                }),
                {
                    title: "Document Count",
                    key: "documentCount",
                    render: ({ submissionDocuments }) => {
                        return (
                            submissionDocuments.length
                        );
                    }
                },
                {
                    title: "Resubmission Due Date",
                    key: "submitterDueDate",
                    dataIndex: "submitterDueDate",
                    render: (submitterDueDate, { initialSubmitterDueDate }) => {
                        const nDayDiff = moment(submitterDueDate).diff(moment(initialSubmitterDueDate), "days");
                        if (submitterDueDate) {

                            return nDayDiff > 0
                                ? `${formatDate(submitterDueDate)} (+${nDayDiff} days)`
                                : formatDate(submitterDueDate);
                        } else {
                            return "";
                        }
                    }
                },
                (userPermCreateExtension && isSubmissionRequestedPresent) ? {
                    title: "Actions",
                    key: "actions",
                    render: (submission) => {
                        if (submission.status === SUBMISSION_STATUS_SUBMITTER.final_requested || submission.status === SUBMISSION_STATUS_SUBMITTER.version) {
                            return <ExtendDueDate
                                submission={submission}
                                latestSubmissionVersionId={submission.submissionNewestVersion.id}
                                refetch={refetch}
                            />;
                        }
                    }
                } : null
            ].filter(Boolean))}
            rowKey="id"
            dataSource={submissions}
            loading={loading}
            pagination={false}
            onChange={(_pagination, _filters, sorter) => {
                setSortOn(sorter.columnKey);
                setSortBy(sorter.order);
            }}
            expandable={{
                expandedRowRender: submission => {
                    return (
                        <div>
                            <CommentsList
                                title={""}
                                parent={submission}
                                userId={userId}
                                refetch={() => refetch()}
                                filterForDueDateComments={true}
                            />
                        </div>
                    );
                   
                },
                rowExpandable: submission => Array.isArray(submission.comments) && submission.comments.length > 0 && submission.comments.some(comment => !isUndefinedOrNull(comment.previousDueDate))
            }}
        />
        <TablePagination
            setPage={setSubmissionsTablePage}
            setPageSize={setSubmissionsTablePageSize}
            total={submissionsCount}
            defaultPage={submissionsTablePage}
            defaultPageSize={submissionsTablePageSize}
            currentPageNumber={submissionsTablePage}
        />
    </>;
};