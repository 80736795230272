import React, { useState, useEffect, useContext } from "react";
import { useQuery } from "@apollo/client";
import { useLocation, useParams } from "react-router-dom";
import { UserContext } from "../Application/UserContext.js";
import { usePreloadedData } from "../Application/PreloadedData/index.js";
import { LIBRARY_BASE_FOLDER } from "../../constants/index.js";
import { Main } from "../common/Main/index.js";
import { LoadingContent } from "../common/LoadingContent/index.js";
import { NoPermission } from "../common/NoPermission/index.js";
import { NoContent } from "../common/NoContent/index.js";
import { handleGraphQLError } from "../../utils/errorHandling.js";
import { PCDULink } from "../common/PCDULink/index.js";
import { LibraryQuery } from "./queries.js";
import { FolderContentsTable } from "./FolderContentsTable/index.js";


export const Library = () => {
    const params = useParams();
    const location = useLocation();
    const { preloadedData } = usePreloadedData();
    const [currentFolderName, setCurrentFolderName] = useState(location?.state?.currentFolderName ?? "");
    const [_currentFolderId, setCurrentFolderId] = useState(location?.state?.currentFolderId ?? "");

    const {
        userPermUploadnModifyLibDoc,
        userPermViewLibDoc,
        userPermViewAssociatedLibDoc
    } = useContext(UserContext);

    const userHasPermission = userPermViewLibDoc || userPermViewAssociatedLibDoc;

    const baseFolderId = params.baseFolderId;
    const contractTypeId = params.contractTypeId;
    const paramsBaseFolderName = params.baseFolderName;
    const currentFolderId = _currentFolderId ? _currentFolderId : baseFolderId;

    const resetStateToBaseFolder = () => {
        setCurrentFolderName("");
        setCurrentFolderId("");
    };

    useEffect(() => {
        const isBaseFolder = location.state?.isBaseFolder ?? false;
        if (isBaseFolder) {
            resetStateToBaseFolder();
        }
    }, [location.state]);

    const { loading, error, data } = useQuery(
        LibraryQuery,
        {
            variables: {
                libraryFolderId: currentFolderId,
                contractTypeId
            },
            fetchPolicy: "no-cache"
        }
    );

    if (error) {
        return handleGraphQLError(error);
    }

    if (loading && typeof data === "undefined") {
        return <LoadingContent delay={600} />;
    }

    if (!userHasPermission) {
        return <NoPermission />;
    }

    const contractType = data?.contractTypes?.find?.(({ id }) => id === params.contractTypeId);
    const baseFolderName = preloadedData?.libraryBaseFolder.find(({ name }) => name === paramsBaseFolderName)?.name;
    const goodParams = contractType && baseFolderName;
    const isFolderActive = data?.libraryFolderInfo?.active ?? true; 
    const folderPathway = data?.libraryFolderPathway ?? [];

    if (!goodParams) {
        return <NoContent />;
    }

    const atBase = currentFolderId === baseFolderId;
    const restrictToCategories = baseFolderName === LIBRARY_BASE_FOLDER.guidance_documents || baseFolderName === LIBRARY_BASE_FOLDER.report_templates;
    const allCategories = data?.contractType?.categories ?? [];
    const libraryFolders = data?.libraryFolders ?? [];
    const categories = allCategories.filter(({ name, deleted }) => !deleted && !libraryFolders.some(folder => folder.name === name || folder?.contractTypeCategory?.name === name));
    const insideBulletinSubfolder = baseFolderName === LIBRARY_BASE_FOLDER.bulletins && !atBase;

    // in all cases, new folders and uploads can only happen in active (non-archived) folders, if user has appropriate permission; in addition...
    // for Bulletins, folders can always be created; for Guidance Documents and Report Templates, new folders can only be created at base :: 
    const canCreateFolders = isFolderActive && userPermUploadnModifyLibDoc && (baseFolderName === LIBRARY_BASE_FOLDER.bulletins || atBase);

    // documents can be uploaded to any folder except the "base level" folder :: 
    const canUploadDocuments = isFolderActive && userPermUploadnModifyLibDoc && !atBase;

    const breadCrumbFolderSelected = folderPathway.map(({name, id}) => {
        return (
            <PCDULink
                key={`breadcrumb-folder-${id}`}
                to={{}}
                tooltip={`Library Folder: ${currentFolderName}`}
                onClick={() => {
                    setCurrentFolderName(name);
                    setCurrentFolderId(id);
                }}
            > 
                {name} 
            </PCDULink>
        );
    });

    return (
        <Main
            title={`${contractType.name}  - ${baseFolderName}`}
            breadcrumbs={
                [
                    "Library Documents",
                    contractType.name,
                    <PCDULink
                        key="libDocumentBaseFolder"
                        to={{
                            pathname: location.pathname,
                            state: {
                                isBaseFolder: true
                            }

                        }}
                        onClick={() => {
                            resetStateToBaseFolder();
                        }}
                        tooltip={`Library Folder: ${baseFolderName}`}
                    >
                        {baseFolderName}
                    </PCDULink>,
                    ...breadCrumbFolderSelected
                ]
            }
        >
            <div>
                <div
                    key="libraryDocumentContent"
                >
                    <FolderContentsTable 
                        currentFolderId={currentFolderId}
                        setCurrentFolderId={setCurrentFolderId}
                        currentFolderName={currentFolderName}
                        setCurrentFolderName={setCurrentFolderName}
                        inArchivedFolder={!isFolderActive}
                        baseFolderName={baseFolderName}
                        baseFolderId={baseFolderId}
                        contractTypeId={contractTypeId}
                        restrictToCategories={restrictToCategories}
                        categories={categories}
                        canUploadDocuments={canUploadDocuments}
                        canCreateFolders={canCreateFolders}
                        insideBulletinSubfolder={insideBulletinSubfolder}
                    /> 
                </div>
            </div> 
        </Main>
    );
};