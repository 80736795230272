import React from "react";
import { Modal, message as notify } from "antd";
import { ErrorMessage } from "../components/ErrorMessage/index.js";
import { isUndefinedOrNull } from "./functions.js";
import { isValidString } from "./index.js";

// Error Handler API 

// main public methods intended for consumption: 
//   handleMutation(mutation, options)
//   handleMutations([mutation], options)
//   handleGraphQLError(error, options)
//   handleMutationResult(result, options)

// WHERE 
//   error is the error property of the GraphQL response — the "error" variable from const { error, loading, data } = useQuery(...)
//   result is the object returned from a (resolved) mutation 
//   mutation is a promise (unresolved) for a mutation 
//   [mutation] is an array of promises (unresolved) each of which is for a mutation 

// "options" is an optional second argument that can be passed; it ought to be an object that can include any of these:
//   returnResponse: BOOLEAN (default: false) -- only applies to function "handleMutation". Will cause function to return the response data from the graphQL mutation, as opposed to its usual return value: id || boolean
//   showSuccess: BOOLEAN (default: false) -- will display a success message if no errors 
//   showError: BOOLEAN (default: true) -- will display an error modal when error occurs 
//   before: STRING (default: "") -- inserted before message 
//   after: STRING (default: "") -- inserted after message 
//   title: STRING (default: "Error") -- title of error message modal 
//   message: STRING (default: "") -- message displayed in error modal. Any string set here will override the status message sent from server 
//   status: STRING (default: "") -- overrides the status sent from the server; will only have an effect if the string matches a valid error status (e.g., "INSUFFICIENT_PERMISSION")
//   successMessage: STRING (default: "") -- sets the message displayed in the success modal; will override the status message sent by server 
//   unknownErrorMessage: STRING (default: "An unexpected error occurred. Please try again later.") -- applies to "handleMutation" and "handleMutations" methods, only. Sets the message displayed when an unexpected // unknown error occurs 
//   unknownErrorTitle: STRING (default: "Error") -- applies to "handleMutation" and "handleMutations" methods, only. Sets the message displayed when an unexpected // unknown error occurs 

// If there is not a valid (non-empty) string that can be derived from options.message, options.successMessage, or status, then these defaults will be used: 
//   Error Title: "Error"
//   Error Message: "An unexpected error occurred. Please try again later." 
//   Success Title: "Notice"
//   Success Message: "Changes saved successfully."


// Additional method for validation of input when making modifications to a resource: 
//   getGenuineModifications(modifications, original, options)

//  This fn compares two objects -- modifications, original -- and returns either 
//      1.) a new object containing the legitimate changes; or 
//      2.) if no legitimate changes found, returns null 
//  By default, if no legit modifications found, the message "No valid modifications were made." is displayed 
//  (but this message can be suppressed with noMessage option) 

//   "options" for getGenuineModifications:
//      noMessage: BOOLEAN (default: false) -- if true, no message will be displayed 
//      limitTo: [String] -- limits comparison of the two objects to only the keys listed (as strings) in the limitTo array 
//      trim: BOOLEAN or [String] (default: false) -- will trim any string values that are being compared, and will also trim any string values in the returned object; 
//          if Array of strings, this behavior will be limited to the keys contained in that array 
//      ignoreCase: BOOLEAN or [String] -- will ignore case for any string values that are being compared; 
//          if array of strings, this behavior will be limited to the keys contained in that array



// ++++++++++++++++++++++++++++++++++++++++++
// VARIABLES 
// 

const DEFAULT_OPTIONS = {
    before: "",
    after: "",
    title: "",
    message: "",
    status: "",
    showSuccess: false,
    successMessage: "",
    showError: true,
    unknownErrorMessage: ""
};

// generic titles / messages :: 
export const genericErrorTitle = "Error";
export const genericErrorMessage = "An unexpected error occurred. Please try again later.";
export const genericSuccessMessage = "Changes saved successfully.";
export const invalidModificationsMessage = "No valid modifications were made.";

// For use in place of "Download" or "Retrieve" buttons, when the s3 fetch for a document failed, but SQL data about the file came through :: 
export const FILE_UNAVAILABLE_TEXT = "File Upload Failed";
export const FILE_PROCESS = "File Processing";
export const FILE_UPLOAD_ERROR_MSG = "Please upload file having size greater than zero byte.";

// state for internal management :: 
let lastErrorOrResponse = null;


// ++++++++++++++++++++++++++++++++++++++++++
// EXPOSED (EXPORTED) FUNCTIONS  
// 

export const handleGraphQLError = (error, options = {}) => {
    if (!wasHandled(error)) {
        const optionsToUse = Object.assign({}, DEFAULT_OPTIONS, options);
        const { showError } = optionsToUse;
        setLastHandled(error);
        if (error && showError) {
            displayError(error, options);
        } 
    }
    return error ? <ErrorMessage /> : null;
};

export const handleMutationResult = (result, options = {}) => {
    // return false if errors, return true if no errors :: 

    const defaults = { ...DEFAULT_OPTIONS };

    if (result === undefined || result === null) {
        console.error("Invalid response passed to ErrorHandler.handleMutationResult. Displaying generic error message.");
        displayGenericError();
        return false;
    }  else if (!wasHandled(result)) {
        setLastHandled(result);
        const innerResponseData = getInnerResponseData(result);
        return handlePossibleError(innerResponseData, Object.assign({}, defaults, options));
    }
};

// returns ID, or success (Boolean) 
export const handleMutation = async (mutation, options = {}) => {
    try {
        const result = await mutation;
        const wasSuccessful = handleMutationResult(result, options);
        if (options.returnResponse === true) {
            return result;
        } else {
            const innerResponseData = getInnerResponseData(result);
            return innerResponseData?.id ? innerResponseData.id : wasSuccessful;
        }
    } catch (err) {
        displayUnknownError(options, err);
        return false;
    }
};

// returns success (Boolean)
export const handleMutations = async (mutationArray, options = {}) => {
    try {
        const results = await Promise.all(mutationArray);
        const optionsToUse = {...options, showSuccess: false, successMessage: ""};
        const allSuccessful = results.every(result => handleMutationResult(result, optionsToUse));
        if (allSuccessful && options.showSuccess) {
            const message = isValidString(options.successMessage) ? options.successMessage : genericSuccessMessage;
            displaySuccess(message);
        }
        return allSuccessful;
    } catch (err) {
        displayUnknownError(options, err);
        return false;
    }
};

export const handleQueryResponse = (response, options = {}) => {
    // return false if errors, return true if no errors :: 

    const defaults = { ...DEFAULT_OPTIONS };

    if (!response || typeof response !== "object") {
        console.error("Invalid response passed to ErrorHandler.handleQueryResponse. Displaying generic error message.");
        displayGenericError();
        return false;
    } 
    
    if (response.loading) {
        return true;
    } 
    
    if (!wasHandled(response)) {
        setLastHandled(response);
        if (response.error) {
            const optionsToUse = Object.assign({}, defaults, options);
            handleGraphQLError(response.error, optionsToUse);
            return false;
        } else {
            return true;
        }
    }

    return response?.error ? false : true;
};

const isValidObject = (obj) => {
    return obj && typeof obj === "object" && Object.keys(obj).length > 0;
};

const differs = (value1, value2, key, options) => {
    const { trim, ignoreCase } = options;
    if (typeof value1 !== "string" && typeof value2 !== "string" || (!trim && !ignoreCase)) {
        return value1 !== value2;
    } else {
        const shouldTrim = trim === true || trim?.includes?.(key);
        const shouldLower = ignoreCase === true || ignoreCase?.includes?.(key);
        if (shouldTrim && shouldLower) {
            return value1.trim().toLowerCase() !== value2.trim().toLowerCase();
        } else if (shouldTrim) {
            return value1.trim() !== value2.trim();
        } else if (shouldLower) {
            return value1.toLowerCase() !== value2.toLowerCase();
        } else {
            return value1 !== value2;
        }
    }
};

export const getGenuineModifications = (modifications, original, options = {}) => {
    const { limitTo, trim, noMessage } = options;
    if (!isValidObject(modifications)) {
        return null;
    } else if (!isValidObject(original)) {
        return modifications;
    } else {
        const genuineModifications = {};
        const toCheck = Array.isArray(limitTo) ? limitTo.filter(key => modifications[key] !== undefined) : Object.keys(modifications);
        toCheck.forEach(key => {
            const newValue = modifications[key];
            const originalValue = original[key];
            const shouldTrim = typeof newValue === "string" && (trim === true || (Array.isArray(trim) && trim.includes(key)));
            if (isUndefinedOrNull(originalValue) || differs(newValue, originalValue, key, options)) {
                genuineModifications[key] = shouldTrim ? newValue.trim() : newValue;
            } 
        });
        if (!isValidObject(genuineModifications)) {
            if (!noMessage) {
                notify.error(invalidModificationsMessage);
            }
            return null;
        } else {
            return genuineModifications;
        }
    }
};

export const displayGenericError = (objOrTitle) => {
    const title = objOrTitle && typeof objOrTitle === "object" ? objOrTitle : objOrTitle;
    Modal.error({
        title: typeof title === "string" ? title : genericErrorTitle,
        content: getErrorMessage()
    });
};


// ++++++++++++++++++++++++++++++++++++++++++
// HELPER FUNCTIONS (NOT EXPORTED) 
// 

const setLastHandled = (errorOrResponse) => {
    if (errorOrResponse && typeof "error" === "object") {
        lastErrorOrResponse = errorOrResponse;
    }
};

const wasHandled = (errorOrResponse) => lastErrorOrResponse === errorOrResponse;

const getInnerResponseData = (result) => {
    try {
        const { data } = result;
        const key = Object.keys(data)[0];
        return data[key];
    } catch (err) {
        console.error(err);
        return ({});
    }
};


const displayUnknownError = ({ unknownErrorMessage, unknownErrorTitle }, err) => { 
    console.error(err);
    if (wasHandled(err)) {
        setLastHandled(err);
        const title = isValidString(unknownErrorTitle) ? unknownErrorTitle : genericErrorTitle;
        const message = isValidString(unknownErrorMessage) ? unknownErrorMessage : genericErrorMessage;
        Modal.error({
            title,
            content: message
        });
    }
};


const getErrorMessage = (status) => {
    if (status && typeof status === "string") { 
        return status;
    } else {
        return genericErrorMessage;
    }
};

const displaySuccess = (message, messageFromServer) => {
    const messageToUse = message ? message : messageFromServer;
    notify.success(messageToUse);
};

const displayError = (errorData, options) => {
    const { before, after, title, message, status } = options;
    const statusToUse = status ? status : errorData?.status;
    const fullMessage = message ? message : constructMessage(before, after, statusToUse);
    const fullTitle = title ? title : genericErrorTitle;
    Modal.error({
        title: fullTitle,
        content: fullMessage
    });
};

const constructMessage = (before, after, statusToUse) => {
    const message = getErrorMessage(statusToUse);
    return `${before ?? ""} ${message} ${after ?? ""}`.trim();
};

const handlePossibleError = (data, options) => {

    // handle possible errors in an attempted mutation's response that was passed into "handleMutationResult":
    const { showSuccess, showError } = options;
    const success = data?.success;
    const status = data?.status;
    const wasError = success === false;

    if (wasError && showError) {
        displayError(data, options);
    } 
    
    if (!wasError && showSuccess) {
        displaySuccess(options.successMessage, status);
    }

    // return TRUE if there were no errors detected, FALSE if there were :: 
    return !wasError;
};
