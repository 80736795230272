import React, { useState } from "react";
import moment from "moment";
import {
    Button,
    Input,
    List
} from "antd";
import { Comment } from "@ant-design/compatible";
import { useMutation } from "@apollo/client";
import { toSvg } from "jdenticon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { CaretUpFilled, CaretDownFilled } from "@ant-design/icons";
import { CREATE_COMMENT, MODIFY_COMMENT, MODIFY_REACTIONS, MODIFY_TIMELINE_COMMENT } from "../../../constants/mutations.js";
import {formatTimeValue, isASCII, isBlank, isUndefinedOrNull} from "../../../utils/index.js";
import { handleMutation } from "../../../utils/errorHandling.js";
import { ISSUE_REACTION, ISSUE_STATUS_LIST } from "../../../constants/index.js";

const { TextArea } = Input;

const correctionNoteStyle = {
    borderLeft: "2px solid blue",
    marginBottom: "10px"
};

const disagreementNoteStyle = {
    borderLeft: "2px solid red",
    marginBottom: "10px"
};

const correctionNoteMessage = (commentVersionId, submissionVersions = []) => {
    const foundVersion = submissionVersions.find(version => version?.id === commentVersionId); 
    const specifier = foundVersion?.specifier;
    const versionNumber = typeof specifier === "number" ? specifier + 1 : "";
    const message = versionNumber ? `for Version ${versionNumber}` : "";
    return (
        <span style={{ color: "blue" }}> Correction Note <span style={{ color: "black" }}>{message} - </span> </span>
    );
};

const disagreementNoteMessage = () => {
    return (
        <span style={{ color: "red"}}> Disagreement <span style={{ color: "black"}}> - </span> </span>
    );
};

const rejectedNoteMessage = () => {
    return (
        <span style={{ color: "red" }}> Rejected <span style={{ color: "black" }}> - </span> </span>
    );
};

const timelineExtensionNoteMessage = (comment) => {
    const additionalMessage = comment.previousDueDate && comment.updatedDueDate ? `due date changed from ${moment(comment.previousDueDate).format("MM/DD/YYYY")} to ${moment(comment.updatedDueDate).format("MM/DD/YYYY")}` : "";
    return additionalMessage && (
        <>
            <span style={{ color: "green" }}> Schedule Change Note <span style={{ color: "black" }}> - {additionalMessage ? additionalMessage : ""} </span> </span>
            <br />
        </>
    ); 
};

export const CommentsList = ({
    title = "Comments",
    parent,
    showTitleWithoutComments = false, 
    userId,
    refetch,
    forTimeline = false,
    filterForDueDateComments = false,
    isUserSubmitter = false,
    disableEditing = false,
    disableCreating = false, 
    latestSubmissionVersionId,
    submissionVersions
}) => {
    const [modifyComment] = useMutation(MODIFY_COMMENT);
    const [createComment] = useMutation(CREATE_COMMENT);
    const [modifyReactions] = useMutation(MODIFY_REACTIONS);
    const [modifyTimelineComment] = useMutation(MODIFY_TIMELINE_COMMENT);

    const [newestLast, setNewestLast] = useState(true);
    const [modified, setModified] = useState({});
    const [showPlainTextError, setPlainTextError] = useState(false); 

    const commentEditBox = (comment) => {
        return (
            <div>
                <TextArea
                    rows={4}
                    key={`edit-comment-${comment.id}`}
                    value={modified.editedCommentContent}
                    onChange={(e) => {
                        editCommentCheck(e.target.value);
                    }}
                />
                { showPlainTextError ? <PlainTextError /> : "" }
                <Button
                    type="primary"
                    className="spaceAbove-xs"
                    htmlType="submit"
                    disabled={isUndefinedOrNull(modified.editedCommentContent) || isBlank(modified.editedCommentContent)}
                    onClick={async () => {

                        let success = false;

                        if (comment.isDisagreementNote) {
                            const newReactions = [{
                                issueId: parent.id,
                                reviewerId: comment.authorId,
                                comment: modified.editedCommentContent
                            }];

                            success = await handleMutation(
                                modifyReactions({
                                    variables: {
                                        newReactions
                                    }
                                })
                            );

                        } else {
                            const newComment = {
                                content: modified.editedCommentContent
                            };

                            if (forTimeline) {
                                success = await handleMutation(
                                    modifyTimelineComment({
                                        variables: {
                                            id: comment.id, 
                                            content: modified.editedCommentContent 
                                        }
                                    })
                                );
                            } else {
                                success = await handleMutation(
                                    modifyComment({
                                        variables: {
                                            id: comment.id,
                                            newComment
                                        }
                                    })
                                );
                            }
                        }

                        if (success) {
                            setModified({});
                        }

                        refetch();
                    }}
                >
                    Update Comment
                </Button>
                
                <Button
                    onClick={() => {
                        setModified({
                            ...modified,
                            editedCommentContent: undefined,
                            editingComment: undefined
                        });

                    }}
                    style={{marginLeft: "10px"}}
                >
                    Cancel
                </Button>
            </div>
        );
    };

    const addCommentCheck = str => {
        if (isASCII(str) === true){
            setModified({
                ...modified,
                commentContent: str
            });
            setPlainTextError(false);
        } else {
            setPlainTextError(true);
        } 
    };

    const editCommentCheck = str => {
        if (isASCII(str) === true){
            setModified({
                ...modified,
                editedCommentContent: str
            });
            setPlainTextError(false);
        } else {
            setPlainTextError(true);
        } 
    };
    
    const PlainTextError = () => (
        <div className="errorMessage">Plain text only</div>
    );

    const disagreeComments = isUserSubmitter || !Array.isArray(parent?.reactions) ? [] : parent.reactions.map((reaction, index) => {
        if (reaction.comment) {
            if (reaction.disposition === ISSUE_REACTION.disagree) {
                return {
                    id: `${reaction.issueId}-${reaction?.reviewer?.id ?? `disagreement-comment-${index}`}}`,
                    author: reaction.reviewer,
                    authorId: reaction?.reviewer?.id,
                    isDisagreementNote: true,
                    isCorrectionNote: false,
                    createdAt: reaction.createdAt,
                    parentId: reaction.issueId, 
                    readyForReview: false,
                    content: reaction.comment 
                };
            }
        } 
    }).filter(comment => comment); 

    const rejectComments = isUserSubmitter || !Array.isArray(parent?.reactions) ? [] : parent.reactions.map((reaction, index) => {
        if (reaction.comment) {
            if (reaction.disposition === ISSUE_STATUS_LIST.reject) {
                return {
                    id: `${reaction.issueId}-${reaction?.reviewer?.id ?? `reject-comment-${index}`}}`,
                    author: reaction.reviewer,
                    authorId: reaction?.reviewer?.id,
                    isRejectedNote: true,
                    isCorrectionNote: false,
                    createdAt: reaction.createdAt,
                    parentId: reaction.issueId, 
                    readyForReview: false,
                    content: reaction.comment 
                };
            }
        } 
    }).filter(comment => comment); 

    let reviewComments = !Array.isArray(parent?.comments) ? [] : parent.comments.filter(comment => {
        if (isUserSubmitter) { 
            return comment.isCorrectionNote || comment.readyForReview;
        } else {
            return true;
        }
    });

    if (filterForDueDateComments){
        const filteredReviewComments = reviewComments.filter(comment=> comment.previousDueDate !== null);
        reviewComments = filteredReviewComments;
    }

    const allComments = [...disagreeComments, ...reviewComments, ...rejectComments];
    
    const enableSorting = allComments.some(comment => !isUndefinedOrNull(comment?.createdAt));

    if (enableSorting) {
        allComments.sort((a, b) => {
            if (!a.createdAt || !b.createdAt) {
                return 0;
            } else if (newestLast) {
                return a.createdAt - b.createdAt;
            } else {
                return b.createdAt - a.createdAt;
            }
        });
    } 

    const shouldRenderTitle = allComments.length > 0 || showTitleWithoutComments;

    return (
        <>
            {shouldRenderTitle && 
                <div> 
                    <div> 
                        <div style={{fontSize: "18px", marginTop: "10px"}}> 
                            <span>{title}</span> 
                        </div>
                        { enableSorting 
                            ? <Button 
                                style={{
                                    boxShadow: "none",
                                    border: "none"
                                }}
                                type="ghost"
                                onClick={(evt) => {
                                    evt.preventDefault();
                                    setNewestLast(!newestLast);
                                }}
                                icon={ newestLast ? <CaretUpFilled /> : <CaretDownFilled /> }
                            > 
                                {newestLast ? "Newest Last" : "Newest First"} 
                            </Button> 
                            : null 
                        }
                    </div>
                    { allComments.length > 0 && <List
                        className="comment-list"
                        itemLayout="horizontal"
                        dataSource={allComments}
                        renderItem={(comment) => {
                            
                            let styleObj = {};
                            if (comment.isCorrectionNote) {
                                styleObj = correctionNoteStyle;
                            } else if (comment.isDisagreementNote || comment.isRejectedNote) {
                                styleObj = disagreementNoteStyle;
                            }

                            return (
                                <li style={styleObj}>
                                    <Comment
                                        author={
                                            <span>{comment?.author?.name} ({comment.author?.organizations?.[0]?.name})</span>
                                        }
                                        avatar={
                                            <div
                                                style={{
                                                    display: "inline-flex",
                                                    marginRight: "8px",
                                                    backgroundColor: "#fff",
                                                    borderRadius: "4px"
                                                }}
                                                dangerouslySetInnerHTML={{
                                                    __html: toSvg(comment.author?.email, 50)
                                                }}
                                            />
                                        }
                                        content={
                                            <>
                                                {comment.createdAt ? <div> Created : {formatTimeValue(comment.createdAt, true)}</div> : <></>}
                                                {comment.modifiedAt ? <div> Last Modified : {formatTimeValue(comment.modifiedAt, true)} </div> : <></>}
                                                {comment.author?.id === userId ?
                                                    (modified.editingComment === comment.id
                                                        ? commentEditBox(comment)
                                                        : <>
                                                            {!disableEditing && <FontAwesomeIcon
                                                                className="iconEdit"
                                                                icon={faEdit}
                                                                color="black"
                                                                onClick={() => {
                                                                    if (!disableEditing) {
                                                                        setModified({
                                                                            ...modified,
                                                                            editedCommentContent: comment.content,
                                                                            editingComment: comment.id
                                                                        });
                                                                    }
                                                                }}
                                                            />}
                                                            {comment.isCorrectionNote ? correctionNoteMessage(comment?.submissionVersionId, submissionVersions) : null}
                                                            {comment.isDisagreementNote ? disagreementNoteMessage() : null}
                                                            {comment.isRejectedNote ? rejectedNoteMessage() : null}
                                                            {timelineExtensionNoteMessage(comment)}
                                                            <span> {comment.content}</span>
                                                        </>
                                                    ) : <>
                                                        {comment.isCorrectionNote ? correctionNoteMessage(comment?.submissionVersionId, submissionVersions) : null}
                                                        {comment.isDisagreementNote ? disagreementNoteMessage() : null}
                                                        {timelineExtensionNoteMessage(comment)}
                                                        <span> {comment.content} </span>
                                                    </>
                                                }
                                            </>
                                        }
                                    />
                                </li>
                            );
                        }}
                    /> }
                </div>
            }
            {!disableEditing && !isUserSubmitter && !disableCreating &&
                <Comment
                    avatar={
                        <div
                            style={{
                                display: "inline-flex",
                                marginRight: "8px",
                                backgroundColor: "#fff",
                                borderRadius: "4px"
                            }}
                        />
                    }
                    content={
                        <>
                            {!filterForDueDateComments && (<div>

                                <TextArea
                                    rows={2}
                                    key="new-comment"
                                    value={modified.commentContent}
                                    onChange={(e) => {
                                        addCommentCheck(e.target.value);
                                    }}
                                />
                                {showPlainTextError ? <PlainTextError /> : ""}
                                <Button
                                    type="primary"
                                    className="spaceAbove-xs"
                                    htmlType="submit"
                                    disabled={isUndefinedOrNull(modified.commentContent) || isBlank(modified.commentContent)}
                                    onClick={async () => {
                                        const newComment = {
                                            content: modified.commentContent,
                                            parentId: parent.id,
                                            readyForReview: isUserSubmitter,
                                            submissionVersionId: latestSubmissionVersionId
                                        };

                                        const success = await handleMutation(
                                            createComment({
                                                variables: {
                                                    newComment
                                                }
                                            })
                                        );

                                        if (success) {
                                            setModified({});
                                        }
                                        refetch();
                                    }}
                                >
                                    Add Comment
                                </Button>
                            </div>)
                            }
                        </>
                    }
                />
            }  
        </>
    );
};
