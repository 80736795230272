import React, { useState, useContext } from "react";
import { useQuery } from "@apollo/client";
import { Button, Form, message } from "antd";
import { useParams } from "react-router-dom";
import { UserContext } from "../Application/UserContext.js";
import { useAttemptToModifyOrganization } from "../../utils/attemptMutationHooks.js";
import { getGenuineModifications, handleGraphQLError } from "../../utils/errorHandling.js";
import { isUndefinedOrNull } from "../../utils/index.js";
import { EditableDetail } from "../common/EditableDetail/index.js";
import { LoadingContent } from "../common/LoadingContent/index.js";
import { organizationsDetailQuery } from "./query.js";
import { Main } from "../common/Main/index.js";
import { NoContent } from "../common/NoContent/index.js";
import { PCDULink } from "../common/PCDULink/index.js";
import { usePreloadedData } from "../Application/PreloadedData/index.js";
import { PrimaryContactTable } from "./PrimaryContactTable.js";
import { MembersTable } from "./MembersList.js";
import { OrganizationContractsTable } from "./OrganizationContractsTable.js";


export const OrganizationDetail = () => {
    const params = useParams();
    const { preloadedData } = usePreloadedData();
    const [modified, setModified] = useState({});
    const [attemptToModifyOrganization] = useAttemptToModifyOrganization();
    const [form] = Form.useForm();

    const {
        userPermEditOrganization,
        userPermViewContracts,
        userPermViewUserAuditLog
    } = useContext(UserContext);

    const { loading, error, data, refetch } = useQuery(organizationsDetailQuery, {
        variables: {
            id: params.id
        },
        fetchPolicy: "no-cache"
    });

    if (error) {
        return handleGraphQLError(error);
    }

    if (loading) {
        return <LoadingContent />;
    }

    if (isUndefinedOrNull(data.organization)) {
        return <NoContent />;
    }

    const { contractsList, usersList } = data;
    const { contractTypes, constants } = preloadedData;
    const invalidSpecifierCharacters = constants?.INVALID_SPECIFIER_CHARACTERS ?? [];
    const invalidSpecifierMessage = `The Service Center ID cannot contain these characters: ${invalidSpecifierCharacters.join(", ")}`;
    const validFirstPositionSpecifierCharacters = constants?.VALID_FIRST_POSITION_SPECIFIER_CHARACTERS ?? []; 
    const invalidFirstPositionMessage = "The Service Center ID must begin with a letter or a digit.";
    const organization = data.organization;

    const NEW_BREADCRUMB = {
        label: `${organization.specifier}`, 
        path: `/organizations/${organization.id}`, 
        tooltip: `Organization: ${organization.name} - ${organization.specifier}`
    };

    const activeOrgMembership = usersList.users.length > 0
        ? usersList.users.filter(user => user.active === true)
        : [];

    const changeDetected = typeof modified.name !== "undefined" || typeof modified.specifier !== "undefined" || typeof modified.email !== "undefined";

    const details = (
        <div id="details"> 
            { userPermViewUserAuditLog && organization.id  
                ? <div style={{margin: "10px 0px", fontSize: "16px"}}>
                    <PCDULink 
                        to={`/changelogs/organization/${organization.id}`}
                        newBreadcrumbs={NEW_BREADCRUMB}
                    > 
                        Changelogs 
                    </PCDULink>
                </div>
                : null 
            }
            <Form name="organizationDetails" form={form}>
                {[
                    {
                        title: "Name",
                        key: "name",
                        required: true
                    },
                    {
                        title: "Service Center ID",
                        key: "specifier",
                        required: true, 
                        additionalRules: [{
                            message: invalidSpecifierMessage,
                            validator: (_, specifier) => {
                                if (specifier && invalidSpecifierCharacters.some(char => specifier.includes(char))) {
                                    return Promise.reject(new Error(invalidSpecifierMessage));
                                } else {
                                    return Promise.resolve();
                                }
                            }
                        }, {
                            message: invalidFirstPositionMessage,
                            validator: (_, specifier) => {
                                if (specifier && validFirstPositionSpecifierCharacters.length > 0 && !validFirstPositionSpecifierCharacters.some(char => char === specifier[0])) {
                                    return Promise.reject(new Error(invalidFirstPositionMessage));
                                } else {
                                    return Promise.resolve();
                                }
                            }
                        }]
                    },
                    {
                        title: "Email",
                        key: "email",
                        required: true,
                        additionalRules: [{type: "email", message: "Should be a valid email"}]
                    }
                ].map(({ title, key, required, additionalRules }) => {
                    return <div key={`editable-org-details-${key}`}>
                        <EditableDetail
                            title={title}
                            inputId={key}
                            required={required}
                            additionalRules={additionalRules}
                            readOnly={!userPermEditOrganization}
                            value={{ ...organization, ...modified }[key]}
                            onValueUpdated={(e) => {
                                setModified({
                                    ...modified,
                                    [key]: e.target.value
                                });
                            }}
                        />
                    </div>;
                })}

                <br />
                <Form.Item>
                    <Button
                        type="primary"
                        style={{
                            display: (userPermEditOrganization && changeDetected)
                                ? "inherit"
                                : "none"
                        }}
                        onClick={async () => {
                            try {
                                await form.validateFields();

                                const newOrganization = {
                                    name: modified.name,
                                    specifier: modified.specifier,
                                    email: modified.email
                                };
                                
                                const genuineModifications = getGenuineModifications(newOrganization, organization, {
                                    limitTo: ["specifier", "name", "email"],
                                    trim: true
                                });

                                if (genuineModifications) {
                                    const result = await attemptToModifyOrganization({
                                        variables: {
                                            id: organization.id,
                                            newOrganization: genuineModifications
                                        }
                                    });
                                    const success = result.success;
                                    if (success) {
                                        refetch().then(({ data }) => {
                                            const organization = data.organization;
                                            form.setFieldsValue({
                                                name: organization.name,
                                                specifier: organization.specifier,
                                                email: organization.email
                                            });
                                            setModified({});
                                        });
                                    }
                                } 
                            } catch {
                                message.error({
                                    content: "Please correct form errors and try again.",
                                    style: {
                                        marginTop: "10vh"
                                    }
                                });
                            }
                        }}
                    >
                        {"Save Changes"}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );

    return (
        <Main
            title={`Organization - ${organization.specifier}`}
            details={details}
            breadcrumbs={[
                <PCDULink to="/organizations" tooltip="All Organizations">Organizations</PCDULink>,
                organization.specifier
            ]}
        >
            <div>
                <MembersTable 
                    activeMembers={activeOrgMembership}
                    organization={organization}
                    refetch={refetch}
                    newBreadcrumb={NEW_BREADCRUMB}
                />
                <PrimaryContactTable 
                    activeMembers={activeOrgMembership}
                    organization={organization}
                    refetch={refetch}
                    canEdit={userPermEditOrganization}
                    newBreadcrumb={NEW_BREADCRUMB}
                />
                { userPermViewContracts 
                    ? <OrganizationContractsTable 
                        contracts={contractsList.contracts}
                        newBreadcrumb={NEW_BREADCRUMB}
                        contractTypes={contractTypes}
                    /> 
                    : null
                }
            </div>
        </Main>
    );
};