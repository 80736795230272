import React, { useState, useEffect } from "react";
import { Button, Table, Input, Modal } from "antd";
import { Main } from "../common/Main/index.js";
import { useQuery } from "@apollo/client";
import { restoreArchiveFile } from "../../utils/restoreArchiveFile.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder } from "@fortawesome/free-solid-svg-icons";
import { LoadingContent } from "../common/LoadingContent/index.js";
import { map, get } from "lodash";
import { ErrorMessage } from "../ErrorMessage/index.js";
import { handleGraphQLError } from "../../utils/errorHandling.js";
import { archiveFoldersQuery } from "./query.js";
import { DownloadButton } from "../common/DownloadButton/index.js";
import { PCDULink } from "../common/PCDULink/index.js";
import { DOCUMENT_UPLOAD_TYPES } from "../../constants/index.js";

const { Search } = Input;


export const Archive = () => {
    const [newData, setNewData] = useState([]);
    const [saveData, setSaveData] = useState([]);
    const [path, setPath] = useState("");
    const [wait, setWait] = useState();
    const [archiveFetchFailed, setArchiveFetchFailed] = useState(false);
    const [archiveFoldersToUse, setArchiveFoldersToUse] = useState(null);
    const [componentIsMounted, setComponentIsMounted] = useState(true);

    // when component unmounts, turn off async updates :: 
    useEffect(() => () => setComponentIsMounted(false), []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setWait(true);
                const response = await fetch(`/archive/list/?path=${path}`);
                const data = await response.json();
                const isGoodResponse = Boolean(data) && (response?.status === 201 || response?.status === 200);
                if (componentIsMounted) {
                    if (isGoodResponse) {
                        setArchiveFetchFailed(false);
                        setNewData(data);
                        setSaveData(data);
                    } else {
                        setArchiveFetchFailed(true);
                    }

                    setWait(false);
                }
            } catch (err) {
                if (componentIsMounted) {
                    setArchiveFetchFailed(true);
                    setWait(false);
                }
            }
        };
        fetchData();
    }, [path]);

    const { loading, data, error } = useQuery(archiveFoldersQuery, { fetchPolicy: "no-cache" });

    useEffect(() => {
        if (data && data?.archiveFolders && componentIsMounted) {
            setArchiveFoldersToUse(data.archiveFolders);
        }
    }, [data]);

    if (!archiveFoldersToUse) {
        return <LoadingContent />;
    }

    if (error) {
        return handleGraphQLError(error);
    }
    const retrivelInProgressModal = (contentLength) => {
        if (contentLength >= 500000) {
            Modal.info({
                title: "Retrieval is in Progress",

                content: (
                    <div>
                        <p>Please wait for 3-5 Hours for files for the retrieval to complete processing as the file is big. Then return to document to download the file.</p>
                    </div>
                )
            });
        }
        else {
            Modal.info({
                title: "Retrieval is in Progress",

                content: (
                    <div>
                        <p>Please wait for 5 minutes for files for the retrieval to complete processing as the file is big. Then return to document to download the file.</p>
                    </div>
                )
            });

        }
    };
    const columns = [
        {
            title: "FileName",
            key: "FileName",
            dataIndex: "FileName",
            render: (_text, record) => {
                return record.ID.split("/").pop();
            }
        },
        {
            title: "Actions",
            render: (_text, record) => (
                <div>
                    {(!record.Restore) ?
                        (
                            <Button
                                className="downloadLink"
                                type="primary"
                                style={{
                                    display: "block",
                                    marginBottom: "5px"
                                }}
                                onClick={() => restoreArchiveFile(record.ID.substring(0, record.ID.lastIndexOf("/")), record.ID.split("/").pop())}
                            >
                                Retrieve
                            </Button>
                        ) : ((record.Restore.match("ongoing-request=\"true\""))) ?
                            (<Button
                                className="downloadLink"
                                type="primary"
                                style={{
                                    display: "block",
                                    marginBottom: "5px"
                                }}

                                onClick={() => {
                                    retrivelInProgressModal(record.ContentLength);
                                }}
                            >

                                Retrieve In Progress
                            </Button>) :
                            (record.Restore || !record.StorageClass) ?
                                (
                                    <DownloadButton
                                        type={DOCUMENT_UPLOAD_TYPES.ARCHIVED}
                                        document={{
                                            path: record.ID.substring(0, record.ID.lastIndexOf("/")),
                                            id: record.ID.split("/").pop()
                                        }}
                                    />
                                ) : <></>
                    }


                </div>
            )
        }
    ];

    const archiveTable = archiveFetchFailed ? ErrorMessage() : (
        <Table
            style={{
                backgroundColor: "#fff"
            }}
            columns={columns}
            loading={wait}
            dataSource={newData}
            rowKey={obj => obj.ID}
        />
    );

    const folderTable = (
        <>
            <Table
                style={{
                    backgroundColor: "#fff"
                }}
                size="middle"
                pagination={false}
                columns={[
                    {
                        title: "Name",
                        dataIndex: "name",
                        render: (name, { id, folderPath }) => {
                            return (
                                <PCDULink
                                    key={id}
                                    to={{}}
                                    onClick={() => {
                                        setPath(folderPath);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faFolder} style={{ marginRight: "10px" }} className="far" />
                                    {name}
                                </PCDULink>
                            );
                        }
                    }
                ]}
                dataSource={archiveFoldersToUse}
                loading={loading}
                rowKey={obj => obj.id}
            />
        </>
    );

    const onSearch = value => {
        const reg = new RegExp(value, "gi");
        const filteredData = map(saveData, record => {
            const nameMatch = get(record, "ID").match(reg);
            if (!nameMatch) {
                return null;
            }
            return record;
        }).filter(record => !!record);
        if (value === "") {
            setNewData(saveData);
        } else {
            setNewData(value ? filteredData : newData);
        }
    };

    return (
        <Main title="All Final Deliverables"
            breadcrumbs={["Archived"]}
        >
            {folderTable}
            { archiveFetchFailed === false && 
                <Search loading={loading || wait} placeholder="Search FileName" allowClear onSearch={onSearch} style={{ width: 300 }} /> 
            }
            {archiveTable}
        </Main>
    );
};
