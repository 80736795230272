import React from "react";
import { Tag } from "antd";
import {
    CheckCircleOutlined,
    SyncOutlined,
    CloseCircleOutlined 
} from "@ant-design/icons";
import { S3_UPLOAD_STATUS } from "../../../constants/index.js";

const S3_STATUS_TEXT_TO_DISPLAY = { 
    [S3_UPLOAD_STATUS.uploading]: "Processing",
    [S3_UPLOAD_STATUS.uploaded]: "Success",
    [S3_UPLOAD_STATUS.error]: "Error",
    [S3_UPLOAD_STATUS.quarantine]: "Error"
};

const DEFAULT_STATUS_TEXT = "unknown";

export const getS3StatusText = (status) => status && typeof status === "string" 
    ? (S3_STATUS_TEXT_TO_DISPLAY[status] ?? DEFAULT_STATUS_TEXT)
    : DEFAULT_STATUS_TEXT;

export const S3UploadStatus = (S3UploadStatus) => {
    switch (S3UploadStatus) {
        case S3_UPLOAD_STATUS.uploading:
            return <Tag icon={<SyncOutlined spin />} color="processing">
                { getS3StatusText(S3UploadStatus) }
            </Tag>;
        case S3_UPLOAD_STATUS.uploaded:
            return <Tag icon={<CheckCircleOutlined />} color="success">
                { getS3StatusText(S3UploadStatus) }
            </Tag>;
        case S3_UPLOAD_STATUS.error:
        case S3_UPLOAD_STATUS.quarantine: 
            return <Tag icon={<CloseCircleOutlined />} color="error">
                { getS3StatusText(S3UploadStatus) }
            </Tag>;
        default:
            return <Tag icon={<CloseCircleOutlined />} color="default">
                { getS3StatusText(S3UploadStatus) }
            </Tag>;
    }
};
