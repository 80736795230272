import React, { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Form } from "antd";
import { UserContext } from "../Application/UserContext.js";
import { NoPermission } from "../common/NoPermission/index.js";
import { SelectableDetail } from "../common/SelectableDetail/index.js";
import { LoadingContent } from "../common/LoadingContent/index.js";
import { Main } from "../common/Main/index.js";
import { isUndefinedOrNull } from "../../utils/functions.js";
import { useAttemptToCreateContractTypeVersionFromLatest, useAttemptToCreateSubmissionType } from "../../utils/attemptMutationHooks.js";
import { getHistoryBreadcrumbs } from "../../utils/getHistoryBreadcrumbs.js";
import { handleGraphQLError } from "../../utils/errorHandling.js";
import { PCDULink } from "../common/PCDULink/index.js";
import { ScheduleDetails } from "./ScheduleDetails.js";
import {
    buildNewSubmissionType,
    SAVE_BUTTON_FIELDS,
    getSubmissionTypeSelectFields, 
    useCreateDataQuery, 
    validateSubmissionTypeSpecifier, 
    newSubmissionTypeRequiredFields, 
    getModifiedForCategory 
} from "./_helpers.js";
import { usePreloadedData } from "../Application/PreloadedData/index.js";
import { SubmissionTypeDetailFields } from "./SubmissionTypeDetailFields.js";


export const CreateSubmissionType = ({ categories }) => {
    const lastHistoryBreadcrumb = getHistoryBreadcrumbs(-1);
    const location = useLocation();
    const navigate = useNavigate();

    const { preloadedData } = usePreloadedData();

    const {         
        businessUnits,
        constants 
    } = preloadedData;
    
    const [modified, setModified] = useState({
        daysToReviewType: "CALENDAR",
        daysToReSubmitType: "CALENDAR"
    });

    const [attemptToCreateSubmissionType] = useAttemptToCreateSubmissionType();
    const [attemptToCreateContractTypeVersionFromLatest] = useAttemptToCreateContractTypeVersionFromLatest();
    const [addingDescription, setAddingDescription] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [fields, setFields] = useState(getSubmissionTypeSelectFields({ businessUnits, constants }));

    const {
        userPermEditSubmissionType,
        userPermCreateSubmissionType,
        userIsContractAdmin,
        userIsSystemAdmin 
    } = useContext(UserContext);

    const { data, loading, error } = useCreateDataQuery(userPermEditSubmissionType);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const contractTypeId = searchParams.get("contract_type");
        const categoryId = searchParams.get("categoryId");
        const defaultModifiedState = { 
            ...modified,
            contractTypeId: isUndefinedOrNull(contractTypeId) ? null : contractTypeId
        };

        if (!isUndefinedOrNull(categoryId)) {
            const category = categories.find(({ id }) => id === categoryId);
            setModified(getModifiedForCategory(category, defaultModifiedState));
            setFields(getSubmissionTypeSelectFields({ category, businessUnits, constants }));
        } else {
            setModified(defaultModifiedState);
        }
    }, []);

    if (!userPermCreateSubmissionType) {
        return <NoPermission />;
    }

    if (loading) {
        return <LoadingContent />;
    }

    if (error) {
        return handleGraphQLError(error);
    }

    const {
        contractTypes = [],
        submissionTypes
    } = data;

    if (!isUndefinedOrNull(modified.contractTypeId)) {
        const selectedContractType = contractTypes
            .find(({ id }) => id === modified.contractTypeId);

        if (isUndefinedOrNull(selectedContractType)) {
            setModified(() => ({
                ...modified,
                contractTypeId: null
            }));
        }
    }

    const saveButtonsVisible = userPermEditSubmissionType && Object.keys(modified).some(key => !isUndefinedOrNull(modified[key]) && SAVE_BUTTON_FIELDS[key]);
    const saveButtonsDisabled = newSubmissionTypeRequiredFields.some((key) => isUndefinedOrNull(modified[key]) || modified[key] === ""); 
    
    const details = (
        <Form
            layout="vertical"
            autoComplete="off"
        >
            <div id="details">
                <SelectableDetail
                    title="Contract Type"
                    passedKey="contract-type-selection"
                    options={Object.values(contractTypes).map((option) => {
                        return {
                            text: option.name,
                            value: option.id
                        };
                    })}
                    readOnly={!userPermEditSubmissionType}
                    value={modified["contractTypeId"]}
                    onValueUpdated={(value) => {
                        setModified({
                            ...modified,
                            contractTypeId: value
                        });
                    }}
                />

                <SubmissionTypeDetailFields
                    isEditable={true}
                    addingDescription={addingDescription}
                    setAddingDescription={setAddingDescription}
                    modified={modified}
                    setModified={setModified}
                    constants={constants}
                    categories={categories}
                    businessUnits={businessUnits}
                    otherFields={fields}
                    setFields={setFields}
                />

                <ScheduleDetails
                    creating={true}
                    contractTypes={contractTypes}
                    submissionType={{}}
                    frequencyOptions={constants.FREQUENCY_OPTIONS}
                    modified={modified}
                    setModified={setModified}
                    canEditSchedule={(userIsContractAdmin || userIsSystemAdmin)}
                    setToDisplay={() => ""}
                />
                <br />
                {saveButtonsVisible &&
                    <Button
                        type="primary"
                        disabled={saveButtonsDisabled || updating}
                        onClick={async () => {
                            const newSubmissionType = buildNewSubmissionType(modified, false);
                            const newSpecifier = newSubmissionType.specifier;
                            if (validateSubmissionTypeSpecifier(newSpecifier, submissionTypes, constants)) {
                                setUpdating(true);
                                const latestContractTypeVersionId = contractTypes
                                    .find(({ id }) => id === modified.contractTypeId)
                                    ?.contractTypeNewestVersion?.id;

                                const response = await attemptToCreateSubmissionType({
                                    variables: {
                                        newSubmissionType,
                                        contractTypeVersionId: latestContractTypeVersionId
                                    }
                                });

                                if (response.success) {
                                    const id = response.result.data.createSubmissionType.id;
                                    navigate(`/submission-types/${id}`);
                                }
                                setUpdating(false);
                            }
                        }}
                    >
                        Add To Current Plan
                    </Button>
                }
                {
                    saveButtonsVisible
                        ? <Button
                            type="secondary"
                            className="spaceBetween-sm"
                            disabled={saveButtonsDisabled}
                            onClick={async () => {
                                const newSubmissionType = buildNewSubmissionType(modified, false);
                                const newSpecifier = newSubmissionType.specifier;
                                if (validateSubmissionTypeSpecifier(newSpecifier, submissionTypes, constants)) {
                                    setUpdating(true);
                                    const {
                                        success: createContractTypeVersionSuccess,
                                        result
                                    } = await attemptToCreateContractTypeVersionFromLatest({
                                        variables: {
                                            contractTypeId: modified.contractTypeId,
                                            newSubmissionTypeIds: []
                                        }
                                    });

                                    if (createContractTypeVersionSuccess) {
                                        const response = await attemptToCreateSubmissionType({
                                            variables: {
                                                newSubmissionType,
                                                contractTypeVersionId: result.data.createContractTypeVersionFromLatest.id
                                            }
                                        });

                                        if (response.success) {
                                            const id = response.result.data.createSubmissionType.id;
                                            navigate(`/submission-types/${id}`);
                                        }
                                    }
                                    setUpdating(false);
                                }
                            }}
                        >
                            Create New Amendment
                        </Button>
                        : null
                }
            </div>
        </Form>
    );

    return (
        <Main
            title={"Create a Submission Type"}
            details={details}
            breadcrumbs={[
                lastHistoryBreadcrumb?.path === "/submission-types"
                    ? null
                    : <PCDULink
                        to="/submission-types"
                        tooltip="All Submission Types"
                    >
                        Submission Types
                    </PCDULink>
            ]}
        />
    );
};