import { gql } from "@apollo/client";

export const DocumentsTableQuery = gql`
    query DocumentsTableQuery (
        $id: ID!,
    ) {
        submission(id: $id) {
            id
            obligation {
                submissionType {
                    dqFlag
                    categoryName 
                }
            }
            submissionDocuments {
                id
                specifier
                nickname
                status
                S3UploadStatus 
                issues {
                    id
                    status
                }
                documentType {
                    id 
                    specifier
                    name
                }
                documentNewestVersion {
                    id
                    name
                    specifier
                    dqFlag
                    dqStatus
                    reportTemplateVersion
                }
            }
        }
    }
`;