import { gql } from "@apollo/client";

export const submissionTypesQuery = gql`
    query SubmissionTypesQuery (
        $limit: Int
        $offset: Int
        $nameSearch: String
        $specifierSearch: String
        $reviewerSearch: String
        $contractTypesFilter: [ID]
        $businessUnitFilter: [String]
        $categoriesFilter: [String]
        $displayDeleted: Boolean
        $sortOn: String
        $sortBy: String
    ) {
        submissionTypesList(
            limit: $limit
            offset: $offset
            nameSearch: $nameSearch
            specifierSearch: $specifierSearch
            reviewerSearch: $reviewerSearch
            contractTypesFilter: $contractTypesFilter
            businessUnitFilter: $businessUnitFilter
            categoriesFilter: $categoriesFilter
            displayDeleted: $displayDeleted
            sortOn: $sortOn
            sortBy: $sortBy
        ) {
            submissionTypes {
                id
                name
                specifier
                businessUnit {
                    id
                    name
                }
                categoryName
                contractType {
                    id
                    name
                }
                reviewerAssignments {
                    id
                    role {
                        id
                        specifier
                    }
                    assignee {
                        __typename
                        ... on User {
                            id
                            name
                            email
                        }
                    }
                }
            }
            count
        }
    }
`;