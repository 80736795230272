import React, { useState, useContext } from "react";
import { useMutation } from "@apollo/client";
import { Button, Modal } from "antd";
import { UserContext } from "../Application/UserContext.js";
import { 
    ROLE_DISPLAY_CONVERSION, 
    SUBMITTER, 
    SUBMITTER_ADDITIONAL_ROLE 
} from "../../constants/index.js";
import { MODIFY_USER } from "../../constants/mutations.js";
import {handleMutation } from "../../utils/errorHandling.js";
import { isValidString, searchable } from "../../utils/index.js";
import { Table } from "../common/Table/index.js";
import { FilterIcon } from "../common/FilterIcon/index.js";
import { PCDULink } from "../common/PCDULink/index.js";

const { confirm } = Modal;

const filterUsers = (users, { username, email }) => {
    if (Array.isArray(users) && users.length > 0) { 
        return users.filter(user => {
            if (
                isValidString(username) && 
                isValidString(user.name) && 
                !user.name.toLowerCase().includes(username.toLowerCase())
            ) {
                return false;
            }

            if (
                isValidString(email) && 
                isValidString(user.email) && 
                !user.email.toLowerCase().includes(email.toLowerCase())
            ) {
                return false; 
            } 

            return true;
        });
    } else {
        return [];
    }
};


export const MembersTable = ({ 
    activeMembers, 
    organization, 
    refetch, 
    newBreadcrumb 
}) => {
    const [modifyUser] = useMutation(MODIFY_USER); 
    const [usernameSearch, setUsernameSearch] = useState(""); 
    const [emailSearch, setEmailSearch] = useState(""); 

    const { userPermEditUsers } = useContext(UserContext);

    const orgHasSubmitters = activeMembers.some((user) => user.roles.some(role => role.specifier === SUBMITTER));
 
    const roleFilterOptions = activeMembers.reduce((arr, membership) => {
        const roles = membership?.roles ?? [];
        roles.forEach(({ specifier }) => {
            if (!arr.includes(specifier)) {
                arr.push(specifier);
            }
        });
        return arr;
    }, []).map(specifier => ({ 
        text: ROLE_DISPLAY_CONVERSION[specifier],
        value: specifier 
    }));

    const showConfirmUser = (id, name) => {
        confirm({
            title: `Are you sure you want to Deactivate ${name} ${id === organization.primaryContact?.id ? "(Primary Contact)" : ""} ?`,
            content: `${id === organization.primaryContact?.id ? "Note: this user will be removed from Primary Contact list too" : ""} `,
            okText: "Yes",
            async onOk() {
                const mutation = modifyUser({
                    variables: {
                        id: id,
                        newUser: { active: false }
                    }
                });

                await handleMutation(mutation, {
                    showSuccess: true
                });

                refetch();
            }
        });
    };

    return (
        <>
            <h2>Users List</h2>
            <Table
                id="affiliatedUsersTable"
                columns={[
                    searchable({
                        title: "Name",
                        key: "name", 
                        dataIndex: "name",
                        sorter: (a, b) => a.name.localeCompare(b.name),
                        sortDirections: ["ascend", "descend", "ascend"],
                        render: (name, { id }) => {
                            return <PCDULink 
                                to={`/users/${id}`}
                                newBreadcrumbs={newBreadcrumb}
                            >
                                {name}
                            </PCDULink>;
                        },
                        searchedText: usernameSearch,
                        handleSearch: (usernameSearchText) => setUsernameSearch(usernameSearchText),
                        handleReset: () => setUsernameSearch("")
                    }),
                    searchable({
                        title: "Email",
                        key: "email",
                        dataIndex: "email",
                        sorter: (a, b) => {
                            if (a.email !== null && b.email !== null) {
                                return a.email.localeCompare(b.email);
                            } else if (a.email === null && b.email !== null){
                                return 1;
                            } else if (b.email === null && a.email !== null){
                                return -1;
                            }
                        },
                        sortDirections: ["ascend", "descend", "ascend"],
                        searchedText: emailSearch,
                        handleSearch: (emailSearchText) => setEmailSearch(emailSearchText),
                        handleReset: () => setEmailSearch("")
                    }),
                    {
                        title: "Role",
                        key: "role",
                        dataIndex: "roles",
                        render: (roles) => {
                            return roles
                                .map((role) => ROLE_DISPLAY_CONVERSION[role.specifier])
                                .join(", ");
                        }, 
                        sorter: (a, b) => {
                            const firstRoleString = a?.roles
                                ?.map?.(role => ROLE_DISPLAY_CONVERSION[role.specifier])
                                ?.join?.(", ") ?? ""; 
                            
                            const secondRoleString = b?.roles 
                                ?.map?.(role => ROLE_DISPLAY_CONVERSION[role.specifier])
                                ?.join?.(", ") ?? ""; 

                            if (firstRoleString && secondRoleString) {
                                return firstRoleString.localeCompare(secondRoleString);
                            } else if (!firstRoleString && secondRoleString){
                                return 1;
                            } else if (firstRoleString && !secondRoleString){
                                return -1;
                            }
                        },
                        sortDirections: ["ascend", "descend", "ascend"],
                        filters: roleFilterOptions,
                        onFilter: (selectedRole, { roles }) => {
                            return roles.some(role => role.specifier === selectedRole);
                        },
                        filterIcon: FilterIcon 
                    },
                    orgHasSubmitters ? {
                        title: "Attestation Level",
                        key: "attestationLevel",
                        dataIndex: "attestationRoleName",
                        render: (attestationRoleName) => { 
                            return ROLE_DISPLAY_CONVERSION[attestationRoleName];
                        }, 
                        filters: Object.values(SUBMITTER_ADDITIONAL_ROLE).map(submitterRole => {
                            return {
                                text: ROLE_DISPLAY_CONVERSION[submitterRole],
                                value: submitterRole
                            };
                        }),
                        sortDirections: ["ascend", "descend", "ascend"], 
                        onFilter: (value, record) => (record.attestationRoleName === value), 
                        filterIcon: FilterIcon 
                    } : null,
                    userPermEditUsers ? {
                        title: "Actions",
                        key: "actions",
                        width: "10%",
                        render: ({ id, name }) => {
                            return (
                                <Button
                                    type="danger"
                                    size="small"
                                    onClick={() => {
                                        showConfirmUser(id, name);
                                    }}
                                >
                                    Remove
                                </Button>
                            );
                        }
                    } : null
                ].filter(Boolean)}
                rowKey="id"
                dataSource={filterUsers(activeMembers, { 
                    username: usernameSearch, 
                    email: emailSearch 
                })}
                pagination={true}
            />
        </>
    );
};