import { useQuery } from "@apollo/client";
import { Pagination, Radio } from "antd";
import { Table } from "../common/Table/index.js";
import { ClearFiltersButton } from "../common/ClearFiltersButton/index.js";
import React, { useEffect, useState } from "react";
import { Main } from "../common/Main/index.js";
import { formatTimeValue } from "../../utils/functions.js";
import { dateable, searchable } from "../../utils/index.js";
import { LoadingContent } from "../common/LoadingContent/index.js";
import { PCDULink } from "../common/PCDULink/index.js";
import { userLogQuery } from "./query.js";
import { handleGraphQLError } from "../../utils/errorHandling.js";
import { WebLogCsvExport } from "./export.js";
import { handleControlledDefaultSortOrder } from "../../utils/handleControlledDefaultSortOrder.js";
import { PersistentState } from "../../utils/PersistentState.js";

const NEW_BREADCRUMB = {
    label: "User Audit Logs",
    path: "/web-logs"
};

const { usePersistentState } = PersistentState();

export const WebLog = () => {
    const defaultSortOn = "userNcid";
    const defaultSortBy = "ascend";

    // persistent state 
    const [search, setSearch] = usePersistentState("");
    const [activityDateInput, setActivityDateInput] = usePersistentState([]);
    const [dateFilter, setDateFilter] = usePersistentState([]);
    const [activeFilter, setActiveFilter] = usePersistentState(undefined);
    const [sortOn, setSortOn] = usePersistentState(defaultSortOn);
    const [sortBy, setSortBy] = usePersistentState(defaultSortBy);
    const [webLogTablePage, setWebLogTablePage] = usePersistentState(1);
    const [webLogTablePageSize, setWebLogTablePageSize] = usePersistentState(10);
    const [dataToUse, setDataToUse] = useState(null);

    const clearOut = () => {
        setWebLogTablePage(1);
        setWebLogTablePageSize(10);
        setSortOn(defaultSortOn);
        setSortBy(defaultSortBy);
        setSearch("");
        setActivityDateInput([]);
        setDateFilter([]);
        setActiveFilter(undefined);
    };

    const queryVariables = {
        offset: (webLogTablePage - 1) * webLogTablePageSize,
        limit: webLogTablePageSize,
        activeFilter,
        dateFilter, 
        userNcid: search, 
        sortOn, 
        sortBy 
    };

    const { loading, error, data } = useQuery(userLogQuery, {
        variables: queryVariables,
        fetchPolicy: "no-cache"
    });

    useEffect(() => {
        if (data) {
            setDataToUse(data);
        }
    }, [data]);

    if (error) {
        return handleGraphQLError(error);
    }

    if (!dataToUse) {
        return <LoadingContent />;
    }

    const { userLogs } = dataToUse.userLogList; 

    const userlogsCount = userLogs?.[0]?.count ?? 0;

    const rightAlignedContent = <Radio.Group
        defaultValue={activeFilter}
        value={activeFilter}
        onChange={(e) => {
            setActiveFilter(e.target.value);
            setWebLogTablePage(1);
        }}
        buttonStyle="solid"
    >
        <Radio.Button value={undefined} > All </Radio.Button>
        <Radio.Button value={true} >Active </Radio.Button>
        <Radio.Button value={false}> Inactive </Radio.Button>
    </Radio.Group>;

    const webLogTable = (
        <Table
            rightAlignedButtonBarElement={rightAlignedContent}
            clearFiltersButton={<ClearFiltersButton 
                clearFilters={clearOut} 
                filtersData={[
                    { currentValue: search, defaultValue: "" },
                    { currentValue: dateFilter, defaultValue: [] },
                    { currentValue: activeFilter, defaultValue: undefined } 
                ]}
            />}
            exportButton={<WebLogCsvExport variables={queryVariables} />}
            style={{
                backgroundColor: "#fff"
            }}
            size="middle"
            pagination={false}
            columns={handleControlledDefaultSortOrder({ sortOn, sortBy }, [
                searchable({
                    title: "User NCID",
                    dataIndex: "userNcid",
                    handleSearch: setSearch,
                    searchedText: search,
                    setPage: setWebLogTablePage,
                    key: "id",
                    width: "20%",
                    sorter: true,
                    sortDirections: ["ascend", "descend", "ascend"],
                    render: (ncid, { userId }) => {
                        return (
                            <>
                                <PCDULink 
                                    to={`/users/${userId}`}
                                    newBreadcrumbs={NEW_BREADCRUMB}
                                >
                                    {ncid}
                                </PCDULink> 
                                <div>
                                    <span>(</span>
                                    <PCDULink 
                                        to={`/changelogs/acting-user/${userId}`}
                                        newBreadcrumbs={NEW_BREADCRUMB}
                                    > 
                                        User Activity 
                                    </PCDULink>
                                    <span>)</span>
                                </div>
                            </>
                        );
                    }
                }),
                {
                    title: "Activity Type",
                    dataIndex: "action",
                    sorter: true,
                    sortDirections: ["ascend", "descend", "ascend"],
                    width: "10%"
                },
                {
                    title: "Browser Name",
                    dataIndex: "browserName",
                    searchFilters: true,
                    width: "20%",                   
                    sorter: true,
                    sortDirections: ["ascend", "descend", "ascend"]                   
                },
                dateable({
                    title: "Activity Date",
                    dataIndex: "activityDate",
                    width: "15%",
                    filter: activityDateInput,
                    setFilter: (filter) => {
                        setActivityDateInput(filter);
                        // need to format differently for backend :: 
                        const isoDateFilter = filter.map(m => m.toISOString());
                        setDateFilter(isoDateFilter);
                    },
                    setPage: setWebLogTablePage,
                    render: (timeStamp) => {
                        const parseTimeStamp = parseInt(timeStamp);                        
                        return formatTimeValue(parseTimeStamp, true);
                    },
                    sorter: true,
                    sortDirections: ["ascend", "descend", "ascend"]
                }),
                {
                    title: "IP Address",
                    dataIndex: "iPAddress",
                    searchFilters: true,
                    sorter: true,
                    sortDirections: ["ascend", "descend", "ascend"],
                    width: "10%"
                }
            ])}
            dataSource={userLogs}
            loading={loading}
            rowKey="id"
            onChange={(_, __, { field, order }) => {
                setSortOn(field);
                setSortBy(order);
            }}
        />
    );

    const webLogTablePagination = (
        <Pagination
            style={{
                display: "flex",
                justifyContent: "center",
                padding: "20px 0 50px 0"
            }}
            showSizeChanger
            onShowSizeChange={(targetPage, pageSize) => {
                setWebLogTablePage(targetPage);
                setWebLogTablePageSize(pageSize);
            }}
            onChange={(targetPage, pageSize) => {
                setWebLogTablePage(targetPage);
                setWebLogTablePageSize(pageSize);
            }}
            current = {webLogTablePage}
            pageSize = {webLogTablePageSize}
            pageSizeOptions={["10", "25", "50", "100"]}
            total={userlogsCount}
        />
    );

    return (
        <Main
            title="User Audit Logs"
            breadcrumbs={["User Audit Logs"]}
        >
            <div style={{ marginTop: "20px" }}>
                {webLogTable}
                {webLogTablePagination}
            </div>
        </Main>
    );
};