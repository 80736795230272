import React from "react";
import { Main } from "../common/Main/index.js";
import { NotificationSettingsLink } from "../common/NotificationSettingsLink/index.js";
import { LibraryNotifications } from "./LibraryNotifications/index.js";
import { SubmissionNotifications } from "./SubmissionNotifications/index.js";
import { useLocation } from "react-router";
import { usePreloadedData } from "../Application/PreloadedData/index.js";

const getTextFromPathname = (pathname, categoryName) => {
    let breadcrumbs = [], title = "Notification Settings";
    
    if (pathname.includes("library")) {
        breadcrumbs = ["Library"];
        title = "Notification Settings: Library";
    } else if (pathname.includes("category")) {
        breadcrumbs = ["Category", categoryName].filter(Boolean);
        title = `Notification Settings${categoryName ? `: ${categoryName}` : ""}`;
    } 

    return { breadcrumbs, title };
};

const getSettingsType = (pathname) => pathname.split("/").slice(-1)[0] === "library" ? "library" : "submission";


export const NotificationSettings = () => {
    const location = useLocation();
    const { preloadedData } = usePreloadedData();
    const { categories } = preloadedData;

    const settingsType = getSettingsType(location.pathname);
    const categoryId = location.pathname.split("/").slice(-1)[0];
    const category = categoryId ? categories.find(({ id }) => id === categoryId) : "";
    const categoryName = category?.name ?? "";
    const { breadcrumbs, title } = getTextFromPathname(location.pathname, categoryName);

    return <Main
        title={title}
        breadcrumbs={[<NotificationSettingsLink />, ...breadcrumbs]}
        details={
            <div>
                <p>You can specify which notifications you receive.</p>
            </div>
        }
    >
        { settingsType === "library"
            ? <LibraryNotifications /> 
            : null 
        }
        { settingsType === "submission"
            ? <SubmissionNotifications 
                categoryName={categoryName}
                categoryId={categoryId}
            />
            : null 
        }
    </Main>;
};