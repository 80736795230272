import React, { useState, useContext } from "react";
import { Button, Form, message } from "antd";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { toSvg } from "jdenticon";
import { UserContext } from "../Application/UserContext.js";
import { PCDULink } from "../common/PCDULink/index.js";
import { LoadingContent } from "../common/LoadingContent/index.js";
import { Main } from "../common/Main/index.js";
import { NoContent } from "../common/NoContent/index.js";
import { SelectableDetail } from "../common/SelectableDetail/index.js";
import { EditableDetail } from "../common/EditableDetail/index.js";
import { isUndefinedOrNull } from "../../utils/functions.js";
import { MODIFY_USER } from "../../constants/mutations.js";
import {
    ROLE_DISPLAY_CONVERSION,
    SUBMITTER_ADDITIONAL_ROLE,
    SUBMITTER
} from "../../constants/index.js";
import {
    getGenuineModifications,
    handleGraphQLError,
    handleMutation
} from "../../utils/errorHandling.js";
import { userDetailQuery, userAssignmentQuery } from "./query.js";
import { OrganizationTable } from "./organizationTable.js";
import { UserRoleTable } from "./roleTable.js";
import { UserSubmissionTypeRoles } from "./submissionTypeTable.js";

const SAVE_BUTTON_FIELDS = {
    ncid: true,
    email: true,
    name: true,
    attestationRoleName: true
};

const showSaveButton = (modified) => {
    if (modified && typeof modified === "object") {
        return Object.keys(modified).some(key => SAVE_BUTTON_FIELDS[key]);
    } else {
        return false;
    }
};

export const UserDetail = (() => {
    const params = useParams();
    const [modified, setModified] = useState({});

    const [modifyUser] = useMutation(MODIFY_USER);


    const [userDetailForm] = Form.useForm();

    const {
        userPermArchiveUsers,
        userPermEditUsers,
        userPermViewUserAuditLog,
        userPermCreateOrgMembership
    } = useContext(UserContext);
    
    const { loading, error, data, refetch } = useQuery(
        userDetailQuery,
        {
            variables: {
                id: params.id,
                sortOn: "specifier",
                sortBy: "ascending"
            },
            fetchPolicy: "no-cache"
        }
    );

    let userAssignmentData = {};

    if (userPermCreateOrgMembership) {
        // query to get 
        userAssignmentData = useQuery(
            userAssignmentQuery,
            {
                variables: {
                    sortOn: "specifier",
                    sortBy: "ascending"
                },
                fetchPolicy: "no-cache"
            }
        );
    }

    if (error) {
        return handleGraphQLError(error);
    }

    if (loading) {
        return <LoadingContent />;
    }
    
    const user = isUndefinedOrNull(data.user) ? {} : data.user;

    if (isUndefinedOrNull(data.user)) {
        return <NoContent />;
    }

    const submitter = SUBMITTER;

    const attestationOptions = Object.keys(SUBMITTER_ADDITIONAL_ROLE).map((id) => {
        return {
            text: ROLE_DISPLAY_CONVERSION[SUBMITTER_ADDITIONAL_ROLE[id]],
            value: id,
            key: id
        };
    });

    const newBreadcrumb = { 
        label: user.name,
        path: `/users/${user.id}`,
        tooltip: `User: ${user.name}`
    };

    const orgRoles = Array.isArray(user.assignments) 
        ? user.assignments.filter((assignment) => assignment.role.specifier === submitter)
        : [];

    const details = (
        <div id="details">
            { userPermViewUserAuditLog && user.id  
                ? <div style={{margin: "10px 0px", fontSize: "16px"}}>
                    <PCDULink 
                        to={`/changelogs/acting-user/${user.id}`}
                        newBreadcrumbs={newBreadcrumb}
                    > 
                        User Activity 
                    </PCDULink> 
                    <span style={{margin: "0 5px"}}> | </span>
                    <PCDULink 
                        to={`/changelogs/user/${user.id}`}
                        newBreadcrumbs={newBreadcrumb}
                    > 
                        Changelogs 
                    </PCDULink>
                </div> 
                : null 
            }
            <Form name="userDetails" form={userDetailForm}>
                {[
                    {
                        title: "Display Name",
                        key: "name",
                        required: true
                    },
                    {
                        title: "NCID",
                        key: "ncid",
                        required: !user.serviceAccount 
                    },
                    {
                        title: "Email",
                        key: "email",
                        additionalRules: [{type: "email", message: "Should be a valid email"}],
                        required: !user.serviceAccount
                    }
                ].map(({ title, key, additionalRules, required }) => {
                    return (
                        <div key={`editable-user-details-${key}`}>
                            <EditableDetail
                                title={title}
                                inputId={key}
                                required={required}
                                additionalRules={additionalRules}
                                readOnly={!userPermEditUsers || !required}
                                value={{ ...user, ...modified }[key]}
                                onValueUpdated={(e) => {
                                    setModified({
                                        ...modified,
                                        [key]: e.target.value
                                    });
                                }}
                            />
                        </div>
                    );
                })}

                <div style={{ display: orgRoles.length > 0 ? "inherit" : "none" }}>
                    <SelectableDetail
                        title="Attestation Level"
                        passedKey="attestationRoleName"
                        defaultValue="None"
                        value={{ ...user, ...modified }["attestationRoleName"]}
                        readOnly={!userPermEditUsers}
                        onValueUpdated={(value) => {
                            setModified({
                                ...modified,
                                attestationRoleName: value,
                                attestationFlag: (value && (ROLE_DISPLAY_CONVERSION[value] != (ROLE_DISPLAY_CONVERSION.SUBMITTER_NONE))) ? true : false 
                            });
                        }}
                        options={attestationOptions}
                    />
                </div>
                { userPermEditUsers && showSaveButton(modified) 
                    ? <Form.Item>
                        <Button
                            type="primary"
                            onClick={async (e) => {
                                e.preventDefault();
                                try {
                                    await userDetailForm.validateFields();

                                    const genuineModifications = getGenuineModifications(modified, user, {
                                        trim: true
                                    });

                                    if (genuineModifications) {
                                        const modifyUserMutation = modifyUser({
                                            variables: {
                                                id: user.id,
                                                newUser: genuineModifications
                                            }
                                        });

                                        const success = await handleMutation(modifyUserMutation, {
                                            showSuccess: true
                                        });

                                        if (success) {
                                            refetch().then(({ data }) => {
                                                const user = data.user;
                                                userDetailForm.setFieldsValue({
                                                    name: user.name,
                                                    ncid: user.ncid,
                                                    email: user.email
                                                });
                                                setModified({});
                                            });
                                        }
                                    }
                                } catch (err) {
                                    message.error({
                                        content: "Please correct form errors and try again.",
                                        style: {
                                            marginTop: "10vh"
                                        }
                                    });
                                }
                            }}
                        >
                            Save Changes
                        </Button>
                    </Form.Item>
                    : null 
                }
            </Form>
        </div>
    );

    const displayAssignmentUI = Boolean(data?.user?.memberships?.length);

    return (
        <Main
            showActivate={userPermArchiveUsers}
            activated={user.active}
            okText={user.active ? "Yes, Deactivate" : "Yes, Activate"}
            confirmAction={async () => {
                await handleMutation(
                    modifyUser({
                        variables: {
                            id: user.id,
                            newUser: { active: !user.active }
                        }
                    })
                );

                refetch();
            }}
            confirmTitle={`Are you sure you want to ${user.active ? "Deactivate" : "Activate"} this user?`}
            title={<>
                <div
                    style={{
                        display: "inline-flex",
                        marginRight: "8px",
                        backgroundColor: "#fff",
                        borderRadius: "4px"
                    }}
                    dangerouslySetInnerHTML={{
                        __html: toSvg(user === null || typeof user.name === "undefined" ? "" : user.email, 50)
                    }}

                />

                { user.active 
                    ? user.serviceAccount ? <span>{`${user.name}  (Service Account)`}</span> : user.name
                    : user.serviceAccount 
                        ? <span style={{ color: "grey" }}>{`${user.name} (Service Account Deactivated)`}</span>
                        : <span style={{ color: "grey" }}>{`${user.name} (Deactivated)`}</span>
                }
            </>}
            details={details}
            breadcrumbs={[<PCDULink tooltip={"All Users"} to="/users">Users</PCDULink>, user.name]}
        >
            { 
                user.active 
                    ? <OrganizationTable
                        newBreadcrumbs={newBreadcrumb}
                        userDetails={data.user}
                        assignmentData={userAssignmentData}
                        refetch={refetch}
                    />
                    : null 
            }
            
            {
                user.active && displayAssignmentUI 
                    ? <>
                        <UserRoleTable
                            newBreadcrumbs={newBreadcrumb}
                            userDetails={data.user}
                            refetch={refetch}
                            assignmentData={userAssignmentData}
                            attestationOptions={attestationOptions}
                        />

                        { !user.isSubmitter &&
                            <UserSubmissionTypeRoles
                                newBreadcrumbs={newBreadcrumb}
                                userDetails={data.user}
                                assignmentData={userAssignmentData}
                                refetch={refetch}
                            />
                        }
                    </>
                    : null
            }
        </Main>
    );
});
