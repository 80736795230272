import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { Pagination, Radio } from "antd";
import { Table } from "../common/Table/index.js";
import { formatDate, isoDateRange } from "../../utils/functions.js";
import { dateable, filterable, searchable } from "../../utils/index.js";
import { LoadingContent } from "../common/LoadingContent/index.js";
import { ContractsCsvExport } from "./export.js";
import { contractsQuery } from "./query.js";
import { handleGraphQLError } from "../../utils/errorHandling.js";
import { ClearFiltersButton } from "../common/ClearFiltersButton/index.js";
import { PersistentState } from "../../utils/PersistentState.js";
import { PCDULink } from "../common/PCDULink/index.js";
import { handleControlledDefaultSortOrder } from "../../utils/handleControlledDefaultSortOrder.js";
import { defaultTablePageSize } from "../../constants/index.js";
import { useAutoFlag } from "../../utils/useAutoFlag.js";
import { usePreloadedData } from "../Application/PreloadedData/index.js";

const { usePersistentState } = PersistentState();

const CONTRACTS_BREADCRUMB = { label: "Contracts", path: "/contracts" };

export const ContractsTable = () => {

    const defaultSortOn = "specifier";
    const defaultSortBy = "ascend";
    
    const { preloadedData } = usePreloadedData();

    // normal state :: 
    const [dataToUse, setDataToUse] = useState(null);
    const [resetFilterSearch, setResetFilterSearch] = useAutoFlag(false);

    // Persistent state :: 
    const [contractTablePage, setContractTablePage] = usePersistentState(1);
    const [contractTablePageSize, setContractTablePageSize] = usePersistentState(defaultTablePageSize);
    const [contractTypeFilter, setContractTypeFilter] = usePersistentState([]);
    const [contractPromisorFilter, setContractPromisorFilter] = usePersistentState([]);
    const [startDateFilter, setStartDateFilter] = usePersistentState([]);
    const [endDateFilter, setEndDateFilter] = usePersistentState([]);
    const [activeToggle, setActiveToggle] = usePersistentState(true);
    const [specifierSearch, setSpecifierSearch] = usePersistentState("");
    const [sortOn, setSortOn] = usePersistentState(defaultSortOn);
    const [sortBy, setSortBy] = usePersistentState(defaultSortBy);

    const clearSearch = () => {
        setContractTablePage(1);
        setActiveToggle(true);
        setContractTypeFilter([]);
        setContractPromisorFilter([]);
        setSpecifierSearch("");
        setStartDateFilter([]);
        setEndDateFilter([]);
        setSortOn(defaultSortOn);
        setSortBy(defaultSortBy);
        setContractTablePageSize(defaultTablePageSize);
        setResetFilterSearch(true);
    };

    let queryVariables = {
        offset: (contractTablePage - 1) * contractTablePageSize,
        limit: contractTablePageSize,
        activeFilter: activeToggle,
        conTypes: contractTypeFilter,
        promisorFilter: contractPromisorFilter,
        startDateFilter: isoDateRange(startDateFilter),
        endDateFilter: isoDateRange(endDateFilter),
        specifierSearch,
        sortOn,
        sortBy
    };

    const { loading, error, data } = useQuery(contractsQuery, {
        variables: queryVariables,
        fetchPolicy: "no-cache"
    });

    useEffect(() => {
        if (data) {
            setDataToUse(data);
        }
    }, [data]);

    if (error) {
        return handleGraphQLError(error);
    }

    if (!dataToUse) {
        return <LoadingContent />;
    }

    const { contractsList } = dataToUse;
    const { contracts, count: contractsCount } = contractsList;
    const { submitterOrganizations, contractTypes } = preloadedData;

    const typesFilterArray = contractTypes.map(({ name, id }) => {
        return {
            label: name,
            value: id
        };
    });

    const organizationFilterArray = submitterOrganizations.map(({ name, id }) => {
        return {
            label: name,
            value: id
        };
    });

    const rightAlignedRadioButtons = <Radio.Group
        defaultValue={activeToggle}
        value={activeToggle}
        onChange={(e) => {
            setActiveToggle(e.target.value);
            setContractTablePage(1);
        }}
        buttonStyle="solid"
    >
        <Radio.Button value={true} >Active </Radio.Button>
        <Radio.Button value={false}>Inactive </Radio.Button>
    </Radio.Group>;

    const contractsTable = (
        <Table
            exportButton={<ContractsCsvExport variables={queryVariables} />}
            clearFiltersButton={<ClearFiltersButton 
                clearFilters={clearSearch} 
                filtersData={[
                    { currentValue: contractTypeFilter, defaultValue: [] },
                    { currentValue: contractPromisorFilter, defaultValue: [] },
                    { currentValue: startDateFilter, defaultValue: [] },
                    { currentValue: endDateFilter, defaultValue: [] },
                    { currentValue: activeToggle, defaultValue: true },
                    { currentValue: specifierSearch, defaultValue: "" } 
                ]}
            />}
            rightAlignedButtonBarElement={rightAlignedRadioButtons}
            style={{
                backgroundColor: "#fff"
            }}
            size="middle"
            pagination={false}
            columns={handleControlledDefaultSortOrder({ sortOn, sortBy }, [
                searchable({
                    title: "Contract ID",
                    key: "id",
                    dataIndex: "specifier",
                    handleSearch: setSpecifierSearch,
                    searchedText: specifierSearch,
                    setPage: setContractTablePage,
                    handleReset: () => setSpecifierSearch(""),
                    width: "20%",
                    sorter: true,
                    sortDirections: ["ascend", "descend", "ascend"],
                    render: (highlightedId, { id }) => {
                        return (
                            <PCDULink to={`/contracts/${id}`}>
                                {highlightedId}
                            </PCDULink>
                        );
                    }
                }),
                filterable({
                    title: "Contract Type",
                    dataIndex: "contractType",
                    searchFilters: true,
                    resetFilterSearch,
                    filter: contractTypeFilter,
                    // Work needed on backend (resolvers) to support sorting contracts by contract type
                    sorter: false,
                    sortDirections: ["ascend", "descend", "ascend"],
                    setFilter: setContractTypeFilter,
                    setPage: setContractTablePage,
                    domain: typesFilterArray,
                    width: "10%",
                    render: ({ id, name }) => {
                        return (
                            <PCDULink 
                                to={`/contract-types/${id}`}
                                newBreadcrumbs={CONTRACTS_BREADCRUMB}
                            >
                                {name}
                            </PCDULink>
                        );
                    }
                }),
                filterable({
                    title: "Submitting Organization",
                    dataIndex: "promisor",
                    searchFilters: true,
                    resetFilterSearch,
                    filter: contractPromisorFilter,
                    setFilter: setContractPromisorFilter,
                    setPage: setContractTablePage,
                    domain: organizationFilterArray,
                    width: "20%",
                    // Work needed on backend (resolvers) to support sorting contracts by promisor
                    sorter: false,
                    sortDirections: ["ascend", "descend", "ascend"],
                    render: ({ id, name }) => {
                        const text = name ?? "";
                        if (!id) {
                            return (<span>{text}</span>);
                        } else {
                            return (
                                <PCDULink 
                                    to={`/organizations/${id}`}
                                    newBreadcrumbs={CONTRACTS_BREADCRUMB}
                                >
                                    {text}
                                </PCDULink>
                            );
                        }
                    }
                }),
                dateable({
                    title: "Start Date",
                    dataIndex: "startDate",
                    width: "15%",
                    filter: startDateFilter,
                    setFilter: setStartDateFilter,
                    setPage: setContractTablePage,
                    render: formatDate,
                    sorter: true,
                    sortDirections: ["ascend", "descend", "ascend"]
                }),
                dateable({
                    title: "End Date",
                    dataIndex: "endDate",
                    width: "15%",
                    filter: endDateFilter,
                    setFilter: setEndDateFilter,
                    setPage: setContractTablePage,
                    render: formatDate,
                    sorter: true,
                    sortDirections: ["ascend", "descend", "ascend"]
                })
            ])}
            dataSource={contracts}
            loading={loading}
            rowKey={"id"}
            onChange={(_, __, { field, order }) => {
                setSortOn(field);
                setSortBy(order);
            }}
        />
    );

    const contractsTablePagination = (
        <Pagination
            style={{
                display: "flex",
                justifyContent: "center",
                padding: "20px 0 50px 0"
            }}
            showSizeChanger
            onShowSizeChange={(targetPage, pageSize) => {
                setContractTablePage(targetPage);
                setContractTablePageSize(pageSize);
            }}
            onChange={(targetPage, pageSize) => {
                setContractTablePage(targetPage);
                setContractTablePageSize(pageSize);
            }}
            current={contractTablePage}
            pageSize={contractTablePageSize}
            defaultCurrent={contractTablePage}
            defaultPageSize={contractTablePageSize}
            pageSizeOptions={["10", "25", "50", "100"]}
            total={contractsCount}
        />
    );

    return (
        <div className="main-content-margin-top">
            {contractsTable}
            {contractsTablePagination}
        </div>
    );
};