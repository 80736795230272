import React, { useState, useEffect } from "react";
import { Button, Modal, Spin } from "antd";
import {
    CloseCircleTwoTone,
    SyncOutlined, 
    DownloadOutlined 
} from "@ant-design/icons";
import { useQuery, useMutation } from "@apollo/client";
import { restoreFile } from "../../../utils/restoreFile.js";
import { pollingTimeByFileSize, LIBRARY_FILE_EXTENTION_ARRAY } from "../../../utils/index.js";
import { DOCUMENT_UPLOAD_TYPES, S3_UPLOAD_STATUS } from "../../../constants/index.js";
import { REUPLOAD_LIBRARY_DOCUMENT } from "../../../constants/mutations.js";
import { DocumentUploadModal } from "../../common/DocumentUploadModal/index.js";
import { handleMutation, FILE_UNAVAILABLE_TEXT, FILE_UPLOAD_ERROR_MSG, FILE_PROCESS } from "../../../utils/errorHandling.js";
import { LibraryDocumentS3Status } from "./query.js"; 
import { DownloadButton } from "../DownloadButton/index.js";

const DEFAULT_BUTTON_STYLE = { marginLeft: 0 };

// ten minutes, in milliseconds :: 
const POLLING_CUTOFF_TIME = (10 * 60 * 1000); 

// in milliseconds :: 
const BUILT_IN_LAG = 2000; 

const recordIsRetrievable = (recordWithMetadata) => { 
    const S3Data = recordWithMetadata?.S3Metadata; 
    if (S3Data?.Restore || !S3Data?.StorageClass) {
        return false;
    } else {
        return true;
    }
};


export const LibraryDocumentActions = ({ 
    buttonStyle,
    editDocument,
    userCanUpload,
    userCanEdit, 
    record, 
    libraryFolderId, 
    onReuploadFailure,  
    reportUpdatedDocument 
}) => { 
    const buttonStyleToUse = buttonStyle ?? DEFAULT_BUTTON_STYLE;
    const isRetrievable = recordIsRetrievable(record); 

    // what we return depends on currentUploadStatus, which may be updated by subsequent queries (polling) :: 
    switch (record.S3UploadStatus) {
        case S3_UPLOAD_STATUS.uploading:
            // this is the only case where we want to actively poll ::  
            return <PollingLibraryActions 
                record={record} 
                reportUpdatedDocument={reportUpdatedDocument}
            />;
        case S3_UPLOAD_STATUS.error: 
        case S3_UPLOAD_STATUS.quarantine: 
            // we KNOW the file should not be downloaded -- so use FILE_UNAVAILABLE_TEXT if user cannot reupload 
            // to correct problem :: 
            return userCanUpload
                ? <ReUploadLibraryDocumentModal 
                    record={record} 
                    onReuploadFailure={onReuploadFailure}
                    reportUpdatedDocument={reportUpdatedDocument}
                    libraryFolderId={libraryFolderId} 
                /> 
                : <DownloadButton 
                    disabled={true}
                    tooltipText={FILE_UNAVAILABLE_TEXT}
                    className={"spaceBetween-md"}
                    type={DOCUMENT_UPLOAD_TYPES.LIBRARY}
                    document={record}
                    style={buttonStyleToUse}
                />; 
        case S3_UPLOAD_STATUS.uploaded: 
            if (record.failedS3Fetch === true) {
                return <DownloadButton 
                    disabled={true}
                    tooltipText={FILE_PROCESS}
                    className={"spaceBetween-md"}
                    type={DOCUMENT_UPLOAD_TYPES.LIBRARY}
                    document={record}
                    style={buttonStyleToUse}
                />; 
            } else {
                const btn = <DownloadButton 
                    className={"spaceBetween-md"}
                    type={DOCUMENT_UPLOAD_TYPES.LIBRARY}
                    document={record}
                    style={buttonStyleToUse}
                />;
                return (
                    <div>
                        {
                            isRetrievable 
                                ? <Button
                                    className="downloadLink spaceBetween-md"
                                    type="primary"
                                    size="small"
                                    icon={ isRetrievable ? <DownloadOutlined /> : null }
                                    style={buttonStyleToUse}
                                    onClick={async () => await restoreFile(record.id, true)}
                                >
                                    Retrieve 
                                </Button>
                                : typeof editDocument === "function" && userCanEdit === true 
                                    ? <WithEditButton editDocument={editDocument}>
                                        { btn }
                                    </WithEditButton>
                                    : btn
                        }
                    </div>
                ); 
            }
        default: 
            // just return disabled button with the generic processing message :: 
            return <DownloadButton 
                disabled={true}
                tooltipText={FILE_PROCESS}
                className={"spaceBetween-md"}
                type={DOCUMENT_UPLOAD_TYPES.LIBRARY}
                document={record}
                style={buttonStyleToUse}
            />;
    }
}; 

const WithEditButton = ({ editDocument, children }) => {
    return <div style={{ 
        display: "flex", 
        justifyContent: "space-between",
        maxWidth: "220px"
    }}>
        <Button onClick={editDocument}>Edit</Button>
        { children }
    </div>;
};

const LibraryActionLoading = () => {
    return (
        <div style={{ 
            width: "100px" 
        }}>
            <SyncOutlined spin />
        </div>
    );
};

const PollingLibraryActions = ({ 
    reportUpdatedDocument, 
    record
}) => {
    const [pollingDelay] = useState(pollingTimeByFileSize(record.fileSize) + BUILT_IN_LAG); 
    const [cutoffReached, setCutoffReached] = useState(false); 

    const {
        data, 
        error, 
        startPolling, 
        stopPolling 
    } = useQuery(LibraryDocumentS3Status, { 
        variables: { 
            id: record.id, 
            includeS3Metadata: true 
        },
        fetchPolicy: "no-cache"
    }); 

    // run exactly once when component mounts :: 
    useEffect(() => { 
        startPolling(pollingDelay); 
        const timeoutId = setTimeout(() => {
            clearTimeout(timeoutId); 
            stopPolling(); 

            // this will cause the component to return FILE_PROCESS :: 
            setCutoffReached(true); 
        }, POLLING_CUTOFF_TIME); 

        // cleanup function :: 
        return () => {
            clearTimeout(timeoutId);
            stopPolling();
        }; 
    }, []); 

    useEffect(() => { 
        // stop polling and update status if the document finishes uploading :: 
        if (data) { 
            const newStatus = data?.libraryDocument?.S3UploadStatus; 
            const succeededAtS3Fetch = data?.libraryDocument?.failedS3Fetch === false;
            if (newStatus !== S3_UPLOAD_STATUS.uploading) { 
                // if uploaded successfully, we need to continue to poll to get a successful S3 fetch if this was a re-upload :: 
                const continuePolling = newStatus === S3_UPLOAD_STATUS.uploaded && !succeededAtS3Fetch;
                if (!continuePolling) {
                    const upToDateLibraryDocument = { ...data?.libraryDocument };
                    stopPolling();
                    reportUpdatedDocument(upToDateLibraryDocument); 
                }
            } 
        }
    }, [data]); 

    if (error || cutoffReached) {
        stopPolling(); 
        return FILE_PROCESS; 
    } else { 
        return <LibraryActionLoading />; 
    }
};

const ReUploadLibraryDocumentModal = ({ 
    record, 
    onReuploadFailure, 
    reportUpdatedDocument, 
    libraryFolderId 
}) => { 
    const [modified, setModified] = useState({});
    const [reUploadLibraryModal, setReuploadLibraryModal] = useState(false);
    const [createLibraryFiles, setCreateLibraryFiles] = useState({});
    const [reUploadLibraryDocument, reUploadLibraryDocumentProgress] = useMutation(REUPLOAD_LIBRARY_DOCUMENT);

    return (
        <>
            <Modal
                title="Re-Upload Library Document"
                destroyOnClose={true}
                maskClosable={false}
                closable={false}
                open={reUploadLibraryModal}
                okText="Save"
                onCancel={() => {
                    setModified({
                        ...modified
                    });
                    setReuploadLibraryModal(false);
                    setCreateLibraryFiles({});
                }}
                okButtonProps={{
                    disabled: Object.keys(createLibraryFiles).length === 0 || reUploadLibraryDocumentProgress.loading || createLibraryFiles.size === 0
                }}
                cancelButtonProps={{
                    disabled: reUploadLibraryDocumentProgress.loading
                }}
                onOk={async (e) => {
                    e.preventDefault(); 

                    const fileSize = (createLibraryFiles.size).toString();
                    const name = createLibraryFiles.name;

                    const DocumentInput = {
                        documentId: record.id,
                        documentName: name,
                        fileSize, 
                        file: createLibraryFiles.originFileObj,
                        contractTypeName: record.contractTypeName,
                        libraryFolderId: libraryFolderId,
                        baseFolderName: record.parentFolderName
                    };

                    const success = await handleMutation(
                        reUploadLibraryDocument({
                            variables: {
                                DocumentInput 
                            }
                        })
                    ); 

                    if (success) { 
                        reportUpdatedDocument({
                            ...record, 
                            S3UploadStatus: S3_UPLOAD_STATUS.uploading, 
                            name, 
                            fileSize 
                        }); 
                    } else if (typeof onReuploadFailure === "function") {
                        onReuploadFailure();
                    }

                    setReuploadLibraryModal(false);
                    setCreateLibraryFiles({});
                    setModified({}); 
                }}
            >
                <Spin size="large" spinning={reUploadLibraryDocumentProgress.loading}>
                    <DocumentUploadModal
                        uploadFileMetaData={createLibraryFiles}
                        setUploadFileMetaData={setCreateLibraryFiles}
                        maxDocumentCount={1}
                        acceptingFileTypeList={LIBRARY_FILE_EXTENTION_ARRAY}
                    />
                </Spin>
                {Object.keys(createLibraryFiles).length != 0 && createLibraryFiles.size === 0 && (
                    <span style={{
                        marginTop: "10px",
                        color: "red",
                        fontStyle: "italic"
                    }}>
                        <CloseCircleTwoTone twoToneColor="red" /> {FILE_UPLOAD_ERROR_MSG}
                    </span>
                )}
            </Modal>
            <Button
                className="ant-btn ant-btn-primary"
                onClick={() => {
                    setReuploadLibraryModal(true);
                }}
            >
                Re-Upload
            </Button>
        </>
    );
}; 
