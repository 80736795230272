import React from "react";
import { useParams } from "react-router-dom";
import { SubmissionTypesChangelogsQuery } from "./queries.js";
import { ChangelogsTable } from "./changelogs-table.js";
import { PCDULink } from "../common/PCDULink/index.js";


export const SubmissionTypeChangelogs = (({ 
    actions,
    roles,
    subjectData,
    setSubjectData 
}) => {
    const params = useParams();
    const currentPageSubjectId = params.id;
    const subjectBreadcrumb = {
        label: "Submission Type Changelogs",
        path: "/changelogs/submission-types"
    };

    return (
        <div style={{minWidth: "900px"}}>
            <ChangelogsTable 
                subjectBreadcrumb={subjectBreadcrumb}
                subjectData={subjectData}
                setSubjectData={setSubjectData}
                filterOptions={{
                    actions,
                    roles
                }}
                exportFilename={"Submission_Type_Changelogs.csv"}
                query={SubmissionTypesChangelogsQuery}
                subjectHeader={{label: "Submission Type", key: "specifier" }}
                subjectColumn={
                    {
                        title: "Submission Type",
                        dataIndex: ["subject", "specifier"],
                        render: (highlightableText, { subject }) => {
                            return subject 
                                ? (<>
                                    <PCDULink 
                                        to={`/submission-types/${subject.id}`}
                                        newBreadcrumbs={[ 
                                            { label: "Changelogs", path: "/changelogs" }, 
                                            subjectBreadcrumb
                                        ]}
                                    > 
                                        {highlightableText} 
                                    </PCDULink> 
                                    { currentPageSubjectId !== subject.id && 
                                        <div>
                                            <span>
                                                <PCDULink to={`/changelogs/submission-types/${subject.id}`}> Changelogs </PCDULink>
                                            </span>
                                        </div> 
                                    }
                                </>)
                                : "N/A";
                        }
                    }
                }
            />
        </div>
    );
});
