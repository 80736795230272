import React from "react";
import { EXPORTSIZE } from "../../constants/index.js";
import { formatDate } from "../../utils/functions.js";
import { CsvExport } from "../common/CsvExport/index.js";
import { contractTypesQuery } from "./query.js";

const csvHeaders = [
    { label: "Name", key: "name" },
    { label: "ID", key: "id" },
    { label: "Start Date", key: "startDate" },
    { label: "End Date", key: "endDate" }
];

const transformContractTypesDataForCSV = (data) => {
    return data.contractTypes.map((contractType) =>{
        return {
            name: contractType.name,
            id: contractType.specifier,
            startDate: formatDate(contractType.startDate),
            endDate: formatDate(contractType.endDate)
        };
    });
};

export const ContractTypesCsvExport = ({variables}) => {
    return <CsvExport
        csvFilename={"ContractTypes_Report.csv"}
        csvHeaders={csvHeaders}
        query={contractTypesQuery}
        variables={{...variables, offset: 0, limit: EXPORTSIZE}}
        transformData={transformContractTypesDataForCSV}
    />;
};