import React, { useState, useContext } from "react";
import { SyncOutlined } from "@ant-design/icons";
import { UserContext } from "../../Application/UserContext.js";
import { S3_UPLOAD_STATUS } from "../../../constants/index.js";
import { DownloadFeedbackDocument } from "./Buttons/Download.js";
import { InactivateFeedbackDocument } from "./Buttons/Inactivate.js";
import { DeleteFeedbackDocument } from "./Buttons/Delete.js";
import { ReuploadFeedbackDocument } from "./Buttons/Reupload.js";


export const FeedbackDocumentActions = ({ 
    feedbackDocument, 
    submissionTypeId, 
    allowModifications,
    refetch,
    issueRefetch
}) => { 
    const [actionHappening, setActionHappening] = useState(false);
    const { getFeedbackDocumentPermissions } = useContext(UserContext); 

    // If the feedback document is currently uploading // processing, don't allow any actions -- return early :: 
    if (feedbackDocument.S3UploadStatus === S3_UPLOAD_STATUS.uploading) {
        return <SyncOutlined spin />;
    }
    
    const {
        canReupload,
        canDelete,
        canInactivate
    } = getFeedbackDocumentPermissions(submissionTypeId);
    
    const allowDownload = feedbackDocument?.S3UploadStatus === S3_UPLOAD_STATUS.uploaded; 

    const allowInactivation = allowModifications === true &&
        feedbackDocument?.S3UploadStatus === S3_UPLOAD_STATUS.uploaded && 
        feedbackDocument?.active === true && 
        canInactivate; 

    const allowReupload = allowModifications === true && 
        canReupload && 
        (feedbackDocument?.S3UploadStatus === S3_UPLOAD_STATUS.error || feedbackDocument?.S3UploadStatus === S3_UPLOAD_STATUS.quarantine);

    const allowDeletion = allowModifications === true && 
        canDelete && 
        feedbackDocument.active &&
        feedbackDocument.isDeletable;

    // figure out the width of the action column :: 
    let widthNumber = 0;

    if (allowDownload) {
        widthNumber += 110;
    }

    if (allowInactivation) {
        widthNumber += 90; 
    }

    if (allowReupload) {
        widthNumber += 100;
    }

    if (allowDeletion) {
        widthNumber += 35;
    }

    const widthToUse = Number.isInteger(widthNumber) && widthNumber > 80 ? `${widthNumber}px` : "fit-content";

    return (
        <div style={{ 
            display: "flex", 
            justifyContent: "space-between", 
            width: widthToUse  
        }}>
            { allowDownload && 
                <DownloadFeedbackDocument 
                    feedbackDocument={feedbackDocument} 
                    actionHappening={actionHappening}
                    setActionHappening={setActionHappening}
                /> 
            } 
            { allowInactivation && 
                <InactivateFeedbackDocument 
                    feedbackDocument={feedbackDocument} 
                    actionHappening={actionHappening}
                    setActionHappening={setActionHappening}
                    refetch={refetch}
                /> 
            }
            { allowReupload && 
                <ReuploadFeedbackDocument 
                    feedbackDocument={feedbackDocument} 
                    actionHappening={actionHappening}
                    setActionHappening={setActionHappening}
                    refetch={refetch}
                    issueRefetch={issueRefetch}
                />
            } 
            { allowDeletion && 
                <DeleteFeedbackDocument 
                    feedbackDocument={feedbackDocument} 
                    actionHappening={actionHappening}
                    setActionHappening={setActionHappening}
                    refetch={refetch}
                    issueRefetch={issueRefetch}
                />
            }
        </div>
    );
};
