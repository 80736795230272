import React, { useState, useContext } from "react";
import { Button, Modal } from "antd";
import { useMutation } from "@apollo/client";
import { UserContext } from "../Application/UserContext.js";
import { EditableDetail } from "../common/EditableDetail/index.js";
import { TextAreaDetail } from "../common/TextAreaDetail/index.js";
import { Table } from "../common/Table/index.js";
import {
    formatTimeValue
} from "../../utils/functions.js";

import {
    CREATE_CRITERIA,
    MODIFY_CRITERIA
} from "../../constants/mutations.js";

import { handleMutation } from "../../utils/errorHandling.js";

import {
    activeVersionsCount,
    VersionList,
    getLatestTimestampForCriteria
} from "./_helpers.js";
import { handleControlledDefaultSortOrder } from "../../utils/handleControlledDefaultSortOrder.js";
import { PersistentState } from "../../utils/PersistentState.js";

const { usePersistentState } = PersistentState();

const { confirm } = Modal;

export const SubmissionTypeCriteriaTable = (({
    submissionType,
    refetchSubmissions
}) => {

    const [createCriteriaVis, setCreateCriteriaVis] = useState(false);
    const [criteriaTitle, setCriteriaTitle] = useState("Define the New Criteria");
    const [criteriaModify, setCriteriaModify] = useState(false);
    const [createCriteria] = useMutation(CREATE_CRITERIA);
    const [modifyCriteria] = useMutation(MODIFY_CRITERIA);
    const [showArchivedCriteria, setShowArchivedCriteria] = useState(false);
    const [modified, setModified] = useState({});

    // Persistent State :: 
    const [sortOn, setSortOn] = usePersistentState("specifier");
    const [sortBy, setSortBy] = usePersistentState("ascend");
    const [page, setPage] = usePersistentState(1);
    const [pageSize, setPageSize] = usePersistentState(10);

    const {
        userPermEditSubmissionType,
        userIsSubmitter
    } = useContext(UserContext);

    const showConfirmCriteria = ({ id, deleted }) => {
        confirm({
            title:
                deleted  
                    ? "Are you sure want to de-archive this criteria?" 
                    : "Are you sure you want to archive this criteria? While archived, it will not be applied to new submissions, submission versions, or submission documents.",
            okText: `Yes, ${deleted ? "De-Archive" : "Archive"}`,
            async onOk() {
                await handleMutation(
                    modifyCriteria({
                        variables: {
                            id,
                            newCriteria: { deleted: !deleted }
                        }
                    })
                );
                refetchSubmissions();
            }
        });
    };

    const criteriaTable = (
        <>
            <div style={{ minWidth: "900px" }}>
                <Table
                    id="criteriaTable"
                    rowKey="id"
                    dataSource={submissionType?.allCriteria?.filter?.(criteria => showArchivedCriteria ? criteria.deleted : !criteria.deleted) ?? []}
                    rowExpandable={({ versions }) => !userIsSubmitter && activeVersionsCount(versions) > 0}
                    expandedRowRender={VersionList}
                    pagination={{
                        defaultCurrent: page,
                        defaultPageSize: pageSize
                    }}
                    onChange={(
                        { current, pageSize }, 
                        _filters, 
                        { columnKey, order }
                    ) => {
                        setPage(current);
                        setPageSize(pageSize);
                        setSortOn(columnKey);
                        setSortBy(order);
                    }}
                    columns={handleControlledDefaultSortOrder({ 
                        sortOn, 
                        sortBy, 
                        customHandler: ({ key }) => key === sortOn 
                    }, [
                        {
                            title: "ID",
                            dataIndex: "specifier",
                            key: "specifier",
                            width: "60px",
                            sorter: (a, b) => a.specifier - b.specifier,
                            sortDirections: ["ascend", "descend", "ascend"]
                        },
                        {
                            title: "Citation",
                            key: "citation",
                            dataIndex: "citation",
                            width: "120px",
                            sorter: (a, b) => (a.citation ? a.citation.trim().toLowerCase() : "") > (b.citation ? b.citation.trim().toLowerCase() : "") ? 1 : -1,
                            sortDirections: ["ascend", "descend", "ascend"]
                        },
                        {
                            title: "Description",
                            key: "description",
                            dataIndex: "description",
                            sorter: (a, b) => (a.description ? a.description.trim().toLowerCase() : "") > (b.description ? b.description.trim().toLowerCase() : "") ? 1 : -1,
                            sortDirections: ["ascend", "descend", "ascend"]
                        },
                        !userIsSubmitter ? {
                            title: "Version",
                            key: "versions",
                            dataIndex: "versions",
                            sorter: (a, b) => activeVersionsCount(a?.versions) - activeVersionsCount(b?.versions),
                            sortDirections: ["ascend", "descend", "ascend"],
                            width: "120px",
                            render: (_, criteria) => activeVersionsCount(criteria?.versions) + 1
                        } : null,
                        !userIsSubmitter ? {
                            title: showArchivedCriteria ? "Archived on" : "Active from",
                            dataIndex: "inEffect",
                            key: "inEffect",
                            width: "180px",
                            sorter: (a, b) => getLatestTimestampForCriteria(a) - getLatestTimestampForCriteria(b),
                            sortDirections: ["ascend", "descend", "ascend"],
                            render: (_, criteria) => formatTimeValue(getLatestTimestampForCriteria(criteria), true)
                        } : null,
                        userPermEditSubmissionType ? {
                            title: "Actions",
                            key: "actions",
                            width: "120px",
                            render: (criteria) => {
                                const { id, specifier, citation, description, deleted } = criteria;
                                return (
                                    <div>
                                        { !deleted && <Button
                                            type=""
                                            style={{ marginRight: "5px", marginBottom: "5px" }}
                                            size="small"
                                            onClick={() => {
                                                setModified({
                                                    ...modified,
                                                    criteriaDescription: description,
                                                    criteriaCitation: citation,
                                                    criteriaId: id
                                                });
                                                setCreateCriteriaVis(true);
                                                setCriteriaModify(true);
                                                setCriteriaTitle(`Modify Criteria for ${specifier}`);
                                            }}
                                        >
                                            Edit
                                        </Button> }

                                        <Button
                                            type="danger"
                                            size="small"
                                            onClick={() => {
                                                showConfirmCriteria(criteria);
                                            }}
                                        >
                                            {deleted ? "De-Archive" : "Archive"}
                                        </Button>
                                    </div>
                                );
                            }
                        } : null
                    ].filter(Boolean))}
                />
            </div>
        </>
    );

    const createCriteriaModal = (
        <Modal
            title={criteriaTitle}
            open={createCriteriaVis}
            maskClosable={false}
            destroyOnClose="true"
            onOk={async (e) => {
                e.preventDefault();
                setCreateCriteriaVis(false);
                const newCriteria = {
                    description: modified.criteriaDescription,
                    submissiontypeId: submissionType.id,
                    citation: modified.criteriaCitation
                };

                if (criteriaModify) {
                    await handleMutation(
                        modifyCriteria({
                            variables: {
                                id: modified.criteriaId,
                                newCriteria
                            }
                        })
                    );
                } else {
                    await handleMutation(
                        createCriteria({
                            variables: {
                                newCriteria
                            }
                        })
                    );
                }
                await refetchSubmissions();
                setCriteriaModify(false);
                setModified({});
            }}
            okText="Save"
            okButtonProps={{
                disabled: typeof modified.criteriaDescription === "undefined"
            }}
            onCancel={() => {
                setModified({});
                setCriteriaModify(false);
                setCreateCriteriaVis(false);
            }}
        >
            <TextAreaDetail
                title="Description"
                key="criteriaDescription"
                placeholder="What qualifications this document must meet..."
                strict={true}
                value={modified.criteriaDescription}
                onValueUpdated={(e) => {
                    setModified({
                        ...modified,
                        criteriaDescription: e.target.value
                    });
                }}
            />
            <EditableDetail
                title="Citation"
                key="criteriaCitation"
                value={modified.criteriaCitation}
                onValueUpdated={(e) => {
                    setModified({
                        ...modified,
                        criteriaCitation: e.target.value
                    });
                }}
            />
        </Modal>
    );

    return (
        <>
            <h2>Criteria</h2>
            <p>Submissions of this submission type must meet the following criteria in addition to any criteria described in the contract obligating the submission.</p>
            <div style={{ 
                display: userIsSubmitter ? "none" : "flex",
                width: "100%", 
                justifyContent: "space-between",
                marginBottom: "20px"
            }}> 
                <Button
                    id="createCriteria"
                    style={{ display: userPermEditSubmissionType ? "inherit" : "none" }}
                    type="primary"
                    onClick={() => {
                        setCreateCriteriaVis(true);
                        setCriteriaModify(false);
                        setCriteriaTitle("Define the New Criteria");
                    }} 
                    disabled={showArchivedCriteria}
                >
                    Add
                </Button> 
                <Button 
                    id="archive-toggle"
                    type="primary"
                    ghost={!showArchivedCriteria}
                    onClick={() => {
                        setShowArchivedCriteria(!showArchivedCriteria);
                    }}
                > 
                    {showArchivedCriteria ? "Active Criteria" : "Archived Criteria"}
                </Button>
            </div>
            {criteriaTable}
            {createCriteriaModal}
        </>

    );
});