import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolderOpen } from "@fortawesome/free-solid-svg-icons";

export const Subtitle = ({ text, isArchived }) => {
    const subtitleHeader = text && typeof text === "string"
        ? <h2><FontAwesomeIcon icon={faFolderOpen} /> {`${text}${isArchived ? " (Archived)" : ""}`}</h2>
        : null;
    
    return <div style={{ marginBottom: "20px" }}>
        { subtitleHeader }
    </div>;
};