import { gql } from "@apollo/client";

export const getContractType = gql`
    query GetContractType ( 
        $id: ID!,
        $contractTypesFilter: [ID]
    ) {
        submissionTypes: submissionTypeFilterOptions (
            contractTypesFilter: $contractTypesFilter
        ) 
        contractsList (
            typesFilter: [$id],
        ) {
            count,
            contracts {
                id
                specifier
                name
                promisor {
                    id
                    name
                }
                startDate
                endDate
            }
        }
        contractType(
            id: $id
        ) {
            id
            name
            specifier
            description
            createdAt
            contractTypeVersions {
                id
                specifier
                startDate
                endDate
                contractActive 
                createdAt
            }
        }
    }
`;

export const submissionTypesOfVersion = gql`
    query SubmissionTypesOfVersion (
        $versionId: ID,
        $contractTypeId: ID
    ) {
        submissionTypesOfVersion (
            versionId: $versionId,
            contractTypeId: $contractTypeId
        ) {
            count 
            submissionTypes
        }
    }
`;