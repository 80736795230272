import { gql } from "@apollo/client";

export const submissionsQuery = gql`
    query SubmissionsListQuery (
        $limit: Int
        $offset: Int
        $submissionTypesFilter: [ID]
        $submitterId: ID
        $reviewerFilter: ID
        $reviewerCurrentOnly: Boolean
        $organizationsFilter: [ID]
        $specifierSearch: String
        $primaryReviewerSearch: String
        $statusFilter: [String]
        $uploadStatusFilter: [String]
        $sortOn: String
        $sortBy: String
        $createdFilter: [String]
        $dueDateFilter: [String]
        $categoryFilter:[String]
        $tab: String
        $latestVersionCreatedFilter: [String]
        $businessUnitsFilter: [ID]
        $contractTypesFilter: [ID]
        $yearFilter: Int
    ) {
        submissionsList (
            limit: $limit
            offset: $offset
            submissionTypesFilter: $submissionTypesFilter
            reviewerCurrentOnly: $reviewerCurrentOnly
            reviewerFilter: $reviewerFilter
            submitterId: $submitterId
            organizationsFilter: $organizationsFilter
            specifierSearch: $specifierSearch
            primaryReviewerSearch: $primaryReviewerSearch
            statusFilter: $statusFilter
            uploadStatusFilter: $uploadStatusFilter
            createdFilter: $createdFilter
            dueDateFilter: $dueDateFilter
            latestVersionCreatedFilter: $latestVersionCreatedFilter
            sortOn: $sortOn
            sortBy: $sortBy
            categoryFilter: $categoryFilter
            tab: $tab
            businessUnitsFilter: $businessUnitsFilter
            contractTypesFilter: $contractTypesFilter
            yearFilter: $yearFilter
        ) {
            submissions {
                id
                specifier
                createdAt
                statusId
                uploadStatus
                submitterDueDate
                initialSubmitterDueDate
                latestVersionCreatedAt
                organizationId
                contractTypeId
                businessUnitId
                frequencyId
                primaryReviewerId
                submissionTypeId
                adhocDueDate
                currentDueDate
                timeline {
                    currentDueDate
                }
            }
            count
        }
    }
`;

export const submissionsExportQuery = gql`
    query SubmissionsExportQuery (
        $limit: Int
        $offset: Int
        $submissionTypesFilter: [ID]
        $submitterId: ID
        $reviewerFilter: ID
        $reviewerCurrentOnly: Boolean
        $organizationsFilter: [ID]
        $specifierSearch: String
        $primaryReviewerSearch: String
        $statusFilter: [String]
        $uploadStatusFilter: [String]
        $sortOn: String
        $sortBy: String
        $createdFilter: [String]
        $dueDateFilter: [String]
        $categoryFilter:[String]
        $tab: String
        $latestVersionCreatedFilter: [String]
        $businessUnitsFilter: [ID]
        $contractTypesFilter: [ID]
        $yearFilter: Int
    ) {
        submissionsList (
            limit: $limit
            offset: $offset
            submissionTypesFilter: $submissionTypesFilter
            reviewerCurrentOnly: $reviewerCurrentOnly
            reviewerFilter: $reviewerFilter
            submitterId: $submitterId
            organizationsFilter: $organizationsFilter
            specifierSearch: $specifierSearch
            primaryReviewerSearch: $primaryReviewerSearch
            statusFilter: $statusFilter
            uploadStatusFilter: $uploadStatusFilter
            createdFilter: $createdFilter
            dueDateFilter: $dueDateFilter
            latestVersionCreatedFilter: $latestVersionCreatedFilter
            sortOn: $sortOn
            sortBy: $sortBy
            categoryFilter: $categoryFilter
            tab: $tab
            businessUnitsFilter: $businessUnitsFilter
            contractTypesFilter: $contractTypesFilter
            yearFilter: $yearFilter
        ) {
            submissions {
                submissionDocuments {
                    status
                }
                id
                specifier
                createdAt
                status
                uploadStatus
                submitterDueDate
                latestVersionCreatedAt
                reviewerAssignments {
                    assignee {
                        id
                        name
                    }
                    role {
                        id
                        specifier
                    }
                }
                submitter {
                    __typename
                    ... on Organization {
                        id
                        name
                    }
                }
                obligation {
                    id
                    submissionType {
                        id
                        name
                        specifier
                        categoryId
                        businessUnitId
                        businessUnitName
                        contractType{
                            id
                            name
                        }
                    }
                }
            }
            count
        }
    }
`;
