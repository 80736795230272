import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { Tag } from "antd";
import { DownloadOutlined, CloseCircleOutlined, SyncOutlined } from "@ant-design/icons";
import { DOCUMENT_UPLOAD_TYPES, S3_UPLOAD_STATUS, S3_UPLOAD_STATUS_FETCH_ERROR } from "../../../constants/index.js";
import { pollingTimeByFileSize } from "../../../utils/index.js";
import { feedbackDocumentStatusQuery } from "./query.js";
import { S3UploadStatus } from "../S3UploadStatus/index.js";
import { DownloadButton } from "../DownloadButton/index.js";

const FeedbackStatusDisplay = (status, id, allowDownload, name) => {
    if (allowDownload === true) {
        switch (status) {
            case S3_UPLOAD_STATUS.uploading:
                return <Tag icon={<SyncOutlined spin />} color="processing">
                    Processing
                </Tag>;
            case S3_UPLOAD_STATUS.uploaded:
                return <DownloadButton
                    icon={<DownloadOutlined />}
                    className="ant-btn ant-btn-primary"
                    size="small"
                    document={{ id, name }}
                    type={DOCUMENT_UPLOAD_TYPES.FEEDBACK}
                    style={{ margin: "20px 0 20px 0" }}
                />;
            default:
                return <Tag icon={<CloseCircleOutlined />} color="red">
                    Error
                </Tag>;
        }
    } else {
        return S3UploadStatus(status);
    }
};

const ActiveStatusTracker = ({
    id,
    fileSize,
    allowDownload,
    status,
    statusUpdateCallback
}) => {
    // we want to set a single polling interval that's used throughout lifecycle :: 
    const [pollingInterval] = useState(pollingTimeByFileSize(fileSize));
    const [documentStatus, setDocumentStatus] = useState(status); 

    // in this case, we don't want to include S3 metadata -- 
    // we are just going off of the S3UploadStatus property of the document :: 
    const fetchOptions = {
        variables: { id }, 
        fetchPolicy: "no-cache"
    };

    const {
        data, 
        error, 
        stopPolling, 
        startPolling 
    } = useQuery(feedbackDocumentStatusQuery, fetchOptions);

    useEffect(() => { 
        if (data) {
            const currentStatus = data?.feedbackDocument?.S3UploadStatus; 
            if (currentStatus === S3_UPLOAD_STATUS.uploading) {
                startPolling(pollingInterval);
            } else { 
                stopPolling(); 
                setDocumentStatus(currentStatus);
                statusUpdateCallback(true);
            } 
        }

        if (error) {
            stopPolling(); 
            setDocumentStatus(S3_UPLOAD_STATUS_FETCH_ERROR);
        }
        
        return stopPolling;
    }, [data, error]); 

    return (
        <div>
            { FeedbackStatusDisplay(documentStatus, id, allowDownload, data?.feedbackDocument?.name) }
        </div>
    );
};


export const FeedbackDocumentStatus = ({
    feedbackDocument,
    allowDownload = true,
    status = null,
    statusUpdateCallback
}) => { 
    const { id, fileSize, S3UploadStatus, name } = feedbackDocument; 
    const statusToUse = status ? status : S3UploadStatus;
    switch (statusToUse) { 
        // in these cases, we don't need to fetch data :: 
        case S3_UPLOAD_STATUS.error: 
        case S3_UPLOAD_STATUS.quarantine: 
        case S3_UPLOAD_STATUS.uploaded: 
            return (
                <div>
                    { FeedbackStatusDisplay(statusToUse, id, allowDownload, name) }
                </div>
            );
        default: 
            // if status is uploading, or invalid -- nullish (i.e., unknown), nonsensical, etc. -- we fetch and render the status :: 
            return <ActiveStatusTracker 
                id={id} 
                allowDownload={allowDownload}
                fileSize={fileSize} 
                status={statusToUse}
                statusUpdateCallback={statusUpdateCallback}
            />;
    }
};
