import React from "react";
import { Checkbox, Divider } from "antd";
import { isUndefinedOrNull } from "../../../utils/functions.js";
import { isBeingModified } from "../_shared/helpers.js";
import { SettingsCard } from "../_shared/settingsCard.js";
import { NOTIFICATION_TYPE_DISPLAY_NAMES, NOTIFICATION_TYPE_DISPLAY_ORDER } from "../_shared/sharedConstants.js";
import { SubmissionTypesNotification } from "./submissionTypes.js";
import { NoNotifications } from "../_shared/noNotifications.js";

const getNotificationTypeDisplayName = (notificationType) => {
    return NOTIFICATION_TYPE_DISPLAY_NAMES[notificationType] || notificationType;
}; 

const getUnsavedChangeText = (modifiedObj, preference) => isBeingModified(modifiedObj, preference) ? " (Unsaved Change)" : "";

const CardContainer = ({ title, subtitle, noPreferencesToRender, children }) => {
    return noPreferencesToRender
        ? <SettingsCard> <NoNotifications /> </SettingsCard>
        : <SettingsCard title={title} subtitle={subtitle}> {children} </SettingsCard>;
};

const sortAndFilterSubmissionPreferences = (preferences) => {
    const uniquePreferences = {};
    return !Array.isArray(preferences)
        ? []
        : preferences.sort((a, b) => {
            if ((a.required && b.required) || (!a.required && !b.required)) {
                return NOTIFICATION_TYPE_DISPLAY_ORDER.indexOf(a.notificationType.name) -
                    NOTIFICATION_TYPE_DISPLAY_ORDER.indexOf(b.notificationType.name);
            } else {
                return a.required ? 1 : -1;
            }
        }).filter((preference) => {
            const shouldDisplay = Object.hasOwn(NOTIFICATION_TYPE_DISPLAY_NAMES, preference.notificationType.name);
            const isNotDuplicate = !Object.hasOwn(uniquePreferences, preference.id);
            if (isNotDuplicate && shouldDisplay) {
                uniquePreferences[preference.id] = true;
                return true;
            } else {
                return false;
            }
        });
};


export const SubmissionNotificationTypes = ({
    resetFlag,
    modified, 
    finalVersionRequestFlag,
    setModified,
    submissionTypeModified,
    setSubmissionTypeModified,
    categoryName,
    excludedContractTypes,
    notificationTypePreferences,
    notificationPreferencesBySubmissionType,
    loading,
    contractTypesFilterOptions
}) => {

    const preferences = sortAndFilterSubmissionPreferences(notificationTypePreferences);

    const isCurrentlyChecked = preference => !isUndefinedOrNull(modified[preference.id]) ? modified[preference.id].active : preference.active;

    const noNotificationTypePreferences = preferences.length === 0;
    const noSubmissionTypePreferences = !Array.isArray(notificationPreferencesBySubmissionType) || notificationPreferencesBySubmissionType.length === 0;
    const noPreferencesToRender = noNotificationTypePreferences && noSubmissionTypePreferences;

    const notificationTypesSection = noNotificationTypePreferences 
        ? <div className="spaceBelow-xs">
            <NoNotifications />
        </div>
        : <div className={"spaceBelow-xs"}>
            <div>{
                preferences
                    .filter((categoryPreference) => finalVersionRequestFlag === false ? categoryPreference.notificationType.name !== "SUBMISSION_FINAL_VERSION_UPLOADED" : true)
                    .map((preference) => {
                        const text = `${getNotificationTypeDisplayName(preference.notificationType.name)}${getUnsavedChangeText(modified, preference)}`;
                        return <div key={preference.id} style={{ marginLeft: "8px" }}>
                            <Checkbox
                                disabled={preference.required}
                                key={preference.id}
                                checked={isCurrentlyChecked(preference)}
                                onChange={(e) => {
                                    setModified({
                                        ...modified,
                                        [preference.id]: {
                                            active: e.target.checked,
                                            isModified: preference.active !== e.target.checked
                                        }
                                    });
                                }}
                            >
                                { text }
                            </Checkbox>
                        </div>;
                    })
            }</div>
        </div>;

    return (
        <CardContainer
            noPreferencesToRender={noPreferencesToRender}
            title="Notification Types"
            subtitle={`For the above selected contract types, select which types of notifications you would like to receive for ${categoryName}. If any notifications are required based on your role, they cannot be turned off.`}
        >
            { notificationTypesSection }
            <Divider />
            <SubmissionTypesNotification 
                resetFlag={resetFlag}
                submissionTypeModified={submissionTypeModified}
                setSubmissionTypeModified={setSubmissionTypeModified}
                excludedContractTypes={excludedContractTypes}
                notificationPreferencesBySubmissionType={notificationPreferencesBySubmissionType}
                loading={loading}
                contractTypesFilterOptions={contractTypesFilterOptions}
            />
        </CardContainer>
    );
};