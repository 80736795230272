import React from "react";
import { EXPORTSIZE, PRIMARY_REVIEWER } from "../../constants/index.js";
import { formatTimeValue } from "../../utils/functions.js";
import { CsvExport } from "../common/CsvExport/index.js";
import { submissionsExportQuery } from "./query.js";

export const SubmissionsCsvExport = ({
    variables,
    userPermCreateSubmission
}) => {
    let csvHeaders = [
        {
            label: "ID",
            key: "id"
        },
        {
            label: "Submission Date",
            key: "submissionDate"
        },
        {
            label: "Submission Type",
            key: "submissionType"
        },
        {
            label: "Primary Reviewer",
            key: "primaryReviewer"
        },
        { 
            label: "Business Unit", 
            key: "businessUnit" 
        },
        { 
            label: "Contract Type", 
            key: "contractType" 
        },
        {
            label: "Organization",
            key: "organization"
        },
        {
            label: "Submission Status",
            key: "submissionStatus"
        }
    ];

    const checkPrimaryReviewer = (reviewerAssignments) =>{
        const assignment = reviewerAssignments.find((assignment) => assignment.role.specifier === PRIMARY_REVIEWER);
        const primaryReviewer = assignment ? assignment.assignee.name : "";
        return primaryReviewer;
    };

    let transformSubmissionsDataForCSV = (data) => {
        const csvDataArray = data.submissionsList.submissions.map((submission) => {
            return {
                id: submission.specifier,
                submissionDate: formatTimeValue(submission.latestVersionCreatedAt),
                submissionType: `${submission.obligation.submissionType.specifier}-${submission.obligation.submissionType.name}`,
                primaryReviewer: submission.reviewerAssignments.length > 0 ? checkPrimaryReviewer(submission.reviewerAssignments) : "",
                businessUnit: submission.obligation.submissionType.businessUnitName,
                contractType: submission.obligation.submissionType.contractType.name,
                organization: submission.submitter.name,
                submissionStatus: submission.status
            };
        });

        return csvDataArray;
    };

    if (variables.tab === "pending-failed" && userPermCreateSubmission){
        csvHeaders = [
            { label: "ID", key: "id" },
            { label: "Submission Date", key: "submissionDate" },
            { label: "Submission Type", key: "submissionType" },
            { label: "Primary Reviewer", key: "primaryReviewer" },
            { label: "Current Due Date", key: "currentDueDate" },
            { label: "Contract Type", key: "contractType" },
            { label: "Submission Status", key: "submissionStatus" },
            { label: "Action", key: "action" }
        ];

        transformSubmissionsDataForCSV = (data) => {
            const csvDataArray = data.submissionsList.submissions.map((submission) => {
                return {
                    id: submission.specifier,
                    submissionDate: formatTimeValue(submission.latestVersionCreatedAt),
                    submissionType: `${submission.obligation.submissionType.specifier}-${submission.obligation.submissionType.name}`,
                    primaryReviewer: submission.reviewerAssignments.length > 0 ? checkPrimaryReviewer(submission.reviewerAssignments) : "",
                    currentDueDate: submission.submitterDueDate,
                    contractType: submission.obligation.submissionType.contractType.name,
                    submissionStatus: submission.uploadStatus,
                    action: (submission.uploadStatus === "Failed") ? "Re-Upload" : ""
                };
            });
            return csvDataArray;
        };        
    }

    if (variables.tab === "pending-failed" && !userPermCreateSubmission) {
        csvHeaders = [
            { label: "ID", key: "id" },
            { label: "Submission Date", key: "submissionDate" },
            { label: "Submission Type", key: "submissionType" },
            { label: "Primary Reviewer", key: "primaryReviewer" },
            { label: "Current Due Date", key: "currentDueDate" },
            { label: "Contract Type", key: "contractType" },
            { label: "Organization", key: "organization" },
            { label: "Submission Status", key: "submissionStatus" }
        ];

        transformSubmissionsDataForCSV = (data) => {
            const csvDataArray = data.submissionsList.submissions.map((submission) => {
                return {
                    id: submission.specifier,
                    submissionDate: formatTimeValue(submission.latestVersionCreatedAt),
                    submissionType: `${submission.obligation.submissionType.specifier}-${submission.obligation.submissionType.name}`,
                    primaryReviewer: submission.reviewerAssignments.length > 0 ? checkPrimaryReviewer(submission.reviewerAssignments) : "",
                    currentDueDate: submission.submitterDueDate,
                    contractType: submission.obligation.submissionType.contractType.name,
                    organization: submission.submitter.name,
                    submissionStatus: submission.uploadStatus
                };
            });
            return csvDataArray;
        };
    }

    return <CsvExport
        csvFilename={"Submissions_Report.csv"}
        csvHeaders={csvHeaders}
        query={submissionsExportQuery}
        variables={{ ...variables, offset: 0, limit: EXPORTSIZE }}
        transformData={transformSubmissionsDataForCSV}
    />;
};