import { Modal } from "antd";
import React from "react";
import { DOCUMENT_UPLOAD_TYPES } from "../constants/index.js";

// helper functions -- 

const getEndpoint = (id, type, path) => {
    const defaultEndpoint = `/download/${id}`; 
    const endpointLookup = {
        [DOCUMENT_UPLOAD_TYPES.SUBMISSION]: `/download/${id}`,
        [DOCUMENT_UPLOAD_TYPES.LIBRARY]: `/download/library/${id}`,
        [DOCUMENT_UPLOAD_TYPES.FEEDBACK]: `/download/feedback-document/${id}`,
        [DOCUMENT_UPLOAD_TYPES.ARCHIVED]: `/archive/download/${id}?path=${path}`
    };
    return endpointLookup[type] ?? defaultEndpoint;
};

const handleBadResponse = (response) => {
    if (response.status === 401) {
        Modal.error({
            title: "File could not be downloaded",
            content: "You are not authorized to download this file."
        });
    } else if (response.status === 404) {
        Modal.error({
            title: "File could not be downloaded",
            content: "File was not found."
        });
    } else {
        Modal.error({
            title: "File could not be downloaded",
            content: "An error occurred while attempting to download this file."
        });
    }
};

const getFilenameFromResponse = (res) => {
    const filenameHeader = res.headers.get("Content-Disposition");
    const filenameInQuotes = filenameHeader?.split?.("filename=")?.[1];
    const filename = filenameInQuotes?.substring?.(1, filenameInQuotes.length - 1);
    return filename;
};

const downloadFromResponse = async (res, name, updateProgress) => {
    const filename = name && typeof name === "string" ? name : getFilenameFromResponse(res);
    const totalContentLength = parseInt(res.headers.get("Content-Length"), 10);
    if (filename && typeof filename === "string") {
        // stream file from server :: 
        const incomingBuffers = [];
        const reader = res.body.getReader();
        let isDone = false, 
            downloadedLength = 0;
        while (isDone === false) {
            const { done, value } = await reader.read();
            isDone = done;
            if (value) {
                incomingBuffers.push(value.buffer);
                downloadedLength += value.length;
                if (totalContentLength && totalContentLength > 0) {
                    const percentage = (downloadedLength / totalContentLength) * 100;
                    updateProgress(Math.ceil(percentage));
                }
            }
        }
        updateProgress(100);

        // get browser to "download" the file :: 
        const url = window.URL.createObjectURL(new Blob(incomingBuffers));
        const anchor = document.createElement("a");
        anchor.setAttribute("href", url);
        anchor.setAttribute("download", filename);
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    } else {
        updateProgress(null);
        throw new Error("Invalid filename or content-length");
    }
};


// exported functions for use :: 

export const downloadFile = async ({ id, name, type, path, register, displayError = true }) => {
    const updateProgress = register({ id, name });
    try {
        const downloadUrl = getEndpoint(id, type, path);
        const response = await fetch(downloadUrl);
        if (response.ok) { 
            await downloadFromResponse(response, name, updateProgress);
        } else {
            updateProgress(null);
            if (displayError) {
                handleBadResponse(response);
            }
            return false;
        }
        return true;
    } catch (err) {
        console.log(err);
        updateProgress(null);
        if (displayError) {
            Modal.error({
                title: `File ${name && typeof name === "string" ? name : ""} could not be downloaded`,
                content: "An unexpected error occurred while attempting to download this file."
            });
        }
        return false;
    }
};


const displayBatchDownloadErrorMessage = (errorDocuments) => {
    const errorFilesDisplay = errorDocuments.map((subDoc, index) => { 
        const name = subDoc?.documentNewestVersion?.name;
        const specifier = subDoc?.specifier;
        return <li key={`download-all-error-message-${subDoc.id}-${index}`}>
            { name ? <p>{ name }</p> : null }
            { specifier ? <p>{ specifier }</p> : null }
        </li>;
    });
    Modal.error({
        title: "Some files could not be downloaded",
        content: (
            <ul>
                { errorFilesDisplay }
            </ul>
        )
    });
};

export const downloadAllNewSubmissionDocuments = async (submissionDocuments, register) => { 
    try {
        if (Array.isArray(submissionDocuments) && submissionDocuments?.length > 0) {
            const errorDocuments = [];

            const downloadFilePromises = submissionDocuments.map(async (subDoc) => {
                const newestDoc = subDoc.documentNewestVersion;
                const success = await downloadFile({ ...newestDoc, register, displayError: false });
                if (!success) {
                    errorDocuments.push(subDoc);
                }
            });
            
            await Promise.allSettled(downloadFilePromises);

            if (errorDocuments.length > 0) {
                displayBatchDownloadErrorMessage(errorDocuments);
            }
        }
    } catch (err) {
        console.log(err);
        Modal.error({
            title: "Files could not be downloaded",
            content: "An unexpected error occurred while attempting to download this file."
        });
    }
};