import React from "react";
import { FilterDropdown } from "../components/common/FilterDropdown/index.js";
import defaultTableRender from "./defaultTableRender.js";
import defaultTableFilter from "./defaultTableFilter.js";
import { FilterIcon } from "../components/common/FilterIcon/index.js";

export const filterable = ({
    title,
    className,
    dataIndex,
    key,
    sorter,
    sortDirections,
    width,
    render = defaultTableRender,
    customFilter,
    domain,
    setFilter,
    setPage,
    truncate = false,
    startShown,
    alwaysOn,
    defaultSortOrder,
    clearFilters, 
    clearSearchOnReset, 
    filter,
    searchFilters = false,
    resetFilterSearch,
    ellipsis
}) => {
    const setFilterToUse = typeof setPage === "function" 
        ? (...args) => {
            setPage(1);
            setFilter(...args);
        }
        : setFilter;
    
    return {
        title,
        className,
        dataIndex,
        key,
        sorter,
        startShown,
        alwaysOn,
        sortDirections,
        defaultSortOrder,
        width,
        ellipsis,
        filterDropdown: () => {
            return (
                <FilterDropdown
                    title={title}
                    passedKey={key}
                    setFilter={setFilterToUse}
                    filter={filter}
                    domain={domain}
                    truncate={truncate}
                    searchFilters={searchFilters}
                    clearFilters={clearFilters}
                    resetFilterSearch={resetFilterSearch}
                    clearSearchOnReset={clearSearchOnReset}
                />
            );
        },
        filterIcon: () => { 
            const isActive = Array.isArray(filter) && filter.length > 0;
            return FilterIcon(isActive);
        },
        onFilter: customFilter ?? defaultTableFilter(dataIndex),
        render
    };
};
