import React, { useState } from "react";
import { InputNumber } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import getUniqueKey from "../../../utils/getUniqueKey.js";

export const NumberableDetail = ({
    title,
    passedMin,
    passedMax,
    placeholder,
    value,
    onValueUpdated,
    strict = false,
    readOnly = false,
    disabled = false
}) => {
    const [updatedValue, setUpdatedValue] = useState(typeof value !== "undefined" && value !== null ? value : passedMin);
    const [backupKey] = useState(getUniqueKey());

    if (!strict && typeof value === "undefined" && typeof updatedValue !== "undefined") {
        setUpdatedValue(undefined);
    }

    const classNames = " input--filled";

    const label = (
        <label
            style={{ margin: "0 32px 0 8px" }}
        >
            <FontAwesomeIcon icon={faPencilAlt} color="#f00" className="icon--sae" />
            <span>
                {title}
            </span>
        </label>
    );

    const input = (
        <InputNumber
            size="small"
            style={{ width: "48px", display: "inline-flex" }}
            min={passedMin}
            max={passedMax}
            value={strict === true ? value : updatedValue}
            placeholder={placeholder}
            onChange={(e) => {
                if (strict !== true) {
                    setUpdatedValue(e);
                }
                onValueUpdated(e);
            }}
            disabled={disabled}
            readOnly={readOnly}
        />
    );

    return (
        <span key={ title ? `numberable-detail-${title}` : backupKey } className={classNames}>
            {input}
            {label}
        </span>
    );
};
