import React from "react";
import { SelectableDetail } from "../SelectableDetail/index.js";
import {
    currentYear
} from "../../../utils/functions.js";

export const SubmissionYearDropDown = ({
    yearFilter,
    setYearFilter,
    title,
    onChange
}) => {
    const yearsListOptions = () => {
        let years = [];
        let presentYear = currentYear;
        let startYear = 2021;
        while (startYear <= presentYear) {
            const year = presentYear--;
            years.push({
                key: year,
                text: year,
                value: year
            });
        }
        return years;
    };

    const yearsList = yearsListOptions();
    
    return (
        <SelectableDetail
            title={title}
            value={yearFilter}
            strict={true}
            onValueUpdated={(value) => {
                setYearFilter(value);
                if (onChange) onChange();
            }}
            showArrow={true}
            options={yearsList}
        />
    );
};